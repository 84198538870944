import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { indicatorListsReducer } from './indicator-lists.reducer';
import { IndicatorListsEffects } from './indicator-lists.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('indicatorLists', indicatorListsReducer),
    EffectsModule.forFeature([IndicatorListsEffects]),
  ],
})
export class IndicatorListsStoreModule {}
