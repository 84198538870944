import { CurrencyPipe } from '@angular/common';
import { Localized } from '../models/localized';
import {
  Language,
  Locale,
} from '../../core/services/session/models/session-record';
import { getWeightUom } from '../../core/store/material-price/material-price.util';
import { getCurrencyCode, getLanguageTag } from './locale-utilities';

export class LocalizedUtilities {
  private static readonly currencyPipes: Map<Locale, CurrencyPipe> = new Map(
    Object.keys(Locale).map((value: Locale) => [
      Locale[value],
      new CurrencyPipe(getLanguageTag(Locale[value])),
    ]),
  );

  public static getLocalizedValue<T>(
    localizedValues: Localized<T>,
    language: Language,
    shouldFallback: boolean = true,
  ): T | undefined {
    if (!localizedValues) {
      return undefined;
    }
    if (shouldFallback) {
      return (
        localizedValues[language] || localizedValues.en || localizedValues.fr
      );
    }

    return localizedValues[language];
  }

  public static getLocalizedStringValue(
    localizedString: Localized<string>,
    language: Language,
    shouldFallback: boolean = true,
  ): string {
    return (
      this.getLocalizedValue(localizedString, language, shouldFallback) ?? ''
    );
  }

  public static getLocalizedPrice(
    locale: Locale,
    value: string | number,
    weightUom?: string,
  ) {
    if (!value || value === '0') {
      return undefined;
    }

    const pipe =
      this.currencyPipes.get(locale) ?? this.currencyPipes.get(Locale.en_US);

    let result = pipe.transform(
      value,
      getCurrencyCode(locale),
      'symbol-narrow',
      '1.2-2',
    );

    const weightUomDisplay = getWeightUom(weightUom);
    if (!!weightUomDisplay) {
      result += `/${weightUomDisplay}`;
    }

    return result;
  }

  public static getLocalizedBoolean(
    value: boolean,
    language: Language,
  ): string {
    if (!value) {
      return 'N';
    }
    if (Language.fr === language) {
      return 'O';
    } else if (Language.es === language) {
      return 'S';
    }
    return 'Y';
  }
}
