import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import {
  PickupScheduleRecord,
  PickupScheduleRecords,
} from './models/pickup-schedule-record';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PickupSchedulesService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getPickupSchedules(
    storePlantIds: string[],
  ): Observable<{ [key: string]: PickupScheduleRecord[] }> {
    return this.httpClient
      .post<PickupScheduleRecords>(
        `${this.webBffService.getBff()}/api/v1/stores/pickup-schedules`,
        { storePlantIds },
      )
      .pipe(
        map((response) => {
          return response?.storeHoursMap;
        }),
      );
  }
}
