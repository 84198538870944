import { Inject, Injectable } from '@angular/core';
import * as LogRocket from 'logrocket';
import { isError, isString } from 'lodash-es';
import { EnvironmentSpecificService } from '../environment-specific/environment-specific.service';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LogRocketService {
  private recordURL = '';

  constructor(
    private environmentSpecificService: EnvironmentSpecificService,
    private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  getRecordURL(): string {
    return this.recordURL;
  }

  initLogRocket(): void {
    const appId = this.environmentSpecificService.getLogRocketAppId();
    if (appId) {
      // Have to add non-passive listeners for mousemove and touchmove before logrocket adds passive listeners to zone.js for DragDropModule
      this._document.addEventListener('mousemove', () => {}, {
        passive: false,
        capture: true,
      });
      this._document.addEventListener('touchmove', () => {}, {
        passive: false,
        capture: true,
      });
      LogRocket.init(appId, {
        mergeIframes: true,
        childDomains: [
          this.environmentSpecificService.getLogRocketChildDomain(),
        ],
      });
      LogRocket.getSessionURL((url) => (this.recordURL = url));
    }
    const _error = this._window.console.error;
    this._window.console.error = function (firstArg: string | Error) {
      _error.apply(this, arguments);
      if (isError(firstArg)) {
        LogRocket.captureException(firstArg);
      } else if (isString(firstArg)) {
        LogRocket.captureMessage(firstArg);
      } else {
        LogRocket.captureException(new Error(JSON.stringify(firstArg)));
      }
    };
  }
}
