import { createActionGroup } from '@ngrx/store';
import { MaterialInfo } from '../../../shared/models/material-info';

export const MaterialDetailsActions = createActionGroup({
  source: 'Material Details',
  events: {
    'Load Material Details': (materialInfo: MaterialInfo) => ({
      materialInfo,
    }),
  },
});
