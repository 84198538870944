import { Localized } from './localized';
import {
  getBrandFromMaterialInfo,
  getDescriptionFromMaterialInfo,
  getGtinFromMaterialInfo,
  getQtyPerMasterSellUnitFromMaterialInfo,
  MaterialInfo,
} from './material-info';

export interface ProductInfo {
  offeringId: string;
  brand: {
    id: string;
    en_CA: string;
    fr_CA: string;
    [key: string]: string;
  };
  description: Localized<string>;
  innerPackSize: string;
  qtyPerMasterSellUnit: number;
  gtin: string;
  catchWeightInd: boolean;
  netWeightKg: number;
  isEmpty?: boolean;
  lastOrderDate?: string;
}

export function createProductInfoFromMaterialInfo(
  materialInfo: MaterialInfo,
): ProductInfo {
  const productInfo: ProductInfo = {
    offeringId: materialInfo.materialNumber,
    brand: getBrandFromMaterialInfo(materialInfo),
    description: getDescriptionFromMaterialInfo(materialInfo),
    innerPackSize: materialInfo.innerPackSize,
    qtyPerMasterSellUnit: getQtyPerMasterSellUnitFromMaterialInfo(materialInfo),
    gtin: getGtinFromMaterialInfo(materialInfo),
    catchWeightInd: materialInfo.isCatchWeight,
    netWeightKg:
      !!materialInfo.baseUomWeight && !!materialInfo.baseUomWeight.net
        ? +materialInfo.baseUomWeight.net
        : null,
  };
  if (materialInfo.isEmpty) {
    productInfo.isEmpty = materialInfo.isEmpty;
  }

  if (materialInfo.lastOrderDate) {
    productInfo.lastOrderDate = materialInfo.lastOrderDate;
  }

  return productInfo;
}
