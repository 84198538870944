import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OrderGuideChangeHistoryRecord } from './models/order-guide-change-history-record';

interface OrderGuideChangeHistoryResponse {
  orderGuideChangeItems: OrderGuideChangeHistoryRecord[];
}

@Injectable({ providedIn: 'root' })
export class OrderGuideChangeHistoryService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getOrderGuideChangeHistory(): Observable<OrderGuideChangeHistoryRecord[]> {
    return this.httpClient
      .get<OrderGuideChangeHistoryResponse>(
        this.webBffService.getBff() + '/api/v1/lists/orderguide/change-history',
      )
      .pipe(map((response) => response.orderGuideChangeItems));
  }
}
