import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-list-header',
  templateUrl: './material-list-header.component.html',
  styleUrls: ['./material-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialListHeaderComponent {
  @Input() context: MaterialRowContext;
  @Input() listStyle: MaterialListStyle;
  @Input() isParEnabled: boolean;
  @Input() isSlimView: boolean;
  @Input() isLoyaltyProgramEligible: boolean;
  @Input() displayGoPointsLogo: boolean = true;

  get displayHeaderSpacer(): boolean {
    return (
      !this.isInsideContainer &&
      MaterialRowContext.OrderDetailsEditModal !== this.context
    );
  }

  get isInsideContainer(): boolean {
    return [
      MaterialRowContext.CartReview,
      MaterialRowContext.Substitutes,
    ].includes(this.context);
  }

  get isUnderCategoryHeader(): boolean {
    return [
      MaterialRowContext.OrderGuide,
      MaterialRowContext.CriticalItemsGuide,
      MaterialRowContext.CustomGuide,
    ].includes(this.context);
  }

  get shouldDisplayLoyaltyPoints(): boolean {
    return (
      this.isLoyaltyProgramEligible &&
      this.listStyle === MaterialListStyle.List &&
      !this.isParEnabled &&
      this.displayGoPointsLogo
    );
  }

  get shouldHideGoPointsLogo(): boolean {
    return !this.displayGoPointsLogo && this.isLoyaltyProgramEligible;
  }
}
