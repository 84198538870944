import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { mergeMap, Observable, of } from 'rxjs';
import { MarketingGuideActions } from './marketing-guide.actions';
import { MarketingGuideService } from '../../services/marketing-guide/marketing-guide.service';
import { catchError, map } from 'rxjs/operators';
import { SharedActions } from '../shared/shared.actions';
import { selectMarketingGuideRecordState } from './marketing-guide.selectors';
import { getMarketingGuideMaterials } from './marketing-guide.transformer';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';

@Injectable()
export class MarketingGuideEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private marketingGuideService: MarketingGuideService,
  ) {}

  getMarketingGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketingGuideActions.getMarketingGuide),
      concatLatestFrom((action) =>
        this.store.select(selectMarketingGuideRecordState(action.pathUrl)),
      ),
      mergeMap(([action, recordState]) => {
        if (recordState.hasLoaded && recordState.record) {
          return of(
            SharedActions.noOperation('Marketing Guide Already Loaded'),
          );
        }

        return this.marketingGuideService
          .getMarketingGuide(action.pathUrl)
          .pipe(
            map((marketingGuideRecord) =>
              MarketingGuideActions.getMarketingGuideSuccess(
                marketingGuideRecord,
              ),
            ),
            catchError(() =>
              of(
                MarketingGuideActions.getMarketingGuideFailure(action.pathUrl),
              ),
            ),
          );
      }),
    );
  });

  getMarketingGuideSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketingGuideActions.getMarketingGuideSuccess),
      mergeMap((record) => {
        const materialNumbers = getMarketingGuideMaterials(
          record.marketingGuideRecord,
        );
        return [
          MaterialInfoActions.loadMaterialInfo(materialNumbers),
          MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers),
        ];
      }),
    );
  });
}
