import { Params } from '@angular/router';

export interface StorePurchaseKey {
  storeId: string;
  saleDate: string;
  laneNumber: number;
  saleNumber: number;
}

export function getStorePurchaseKeyFromParams(
  params: Params,
): StorePurchaseKey | undefined {
  if (isStorePurchaseKey(params)) {
    return params;
  }
  return undefined;
}

function isStorePurchaseKey(params: Params): params is StorePurchaseKey {
  const keyObject = <StorePurchaseKey>params;
  return !!(
    keyObject?.storeId &&
    keyObject?.saleDate &&
    keyObject?.laneNumber &&
    keyObject?.saleNumber
  );
}
