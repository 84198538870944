<naoo-modal-header
  [title]="'EDIT_ORDER.EDIT_ORDER_QUANTITY' | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>
<div class="order-details-note">
  {{ 'ORDERS.DETAILS.NOTE' | translate }}
</div>
<div class="modal-wrapper">
  <naoo-material-list-header
    [context]="context"
    [listStyle]="listStyle"
    [isParEnabled]="false"
    *ngIf="!isMobile"
  >
  </naoo-material-list-header>
  <naoo-material-row-container
    class="material-row-border"
    [materialNumber]="orderItem.materialNumber"
    [context]="context"
    [listStyle]="isMobile ? gridStyle : listStyle"
    [isLast]="true"
    [isFirst]="true"
    [isMobile]="isMobile"
    [materialLines]="quantityOrdered"
    (quantityInputChanged)="handleQuantityInputChanged($event)"
  >
  </naoo-material-row-container>
</div>
<div class="buttons-container">
  <button
    (click)="closeModal()"
    class="cancel-button"
    attr.aria-label="{{ 'SHARED.CANCEL' | translate }}"
  >
    {{ 'SHARED.CANCEL' | translate }}
  </button>
  <button
    (click)="saveChanges()"
    class="done-button"
    [disabled]="isSaveDisabled"
    attr.aria-label="{{ 'SHARED.SAVE' | translate }}"
  >
    {{ 'SHARED.SAVE' | translate }}
  </button>
</div>
