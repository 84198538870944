<button
  class="action-button"
  [disabled]="iconCurrentState !== enabledIconState"
  [attr.aria-label]="label | translate"
  (click)="handleClick()"
  [ngClass]="{
    'action-icon-disabled': iconCurrentState === disabledIconState,
    'action-icon-enabled': iconCurrentState === enabledIconState,
    'print-icon': actionIcon === printActionIcon,
    'in-cart-action-bar': isInCartActionBar,
  }"
>
  <div class="action-icon-label-container">
    <mat-icon [svgIcon]="actionIcon"></mat-icon>
    <div class="action-icon-label">{{ label | translate }}</div>
  </div>
</button>
