import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderGuideChangeItemStatus } from '../../services/order-guide-change-history/models/order-guide-change-history-record';
import { Observable } from 'rxjs';
import { selectMaterialGuideMapping } from './order-guide-change-history.selectors';
import { Localized } from '../../../shared/models/localized';
import { MaterialGuideMapping } from './models/order-guide-change-history';

export interface OrderGuideChangeItem {
  description: Localized<string>;
  materialNumber: string;
  lastUpdate: Date;
  status: OrderGuideChangeItemStatus;
}

@Injectable({ providedIn: 'root' })
export class OrderGuideChangeHistoryFacade {
  constructor(private store: Store) {}

  getChangesMaterialGuideMapping(): Observable<MaterialGuideMapping> {
    return this.store.select(selectMaterialGuideMapping);
  }
}
