<div class="main-container" [ngClass]="{ 'hide-border': hideBorder }">
  <div class="title">{{ 'ALLERGENS.TITLE' | translate }}</div>
  <div
    class="allergen-row"
    *ngFor="let key of allergens; let last = last"
    [ngClass]="{ last: last }"
  >
    {{ allergenInfoOrder[key] | translate }}
    <span class="allergen-status">{{
      allergenStatus(allergenInfo[key]) | translate
    }}</span>
  </div>
  <div class="allergen-warning">
    {{ 'PRODUCT_DETAILS.ALLERGEN_WARNING' | translate }}
  </div>
</div>
