import { Observable, of } from 'rxjs';
import { MultipleCartsRecord } from '../../../services/multiple-carts/models/multiple-carts-record';
import { MultipleCartsTransformer } from '../transformer/multiple-carts.transformer';
import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { MultipleCartsActions } from '../multiple-carts.actions';
import { CartActions } from '../../cart/cart.actions';
import { ErrorActions } from '../../error/error.actions';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { DefaultDialogService } from '../../../../shared/services/dialog/default-dialog/default-dialog.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { NotificationPriority } from '../../../../shared/models/notification';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../../../shared/error-handler/NaooErrorUtils';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalActions } from '../../modal/modal.actions';
import {
  CustomerPermission,
  SessionActiveCustomer,
} from '../../../services/session/models/session-record';
import { mergeMaterialList } from '../../../../shared/utilities/cart-material-utilities';
import { MaterialRowContext } from '../../material-row/models/material-row';
import { selectCustomerAndCartQuantities } from '../multiple-carts.selectors';

export enum MultipleCartErrorRefresh {
  All = 'All',
  Cart = 'Cart',
  SavedCarts = 'Saved Carts',
}

@Injectable({ providedIn: 'root' })
export class MultipleCartsUtilities {
  readonly notificationTitle = 'CART.SUBMITTED_CARTS.BANNER.TITLE';

  constructor(
    private transformer: MultipleCartsTransformer,
    private defaultDialogService: DefaultDialogService,
    private store: Store,
    private notificationService: NotificationService,
  ) {}

  serviceCall(
    serviceCaller: () => Observable<MultipleCartsRecord>,
    successHandler?: () => void,
    successHandlerClosesDialog?: boolean,
    refreshCartOnError = MultipleCartErrorRefresh.SavedCarts,
    errorHandler?: () => void,
    mergeWithCurrentCart?: boolean,
  ): Observable<Action> {
    this.defaultDialogService.openLoadingModal();
    return this.store.select(selectCustomerAndCartQuantities).pipe(
      first(({ isDoneUpdating }) => isDoneUpdating),
      switchMap(({ customer, cartMaterialQuantities }) => {
        return serviceCaller().pipe(
          mergeMap((multipleCartsRecord) => {
            if (successHandler) {
              successHandler();
            }

            if (!successHandlerClosesDialog) {
              this.defaultDialogService.closeLoadingModal();
            }

            if (
              customer.permissions.includes(
                CustomerPermission.OrderSubmission,
              ) &&
              !this.hasSubmittedCartsThatRequireApproval(multipleCartsRecord)
            ) {
              this.notificationService.clearNotification(
                NotificationPriority.HIGH,
                this.notificationTitle,
              );
            }

            const actions: Action[] = [
              MultipleCartsActions.getSavedCartsSuccess(multipleCartsRecord),
            ];

            if (!multipleCartsRecord.activeCart) {
              return actions;
            }

            if (mergeWithCurrentCart) {
              const mergedQuantityUpdates = mergeMaterialList(
                multipleCartsRecord.activeCart.materials,
                cartMaterialQuantities,
                MaterialRowContext.ActivateCart,
              );
              const mergeCart = {
                ...multipleCartsRecord.activeCart,
                materials: mergedQuantityUpdates,
              };
              const cartQuantityUpdates = this.transformer.toCartQuantities(
                mergeCart,
                MaterialRowContext.ActivateCart,
              );
              actions.push(
                CartActions.refreshCartSuccess(
                  this.transformer.toCartEntity(mergeCart),
                ),
                CartActions.updateCartQuantities(cartQuantityUpdates),
              );
            } else {
              actions.push(
                CartActions.refreshCartSuccess(
                  this.transformer.toCartEntity(multipleCartsRecord.activeCart),
                ),
              );
            }

            return actions;
          }),
          catchError((error) =>
            this.processError(error, refreshCartOnError, errorHandler),
          ),
        );
      }),
    );
  }

  notifyCartsSubmittedForApproval(
    multipleCartsRecord: MultipleCartsRecord,
    currentCustomer: SessionActiveCustomer,
  ) {
    if (
      currentCustomer.permissions.includes(
        CustomerPermission.OrderSubmission,
      ) &&
      this.hasSubmittedCartsThatRequireApproval(multipleCartsRecord)
    ) {
      this.notificationService.sendNotification(
        {
          title: this.notificationTitle,
          body: 'CART.SUBMITTED_CARTS.BANNER.BODY',
          route: {
            routerLink: '/cart',
            queryParams: { activeTab: 'saved-carts' },
            text: 'CART.SUBMITTED_CARTS.BANNER.LINK',
          },
        },
        undefined,
        NotificationPriority.HIGH,
      );
    }
  }

  private hasSubmittedCartsThatRequireApproval(
    multipleCartsRecord: MultipleCartsRecord,
  ) {
    return (
      multipleCartsRecord.submittedForApprovalCarts.filter(
        (cart) => cart.status === NaooConstants.CartStatus.Saved,
      ).length > 0
    );
  }

  private processError(
    errorResponse: HttpErrorResponse,
    errorRefresh: MultipleCartErrorRefresh,
    errorHandler?: () => void,
  ): Observable<Action> {
    this.defaultDialogService.closeLoadingModal();
    if (errorHandler) {
      errorHandler();
    }

    const error = NaooErrorUtils.getNaooError(errorResponse);
    switch (error.code) {
      case NAOOErrorCode.CART_RETRYABLE_ERROR:
        return of(
          ModalActions.oneButtonModal(
            'cart-contention-error-display',
            'CART.SAVED_CARTS.CONTENTION_ERROR_MESSAGE',
            'SHARED.MODALS.OK_BUTTON_TEXT',
            () => {},
            true,
          ),
        );
      case NAOOErrorCode.CART_STATUS_INVALID:
        return of(
          ModalActions.oneButtonModal(
            'cart-status-invalid-display',
            'CART.SAVED_CARTS.UNEXPECTED_ERROR_MESSAGE',
            'SHARED.MODALS.OK_BUTTON_TEXT',
            () => {
              this.refreshData(errorRefresh);
            },
            false,
          ),
        );
      default:
        return of(ErrorActions.fatalError(errorResponse));
    }
  }

  private refreshData(refreshCartOnError: MultipleCartErrorRefresh) {
    switch (refreshCartOnError) {
      case MultipleCartErrorRefresh.All:
        this.store.dispatch(CartActions.refreshCart());
        this.store.dispatch(MultipleCartsActions.refreshSavedCarts());
        break;
      case MultipleCartErrorRefresh.Cart:
        this.store.dispatch(CartActions.refreshCart());
        break;
      case MultipleCartErrorRefresh.SavedCarts:
        this.store.dispatch(MultipleCartsActions.refreshSavedCarts());
        break;
    }
  }
}
