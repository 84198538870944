import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreRecord } from '../../services/store/model/store-record';
import { StoreState, storeStateAdapter, StoreStateStatus } from './store.state';
import { selectStoreFulfillment } from '../cart/cart.selectors';
import {
  FulfillmentType,
  StoreFulfillment,
} from '../../services/cart/models/cart-record';
import moment, { Moment } from 'moment';

export interface ActiveStoreFulfillment {
  store?: StoreRecord;
  selectedDate: Moment;
  storeFulfillment?: StoreFulfillment;
}

const selectStoreFeature = createFeatureSelector<StoreState>('store');

export const selectAllStoreRecordStateEntities = createSelector(
  selectStoreFeature,
  (state) => storeStateAdapter.getSelectors().selectEntities(state.records),
);

export const selectAllStoreRecords = createSelector(
  selectStoreFeature,
  (state): StoreRecord[] =>
    storeStateAdapter.getSelectors().selectAll(state.records),
);

export const selectStoreStateStatus = createSelector(
  selectStoreFeature,
  (state): StoreStateStatus | undefined => state.status,
);

const selectHasStoresLoaded = createSelector(selectStoreStateStatus, (status) =>
  [StoreStateStatus.Success, StoreStateStatus.Error].includes(status),
);

export const selectPickupStoreRecords = createSelector(
  selectAllStoreRecords,
  (storeRecords): StoreRecord[] => {
    return storeRecords.filter((store) =>
      store.fulfillmentTypes.includes(FulfillmentType.PICKUP),
    );
  },
);

export const selectPickupStorePlantIds = createSelector(
  selectHasStoresLoaded,
  selectPickupStoreRecords,
  (hasLoaded, storeRecords): string[] => {
    return hasLoaded
      ? storeRecords.map((store) => store.storePlantId)
      : undefined;
  },
);

export const selectLoadedStoreRecord = (storePlantId: string) =>
  createSelector(
    selectHasStoresLoaded,
    selectAllStoreRecordStateEntities,
    (hasLoaded, storeRecordStateDictionary): StoreRecord | undefined => {
      return hasLoaded ? storeRecordStateDictionary[storePlantId] : undefined;
    },
  );

export const selectLoadedStoreRecords = (storePlantIds: string[]) =>
  createSelector(
    selectHasStoresLoaded,
    selectAllStoreRecordStateEntities,
    (
      hasLoaded,
      storeRecordStateDictionary,
    ): Map<string, StoreRecord> | undefined => {
      return hasLoaded
        ? new Map(
            storePlantIds.map((storeId) => [
              storeId,
              storeRecordStateDictionary[storeId],
            ]),
          )
        : undefined;
    },
  );

export const selectActiveStoreFulfillment = createSelector(
  selectHasStoresLoaded,
  selectStoreFulfillment,
  selectAllStoreRecordStateEntities,
  (
    hasStoreLoaded,
    fulfillment,
    storeRecordStateDictionary,
  ): ActiveStoreFulfillment | undefined => {
    if (!fulfillment || !hasStoreLoaded) {
      return undefined;
    }
    const {
      storePlantId,
      requestedPickupTimestamp,
      deliveryWindowStartTimestamp,
      deliveryWindowEndTimestamp,
    } = fulfillment;
    const store = storePlantId
      ? storeRecordStateDictionary[storePlantId]
      : undefined;

    let selectedDate: Moment;
    if (requestedPickupTimestamp && store?.timezone) {
      selectedDate = moment.tz(requestedPickupTimestamp, store.timezone);
    } else if (requestedPickupTimestamp) {
      selectedDate = moment(requestedPickupTimestamp);
    }
    return {
      store,
      selectedDate,
      storeFulfillment:
        deliveryWindowStartTimestamp && deliveryWindowEndTimestamp
          ? {
              storePlantId,
              deliveryWindowStartTimestamp,
              deliveryWindowEndTimestamp,
            }
          : undefined,
    };
  },
);
