import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'naoo-remove-from-category-toast',
  templateUrl: './remove-from-category-toast.component.html',
})
export class RemoveFromCategoryToastComponent implements OnInit {
  categoryName: string;
  isProductSuccessfullyRemoved: boolean;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { categoryName: string; success: boolean },
  ) {}

  ngOnInit() {
    this.categoryName = this.data.categoryName;
    this.isProductSuccessfullyRemoved = this.data.success;
  }
}
