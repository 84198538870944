import { SessionRecord } from '../../services/session/models/session-record';

export interface SessionState {
  sessionRecord?: SessionRecord;
  previousSelectedCompositeCustomerId?: string;
  isCustomerUpdating: boolean;
}

export const initialSessionState: SessionState = {
  sessionRecord: undefined,
  previousSelectedCompositeCustomerId: undefined,
  isCustomerUpdating: true,
};
