import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialPriceInfoRecord } from '../../services/material-price/material-price.service';

export const CommodityPriceActions = createActionGroup({
  source: 'Commodity Price',
  events: {
    'Load Commodity Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Commodity Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Abort Commodity Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Commodity Prices Success': (
      priceInfoRecord: MaterialPriceInfoRecord,
    ) => ({ priceInfoRecord }),
    'Get Commodity Prices Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Commodity Prices': emptyProps(),
    'Refresh Commodity Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
  },
});
