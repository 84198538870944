import {
  CartReviewHeaderType,
  CartReviewSection,
  CartReviewSectionName,
  CartReviewSectionType,
} from '../cart-review.state';
import { MaterialListRow } from '../../../../material-list/models/material-list';
import { MaterialRowContext } from '../../material-row/models/material-row';
import { DeliveryScheduleEntryRecord } from '../../../services/delivery-schedule/models/delivery-schedules-record';
import { MaterialAvailabilityRecordState } from '../../material-availability/material-availability.state';
import { Site } from '../../../services/session/models/session-record';
import {
  FulfillmentMaterial,
  FulfillmentOrderSplit,
  StoreOrderSplitOptions,
} from '../../../services/pre-submit/model/order-split-record';
import {
  getDropShipMaterials,
  transformFulfillmentMaterialsToMaterialListRows,
} from './cart-review-util';
import { StoreRecord } from '../../../services/store/model/store-record';
import { FulfillmentType } from '../../../services/cart/models/cart-record';
import { CartReviewTransformerStoreData } from '../cart-review.selectors';
import { ExpressDeliveryWindow } from '../../../services/express-schedules/models/express-schedule-record';
import moment, { Moment } from 'moment';
import { NaooConstants } from '../../../../shared/NaooConstants';

export const cartReviewExceptionIcon = NaooConstants.icons.exception_icon_v2;
export const cartReviewSuccessIcon = NaooConstants.icons.checkmark_icon;
export const cartReviewWarningIcon = NaooConstants.icons.warning;
const warningIcon = NaooConstants.icons.warning_v3;

export function createCutoffSplitItemsSection(
  materialNumbers: MaterialListRow[],
  nextValidDeliverySchedules: DeliveryScheduleEntryRecord[],
  poNumber: string,
): CartReviewSection {
  const headerKey = 'CART_REVIEW.CUTOFF_SPLIT.BANNER.NAME';
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.MissedCutoff,
    icon: cartReviewExceptionIcon,
    materialNumbers,
    name: CartReviewSectionName.CutoffSplitItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Error,
    nextValidDeliverySchedules,
    poNumber,
    subHeaderKeys: [{ key: 'CART_REVIEW.CUTOFF_SPLIT.BANNER.SUB_HEADER' }],
  };
}

export function createDropShipItemsSections(
  availabilities: MaterialAvailabilityRecordState[],
  fulfillmentOrderSplits: FulfillmentOrderSplit[],
  sites: Site[],
  selectedSiteId: string,
  poNumber: string,
  isPoNumberRequired: boolean,
): CartReviewSection[] {
  if (!fulfillmentOrderSplits) {
    return [];
  }
  return fulfillmentOrderSplits.map((fulfillmentOrderSplit, index) => {
    const dropShipMaterials = getDropShipMaterials(
      availabilities,
      fulfillmentOrderSplit,
    );
    return createDropShipItemsSection(
      dropShipMaterials,
      fulfillmentOrderSplit,
      sites,
      fulfillmentOrderSplits.length > 1 ? index + 1 : 0,
      selectedSiteId,
      poNumber,
      isPoNumberRequired,
    );
  });
}

export function createStoreItemsSections(
  storeData: CartReviewTransformerStoreData,
  routeDate: Moment,
  poNumber: string,
  options: StoreOrderSplitOptions,
  customerTz: string,
): CartReviewSection[] {
  if (!options?.storeOrderOptions?.length) {
    return [];
  }
  const orderOption = options.storeOrderOptions[0];
  const orderOptionSplit = orderOption.splitsForStoreOrderOption[0];

  if (orderOption.splitsForStoreOrderOption.length === 1) {
    let pickupResolutionDate = undefined;
    let expressResolutionDate = undefined;
    let type = CartReviewSectionType.Error;
    const suggestedAvailabilityDateTime = moment.tz(
      orderOptionSplit.suggestedAvailabilityDateTime,
      customerTz,
    );
    const isCurrentlyAvailable = routeDate?.isSame(
      suggestedAvailabilityDateTime,
    );
    if (isCurrentlyAvailable) {
      pickupResolutionDate = routeDate;
      expressResolutionDate = storeData.currentExpressDeliveryWindow;
      type = CartReviewSectionType.Success;
    }
    return [
      createStoreItemsSection(
        storeData.fulfillmentType,
        poNumber,
        routeDate,
        0,
        suggestedAvailabilityDateTime,
        orderOptionSplit.materials,
        storeData.store,
        pickupResolutionDate,
        storeData.currentExpressDeliveryWindow,
        expressResolutionDate,
        type,
      ),
    ];
  }

  const sections: CartReviewSection[] = [];

  let i = orderOption.splitsForStoreOrderOption.length - 1;
  let sectionCounter = 0;
  while (i > -1) {
    let pickupResolutionDate = undefined;
    let expressResolutionDate = undefined;
    let type = CartReviewSectionType.Error;
    if (i === 0) {
      pickupResolutionDate = routeDate;
      expressResolutionDate = storeData.currentExpressDeliveryWindow;
      type = CartReviewSectionType.Success;
    }
    const split = orderOption.splitsForStoreOrderOption[i];
    sections.push(
      createStoreItemsSection(
        storeData.fulfillmentType,
        poNumber,
        routeDate,
        sectionCounter,
        moment.tz(split.suggestedAvailabilityDateTime, customerTz),
        split.materials,
        storeData.store,
        pickupResolutionDate,
        storeData.currentExpressDeliveryWindow,
        expressResolutionDate,
        type,
      ),
    );
    i--;
    sectionCounter++;
  }
  return sections;
}

export function createDropShipRetalixItemsSection(
  materialNumbers: MaterialListRow[],
  routeDate: Date,
  poNumber: string,
): CartReviewSection {
  const headerKey = 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.NAME';
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Default,
    icon: warningIcon,
    materialNumbers,
    name: CartReviewSectionName.DropShipRetalixItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Warning,
    poNumber,
    routeDate,
    routeDateKey: 'CART_REVIEW.SUB_HEADER.DROP_SHIP_DATE',
    subHeaderKeys: [
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.RETURNS' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.PRICES' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SHIPPING' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.TRACKING' },
    ],
  };
}

export function createSpecialItemsSection(
  materialNumbers: MaterialListRow[],
  routeDate: Date,
  poNumber: string,
): CartReviewSection {
  const headerKey = 'CART_REVIEW.SPECIAL_ORDER.BANNER.NAME';
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Default,
    icon: warningIcon,
    materialNumbers,
    name: CartReviewSectionName.SpecialItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Warning,
    poNumber,
    routeDate,
    routeDateKey: 'CART_REVIEW.SUB_HEADER.SPECIAL_ORDER_DATE',
    subHeaderKeys: [
      {
        key: 'CART_REVIEW.SPECIAL_ORDER.BANNER.NOT_RETURNABLE_POINT',
      },
      { key: 'CART_REVIEW.SPECIAL_ORDER.BANNER.FINAL_PRICE_POINT' },
      { key: 'CART_REVIEW.SPECIAL_ORDER.BANNER.DEADLINE_ALERT_POINT' },
      {
        key: 'CART_REVIEW.SPECIAL_ORDER.BANNER.CHECK_STATUS_POINT',
        link: {
          key: 'CART_REVIEW.SPECIAL_ORDER.BANNER.CHECK_STATUS_LINK',
          route: '/orders',
          params: {
            activeTab: 'special-orders',
          },
        },
      },
    ],
  };
}

export function createStandardItemsSection(
  materialNumbers: MaterialListRow[],
  customerName: string,
  customerNumber: string,
  routeDate: Date,
  poNumber: string,
  headerKey = 'CART_REVIEW.REST_OF_YOUR_ORDER.BANNER.NAME',
): CartReviewSection {
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Success,
    icon: cartReviewSuccessIcon,
    materialNumbers,
    name: CartReviewSectionName.StandardItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Success,
    customerName,
    customerNumber,
    poNumber,
    routeDate,
  };
}

export function createStockWarningItemsSection(
  materialNumbers: MaterialListRow[],
  routeDate: Date,
  poNumber: string,
): CartReviewSection {
  const headerKey = 'CART_REVIEW.INVENTORY.BANNER.NAME';
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Default,
    icon: cartReviewExceptionIcon,
    materialNumbers,
    name: CartReviewSectionName.StockWarningItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Error,
    poNumber,
    routeDate,
    subHeaderKeys: [{ key: 'CART_REVIEW.INVENTORY.BANNER.LOW_STOCK' }],
  };
}

export function createUnavailableItemsSection(
  materialNumbers: MaterialListRow[],
): CartReviewSection {
  const headerKey = 'CART_REVIEW.UNAVAILABLE.BANNER.NAME';
  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Default,
    icon: cartReviewExceptionIcon,
    materialNumbers,
    name: CartReviewSectionName.UnavailableItemsSection,
    sectionCounter: 0,
    type: CartReviewSectionType.Error,
    subHeaderKeys: [{ key: 'CART_REVIEW.UNAVAILABLE.BANNER.SUB_HEADER' }],
  };
}

export function createFulfillmentSystemErrorSection(
  materialNumbers: MaterialListRow[],
): CartReviewSection {
  const headerKey = 'CART_REVIEW.FULFILLMENT_SYSTEM_ERROR_SECTION.BANNER.NAME';
  return {
    context: MaterialRowContext.ErrorProcessingItem,
    headerKey,
    headerKeyId: headerKey,
    headerType: CartReviewHeaderType.Default,
    icon: cartReviewExceptionIcon,
    materialNumbers,
    name: CartReviewSectionName.FulfillmentSystemErrorSection,
    alwaysShowError: true,
    sectionCounter: 0,
    type: CartReviewSectionType.Error,
    subHeaderKeys: [
      { key: 'CART_REVIEW.FULFILLMENT_SYSTEM_ERROR_SECTION.BANNER.SUB_HEADER' },
    ],
  };
}

// eslint-disable-next-line max-params
function createStoreItemsSection(
  fulfillmentType: FulfillmentType,
  poNumber: string,
  routeDate: Moment,
  sectionCounter: number,
  suggestedAvailabilityDateTime: Moment,
  materials: FulfillmentMaterial[],
  store: StoreRecord,
  pickupResolutionDate: Moment,
  originalExpressDate: ExpressDeliveryWindow,
  expressResolutionDate: ExpressDeliveryWindow,
  type: CartReviewSectionType = CartReviewSectionType.Success,
): CartReviewSection {
  let headerType = CartReviewHeaderType.StoreError;
  let icon = cartReviewExceptionIcon;
  if (CartReviewSectionType.Success === type) {
    headerType = CartReviewHeaderType.StoreSuccess;
    icon = cartReviewSuccessIcon;
  }

  let headerKey: string;
  let name: CartReviewSectionName;
  let originalPickupDate: Moment;
  if (FulfillmentType.EXPRESS === fulfillmentType) {
    headerKey = 'CART_REVIEW.STORE_EXPRESS_ITEMS.BANNER.NAME';
    name = CartReviewSectionName.ExpressItemsSection;
    originalPickupDate = undefined;
    pickupResolutionDate = undefined;
    store = undefined;
  } else {
    headerKey = 'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.NAME';
    name = CartReviewSectionName.PickupItemsSection;
    originalPickupDate = routeDate;
    originalExpressDate = undefined;
    expressResolutionDate = undefined;
  }

  return {
    context: MaterialRowContext.CartReview,
    headerKey,
    headerKeyId: headerKey,
    headerType,
    icon,
    materialNumbers: transformFulfillmentMaterialsToMaterialListRows(materials),
    name,
    sectionCounter,
    type,
    poNumber,
    store,
    routeDate,
    suggestedAvailabilityDateTime,
    originalPickupDate,
    pickupResolutionDate,
    originalExpressDate,
    expressResolutionDate,
  };
}

function createDropShipItemsSection(
  materialNumbers: MaterialListRow[],
  fulfillmentOrderSplit: FulfillmentOrderSplit,
  sites: Site[],
  sectionCounter: number,
  selectedSiteId: string,
  poNumber: string,
  isPoNumberRequired: boolean,
): CartReviewSection {
  const headerKey = 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.NAME';
  return {
    context: MaterialRowContext.NonEditableItem,
    headerKey,
    headerKeyId: headerKey + sectionCounter,
    headerType: CartReviewHeaderType.DropShip,
    icon: cartReviewExceptionIcon,
    materialNumbers,
    name: CartReviewSectionName.DropShipItemsSection,
    sectionCounter,
    type: CartReviewSectionType.Error,
    deliveryKey: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.DELIVERY',
    estimatedCostKey:
      'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.ESTIMATED_COST',
    fulfillmentOrderSplit,
    routeDateKey: 'CART_REVIEW.SUB_HEADER.DROP_SHIP_DATE',
    selectAddressKey:
      'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SELECT_ADDRESS',
    selectShippingKey:
      'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SELECT_SHIPPING',
    selectedSiteId,
    shippingCostKey: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SHIPPING_COST',
    shippingOptionsKey:
      'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SHIPPING_OPTIONS',
    sites,
    subHeaderKeys: [
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.RETURNS' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.PRICES' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SHIPPING' },
      { key: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.TRACKING' },
    ],
    subTotalKey: 'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.SUBTOTAL',
    poNumber,
    isPoNumberRequired,
  };
}
