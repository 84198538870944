export function isPrintEvent(
  event: KeyboardEvent,
  shouldStopDefault = true,
): boolean {
  let controlKey;
  if (navigator.platform.match('Mac')) {
    controlKey = event.metaKey;
  } else {
    controlKey = event.ctrlKey;
  }

  const isPrintKey = controlKey && event.key === 'p';
  if (isPrintKey && shouldStopDefault) {
    event.preventDefault();
    event.stopPropagation();
  }

  return isPrintKey;
}

export interface IKeyboardEvent {
  charCode: number;
  target: { tagName: string };
}
