import {
  defaultFilterEntityAdapter,
  defaultFilterGroupEntityAdapter,
  FilterGroupRecordState,
  initialDefaultFilterEntityState,
  initialDefaultFilterGroupEntityState,
  initialSearchState,
  SearchState,
} from './search.state';
import { EntityState } from '@ngrx/entity';
import {
  DefaultFilterGroupRecord,
  SearchResultRecord,
} from '../../services/search/models/search-record';
import { SearchConstants } from 'src/app/shared/models/search/SearchConstants';
import { createReducer, on } from '@ngrx/store';
import { SearchActions } from './search.actions';

export const searchReducer = createReducer(
  initialSearchState,
  on(
    SearchActions.clearSearchResults,
    (state, action): SearchState => clearSearchResults(state, action),
  ),
  on(
    SearchActions.newCatalogSearch,
    (state): SearchState => clearSearchState(state),
  ),
  on(SearchActions.newSearch, (state): SearchState => newSearch(state)),
  on(
    SearchActions.getSearchResults,
    (state): SearchState => getSearchResults(state),
  ),
  on(
    SearchActions.getSearchResultsSuccess,
    (state, action): SearchState => getSearchResultsSuccess(state, action),
  ),
  on(
    SearchActions.getSearchResultsError,
    (): SearchState => getSearchResultsError(),
  ),
  on(
    SearchActions.loadMoreSearchResults,
    (state): SearchState => loadMoreSearchResults(state),
  ),
  on(
    SearchActions.collapseDefaultFilter,
    (state, action): SearchState => collapseDefaultFilter(state, action),
  ),
  on(
    SearchActions.viewMoreDefaultFilter,
    (state, action): SearchState => viewMoreDefaultFilter(state, action),
  ),
  on(
    SearchActions.viewMoreTaxonomyFilter,
    (state, action): SearchState => viewMoreTaxonomyFilter(state, action),
  ),
  on(
    SearchActions.toggleOrderGuideFilter,
    (state): SearchState => genericToggle(state),
  ),
  on(
    SearchActions.toggleAvailableTodayFilter,
    (state, action): SearchState => toggleAvailableTodayFilter(state, action),
  ),
  on(
    SearchActions.toggleDefaultFilter,
    (state, action): SearchState => toggleDefaultFilter(state, action),
  ),
  on(
    SearchActions.clearAllFilters,
    (state): SearchState => clearAllFilters(state),
  ),
  on(
    SearchActions.toggleTaxonomyFilter,
    (state): SearchState => genericToggle(state),
  ),
  on(SearchActions.clearSearchState, (): SearchState => initialSearchState),
  on(
    SearchActions.submitSearch,
    SearchActions.searchMaterialClick,
    SearchActions.refreshCurrentSearch,
    (state): SearchState => state,
  ),
);

function clearSearchResults(
  state: SearchState,
  action: { isNewSearch: boolean },
): SearchState {
  return action.isNewSearch
    ? {
        ...initialSearchState,
        orderGuideFilter: state.orderGuideFilter,
        availableTodayFilter: state.availableTodayFilter,
      }
    : {
        ...state,
        currentOffset: state.currentOffset,
        resultSet: {
          ...state.resultSet,
          materialNumbers: [],
        },
      };
}

function clearSearchState(state: SearchState): SearchState {
  return {
    ...initialSearchState,
    orderGuideFilter: state.orderGuideFilter,
    availableTodayFilter: state.availableTodayFilter,
  };
}

function newSearch(state: SearchState): SearchState {
  return {
    ...initialSearchState,
    orderGuideFilter: state.orderGuideFilter,
    availableTodayFilter: state.availableTodayFilter,
    lastSearchText: state.lastSearchText,
  };
}

function getSearchResults(state: SearchState): SearchState {
  return {
    ...state,
    hasLoaded: false,
  };
}

function getSearchResultsError(): SearchState {
  return {
    ...initialSearchState,
    hasLoaded: true,
  };
}

function getSearchResultsSuccess(
  state: SearchState,
  action: {
    isSearch: boolean;
    searchResults: SearchResultRecord;
  },
): SearchState {
  const resultSet = { ...action.searchResults.resultSet };
  const recommendedProducts = Array.from(
    resultSet.sponsoredRecommendations?.productRecommendations.keys() || [],
  );

  // when loading more results we need to concat the materialNumbers from response
  const shouldConcat =
    state.resultSet &&
    ((action.searchResults.defaultFilters.length === 0 && action.isSearch) ||
      state.resultSet.materialNumbers.length > 0);

  if (shouldConcat) {
    resultSet.sponsoredRecommendations =
      state.resultSet?.sponsoredRecommendations;

    resultSet.materialNumbers = [
      ...new Set(
        state.resultSet.materialNumbers.concat(
          recommendedProducts,
          resultSet.materialNumbers,
        ),
      ),
    ];
  } else {
    resultSet.materialNumbers = combineRecommendationsAndSearch(
      recommendedProducts,
      resultSet.materialNumbers,
    );
  }

  const defaultFilters =
    action.searchResults.defaultFilters.length > 0
      ? updateDefaultRecordStates(
          state.defaultFilters,
          action.searchResults.defaultFilters,
        )
      : state.defaultFilters;

  // order guide filter is always dependent on the response

  const orderGuideFilter = action.searchResults.orderGuideFilter;
  const orderGuideFilterRecordState = orderGuideFilter
    ? {
        record: orderGuideFilter,
        isOrderGuideOptionSelected:
          orderGuideFilter.selectedId ===
          SearchConstants.orderGuideFilterOrderGuideId,
      }
    : state.orderGuideFilter;

  const availableTodayFilter = action.searchResults.availableTodayFilter
    ? action.searchResults.availableTodayFilter
    : state.availableTodayFilter;

  const taxonomyFilters = action.searchResults.taxonomyFilters
    ? {
        ...action.searchResults.taxonomyFilters,
        isViewingMore: false,
      }
    : state.taxonomyFilters;

  return {
    ...state,
    hasLoaded: true,
    resultSet,
    orderGuideFilter: orderGuideFilterRecordState,
    availableTodayFilter,
    defaultFilters,
    taxonomyFilters,
    searchText: action.searchResults.searchText,
    lastSearchText: action.searchResults.searchText
      ? action.searchResults.searchText.en
      : undefined,
    hideSearchText: action.searchResults.shouldHideSearchText,
  };
}

function loadMoreSearchResults(state: SearchState): SearchState {
  if (!!state.resultSet && state.resultSet.totalResults > state.currentOffset) {
    return {
      ...state,
      currentOffset: state.currentOffset + SearchConstants.searchOffsetCount,
    };
  } else {
    return state;
  }
}

function collapseDefaultFilter(
  state: SearchState,
  action: {
    filterGroupNameEN: string;
    isCollapsed: boolean;
  },
): SearchState {
  return {
    ...state,
    defaultFilters: defaultFilterGroupEntityAdapter.updateOne(
      {
        id: action.filterGroupNameEN,
        changes: {
          isCollapsed: action.isCollapsed,
        },
      },
      state.defaultFilters,
    ),
  };
}

function viewMoreDefaultFilter(
  state: SearchState,
  action: {
    filterGroupNameEN: string;
    isViewingMore: boolean;
  },
): SearchState {
  return {
    ...state,
    defaultFilters: defaultFilterGroupEntityAdapter.updateOne(
      {
        id: action.filterGroupNameEN,
        changes: {
          isViewingMore: action.isViewingMore,
        },
      },
      state.defaultFilters,
    ),
  };
}

function viewMoreTaxonomyFilter(
  state: SearchState,
  action: { isViewingMore: boolean },
): SearchState {
  return {
    ...state,
    taxonomyFilters: {
      ...state.taxonomyFilters,
      isViewingMore: action.isViewingMore,
    },
  };
}

function genericToggle(state: SearchState): SearchState {
  return {
    ...state,
    hasLoaded: false,
    currentOffset: 0,
    resultSet: {
      ...state.resultSet,
      materialNumbers: [],
    },
  };
}

function toggleAvailableTodayFilter(
  state: SearchState,
  action: { isToggled: boolean },
): SearchState {
  return {
    ...state,
    hasLoaded: false,
    currentOffset: 0,
    resultSet: {
      ...state.resultSet,
      materialNumbers: [],
    },
    availableTodayFilter: {
      ...state.availableTodayFilter,
      isEnabled: action.isToggled,
    },
  };
}

function toggleDefaultFilter(
  state: SearchState,
  action: {
    filterGroupNameEN: string;
    filterParam: string;
    queryParamValue: string;
    isToggled: boolean;
  },
): SearchState {
  const targetGroup = state.defaultFilters.entities[action.filterGroupNameEN];

  if (!targetGroup) {
    return state;
  }

  return {
    ...state,
    hasLoaded: false,
    currentOffset: 0,
    resultSet: {
      ...state.resultSet,
      materialNumbers: [],
    },
    defaultFilters: defaultFilterGroupEntityAdapter.updateOne(
      {
        id: action.filterGroupNameEN,
        changes: {
          filters: defaultFilterEntityAdapter.updateOne(
            {
              id: action.queryParamValue,
              changes: {
                isSelected: action.isToggled,
              },
            },
            targetGroup.filters,
          ),
        },
      },
      state.defaultFilters,
    ),
  };
}

function clearAllFilters(state: SearchState): SearchState {
  return {
    ...state,
    hasLoaded: false,
    currentOffset: 0,
    resultSet: {
      ...state.resultSet,
      materialNumbers: [],
    },
  };
}

function updateDefaultRecordStates(
  stateFilters: EntityState<FilterGroupRecordState>,
  updatingFilters: DefaultFilterGroupRecord[],
): EntityState<FilterGroupRecordState> {
  const stateFiltersIds = [...stateFilters.ids];

  // we need to keep the order of the response filters (it's ordered in the service)
  let orderedFilters: FilterGroupRecordState[] = updatingFilters.map(
    (filter) => {
      const filterIdIndex = stateFiltersIds.indexOf(filter.name.en);
      if (filterIdIndex !== -1) {
        stateFiltersIds.splice(filterIdIndex, 1);
        const filterInState = stateFilters.entities[filter.name.en];
        // keep state viewingMore and collapsed states for existing filters
        return recordToRecordState(
          filter,
          filterInState.isCollapsed,
          filterInState.isViewingMore,
        );
      } else {
        // default viewingMore and collapsed states for new filters
        return recordToRecordState(filter, false, false);
      }
    },
  );

  orderedFilters = orderedFilters.concat(
    stateFiltersIds.map((filterId) => {
      // we don't care about the child filters if it's not in the response
      return removeFilters(stateFilters.entities[filterId]);
    }),
  );

  return defaultFilterGroupEntityAdapter.setAll(
    orderedFilters,
    initialDefaultFilterGroupEntityState,
  );
}

function recordToRecordState(
  filter: DefaultFilterGroupRecord,
  isCollapsed: boolean,
  isViewingMore: boolean,
): FilterGroupRecordState {
  return {
    ...filter,
    isCollapsed,
    isViewingMore,
    filters: defaultFilterEntityAdapter.setAll(
      filter.filters,
      initialDefaultFilterEntityState,
    ),
  };
}

function removeFilters(filter: FilterGroupRecordState): FilterGroupRecordState {
  return {
    ...filter,
    filters: initialDefaultFilterEntityState,
  };
}

function combineRecommendationsAndSearch(
  recommendedMaterial: string[],
  searchMaterial: string[],
): string[] {
  return [...new Set([...recommendedMaterial, ...searchMaterial])];
}
