import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfflineModeState } from './offline-mode.state';

const selectOfflineModeFeature =
  createFeatureSelector<OfflineModeState>('offlineMode');

export const selectIsOffline = createSelector(
  selectOfflineModeFeature,
  (offlineModeState) => (offlineModeState ? offlineModeState.isOffline : false),
);

export const selectIsOnline = createSelector(
  selectOfflineModeFeature,
  (offlineModeState) => (offlineModeState ? !offlineModeState.isOffline : true),
);
