import { BannerAd } from '../../../store/banner-ads/banner-ads.state';
import { RecommendationBanner } from '../../../store/search/search.state';

export enum GAEventName {
  ActivateSavedCart = 'activate_saved_cart',
  AddToCart = 'add_to_cart',
  AddToCartConflict = 'add_to_cart_conflict',
  AddToWishlist = 'add_to_wishlist',
  BeginCheckout = 'begin_checkout',
  FilterApplied = 'filter_applied',
  Login = 'login',
  Logout = 'logout',
  MoveSavedCart = 'move_saved_cart',
  PageView = 'pageview',
  Purchase = 'purchase',
  PurchaseOutOfStock = 'purchase_out_of_stock',
  RemoveFromCart = 'remove_from_cart',
  SelectItem = 'select_item',
  SelectPromotion = 'select_promotion',
  SearchRequest = 'search_results',
  SelectShipDate = 'select_ship_date',
  SponsoredSearchResults = 'sponsored_search_results',
  SubmitCheckRequest = 'submit_check_request',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewModal = 'view_modal',
  ViewOutOfStock = 'view_out_of_stock',
  ViewPromotion = 'view_promotion',
}

export enum GAMessageType {
  ContentAnalytics = 'content_analytics',
}

export interface GAEvent {
  event: string;
  interaction: GAInteraction;
}

export interface GAPageViewEvent extends GAEvent {
  customer: {
    customer_unit: string;
    sap_plant_id: string;
    personalization_id: string;
    segment: number;
    sfmc_user_id: string;
  };
  user: {
    ga4_client_id: string;
    site_country: string;
    site_language: string;
    ga4_user_id: string;
    internal_user: boolean;
  };
  utm: {
    utm_campaign_id?: string;
    utm_promo_code?: string;
    utm_email_template_position: number;
  };
}

export interface GASavedCartEvent extends GAEvent {
  user: {
    authorized_purchaser: boolean;
  };
  link_text: string;
}

export interface GAFilterEvent extends GAEvent {
  filter: { filter_category: string; filter_option: string };
}

export interface GASelectShipDateEvent extends GAEvent {
  shipping: { ship_date: string };
}

export interface GAViewModalEvent extends GAEvent {
  modal: { modal_name: string };
}

export interface GASponsoredSearchResultsEvent extends GAEvent {
  sponsored: {
    sponsored_search_term: string;
    sponsored_banner: boolean;
    sponsored_item: boolean;
    sponsored_result_campaign?: string;
  };
}

export interface NoMaterialsGAPromotionEvent extends GAEvent {
  ecommerce: {
    items: never[];
    creative_slot?: string;
    creative_name?: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_code?: string;
    collection_name_event?: string;
    content_name_event?: string;
  };
}

export interface GAEcommerceEvent extends GAEvent {
  ecommerce: {
    items: GAEcommerceItem[];
    currency?: string;
    creative_slot?: string;
    creative_name?: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_code?: string;
    collection_name_event?: string;
    content_name_event?: string;
  };
}

export interface GACartEvent extends GAEvent {
  ecommerce: {
    items: GACartItem[];
    transaction_id: string;
    purchase_orders: number;
    coupons: string[];
    quantity: number;
    cart_discount?: number;
    value?: number;
    currency?: string;
    shipping?: number;
    ship_date?: string;
    order_submission_pending?: boolean;
  };
}

export interface GACartItemAdjustmentEvent extends GAEvent {
  ecommerce: {
    items: GACartItem[];
    value?: number;
    currency?: string;
    add_to_cart_type?: string;
  };
}

export interface GAViewItemListEvent extends GAEvent {
  ecommerce: {
    items: GACartItem[];
    total_items_list: number;
    value?: number;
    currency?: string;
    search_term?: string;
  };
}

export interface GACWishlistEvent extends GAEvent {
  guide: {
    guide_type: string;
  };
  ecommerce: {
    items: GACartItem[];
    value?: number;
    currency?: string;
  };
}

export interface GAEcommerceItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  price?: number;
  item_category?: string;
  sponsored_search_term?: string;
  sponsored_banner?: boolean;
  sponsored_item?: boolean;
  sponsored_result_campaign?: string;
  sponsored?: boolean;
  sponsored_index?: boolean;
  sponsored_add_to_cart?: boolean;
  item_list_name?: string;
  item_list_id?: string;
  rec_engine_name?: string;
  search_results_index?: number;
  search_term?: string;
  collection_name?: string;
  subheading?: string;
}

export interface GACartItem extends GAEcommerceItem {
  item_variant: string;
  quantity: number;
  index: number;
  stock_warning: string;
  discount?: number;
  coupon?: string;
}

export interface GAInteraction {
  event_timestamp: number;
  page_path?: string;
}

export interface GANoStockEvent extends GAEvent {
  products: {
    product_id: string;
    product_name?: string;
  };
}

export interface GANoStockPurchaseEvent extends GANoStockEvent {
  shipping: {
    ship_date: string;
  };
}

export interface GALoyaltyCheckRequestEvent extends GAEvent {
  check_request: {
    count: number;
  };
}

export interface GASearchRequestEvent extends GAEvent {
  search_term: string;
}

export interface GAAddToCartConflictEvent extends GAEvent {
  action: {
    modal_name: string;
    add_to_cart_type: string;
    index: number;
    action_id: number;
    cta_text: string;
  };
}

export interface PromotionEventData {
  bannerAds?: BannerAd[];
  recommendationBanner?: RecommendationBanner;
  materials?: string[];
}

export interface PromotionData {
  materialNumbers: string[];
  creativeSlot?: string;
  creativeName?: string;
  promotionId?: string;
  promotionName?: string;
  promotionCode?: string;
  promotionType?: PromotionType;
  promotionTypeValue?: string;
  sponsoredSearchTerm?: string;
  sponsoredBanner?: boolean;
  sponsoredItem?: boolean;
}

export enum PromotionType {
  Collections = '/collections',
  Site = '/site',
}

export interface AuxiliaryAnalyticsData {
  collectionName?: string;
  itemSubheadingMap?: Map<string, string>;
  recommendationEngineName?: string;
  searchResultsIndex?: number;
  searchText?: string;
}
