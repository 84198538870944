<div #dateFocus class="calendar-width" (keydown)="dateFocusKeydown($event)">
  <mat-calendar
    #calendar
    [dateClass]="dateClass"
    [selected]="calendarSelectedDate"
    [startAt]="selectedDate"
    [dateFilter]="dateFilter"
    [minDate]="calendarMinDate"
    [maxDate]="calendarMaxDate"
    (selectedChange)="selectDate($event)"
  >
  </mat-calendar>
</div>
