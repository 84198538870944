import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialPriceRecord } from '../../services/material-price/models/material-price-record';

export interface MaterialPriceState {
  prices: EntityState<MaterialPriceRecordState>;
  watchedMaterialNumbers: string[];
  invalidCoupons: string[];
  currency?: string;
}

export interface MaterialPriceRecordState {
  materialNumber: string;
  status: MaterialPriceRecordStatus;
  record?: MaterialPriceRecord;
}

export enum MaterialPriceRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const materialPriceRecordEntityAdapter =
  createEntityAdapter<MaterialPriceRecordState>({
    selectId: (materialPrice) => materialPrice.materialNumber,
  });

export const materialPriceInitialState: MaterialPriceState = {
  prices: materialPriceRecordEntityAdapter.getInitialState(),
  watchedMaterialNumbers: [],
  invalidCoupons: [],
};
