<naoo-modal-header
  class="title"
  [title]="'DATE_PICKER_HEADER_TITLE' | deliveryOrShip | translate"
  [showCloseButton]="isCloseable"
  [stickyHeader]="true"
  (closeModalEmitter)="handleClosePicker()"
></naoo-modal-header>
<ng-container *ngIf="isDoneLoading">
  <div
    *ngIf="
      deliveryDatePicker.content.isRouteDateExpired ||
      deliveryDatePicker.content.isPastCutoff
    "
    class="delivery-date-expired-text"
  >
    {{ 'DATE_PICKER_EXPIRED' | deliveryOrShip | translate }}
  </div>
  <div class="modal-body">
    <naoo-delivery-date-picker-calendar
      [routeDateContent]="deliveryDatePicker.content"
      (routeDateSelected)="handleRouteDateSelected($event)"
      (shiftFocus)="handleDatePickerShiftFocus($event)"
    ></naoo-delivery-date-picker-calendar>
    <div class="divider"></div>
    <naoo-delivery-date-picker-details
      [routeDateContent]="deliveryDatePicker.content"
      [selectedAvailableRouteDate]="selectedAvailableRouteDate"
      (saveRouteDate)="handleSaveRouteDate($event)"
      (justBrowsing)="handleJustBrowsing()"
    ></naoo-delivery-date-picker-details>
  </div>
  <div class="mobile-footer">
    <button
      #mobileSaveButton
      class="mobile-save-date-button"
      (click)="handleSaveRouteDate(calculatedAvailableRouteDate.routeDate)"
    >
      {{
        (isJustBrowsing ? 'DELIVERY_DATE_PICKER.START_MY_ORDER' : 'SHARED.SAVE')
          | translate
          | uppercase
      }}
    </button>
    <button
      *ngIf="isJustBrowsing"
      class="mobile-just-browsing-button"
      (click)="handleJustBrowsing()"
    >
      {{ 'DELIVERY_DATE_PICKER.JUST_BROWSING' | translate | uppercase }}
    </button>
  </div>
</ng-container>
