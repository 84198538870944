import { Injectable } from '@angular/core';
import { MaterialRecommendationsService } from '../../../shared/services/material-recommendations/material-recommendations.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { mergeMap, Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { MaterialRecommendationsActions } from './material-recommendations.actions';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  MaterialRecommendationsRequestStatus,
  Page,
} from './material-recommendations.state';
import { SharedActions } from '../shared/shared.actions';
import { selectMaterialRecommendationsState } from './material-recommendations.selector';
import { selectMaterialNumberFromRouteParams } from '../router/router.selectors';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import { LastOrderedActions } from '../last-ordered/last-ordered.actions';

@Injectable()
export class MaterialRecommendationsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private materialRecommendationsService: MaterialRecommendationsService,
  ) {}

  loadMaterialRecommendations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRecommendationsActions.loadMaterialRecommendations),
      concatLatestFrom(() =>
        this.store.select(selectMaterialRecommendationsState),
      ),
      map(([action, materialRecommendationState]) => {
        if (
          MaterialRecommendationsRequestStatus.Queued !==
          materialRecommendationState.entities[action.page]?.status
        ) {
          return SharedActions.noOperation('Page is not in queued status');
        }
        return MaterialRecommendationsActions.getMaterialRecommendations(
          action.page,
          action.materialNumber,
        );
      }),
    );
  });

  getMaterialRecommendations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRecommendationsActions.getMaterialRecommendations),
      mergeMap((action) => {
        return this.materialRecommendationsService
          .getMaterialRecommendations(action.page, action.materialNumber)
          .pipe(
            mergeMap((materialRecommendations) => {
              const filteredRecommendation = materialRecommendations.filter(
                (recommendedMaterial: MaterialRecommendations) =>
                  !!recommendedMaterial.title &&
                  recommendedMaterial.materialListRows.length > 0,
              );

              const loadRecommendations =
                MaterialRecommendationsActions.getMaterialRecommendationsSuccess(
                  action.page,
                  filteredRecommendation,
                );
              const materialNumbers = this.getMaterialNumbers(
                filteredRecommendation,
                action.materialNumber,
              );
              return [
                loadRecommendations,
                MaterialAvailabilityActions.loadMaterialAvailability(
                  materialNumbers,
                ),
                MaterialInfoActions.loadMaterialInfo(materialNumbers),
                MaterialPriceActions.loadMaterialPrices(materialNumbers),
                LastOrderedActions.loadLastOrdered(materialNumbers),
              ];
            }),
            catchError(() => {
              return of(
                MaterialRecommendationsActions.getMaterialRecommendationsError(
                  action.page,
                ),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  MaterialRecommendationsActions.refreshMaterialRecommendations,
                  MaterialRecommendationsActions.clearMaterialRecommendations,
                ),
              ),
            ),
          );
      }),
    );
  });

  refreshMaterialRecommendations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRecommendationsActions.refreshMaterialRecommendations),
      concatLatestFrom(() =>
        this.store.select(selectMaterialNumberFromRouteParams),
      ),
      mergeMap(([_, materialNumber]) => {
        const homepage =
          MaterialRecommendationsActions.loadMaterialRecommendations(
            Page.HOMEPAGE,
          );
        if (materialNumber) {
          return [
            homepage,
            MaterialRecommendationsActions.loadMaterialRecommendations(
              Page.PDP,
              materialNumber,
            ),
          ];
        }
        return [homepage];
      }),
    );
  });

  private getMaterialNumbers(
    materialRecommendations: MaterialRecommendations[],
    materialNumber?: string,
  ): string[] {
    const materialNumbers: string[] = materialNumber ? [materialNumber] : [];
    materialRecommendations.forEach((item) => {
      materialNumbers.push(
        ...item.materialListRows.map((material) => material.value as string),
      );
    });
    return materialNumbers;
  }
}
