import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerMaterialActions } from './customer-material.actions';
import { Observable } from 'rxjs';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import {
  selectCustomerMaterialInfo,
  selectCustomerMaterialRecords,
  selectCustomerMaterialRecord,
  selectHasCustomerMaterial,
} from './customer-material.selector';
import { CustomerMaterialInfo } from '../../services/customer-material/model/customer-material-info.model';

@Injectable({ providedIn: 'root' })
export class CustomerMaterialFacade {
  constructor(private store: Store) {}

  refreshCustomerMaterial(): void {
    this.store.dispatch(CustomerMaterialActions.refreshCustomerMaterialCodes());
  }

  getLoadedCustomerMaterialInfo(
    materialNumber: string,
  ): Observable<CustomerMaterialInfo> {
    return this.store.select(selectCustomerMaterialInfo(materialNumber));
  }

  hasCustomerMaterial(): Observable<boolean> {
    return this.store.select(selectHasCustomerMaterial);
  }

  getLoadedCustomerMaterialRecord(): Observable<CustomerMaterialRecord> {
    return this.store.select(selectCustomerMaterialRecord);
  }

  getLoadedCustomerMaterial(
    materialNumbers: string[],
  ): Observable<CustomerMaterialRecord> {
    return this.store.select(selectCustomerMaterialRecords(materialNumbers));
  }
}
