import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeHtmlEntity',
})
export class DecodeHtmlEntityPipe implements PipeTransform {
  transform(input: string): string {
    if (input) {
      return new DOMParser().parseFromString(input, 'text/html').documentElement
        .textContent;
    }

    return input;
  }
}
