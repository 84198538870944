import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OrderMethodWidgetViewModel } from './order-method-widget-view-model';
import { selectOrderMethodWidgetViewModel } from './order-method-widget.selectors';

@Injectable({ providedIn: 'root' })
export class OrderMethodWidgetFacade {
  constructor(private store: Store) {}

  getViewModel(): Observable<OrderMethodWidgetViewModel> {
    return this.store.select(selectOrderMethodWidgetViewModel);
  }
}
