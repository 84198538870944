import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectSalesCriticalItemsWithExceededHardStopQuantity } from './sales-critical-items.selector';
import { SalesCriticalItem } from '../../services/sales-critical-items/model/sales-critical-items';
import { SalesCriticalItemsActions } from './sales-critical-items.actions';

@Injectable({ providedIn: 'root' })
export class SalesCriticalItemsFacade {
  constructor(private store: Store) {}

  getSalesCriticalItemsWithExceededHardStopQuantity(): Observable<
    SalesCriticalItem[]
  > {
    return this.store.select(
      selectSalesCriticalItemsWithExceededHardStopQuantity,
    );
  }

  refreshSalesCriticalItems(): void {
    this.store.dispatch(SalesCriticalItemsActions.refreshSalesCriticalItems());
  }
}
