import { SortByType } from '../../../guides/shared/guides';
import { OrderGuideRecord } from '../../services/order-guide/models/order-guide-record';
import { createActionGroup, emptyProps } from '@ngrx/store';
import { OrderGuideUpdateRecord } from '../../services/order-guide/models/order-guide-update-record';

export const OrderGuideActions = createActionGroup({
  source: 'OrderGuide',
  events: {
    'Get Order Guide': emptyProps(),
    'Get Order Guide Success': (payload: OrderGuideRecord) => ({ payload }),
    'Refresh Order Guide': emptyProps(),
    'Update Order Guide Sort By': (payload: SortByType) => ({ payload }),
    'Update Order Guide Search Text': (searchText: string) => ({ searchText }),
    'Add Item To Order Guide': (
      materialNumber: string,
      categoryName: string,
    ) => ({ materialNumber, categoryName }),
    'Remove Item From Order Guide': (materialNumber: string) => ({
      materialNumber,
    }),
    'Add Item To Order Guide Success': (
      materialNumber: string,
      categoryName: string,
      categoryId: string,
    ) => ({ materialNumber, categoryName, categoryId }),
    'Remove Item From Order Guide Success': (materialNumber: string) => ({
      materialNumber,
    }),

    // Update Actions
    'Update Order Guide': emptyProps(),
    'Update Order Guide Success': (payload: OrderGuideUpdateRecord) => ({
      payload,
    }),
    'Navigate To Order Guide': emptyProps(),
    'Update Order Guide Error': (error: unknown) => ({ error }),
    'Update Order Guide Unsaved Changes': (hasUnsavedChanges: boolean) => ({
      hasUnsavedChanges,
    }),

    // Category Actions
    'Create Order Guide Category': (categoryName: string) => ({ categoryName }),
    'Rename Order Guide Category': (
      categoryIndex: number,
      categoryName: string,
    ) => ({ categoryIndex, categoryName }),
    'Remove Order Guide Category': (categoryIndex: number) => ({
      categoryIndex,
    }),
    'Move Order Guide Category': (
      originalCategoryIndex: number,
      newCategoryIndex: number,
    ) => ({ originalCategoryIndex, newCategoryIndex }),

    // Material Actions
    'Add Order Guide Materials': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Add Order Guide Materials Success': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Remove Order Guide Materials In Local': (materialNumber: string) => ({
      materialNumber,
    }),
    'Move Order Guide Materials': (
      materialNumber: string,
      newCategoryIndex: number,
      newMaterialIndex: number,
    ) => ({ materialNumber, newCategoryIndex, newMaterialIndex }),
    'Remove Empty Categories': emptyProps(),
  },
});
