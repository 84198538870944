<button
  [disabled]="isDisabled"
  (click)="didClick.emit()"
  [ngClass]="{ selected: isSelected }"
  mat-button
>
  <mat-icon
    [svgIcon]="isSelected ? 'rounded_corner_selected' : 'rounded_corner'"
    class="corner-icon"
  ></mat-icon>
  <div class="option-group">
    <mat-icon
      [svgIcon]="icon"
      class="option-icon single-pickup-icon"
    ></mat-icon>
    {{ text | translate }}
  </div>
</button>
