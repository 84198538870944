import { createEntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { StoreRecord } from '../../services/store/model/store-record';

export interface StoreState {
  records: EntityState<StoreRecord>;
  status?: StoreStateStatus;
}

export enum StoreStateStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const storeStateAdapter = createEntityAdapter<StoreRecord>({
  selectId: (store) => store.storePlantId,
});

export const storeInitialState: StoreState = {
  records: storeStateAdapter.getInitialState(),
  status: undefined,
};
