<button
  class="jump-button previous-jump-button"
  (click)="jumpToCategory(prevCategoryIndex)"
  [disabled]="categoryCount < 1"
>
  {{ 'ORDER_GUIDE.JUMP_BUTTON.PREV_CATEGORY' | translate }}
  <mat-icon svgIcon="arrow-v2"></mat-icon>
</button>

<button
  class="jump-button next-jump-button"
  (click)="jumpToCategory(nextCategoryIndex)"
  [disabled]="!nextCategoryIndex"
>
  {{ 'ORDER_GUIDE.JUMP_BUTTON.NEXT_CATEGORY' | translate }}
  <mat-icon svgIcon="arrow-v2"></mat-icon>
</button>
