import { IndicatorListsRecord } from '../../services/indicator-lists/models/indicator-lists-record';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const IndicatorListsActions = createActionGroup({
  source: 'Indicator List',
  events: {
    'Get All Indicator Lists': emptyProps(),
    'Get All Indicator Lists Success': (
      indicatorLists: IndicatorListsRecord,
    ) => ({ indicatorLists }),
    'Refresh Indicator Lists': emptyProps(),
    'Get All Indicator List Failure': emptyProps(),
  },
});
