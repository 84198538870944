<div class="price-placeholder"></div>

<div class="quantity-container">
  <div class="par-container">
    <div class="par-header">
      {{ 'CART.HEADER.PAR' | translate }}
    </div>

    <div class="inventory-header">
      {{ 'CART.HEADER.INVENTORY' | translate }}
    </div>
  </div>

  <div class="cart-quantity-header">
    {{ 'CART.HEADER.QTY' | translate }}
  </div>
</div>

<div class="total-header">
  {{ 'CART.HEADER.TOTAL' | translate }}
</div>
