import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { NaooConstants } from '../NaooConstants';
import { NAOOErrorCode, NaooErrorUtils } from '../error-handler/NaooErrorUtils';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NaooScheduledMaintenanceInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && this.isMigrationError(err)) {
          this.router.navigateByUrl(NaooConstants.SCHEDULED_MAINTENANCE_PATH);
          return EMPTY;
        }
        return throwError(err);
      }),
    );
  }

  private isMigrationError(err: HttpErrorResponse) {
    return (
      NaooErrorUtils.getNaooError(err).code ===
      NAOOErrorCode.SCHEDULED_MAINTENANCE
    );
  }
}
