import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { MenuPositionY } from '@angular/material/menu';
import { DefaultDialogService } from 'src/app/shared/services/dialog/default-dialog/default-dialog.service';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { AddToGuideMenuComponent } from 'src/app/shared/add-to-guide-menu/add-to-guide-menu.component';
import {
  MenuAction,
  MenuActionInfo,
} from 'src/app/product-row/product-menu/menu-action';
import { CriticalItemsFacade } from '../../../../core/store/critical-items/critical-items.facade';
import { DOCUMENT } from '@angular/common';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../core/store/material-row/models/material-row';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { MatMenuUtilities } from '../../../../shared/utilities/mat-menu-utilities';
import { CustomGuideFacade } from '../../../../core/store/custom-guide/custom-guide.facade';
import { CustomDialogService } from '../../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { NaooAnalyticsManager } from '../../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../../shared/analytics/analytics-event-info';
import {
  CustomGuideCreationSourceAnalyticLabel,
  ListsAnalyticsConstants,
} from '../../../../lists/lists-analytics.constants';
import { OrderGuideFacade } from '../../../../core/store/order-guide/order-guide.facade';

@Component({
  selector: 'naoo-material-options-menu',
  templateUrl: './material-options-menu.component.html',
  styleUrls: ['./material-options-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialOptionsMenuComponent {
  // eslint-disable-next-line max-params
  constructor(
    private customDialogService: CustomDialogService,
    private customGuideFacade: CustomGuideFacade,
    private dialogService: DefaultDialogService,
    private cartFacade: CartFacade,
    private criticalItemsFacade: CriticalItemsFacade,
    private searchFacade: SearchFacade,
    private orderGuideFacade: OrderGuideFacade,
    private analytics: NaooAnalyticsManager,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  @HostBinding('class.vertical-triple-dot') get list() {
    return this.verticalTripleDot;
  }

  @ViewChild('addToGuideMenu', { static: true })
  addToGuideMenu: AddToGuideMenuComponent;
  @ViewChild('matMenuButton', { static: true, read: ElementRef })
  menuButton: ElementRef;

  @Input() set materialRowOptions(sharedOptions: MaterialRowSharedOptions) {
    this._materialRowOptions = sharedOptions;
    this.updateAdditionalMenuItems(sharedOptions.isOffline);
  }

  get materialRowOptions(): MaterialRowSharedOptions {
    return this._materialRowOptions;
  }

  @Input() isMobile: boolean;

  additionalMenuItems: MenuActionInfo[] = [];
  openDirection: MenuPositionY = 'below';

  private _materialRowOptions: MaterialRowSharedOptions;

  get verticalTripleDot(): boolean {
    const currentStyle = this.materialRowOptions.materialListStyle;
    return (
      currentStyle === MaterialListStyle.Grid ||
      currentStyle === MaterialListStyle.SlimGrid ||
      MaterialRowContext.ProductDetails === this.materialRowOptions.context
    );
  }

  get isCriticalItemsGuide(): boolean {
    return this.isCriticalItemContext();
  }

  performMenuAction(subMenuItem: MenuActionInfo) {
    switch (subMenuItem.action) {
      case MenuAction.AddToExistingCustomGuide:
        this.addMaterialToGuide(subMenuItem.id);
        break;
      case MenuAction.AddToNewCustomGuide:
        this.openCreateCustomGuideModal();
        break;
      case MenuAction.RemoveItemFromCart:
        this.removeFromCart();
        break;
      case MenuAction.RemoveItemFromCustomGuide:
        this.openRemoveModal();
        break;
      case MenuAction.AddToCriticalItemsGuide:
        this.criticalItemsFacade.addCriticalItem(
          this.materialRowOptions.materialNumber,
        );
        break;
      case MenuAction.RemoveItemFromCriticalItemGuide:
        this.openRemoveModal();
        break;
      case MenuAction.AddToOrderGuide:
        this.orderGuideFacade.addItemToOrderGuide(
          this.materialRowOptions.materialNumber,
        );
        break;
      case MenuAction.RemoveFromOrderGuide:
        this.orderGuideFacade.removeItemFromOrderGuide(
          this.materialRowOptions.materialNumber,
        );
        break;
    }

    if (this.materialRowOptions.context === MaterialRowContext.Browse) {
      this.searchFacade.searchMaterialClick(
        this.materialRowOptions.materialNumber,
      );
    }
  }

  determineOpenDirection() {
    this.openDirection = MatMenuUtilities.determineMenuOpenDirection(
      this._document.documentElement,
      this.menuButton.nativeElement,
      MatMenuUtilities.addToCustomGuideMenuMaxHeight,
    );
    this.changeDetectorRef.markForCheck();
  }

  private updateAdditionalMenuItems(isOffline: boolean) {
    switch (this.materialRowOptions.context) {
      case MaterialRowContext.CartSummary:
        this.additionalMenuItems = [
          {
            name: 'CART.REMOVE_ITEM_FROM_CART',
            action: MenuAction.RemoveItemFromCart,
          },
        ];
        break;
      case MaterialRowContext.CustomGuide:
        this.additionalMenuItems = [
          {
            name: 'LISTS.REMOVE_PRODUCT_FROM_GUIDE',
            action: MenuAction.RemoveItemFromCustomGuide,
            disabled: isOffline,
          },
        ];
        break;
      default:
        this.additionalMenuItems = [];
    }

    this.changeDetectorRef.markForCheck();
  }

  private openCreateCustomGuideModal() {
    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.MaterialMenu,
      [this.materialRowOptions.materialNumber],
    );
  }

  private openRemoveModal() {
    this.dialogService.twoButtonModal(
      'remove-product-from-guide',
      'LISTS.REMOVE_PRODUCT_MODAL.TITLE',
      'LISTS.REMOVE_PRODUCT_MODAL.REMOVE',
      'CANCEL.TEXT',
      () => {
        this.removeMaterialFromGuide();
      },
      () => {},
      true,
      true,
      null,
    );
  }

  private removeFromCart(): void {
    const { analytics, materialNumber } = this.materialRowOptions;

    this.cartFacade.deleteCartMaterial(
      materialNumber,
      analytics?.recommendationEngineName,
    );
  }

  private isCriticalItemContext(): boolean {
    return (
      this.materialRowOptions.context ===
        MaterialRowContext.CriticalItemsGuide ||
      this.materialRowOptions.context === MaterialRowContext.CriticalItem
    );
  }

  private removeMaterialFromGuide() {
    const { context, customGuideId, materialNumber } = this.materialRowOptions;

    if (this.isCriticalItemContext()) {
      this.criticalItemsFacade.deleteCriticalItem(materialNumber);
    } else if (context === MaterialRowContext.CustomGuide) {
      this.customGuideFacade.removeCustomGuideMaterials(customGuideId, [
        materialNumber,
      ]);
    }
  }

  private addMaterialToGuide(customGuideId: string) {
    this.customGuideFacade.addCustomGuideMaterials(customGuideId, [
      this.materialRowOptions.materialNumber,
    ]);
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'add to guide',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
