<naoo-modal-header
  mat-dialog-title
  [title]="title | translate"
  (closeModalEmitter)="closeModal(true)"
  [showCloseButton]="true"
></naoo-modal-header>
<form [formGroup]="exportFormGroup" (ngSubmit)="submitSelection()">
  <mat-dialog-content>
    <div
      *ngIf="isWarningMessageVisible"
      [@displayWarning]="warningAnimationState"
      (@displayWarning.done)="animationComplete()"
      class="warning-banner"
    >
      <mat-icon svgIcon="warning-icon" class="warning-image"></mat-icon>
      <div class="warning-message">
        {{ warningMessage | translate }}
      </div>
    </div>
    <div class="modal-container">
      <div class="content-container" *ngIf="!isPrintModal">
        <div class="title">
          {{ 'EXPORT_MODAL.FILE_FORMAT' | translate }}
        </div>
        <div class="format-selector">
          <mat-radio-group [formControl]="fileFormat">
            <mat-radio-button
              *ngFor="let format of possibleFileFormats"
              name="{{ format.name }}"
              value="{{ format.value }}"
              checked="{{ format.isChecked }}"
            >
              {{ format.i18nTag | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="content-container">
        <div class="title">
          {{
            (isPrintModal
              ? 'EXPORT_MODAL.DATA_TO_PRINT'
              : 'EXPORT_MODAL.DATA_TO_EXPORT'
            ) | translate
          }}
        </div>
        <div class="subtitle">
          {{
            (isPrintModal
              ? 'EXPORT_MODAL.PRINT_DESCRIPTION'
              : 'EXPORT_MODAL.EXPORT_DESCRIPTION'
            ) | translate
          }}
        </div>
        <div class="table-data">
          <naoo-export-available-data
            class="table-column"
            [isPrintModal]="isPrintModal"
            [unselectedDataPoints]="unselected"
            [totalDataPoints]="data.dataPoints.length"
            (selectedItems)="updateSelection($event)"
            [highlighted]="selected.length === 0"
          >
          </naoo-export-available-data>
          <naoo-export-selected-data
            class="table-column"
            [isPrintModal]="isPrintModal"
            [selectedDataPoints]="selected"
            [totalDataPoints]="data.dataPoints.length"
            (removedItems)="updateSelection($event)"
            (updatedOrderItems)="updateOrder($event)"
          >
          </naoo-export-selected-data>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="modal-footer">
    <button
      mat-button
      type="button"
      class="cancel-button"
      (click)="closeModal(true)"
    >
      {{ 'EXPORT_MODAL.CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="export-button"
      type="submit"
      [disabled]="(isOffline$ | async) || isButtonDisabled"
    >
      {{
        (isPrintModal ? 'EXPORT_MODAL.PRINT' : 'EXPORT_MODAL.EXPORT')
          | translate
      }}
    </button>
  </div>
</form>
