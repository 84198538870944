<naoo-material-category-jump-buttons
  *ngIf="isMobile && !isFirst"
  [nextCategoryIndex]="categoryHeader.nextVirtualScrollIndex"
  [prevCategoryIndex]="categoryHeader.previousVirtualScrollIndex"
  [categoryCount]="categoryHeader.count"
  (categoryJump)="jumpToCategory($event)"
></naoo-material-category-jump-buttons>

<div class="category-header" [id]="categoryHeader.name.en" tabindex="0">
  {{ categoryHeader.name | stringdefaulter }} ({{ categoryHeader.count }})
</div>

<naoo-material-list-header
  *ngIf="list && !mobile"
  [listStyle]="listStyle"
  [context]="context"
  [isParEnabled]="isParEnabled"
  [isLoyaltyProgramEligible]="isLoyaltyProgramEligible"
>
</naoo-material-list-header>
