import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NaooLangHttpInterceptor } from './naoo-lang-interceptor';
import { NaooScheduledMaintenanceInterceptor } from './naoo-scheduled-maintenance-interceptor';
import { NaooSecurityInterceptor } from './naoo-security-interceptor';
import { WiremockOnlyInterceptor } from './naoo-wiremock-interceptor';
import { NaooLogRocketInterceptor } from './logrocket-interceptor';
import { NaooInvalidSessionInterceptor } from './naoo-invalid-session-interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WiremockOnlyInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NaooLangHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NaooScheduledMaintenanceInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NaooInvalidSessionInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NaooSecurityInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NaooLogRocketInterceptor,
    multi: true,
  },
];
