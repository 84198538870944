import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { CartService } from '../../services/cart/cart.service';
import {
  audit,
  catchError,
  concatMap,
  debounceTime,
  delayWhen,
  filter,
  first,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import { ErrorActions } from '../error/error.actions';
import { CartActions, CartQuantityUpdate } from './cart.actions';
import {
  CartEntityState,
  cartLineAdapter,
  cartMaterialAdapter,
  CartMaterialState,
  CartUpdateType,
  FocusedMaterialState,
  initialCartLineState,
  initialCartMaterialState,
} from './cart.state';
import {
  CartRecord,
  CartUpdateRequest,
  FulfillmentType,
} from '../../services/cart/models/cart-record';
import {
  selectCartEntity,
  selectCartFeature,
  selectCartMaterialEntities,
  selectFocusedMaterial,
  selectHasPendingFulfillmentChange,
  selectMaterialNumbersToRefresh,
  selectStoreFulfillment,
} from './cart.selectors';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../../shared/error-handler/NaooErrorUtils';
import { ModalActions } from '../modal/modal.actions';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MaterialCutoffActions } from '../material-cutoff/material-cutoff.actions';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import {
  ChangedFieldType,
  MaterialWarningActions,
} from '../material-warning/material-warning.actions';
import {
  selectCurrentSystem,
  selectSession,
} from '../session/session.selectors';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { filterNonEmptyCartLines } from '../../../shared/utilities/cart-material-utilities';
import { isEqual } from 'lodash-es';
import {
  formatDate,
  parseDate,
  SapDateTimeFormat,
} from '../../../shared/utilities/date-utilities';
import { selectIsOnline } from '../offline-mode/offline-mode.selectors';
import { CartOfflineStorageService } from '../../../shared/services/cart-offline-storage/cart-offline-storage.service';
import { NaooError } from '../../../shared/models/naoo-error';
import { CartRollupTransformer } from './shared/cart-rollup-transformer';
import { EcommerceAnalyticsFacade } from '../ecommerce-analytics/ecommerce-analytics.facade';
import * as LogRocket from 'logrocket';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { EntitlementActions } from '../entitlement/entitlement.actions';
import { CartReviewActions } from '../cart-review/cart-review.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { selectAllStoreRecordStateEntities } from '../store/store.selectors';
import { getPickupTime } from '../store/store.utilities';
import { CustomerPreferencesActions } from '../customer-preferences/customer-preferences.actions';
import { MaterialAdditionalInfoActions } from '../material-additional-info/material-additional-info.actions';
import { MaterialValidationService } from '../../../shared/services/material-validation/material-validation.service';
import { CatalogService } from '../../../shared/services/catalog/catalog.service';
import {
  selectFulfillmentDataLoaded,
  selectRequiresFulfillmentChange,
} from '../order-method-modal/order-method-modal-fulfillment.selectors';
import { FulfillmentModalService } from '../../../shared/services/fulfillment-modal/fulfillment-modal.service';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { findLargestSellableUom } from '../../../shared/utilities/material-units-utilities';
import { InventoryAvailabilityActions } from '../inventory-availability/inventory-availability.actions';
import { SalesCriticalItemsActions } from '../sales-critical-items/sales-critical-items.actions';
import { SearchActions } from '../search/search.actions';
import { SharedActions } from '../shared/shared.actions';
import { OpenOrderItemsActions } from '../open-order-items/open-order-items.actions';
import { OpenSpecialOrderItemsActions } from '../open-special-order-items/open-special-order-items.actions';
import { CurrentSystem } from '../../services/session/models/session-record';
import { StorePurchaseDetailsActions } from '../store-purchase-details/store-purchase-details.actions';
import { EcommerceAnalyticsActions } from '../ecommerce-analytics/ecommerce-analytics.actions';
import moment from 'moment';
import { MaterialRecommendationsActions } from '../material-recommendations/material-recommendations.actions';
import { selectCurrentUrl } from '../router/router.selectors';
import { OfflineModeActions } from '../offline-mode/offline-mode.actions';
import { OfflineModeService } from '../../services/offline-mode/offline-mode.service';

@Injectable()
export class CartEffects {
  // eslint-disable-next-line max-params
  constructor(
    private actions$: Actions,
    private store: Store,
    private cartService: CartService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private cartOfflineStorage: CartOfflineStorageService,
    private toastMessageService: ToastMessageService,
    private materialValidationService: MaterialValidationService,
    private catalogService: CatalogService,
    private fulfillmentModalService: FulfillmentModalService,
    private offlineModeService: OfflineModeService,
  ) {}

  updateCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateCart),
      concatLatestFrom(() => [
        this.store.select(selectHasPendingFulfillmentChange),
        this.store.select(selectIsOnline),
      ]),
      concatMap(([action, hasPendingFulfillmentChange, isOnline]) => {
        if (!isOnline) {
          return [CartActions.updateCartOffline()];
        }
        const dispatchedActions: Action[] = [];
        if (hasPendingFulfillmentChange) {
          dispatchedActions.push(
            ...this.getFulfillmentDataChangedClearActions(),
          );
          this.catalogService.clearCache();
        }
        dispatchedActions.push(
          CartActions.updateCartOnline(
            action.cartUpdateRequest,
            action.updateType,
            hasPendingFulfillmentChange,
          ),
        );
        return dispatchedActions;
      }),
    );
  });

  updateCartOnline$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateCartOnline),
      concatLatestFrom(() => [
        this.store.select(selectCartEntity),
        this.store.select(selectSession),
        this.store.select(selectCurrentSystem),
        this.store.select(selectMaterialNumbersToRefresh),
      ]),
      tap(([action]) => {
        if (
          action.cartUpdateRequest.materials &&
          action.cartUpdateRequest.materials.length > 0
        ) {
          this.ecommerceAnalyticsFacade.trackCartUpdateEvent(
            action.cartUpdateRequest,
          );
        }
      }),
      concatMap(
        ([
          action,
          cartEntity,
          sessionRecord,
          currentSystem,
          materialNumberToRefresh,
        ]) => {
          return this.cartService
            .updateCart(cartEntity.id, action.cartUpdateRequest)
            .pipe(
              mergeMap((cartRecord) => {
                const cartEntityState = CartEffects.transformRecord(
                  cartRecord,
                  cartEntity,
                );
                this.cartOfflineStorage.deleteCart();

                const dispatchedActions: Action[] = [
                  CartActions.updateCartSuccess(cartEntityState),
                  MaterialPriceActions.loadMaterialPrices(
                    materialNumberToRefresh,
                  ),
                ];
                if (action.isFulfillmentDataChanged) {
                  LogRocket.track('FulfillmentType', {
                    fulfillmentType: cartEntity.fulfillmentType,
                  });
                  dispatchedActions.push(
                    ...this.getFulfillmentDataChangedLoadActions(
                      currentSystem,
                      materialNumberToRefresh,
                    ),
                  );
                }
                return dispatchedActions;
              }),
              catchError((error) => {
                return this.offlineModeService.isOfflineManualCheck().pipe(
                  mergeMap((isOffline) => {
                    if (isOffline || NaooErrorUtils.isOfflineError(error)) {
                      return [
                        OfflineModeActions.updateOfflineMode(true),
                        CartActions.updateCartOffline(),
                      ];
                    }
                    return this.promptModalForError(
                      action,
                      NaooErrorUtils.getNaooError(error),
                      sessionRecord.isPunchOut,
                    );
                  }),
                );
              }),
            );
        },
      ),
    );
  });

  updateCartOffline$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CartActions.updateCartOffline),
        concatLatestFrom(() => [
          this.store.select(selectCartEntity),
          this.store.select(selectSession),
        ]),
        tap(([_, cartEntity, sessionRecord]) =>
          this.cartOfflineStorage.setCartWithTimestamp(
            cartEntity,
            sessionRecord.activeCustomer.naooCustomerId,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  updateDropShipSiteId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateDropShipSiteId),
      map((_) => CartReviewActions.refreshDropShipMetadata()),
    );
  });

  updateRouteDate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateRouteDate),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      mergeMap(([action, cart]) => {
        if (!cart) {
          return [SharedActions.noOperation('No cart exists in the store')];
        }

        const customerArrivalDate1 = action.customerArrivalDate || new Date(0);
        const customerArrivalDate2 =
          cart.truckFulfillment?.customerArrivalDate || new Date(0);
        if (
          +action.routeDate === +cart.truckFulfillment?.routeDate &&
          +customerArrivalDate1 === +customerArrivalDate2
        ) {
          return [
            SharedActions.noOperation(
              'The routeDate and customerArrivalDate are unchanged',
            ),
          ];
        }
        return [
          CartActions.updateRouteDateSuccess(
            action.routeDate,
            action.customerArrivalDate,
          ),
          EcommerceAnalyticsActions.selectShipDateEvent(
            moment
              .utc(action.routeDate)
              .format(SapDateTimeFormat.DATE_ONLY)
              .toString(),
          ),
        ];
      }),
    );
  });

  updateRouteDateSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateRouteDateSuccess),
      map((action) => {
        const updateRequest: CartUpdateRequest = {
          fulfillmentType: FulfillmentType.TRUCK,
          truckFulfillment: {
            routeDate: formatDate(action.routeDate),
            customerArrivalDate: formatDate(action.customerArrivalDate),
          },
          userLastUpdatedTimestamp: new Date().toISOString(),
        };
        return CartActions.updateCart(
          updateRequest,
          CartUpdateType.UpdateRouteDate,
        );
      }),
    );
  });

  updatePoNumber$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updatePoNumber),
      map((action) => {
        const updateRequest: CartUpdateRequest = {
          customerPoNumber: action.poNumber,
          userLastUpdatedTimestamp: new Date().toISOString(),
        };

        return CartActions.updateCart(
          updateRequest,
          CartUpdateType.UpdatePoNumber,
        );
      }),
    );
  });

  updateCartMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateCartMaterials),
      debounceTime(NaooConstants.CART_DEBOUNCE_REQUEST_INTERVAL),
      concatLatestFrom(() => this.store.select(selectCartFeature)),
      map(([_, cartState]) => {
        const updateRequest: CartUpdateRequest = {
          userLastUpdatedTimestamp: new Date().toISOString(),
          materials: cartState.queuedMaterialNumbers.map(
            (queuedMaterialNumber) => {
              const queuedCartMaterial: CartMaterialState =
                cartState.cart.materials.entities[queuedMaterialNumber];
              if (queuedCartMaterial) {
                return {
                  materialNumber: queuedCartMaterial.materialNumber,
                  lines: queuedCartMaterial.isDeleted
                    ? []
                    : Object.values(queuedCartMaterial.lines.entities),
                  restored: queuedCartMaterial.isRestored,
                  // This is intentionally null so that the existing value persists in GCD
                  originTrackingId: null,
                };
              } else {
                return {
                  materialNumber: queuedMaterialNumber,
                  lines: [],
                  restored: false,
                  originTrackingId: null,
                };
              }
            },
          ),
        };

        return CartActions.updateCart(
          updateRequest,
          CartUpdateType.UpdateCartQuantities,
        );
      }),
    );
  });

  clearCartMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.clearCartMaterials),
      map(() =>
        // The materials we need to update are already set in `queuedMaterialNumbers` in the reducer.
        CartActions.updateCartMaterials([]),
      ),
    );
  });

  updateCartQuantities$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateCartQuantities),
      concatLatestFrom(() => this.store.select(selectFocusedMaterial)),
      mergeMap(([action, focusMaterial]) => {
        const updatedItemIds = action.cartQuantityUpdates.map(
          (cartMaterial) => cartMaterial.materialNumber,
        );

        // We filter out the current focused material to not reset the current state for that warning.
        const resetWarningRequest = action.cartQuantityUpdates
          .filter(
            (cartMaterial) =>
              !focusMaterial ||
              cartMaterial.materialNumber !== focusMaterial.materialNumber,
          )
          .map((cartMaterial) => {
            const changedField = this.getChangedField(cartMaterial);
            return {
              materialNumber: cartMaterial.materialNumber,
              changedField,
            };
          });

        const actions: Action[] = [
          CartActions.updateCartMaterials(updatedItemIds),
          InventoryAvailabilityActions.loadCartInventoryAvailability(
            updatedItemIds,
          ),
        ];

        if (resetWarningRequest.length) {
          actions.push(
            MaterialWarningActions.resetMaterialWarning(resetWarningRequest),
          );
        }

        return actions;
      }),
    );
  });

  deleteCartMaterial$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.deleteCartMaterial),
      map((action) => {
        return CartActions.updateCartMaterials([action.materialNumber]);
      }),
    );
  });

  restoreCartMaterial$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.restoreCartMaterial),
      map((action) => CartActions.updateCartMaterials([action.materialNumber])),
    );
  });

  onBlur$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.blurCartQuantity),
      concatLatestFrom(() => [
        this.store.select(selectCartMaterialEntities),
        this.store.select(selectFocusedMaterial),
        this.store.select(selectAllMaterialInfoRecordStates),
        this.store.select(selectAllMaterialAvailabilityRecords),
      ]),
      mergeMap(
        ([
          action,
          cartMaterials,
          focusedMaterial,
          materialInfos,
          materialAvailabilities,
        ]) => {
          // We only reset the warnings for the changed quantity field.
          const existingCartMaterial = cartMaterials[action.materialNumber];

          if (
            !!existingCartMaterial &&
            CartEffects.quantityChanged(existingCartMaterial, focusedMaterial)
          ) {
            const infoForCartMaterial = materialInfos[action.materialNumber];
            const materialAvailability =
              materialAvailabilities[action.materialNumber];
            const isValidMaterial =
              !!materialAvailability?.record && !!infoForCartMaterial?.record;
            const largestSellableUom = findLargestSellableUom(
              infoForCartMaterial?.record?.units,
              materialAvailability?.record?.units,
            );
            const isPrimaryUom = action.uom === largestSellableUom;
            const actions: Action[] = [
              CartActions.blurCartQuantitySuccess(action.materialNumber),
              MaterialWarningActions.resetMaterialWarning([
                {
                  materialNumber: action.materialNumber,
                  changedField: isPrimaryUom
                    ? ChangedFieldType.Case
                    : ChangedFieldType.Unit,
                },
              ]),
            ];

            if (isValidMaterial && !isPrimaryUom) {
              const rollupQuantityUpdate =
                CartRollupTransformer.getCartRollupUpdate(
                  action.materialNumber,
                  existingCartMaterial,
                  infoForCartMaterial.record.units,
                  materialAvailability.record.units,
                  action.context,
                );

              if (rollupQuantityUpdate) {
                actions.push(
                  CartActions.updateCartQuantities([rollupQuantityUpdate]),
                );
              }
            }

            return actions;
          }
          return [CartActions.blurCartQuantitySuccess(action.materialNumber)];
        },
      ),
    );
  });

  refreshCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.refreshCart),
      switchMap((action) => {
        return this.cartService.retrieveOrCreateActiveCart().pipe(
          mergeMap((cartRecord) => {
            const cartEntityState = CartEffects.transformRecord(cartRecord);
            const actions: Action[] = [
              ...this.getFulfillmentDataChangedClearActions(),
              CartActions.refreshCartSuccess(cartEntityState),
            ];
            if (action.openFulfillmentModal) {
              actions.push(CartActions.openFulfillmentModal());
            }
            return actions;
          }),
          catchError((error) => of(ErrorActions.fatalError(error))),
        );
      }),
    );
  });

  refreshCartSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.refreshCartSuccess),
      concatLatestFrom(() => [
        this.store.select(selectCurrentSystem),
        this.store.select(selectMaterialNumbersToRefresh),
      ]),
      mergeMap(([_, currentSystem, materialNumbersToRefresh]) => [
        ...this.getFulfillmentDataChangedLoadActions(
          currentSystem,
          materialNumbersToRefresh,
        ),
        MaterialPriceActions.loadMaterialPrices(materialNumbersToRefresh),
      ]),
    );
  });

  /**
   * Need to ensure local storage is in sync with cache while in offline mode
   */

  clearDeletedCartMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.clearDeletedCartMaterials),
      concatLatestFrom(() => this.store.select(selectIsOnline)),
      skipWhile(([_, isOnline]) => isOnline),
      map(() => CartActions.updateCartOffline()),
    );
  });

  syncOfflineCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.syncOfflineCart),
      concatLatestFrom(() => this.store.select(selectSession)),
      filter(
        ([_, sessionRecord]) =>
          !!this.cartOfflineStorage.getCart() &&
          sessionRecord.activeCustomer.naooCustomerId ===
            this.cartOfflineStorage.getCartCustomerCompositeId(),
      ),
      audit(() =>
        this.store.select(selectCartEntity).pipe(first((cart) => !!cart)),
      ),
      concatMap(() =>
        this.cartService.retrieveOrCreateActiveCart().pipe(
          map((backendCart) => {
            const offlineCart = this.cartOfflineStorage.getCart();
            const offlineCartTimestamp =
              this.cartOfflineStorage.getCartTimestamp();
            const backendCartTimestamp = new Date(
              backendCart.userLastUpdatedTimestamp,
            );

            let actionToDispatch: Action;
            if (
              !offlineCart ||
              !offlineCart.materials ||
              !offlineCartTimestamp
            ) {
              actionToDispatch = CartActions.refreshCartSuccess(
                CartEffects.transformRecord(backendCart),
              );
            } else if (
              backendCart.id !== offlineCart.id ||
              backendCartTimestamp >= offlineCartTimestamp
            ) {
              LogRocket.track('cart_discarded_event');
              LogRocket.log(
                'Offline cart:',
                offlineCart,
                'Offline cart timestamp:',
                offlineCartTimestamp.toISOString(),
                'Backend cart:',
                backendCart,
              );

              actionToDispatch = CartActions.refreshCartSuccess(
                CartEffects.transformRecord(backendCart),
              );
            } else {
              const updateRequest =
                this.cartOfflineStorage.createCartSyncUpdateRequest(
                  backendCart,
                  offlineCart,
                );
              actionToDispatch = CartActions.updateCartOnline(
                updateRequest,
                CartUpdateType.OfflineCartSync,
              );
            }

            this.cartOfflineStorage.deleteCart();
            return actionToDispatch;
          }),
          catchError((error) => of(ErrorActions.fatalError(error))),
        ),
      ),
    );
  });

  addCouponToCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addCouponToCart),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      map(([action, cartState]) => {
        const updateRequest: CartUpdateRequest = {
          couponCodes: cartState.couponCodes,
          userLastUpdatedTimestamp: new Date().toISOString(),
        };
        this.showLocalizedToastMessage(
          'CART_COUPON.ADDED',
          action.couponCodeToAdd,
        );
        return CartActions.updateCart(
          updateRequest,
          CartUpdateType.AddCartCoupons,
        );
      }),
    );
  });

  removeCouponFromCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeCouponFromCart),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      map(([action, cartState]) => {
        const updateRequest: CartUpdateRequest = {
          couponCodes: cartState.couponCodes,
          userLastUpdatedTimestamp: new Date().toISOString(),
        };
        this.showLocalizedToastMessage(
          'CART_COUPON.REMOVED',
          action.couponCodeToRemove,
        );
        return CartActions.updateCart(
          updateRequest,
          CartUpdateType.RemoveCartCoupons,
        );
      }),
    );
  });

  updatePickupStoreFulfillment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CartActions.updatePickupStoreFulfillmentForCartReview,
        CartActions.updatePickupStoreFulfillmentForOrderMethodModal,
      ),
      concatLatestFrom(() => [
        this.store.select(selectAllStoreRecordStateEntities),
        this.store.select(selectCartEntity),
      ]),
      mergeMap(([action, storeRecords, cart]) => {
        const storeRecord = storeRecords[action.storePlantId];
        if (!storeRecord || !action.date || !cart) {
          return [
            SharedActions.noOperation(
              'missing required storeRecord, date, or cart',
            ),
          ];
        }
        const requestedPickupTimestamp =
          CartActions.updatePickupStoreFulfillmentForOrderMethodModal.type ===
          action.type
            ? getPickupTime(storeRecord, action.date)
            : action.date.format();

        const updateRequest: CartUpdateRequest = {
          userLastUpdatedTimestamp: new Date().toISOString(),
          fulfillmentType: FulfillmentType.PICKUP,
          couponCodes: [],
          storeFulfillment: {
            requestedPickupTimestamp,
            storePlantId: action.storePlantId,
          },
        };

        if (this.refreshDataNotNeeded(updateRequest, cart)) {
          return [
            SharedActions.noOperation(
              'The requestedPickupTimestamp and storePlantId are unchanged',
            ),
          ];
        }

        return [
          CartActions.updateCart(
            updateRequest,
            CartUpdateType.UpdateStoreFulfillment,
          ),
          CustomerPreferencesActions.updateCustomerPreference({
            preferredStorePlantId: action.storePlantId,
          }),
          EcommerceAnalyticsActions.selectShipDateEvent(
            moment(requestedPickupTimestamp)
              .format(SapDateTimeFormat.DATE_ONLY)
              .toString(),
          ),
        ];
      }),
    );
  });

  updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded$: Observable<Action> =
    createEffect(() => {
      return this.actions$.pipe(
        ofType(
          CartActions.updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded,
        ),
        concatLatestFrom(() => this.store.select(selectStoreFulfillment)),
        mergeMap(([_, storeFulfillment]) => {
          const userPickupTime = storeFulfillment?.requestedPickupTimestamp;
          if (!userPickupTime) {
            return [
              SharedActions.noOperation(
                'updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded$ requestedPickupTimestamp falsy',
              ),
            ];
          }
          const nowPlusBuffer = moment().add(
            NaooConstants.pickupSubmissionBufferMinutes,
            'minutes',
          );
          if (nowPlusBuffer.isSameOrBefore(userPickupTime, 'seconds')) {
            return [
              SharedActions.noOperation(
                'updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded$ no action required',
              ),
            ];
          }
          const updateRequest: CartUpdateRequest = {
            userLastUpdatedTimestamp: new Date().toISOString(),
            fulfillmentType: FulfillmentType.PICKUP,
            couponCodes: [],
            storeFulfillment: {
              requestedPickupTimestamp: nowPlusBuffer.format(),
              storePlantId: storeFulfillment.storePlantId,
            },
          };
          return [
            CartActions.updateCart(
              updateRequest,
              CartUpdateType.UpdateStoreFulfillment,
            ),
          ];
        }),
      );
    });

  updateExpressStoreFulfillment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateExpressStoreFulfillment),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      mergeMap(([action, cart]) => {
        if (!action.expressDeliveryWindow || !cart) {
          return [
            SharedActions.noOperation(
              'missing required expressDeliveryWindow or cart',
            ),
          ];
        }
        const updateRequest: CartUpdateRequest = {
          userLastUpdatedTimestamp: new Date().toISOString(),
          fulfillmentType: FulfillmentType.EXPRESS,
          couponCodes: [],
          storeFulfillment: {
            deliveryWindowStartTimestamp:
              action.expressDeliveryWindow.deliveryWindowStart,
            deliveryWindowEndTimestamp:
              action.expressDeliveryWindow.deliveryWindowEnd,
            orderEntryCutoff: action.expressDeliveryWindow.orderEntryCutoff,
            expressRouteId:
              +action.expressDeliveryWindow.storeDeliveryScheduleId,
          },
        };

        if (this.refreshDataNotNeeded(updateRequest, cart)) {
          return [
            SharedActions.noOperation(
              'The deliveryWindowStartTimestamp and expressRouteId are unchanged',
            ),
          ];
        }

        return [
          CartActions.updateCart(
            updateRequest,
            CartUpdateType.UpdateStoreFulfillment,
          ),
          EcommerceAnalyticsActions.selectShipDateEvent(
            moment(action.expressDeliveryWindow.deliveryWindowStart)
              .format(SapDateTimeFormat.DATE_ONLY)
              .toString(),
          ),
        ];
      }),
    );
  });

  openFulfillmentModalAction$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.openFulfillmentModal),
      delayWhen(() =>
        this.store.select(selectFulfillmentDataLoaded).pipe(
          first((fulfillmentDataLoaded) => !!fulfillmentDataLoaded),
          takeUntil(this.actions$.pipe(ofType(CartActions.refreshCart))),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectFulfillmentDataLoaded),
        this.store.select(selectRequiresFulfillmentChange),
        this.store.select(selectCurrentUrl),
      ]),
      map(
        ([_, fulfillmentDataLoaded, requiresFulfillmentChange, currentUrl]) => {
          if (
            fulfillmentDataLoaded &&
            !currentUrl?.includes(NaooConstants.CUSTOMER_UNIT_SELECTION_PATH)
          ) {
            this.fulfillmentModalService.openFulfillmentModal(
              false,
              requiresFulfillmentChange,
              false,
              !requiresFulfillmentChange,
            );
            return SharedActions.noOperation('Open Fulfillment Modal Called.');
          }
          return SharedActions.noOperation('Cart Refresh Occurred.');
        },
      ),
    );
  });

  private static transformRecord(
    cartRecord: CartRecord,
    previousCart?: CartEntityState,
  ): CartEntityState {
    if (previousCart?.couponCodes) {
      cartRecord.couponCodes = previousCart.couponCodes;
    }
    const materials: CartMaterialState[] = previousCart
      ? (previousCart.materials.ids as string[]).map(() => undefined)
      : [];
    cartRecord.materials.forEach((materialRecord) => {
      let materialState: CartMaterialState;
      const previousMaterial = previousCart
        ? previousCart.materials.entities[materialRecord.materialNumber]
        : undefined;
      if (previousMaterial) {
        const index = (previousCart.materials.ids as string[]).indexOf(
          previousMaterial.materialNumber,
        );

        materialState = {
          materialNumber: materialRecord.materialNumber,
          lines: cartLineAdapter.setAll(
            materialRecord.lines,
            initialCartLineState,
          ),
          isDeleted: previousMaterial.isDeleted,
          isRestored: previousMaterial.isRestored,
          isAddedFromCriticalItem: previousMaterial.isAddedFromCriticalItem,
          isAddedFromCriticalItemsSection:
            previousMaterial.isAddedFromCriticalItemsSection,
          isAddedFromMaterialComparison:
            previousMaterial.isAddedFromMaterialComparison,
          isRollUpConversion: previousMaterial.isRollUpConversion,
          originTrackingId: materialRecord.originTrackingId,
        };
        materials[index] = materialState;
        return;
      }

      materialState = {
        materialNumber: materialRecord.materialNumber,
        lines: cartLineAdapter.setAll(
          materialRecord.lines,
          initialCartLineState,
        ),
        isDeleted: false,
        isRestored: false,
        isAddedFromCriticalItem: false,
        isAddedFromCriticalItemsSection: false,
        isAddedFromMaterialComparison: false,
        isRollUpConversion: false,
        originTrackingId: materialRecord.originTrackingId,
      };
      materials.push(materialState);
    });

    /**
     * We need to maintain the order of items that are deleted. Since the response from the backend does not include the deleted items,
     * we need to do this on our own. Here we are going to find all deleted items from the old cart and insert them into our merge array
     * at the index they previously had, therefore maintaining the order of deleted items.
     */
    if (previousCart) {
      Object.values(previousCart.materials.entities)
        .filter((oldItem) => oldItem.isDeleted)
        .forEach((oldItem) => {
          const index = (previousCart.materials.ids as string[]).indexOf(
            oldItem.materialNumber,
          );
          materials[index] = {
            materialNumber: oldItem.materialNumber,
            lines: oldItem.lines,
            isDeleted: true,
            isRestored: oldItem.isRestored,
            isAddedFromCriticalItem: oldItem.isAddedFromCriticalItem,
            isAddedFromCriticalItemsSection:
              oldItem.isAddedFromCriticalItemsSection,
            isAddedFromMaterialComparison:
              oldItem.isAddedFromMaterialComparison,
            isRollUpConversion: oldItem.isRollUpConversion,
            originTrackingId: oldItem.originTrackingId,
          };
        });
    }

    const filteredCartMaterials = materials.filter((material) => {
      if (!material) {
        return false;
      }

      return (
        material.isDeleted ||
        filterNonEmptyCartLines(material.lines.entities).length !== 0
      );
    });

    const storeFulfillment = cartRecord?.storeFulfillment;
    return {
      id: cartRecord.id,
      customerPoNumber: cartRecord.customerPoNumber,
      fulfillmentType: cartRecord.fulfillmentType,
      truckFulfillment: cartRecord.truckFulfillment
        ? {
            routeDate: parseDate(cartRecord.truckFulfillment.routeDate),
            customerArrivalDate: parseDate(
              cartRecord.truckFulfillment.customerArrivalDate,
            ),
          }
        : undefined,
      storeFulfillment: storeFulfillment
        ? {
            storePlantId: storeFulfillment.storePlantId,
            requestedPickupTimestamp: storeFulfillment.requestedPickupTimestamp,
            deliveryWindowStartTimestamp:
              storeFulfillment.deliveryWindowStartTimestamp,
            deliveryWindowEndTimestamp:
              storeFulfillment.deliveryWindowEndTimestamp,
            expressRouteId: storeFulfillment.expressRouteId,
          }
        : undefined,
      couponCodes: cartRecord.couponCodes,
      splitOrders: previousCart?.splitOrders,
      materials: cartMaterialAdapter.setAll(
        filteredCartMaterials,
        initialCartMaterialState,
      ),
    };
  }

  private static quantityChanged(
    existingCartMaterial: CartMaterialState,
    focusedMaterial: FocusedMaterialState,
  ): boolean {
    return (
      !focusedMaterial ||
      !isEqual(
        existingCartMaterial.lines.entities,
        focusedMaterial.lines.entities,
      )
    );
  }

  private getFulfillmentDataChangedClearActions(): Action[] {
    this.materialValidationService.clearCache();
    return [
      MaterialAvailabilityActions.clearMaterialAvailability(),
      MaterialInfoActions.clearMaterialInfo(),
      MaterialPriceActions.clearMaterialPrices(),
      MaterialAdditionalInfoActions.clearMaterialAdditionalInfo(),
      InventoryAvailabilityActions.clearInventoryAvailability(),
      StorePurchaseDetailsActions.clearStorePurchaseDetails(),
      MaterialWarningActions.clearMaterialWarning(),
      SearchActions.clearSearchResults(true),
    ];
  }

  private getFulfillmentDataChangedLoadActions(
    currentSystem: CurrentSystem,
    materialNumbers: string[],
  ): Action[] {
    return [
      MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers),
      MaterialInfoActions.loadMaterialInfo(materialNumbers),
      MaterialAvailabilityActions.refreshListMaterialAvailability(),
      MaterialInfoActions.refreshListMaterialInfo(),
      InventoryAvailabilityActions.loadInventoryAvailability(materialNumbers),
      MaterialCutoffActions.refreshMaterialCutoffs(currentSystem),
      OpenOrderItemsActions.refreshOpenOrderItems(),
      MaterialAdditionalInfoActions.loadMaterialAdditionalInfo(materialNumbers),
      OpenSpecialOrderItemsActions.refreshOpenSpecialOrderItems(),
      EntitlementActions.refreshEntitlement(),
      SalesCriticalItemsActions.refreshSalesCriticalItems(),
      SearchActions.refreshCurrentSearch(),
      StorePurchaseDetailsActions.refreshStorePurchaseDetails(),
      MaterialRecommendationsActions.refreshMaterialRecommendations(),
    ];
  }

  private promptModalForError(
    action: {
      cartUpdateRequest: CartUpdateRequest;
      isFulfillmentDataChanged: boolean;
      updateType: CartUpdateType;
    },
    httpError: NaooError,
    isPunchOut: boolean,
  ): Observable<Action> {
    const httpErrorCode = httpError.code;

    switch (httpErrorCode) {
      case NAOOErrorCode.INACTIVE_CART:
        return this.promptOneButtonErrorModal('CART.ERROR.INACTIVE_CART');
      case NAOOErrorCode.CART_ALREADY_SUBMITTED:
        if (isPunchOut) {
          return this.promptOneButtonErrorModal(
            'CART.ERROR.ALREADY_PUNCHED_OUT',
          );
        }
        return this.promptOneButtonErrorModal('CART.ERROR.ALREADY_SUBMITTED');
      default:
        return of(ErrorActions.fatalError(httpError, action.updateType));
    }
  }

  private promptOneButtonErrorModal(
    messageData: string,
    buttonText: string = 'SHARED.MODALS.OK_BUTTON_TEXT',
  ): Observable<Action> {
    return of(
      ModalActions.oneButtonModal(
        'cart-effects-error-display',
        messageData,
        buttonText,
        () => {
          this.store.dispatch(CartActions.refreshCart());
        },
        false,
      ),
    );
  }

  private getChangedField(
    cartQuantityUpdate: CartQuantityUpdate,
  ): ChangedFieldType {
    const hasCaseUpdate = !!cartQuantityUpdate.lines.find(
      (line) => NaooConstants.Uom.Case === line.uom,
    );
    const hasUnitUpdate = !!cartQuantityUpdate.lines.find(
      (line) => NaooConstants.Uom.Unit === line.uom,
    );

    if (hasCaseUpdate && hasUnitUpdate) {
      return ChangedFieldType.Both;
    } else if (hasCaseUpdate) {
      return ChangedFieldType.Case;
    } else {
      return ChangedFieldType.Unit;
    }
  }

  private showLocalizedToastMessage(messageKey: string, couponCode: string) {
    this.toastMessageService.showLocalizedToastMessage(messageKey, {
      value: couponCode,
    });
  }

  private refreshDataNotNeeded(
    updateRequest: CartUpdateRequest,
    cart: CartEntityState,
  ): boolean {
    if (FulfillmentType.PICKUP === updateRequest.fulfillmentType) {
      return (
        moment(updateRequest.storeFulfillment.requestedPickupTimestamp).isSame(
          moment(cart.storeFulfillment?.requestedPickupTimestamp),
        ) &&
        updateRequest.storeFulfillment.storePlantId ===
          cart.storeFulfillment?.storePlantId
      );
    } else {
      return (
        moment(
          updateRequest.storeFulfillment.deliveryWindowStartTimestamp,
        ).isSame(moment(cart.storeFulfillment?.deliveryWindowStartTimestamp)) &&
        updateRequest.storeFulfillment.expressRouteId ===
          cart.storeFulfillment?.expressRouteId
      );
    }
  }
}
