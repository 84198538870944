import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { DeliverySchedule } from 'src/app/shared/models/delivery-schedule';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';
import {
  selectAllDeliveryScheduleRecords,
  selectCurrentDeliverySchedule,
  selectRouteDateExpired,
} from './delivery-schedule.selectors';
import moment from 'moment-timezone';
import { DeliveryScheduleActions } from './delivery-schedule.actions';

@Injectable({ providedIn: 'root' })
export class DeliveryScheduleFacade {
  constructor(private store: Store) {}

  getDeliverySchedules(): Observable<DeliverySchedule[]> {
    return this.store.select(selectAllDeliveryScheduleRecords).pipe(
      map((deliveryScheduleRecords: DeliveryScheduleEntryRecord[]) => {
        return deliveryScheduleRecords
          ? deliveryScheduleRecords.map((record) => {
              return this.convertDeliveryScheduleRecord(record);
            })
          : undefined;
      }),
    );
  }

  getLoadedDeliverySchedules(): Observable<DeliverySchedule[]> {
    return this.store.select(selectAllDeliveryScheduleRecords).pipe(
      filter((hasLoaded) => !!hasLoaded),
      map((deliveryScheduleRecords: DeliveryScheduleEntryRecord[]) => {
        return deliveryScheduleRecords.map((record) => {
          return this.convertDeliveryScheduleRecord(record);
        });
      }),
    );
  }

  getCurrentDeliverySchedule(): Observable<DeliverySchedule> {
    return this.store.select(selectCurrentDeliverySchedule).pipe(
      map((deliveryScheduleEntryRecord: DeliveryScheduleEntryRecord) => {
        return this.convertDeliveryScheduleRecord(deliveryScheduleEntryRecord);
      }),
    );
  }

  getLoadedIsRouteDateExpired(): Observable<boolean> {
    return this.store
      .select(selectRouteDateExpired)
      .pipe(filter((hasLoaded) => hasLoaded != undefined));
  }

  refreshDeliverySchedules() {
    this.store.dispatch(DeliveryScheduleActions.refreshDeliverySchedules());
  }

  private convertDeliveryScheduleRecord(
    record: DeliveryScheduleEntryRecord,
  ): DeliverySchedule {
    if (!record) {
      return undefined;
    } else {
      return new DeliverySchedule(
        record.routeDate,
        record.customerArrivalDate,
        moment(record.cutoffDateTime, 'YYYY-MM-DDTHH:mm:ssZZ'),
        record.routeId,
      );
    }
  }
}
