export class NaooError {
  code: string;
  status: number;
  message: Map<string, object>;

  constructor(code: string, status: number, message?: object) {
    this.code = code;
    this.status = status;
    this.message = message ? new Map(Object.entries(message)) : new Map();
  }
}
