import { Localized } from '../../../shared/models/localized';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { RecommendationProduct } from '../../services/search/search.service';
import { OrderGuideFilter } from '../../services/search/models/search-record';

export interface SearchState {
  hasLoaded: boolean;
  currentOffset: number;
  defaultFilters: EntityState<FilterGroupRecordState>;
  taxonomyFilters?: TaxonomyFilterGroupRecordState;
  orderGuideFilter?: OrderGuideFilterRecordState;
  searchText?: Localized<string>;
  lastSearchText?: string;
  hideSearchText?: boolean;
  resultSet?: SearchResultSetState;
  availableTodayFilter?: AvailableTodayFilterRecordState;
}

export interface SearchResultSetState {
  materialNumbers: string[];
  suggestedText: string;
  totalResults: number;
  breadCrumbTree?: BreadCrumbCategory;
  sponsoredRecommendations?: SponsoredRecommendationsState;
}

export interface SponsoredRecommendationsState {
  banners: RecommendationBanner[];
  productRecommendations: Map<string, RecommendationProduct>;
}

export interface RecommendationBanner {
  imageVersions: ImageInfo[];
  imageId: string;
  destinationUrl: string;
  searchTerm: string;
  imageAltText: string;
}

export interface ImageInfo {
  device: string;
  imageHeight: string;
  imageWidth: string;
  imagePath: string;
}

export interface BreadCrumbCategory {
  categoryLevel: string;
  categoryKey: string;
  name: Localized<string>;
  subCategories: BreadCrumbCategory[];
}

export interface FilterGroupRecordState {
  name: Localized<string>;
  isCollapsed: boolean;
  queryParameterName: string;
  filters: EntityState<FilterRecordState>;
  isViewingMore?: boolean;
  viewMoreThreshold?: number;
}

export interface FilterRecordState {
  count: number;
  name: Localized<string>;
  isSelected: boolean;
  queryParameterValue: string;
}

export interface TaxonomyFilterGroupRecordState {
  queryParameterValue: string;
  name: Localized<string>;
  subCategories: TaxonomyFilterRecordState[];
  isViewingMore?: boolean;
}

export interface TaxonomyFilterRecordState {
  queryParameterValue: string;
  name: Localized<string>;
  subCategories: TaxonomyFilterRecordState[];
  count?: number;
  isSelected?: boolean;
}

export interface OrderGuideFilterRecordState {
  record?: OrderGuideFilter;
  isOrderGuideOptionSelected: boolean;
}

export interface AvailableTodayFilterRecordState {
  isEnabled: boolean;
  count: number;
}

export const defaultFilterGroupEntityAdapter =
  createEntityAdapter<FilterGroupRecordState>({
    selectId: (filterGroup) => filterGroup.name.en,
  });

export const defaultFilterEntityAdapter =
  createEntityAdapter<FilterRecordState>({
    selectId: (filter) => filter.queryParameterValue,
  });

export const initialDefaultFilterGroupEntityState =
  defaultFilterGroupEntityAdapter.getInitialState();
export const initialDefaultFilterEntityState =
  defaultFilterEntityAdapter.getInitialState();

export const initialSearchState: SearchState = {
  hasLoaded: false,
  currentOffset: 0,
  defaultFilters: initialDefaultFilterGroupEntityState,
};
