import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { materialAvailabilityReducer } from './material-availability.reducer';
import { MaterialAvailabilityEffects } from './material-availability.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('materialAvailability', materialAvailabilityReducer),
    EffectsModule.forFeature([MaterialAvailabilityEffects]),
  ],
})
export class MaterialAvailabilityStoreModule {}
