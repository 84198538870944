import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { materialCutoffReducer } from './material-cutoff.reducer';
import { MaterialCutoffEffects } from './material-cutoff.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('materialCutoff', materialCutoffReducer),
    EffectsModule.forFeature([MaterialCutoffEffects]),
  ],
})
export class MaterialCutoffStoreModule {}
