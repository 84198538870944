import * as Hammer from 'hammerjs';
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

/**
 * This class allows for scrolling on our swiping for image carousel
 */
@Injectable()
export class NaooHammerGestureConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: Hammer.DIRECTION_HORIZONTAL,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
  };
}
