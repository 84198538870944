import { Injectable } from '@angular/core';
import { StorePurchaseHistoryService } from '../../services/store-purchase-history/store-purchase-history.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import { StorePurchaseHistoryActions } from './store-purchase-history.actions';
import { Action, Store } from '@ngrx/store';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { selectHasShowPurchaseHistoryEnabled } from '../session/session.selectors';
import { StorePurchaseHistoryRequest } from '../../services/store-purchase-history/model/store-purchase-history-request';
import { selectStorePurchaseHistoryStatus } from './store-purchase-history.selectors';
import { StorePurchaseHistoryStateStatus } from './store-purchase-history.state';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { NaooConstants } from '../../../shared/NaooConstants';

@Injectable()
export class StorePurchaseHistoryEffects {
  readonly analyticsLabelServiceError = 'store purchase history service error';
  readonly analyticsLabelNoOrders = 'no purchase data';

  readonly initialSkipQuantity: number = 0;
  readonly resultsPerPage: number = 100;

  constructor(
    private actions$: Actions,
    private store: Store,
    private purchaseHistoryService: StorePurchaseHistoryService,
    private analytics: NaooAnalyticsManager,
  ) {}

  loadPurchaseHistory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorePurchaseHistoryActions.loadStorePurchaseHistory),
      concatLatestFrom(() => [
        this.store.select(selectHasShowPurchaseHistoryEnabled),
        this.store.select(selectStorePurchaseHistoryStatus),
      ]),
      mergeMap(([action, isPurchaseHistory, status]) => {
        const isQueuedStatus =
          StorePurchaseHistoryStateStatus.Queued === status;
        if (!isPurchaseHistory || !isQueuedStatus) {
          return of(
            StorePurchaseHistoryActions.getStorePurchaseHistorySuccess([]),
          );
        }
        return of(
          StorePurchaseHistoryActions.getStorePurchaseHistory(
            this.buildPurchaseHistoryRequest(
              action.startDate,
              action.endDate,
              this.initialSkipQuantity,
            ),
          ),
        );
      }),
    );
  });

  getPurchaseHistory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorePurchaseHistoryActions.getStorePurchaseHistory),
      concatLatestFrom(() =>
        this.store.select(selectHasShowPurchaseHistoryEnabled),
      ),
      mergeMap(([action, isPurchaseHistory]) => {
        if (!isPurchaseHistory) {
          return of(
            StorePurchaseHistoryActions.getStorePurchaseHistorySuccess([]),
          );
        }
        return this.purchaseHistoryService
          .getStorePurchaseHistory(action.storePurchaseHistoryRequest)
          .pipe(
            mergeMap((purchaseHistoryRecords) => {
              const actions: Action[] = [
                StorePurchaseHistoryActions.getStorePurchaseHistorySuccess(
                  purchaseHistoryRecords.sales,
                ),
              ];
              if (this.resultsPerPage === purchaseHistoryRecords.sales.length) {
                actions.push(
                  StorePurchaseHistoryActions.getStorePurchaseHistory(
                    this.buildPurchaseHistoryRequest(
                      action.storePurchaseHistoryRequest.startDate,
                      action.storePurchaseHistoryRequest.endDate,
                      action.storePurchaseHistoryRequest.skipQuantity +
                        this.resultsPerPage,
                    ),
                  ),
                );
              }
              if (
                action.storePurchaseHistoryRequest.skipQuantity === 0 &&
                purchaseHistoryRecords.sales.length === 0
              ) {
                this.trackPurchaseHistoryAnalytics(this.analyticsLabelNoOrders);
              }
              return actions;
            }),
            catchError(() => {
              this.trackPurchaseHistoryAnalytics(
                this.analyticsLabelServiceError,
              );
              return of(
                StorePurchaseHistoryActions.getStorePurchaseHistoryError(),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  StorePurchaseHistoryActions.loadStorePurchaseHistory,
                  StorePurchaseHistoryActions.clearStorePurchaseHistory,
                ),
              ),
            ),
          );
      }),
    );
  });

  private buildPurchaseHistoryRequest(
    startDate: string,
    endDate: string,
    skipQuantity: number,
  ): StorePurchaseHistoryRequest {
    return {
      skipQuantity,
      startDate,
      endDate,
      resultsPerPage: this.resultsPerPage,
    };
  }

  private trackPurchaseHistoryAnalytics(label: string) {
    this.analytics.trackAnalyticsEvent({
      action: NaooConstants.ANALYTICS_ACTION_DISPLAYED,
      category: NaooConstants.ANALYTICS_CATEGORY_STORE_PURCHASE_HISTORY,
      label,
    });
  }
}
