import {
  asapScheduler,
  Observable,
  throwError,
  TimeoutError,
  timer,
} from 'rxjs';
import {
  catchError,
  mergeMap,
  retryWhen,
  subscribeOn,
  tap,
  timeout,
} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHandler,
} from '@angular/common/http';
import { NaooConstants } from '../NaooConstants';
import { WebBffService } from '../services/web-bff/web-bff.service';
import { NaooErrorUtils } from '../error-handler/NaooErrorUtils';
import {
  defaultRequestConfiguration,
  NaooRequestConfiguration,
  silentNoRetryRequestConfiguration,
  silentRequestConfiguration,
} from './naoo-request-configuration';
import { RetryUIService } from '../retry-ui/retry-ui.service';
import { NaooError } from '../models/naoo-error';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { ZoneUtilities } from '../utilities/zone-utilities';

@Injectable({ providedIn: 'root' })
export class NaooHttpClient extends HttpClient {
  private requestUIModalCounter = 0;
  private isOnline = true;

  constructor(
    handler: HttpHandler,
    private webBffService: WebBffService,
    private retryUIService: RetryUIService,
    private offlineModeFacade: OfflineModeFacade,
    private ngZone: NgZone,
  ) {
    super(handler);
    this.offlineModeFacade
      .getIsOnline()
      .subscribe((isOnline) => (this.isOnline = isOnline));
  }

  public get<T>(
    url: string,
    options?: object,
    configuration: NaooRequestConfiguration = defaultRequestConfiguration,
  ): Observable<T> {
    return this.makeRequest<T>('GET', url, options, configuration);
  }

  public post<T>(
    url: string,
    body: any | null,
    options?: object,
    configuration: NaooRequestConfiguration = defaultRequestConfiguration,
  ): Observable<T> {
    return this.makeRequest<T>(
      'POST',
      url,
      { ...options, body },
      configuration,
    );
  }

  public put<T>(
    url: string,
    body: any | null,
    options?: object,
    configuration: NaooRequestConfiguration = defaultRequestConfiguration,
  ): Observable<T> {
    return this.makeRequest<T>('PUT', url, { ...options, body }, configuration);
  }

  public delete<T>(
    url: string,
    options?: object,
    configuration: NaooRequestConfiguration = defaultRequestConfiguration,
  ): Observable<T> {
    return this.makeRequest<T>('DELETE', url, options, configuration);
  }

  public patch<T>(
    url: string,
    body: any | null,
    options?: object,
    configuration?: NaooRequestConfiguration,
  ): Observable<T> {
    return this.makeRequest<T>(
      'PATCH',
      url,
      { ...options, body },
      configuration,
    );
  }

  private makeRequest<T>(
    method: string,
    url: string,
    options?: object,
    configuration: NaooRequestConfiguration = defaultRequestConfiguration,
  ): Observable<T> {
    if (!this.webBffService.isBffCall(url)) {
      configuration = silentNoRetryRequestConfiguration;
    }

    return this.ngZone.runOutsideAngular<Observable<T>>(() => {
      return this.makeRequestWithConfiguration(
        method,
        url,
        options,
        configuration,
      ).pipe(
        subscribeOn<T>(ZoneUtilities.enterZone(this.ngZone, asapScheduler)),
      );
    });
  }

  makeRequestWithConfiguration<T>(
    method: string,
    url: string,
    options: object,
    requestConfig: NaooRequestConfiguration,
  ): Observable<T> {
    this.updateCounterIfNecessary(requestConfig, 1);
    return super.request<T>(method, url, options).pipe(
      timeout(requestConfig.timeout),
      retryWhen((errors) =>
        errors.pipe(
          mergeMap(
            (error: TimeoutError | HttpErrorResponse, retryIndex: number) => {
              const errorResponse: HttpErrorResponse =
                error instanceof TimeoutError
                  ? new HttpErrorResponse({ error, url, status: 0 })
                  : error;
              return this.getNewErrorObservable(
                errorResponse,
                retryIndex,
                requestConfig,
              );
            },
          ),
        ),
      ),
      catchError((err) => {
        this.updateCounterIfNecessary(requestConfig, -1);
        return throwError(err);
      }),
      tap(() => {
        this.updateCounterIfNecessary(requestConfig, -1);
        this.callUIService(
          (retryUIService) => retryUIService.closeConnectivityModal(),
          requestConfig.shouldShowConnectivityModal &&
            this.requestUIModalCounter === 0,
        );
      }),
    );
  }

  private updateCounterIfNecessary(
    requestConfig: NaooRequestConfiguration,
    updateCount: number,
  ) {
    if (
      requestConfig.shouldRetry &&
      requestConfig.shouldShowConnectivityModal
    ) {
      this.requestUIModalCounter = this.requestUIModalCounter + updateCount;
    }
  }

  private getNewErrorObservable(
    error: HttpErrorResponse,
    retryIndex: number,
    requestConfig: NaooRequestConfiguration,
  ) {
    let errorObservable: Observable<never | 0> = throwError(() => error);
    const naooError: NaooError = NaooErrorUtils.getNaooError(error);
    const shouldRetryConnection: boolean = this.shouldRetryConnection(
      naooError,
      requestConfig.shouldRetry,
    );

    if (naooError.status === NaooErrorUtils.offlineErrorCode) {
      requestConfig = silentRequestConfiguration;
    }

    if (shouldRetryConnection && retryIndex < requestConfig.maxRetryCount) {
      errorObservable = timer(NaooConstants.CONNECTIVITY_RETRY_UI_TIMER);
      this.callUIService(
        (retryService) => retryService.openRetryingConnectivityModal(),
        retryIndex === 0 && requestConfig.shouldShowConnectivityModal,
      );
    } else if (shouldRetryConnection) {
      this.callUIService(
        (retryUIService) => retryUIService.openNoConnectionModal(),
        requestConfig.shouldShowConnectivityModal,
      );
    }
    return errorObservable;
  }

  private shouldRetryConnection(
    naooError: NaooError,
    retryEnabled: boolean,
  ): boolean {
    return (
      retryEnabled &&
      NaooErrorUtils.retryCodes.includes(naooError.status) &&
      this.isOnline
    );
  }

  private callUIService(
    uiCall: (retryUIService: RetryUIService) => void,
    shouldMakeUICall: boolean,
  ) {
    if (shouldMakeUICall) {
      uiCall(this.retryUIService);
    }
  }
}
