import { Injectable } from '@angular/core';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { MaterialPriceRecord } from './models/material-price-record';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

export interface MaterialPriceInfoRecord {
  currency: string;
  materialPrices: MaterialPriceRecord[];
  invalidCoupons?: string[];
}

const LOOKUP_PRICE_ENDPOINT = '/api/v5/prices';

@Injectable({ providedIn: 'root' })
export class MaterialPriceService {
  constructor(
    private http: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getMaterialPricing(
    materialNumbers: string[],
    useCommodityPricing: boolean = false,
    couponCodes?: string[],
  ): Observable<MaterialPriceInfoRecord> {
    return this.http.post<MaterialPriceInfoRecord>(
      `${this.webBffService.getBff()}${LOOKUP_PRICE_ENDPOINT}`,
      {
        materialNumbers,
        useCommodityPricing,
        couponCodes,
      },
      undefined,
      silentRequestConfiguration,
    );
  }
}
