import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { marketingGuideReducer } from './marketing-guide.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MarketingGuideEffects } from './marketing-guide.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('marketingGuide', marketingGuideReducer),
    EffectsModule.forFeature([MarketingGuideEffects]),
  ],
})
export class MarketingGuideStoreModule {}
