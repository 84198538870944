import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerBrand } from '../../core/services/session/models/session-record';

export interface CreateCustomGuideModal {
  customGuideId: string;
  customGuideName: string;
}

@Component({
  selector: 'naoo-support-contact-modal',
  templateUrl: './support-contact-modal.component.html',
  styleUrls: ['./support-contact-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportContactModalComponent {
  readonly telephoneTranslationBase = 'SUPPORT_CONTACT.PHONE_NUMBER';

  constructor(
    private dialogRef: MatDialogRef<SupportContactModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public customerBrand: CustomerBrand,
  ) {}

  closeModal(customGuideModal?: CreateCustomGuideModal) {
    this.dialogRef.close(customGuideModal);
  }
}
