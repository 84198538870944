import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'naoo-generic-banner',
  templateUrl: './generic-banner.component.html',
  styleUrls: ['./generic-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericBannerComponent {
  @Input() messageTranslationKey: string;
  @Input() messageTranslationArgs: { [key: string]: string } = {};
  @Input() titleTranslationKey: string;
}
