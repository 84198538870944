export interface CartRecord {
  id: string;
  status: string;
  couponCodes: string[];
  materials: CartMaterialRecord[];
  lastUpdatedTimestamp: string;
  userLastUpdatedTimestamp: string;
  lastUpdatedByUserFullName?: string;
  lastUpdatedByUserEmail?: string;
  customerPoNumber?: string;
  fulfillmentType?: FulfillmentType;
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;
}

export enum FulfillmentType {
  NONE = 'NONE',
  TRUCK = 'TRUCK',
  PICKUP = 'PICKUP',
  EXPRESS = 'EXPRESS',
}

export interface TruckFulfillment {
  routeDate?: string;
  customerArrivalDate?: string;
}

export interface StoreFulfillment {
  storePlantId?: string;
  requestedPickupTimestamp?: string;
  deliveryWindowStartTimestamp?: string;
  deliveryWindowEndTimestamp?: string;
  orderEntryCutoff?: string;
  expressRouteId?: number;
}

export interface CartMaterialRecord {
  materialNumber: string;
  lines: CartLineRecord[];
  originTrackingId?: string;
}

export interface CartLineRecord {
  uom: string;
  quantity: number;
}

export interface CartUpdateRequest {
  customerPoNumber?: string;
  userLastUpdatedTimestamp: string;
  couponCodes?: string[];
  materials?: CartMaterialUpdateRequest[];
  fulfillmentType?: FulfillmentType;
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;
}

export interface CartMaterialUpdateRequest {
  materialNumber: string;
  lines: CartLineRecord[];
  restored?: boolean;
  originTrackingId?: string;
}
