import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FulfillmentType } from '../../../../../core/services/cart/models/cart-record';
import { DisplayData } from '../../../../../core/store/order-method-modal/order-method-modal-view-model';
import { ExpressDeliveryFees } from '../../../../../core/services/express-schedules/models/express-schedule-record';
import { getFulfillmentText } from '../../util/order-method-modal.utils';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'naoo-order-method-step',
  templateUrl: './order-method-step.component.html',
  styleUrls: ['./order-method-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderMethodStepComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() fulfillmentView: Map<FulfillmentType, DisplayData>;
  @Input() expressDeliveryFees: ExpressDeliveryFees;
  @Input() expressDeliveryDate: Moment;
  @Input() filterByFulfillmentType: boolean;

  @Output() fulfillmentTypeChangeEmitter = new EventEmitter<FulfillmentType>();

  get FulfillmentTypeEnum(): typeof FulfillmentType {
    return FulfillmentType;
  }

  get tabIndex(): number {
    let numberOfVisibleItems = 0;
    this.fulfillmentView.forEach((value) => {
      numberOfVisibleItems += value.isDisplayed ? 1 : 0;
    });

    return numberOfVisibleItems > 1 ? 0 : -1;
  }

  get expressOrderSubtitle(): string {
    if (!this.expressDeliveryDate) {
      return '';
    }
    const minOrderDollarAmount =
      this.expressDeliveryFees?.minOrderDollarAmount ?? 0;

    const minOrderAmountFee = +this.expressDeliveryFees?.minOrderAmountFee || 0;

    if (minOrderDollarAmount > 0 && minOrderAmountFee <= 0) {
      return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.EXPRESS_MINIMUM_DELIVERY';
    }
    if (minOrderDollarAmount > 0 && minOrderAmountFee > 0) {
      return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.EXPRESS_MINIMUM_ORDER_FEE';
    }
    return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.EXPRESS_MESSAGE';
  }

  isDisplayed(fulfillmentType: FulfillmentType): boolean {
    const isDisplayed =
      !!this.fulfillmentView.get(fulfillmentType)?.isDisplayed;
    return this.filterByFulfillmentType
      ? this.fulfillmentType === fulfillmentType && isDisplayed
      : isDisplayed;
  }

  isDisabled(fulfillmentType: FulfillmentType): boolean {
    return !!this.fulfillmentView.get(fulfillmentType)?.isDisabled;
  }

  isFulfillmentType(fulfillmentType: FulfillmentType): boolean {
    return this.fulfillmentType === fulfillmentType;
  }

  setUserSelectedFulfillmentType(fulfillmentType: FulfillmentType): void {
    this.fulfillmentTypeChangeEmitter.emit(fulfillmentType);
  }

  fulfillmentText(fulfillmentType: FulfillmentType) {
    return getFulfillmentText(fulfillmentType);
  }
}
