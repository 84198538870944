import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProductDetailsMainComponent } from './product-details-main/product-details-main.component';
import { ProductRowModule } from '../product-row/product-row.module';
import { SharedModule } from '../shared/shared.module';
import { ProductAttributesModalComponent } from './product-attributes-modal/product-attributes-modal.component';
import { MaterialMovementComponent } from './material-movement/material-movement.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { MatIconModule } from '@angular/material/icon';
import { productDetailsResolver } from './product-details-resolver/product-details-resolver';
import { MaterialListModule } from '../material-list/material-list.module';
import { NutritionContainerComponent } from './nutrition/nutrition-container/nutrition-container.component';
import { NutritionContentComponent } from './nutrition/nutrition-content/nutrition-content.component';
import { MatSelectModule } from '@angular/material/select';
import { NutritionLabelComponent } from './nutrition/nutrition-content/nutrition-label/nutrition-label.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { IngredientContentComponent } from './nutrition/ingredients/ingredient-content/ingredient-content.component';
import { IngredientListComponent } from './nutrition/ingredients/ingredient-list.component';
import { NutritionFactsComponent } from './nutrition/nutrition-facts/nutrition-facts.component';
import { NutritionFactsContentComponent } from './nutrition/nutrition-facts/nutrition-content/nutrition-facts-content.component';
import { ProductSecondaryDetailsComponent } from './product-secondary-details/product-secondary-details.component';
import { ComplimentaryProductComponent } from './complimentary-product/complimentary-product.component';
import { MatChipsModule } from '@angular/material/chips';

const routes: Routes = [
  {
    path: ':materialNumber',
    component: ProductDetailsMainComponent,
    resolve: {
      detailsData: productDetailsResolver,
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ProductRowModule,
    MatIconModule,
    MaterialListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatChipsModule,
  ],
  declarations: [
    ProductDetailsMainComponent,
    ProductAttributesModalComponent,
    MaterialMovementComponent,
    InventoryComponent,
    ImageCarouselComponent,
    NutritionContainerComponent,
    NutritionContentComponent,
    IngredientListComponent,
    IngredientContentComponent,
    NutritionLabelComponent,
    NutritionFactsComponent,
    NutritionFactsContentComponent,
    ProductSecondaryDetailsComponent,
    ComplimentaryProductComponent,
  ],
  exports: [NutritionFactsComponent],
})
export class ProductDetailsModule {}
