import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkAddModalComponent } from './bulk-add-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared.module';
import { NaooConstants } from '../NaooConstants';
import { NaooIconService } from '../services/images/naoo-icon.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

const { icons } = NaooConstants;

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatCardModule,
    MatButtonModule,
  ],
  declarations: [BulkAddModalComponent],
})
export class BulkAddModalModule {
  readonly bulkAddIcons = [icons.keep_cart, icons.remove_cart, icons.save_cart];

  constructor(private naooIconService: NaooIconService) {
    naooIconService.registerIcons(this.bulkAddIcons);
  }
}
