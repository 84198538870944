import {
  initialMaterialComparisonState,
  MaterialComparisonState,
} from './material-comparison.state';
import { MaterialComparisonActions } from './material-comparison.actions';
import { maximumMaterialComparisons } from './material-comparison.selectors';
import { createReducer, on } from '@ngrx/store';

export const materialComparisonReducer = createReducer(
  initialMaterialComparisonState,
  on(
    MaterialComparisonActions.toggleMaterialComparison,
    (state, action): MaterialComparisonState => ({
      ...state,
      isCollapsed: true,
      isEnabled: action.isEnabled,
    }),
  ),
  on(
    MaterialComparisonActions.toggleCollapseMaterialComparison,
    (state, action): MaterialComparisonState => ({
      ...state,
      isCollapsed: action.isCollapsed,
    }),
  ),
  on(
    MaterialComparisonActions.addMaterialComparison,
    (state, action): MaterialComparisonState => {
      const comparisonCount = state.materialNumbers.length;
      return comparisonCount >= maximumMaterialComparisons
        ? state
        : {
            ...state,
            materialNumbers: state.materialNumbers.concat(
              action.materialNumber,
            ),
          };
    },
  ),
  on(
    MaterialComparisonActions.removeMaterialComparison,
    (state, action): MaterialComparisonState => {
      const updatedMaterialNumbers = state.materialNumbers.filter(
        (materialNumber) => materialNumber !== action.materialNumber,
      );
      return {
        ...state,
        isCollapsed:
          updatedMaterialNumbers.length === 0 ? true : state.isCollapsed,
        materialNumbers: updatedMaterialNumbers,
      };
    },
  ),
  on(
    MaterialComparisonActions.removeAllMaterialComparisons,
    (state, _action): MaterialComparisonState => ({
      ...state,
      isCollapsed: true,
      materialNumbers: [],
    }),
  ),
  on(
    MaterialComparisonActions.forceHideMaterialComparisonDocket,
    (state, action): MaterialComparisonState => ({
      ...state,
      shouldForceHideDocket: action.shouldHideDocket,
    }),
  ),
  on(
    MaterialComparisonActions.hydrateMaterialComparison,
    (): MaterialComparisonState => initialMaterialComparisonState,
  ),
  on(
    MaterialComparisonActions.hydrateMaterialComparisonSuccess,
    (state, action): MaterialComparisonState => ({
      ...state,
      isEnabled: action.isComparisonEnabled,
      materialNumbers: action.materialNumbers,
    }),
  ),
  on(
    MaterialComparisonActions.loadFromMaterialList,
    (state, action): MaterialComparisonState => ({
      ...state,
      isEnabled: true,
      isCollapsed: true,
      materialNumbers: action.materialNumbers.slice(
        0,
        maximumMaterialComparisons,
      ),
    }),
  ),
);
