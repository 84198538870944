<ng-container>
  <button
    id="hamburger-icon"
    [attr.aria-label]="'MENU.HAMBURGER.ALT_TEXT' | translate"
    (click)="navigateToHamburgerMenu()"
    role="link"
  >
    <mat-icon svgIcon="hamburger_icon" class="hamburger"></mat-icon>
  </button>
</ng-container>
