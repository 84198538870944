<div class="duplicate-guide-container">
  <div
    class="duplicate-guide-text"
    [innerHTML]="
      'LISTS.TOAST_DUPLICATE_CUSTOM_GUIDE'
        | translate: { guideName: newGuideName }
    "
  ></div>
  <button class="toast-button" (click)="navigate()">
    {{ 'LISTS.TOAST_GO' | translate }}
  </button>
</div>
