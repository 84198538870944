<div class="material-wrapper" *ngIf="materialRow$ | async">
  <ng-container
    *ngIf="isUnavailable((materialRow$ | async)?.status); else orderable"
  >
    <naoo-material-unavailable
      [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
      [isMobile]="true"
      [isDeleteHidden]="true"
    >
    </naoo-material-unavailable>
  </ng-container>

  <ng-template #orderable>
    <naoo-material-image
      [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
      [isMobile]="true"
    ></naoo-material-image>
    <naoo-material-info
      [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
      [materialInfo]="(materialRow$ | async)?.materialRowInfo"
    ></naoo-material-info>
  </ng-template>
</div>
