import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { MaterialRowSharedOptions } from '../../../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-trash-button',
  templateUrl: './material-trash-button.component.html',
  styleUrls: ['./material-trash-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialTrashButtonComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  constructor(private cartFacade: CartFacade) {}

  deleteMaterial(): void {
    this.cartFacade.deleteCartMaterial(
      this.materialRowOptions.materialNumber,
      this.materialRowOptions.analytics?.recommendationEngineName,
    );
  }
}
