import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NutritionInfo,
  NutritionLabel,
} from '../../../../core/store/nutrition/models/nutrition';

@Component({
  selector: 'naoo-nutrition-facts-content',
  templateUrl: './nutrition-facts-content.component.html',
  styleUrls: ['./nutrition-facts-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutritionFactsContentComponent {
  @Input()
  set nutritionLabel(nutritionLabel: NutritionLabel) {
    this.nutritionInfos = nutritionLabel?.nutritionInfos;
    this.selectedInfo = this.nutritionInfos
      ? this.nutritionInfos[0]
      : undefined;
  }

  nutritionInfos: NutritionInfo[];
  selectedInfo: NutritionInfo;
  shouldRound = true;
}
