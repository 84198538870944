import { ExportDataPoint } from '../../../models/export/export-properties';
import * as PrintConstants from './export-materials-print-constants';
import { Styles, UserOptions } from 'jspdf-autotable';

export enum PageOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
  Invalid = 'invalid',
}

export enum SectionType {
  Head = 'head',
  Body = 'body',
}

export interface ExportMaterialsPrintStyle {
  cellWidth: number;
  overflow?: Overflow;
  valign?: VerticalAlignment;
  halign?: HorizontalAlignment;
}

export enum HorizontalAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum VerticalAlignment {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum Overflow {
  LineBreak = 'linebreak',
  Ellipsize = 'ellipsize',
  Visible = 'visible',
  Hidden = 'hidden',
  Normal = 'normal',
}

export const materialRowDataPointStyles: {
  [key: string]: Partial<Styles>;
} = {
  [ExportDataPoint.Brand]: {
    cellWidth: 64,
    overflow: Overflow.Ellipsize,
  },
  [ExportDataPoint.CasePrice]: {
    cellWidth: 45,
    halign: HorizontalAlignment.Right,
  },
  [ExportDataPoint.CasePriceUom]: {
    cellWidth: 38,
  },
  [ExportDataPoint.CatchWeight]: {
    cellWidth: 35,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.Category]: {
    cellWidth: 124,
  },
  [ExportDataPoint.ContractItemFlag]: {
    cellWidth: 52,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.CustomCategory]: {
    cellWidth: 124,
  },
  [ExportDataPoint.CustomerMaterialNumber]: {
    cellWidth: 45,
    overflow: Overflow.LineBreak,
  },
  [ExportDataPoint.DropShipFlag]: {
    cellWidth: 39,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.EarlyCutoffFlag]: {
    cellWidth: 39,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.Gtin]: {
    cellWidth: 68,
  },
  [ExportDataPoint.InventoryQty]: {
    cellWidth: 42,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.ItemDescription]: {
    cellWidth: 140,
    overflow: Overflow.LineBreak,
  },
  [ExportDataPoint.ItemNumber]: {
    cellWidth: 37,
  },
  [ExportDataPoint.NetWeight]: {
    cellWidth: 31,
    halign: HorizontalAlignment.Right,
  },
  [ExportDataPoint.PackSize]: {
    cellWidth: 32,
    halign: HorizontalAlignment.Right,
  },
  [ExportDataPoint.PackUom]: {
    cellWidth: 22,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.ParQty]: {
    cellWidth: 42,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.Qt1]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt2]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt3]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt4]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt5]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt6]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt7]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt8]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt9]: {
    cellWidth: 37,
  },
  [ExportDataPoint.Qt10]: {
    cellWidth: 37,
  },
  [ExportDataPoint.SpecialOrderFlag]: {
    cellWidth: 48,
    halign: HorizontalAlignment.Center,
  },
  [ExportDataPoint.StorageArea]: {
    cellWidth: 124,
  },
  [ExportDataPoint.UnitPrice]: {
    cellWidth: 45,
    halign: HorizontalAlignment.Right,
  },
  [ExportDataPoint.UnitPriceUom]: {
    cellWidth: 34,
  },
  [ExportDataPoint.UnitSize]: {
    cellWidth: 43,
    halign: HorizontalAlignment.Right,
  },
  [ExportDataPoint.VendorItemCode]: {
    cellWidth: 71,
  },
};

export const tableStyles: UserOptions = {
  theme: 'grid',
  styles: {
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
    fontSize: 8,
    minCellHeight: PrintConstants.minCellHeight,
    lineColor: [80, 80, 80],
    lineWidth: 0.5,
    cellPadding: 2.75,
    halign: HorizontalAlignment.Left,
    valign: VerticalAlignment.Middle,
  },
  headStyles: {
    overflow: Overflow.LineBreak,
    cellPadding: 3,
    halign: HorizontalAlignment.Left,
    valign: VerticalAlignment.Bottom,
    minCellHeight: 15,
    lineWidth: 0,
  },
  columnStyles: materialRowDataPointStyles,
  margin: PrintConstants.pageMargin,
  rowPageBreak: 'avoid',
};
