import { Injectable } from '@angular/core';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';
import { LocalizationService } from '../../../shared/services/translation/localization.service';
import { DateService } from '../../../shared/services/date/date.service';
import { StorePurchaseHistoryViewModel } from './models/store-purchase-history-view-model';
import { NON_BREAKING_SPACE_REGEX } from '../../../orders/orders/order-history/order-history-transformation.service';
import {
  getAddressLineTwo,
  getFormattedPhoneNumber,
} from '../../../shared/modals/order-method-modal/util/order-method-modal.utils';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { selectStorePurchaseHistoryTransformerData } from './store-purchase-history.selectors';
import { map } from 'rxjs/operators';
import { StorePurchaseHistoryStateStatus } from './store-purchase-history.state';
import { StorePurchaseHistoryRecord } from '../../services/store-purchase-history/model/store-purchase-history-record';
import { StoreRecord } from '../../services/store/model/store-record';

@Injectable({ providedIn: 'root' })
export class OrderHistoryTransformationService {
  private naooPricePipe: NaooPricePipe;

  constructor(
    private localizationService: LocalizationService,
    private dateService: DateService,
    private store: Store,
  ) {
    this.naooPricePipe = new NaooPricePipe(this.localizationService);
  }

  getStorePurchaseHistoryViewModel(): Observable<StorePurchaseHistoryViewModel> {
    return combineLatest([
      this.localizationService.locale(),
      this.store.select(selectStorePurchaseHistoryTransformerData),
    ]).pipe(
      map(([_, data]) => {
        const isLoading = !data.isFinishedLoading;
        if (
          StorePurchaseHistoryStateStatus.Error === data.status &&
          !data.historyRecords.length
        ) {
          return {
            purchases: [],
            customerTimezone: data.customerTimezone,
            isLoading,
            shouldDisplayErrorPage: true,
            shouldDisplayNoResults: false,
            startDate: data.startDate,
          };
        }

        return {
          purchases: data.historyRecords.map((recordState) => {
            const record = recordState.record;
            return {
              storeId: record.storeId,
              saleDate: record.saleDate,
              laneNumber: record.laneNumber,
              saleNumber: record.saleNumber,
              purchaseKey: recordState.entityKey,
              storeRecord: data.storeRecordDictionary[record.storePlantId],
              saleTimestamp: record.saleTimestamp,
              netTransactionAmount: record.netTransactionAmount,
              pointsEarned: record.pointsEarned,
              searchableStrings: this.generateSearchableStrings(
                record,
                data.storeRecordDictionary[record.storePlantId],
              ),
            };
          }),
          customerTimezone: data.customerTimezone,
          isLoading,
          shouldDisplayNoResults:
            data.isFinishedLoading && !data.historyRecords.length,
          shouldDisplayErrorPage: false,
          startDate: data.startDate,
        };
      }),
    );
  }

  private generateSearchableStrings(
    purchaseRecord: StorePurchaseHistoryRecord,
    storeRecord: StoreRecord,
  ): string[] {
    const searchableStrings = [
      this.dateService.getFormattedDate(purchaseRecord.saleDate, 'shortDate'),
      purchaseRecord.pointsEarned?.toString(),
      this.naooPricePipe
        .transform(purchaseRecord.netTransactionAmount, undefined, '')
        .replace(NON_BREAKING_SPACE_REGEX, ' '),
    ];

    if (storeRecord) {
      const storeSearchableStrings = [
        storeRecord.name,
        getFormattedPhoneNumber(storeRecord.phoneNumber),
        storeRecord.address.address1,
        getAddressLineTwo(storeRecord.address),
      ];

      searchableStrings.push(...storeSearchableStrings);
    }

    return searchableStrings
      .filter((element) => element && element.length)
      .map((field) => field.toLowerCase());
  }
}
