<mat-expansion-panel
  class="generic-help-panel-content"
  *ngIf="!!section"
  [ngClass]="{ first: isFirst, last: isLast }"
>
  <mat-expansion-panel-header
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
  >
    <mat-panel-title>
      {{ section.title | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="paragraph-content" *ngFor="let paragraph of section.paragraphs">
    <p class="paragraph">{{ paragraph | translate }}</p>
  </div>
  <div class="list-items-content" *ngIf="!!section.imageDetails">
    <div class="ordered-list">
      <ng-container *ngFor="let listItem of section.imageDetails.listItems">
        <div class="list-item">{{ listItem | translate }}</div>
      </ng-container>
    </div>
    <div class="help-img" *ngIf="!!section.imageDetails.image">
      <img
        [alt]="section.imageDetails.image.altText | translate"
        [src]="section.imageDetails.image.src | translate"
        [height]="section.imageDetails.image.height"
        [width]="section.imageDetails.image.width"
      />
    </div>
  </div>
</mat-expansion-panel>
