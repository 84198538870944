import { SessionCustomer } from '../../core/services/session/models/session-record';

export class SearchableCustomer {
  customer: SessionCustomer;
  isChecked?: boolean;

  constructor(customer: SessionCustomer) {
    this.customer = customer;
    this.isChecked = false;
  }

  get searchableCustomerAttributes(): string {
    return [
      this.customer.customerDisplayId,
      this.customer.name,
      this.customer.address.line2,
      this.customer.address.city,
      this.customer.address.postalCode,
      this.customer.address.provinceState,
    ].join(' ');
  }
}
