import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'naoo-generic-docket-header',
  templateUrl: './generic-docket-header.component.html',
  styleUrls: ['./generic-docket-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericDocketHeaderComponent {
  @Input() title: string;
  @Input() tooltipKey?: string;
  @Output() closeDocketEmitter = new EventEmitter();
  @ViewChild('tooltip') manualTooltip: MatTooltip;
}
