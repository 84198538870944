import { filter, first, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NaooAnalyticsManager } from '../../analytics/NaooAnalyticsManager';
import { DeliverySchedule } from '../../models/delivery-schedule';
import { forkJoin } from 'rxjs';
import { DeliveryDatePickerContainerComponent } from '../delivery-date-picker-container.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsEventInfo } from '../../analytics/analytics-event-info';
import { DeliveryScheduleFacade } from 'src/app/core/store/delivery-schedule/delivery-schedule.facade';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { CurrentSystem } from '../../../core/services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class DeliveryDatePickerService {
  constructor(
    private deliveryScheduleFacade: DeliveryScheduleFacade,
    private cartFacade: CartFacade,
    private naooAnalyticsManager: NaooAnalyticsManager,
    private dialog: MatDialog,
    private sessionFacade: SessionFacade,
  ) {}

  private isOpen = false;

  showDeliveryDatePickerIfNeeded(
    isFirstDisplay: boolean = false,
    isImportOrder: boolean = false,
    isPastCutoff: boolean = false,
    shouldRefreshDeliverySchedules: boolean = false,
    closeAction?: () => void,
  ) {
    if (shouldRefreshDeliverySchedules) {
      this.deliveryScheduleFacade.refreshDeliverySchedules();
    }

    forkJoin([
      this.deliveryScheduleFacade.getLoadedDeliverySchedules().pipe(first()),
      this.cartFacade.getLoadedRouteDate().pipe(first()),
      this.cartFacade.getLoadedCustomerArrivalDate().pipe(first()),
      this.sessionFacade.getLoadedCurrentSystem().pipe(first()),
    ])
      .pipe(
        filter(
          ([
            deliverySchedules,
            routeDate,
            customerArrivalDate,
            currentSystem,
          ]) =>
            (isPastCutoff ||
              this.hasMissingOrExpiredRouteDate(
                deliverySchedules,
                routeDate,
                customerArrivalDate,
                currentSystem,
              )) &&
            currentSystem !== CurrentSystem.Migrating,
        ),
        tap(() => {
          this.openRouteDatePickerDialog(
            isFirstDisplay,
            isImportOrder,
            isPastCutoff,
            closeAction,
          );
        }),
      )
      .subscribe();
  }

  showDeliveryDatePicker(
    shouldRefreshDeliverySchedules: boolean,
    isImportOrder: boolean = false,
    isPastCutoff: boolean = false,
  ) {
    if (shouldRefreshDeliverySchedules) {
      this.deliveryScheduleFacade.refreshDeliverySchedules();
    }

    this.deliveryScheduleFacade
      .getLoadedDeliverySchedules()
      .pipe(
        first(),
        filter(
          (deliverySchedules) =>
            deliverySchedules && deliverySchedules.length > 0,
        ),
        tap(() => {
          this.openRouteDatePickerDialog(false, isImportOrder, isPastCutoff);
        }),
      )
      .subscribe();
  }

  private hasMissingOrExpiredRouteDate(
    deliverySchedules: DeliverySchedule[],
    routeDate: Date,
    customerArrivalDate: Date,
    currentSystem: CurrentSystem,
  ): boolean {
    if (!deliverySchedules || deliverySchedules.length === 0) {
      return false;
    }
    if (!routeDate) {
      return true;
    }
    const selectedDeliverySchedule = this.findSelectedDeliverySchedule(
      deliverySchedules,
      routeDate,
    );

    if (
      CurrentSystem.isMygfsOrSap(currentSystem) &&
      !!selectedDeliverySchedule
    ) {
      if (
        customerArrivalDate &&
        customerArrivalDate.valueOf() !==
          selectedDeliverySchedule.customerArrivalDateAsDate().valueOf()
      ) {
        return true;
      } else if (!customerArrivalDate) {
        // this resolves the situation where a cartOrder is missing the customerArrivalDate
        this.cartFacade.updateRouteDate(
          routeDate,
          selectedDeliverySchedule.customerArrivalDateAsDate(),
        );
      }
    }
    return !selectedDeliverySchedule;
  }

  private findSelectedDeliverySchedule(
    deliverySchedules: DeliverySchedule[],
    routeDate: Date,
  ): DeliverySchedule {
    return deliverySchedules.find(
      (deliverySchedule) =>
        deliverySchedule.routeDateAsDate().valueOf() === routeDate.valueOf(),
    );
  }

  private openRouteDatePickerDialog(
    isFirstDisplay: boolean,
    isImportOrder: boolean,
    isPastCutoff: boolean,
    closeAction?: () => void,
  ) {
    if (!this.isOpen) {
      this.isOpen = true;
      this.trackDatePickerShown();
      this.dialog
        .open(DeliveryDatePickerContainerComponent, {
          id: 'delivery-date-picker-legacy',
          data: {
            isFirstDisplay: isFirstDisplay,
            isImportOrder: isImportOrder,
            isPastCutoff: isPastCutoff,
            closeAction,
          },
          panelClass: 'naoo-delivery-date-picker-modal',
          disableClose: true,
          maxWidth: '86vw',
        })
        .afterClosed()
        .subscribe(() => (this.isOpen = false));
    }
  }

  private trackDatePickerShown() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'displays',
      category: 'login',
      label: 'ship date modal',
    };

    this.naooAnalyticsManager.trackAnalyticsEvent(eventInfo);
  }
}
