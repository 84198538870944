import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'naoo-complete-modal',
  templateUrl: './complete-modal.component.html',
  styleUrls: ['./complete-modal.component.scss'],
})
export class CompleteModalComponent {
  @Input() successIconPath: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() completeButtonMessage: string;
  @Input() imageAltText = '';

  @Output() navigateToResults = new EventEmitter();

  onComplete() {
    this.navigateToResults.emit();
  }
}
