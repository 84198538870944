import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooConstants } from '../NaooConstants';

@Component({
  selector: 'naoo-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductImageComponent {
  @Input() materialNumber: string;
  @Input() dimension: number;
  @Input() alt = '';
  @Input() src: string;
  @Input() srcSet: string;
  @Input() isDraggable = false;

  noImageAvailableURL($event: UIEvent): void {
    (<HTMLImageElement>$event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>$event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }
}
