import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class NotOnlyWhitespaceValidator {
  validate(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      const isOnlyWhitespace =
        ((ctrl && ctrl.value && ctrl.value.toString()) || '').trim().length ===
        0;
      return !isOnlyWhitespace ? null : { isOnlyWhitespace: true };
    };
  }
}
