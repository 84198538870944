import { Component, Input } from '@angular/core';
import { AllergenInfo } from '../../shared/models/material-additional-info';

@Component({
  selector: 'naoo-allergen-details',
  templateUrl: './allergen-details.component.html',
  styleUrls: ['./allergen-details.component.scss'],
})
export class AllergenDetailsComponent {
  @Input() allergenInfo: AllergenInfo;
  @Input() hideBorder = false;

  allergenInfoOrder = {
    peanuts: 'ALLERGENS.PEANUTS',
    crustacean: 'ALLERGENS.CRUSTACEAN',
    treeNuts: 'ALLERGENS.TREENUTS',
    shellfish: 'ALLERGENS.SHELLFISH',
    sesame: 'ALLERGENS.SESAME',
    soy: 'ALLERGENS.SOY',
    fish: 'ALLERGENS.FISH',
    wheat: 'ALLERGENS.WHEAT',
    milk: 'ALLERGENS.MILK',
    sulphites: 'ALLERGENS.SULPHITES',
    eggs: 'ALLERGENS.EGGS',
    gluten: 'ALLERGENS.GLUTEN',
    mustard: 'ALLERGENS.MUSTARD',
  };

  allergens: string[] = [];

  constructor() {
    this.allergens = Object.keys(this.allergenInfoOrder);
  }

  allergenStatus(status: string): string {
    const NO_DATA = '\u2014';

    if (!status) {
      return NO_DATA;
    }
    switch (status.toLocaleLowerCase()) {
      case 'c':
      case 'y':
      case 'yes':
      case 'contains':
        return 'ALLERGENS.CONTAINS';

      case 'n':
      case 'no':
        return 'ALLERGENS.NO';

      case 'may contain':
      case 'm':
        return 'ALLERGENS.MAY_CONTAIN';

      case 'free from':
        return 'ALLERGENS.DOES_NOT_CONTAIN';

      case 'n/a':
        return 'ALLERGENS.NOT_APPLICABLE';

      default:
        return NO_DATA;
    }
  }
}
