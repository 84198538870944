import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { OrderMethodModalComponent } from './order-method-modal.component';
import { NaooIconService } from '../../services/images/naoo-icon.service';
import { NaooConstants } from '../../NaooConstants';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrderMethodStepComponent } from './steps/order-method-step/order-method-step.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../../shared.module';
import { MatRadioModule } from '@angular/material/radio';
import { SelectStoreStepComponent } from './steps/select-store-step/select-store-step.component';
import { GoogleMapsNaooModule } from '../../maps/google-maps.module';
import { OrderMethodModalSharedModule } from './shared/order-method-modal-shared.module';
import { SelectDateStepModule } from './steps/select-date/select-date-step.module';

const { icons } = NaooConstants;

@NgModule({
  imports: [
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatStepperModule,
    TranslateModule.forChild(),
    MatDatepickerModule,
    MatRadioModule,
    GoogleMapsNaooModule,
    OrderMethodModalSharedModule,
    SelectDateStepModule,
  ],
  declarations: [
    OrderMethodModalComponent,
    OrderMethodStepComponent,
    SelectStoreStepComponent,
  ],
})
export class OrderMethodModalModule {
  readonly orderMethodModalIcons = [icons.semi_truck];

  constructor(public naooIconService: NaooIconService) {
    naooIconService.registerIcons(this.orderMethodModalIcons);
  }
}
