import {
  AuxiliaryAnalyticsData,
  GAAddToCartConflictEvent,
  GACartEvent,
  GACartItem,
  GACartItemAdjustmentEvent,
  GACWishlistEvent,
  GAEcommerceEvent,
  GAEcommerceItem,
  GAEvent,
  GAEventName,
  GAFilterEvent,
  GALoyaltyCheckRequestEvent,
  GANoStockEvent,
  GANoStockPurchaseEvent,
  GAPageViewEvent,
  GASavedCartEvent,
  GASearchRequestEvent,
  GASelectShipDateEvent,
  GASponsoredSearchResultsEvent,
  GAViewItemListEvent,
  GAViewModalEvent,
  NoMaterialsGAPromotionEvent,
  PromotionData,
  PromotionEventData,
  PromotionType,
} from './models/google-events';
import { SessionRecord } from '../session/models/session-record';
import { getCookie } from '../../../shared/utilities/cookie-utilities';
import { NaooConstants } from '../../../shared/NaooConstants';
import { Injectable } from '@angular/core';
import {
  BaseAnalyticInformation,
  CartAnalyticInformation,
  MaterialAdjustmentAnalyticInformation,
  MaterialAnalyticInformation,
} from '../../store/ecommerce-analytics/ecommerce-analytics.selectors';
import { BannerAd } from '../../store/banner-ads/banner-ads.state';
import { RecommendationBanner } from '../../store/search/search.state';
import { UrlSerializerService } from '../../../shared/services/url-serializer/url-serializer.service';
import { CartEntityState } from '../../store/cart/cart.state';
import { SponsoredSearchData } from '../../store/search/search.selectors';
import { MaterialRowContext } from '../../store/material-row/models/material-row';
import { MaterialQuantityDetail } from '../../store/shared/shared';
import { CombinedUnitPriceRecord } from '../../store/material-price/material-price.util';
import { MaterialRecord } from '../material-info/models/materials-info-record';
import { MaterialWarningType } from '../../store/material-warning/material-warning';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsTransformerService {
  private readonly maxDescriptionLength = 50;
  private readonly chunkSize = 25;

  constructor(private urlSerializerService: UrlSerializerService) {}

  buildBaseGAEvent(eventName: GAEventName, url?: string): GAEvent {
    return {
      event: eventName.valueOf(),
      interaction: {
        event_timestamp: Date.now(),
        ...(!!url && { page_path: url }),
      },
    };
  }

  buildGAPageViewEvent(
    url: string,
    sessionRecord: SessionRecord,
    clientId: string,
  ): GAPageViewEvent {
    const activeCustomer = sessionRecord?.activeCustomer;
    const siteAddress = activeCustomer?.sites?.find(
      (site) => activeCustomer.primarySiteId === site.siteId,
    )?.address;
    const paramMap = this.urlSerializerService.buildParamMapFromUrl(url);
    return {
      ...this.buildBaseGAEvent(GAEventName.PageView, url),
      customer: {
        customer_unit: activeCustomer?.customerDisplayId,
        sap_plant_id: activeCustomer?.plantNumber,
        personalization_id: getCookie(NaooConstants.PERSONALIZATION_COOKIE),
        segment: activeCustomer?.segmentId,
        sfmc_user_id: paramMap.get('sfmc_s'),
      },
      user: {
        ga4_client_id: clientId,
        site_country: siteAddress?.countryCode,
        site_language: sessionRecord?.language,
        ga4_user_id: sessionRecord?.guid,
        internal_user: sessionRecord?.isInternalUser,
      },
      utm: {
        utm_campaign_id: paramMap.get('promo_id'),
        utm_promo_code: paramMap.get('promo_code'),
        utm_email_template_position: paramMap.get('promo_position')
          ? +paramMap.get('promo_position')
          : null,
      },
    };
  }

  buildGAFilterEvent(category: string, value: string): GAFilterEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.FilterApplied),
      filter: {
        filter_category: category,
        filter_option: value,
      },
    };
  }

  buildGASelectShipDateEvent(selectedDate: string): GASelectShipDateEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.SelectShipDate),
      shipping: {
        ship_date: selectedDate,
      },
    };
  }

  buildGAViewModalEvent(modalName: string): GAViewModalEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.ViewModal),
      modal: {
        modal_name: modalName,
      },
    };
  }

  buildGASponsoredSearchResultsEvent(
    sponsoredSearchData: SponsoredSearchData,
  ): GASponsoredSearchResultsEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.SponsoredSearchResults),
      sponsored: {
        sponsored_search_term: sponsoredSearchData.searchTerm,
        sponsored_banner: sponsoredSearchData.hasBanners,
        sponsored_item: sponsoredSearchData.hasMaterials,
        sponsored_result_campaign: sponsoredSearchData.firstCampaignId,
      },
    };
  }

  buildNoMaterialsGAPromotionEvent(
    eventName: GAEventName,
    {
      creativeSlot,
      creativeName,
      promotionId,
      promotionName,
      promotionCode,
      promotionType,
      promotionTypeValue,
    }: PromotionData,
  ): NoMaterialsGAPromotionEvent {
    return {
      ...this.buildBaseGAEvent(eventName),
      ecommerce: {
        items: [],
        creative_slot: creativeSlot,
        creative_name: creativeName,
        promotion_id: promotionId,
        promotion_name: promotionName,
        promotion_code: promotionCode,
        ...(promotionType === PromotionType.Collections && {
          collection_name_event: promotionTypeValue,
        }),
        ...(promotionType === PromotionType.Site && {
          content_name_event: promotionTypeValue,
        }),
      },
    };
  }

  buildGAEcommerceEvent({
    eventName,
    additionalMaterialInfo,
    data,
  }: {
    eventName: GAEventName;
    additionalMaterialInfo: MaterialAnalyticInformation;
    data?: {
      analytics?: AuxiliaryAnalyticsData;
      context?: MaterialRowContext;
      materialRecordMap?: Map<string, MaterialRecord>;
      promotionData?: PromotionData;
    };
  }): GAEcommerceEvent {
    const combinedPrice = additionalMaterialInfo.combinedPricingMap?.get(
      additionalMaterialInfo.materialNumbers[0],
    );

    const {
      creativeSlot,
      creativeName,
      promotionId,
      promotionName,
      promotionCode,
      promotionType,
      promotionTypeValue,
    } = data?.promotionData ?? {};

    return {
      ...this.buildBaseGAEvent(eventName),
      ecommerce: {
        currency: combinedPrice?.currency,
        creative_slot: creativeSlot,
        creative_name: creativeName,
        promotion_id: promotionId,
        promotion_name: promotionName,
        promotion_code: promotionCode,
        ...(promotionType === PromotionType.Collections && {
          collection_name_event: promotionTypeValue,
        }),
        ...(promotionType === PromotionType.Site && {
          content_name_event: promotionTypeValue,
        }),
        items: this.buildGAEcommerceEventItems(
          eventName,
          additionalMaterialInfo,
          data,
        ),
      },
    };
  }

  buildGACartEvent(
    eventName: GAEventName,
    cartEntityState: CartEntityState,
    analyticInformation: CartAnalyticInformation,
    hasPendingOrders?: boolean,
  ): GACartEvent {
    const cartPriceTotals = analyticInformation.cartPriceTotals;
    const totalSplitOrders = cartEntityState.splitOrders?.length ?? 0;
    return {
      ...this.buildBaseGAEvent(eventName),
      ecommerce: {
        transaction_id: cartEntityState.id,
        items: this.buildGACartItems({ analyticInformation, eventName }),
        purchase_orders: totalSplitOrders + 1,
        coupons: cartEntityState.couponCodes,
        cart_discount: cartPriceTotals.estimatedSavings,
        quantity: cartPriceTotals.cartCounts.totalQuantity,
        value: +cartPriceTotals.estimatedCost.toFixed(2),
        currency: cartPriceTotals.currency,
        shipping: +cartPriceTotals.shippingCost.toFixed(2),
        ship_date: analyticInformation.routeDate?.toLocaleDateString(),
        ...(hasPendingOrders !== undefined && {
          order_submission_pending: hasPendingOrders,
        }),
      },
    };
  }

  buildGAWishlistEvent(
    eventName: GAEventName,
    analyticInformation: MaterialAdjustmentAnalyticInformation,
    guideType: string,
  ): GACWishlistEvent {
    return {
      ...this.buildBaseGAEvent(eventName),
      guide: {
        guide_type: guideType,
      },
      ecommerce: {
        items: this.buildGACartItems({ analyticInformation, eventName }),
        value: analyticInformation.value,
        currency: analyticInformation.currency,
      },
    };
  }

  buildGACartItemAdjustmentEvent(
    eventName: GAEventName,
    analyticInformation: MaterialAdjustmentAnalyticInformation,
    data?: {
      analytics?: AuxiliaryAnalyticsData;
      context?: MaterialRowContext;
    },
  ): GACartItemAdjustmentEvent {
    return {
      ...this.buildBaseGAEvent(eventName),
      ecommerce: {
        items: this.buildGACartItems({
          analyticInformation,
          eventName,
          data,
        }),
        value: analyticInformation.value,
        currency: analyticInformation.currency,
        ...(data?.context && {
          add_to_cart_type: data.context,
        }),
      },
    };
  }

  buildGAViewItemListEventForNoResults(
    searchText: string,
  ): GAViewItemListEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.ViewItemList),
      ecommerce: {
        items: [],
        total_items_list: 0,
        value: 0,
        currency: undefined,
        search_term: searchText,
      },
    } as GAViewItemListEvent;
  }

  buildGAViewItemListEvents(
    eventName: GAEventName,
    analyticInformation: MaterialAdjustmentAnalyticInformation,
    context: MaterialRowContext,
    analytics?: AuxiliaryAnalyticsData,
  ): GAViewItemListEvent[] {
    const items = this.buildGACartItems({
      analyticInformation,
      eventName,
      data: {
        analytics,
        context,
      },
    });
    const response: GAViewItemListEvent[] = [];
    for (let i = 0; i < items.length; i += this.chunkSize) {
      const partition = items.slice(i, i + this.chunkSize);
      response.push({
        ...this.buildBaseGAEvent(eventName),
        ecommerce: {
          items: partition,
          total_items_list: partition.length,
          value: analyticInformation.value,
          currency: analyticInformation.currency,
          ...(analytics?.searchText && { search_term: analytics.searchText }),
        },
      });
    }
    return response;
  }

  buildGASavedCartEvent(
    eventName: GAEventName,
    isAuthorizedPurchaser: boolean,
    linkText: string,
  ): GASavedCartEvent {
    return {
      ...this.buildBaseGAEvent(eventName),
      user: {
        authorized_purchaser: isAuthorizedPurchaser,
      },
      link_text: linkText,
    };
  }

  buildGANoStockEvent(
    eventName: GAEventName,
    materialNumber: string,
    materialName?: string,
  ): GANoStockEvent {
    return {
      ...this.buildBaseGAEvent(eventName),
      products: {
        product_id: materialNumber,
        product_name: materialName,
      },
    };
  }

  buildGANoStockPurchaseEvent(
    materialNumber: string,
    materialName: string,
    shipDate: string,
  ): GANoStockPurchaseEvent {
    return {
      ...this.buildGANoStockEvent(
        GAEventName.PurchaseOutOfStock,
        materialNumber,
        materialName,
      ),
      shipping: {
        ship_date: shipDate,
      },
    };
  }

  buildLoyaltyCheckRequestEvent(
    numberOfChecks: number,
  ): GALoyaltyCheckRequestEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.SubmitCheckRequest),
      check_request: {
        count: numberOfChecks,
      },
    };
  }

  buildSearchRequestEvent(searchTerm: string): GASearchRequestEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.SearchRequest),
      search_term: searchTerm,
    };
  }

  buildPromotionDataArray(data: PromotionEventData): PromotionData[] {
    const { bannerAds, materials, recommendationBanner } = data;

    const promotions =
      bannerAds?.map((bannerAd: BannerAd) =>
        this.buildPromotionData(bannerAd.ctTargetUrl, materials),
      ) || [];

    if (recommendationBanner) {
      promotions.push(
        this.buildPromotionData(
          recommendationBanner?.destinationUrl,
          materials,
          recommendationBanner,
        ),
      );
    }
    return promotions;
  }

  buildGAAddToCartConflictEvent(
    modal_name: string,
    add_to_cart_type: string,
    index: number,
    action_id: number,
    cta_text: string,
  ): GAAddToCartConflictEvent {
    return {
      ...this.buildBaseGAEvent(GAEventName.AddToCartConflict),
      action: {
        modal_name,
        add_to_cart_type,
        index,
        action_id,
        cta_text,
      },
    };
  }

  private buildPromotionData(
    url: string,
    materials: string[],
    recommendationBanner?: RecommendationBanner,
  ): PromotionData {
    const paramMap = this.urlSerializerService.buildParamMapFromUrl(url);
    const urlMaterials =
      this.urlSerializerService.getMaterialNumbersFromUrl(url);

    const promotionType = Object.values(PromotionType).find((type) =>
      url.startsWith(type),
    );

    // extract the part of the url between the path and the query parameters
    // e.g. /site/sienna-bakery?promo_id=xxx -> sienna-bakery
    const queryParamIndex = url.indexOf('?');
    const promotionTypeValueEndIndex =
      queryParamIndex !== -1 ? queryParamIndex : url.length;
    const promotionTypeValue = promotionType
      ? url.substring(
          url.indexOf(promotionType) + promotionType.length + 1,
          promotionTypeValueEndIndex,
        )
      : undefined;

    return {
      materialNumbers: urlMaterials,
      creativeSlot: paramMap.get('promo_position'),
      creativeName: paramMap.get('promo_creative'),
      promotionId: paramMap.get('promo_id'),
      promotionName: paramMap.get('promo_name'),
      promotionCode: paramMap.get('promo_code'),
      promotionType,
      promotionTypeValue,
      sponsoredSearchTerm: recommendationBanner?.searchTerm,
      sponsoredBanner: !!recommendationBanner,
      sponsoredItem: !!materials?.some((material) =>
        urlMaterials.includes(material),
      ),
    };
  }

  private buildGACartItems({
    analyticInformation,
    eventName,
    data,
  }: {
    analyticInformation: BaseAnalyticInformation;
    eventName: GAEventName;
    data?: {
      analytics?: AuxiliaryAnalyticsData;
      context?: MaterialRowContext;
    };
  }): GACartItem[] {
    const isSponsoredAddToCart = [
      GAEventName.AddToCart,
      GAEventName.AddToWishlist,
      GAEventName.RemoveFromCart,
      GAEventName.BeginCheckout,
      GAEventName.Purchase,
      GAEventName.ViewCart,
    ].includes(eventName);

    const isSponsoredIndex = [
      GAEventName.ViewItem,
      GAEventName.SelectItem,
      GAEventName.AddToWishlist,
    ].includes(eventName);

    return analyticInformation.materialQuantities
      .filter((materialQuantity) => !materialQuantity.isDeleted)
      .flatMap((materialDetail: MaterialQuantityDetail) => {
        const materialNumber = materialDetail.materialNumber;
        const materialRecord =
          analyticInformation.materialRecords.get(materialNumber);
        const pricingRecord =
          analyticInformation.materialPrices.get(materialNumber);
        const additionalInfo =
          analyticInformation.additionalInfos.get(materialNumber);
        const materialSponsoredSearchState =
          analyticInformation.sponsoredSearchAnalytics?.allSponsoredSearchStatesMap.get(
            materialNumber,
          );
        const warning =
          analyticInformation.limitedStockWarnings.get(materialNumber);
        const isPromotionMaterial = materialSponsoredSearchState?.sponsored;
        const includeItemListId = isSponsoredIndex && isPromotionMaterial;
        return materialDetail.lines.flatMap((quantityLine) => {
          const unitPriceRecord = pricingRecord?.unitPrices?.find(
            (price: CombinedUnitPriceRecord) =>
              price.salesUom === quantityLine.uom,
          );
          return {
            index: quantityLine.index,
            item_id: materialNumber,
            item_variant: quantityLine.uom,
            quantity: quantityLine.quantity,
            item_name: materialRecord?.description?.en?.slice(
              0,
              this.maxDescriptionLength,
            ),
            item_brand: materialRecord?.brand?.en,
            price: unitPriceRecord?.price,
            discount: unitPriceRecord?.discountAmount,
            coupon: unitPriceRecord?.coupon,
            item_category: additionalInfo?.categoryLevel1?.en,
            sponsored_search_term: materialSponsoredSearchState?.searchTerm,
            sponsored_banner: materialSponsoredSearchState?.hasBanners,
            sponsored_item: materialSponsoredSearchState?.hasMaterials,
            sponsored_result_campaign: materialSponsoredSearchState?.campaignId,
            item_list_id: includeItemListId
              ? materialSponsoredSearchState?.campaignId
              : undefined,
            stock_warning: getStockWarningDescription(warning),
            ...(!isSponsoredAddToCart &&
              !isSponsoredIndex && { sponsored: isPromotionMaterial }),
            ...(isSponsoredAddToCart && {
              sponsored_add_to_cart: isPromotionMaterial,
            }),
            ...(isSponsoredIndex && { sponsored_index: isPromotionMaterial }),
            ...(data?.context && {
              item_list_name: data.context,
            }),
            ...(data?.analytics?.collectionName && {
              collection_name: data.analytics.collectionName,
            }),
            ...(data?.analytics?.recommendationEngineName && {
              rec_engine_name: data.analytics.recommendationEngineName,
            }),
            ...(data?.analytics?.searchResultsIndex && {
              search_results_index: data.analytics.searchResultsIndex,
            }),
            ...(data?.analytics?.searchText && {
              search_term: data.analytics.searchText,
            }),
            ...(data?.analytics?.itemSubheadingMap?.has(materialNumber) && {
              subheading: data?.analytics.itemSubheadingMap.get(materialNumber),
            }),
          };
        });
      });
  }

  private buildGAEcommerceEventItems(
    eventName: GAEventName,
    additionalMaterialInfo: MaterialAnalyticInformation,
    data?: {
      analytics?: AuxiliaryAnalyticsData;
      context?: MaterialRowContext;
      materialRecordMap?: Map<string, MaterialRecord>;
      promotionData?: PromotionData;
    },
  ): GAEcommerceItem[] {
    const isPromotionEvent = [
      GAEventName.ViewPromotion,
      GAEventName.SelectPromotion,
    ].includes(eventName);

    return additionalMaterialInfo.materialNumbers.map((materialNumber) => {
      const combinedPrice =
        additionalMaterialInfo.combinedPricingMap.get(materialNumber);
      const materialSponsoredSearchState =
        additionalMaterialInfo.sponsoredSearchAnalytics.allSponsoredSearchStatesMap.get(
          materialNumber,
        );
      const materialAdditionalInfoRecord =
        additionalMaterialInfo.additionalInfoMap.get(materialNumber);
      const materialRecord = data?.materialRecordMap?.get(materialNumber);
      const isPromotionMaterial = materialSponsoredSearchState?.sponsored;
      const includeItemListId = isPromotionEvent && isPromotionMaterial;

      return {
        item_id: materialNumber,
        item_name: materialRecord?.description?.en.slice(
          0,
          this.maxDescriptionLength,
        ),
        item_brand: materialRecord?.brand?.en,
        price: combinedPrice?.unitPrices?.[0]?.price,
        item_category: materialAdditionalInfoRecord?.categoryLevel1?.en,
        sponsored_search_term: materialSponsoredSearchState?.searchTerm,
        sponsored_banner: materialSponsoredSearchState?.hasBanners,
        sponsored_item: materialSponsoredSearchState?.hasMaterials,
        sponsored_result_campaign: materialSponsoredSearchState?.campaignId,
        item_list_id: includeItemListId
          ? materialSponsoredSearchState?.campaignId
          : undefined,
        ...(isPromotionEvent && { sponsored: isPromotionMaterial }),
        ...(!isPromotionEvent && { sponsored_index: isPromotionMaterial }),
        ...(data?.context && { item_list_name: data.context }),
        ...(data?.analytics?.collectionName && {
          collection_name: data.analytics.collectionName,
        }),
        ...(data?.analytics?.recommendationEngineName && {
          rec_engine_name: data.analytics.recommendationEngineName,
        }),
        ...(data?.analytics?.searchResultsIndex && {
          search_results_index: data.analytics.searchResultsIndex,
        }),
        ...(data?.analytics?.searchText && {
          search_term: data.analytics.searchText,
        }),
        ...(data?.analytics?.itemSubheadingMap?.has(materialNumber) && {
          subheading: data?.analytics.itemSubheadingMap.get(materialNumber),
        }),
      };
    });
  }
}

function getStockWarningDescription(warningType: MaterialWarningType): string {
  switch (warningType) {
    case MaterialWarningType.NoStock: {
      return 'no_stock';
    }
    case MaterialWarningType.PartialStockCase:
    case MaterialWarningType.PartialStockCaseUnit: {
      return 'partial';
    }
    default: {
      return undefined;
    }
  }
}
