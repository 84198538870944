import {
  Language,
  Locale,
} from '../../core/services/session/models/session-record';

export function getLanguageTag(locale: Locale): string {
  // ex. 'en-CA' - en is the language tag, CA is the subtag
  switch (locale) {
    case Locale.en_CA:
      return 'en-CA';
    case Locale.fr_CA:
      return 'fr-CA';
    case Locale.es_US:
      return 'es-US';
    case Locale.en_US:
    default:
      return 'en-US';
  }
}

export function getLanguage(locale: Locale): Language {
  switch (locale) {
    case Locale.fr_CA:
      return Language.fr;
    case Locale.es_US:
      return Language.es;
    case Locale.en_CA:
    case Locale.en_US:
    default:
      return Language.en;
  }
}

export function getCurrencyCode(locale: Locale): string {
  switch (locale) {
    case Locale.en_CA:
    case Locale.fr_CA:
      return 'CAD';
    case Locale.es_US:
    case Locale.en_US:
    default:
      return 'USD';
  }
}
