import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { commodityPriceReducer } from './commodity-price.reducer';
import { CommodityPriceEffects } from './commodity-price.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('commodityPrice', commodityPriceReducer),
    EffectsModule.forFeature([CommodityPriceEffects]),
  ],
})
export class CommodityPriceStoreModule {}
