import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MaterialInfoFacade } from '../../../core/store/material-info/material-info.facade';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { MaterialRecommendations } from './models/material-recommendations';
import { WebBffService } from '../web-bff/web-bff.service';
import { MaterialInfo } from '../../models/material-info';
import { MaterialRecommendationsRecord } from './models/material-recommendations-record';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import {
  MaterialListRow,
  MaterialListRowType,
} from '../../../material-list/models/material-list';
import {
  SmartCartRecommendation,
  SmartCartRequest,
} from '../../../core/store/smart-cart-recommendations/smart-cart-recommendations.state';
import { NaooHttpClient } from '../../http-client/naoo-http-client';
import { silentRequestConfiguration } from '../../http-client/naoo-request-configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MaterialRecommendationsService {
  constructor(
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private httpClient: HttpClient,
    private naooHttpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getMaterialRecommendations(
    page: string,
    materialNumber?: string,
  ): Observable<MaterialRecommendations[]> {
    return this.httpClient
      .get<MaterialRecommendationsRecord[]>(
        this.webBffService.getBff() + '/api/v2/recommendations',
        {
          params: {
            page,
            ...(!!materialNumber && { materialNumber }),
          },
        },
      )
      .pipe(
        first(),
        catchError(() => of([])),
        switchMap((records: MaterialRecommendationsRecord[]) => {
          const nonNullRecords = records.filter(
            (record) => record.items != null,
          );
          this.ecommerceAnalyticsFacade.updateMaterialRecommendations(
            nonNullRecords,
          );

          const flattenedItems = nonNullRecords.flatMap(
            (record) => record.items,
          );
          const materialNumbers = flattenedItems.map((item) => item.sku_id);
          this.materialInfoFacade.loadMaterialInfos(materialNumbers);
          return this.materialInfoFacade
            .getLoadedMaterialInfos(materialNumbers)
            .pipe(
              first(),
              map((materialInfos) =>
                this.groupRecommendedMaterials(nonNullRecords, materialInfos),
              ),
            );
        }),
      );
  }

  getSmartCartRecommendations(
    request: SmartCartRequest,
  ): Observable<SmartCartRecommendation[]> {
    return this.naooHttpClient
      .post<
        SmartCartRecommendation[]
      >(this.webBffService.getBff() + '/api/v1/smart-cart-recommendations', request, undefined, silentRequestConfiguration)
      .pipe(
        first(),
        catchError(() => of([])),
      );
  }

  private groupRecommendedMaterials(
    recommendationsRecords: MaterialRecommendationsRecord[],
    materialInfos: MaterialInfo[],
  ): MaterialRecommendations[] {
    return recommendationsRecords.map((recommendationRecord) => {
      const materialListRows = recommendationRecord.items
        .map(
          (item) =>
            <MaterialListRow>{
              type: MaterialListRowType.MaterialRow,
              value: item.sku_id,
            },
        )
        .filter((mappedRow) =>
          materialInfos.some(
            (info) =>
              !!info &&
              !info.isEmpty &&
              info.materialNumber === mappedRow.value,
          ),
        );

      return {
        name: recommendationRecord.name,
        title: recommendationRecord.title,
        materialListRows,
      };
    });
  }
}
