import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import {
  MaterialListRow,
  MaterialListRowType,
} from 'src/app/material-list/models/material-list';
import {
  GroupByType,
  GuideCategoryHeader,
  GuideHeader,
  GuidesContext,
  GuideSideBar,
  SortByType,
} from 'src/app/guides/shared/guides';
import { MaterialListStyle } from '../material-row/models/material-row';
import { CustomerMaterialInfo } from '../../services/customer-material/model/customer-material-info.model';
import { Localized } from '../../../shared/models/localized';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import { ExportFeatureType } from '../../../shared/models/export/export-properties';
import {
  ExportCategoryGrouping,
  ExportMaterialsInput,
} from '../../../shared/services/export-materials/models/export-materials';
import { LocalizedUtilities } from '../../../shared/utilities/localized-utilities';
import { Language } from '../../services/session/models/session-record';
import { getCollator } from 'src/app/shared/collator/collator';

export interface CategorizedGuideMaterials {
  categoryName: Localized<string>;
  materialNumbers: string[];
}

export class GuidesTransformer {
  static SEARCH_TERMS_SEPARATORS = new RegExp(/,|\s|-|\./);

  public static flattenCategories(
    categorizedMaterials: CategorizedGuideMaterials[],
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    sortBy: SortByType,
    searchText: string,
    language: Language,
  ): MaterialListRow[] {
    let currentHeaderIndex = 0;
    let prevMaterialCount = 0;
    return categorizedMaterials.reduce((acc, curr) => {
      const filteredMaterials = searchText?.length
        ? curr.materialNumbers.filter((materialNumber) =>
            this.doesMatchSearch(
              materialInfoRecords[materialNumber],
              customerMaterialRecord[materialNumber],
              searchText,
              language,
            ),
          )
        : curr.materialNumbers;

      /**
       * To correctly calculate index values during search, this needs to be guarded
       */
      if (!searchText || (searchText && filteredMaterials.length)) {
        const sortedMaterials =
          sortBy !== SortByType.Custom
            ? filteredMaterials.sort((lhs, rhs) =>
                this.sortMaterials(
                  lhs,
                  rhs,
                  materialInfoRecords,
                  lastOrderedRecords,
                  sortBy,
                  language,
                ),
              )
            : filteredMaterials;

        const isFirst = acc.length === 0;
        const previousHeaderIndex = isFirst ? undefined : currentHeaderIndex;
        currentHeaderIndex += isFirst ? 0 : prevMaterialCount + 1;
        prevMaterialCount = sortedMaterials.length;

        /**
         * Since we are filtering, we can't know whether the next category will be in the result set,
         * so we only add the nextVirtualScrollIndex after we know it will exist
         */
        if (previousHeaderIndex !== undefined) {
          (
            acc[previousHeaderIndex].value as GuideCategoryHeader
          ).nextVirtualScrollIndex = currentHeaderIndex;
        }

        const materialListRows = sortedMaterials.map((materialNumber) => {
          return {
            type: MaterialListRowType.MaterialRow,
            value: materialNumber,
          };
        });

        acc.push({
          type: MaterialListRowType.CategoryHeader,
          value: {
            name: curr.categoryName,
            count: sortedMaterials.length,
            virtualScrollIndex: currentHeaderIndex,
            nextVirtualScrollIndex: undefined,
            previousVirtualScrollIndex: previousHeaderIndex,
          },
        });
        return acc.concat(materialListRows);
      }
      return acc;
    }, [] as MaterialListRow[]);
  }

  private static doesMatchSearch(
    materialInfo: MaterialInfoRecordState,
    customerMaterialInfo: CustomerMaterialInfo,
    searchText: string,
    language: Language,
  ): boolean {
    const materialNumber = materialInfo?.materialNumber;
    const lowerCaseSearchTerms = searchText
      .toLowerCase()
      .split(GuidesTransformer.SEARCH_TERMS_SEPARATORS);

    const description = this.getLanguageBasedDescription(
      materialInfo,
      language,
    );

    const brand = this.getLanguageBasedBrand(materialInfo, language);
    const allMaterialInfoText = materialNumber.concat(
      description?.toLowerCase(),
      brand?.toLowerCase(),
      customerMaterialInfo?.customerMaterialNumber?.toLowerCase(),
    );

    return lowerCaseSearchTerms.every((term) =>
      allMaterialInfoText.includes(term),
    );
  }

  private static getLanguageBasedBrand(
    info: MaterialInfoRecordState,
    language: Language,
  ): string {
    if (!info?.record) {
      return '';
    }

    return LocalizedUtilities.getLocalizedStringValue(
      info.record.brand,
      language,
    );
  }

  private static getLanguageBasedDescription(
    info: MaterialInfoRecordState,
    language: Language,
  ): string {
    if (!info?.record) {
      return '';
    }

    return LocalizedUtilities.getLocalizedStringValue(
      info.record?.description,
      language,
    );
  }

  private static sortMaterials(
    lhs: string,
    rhs: string,
    materialInfos: Dictionary<MaterialInfoRecordState>,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    sortBy: SortByType,
    language: Language,
  ): number {
    switch (sortBy) {
      case SortByType.Description:
        return this.descriptionComparator(lhs, rhs, materialInfos, language);
      case SortByType.Brand:
        return this.brandComparator(lhs, rhs, materialInfos, language);
      case SortByType.ItemCode:
        return this.materialNumberComparator(lhs, rhs);
      case SortByType.OrderDate:
        return this.orderDateComparator(
          lhs,
          rhs,
          materialInfos,
          lastOrderedRecords,
          language,
        );
      case SortByType.Custom:
      default:
        return 0;
    }
  }

  private static descriptionComparator(
    lhs: string,
    rhs: string,
    materialInfos: Dictionary<MaterialInfoRecordState>,
    language: Language,
  ): number {
    const lhsInfoRecord = materialInfos[lhs]
      ? materialInfos[lhs].record
      : undefined;
    const rhsInfoRecord = materialInfos[rhs]
      ? materialInfos[rhs].record
      : undefined;

    if (!lhsInfoRecord || !rhsInfoRecord) {
      return 1;
    }
    return getCollator(language).compare(
      LocalizedUtilities.getLocalizedStringValue(
        lhsInfoRecord.description,
        language,
      ),
      LocalizedUtilities.getLocalizedStringValue(
        rhsInfoRecord.description,
        language,
      ),
    );
  }

  private static brandComparator(
    lhs: string,
    rhs: string,
    materialInfos: Dictionary<MaterialInfoRecordState>,
    language: Language,
  ): number {
    const lhsInfoRecord = materialInfos[lhs]
      ? materialInfos[lhs].record
      : undefined;
    const rhsInfoRecord = materialInfos[rhs]
      ? materialInfos[rhs].record
      : undefined;

    if (!lhsInfoRecord?.brand?.en || !rhsInfoRecord?.brand?.en) {
      return 1;
    }

    const brandComparisonValue = getCollator(language).compare(
      LocalizedUtilities.getLocalizedStringValue(lhsInfoRecord.brand, language),
      LocalizedUtilities.getLocalizedStringValue(rhsInfoRecord.brand, language),
    );

    return brandComparisonValue !== 0
      ? brandComparisonValue
      : this.descriptionComparator(lhs, rhs, materialInfos, language);
  }

  private static materialNumberComparator(lhs: string, rhs: string): number {
    return lhs > rhs ? 1 : -1;
  }

  private static orderDateComparator(
    lhs: string,
    rhs: string,
    materialInfos: Dictionary<MaterialInfoRecordState>,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    language: Language,
  ): number {
    const lhsLastOrderDate =
      !!lastOrderedRecords[lhs] && !!lastOrderedRecords[lhs].record
        ? lastOrderedRecords[lhs].record.lastOrderDate
        : undefined;
    const rhsLastOrderDate =
      !!lastOrderedRecords[rhs] && !!lastOrderedRecords[rhs].record
        ? lastOrderedRecords[rhs].record.lastOrderDate
        : undefined;

    if (!!lhsLastOrderDate && !!rhsLastOrderDate) {
      if (lhsLastOrderDate === rhsLastOrderDate) {
        return this.descriptionComparator(lhs, rhs, materialInfos, language);
      }
      return getCollator(language).compare(rhsLastOrderDate, lhsLastOrderDate);
    } else if (lhsLastOrderDate) {
      return -1;
    } else if (rhsLastOrderDate) {
      return 1;
    }

    return this.descriptionComparator(lhs, rhs, materialInfos, language);
  }

  public static transformGuideHeader(
    categorizedMaterials: CategorizedGuideMaterials[],
    customerMaterialRecord: CustomerMaterialRecord,
    name: Localized<string>,
    searchText: string,
    isParEnabled?: boolean,
  ): GuideHeader {
    const count = categorizedMaterials.reduce(
      (prev, curr) => prev + curr.materialNumbers.length,
      0,
    );

    return {
      name,
      count,
      hasCustomerMaterial: Object.keys(customerMaterialRecord).length > 0,
      actionBar: {
        searchText,
        isParEnabled,
      },
    };
  }

  public static transformGuideSideBar(
    flattenedCategories: MaterialListRow[],
    sortBy: SortByType,
    guidesContext: GuidesContext,
    groupBy?: GroupByType,
  ): GuideSideBar {
    return {
      categoryHeaders: flattenedCategories
        .filter((val) => val.type === MaterialListRowType.CategoryHeader)
        .map((val) => val.value as GuideCategoryHeader),
      sortBy,
      sortByOptions: this.getSortByOptions(guidesContext),
      groupBy,
      groupByOptions: this.getGroupByOptions(guidesContext),
    };
  }

  private static getSortByOptions(guideContext: GuidesContext): SortByType[] {
    switch (guideContext) {
      case GuidesContext.CustomGuide:
        return [
          SortByType.Custom,
          SortByType.Description,
          SortByType.Brand,
          SortByType.ItemCode,
          SortByType.OrderDate,
        ];
      case GuidesContext.OrderGuide:
        return [
          SortByType.Description,
          SortByType.Custom,
          SortByType.ItemCode,
          SortByType.OrderDate,
          SortByType.Brand,
        ];
      case GuidesContext.CriticalItemsGuide:
        return [
          SortByType.Description,
          SortByType.Brand,
          SortByType.ItemCode,
          SortByType.OrderDate,
        ];
      default:
        return [];
    }
  }

  private static getGroupByOptions(guideContext: GuidesContext): GroupByType[] {
    switch (guideContext) {
      case GuidesContext.CustomGuide:
        return [GroupByType.Custom, GroupByType.Storage, GroupByType.Taxonomy];
      case GuidesContext.CriticalItemsGuide:
      case GuidesContext.OrderGuide:
      default:
        return undefined;
    }
  }

  public static getMaterialListStyle(
    preferredView: MaterialListStyle,
  ): MaterialListStyle {
    return preferredView === MaterialListStyle.Grid
      ? MaterialListStyle.List
      : preferredView;
  }

  // eslint-disable-next-line max-params
  public static transformExportMaterialsInput(
    categorizedMaterials: CategorizedGuideMaterials[],
    materialInfos: Dictionary<MaterialInfoRecordState>,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    sortBy: SortByType,
    titleTranslationKey: string,
    featureType: ExportFeatureType,
    guideName: Localized<string>,
    analyticsCategory: string,
    language: Language,
    customGuideId?: string,
  ): ExportMaterialsInput {
    const materialNumbers: string[] = [];
    const categoryGroupings: ExportCategoryGrouping[] = [];
    categorizedMaterials.forEach((category) => {
      const sortedMaterialNumbers = [...category.materialNumbers];
      sortedMaterialNumbers.sort((lhs, rhs) =>
        this.sortMaterials(
          lhs,
          rhs,
          materialInfos,
          lastOrderedRecords,
          sortBy,
          language,
        ),
      );

      materialNumbers.push(...sortedMaterialNumbers);
      categoryGroupings.push({
        categoryName: LocalizedUtilities.getLocalizedStringValue(
          category.categoryName,
          language,
        ),
        materialNumbers: sortedMaterialNumbers,
      });
    });

    return {
      titleTranslationKey: titleTranslationKey,
      featureType: featureType,
      fileName: LocalizedUtilities.getLocalizedStringValue(guideName, language),
      hasCustomerMaterial: customerMaterialRecord
        ? Object.keys(customerMaterialRecord).length > 0
        : false,
      analyticsCategory: analyticsCategory,
      materialNumbers: materialNumbers,
      categoryGroupings: categoryGroupings,
      customGuideId: customGuideId,
    };
  }
}
