export interface MaterialComparisonState {
  isCollapsed: boolean;
  isEnabled: boolean;
  materialNumbers: string[];
  shouldForceHideDocket: boolean;
}

export const initialMaterialComparisonState: MaterialComparisonState = {
  isCollapsed: true,
  isEnabled: false,
  materialNumbers: [],
  shouldForceHideDocket: false,
};
