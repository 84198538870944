import { MultipleCartsRecord } from '../../services/multiple-carts/models/multiple-carts-record';
import { StoreFulfillment, TruckFulfillment } from '../cart/cart.state';
import {
  CartMaterialRecord,
  FulfillmentType,
} from '../../services/cart/models/cart-record';

export enum SubmittedCartStatus {
  SubmittedAndApproved = 'CART.SUBMITTED_CARTS.STATUS.SUBMITTED_APPROVED',
  AwaitingApproval = 'CART.SUBMITTED_CARTS.STATUS.AWAITING_APPROVAL',
  Deleted = 'CART.SUBMITTED_CARTS.STATUS.DELETED',
  InReview = 'CART.SUBMITTED_CARTS.STATUS.IN_REVIEW',
  None = 'None',
}

export interface SavedCart {
  id: string;
  materials: CartMaterialRecord[];
  totalQuantity: number;
  lastUpdatedTimestamp: Date;
  lastUpdatedUserName?: string;
  lastUpdatedUserEmail?: string;
  customerPoNumber?: string;
  fulfillmentType: FulfillmentType;
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;
}

export interface SubmittedCart extends SavedCart {
  submittedCartStatus: SubmittedCartStatus;
}

export interface SavedCarts {
  shouldPromptActivateModal: boolean;
  submittedForApproval: SubmittedCart[];
  punchedOutCarts: SavedCart[];
  saved: SavedCart[];
  isOffline: boolean;
}

export interface MultipleCartsState {
  isLoading: boolean;
  carts?: MultipleCartsRecord;
}

export const initialMultipleCartsState: MultipleCartsState = {
  isLoading: false,
};
