<naoo-modal-header
  [title]="getTitle | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeAction()"
></naoo-modal-header>
<div
  *ngIf="!!displayValues"
  class="help-container"
  [@.disabled]="disableAnimation"
>
  <section class="fixed-section" *ngFor="let section of displayValues.sections">
    <div class="fixed-title">
      {{ section.title | translate }}
    </div>
    <p class="fixed-content" *ngFor="let paragraph of section.paragraphs">
      {{ paragraph | translate }}
    </p>
  </section>
  <mat-accordion>
    <ng-container
      *ngFor="
        let section of displayValues.expansions;
        first as isFirst;
        last as isLast
      "
    >
      <naoo-generic-help-expansion-panel
        [section]="section"
        [isFirst]="isFirst"
        [isLast]="isLast"
      ></naoo-generic-help-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
