import {
  initialOpenOrderItemsState,
  OpenOrderItemsState,
} from './open-order-items.state';
import { OpenOrderItemsActions } from './open-order-items.actions';
import { createReducer, on } from '@ngrx/store';

export const openOrderItemsReducer = createReducer(
  initialOpenOrderItemsState,
  on(
    OpenOrderItemsActions.getOpenOrderItemsSuccess,
    (state, action): OpenOrderItemsState => ({
      ...state,
      openOrderItems: action.openOrderItems,
    }),
  ),
  on(
    OpenOrderItemsActions.getOpenOrderItemsError,
    (state): OpenOrderItemsState => ({
      ...state,
      openOrderItems: [],
    }),
  ),
  on(
    OpenOrderItemsActions.clearOpenOrderItems,
    OpenOrderItemsActions.refreshOpenOrderItems,
    (): OpenOrderItemsState => initialOpenOrderItemsState,
  ),
);
