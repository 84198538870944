<div
  class="drag-drop-zone"
  [ngClass]="{ dragging: dragging }"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <mat-icon class="import-icon" svgIcon="import_icon_v2"></mat-icon>
  <span class="description">
    {{ 'FILE_INPUT.DRAG_AND_DROP_BOX' | translate }}
  </span>
  <div class="upload-button-wrapper">
    <button
      data-test-id="file-input-choose-file-button"
      class="primary-button"
      (click)="file.click()"
    >
      {{ 'FILE_INPUT.CHOOSE_FILE_BUTTON' | translate }}
    </button>
    <input
      class="chose-file-input"
      #file
      type="file"
      (change)="fileChanged($event.target.files)"
      [disabled]="dragging"
      tabindex="-1"
    />
  </div>
</div>
