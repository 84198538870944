export interface BannerAdsState {
  bannerAds: BannerAd[];
}

export const initialBannerAdsState: BannerAdsState = {
  bannerAds: undefined,
};

export interface BannerAd {
  imgUrl: string;
  headline: string;
  cta: string;
  ctTargetUrl: string;
  clickUrl: string;
  impressionUrl: string;
  divName: string;
}

export interface BannerAdCollection {
  bannerAds: BannerAd[][];
}
