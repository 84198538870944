import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { FulfillmentSplitRequest } from './model/fulfillment-split-request';
import { OrderSplitRecord } from './model/order-split-record';

@Injectable({ providedIn: 'root' })
export class PreSubmitService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getFulfillmentSplits(
    cartId: string,
    request: FulfillmentSplitRequest,
  ): Observable<OrderSplitRecord> {
    return this.httpClient.post<OrderSplitRecord>(
      this.webBffService.getBff() + `/api/v2/cart/${cartId}/presubmit`,
      request,
      undefined,
      silentRequestConfiguration,
    );
  }
}
