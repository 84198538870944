import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';

export const MaterialInfoActions = createActionGroup({
  source: 'Material Info',
  events: {
    'Load Material Info': (materialNumbers: string[]) => ({ materialNumbers }),
    'Get Material Info': (materialNumbers: string[]) => ({ materialNumbers }),
    'Get Material Info Success': (materialInfoRecords: MaterialRecord[]) => ({
      materialInfoRecords,
    }),
    'Get Material Info Unavailable': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Info Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Material Info': emptyProps(),
    'Refresh List Material Info': emptyProps(),
  },
});
