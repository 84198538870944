import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(
    private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  static readonly BYTE_ORDER_MARK = '\ufeff';

  async downloadCsv(
    data: unknown[],
    fileName: string,
    fieldSeparator: string = ',',
  ) {
    const xlsx = await this.getXlsxImport();
    const newSheet = xlsx.utils.json_to_sheet(data, {
      skipHeader: true,
    });

    let recordSeparator = '\n';
    if (this._window.navigator.appVersion.indexOf('Win') !== -1) {
      recordSeparator = '\r\n';
    }

    const output = xlsx.utils.sheet_to_csv(newSheet, {
      FS: fieldSeparator,
      RS: recordSeparator,
    });
    this.triggerDownload(output, fileName + '.csv');
  }

  async downloadXlsx(inputData: unknown[], fileName: string) {
    const xlsx = await this.getXlsxImport();
    const newSheet = xlsx.utils.json_to_sheet(inputData, {
      skipHeader: true,
    });
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, newSheet, 'Sheet1');
    xlsx.writeFile(workBook, `${fileName}.xlsx`);
  }

  triggerDownload(csv: string, filename: string) {
    const blob: Blob = new Blob([ExportService.BYTE_ORDER_MARK, csv], {
      type: 'application/octet-stream',
    });

    const url = URL.createObjectURL(blob);
    const anchor = this._document.createElement('a');
    anchor.download = filename;
    anchor.rel = 'noopener';
    anchor.href = url;

    anchor.dispatchEvent(new MouseEvent('click'));
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 40000);
  }

  async getXlsxImport() {
    return import(/* webpackChunkName: "xlsx" */ 'xlsx');
  }
}
