import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { DeliverySchedule } from '../../../shared/models/delivery-schedule';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

interface OrderedItems {
  items: string[];
}

@Injectable({ providedIn: 'root' })
export class OpenOrderItemsService {
  static readonly orderedItemsEndpoint = '/api/v1/open-order-items';

  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getOpenOrderItems(routeDate: Date): Observable<string[]> {
    const openOrderItemsURL =
      this.webBffService.getBff() +
      OpenOrderItemsService.orderedItemsEndpoint +
      `/${DeliverySchedule.dateString(routeDate)}`;

    return this.httpClient
      .get<OrderedItems>(
        openOrderItemsURL,
        undefined,
        silentRequestConfiguration,
      )
      .pipe(map((request) => request.items));
  }
}
