import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NaooConstants } from '../NaooConstants';

@Component({
  selector: 'naoo-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent {
  MAX_INPUT_LENGTH = NaooConstants.MAX_INPUT_LENGTH;
  @Input() value: string;

  @Input() searchPlaceholderText: string;
  @Input() searchHintText: string;

  @Output()
  searchTextEmitter = new EventEmitter<string>();

  @Output()
  blurEmitter = new EventEmitter<string>();

  search() {
    this.searchTextEmitter.emit(this.value);
  }

  blur() {
    this.blurEmitter.emit(this.value);
  }

  clear() {
    this.value = '';
    this.search();
  }
}
