<div class="tab-content">
  <span *ngIf="displayMaxAllocation" class="commodity-available">
    <button (click)="viewCommodityDetailsClicked.emit()">
      {{
        materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
          | translate
            : {
                count:
                  materialRowOptions.materialCommodityInfo.maxAllocationDisplay,
              }
      }}
    </button>
  </span>
  <naoo-material-warning
    *ngIf="!!materialOrdering.materialWarning"
    [warning]="materialOrdering.materialWarning"
    [openDirection]="materialOpenDirection"
    [materialRowOptions]="materialRowOptions"
    [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
    [selectedOrderingInfoIndex]="selectedTabIndex"
    [isMobile]="isMobile"
  ></naoo-material-warning>

  <div
    *ngIf="displayLoyaltyPoints"
    class="points"
    [ngClass]="{ 'no-icon': !displayGoPointsLogo }"
  >
    <div *ngIf="displayGoPointsLogo">
      <mat-icon class="loyalty-icon" svgIcon="loyalty_icon"></mat-icon>
    </div>
    {{ selectedMaterialOrderingInfo.loyaltyPoints | naooloyalty }}
  </div>

  <mat-tab-group
    (selectedTabChange)="tabChanged($event)"
    [disablePagination]="true"
    [mat-stretch-tabs]="false"
  >
    <mat-tab
      *ngFor="
        let tab of materialOrdering.tabs;
        index as index;
        trackBy: trackByTab
      "
    >
      <ng-template mat-tab-label>
        {{ { uomCode: tab.displayCode } | materialunits }}
        <span *ngIf="this.selectedTabIndex !== index && tab.quantity > 0"
          >({{ tab.quantity }})</span
        >
      </ng-template>
      <ng-template matTabContent>
        <naoo-material-ordering-info
          [orderingInfos]="materialOrdering.materialOrderingInfos"
          [currentOrderingInfo]="materialOrdering.materialOrderingInfos[index]"
          [materialRowOptions]="materialRowOptions"
          [materialWarning]="materialOrdering.materialWarning"
          [isMobile]="isMobile"
        >
        </naoo-material-ordering-info>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
