import { Localized } from '../../shared/models/localized';

export enum GuidesContext {
  CriticalItemsGuide = 'Critical Items Guide',
  OrderGuide = 'Order Guide',
  CustomGuide = 'Custom Guide',
  MarketingGuide = 'Marketing Guide',
}

export interface GuideActionBar {
  searchText: string;
  isParEnabled?: boolean;
}

export interface GuideHeader {
  name: Localized<string>;
  count: number;
  hasCustomerMaterial: boolean;
  actionBar: GuideActionBar;
}

export interface GuideCategoryHeader {
  name: Localized<string>;
  count: number;
  virtualScrollIndex: number;
  nextVirtualScrollIndex?: number;
  previousVirtualScrollIndex?: number;
}

export interface GuideSideBar {
  categoryHeaders: GuideCategoryHeader[];
  sortBy: SortByType;
  sortByOptions: SortByType[];
  groupBy?: GroupByType;
  groupByOptions?: GroupByType[];
}

export enum SortByType {
  Custom = 'custom',
  Description = 'description',
  Brand = 'brand',
  ItemCode = 'itemCode',
  OrderDate = 'orderDate',
}

export enum GroupByType {
  Custom = 'custom',
  Storage = 'storage',
  Taxonomy = 'taxonomy',
}
