import {
  MaterialCommodityShieldColor,
  MaterialRowContext,
} from './models/material-row';
import { createSelector } from '@ngrx/store';
import {
  selectAreCutoffsLoaded,
  selectMaterialCutoffEntities,
} from 'src/app/core/store/material-cutoff/material-cutoff.selectors';
import { selectRouteDate } from 'src/app/core/store/cart/cart.selectors';
import { selectIsInOrderGuide } from 'src/app/core/store/order-guide/order-guide.selectors';
import { selectCurrentSystem } from 'src/app/core/store/session/session.selectors';
import { Dictionary } from '@ngrx/entity';
import { MaterialCutoffEntryRecord } from 'src/app/core/services/material-cutoff/models/material-cutoff-record';
import { MaterialCutoffRecordState } from 'src/app/core/store/material-cutoff/material-cutoff.state';
import {
  MaterialFlag,
  MaterialFlagType,
  materialFlagTypeSort,
} from 'src/app/material-flag/material-flag';
import { selectMaterialAvailabilityRecordState } from '../material-availability/material-availability.selectors';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import {
  MaterialAvailabilityRecord,
  StockType,
} from '../../services/material-availability/model/material-availabilities-record';
import { CurrentSystem } from '../../services/session/models/session-record';
import { selectEntitlementMaterialDetail } from '../entitlement/entitlement.selectors';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';
import { selectIndicatorFlags } from '../indicator-lists/indicator-lists.selectors';
import { selectMaterialPriceRecordState } from '../material-price/material-price.selectors';
import { CutoffInfo } from './models/cutoff-info';
import { MaterialPriceRecordState } from '../material-price/material-price.state';
import { NaooConstants } from '../../../shared/NaooConstants';
import { selectIsCatchWeight } from '../material-info/material-info.selectors';
import { getMaterialCommodityShieldColor } from '../entitlement/entitlement.util';

interface MaterialSpecificData {
  entitlementMaterialDetail: EntitlementMaterialDetail;
  availability: MaterialAvailabilityRecordState;
  materialPrice: MaterialPriceRecordState;
  indicatorFlags: MaterialFlagType[];
  isCatchWeight: boolean;
  isOrderGuideItem: boolean;
}

const selectMaterialSpecificData = (materialNumber: string) =>
  createSelector(
    selectEntitlementMaterialDetail(materialNumber),
    selectMaterialAvailabilityRecordState(materialNumber),
    selectMaterialPriceRecordState(materialNumber),
    selectIndicatorFlags(materialNumber),
    selectIsCatchWeight(materialNumber),
    selectIsInOrderGuide(materialNumber),
    (
      entitlementMaterialDetail: EntitlementMaterialDetail,
      availability: MaterialAvailabilityRecordState,
      materialPrice: MaterialPriceRecordState,
      indicatorFlags: MaterialFlagType[],
      isCatchWeight: boolean,
      isOrderGuideItem: boolean,
    ): MaterialSpecificData =>
      <MaterialSpecificData>{
        entitlementMaterialDetail,
        availability,
        materialPrice,
        indicatorFlags,
        isCatchWeight,
        isOrderGuideItem,
      },
  );

export const selectMaterialFlags = (
  materialNumber: string,
  context: MaterialRowContext,
) => {
  const isOrderGuideContext = context === MaterialRowContext.OrderGuide;
  return createSelector(
    selectMaterialCutoffEntities,
    selectRouteDate,
    selectCurrentSystem,
    selectAreCutoffsLoaded,
    selectMaterialSpecificData(materialNumber),
    (
      cutoffs: Dictionary<MaterialCutoffRecordState>,
      routeDate: Date,
      currentSystem: CurrentSystem,
      areCutoffsLoaded: boolean,
      materialSpecificData: MaterialSpecificData,
    ): MaterialFlag[] => {
      const cutoffInfo: CutoffInfo = {
        cutoffs,
        areCutoffsLoaded,
      };
      return generateFlags(
        cutoffInfo,
        routeDate,
        currentSystem,
        isOrderGuideContext,
        materialSpecificData,
      );
    },
  );
};

export function createEntitlementFlag(
  entitlementMaterialDetail: EntitlementMaterialDetail,
): MaterialFlag {
  return {
    type: MaterialFlagType.Entitlement,
    entitlementParams: {
      shieldColor: `cma ${entitlementMaterialDetail.shieldColorDescription?.toLowerCase()}`,
      descriptionKey: getEntitlementDescriptionKey(
        getMaterialCommodityShieldColor(entitlementMaterialDetail),
      ),
    },
  };
}

function getCutoff(
  availability: MaterialAvailabilityRecord,
  cutoffs: Dictionary<MaterialCutoffRecordState>,
): MaterialCutoffEntryRecord {
  return availability?.cutoffCode
    ? cutoffs[availability.cutoffCode]?.record
    : undefined;
}

function generateFlags(
  cutoffInfo: CutoffInfo,
  routeDate: Date,
  currentSystem: CurrentSystem,
  isOrderGuideContext: boolean,
  materialSpecificData: MaterialSpecificData,
): MaterialFlag[] {
  const {
    entitlementMaterialDetail,
    availability,
    materialPrice,
    indicatorFlags,
    isCatchWeight,
    isOrderGuideItem,
  } = materialSpecificData;
  const availabilityRecord = availability?.record;
  const flags: Map<MaterialFlagType, MaterialFlag> = new Map();
  const cutoff = getCutoff(availabilityRecord, cutoffInfo.cutoffs);
  const areCutoffsLoaded = cutoffInfo.areCutoffsLoaded;

  if (entitlementMaterialDetail) {
    flags.set(
      MaterialFlagType.Entitlement,
      createEntitlementFlag(entitlementMaterialDetail),
    );
  }

  if (availabilityRecord?.cutoffCode) {
    flags.set(MaterialFlagType.EarlyCutoff, {
      type: MaterialFlagType.EarlyCutoff,
      earlyCutoffParams: {
        haveCutoffsLoaded: areCutoffsLoaded,
        cutoffDateTime:
          !!cutoff && !!routeDate ? cutoff.cutoffDateTime : undefined,
        hasShipDate: !!routeDate,
        currentSystem: currentSystem,
      },
    });
  }

  if (StockType.DropShip === availabilityRecord?.stockType) {
    flags.set(MaterialFlagType.DropShip, { type: MaterialFlagType.DropShip });
  }

  if (isSpecialOrder(availabilityRecord)) {
    flags.set(MaterialFlagType.SpecialOrder, {
      type: MaterialFlagType.SpecialOrder,
    });
  }

  if (isOrderGuideItem && !isOrderGuideContext) {
    flags.set(MaterialFlagType.OrderGuide, {
      type: MaterialFlagType.OrderGuide,
    });
  }

  if (availabilityRecord?.isContract) {
    flags.set(MaterialFlagType.Contract, { type: MaterialFlagType.Contract });
  }

  if (availabilityRecord?.isLocal) {
    flags.set(MaterialFlagType.Local, { type: MaterialFlagType.Local });
  }

  indicatorFlags?.forEach((flagType) =>
    flags.set(flagType, { type: flagType }),
  );

  if (materialPrice?.record?.unitPrices?.[0]?.isContract) {
    flags.set(MaterialFlagType.ContractPrice, {
      type: MaterialFlagType.ContractPrice,
    });
  }

  const caseUnitPrice = materialPrice?.record?.unitPrices?.find(
    (unit) => NaooConstants.Uom.Case === unit.salesUom,
  );
  if (caseUnitPrice?.quantityPriceThreshold) {
    flags.set(MaterialFlagType.Bulk, {
      type: MaterialFlagType.Bulk,
      bulkParams: {
        threshold: caseUnitPrice.quantityPriceThreshold,
        uom: caseUnitPrice.salesUom,
        price: isCatchWeight
          ? caseUnitPrice.quantityCatchWeightPrice
          : caseUnitPrice.quantityPrice,
      },
    });
  }

  return Array.from(flags.values()).sort(
    (a, b) => materialFlagTypeSort[a.type] - materialFlagTypeSort[b.type],
  );
}

function isSpecialOrder(availabilityRecord: MaterialAvailabilityRecord) {
  return [StockType.SpecialOrder, StockType.SpecialOrderSAP].includes(
    availabilityRecord?.stockType,
  );
}

function getEntitlementDescriptionKey(
  shieldColor: MaterialCommodityShieldColor,
): string {
  switch (shieldColor) {
    case MaterialCommodityShieldColor.GREEN:
      return 'MATERIAL_FLAG.ENTITLEMENT_FLAGS.GREEN';
    case MaterialCommodityShieldColor.YELLOW:
      return 'MATERIAL_FLAG.ENTITLEMENT_FLAGS.YELLOW';
    case MaterialCommodityShieldColor.BLUE:
      return 'MATERIAL_FLAG.ENTITLEMENT_FLAGS.BLUE';
    case MaterialCommodityShieldColor.RED:
      return 'MATERIAL_FLAG.ENTITLEMENT_FLAGS.RED';
    default:
      return 'MATERIAL_FLAG.ENTITLEMENT_FLAGS.ERROR';
  }
}
