import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface SelectableButton {
  text: string;
  icon: string;
  disabled?: boolean;
  buttonType: SelectableButtonType;
}

export enum SelectableButtonType {
  CREDIT_PAYMENT,
  IN_STORE_PAYMENT,
}
@Component({
  selector: 'naoo-selectable-button',
  templateUrl: './selectable-button.component.html',
  styleUrls: ['./selectable-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectableButtonComponent {
  @Input() isDisabled = false;
  @Input() isSelected = false;
  @Input() text: string;
  @Input() icon: string;

  @Output() didClick = new EventEmitter<void>();
}
