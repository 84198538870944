<div class="info-content" [ngClass]="{ mobile: isMobile }">
  <naoo-material-image
    [materialRowOptions]="materialRowOptions"
    [isUnorderable]="true"
    [isMobile]="isMobile"
  ></naoo-material-image>

  <div class="unavailable-info">
    <div class="description">
      {{ 'PRODUCT.UNAVAILABLE_ITEM' | translate }}
    </div>
    <div class="material-number">
      <ng-container *ngIf="!!materialRowOptions.customerMaterialNumber"
        >{{ materialRowOptions.customerMaterialNumber }} :
      </ng-container>
      #{{ materialRowOptions.materialNumber }}
    </div>
  </div>
</div>

<div
  *ngIf="!isDeleteHidden"
  class="trash-button"
  [ngClass]="{ mobile: isMobile }"
>
  <naoo-material-trash-button [materialRowOptions]="materialRowOptions">
  </naoo-material-trash-button>
</div>
