export class SearchConstants {
  public static readonly searchEndpoint = '/api/v2/materials/search';
  public static readonly paramSearchText = 'searchText';
  public static readonly paramSearchOffset = 'searchOffset';
  public static readonly searchOffsetCount = 50;
  public static readonly paramResultsLimit = 'resultsLimit';
  public static readonly paramCategoryCoordinate = 'categoryCoordinate';
  public static readonly paramOrderGuideOnly = 'onGuide';
  public static readonly paramAvailableTodayFilter = 'storeStocked';
  public static readonly paramHideSearchText = 'hst';
  public static readonly categorySearchEndpoint = '/api/v2/materials/category';
  public static readonly orderGuideFilterAllResultsId = 0;
  public static readonly orderGuideFilterOrderGuideId = 1;
}
