import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NaooConstants } from '../NaooConstants';
import { LogRocketService } from '../services/logrocket/logrocket.service';

@Injectable()
export class NaooLogRocketInterceptor implements HttpInterceptor {
  constructor(
    private logRocketService: LogRocketService,
    private window: Window,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.match(this.window.location.hostname)) {
      return next.handle(
        req.clone({
          setHeaders: {
            [NaooConstants.LOG_ROCKET_URL_HEADER]:
              this.logRocketService.getRecordURL() ?? '',
          },
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
