<naoo-select-date-step-truck
  *ngIf="isFulfillmentType(truckFulfillment)"
  [fulfillmentType]="fulfillmentType"
  [availableDates]="availableDates"
  [customerTimeZone]="customerTimeZone"
  [cutoffDateTime]="cutoffDateTime"
  [isRouteDateExpired]="isRouteDateExpired"
  [isMobileWidth]="isMobileWidth"
  [selectedDate]="selectedDate"
  (dateChangeEmitter)="dateChangeEmitter.emit($event)"
>
</naoo-select-date-step-truck>
<naoo-select-date-step-express
  *ngIf="isFulfillmentType(expressFulfillment)"
  [fulfillmentType]="fulfillmentType"
  [availableDates]="availableDates"
  [customerTimeZone]="customerTimeZone"
  [selectedDate]="selectedDate"
  [availableExpressDeliveryWindows]="
    selectedExpressScheduleRecord?.deliveryWindows
  "
  [selectedExpressDeliveryWindow]="selectedExpressDeliveryWindow"
  [isMobileWidth]="isMobileWidth"
  [isRouteDateExpired]="isRouteDateExpired"
  (dateChangeEmitter)="dateChangeEmitter.emit($event)"
  (expressDeliveryWindowChangeEmitter)="
    expressDeliveryWindowChangeEmitter.emit($event)
  "
>
</naoo-select-date-step-express>
<naoo-select-date-step-ispu
  *ngIf="isFulfillmentType(pickupFulfillment)"
  [fulfillmentType]="fulfillmentType"
  [availableDates]="availableDates"
  [customerTimeZone]="customerTimeZone"
  [selectedDate]="selectedDate"
  [storeDetailsViewModel]="storeDetailsViewModel"
  [isRouteDateExpired]="isRouteDateExpired"
  [isMobileWidth]="isMobileWidth"
  (dateChangeEmitter)="dateChangeEmitter.emit($event)"
></naoo-select-date-step-ispu>
