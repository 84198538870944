import { createActionGroup, emptyProps } from '@ngrx/store';
import {
  CartReviewMetadata,
  CartReviewSection,
  CartReviewSectionGroup,
  CartReviewSectionName,
  CreditWidgetLoadingState,
} from './cart-review.state';
import { CartReview } from './cart-review.selectors';

export const CartReviewActions = createActionGroup({
  source: 'Cart Review',
  events: {
    'Update Cart Review Aci Has Resolved': (hasResolved: boolean) => ({
      hasResolved,
    }),
    'Build Cart Review': (metadata: CartReviewMetadata) => ({ metadata }),
    'Build Cart Review Success': (data: CartReview) => ({ data }),
    'Clear Cart Review': emptyProps(),
    'Get Cart Review Metadata': emptyProps(),
    'Get Cart Review Metadata Failure': emptyProps(),
    'Load Cart Review': emptyProps(),
    'Load Cart Review Cart Data': emptyProps(),
    'Refresh Drop Ship Metadata': emptyProps(),
    'Refresh Drop Ship Metadata Success': (
      dropShipSectionGroup: CartReviewSectionGroup,
    ) => ({ dropShipSectionGroup }),
    'Get Drop Ship Metadata Failure': emptyProps(),
    'Refresh Cart Review': emptyProps(),
    'Remove Failed Fulfillment Materials': emptyProps(),
    'Remove Failed Fulfillment Materials Success': emptyProps(),
    'Build System Error': (failedDropShipMaterials: string[]) => ({
      failedDropShipMaterials,
    }),
    'Save Selected Credit Card Option': (
      creditCardOptionSelected: boolean,
    ) => ({ creditCardOptionSelected }),
    'Update Credit Widget Loading State': (
      loadingState: CreditWidgetLoadingState,
    ) => ({ loadingState }),
    'Update Cart Review Section': (
      name: CartReviewSectionName,
      index: number,
      changes: Partial<CartReviewSection>,
      isSplit: boolean,
    ) => ({ name, index, changes, isSplit }),
  },
});
