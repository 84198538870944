import {
  BreadCrumbCategory,
  FilterGroupRecordState,
  FilterRecordState,
  SearchResultSetState,
  SearchState,
  TaxonomyFilterGroupRecordState,
  TaxonomyFilterRecordState,
} from '../search.state';
import {
  ActiveDefaultFilter,
  CategoryLink,
  DefaultFilterGroup,
  SearchResults,
  SearchResultSet,
  Taxonomy,
  TaxonomyFilter,
} from './search-results';
import { EntityState } from '@ngrx/entity';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { SearchConstants } from 'src/app/shared/models/search/SearchConstants';
import { MaterialListStyle } from '../../material-row/models/material-row';
import { MaterialListRowType } from 'src/app/material-list/models/material-list';
import { Localized } from '../../../../shared/models/localized';

interface MappedTaxonomy {
  record: TaxonomyFilterRecordState;
  depth: number;
}

export class SearchTransformer {
  public static transformSearchState(
    state: SearchState,
    preferredView: MaterialListStyle,
  ): SearchResults {
    const {
      availableTodayFilter,
      hasLoaded,
      hideSearchText,
      orderGuideFilter,
      resultSet,
      searchText,
      taxonomyFilters,
    } = state;

    const defaultFilters = this.transformDefaultFilters(state.defaultFilters);
    const activeDefaultFilters =
      this.transformActiveDefaultFilters(defaultFilters);
    const taxonomy = this.transformTaxonomyFilters(taxonomyFilters);
    const activeFilterCount = this.getTotalActiveFilters(
      activeDefaultFilters,
      taxonomy.filters,
    );

    return {
      hasLoaded,
      defaultFilters,
      activeDefaultFilters,
      activeFilterCount,
      taxonomy,
      orderGuideFilter,
      availableTodayFilter,
      searchText,
      hideSearchText,
      resultSet: this.transformResultSet(resultSet),
      preferredView,
    };
  }

  public static transformBreadCrumbTreeToString(
    breadCrumbCategory: BreadCrumbCategory,
  ): Localized<string> {
    if (!breadCrumbCategory) {
      return undefined;
    }
    return SearchTransformer.appendBreadCrumbSegmentToString(
      breadCrumbCategory,
      {
        en: 'Categories',
        fr: 'Catégories',
        es: 'Categorías',
      },
    );
  }

  private static appendBreadCrumbSegmentToString(
    breadCrumbCategory: BreadCrumbCategory,
    value: Localized<string>,
  ): Localized<string> {
    const subCategory = breadCrumbCategory?.subCategories?.[0];
    const subCategoryName = subCategory?.name;
    if (subCategoryName) {
      return SearchTransformer.appendBreadCrumbSegmentToString(subCategory, {
        en: value.en + ' > ' + subCategoryName.en,
        fr: value.fr + ' > ' + subCategoryName.fr,
        es: value.es + ' > ' + subCategoryName.es,
      });
    }
    return value;
  }

  private static transformDefaultFilters(
    defaultFilters: EntityState<FilterGroupRecordState>,
  ): DefaultFilterGroup[] {
    return Object.values(defaultFilters.entities)
      .map((filterGroup) => {
        const filters: FilterRecordState[] = Object.values(
          filterGroup.filters.ids,
        ).map((id) => filterGroup.filters.entities[id]);
        return {
          name: filterGroup.name,
          isCollapsed: filterGroup.isCollapsed,
          queryParameterName: filterGroup.queryParameterName,
          isViewingMore: filterGroup.isViewingMore,
          viewMoreThreshold: filterGroup.viewMoreThreshold,
          filters,
        };
      })
      .filter((filterGroup) => filterGroup.filters.length > 0);
  }

  private static flattenBreadCrumbs(
    currentBreadCrumb: BreadCrumbCategory,
    flattenedCrumbs: BreadCrumbCategory[],
  ): BreadCrumbCategory[] {
    if (
      !currentBreadCrumb.subCategories ||
      currentBreadCrumb.subCategories.length === 0
    ) {
      flattenedCrumbs.push(currentBreadCrumb);
      return flattenedCrumbs;
    }

    flattenedCrumbs.push(currentBreadCrumb);

    return this.flattenBreadCrumbs(
      currentBreadCrumb.subCategories[0],
      flattenedCrumbs,
    );
  }

  private static transformResultSet(
    resultSet: SearchResultSetState,
  ): SearchResultSet {
    if (!resultSet) {
      return undefined;
    }
    const {
      breadCrumbTree,
      materialNumbers,
      sponsoredRecommendations,
      suggestedText,
      totalResults,
    } = resultSet;

    return {
      totalResults,
      suggestedText,
      materialNumbers: materialNumbers.map((value, index) => {
        return {
          type: MaterialListRowType.MaterialRow,
          value,
          searchResultsIndex: index + 1,
        };
      }),
      categoryLinks: this.transformBreadCrumbTree(breadCrumbTree),
      sponsoredRecommendations,
    };
  }

  private static buildBreadcrumbLink(
    coordinate: string,
    depth: number,
    maxDepth: number,
  ): string {
    if (depth === maxDepth) {
      return undefined;
    }

    if (depth > 1) {
      return undefined;
    } else if (depth === 0) {
      return NaooConstants.CATEGORIES_PATH;
    } else {
      return `${NaooConstants.CATEGORIES_PATH}/${coordinate}`;
    }
  }

  private static transformBreadCrumbTree(
    breadCrumbCategory?: BreadCrumbCategory,
  ): CategoryLink[] {
    if (!breadCrumbCategory) {
      return undefined;
    }
    const flattenedCrumbs = this.flattenBreadCrumbs(breadCrumbCategory, []);
    return flattenedCrumbs.map((crumb, index) => {
      return {
        name: crumb.name
          ? crumb.name
          : {
              en: 'Categories',
              fr: 'Catégories',
              es: 'Categorías',
            },
        link: this.buildBreadcrumbLink(
          crumb.categoryKey,
          index,
          flattenedCrumbs.length - 1,
        ),
        depth: index,
        categoryKey: crumb.categoryKey,
      };
    });
  }

  private static flattenTaxonomy(
    taxonomy: TaxonomyFilterRecordState,
    arr: MappedTaxonomy[],
    depth: number,
  ): MappedTaxonomy[] {
    if (!taxonomy.subCategories || taxonomy.subCategories.length === 0) {
      return arr;
    } else {
      if (arr.length === 0) {
        arr.push({
          record: taxonomy,
          depth: 0,
        });
      }
      const mappedSubCategories: MappedTaxonomy[] = taxonomy.subCategories.map(
        (cat) => {
          return {
            record: cat,
            depth: depth + 1,
          };
        },
      );
      arr = arr.concat(mappedSubCategories);
    }
    return this.flattenTaxonomy(taxonomy.subCategories[0], arr, depth + 1);
  }

  private static getViewMoreThreshold(maxDepth: number): number {
    switch (maxDepth) {
      case 2:
        return 6;
      case 3:
        return 7;
      case 4:
        return 5;
      default:
        return 4;
    }
  }

  private static transformTaxonomyFilters(
    taxonomyFilter: TaxonomyFilterGroupRecordState,
  ): Taxonomy {
    if (!taxonomyFilter) {
      return {
        filters: [],
        viewMoreThreshold: 0,
      };
    }

    const flattenedTaxonomy = this.flattenTaxonomy(taxonomyFilter, [], 0);
    const selectedTaxonomy = flattenedTaxonomy
      .filter((taxonomy) => taxonomy.record.isSelected)
      .pop();
    const maxDepth = selectedTaxonomy ? selectedTaxonomy.depth : 0;

    return {
      filters: flattenedTaxonomy
        .filter((taxonomy) => !!taxonomy)
        .map((taxonomy) => {
          return {
            categoryKey: taxonomy.record.queryParameterValue,
            depth:
              taxonomy.record.queryParameterValue ===
                SearchConstants.paramCategoryCoordinate ||
              taxonomy.record.queryParameterValue === null
                ? 0
                : taxonomy.depth,
            name: taxonomy.record.name,
            isSelected: !!taxonomy.record.isSelected,
            canToggle:
              !taxonomy.record.isSelected &&
              taxonomy.record.queryParameterValue !==
                SearchConstants.paramCategoryCoordinate,
            count: taxonomy.record.count,
          };
        }),
      viewMoreThreshold: this.getViewMoreThreshold(maxDepth),
    };
  }

  private static transformActiveDefaultFilters(
    defaultFilters: DefaultFilterGroup[],
  ): ActiveDefaultFilter[] {
    return defaultFilters
      .map((filterGroup) => {
        return filterGroup.filters.map((filter) => {
          return {
            filter: filter,
            parentFilterGroupName: filterGroup.name.en,
            queryParameterKey: filterGroup.queryParameterName,
          };
        });
      })
      .reduce(
        (flattenedFilters, filtersToFlatten) =>
          flattenedFilters.concat(filtersToFlatten),
        [],
      )
      .filter((activeFilter) => activeFilter.filter.isSelected);
  }

  private static getTotalActiveFilters(
    activeDefaultFilters: ActiveDefaultFilter[],
    taxonomyFilters: TaxonomyFilter[],
  ): number {
    return (
      activeDefaultFilters.length +
      taxonomyFilters.filter((filter) => filter.isSelected).length
    );
  }
}
