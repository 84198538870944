import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'naoo-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsComponent {
  @Input() isZeroWithOG: boolean;
  @Input() isZeroWithAT: boolean;
  @Input() isCategories: boolean;
  @Input() searchText: string;
  @Input() isMobile: boolean;
  @Input() isInvoiceSearch: boolean;
}
