import { CategorizedMaterialsRecord } from '../../services/order-guide/models/categorized-materials-record';
import { Localized } from '../../../shared/models/localized';
import { OrderGuideState } from './order-guide.state';
import { NaooConstants } from '../../../shared/NaooConstants';

export function getUnassignedCategoryName(): Localized<string> {
  return {
    en: NaooConstants.unassignedCategory,
    fr: 'Non-assignés',
    es: 'Sin asignar',
  };
}

export function getClonedOrderGuideCategories(
  state: OrderGuideState,
): CategorizedMaterialsRecord[] {
  return state.records.ids
    .map((categoryId) => state.records.entities[categoryId])
    .map((category) => {
      return {
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        materialNumbers: Array.from(category.materialNumbers),
        categoryType: category.categoryType,
      };
    });
}
