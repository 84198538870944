import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { NutritionActions } from './nutrition.actions';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { selectAllNutritionRecords } from './nutrition.selectors';
import { NutritionRecordStatus } from './nutrition.state';
import { SharedActions } from '../shared/shared.actions';
import { selectCustomerBrand } from '../session/session.selectors';
import { CustomerBrand } from '../../services/session/models/session-record';
import { NutritionTransformerService } from './nutrition-transformer.service';
import { NutritionService } from '../../services/nutrition/nutrition.service';

@Injectable()
export class NutritionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private nutritionService: NutritionService,
    private nutritionTransformerService: NutritionTransformerService,
  ) {}

  loadNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionActions.loadNutrition),
      concatLatestFrom(() => [
        this.store.select(selectAllNutritionRecords),
        this.store.select(selectCustomerBrand),
      ]),
      mergeMap(([action, nutritionRecords, customerBrand]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            NutritionRecordStatus.Queued ===
            nutritionRecords[materialNumber]?.status,
        );
        if (
          CustomerBrand.GFS_US !== customerBrand ||
          !queuedMaterialNumbers.length
        ) {
          return of(
            SharedActions.noOperation(
              'Nutrition for material numbers are not in queued status',
            ),
          );
        }
        const dispatchActions: Action[] = [];
        queuedMaterialNumbers.forEach((materialNumber) =>
          dispatchActions.push(NutritionActions.getNutrition(materialNumber)),
        );
        return dispatchActions;
      }),
    );
  });

  getNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionActions.getNutrition),
      concatLatestFrom(() => [this.store.select(selectAllNutritionRecords)]),
      mergeMap(([action, nutritionRecords]) => {
        const materialNumber = action.materialNumber;
        if (
          nutritionRecords[materialNumber].status !==
          NutritionRecordStatus.Requested
        ) {
          return of(
            SharedActions.noOperation(
              'Nutrition for material number not in requested status',
            ),
          );
        }

        return this.nutritionService.getNutrition(materialNumber).pipe(
          map((record) =>
            NutritionActions.getNutritionSuccess(
              materialNumber,
              this.nutritionTransformerService.transformPicRecords([record]),
            ),
          ),
          catchError(() =>
            of(NutritionActions.getNutritionError(materialNumber)),
          ),
          takeUntil(
            this.actions$.pipe(ofType(NutritionActions.clearNutrition)),
          ),
        );
      }),
    );
  });
}
