import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ComparisonAttributeDetails,
  ComparisonAttributesViewModel,
  VisibleDimensions,
} from '../shared/models/comparison-attributes';

export enum ComparisonAttribute {
  Description = 'PRODUCT_ATTRIBUTES.DESCRIPTION',
  PricePerWeight = 'PRODUCT_ATTRIBUTES.PRICE_PER_WEIGHT',
  Ingredients = 'PRODUCT_ATTRIBUTES.INGREDIENTS',
  PackagingAndStorage = 'PRODUCT_ATTRIBUTES.PACKAGING_AND_STORAGE',
  Brand = 'PRODUCT_ATTRIBUTES.BRAND',
  Attributes = 'PRODUCT_ATTRIBUTES.ATTRIBUTES',
  NutritionFacts = 'PRODUCT_ATTRIBUTES.NUTRITION_FACTS',
  Allergens = 'PRODUCT_ATTRIBUTES.ALLERGENS',
  ServingSuggestions = 'PRODUCT_ATTRIBUTES.SERVING_SUGGESTIONS',
}

@Component({
  selector: 'naoo-comparison-attributes',
  templateUrl: './comparison-attributes.component.html',
  styleUrls: ['./comparison-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonAttributesComponent implements OnChanges {
  @Input() viewModel: ComparisonAttributesViewModel;
  @Input() visibleMaterialIds: string[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isViewModelChanged =
      changes.viewModel?.previousValue !== changes.viewModel?.currentValue;
    const isVisibleMaterialIdsChanged =
      changes.visibleMaterialIds?.previousValue !==
      changes.visibleMaterialIds?.currentValue;

    if (isViewModelChanged || isVisibleMaterialIdsChanged) {
      setTimeout(() => this.changeDetectorRef.markForCheck());
    }
  }

  get ComparisonAttribute(): typeof ComparisonAttribute {
    return ComparisonAttribute;
  }

  get attributes(): ComparisonAttribute[] {
    return Object.values(ComparisonAttribute);
  }

  get materialNumbers(): string[] {
    return this.visibleMaterialIds ?? [];
  }

  getClassForComparisonAttribute(attribute: ComparisonAttribute): string {
    return attribute
      .substring(attribute.indexOf('PRODUCT_ATTRIBUTES.') + 19)
      .toLowerCase()
      .split('_')
      .join('-');
  }

  getDetails(materialNumber: string): ComparisonAttributeDetails {
    return this.viewModel?.materialDetailsMap?.get(materialNumber);
  }

  getDimensions(materialNumber: string): VisibleDimensions {
    return this.getDetails(materialNumber)?.dimensions;
  }

  shouldRenderDescription(materialNumber: string): boolean {
    const details = this.getDetails(materialNumber);
    const sellingParagraphs = details?.sellingParagraphs;
    const sellingBulletPoints = details?.sellingBulletPoints;
    return !!sellingParagraphs?.length || !!sellingBulletPoints?.length;
  }

  shouldRenderExpansionPanel(attribute: ComparisonAttribute): boolean {
    const materials = this.materialNumbers;
    switch (attribute) {
      case ComparisonAttribute.Description:
        return materials.some((i) => this.shouldRenderDescription(i));
      case ComparisonAttribute.PricePerWeight:
        return materials.some((i) => this.shouldRenderPricePerWeight(i));
      case ComparisonAttribute.Ingredients:
        return materials.some((i) => !!this.getDetails(i)?.ingredients);
      case ComparisonAttribute.Brand:
        return materials.some((i) => !!this.getDetails(i)?.brand);
      case ComparisonAttribute.Attributes:
        return materials.some((i) => !!this.getDetails(i)?.attributes?.length);
      case ComparisonAttribute.NutritionFacts:
        return materials.some((i) => this.shouldRenderNutritionInfo(i));
      case ComparisonAttribute.Allergens:
        return materials.some((i) => !!this.getDetails(i)?.allergenInfo);
      case ComparisonAttribute.ServingSuggestions:
        return materials.some((i) => !!this.getDetails(i)?.servingSuggestions);
      default:
        return materials.some((i) => !!this.getDetails(i));
    }
  }

  shouldRenderPricePerWeight(materialNumber: string): boolean {
    const details = this.getDetails(materialNumber);
    return !!details?.price && !!details?.weightUom;
  }

  shouldStartExpanded(attribute: ComparisonAttribute): boolean {
    const renderedExpansionPanels = this.attributes.filter((i) =>
      this.shouldRenderExpansionPanel(i),
    );
    return (
      renderedExpansionPanels.indexOf(attribute) > -1 &&
      renderedExpansionPanels.indexOf(attribute) < 3
    );
  }

  shouldRenderNutritionInfo(materialNumber: string): boolean {
    const details = this.getDetails(materialNumber);
    return !!details?.nutritionalInfo || !!details?.nutritionLabel;
  }
}
