import { NgModule } from '@angular/core';
import { OfflineErrorComponent } from './oflline-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
  {
    path: '',
    component: OfflineErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    CommonModule,
    MatIconModule,
  ],
  exports: [OfflineErrorComponent],
  declarations: [OfflineErrorComponent],
})
export class OfflineModule {}
