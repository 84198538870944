import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../../material-info/material-info.state';
import { MaterialListRow } from '../../../../material-list/models/material-list';
import { createMaterialListRow } from '../../material-row/models/material-row';
import {
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
} from '../../material-availability/material-availability.state';
import {
  FulfillmentMaterial,
  FulfillmentOrderSplit,
} from '../../../services/pre-submit/model/order-split-record';
import { StockType } from '../../../services/material-availability/model/material-availabilities-record';
import { isAtOrOverLimit } from '../../customer-notification-preferences/customer-notification-preferences.selectors';
import { MaterialWarningType } from '../../material-warning/material-warning';
import {
  InventoryAvailabilityRecordState,
  InventoryAvailabilityRecordStatus,
} from '../../inventory-availability/inventory-availability.state';
import { CartReviewWarning } from '../cart-review.selectors';
import { CartReviewSectionName } from '../cart-review.state';
import { OrderConfirmationEmailOptInStatus } from '../../customer-preferences/customer-preferences.selectors';
import { NotificationRecord } from '../../../services/customer-notification-preferences/models/customer-notification-preferences-record';

export function getCartReviewSectionGroupDisplayOrder(
  cartReviewSectionName: CartReviewSectionName,
): number {
  switch (cartReviewSectionName) {
    case CartReviewSectionName.CutoffSplitItemsSection:
      return 1;
    case CartReviewSectionName.UnavailableItemsSection:
      return 2;
    case CartReviewSectionName.StockWarningItemsSection:
      return 3;
    case CartReviewSectionName.SpecialItemsSection:
      return 4;
    case CartReviewSectionName.DropShipRetalixItemsSection:
    case CartReviewSectionName.DropShipItemsSection:
      return 5;
    case CartReviewSectionName.StandardItemsSection:
      return 6;
    default:
      return 0;
  }
}

export function getCriticalMaterials(
  unsafeCriticalInfoRecordStates: MaterialInfoRecordState[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const criticalInfoRecordStates = safeMaterialInfoRecordState(
    unsafeCriticalInfoRecordStates,
  );

  const materialNumbers = criticalInfoRecordStates
    .filter((info) => MaterialInfoRecordStatus.Unavailable !== info.status)
    .map((info) => createMaterialListRow(info.materialNumber));

  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

export function getCutoffSplitMaterials(
  materialsPastCutoff: string[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const materialNumbers = materialsPastCutoff.map((material) =>
    createMaterialListRow(material),
  );
  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

export function transformFulfillmentMaterialsToMaterialListRows(
  fulfillmentMaterials: FulfillmentMaterial[],
): MaterialListRow[] {
  return fulfillmentMaterials
    .filter(
      (material, index) =>
        index ===
        fulfillmentMaterials.findIndex(
          (other) => material.materialNumber === other.materialNumber,
        ),
    )
    .map((material) => createMaterialListRow(material.materialNumber));
}

export function getDropShipMaterials(
  unsafeAvailabilities: MaterialAvailabilityRecordState[],
  fulfillmentOrderSplit: FulfillmentOrderSplit,
): MaterialListRow[] {
  const availabilities =
    safeMaterialAvailabilityRecordStates(unsafeAvailabilities);
  const fulfillmentMaterials = fulfillmentOrderSplit.materials.map(
    (material) => material.materialNumber,
  );
  return availabilities
    .filter((availability) =>
      fulfillmentMaterials.includes(availability.materialNumber),
    )
    .map((availability) => createMaterialListRow(availability.materialNumber));
}

export function getDropShipRetalixMaterials(
  unsafeAvailabilities: MaterialAvailabilityRecordState[],
): MaterialListRow[] {
  const availabilities =
    safeMaterialAvailabilityRecordStates(unsafeAvailabilities);
  return availabilities
    .filter(
      (availability) => StockType.DropShip === availability.record.stockType,
    )
    .map((availability) => createMaterialListRow(availability.materialNumber));
}

export function getEmailOptInStatus(
  emailNotifications: NotificationRecord[],
  emailNotificationOptOuts: string[],
  email: string,
): OrderConfirmationEmailOptInStatus {
  if (!emailNotifications || !emailNotificationOptOuts || !email) {
    return OrderConfirmationEmailOptInStatus.Hidden;
  }
  const lowercaseNotifications = emailNotifications.map((notification) =>
    notification.methodValue.toLowerCase(),
  );
  const lowercaseOptOuts = emailNotificationOptOuts.map((optOutEmail) =>
    optOutEmail.toLowerCase(),
  );
  const lowercaseCombinedList = lowercaseNotifications.concat(lowercaseOptOuts);
  if (lowercaseCombinedList.includes(email.toLowerCase())) {
    return OrderConfirmationEmailOptInStatus.Hidden;
  }
  if (isAtOrOverLimit(emailNotifications.length)) {
    return OrderConfirmationEmailOptInStatus.LimitReached;
  }
  return OrderConfirmationEmailOptInStatus.Prompt;
}

export function getSpecialMaterials(
  unsafeAvailabilities: MaterialAvailabilityRecordState[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const availabilities =
    safeMaterialAvailabilityRecordStates(unsafeAvailabilities);
  const materialNumbers = availabilities
    .filter((availability) =>
      [StockType.SpecialOrder, StockType.SpecialOrderSAP].includes(
        availability.record.stockType,
      ),
    )
    .map((availability) => createMaterialListRow(availability.materialNumber));
  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

export function getStandardMaterials(
  unsafeMaterialInfoRecordStates: MaterialInfoRecordState[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const materialInfoRecordStates = safeMaterialInfoRecordState(
    unsafeMaterialInfoRecordStates,
  );
  const materialNumbers = materialInfoRecordStates.map((info) =>
    createMaterialListRow(info.materialNumber),
  );
  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

export function getStockWarningMaterials(
  unsafeCartReviewWarnings: CartReviewWarning[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const stockWarnings = [
    MaterialWarningType.NoStock,
    MaterialWarningType.OverAllocation,
    MaterialWarningType.PartialStockCase,
    MaterialWarningType.PartialStockCaseUnit,
  ];
  const cartReviewWarnings = safeCartReviewWarnings(unsafeCartReviewWarnings);
  const materialNumbers = cartReviewWarnings
    .filter((warning) => stockWarnings.includes(warning.type))
    .map((warning) => createMaterialListRow(warning.materialNumber));

  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

export function getUnavailableMaterials(
  unsafeAvailabilities: MaterialAvailabilityRecordState[],
  unsafeMaterialInfoRecordStates: MaterialInfoRecordState[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  const availabilities =
    safeMaterialAvailabilityRecordStates(unsafeAvailabilities);
  const materialInfoRecordStates = safeMaterialInfoRecordState(
    unsafeMaterialInfoRecordStates,
  );
  const unorderableMaterialNumbers = availabilities
    .filter((availability) => !availability.record.isOrderable)
    .map((availability) => availability.materialNumber);

  const unavailableMaterialNumbers = materialInfoRecordStates
    .filter((info) => MaterialInfoRecordStatus.Unavailable === info.status)
    .map((info) => info.materialNumber);

  const materialNumbers = [
    ...new Set([...unorderableMaterialNumbers, ...unavailableMaterialNumbers]),
  ].map((material) => createMaterialListRow(material));
  return excludeMaterialNumbers(materialNumbers, excludeMaterials);
}

const noDateTypes = [
  StockType.SpecialOrder,
  StockType.SpecialOrderSAP,
  StockType.DropShip,
];

export function isCartReviewLoading(
  unsafeMaterialInfoRecordStates: MaterialInfoRecordState[],
  unsafeAvailabilities: MaterialAvailabilityRecordState[],
  inventories: InventoryAvailabilityRecordState[],
  unavailableMaterialNumbers: MaterialListRow[],
  unsafeCriticalInfoRecordStates: MaterialInfoRecordState[] | undefined,
  isSelectedFulfillmentValidForSubmission: boolean,
): boolean {
  return (
    !(
      isInfoLoaded(unsafeMaterialInfoRecordStates) &&
      isAvailabilityLoaded(unsafeAvailabilities) &&
      isInventoryLoaded(inventories, unavailableMaterialNumbers) &&
      !!unsafeCriticalInfoRecordStates &&
      isInfoLoaded(unsafeCriticalInfoRecordStates) &&
      (isSelectedFulfillmentValidForSubmission ||
        !unsafeAvailabilities?.some(
          (availability) =>
            !noDateTypes.includes(availability?.record?.stockType),
        ))
    ) ||
    safeMaterialInfoRecordState(unsafeMaterialInfoRecordStates).length < 1 ||
    safeMaterialAvailabilityRecordStates(unsafeAvailabilities).length < 1 ||
    inventories.length < 1
  );
}

function excludeMaterialNumbers(
  materialNumbers: MaterialListRow[],
  excludeMaterials?: MaterialListRow[],
): MaterialListRow[] {
  if (!excludeMaterials) {
    return materialNumbers;
  }
  const excludeMaterialStrings = excludeMaterials.map(
    (material) => material.value as string,
  );
  return materialNumbers.filter(
    (material) => !excludeMaterialStrings.includes(material.value as string),
  );
}

function isAvailabilityLoaded(
  availabilities: MaterialAvailabilityRecordState[],
): boolean {
  return availabilities.every((availability) =>
    [
      MaterialAvailabilityRecordStatus.Error,
      MaterialAvailabilityRecordStatus.Success,
    ].includes(availability?.status),
  );
}

function isInfoLoaded(materialInfos: MaterialInfoRecordState[]): boolean {
  return materialInfos.every((info) =>
    [
      MaterialInfoRecordStatus.Error,
      MaterialInfoRecordStatus.Success,
      MaterialInfoRecordStatus.Unavailable,
    ].includes(info?.status),
  );
}

function isInventoryLoaded(
  inventories: InventoryAvailabilityRecordState[],
  unavailableMaterialRows: MaterialListRow[],
): boolean {
  if (inventories.some((inventory) => !inventory)) {
    return false;
  }
  const unavailableMaterialNumbers = unavailableMaterialRows.map(
    (row) => row.value,
  );
  return inventories
    .filter(
      (inventory) =>
        !unavailableMaterialNumbers.includes(inventory.materialNumber),
    )
    .every((inventory) =>
      [
        InventoryAvailabilityRecordStatus.Error,
        InventoryAvailabilityRecordStatus.Success,
      ].includes(inventory.status),
    );
}

function safeCartReviewWarnings(
  warnings: CartReviewWarning[],
): CartReviewWarning[] {
  return warnings
    ? warnings.filter((warning: CartReviewWarning) => !!warning)
    : [];
}

function safeMaterialAvailabilityRecordStates(
  availabilities: MaterialAvailabilityRecordState[],
): MaterialAvailabilityRecordState[] {
  return availabilities
    ? availabilities.filter(
        (availability: MaterialAvailabilityRecordState) =>
          !!availability?.record,
      )
    : [];
}

function safeMaterialInfoRecordState(
  infoRecordStates: MaterialInfoRecordState[],
) {
  return infoRecordStates
    ? infoRecordStates.filter((info: MaterialInfoRecordState) => !!info?.record)
    : [];
}
