export interface EntitlementResponse {
  currentSchoolYear?: YearEntitlement;
  nextSchoolYear?: YearEntitlement;
}

export interface YearEntitlement {
  beginEffectiveDate: string;
  endEffectiveDate: string;
  entitlementMaterialDetails: EntitlementMaterialDetail[];
}

export enum EntitlementAllowedPricingType {
  COMMERCIAL = 'COMMERCIAL',
  COMMODITY = 'COMMODITY',
  NONE = 'NONE',
}

export interface EntitlementMaterialDetail {
  allowedPricingType: EntitlementAllowedPricingType;
  materialNumber: string;
  maxCommodityCasesAmount: number;
  shieldColorCode: number;
  shieldColorDescription: string;
  vendorId: string;
  vendorVptMethodId: number;
  vendorVptMethodName: string;
  mappings: MaterialAllocation[];
}

export interface MaterialAllocation {
  allocationAccountUom: string;
  availableAllocationBalanceAmount: number;
  beginningAllocationBalanceAmount: number;
  drawdownAmount: number;
  drawdownUom: string;
  fairMarketValueAmount: number;
  priceValuePassThroughAmount: string;
  splitType: string;
  usdaMaterialDescription: string;
  usdaMaterialId: string;
  fullDescription: string;
}
