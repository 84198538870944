import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportOrderModalComponent } from './import-order-modal/import-order-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NaooConstants } from '../shared/NaooConstants';
import { NaooIconService } from '../shared/services/images/naoo-icon.service';
import { ImportOrderErrorTableComponent } from './steps/error-table/import-order-error-table.component';
import { ImportOrderModalAnalytics } from './import-order-modal/shared/import-order-modal-analytics';

const { icons } = NaooConstants;

@NgModule({
  providers: [ImportOrderModalAnalytics],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatIconModule,
    SharedModule,
    MatProgressBarModule,
  ],
  declarations: [ImportOrderModalComponent, ImportOrderErrorTableComponent],
})
export class ImportOrderModule {
  readonly importOrderIcons = [
    icons.error,
    icons.warning,
    icons.import_icon_v2,
  ];

  constructor(public naooIconService: NaooIconService) {
    naooIconService.registerIcons(this.importOrderIcons);
  }
}
