import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { Page } from './material-recommendations.state';

export const MaterialRecommendationsActions = createActionGroup({
  source: 'Material Recommendations',
  events: {
    'Load Material Recommendations': (page: Page, materialNumber?: string) => ({
      page,
      materialNumber,
    }),
    'Get Material Recommendations': (page: Page, materialNumber?: string) => ({
      page,
      materialNumber,
    }),
    'Get Material Recommendations Error': (page: Page) => ({
      page,
    }),
    'Get Material Recommendations Success': (
      page: Page,
      materialRecommendations: MaterialRecommendations[],
    ) => ({ page, materialRecommendations }),
    'Refresh Material Recommendations': emptyProps(),
    'Clear Material Recommendations': emptyProps(),
  },
});
