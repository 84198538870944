import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsPdfService {
  async getJsPdfInstance(orientation: 'portrait' | 'landscape') {
    const { jsPDF } = await this.importJspdf();
    return new jsPDF({
      orientation: orientation,
      unit: 'pt',
      format: 'letter',
    });
  }

  /**
   * Lazy load jsPDF
   */
  async importJspdf() {
    await import('jspdf-autotable');
    return import('jspdf');
  }
}
