<div class="product-comparison">
  <div class="back-button-container">
    <naoo-back-button [navigationLink]="backNavigationLink"></naoo-back-button>
  </div>
  <div class="page-header" #pageHeader>
    <h1 class="page-title">{{ 'PRODUCT_COMPARISON.TITLE' | translate }}</h1>
  </div>
  <div class="central-column">
    <div
      class="comparison-card-carousel sticky-carousel"
      [ngClass]="{ 'should-hide': !shouldStickyHeader }"
      [style.width]="headerWidth"
    >
      <div class="comparison-card-container">
        <div class="sticky-container">
          <naoo-material-carousel
            #popoverCarouselComponent
            [maxVisibleMaterials]="5"
            [materialContext]="comparisonsContext"
            [recommendationsAnalyticsEnabled]="false"
            [materialListStyle]="slimGridStyle"
            (visibleRowsDidChange)="popoverDidChangeVisibleRows($event)"
            [materialListRows]="materialListRows"
          >
          </naoo-material-carousel>
        </div>
      </div>
    </div>
    <div
      class="comparison-card-carousel comparison-card-container comparison-card-wrapper"
      #mainCarousel
    >
      <naoo-material-carousel
        #carouselComponent
        [maxVisibleMaterials]="5"
        [materialContext]="comparisonsContext"
        [recommendationsAnalyticsEnabled]="false"
        (visibleRowsDidChange)="visibleRowsDidChange($event)"
        [materialListRows]="materialListRows"
      >
      </naoo-material-carousel>
    </div>
    <div
      class="comparison-attributes-container"
      [style.width.px]="carouselComponent.carouselContentWidth"
      (swipeLeft)="slideMaterialCard(true)"
      (swipeRight)="slideMaterialCard(false)"
      [style.touch-action]="'pan-y'"
    >
      <naoo-comparison-attributes
        [viewModel]="attributesViewModel$ | async"
        [visibleMaterialIds]="visibleMaterialIds$ | async"
      ></naoo-comparison-attributes>
    </div>
  </div>
</div>
