import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import {
  selectMaterialRecommendations,
  selectMaterialRecommendationsRecordStateIsLoading,
} from './material-recommendations.selector';
import { MaterialRecommendationsActions } from './material-recommendations.actions';
import { Page } from './material-recommendations.state';

@Injectable({ providedIn: 'root' })
export class MaterialRecommendationsFacade {
  constructor(private store: Store) {}

  loadMaterialRecommendations(page: Page, materialNumber?: string): void {
    this.store.dispatch(
      MaterialRecommendationsActions.loadMaterialRecommendations(
        page,
        materialNumber,
      ),
    );
  }

  getLoadedMaterialRecommendations(
    page: string,
    sortByLastOrdered?: boolean,
  ): Observable<MaterialRecommendations[]> {
    return this.store
      .select(selectMaterialRecommendations(page, sortByLastOrdered))
      .pipe(filter((materialRecommendations) => !!materialRecommendations));
  }

  isMaterialRecommendationsLoading(page: string): Observable<boolean> {
    return this.store.select(
      selectMaterialRecommendationsRecordStateIsLoading(page),
    );
  }
}
