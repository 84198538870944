import { MenuPositionY } from '@angular/material/menu';

export class MatMenuUtilities {
  public static readonly addToCustomGuideMenuMaxHeight = 346;

  public static determineMenuOpenDirection(
    documentElement: HTMLElement,
    menuNativeElement: Element,
    menuMaxHeight: number,
  ): MenuPositionY {
    const viewportHeight = documentElement.clientHeight;
    const visibleActivatorBottom =
      menuNativeElement.getBoundingClientRect().bottom;

    if (visibleActivatorBottom + menuMaxHeight > viewportHeight) {
      return 'above';
    } else {
      return 'below';
    }
  }
}
