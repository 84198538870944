import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { NaooConstants } from '../../NaooConstants';
import { of, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { CustomGuideRecord } from '../../../core/services/custom-guide/model/custom-guide-record';

@Injectable({
  providedIn: 'root',
})
export class CustomGuideNameExistsValidator {
  constructor(private customGuideFacade: CustomGuideFacade) {}

  validate(initialCustomGuideName?: string): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
      const initialCustomGuideValue =
        initialCustomGuideName && initialCustomGuideName.toLowerCase();
      const newCustomGuideName = ctrl.value.toLowerCase().trim();
      if (initialCustomGuideValue === newCustomGuideName) {
        return of(null);
      }
      return this.customGuideFacade.getAllLoadedSortedCustomGuides().pipe(
        first(),
        map((customGuides) => {
          const existingNamedGuide = customGuides.find(
            (customGuide: CustomGuideRecord) =>
              !!customGuide.name &&
              customGuide.name.toLowerCase() === newCustomGuideName,
          );
          const matchesOrderGuideName =
            NaooConstants.ORDER_GUIDE_EN.toLowerCase() === newCustomGuideName ||
            NaooConstants.ORDER_GUIDE_FR.toLowerCase() === newCustomGuideName;
          return !!existingNamedGuide || matchesOrderGuideName
            ? { alreadyExistingCustomGuideName: true }
            : null;
        }),
        catchError(() => of(null)),
      );
    };
  }
}
