import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { BannerAdsActions } from './banner-ads.actions';
import { selectSession } from '../session/session.selectors';
import { audit, catchError, first, map, mergeMap } from 'rxjs/operators';
import { BannerAdsRecord } from '../../services/banner-ads/models/banner-ads-record';
import { BannerAdsService } from '../../services/banner-ads/banner-ads.service';
import { UrlSerializerService } from '../../../shared/services/url-serializer/url-serializer.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';

@Injectable()
export class BannerAdsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private bannerAdsService: BannerAdsService,
    private urlSerializerService: UrlSerializerService,
  ) {}

  getBannerAds$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(BannerAdsActions.getBannerAds),
      audit(() =>
        this.store
          .select(selectSession)
          .pipe(first((session) => !!session.activeCustomer)),
      ),
      concatLatestFrom(() => this.store.select(selectSession)),
      mergeMap(() => {
        return this.bannerAdsService.getBannerAds().pipe(
          mergeMap((bannerAdsRecord: BannerAdsRecord) => {
            const materialNumbers =
              this.getBannerMaterialNumbers(bannerAdsRecord);
            return [
              BannerAdsActions.getBannerAdsSuccess(bannerAdsRecord),
              MaterialInfoActions.loadMaterialInfo(materialNumbers),
            ];
          }),
          catchError(() => of(BannerAdsActions.getBannerAdsError())),
        );
      }),
    );
  });

  refreshBannerAds$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(BannerAdsActions.refreshBannerAds),
      map(() => BannerAdsActions.getBannerAds()),
    );
  });

  private getBannerMaterialNumbers(bannerAdsRecord: BannerAdsRecord): string[] {
    return [
      ...new Set(
        Object.values(bannerAdsRecord.banners)
          .map((banner) => {
            return this.urlSerializerService.getMaterialNumbersFromUrl(
              banner?.ctTargetUrl,
            );
          })
          .reduce((acc, materialNumbers) => acc.concat(materialNumbers), []),
      ),
    ];
  }
}
