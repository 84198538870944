import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PicRecord } from './models/pic-record';

@Injectable({ providedIn: 'root' })
export class NutritionService {
  constructor(private httpClient: HttpClient) {}

  getNutrition(materialNumber: string): Observable<PicRecord> {
    return this.httpClient.get<PicRecord>(
      `/api/v1/materials/${materialNumber}/nutrition`,
    );
  }
}
