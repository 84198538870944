export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    const lastPart = parts[parts.length - 1];
    return lastPart.split(';').shift();
  } else {
    return '';
  }
}
