import { ImportGuideRow } from './import-guide-row';
import { ImportGuideValidationResultType } from './import-guide-validation-result-type';

export class ImportGuideValidationData {
  type: ImportGuideValidationResultType;
  data: ImportGuideRow[];
  errorsCount: number;
  validItemIds: string[] = [];

  constructor() {
    this.data = [];
    this.errorsCount = 0;
  }
}
