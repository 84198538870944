<button
  [class]="
    'banner-offline-container ' +
    (this.bannerState ? 'banner-online-container' : '')
  "
  (click)="toggleCollapsed()"
>
  <div class="messages">
    <div class="offline-banner-content">
      <div class="msg-title">
        <mat-icon svgIcon="offline-icon" class="offline-icon"></mat-icon>
        <div class="title">{{ offlineTitle | translate }}</div>
        <mat-icon
          *ngIf="!this.bannerState"
          class="toggle-icon"
          svgIcon="arrow-v2"
          [@rotate]="isCollapsed ? ROTATE_180 : ROTATE_DEFAULT"
          [attr.aria-hidden]="true"
        ></mat-icon>
      </div>
      <div *ngIf="!this.bannerState && !isCollapsed" class="msg-body">
        <span>{{
          'OFFLINE_NOTIFICATION_BANNER.NO_INTERNET_CONNECTION_ERROR' | translate
        }}</span>
      </div>
    </div>
  </div>
</button>
