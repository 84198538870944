import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { bannerAdsReducer } from './banner-ads.reducer';
import { BannerAdsEffects } from './banner-ads.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('bannerAds', bannerAdsReducer),
    EffectsModule.forFeature([BannerAdsEffects]),
  ],
})
export class BannerAdsStoreModule {}
