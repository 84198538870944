import {
  initialLastOrderedState,
  lastOrderedAdapter,
  LastOrderedRecordState,
  LastOrderedRecordStatus,
  LastOrderedState,
} from './last-ordered.state';
import { LastOrderedActions } from './last-ordered.actions';
import { Update } from '@ngrx/entity';
import { LastOrderedRecord } from '../../services/last-ordered/models/last-ordered-records';
import { createReducer, on } from '@ngrx/store';

export const lastOrderedReducer = createReducer(
  initialLastOrderedState,
  on(
    LastOrderedActions.loadLastOrdered,
    (state, action): LastOrderedState =>
      loadLastOrdered(state, action.materialNumbers),
  ),
  on(
    LastOrderedActions.getLastOrdered,
    (state, action): LastOrderedState =>
      getLastOrdered(state, action.materialNumbers),
  ),
  on(
    LastOrderedActions.getLastOrderedSuccess,
    (state, action): LastOrderedState =>
      getLastOrderedSuccess(state, action.lastOrderedDateRecords),
  ),
  on(
    LastOrderedActions.getLastOrderedError,
    (state, action): LastOrderedState =>
      getLastOrderedError(state, action.materialNumbers),
  ),
  on(
    LastOrderedActions.clearLastOrdered,
    (): LastOrderedState => initialLastOrderedState,
  ),
);

function loadLastOrdered(
  state: LastOrderedState,
  materialNumbers: string[],
): LastOrderedState {
  const upsertedEntities: LastOrderedRecordState[] = materialNumbers
    .filter((materialNumber) => {
      return (
        !state.lastOrderedRecords.entities[materialNumber] ||
        state.lastOrderedRecords.entities[materialNumber].status ===
          LastOrderedRecordStatus.Error
      );
    })
    .map((materialNumber) => {
      return {
        materialNumber: materialNumber,
        status: LastOrderedRecordStatus.Queued,
        record: undefined,
      };
    });

  return {
    lastOrderedRecords: lastOrderedAdapter.upsertMany(
      upsertedEntities,
      state.lastOrderedRecords,
    ),
  };
}

function getLastOrdered(
  state: LastOrderedState,
  materialNumbers: string[],
): LastOrderedState {
  const queuedEntities: Update<LastOrderedRecordState>[] = materialNumbers
    .filter((materialNumber) => {
      return (
        state.lastOrderedRecords.entities[materialNumber].status ===
        LastOrderedRecordStatus.Queued
      );
    })
    .map((materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: LastOrderedRecordStatus.Requested,
        },
      };
    });

  return {
    lastOrderedRecords: lastOrderedAdapter.updateMany(
      queuedEntities,
      state.lastOrderedRecords,
    ),
  };
}

function getLastOrderedSuccess(
  state: LastOrderedState,
  lastOrderedRecords: LastOrderedRecord[],
): LastOrderedState {
  const updatedLastOrderRecordStates: Update<LastOrderedRecordState>[] =
    lastOrderedRecords.map((lastOrderedRecord) => {
      return {
        id: lastOrderedRecord.materialNumber,
        changes: {
          status: LastOrderedRecordStatus.Success,
          record: lastOrderedRecord,
        },
      };
    });

  return {
    lastOrderedRecords: lastOrderedAdapter.updateMany(
      updatedLastOrderRecordStates,
      state.lastOrderedRecords,
    ),
  };
}

function getLastOrderedError(
  state: LastOrderedState,
  materialNumbers: string[],
): LastOrderedState {
  const updatedlastOrderedRecordStates: Update<LastOrderedRecordState>[] =
    materialNumbers.map((materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: LastOrderedRecordStatus.Error,
          record: undefined,
        },
      };
    });

  return {
    lastOrderedRecords: lastOrderedAdapter.updateMany(
      updatedlastOrderedRecordStates,
      state.lastOrderedRecords,
    ),
  };
}
