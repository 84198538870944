<div class="product" *ngIf="materialInfo">
  <ng-container *ngIf="!materialInfo.isEmpty; else emptyMaterial">
    <span
      class="product-image"
      [attr.aria-label]="materialInfo.description | stringdefaulter"
    >
      <ng-container [ngTemplateOutlet]="materialImage"></ng-container>
    </span>
  </ng-container>

  <div class="product-details">
    <ng-container *ngIf="!materialInfo.isEmpty; else emptyMaterialDescription">
      <span [attr.aria-label]="materialInfo.description | stringdefaulter">
        <ng-template [ngTemplateOutlet]="materialDescription"></ng-template>
      </span>
    </ng-container>
    <button
      id="remove-{{ materialNumber }}"
      class="remove-button"
      (click)="removeMaterial()"
    >
      {{ 'COMPARE_DOCKET.REMOVE' | translate }}
    </button>
  </div>
</div>

<ng-template #emptyMaterial>
  <div
    class="product-image"
    [attr.aria-label]="materialInfo.description | stringdefaulter"
  >
    <ng-container [ngTemplateOutlet]="materialImage"></ng-container>
  </div>
</ng-template>

<ng-template #materialImage>
  <naoo-product-image
    [materialNumber]="materialNumber"
    [dimension]="listImageDimension"
    [alt]="materialInfo.description | stringdefaulter"
  ></naoo-product-image>
</ng-template>

<ng-template #emptyMaterialDescription>
  <div [attr.aria-label]="materialInfo.description | stringdefaulter">
    <ng-template [ngTemplateOutlet]="materialDescription"></ng-template>
  </div>
</ng-template>

<ng-template #materialDescription>
  <div class="description">
    {{ materialInfo.description | stringdefaulter }}
  </div>
</ng-template>
