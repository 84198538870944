import { Injectable } from '@angular/core';
import { NaooHttpClient } from 'src/app/shared/http-client/naoo-http-client';
import { WebBffService } from 'src/app/shared/services/web-bff/web-bff.service';
import { MaterialSearchResponse } from 'src/app/shared/models/search/material-search-response';
import { Observable } from 'rxjs';
import { CategoryProductSearchResponse } from 'src/app/shared/models/search/category-product-search-response';
import { SearchConstants } from 'src/app/shared/models/search/SearchConstants';
import { HttpParams } from '@angular/common/http';
import { Filter } from 'src/app/shared/models/search/filter';
import { RouterStateUrl } from '../../store/router/router-state-serializer';
import { CatalogResponse } from '../../../shared/models/catalog/catalog-response';
import { FulfillmentType } from '../cart/models/cart-record';
import { RecommendationBanner } from '../../store/search/search.state';

export interface SearchResultResponse {
  materialNumbers: string[];
  filterList: Filter[];
  totalResults: number;
  suggestedSearchText?: string;
  categoryTree?: CatalogResponse;
  recommendations?: SponsoredRecommendations;
}

export interface SponsoredRecommendations {
  banners: RecommendationBanner[];
  productRecommendations: RecommendationProduct[];
}

export interface RecommendationProduct {
  materialId: string;
  campaignTracking: string;
}

export interface SearchOptions {
  searchText?: string;
  searchOffset?: number;
  resultsLimit?: number;
  filters?: Map<string, Set<string>>;
  orderGuideOnly?: boolean;
  availableToday?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(
    private http: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  performSearch(
    routerState: RouterStateUrl,
    fulfillmentType: FulfillmentType,
    searchOffset: number,
    resultsLimit?: number,
  ): Observable<SearchResultResponse> {
    const isSearch = routerState.url.includes('/search');
    const searchOptions = this.buildSearchOptions(
      isSearch,
      routerState,
      fulfillmentType,
      searchOffset,
      resultsLimit || SearchConstants.searchOffsetCount,
    );

    return isSearch
      ? this.getSearchResults(searchOptions)
      : this.getCategorySearch(searchOptions);
  }

  private getSearchResults(
    searchOptions: SearchOptions,
  ): Observable<SearchResultResponse> {
    return this.http.get<MaterialSearchResponse>(
      this.webBffService.getBff() + SearchConstants.searchEndpoint,
      {
        params: this.buildParams(searchOptions, true),
      },
    );
  }

  private getCategorySearch(
    searchOptions: SearchOptions,
  ): Observable<SearchResultResponse> {
    return this.http.get<CategoryProductSearchResponse>(
      this.webBffService.getBff() + SearchConstants.categorySearchEndpoint,
      {
        params: this.buildParams(searchOptions, false),
      },
    );
  }

  private buildParams(
    searchOptions: SearchOptions,
    isSearch: boolean,
  ): HttpParams {
    let searchParameters = new HttpParams().append(
      isSearch
        ? SearchConstants.paramSearchText
        : SearchConstants.paramCategoryCoordinate,
      searchOptions.searchText,
    );

    if (!!searchOptions.filters && searchOptions.filters.size) {
      searchOptions.filters.forEach((value, key) => {
        searchParameters = searchParameters.append(
          key,
          Array.from(value)
            .map((paramValue) => paramValue)
            .join(','),
        );
      });
    }

    if (searchOptions.searchOffset >= 0) {
      searchParameters = searchParameters.append(
        SearchConstants.paramSearchOffset,
        `${searchOptions.searchOffset}`,
      );
    }

    if (searchOptions.resultsLimit) {
      searchParameters = searchParameters.append(
        SearchConstants.paramResultsLimit,
        `${searchOptions.resultsLimit}`,
      );
    }

    if (searchOptions.orderGuideOnly) {
      searchParameters = searchParameters.append(
        SearchConstants.paramOrderGuideOnly,
        'true',
      );
    }

    if (searchOptions.availableToday) {
      searchParameters = searchParameters.append(
        SearchConstants.paramAvailableTodayFilter,
        'true',
      );
    }

    return searchParameters;
  }

  private buildSearchOptions(
    isSearch: boolean,
    routerState: RouterStateUrl,
    fulfillmentType: FulfillmentType,
    searchOffset: number,
    resultsLimit: number,
  ): SearchOptions {
    const searchText = isSearch
      ? routerState.queryParams[SearchConstants.paramSearchText]
      : routerState.params[SearchConstants.paramCategoryCoordinate];

    const filters = new Map<string, Set<string>>();
    Object.keys(routerState.queryParams)
      .filter(
        (queryKey) =>
          queryKey !== SearchConstants.paramSearchText &&
          queryKey !== SearchConstants.paramOrderGuideOnly &&
          queryKey !== SearchConstants.paramAvailableTodayFilter,
      )
      .forEach((queryKey) => {
        let value: Set<string>;
        if (Array.isArray(routerState.queryParams[queryKey])) {
          value = new Set(routerState.queryParams[queryKey]);
        } else {
          value = new Set(
            (routerState.queryParams[queryKey] as string).split(','),
          );
        }
        filters.set(queryKey, value);
      });

    const orderGuideOnly =
      routerState.queryParams[SearchConstants.paramOrderGuideOnly];

    const availableToday =
      [FulfillmentType.EXPRESS, FulfillmentType.PICKUP].includes(
        fulfillmentType,
      ) && routerState.queryParams[SearchConstants.paramAvailableTodayFilter];

    return {
      searchText,
      searchOffset,
      resultsLimit,
      filters,
      orderGuideOnly,
      availableToday,
    };
  }
}
