import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { GenericDocketComponent } from './generic-docket.component';
import { GenericDocketHeaderComponent } from './generic-docket-header/generic-docket-header.component';
import { A11yModule } from '@angular/cdk/a11y';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    A11yModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [GenericDocketComponent, GenericDocketHeaderComponent],
  exports: [GenericDocketComponent, GenericDocketHeaderComponent],
})
export class GenericDocketModule {}
