<ng-container>
  <button
    class="search-button-mobile"
    (click)="openMobileSearchModal()"
    role="link"
    [attr.aria-label]="'SEARCH.PLACEHOLDER_MOBILE' | translate"
    [disabled]="isDisabled"
  >
    <mat-icon
      [ngClass]="{ disabled: isDisabled }"
      svgIcon="search_icon_v2"
    ></mat-icon>
  </button>
</ng-container>
