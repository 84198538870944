import { CategorizedCriticalItemRecord } from '../../services/critical-items/model/categorized-critical-items';
import { SortByType } from '../../../guides/shared/guides';

export interface CriticalItemsState {
  categorizedCriticalItems: CategorizedCriticalItemRecord[];
  sortBy: SortByType;
  searchText: string;
}

export const initialCriticalItemsState: CriticalItemsState = {
  categorizedCriticalItems: undefined,
  sortBy: SortByType.Description,
  searchText: '',
};
