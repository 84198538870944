import { FactoryProvider, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/* Create a new injection token for injecting the window into a component. */
export const CONTENT_SCROLL = new InjectionToken('MainContentToken');

/* Create an factory function that returns the main scroll element. */
export function mainScrollContentFactory(document: Document): Element | null {
  return document.getElementById('scrolling-content');
}

/* Create an injectable provider that uses the windowFactory function for returning the main scroll element. */
const mainScrollProvider: FactoryProvider = {
  provide: CONTENT_SCROLL,
  useFactory: mainScrollContentFactory,
  deps: [DOCUMENT],
};

// /* Create an array of providers. */
export const SCROLL_CONTAINER_PROVIDER = [mainScrollProvider];
