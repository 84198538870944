import { Injectable } from '@angular/core';
import { OrderConfirmation } from './models/order-confirmation';
import { catchError, first, map, timeout } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { CartOrderService } from '../../core/services/cart-order/cart-order.service';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CustomerMaterialFacade } from '../../core/store/customer-material/customer-material.facade';
import { CustomerMaterialRecord } from '../../core/services/customer-material/model/customer-material-record';
import { OrderConfirmationTransformationService } from './order-confirmation-transformation.service';
import { OrderConfirmationCompleteResponse } from '../../core/services/order-confirmation/models/order-confirmation-record';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import { MaterialRelatedFacade } from '../../core/store/material-related/material-related.facade';
import { MaterialPriceFacade } from '../../core/store/material-price/material-price.facade';
import { CombinedPricingRecord } from '../../core/store/material-price/material-price.util';
import { StoreFacade } from '../../core/store/store/store.facade';
import {
  OrderType,
  RequestedDeliveryType,
} from '../../shared/models/order-type';
import { StoreRecord } from '../../core/services/store/model/store-record';

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationAggregatorService {
  constructor(
    private sessionFacade: SessionFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private customerMaterialFacade: CustomerMaterialFacade,
    private transformationService: OrderConfirmationTransformationService,
    private cartOrderService: CartOrderService,
    private materialRelatedFacade: MaterialRelatedFacade,
    private storeFacade: StoreFacade,
  ) {}

  aggregateAndTransform(
    orderConfirmationCompleteResponse: OrderConfirmationCompleteResponse,
    cartRouteDate?: Date,
  ): Observable<OrderConfirmation> {
    const materialNumbers: string[] = [];
    const processingMaterialNumbers: string[] = [];
    const storePlantIds: Set<string> = new Set<string>();

    this.materialRelatedFacade.clearMaterialRelated();

    orderConfirmationCompleteResponse.processingCartOrders.forEach((order) => {
      order.materials.forEach((material) =>
        processingMaterialNumbers.push(material.materialNumber),
      );
    });

    orderConfirmationCompleteResponse.orders.forEach((order) => {
      if (
        OrderType.StoreFulfillment === order.orderType &&
        RequestedDeliveryType.EXPRESS !== order.requestedDeliveryType &&
        !!order.storeFulfillment?.storePlantId
      ) {
        storePlantIds.add(order.storeFulfillment.storePlantId);
      }
      order.materials.forEach((material) => {
        materialNumbers.push(material.materialNumber);
        if (material.substituteForMaterial) {
          materialNumbers.push(material.substituteForMaterial);
        }
      });
    });

    materialNumbers.push(...processingMaterialNumbers);

    const activeCustomer$ = this.sessionFacade
      .getLoadedActiveCustomer()
      .pipe(first());

    const priceMap = new Map<string, CombinedPricingRecord>();
    let itemPricing$: Observable<Map<string, CombinedPricingRecord>> =
      of(priceMap);
    if (processingMaterialNumbers.length > 0) {
      itemPricing$ = this.materialPriceFacade
        .getLoadedCombinedPriceMap(materialNumbers)
        .pipe(
          first(),
          timeout(5000),
          catchError(() => of(priceMap)),
        );
    }

    let storeRecords$: Observable<Map<string, StoreRecord>> = of(new Map());
    if (storePlantIds.size > 0) {
      this.storeFacade.loadStores();
      storeRecords$ = this.storeFacade
        .getLoadedStoreRecords(Array.from(storePlantIds.values()))
        .pipe(
          first(),
          timeout(5000),
          catchError(() => of(new Map())),
        );
    }

    this.materialInfoFacade.loadMaterialInfos(materialNumbers);

    const itemInfos$ = this.materialInfoFacade
      .getLoadedMaterialInfoMap(materialNumbers)
      .pipe(first());

    const customerMaterial$: Observable<CustomerMaterialRecord> =
      this.customerMaterialFacade
        .getLoadedCustomerMaterial(materialNumbers)
        .pipe(
          first(),
          timeout(5000),
          catchError(() => of({})),
        );

    return forkJoin([
      activeCustomer$,
      itemPricing$,
      itemInfos$,
      customerMaterial$,
      storeRecords$,
    ]).pipe(
      map(
        ([
          activeCustomer,
          pricingMap,
          infoMap,
          customerMaterial,
          storeRecords,
        ]) => {
          this.cartOrderService.markSubmitModalDone();
          const orderConfirmation: OrderConfirmation =
            this.transformationService.transformOrderConfirmation(
              orderConfirmationCompleteResponse,
              activeCustomer,
              storeRecords,
              pricingMap,
              infoMap,
              customerMaterial,
              cartRouteDate,
            );

          if (
            orderConfirmation.orders.errorMaterials &&
            orderConfirmation.orders.errorMaterials.length > 0
          ) {
            const exceptionMaterialNumbers =
              orderConfirmation.orders.errorMaterials.map(
                (itemException) => itemException.itemDetail.id,
              );
            this.materialRelatedFacade.loadMaterialRelated(
              exceptionMaterialNumbers,
            );
          }

          return orderConfirmation;
        },
      ),
    );
  }
}
