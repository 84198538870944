import { ExpressScheduleRecord } from '../../services/express-schedules/models/express-schedule-record';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const ExpressSchedulesActions = createActionGroup({
  source: 'ExpressSchedules',
  events: {
    'Load Express Schedules': emptyProps(),
    'Get Express Schedules': emptyProps(),
    'Get Express Schedules Success': (
      expressSchedules: ExpressScheduleRecord[],
    ) => ({ expressSchedules }),
    'Get Express Schedules Failure': emptyProps(),
    'Refresh Express Schedules': emptyProps(),
  },
});
