export class NaooStorage {
  private sessionStorageFallback = new Map<string, string>();
  constructor(private storage: Storage) {}

  public getItem(key: string): string | null {
    try {
      return this.storage.getItem(key);
    } catch (domException) {
      // handle any error that could be thrown by sessionStorage.getItem
      return this.getFromFallbackStorage(key);
    }
  }

  public setItem(key: string, value: string): void {
    try {
      this.storage.setItem(key, value);
    } catch (domException) {
      // handle any error that could be thrown by sessionStorage.setItem
      this.setFromFallbackStorage(key, value);
    }
  }

  public removeItem(key: string): void {
    try {
      this.storage.removeItem(key);
    } catch (domException) {
      this.removeFromFallbackStorage(key);
    }
  }

  public clear(): void {
    try {
      this.storage.clear();
    } catch (domException) {
      this.clearFromFallbackStorage();
    }
  }

  private setFromFallbackStorage(key: string, val: string) {
    this.sessionStorageFallback.set(key, val);
  }

  private getFromFallbackStorage(key: string): string {
    return this.sessionStorageFallback.get(key);
  }

  private removeFromFallbackStorage(key: string): void {
    this.sessionStorageFallback.delete(key);
  }

  private clearFromFallbackStorage(): void {
    this.sessionStorageFallback.clear();
  }
}
