import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MaterialWarning,
  MaterialWarningType,
  WarningOpenDirection,
} from 'src/app/core/store/material-warning/material-warning';
import { MaterialWarningFacade } from 'src/app/core/store/material-warning/material-warning.facade';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { animate, style, transition, trigger } from '@angular/animations';
import { asyncScheduler, Subject, timer } from 'rxjs';
import { subscribeOn, takeUntil, tap } from 'rxjs/operators';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../../core/store/material-row/models/material-row';
import { ZoneUtilities } from '../../../../../shared/utilities/zone-utilities';
import { CustomDimension } from '../../../../../shared/analytics/custom-dimension';

@Component({
  selector: 'naoo-material-warning',
  templateUrl: './material-warning.component.html',
  styleUrls: ['./material-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(125, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(125, style({ opacity: 0 }))]),
    ]),
  ],
})
export class MaterialWarningComponent implements OnInit, OnDestroy, OnChanges {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() openDirection: WarningOpenDirection;
  @Input() isInventoryLoaded: boolean;
  @Input() selectedOrderingInfoIndex: number;
  @Input() isMobile: boolean;
  @Input() warning: MaterialWarning;

  private readonly inventoryTimeOut = 10000;
  private readonly defaultCenterTopTooltipClass = 'center-top-tooltip';
  private readonly customDimension12: CustomDimension = {
    index: 12,
    value: 'Site Location (URL)',
  };

  private hasWaitedForInventory = false;
  private destroyed$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['warning'] && changes['warning'].currentValue) ||
      (changes['materialRowOptions'] &&
        changes['materialRowOptions'].currentValue)
    ) {
      this.triggerWarningAnalyticsEvent();
    }
  }

  @HostBinding('class.par-enabled') get hasParEnabledOffset(): boolean {
    return this.materialRowOptions.isParEnabled && this.isMobile;
  }

  get shouldShowWarning(): boolean {
    return (
      !!this.warning &&
      (this.isInventoryLoaded || this.hasWaitedForInventory) &&
      this.shouldShowWarningForSelectedUom
    );
  }

  get warningActiveClass(): string {
    return this.warning.isOpen ? 'active' : 'inactive';
  }

  get tooltipIconClass(): string {
    return this.warning.isHardWarning
      ? 'tooltip-icon-hard-warning'
      : 'tooltip-icon';
  }

  get warningIconClass(): string {
    return this.warning.isHardWarning
      ? 'warning-icon-hard-warning'
      : 'warning-icon';
  }

  get tooltipClassForTwoLine(): string {
    return this.warning.isMultiLine ? 'tooltip-two-lines' : '';
  }

  get warningTooltipClass(): string {
    switch (this.openDirection) {
      case WarningOpenDirection.Up:
        return this.centerTopTooltipClass();
      case WarningOpenDirection.UpOffset:
        return 'offset-top-tooltip';
      case WarningOpenDirection.DownLeft:
        return 'bottom-left-tooltip';
      case WarningOpenDirection.Left:
        return 'straight-left-tooltip';
      default:
        return this.warning.isMultiLine
          ? 'center-top-tooltip tooltip-two-lines'
          : 'center-top-tooltip';
    }
  }

  get shouldShowWarningForSelectedUom(): boolean {
    if (this.warning) {
      if (
        [
          MaterialWarningType.NoStock,
          MaterialWarningType.PartialStockCase,
          MaterialWarningType.PartialStockCaseUnit,
          MaterialWarningType.UnitCaseConversion,
          MaterialWarningType.OpenSpecialOrders,
          MaterialWarningType.MissedCutoff,
          MaterialWarningType.MaximumQuantityHardStop,
          MaterialWarningType.MaximumQuantitySoftStop,
          MaterialWarningType.MinimumQuantitySoftStop,
          MaterialWarningType.OverAllocation,
        ].includes(this.warning.type) ||
        !this.isUsingSplitUoms
      ) {
        return true;
      }
      if (this.selectedOrderingInfoIndex === 0) {
        return [
          MaterialWarningType.QuantityThresholdExceededCase,
          MaterialWarningType.RepeatingDigitCase,
        ].includes(this.warning.type);
      } else {
        return [
          MaterialWarningType.QuantityThresholdExceededUnit,
          MaterialWarningType.RepeatingDigitUnit,
        ].includes(this.warning.type);
      }
    }
    return false;
  }

  get isUsingSplitUoms(): boolean {
    return (
      (this.materialRowOptions.materialListStyle === MaterialListStyle.Slim &&
        !this.isMobile) ||
      this.isMobile
    );
  }

  constructor(
    private materialWarningFacade: MaterialWarningFacade,
    private analytics: NaooAnalyticsManager,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    if (!this.isInventoryLoaded) {
      // We leave the ngZone for e2e so Angular is stable when executing tests
      timer(
        this.inventoryTimeOut,
        ZoneUtilities.leaveZone(this.ngZone, asyncScheduler),
      )
        .pipe(
          tap(() => {
            this.hasWaitedForInventory = true;
            // Only markForCheck if we need to which is when inventory isn't loaded
            if (!this.isInventoryLoaded) {
              this.changeDetector.markForCheck();
            }
          }),
          subscribeOn(ZoneUtilities.enterZone(this.ngZone, asyncScheduler)),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleWarningOpen() {
    const suppressedEvent = this.warning.analytics.suppressed;

    if (suppressedEvent) {
      this.analytics.trackAnalyticsEvent(suppressedEvent, [
        this.customDimension12,
      ]);
    }

    this.materialWarningFacade.acknowledgeWarning(
      this.materialRowOptions.materialNumber,
      this.warning.type,
    );

    this.warning.isOpen
      ? this.materialWarningFacade.closeWarning(
          this.materialRowOptions.materialNumber,
        )
      : this.materialWarningFacade.openWarning(
          this.materialRowOptions.materialNumber,
        );
  }

  private centerTopTooltipClass(): string {
    switch (this.materialRowOptions.context) {
      case MaterialRowContext.CriticalItem:
      case MaterialRowContext.CartReview:
        return `${this.defaultCenterTopTooltipClass} review-tooltip`;
      case MaterialRowContext.CustomGuide:
        return this.hasParEnabledOffset
          ? `${this.defaultCenterTopTooltipClass} par-enabled-tooltip`
          : this.defaultCenterTopTooltipClass;
      case MaterialRowContext.ComplimentaryProduct:
        return `${this.defaultCenterTopTooltipClass} complimentary-product-tooltip`;
      case MaterialRowContext.ProductDetails:
        return `${this.defaultCenterTopTooltipClass} product-details-tooltip`;
      default:
        return this.defaultCenterTopTooltipClass;
    }
  }

  private triggerWarningAnalyticsEvent() {
    const displayAnalyticsEvent =
      !!this.warning && this.warning.analytics.displayed;
    if (!!displayAnalyticsEvent && !!this.materialRowOptions) {
      this.analytics.trackAnalyticsEvent(displayAnalyticsEvent, [
        this.customDimension12,
      ]);
      this.materialWarningFacade.displayedAnalytics(
        this.materialRowOptions.materialNumber,
        this.warning.type,
      );
    }
  }
}
