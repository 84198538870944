import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { multipleCartsReducer } from './multiple-carts.reducer';
import { MultipleCartsEffects } from './multiple-carts.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('multipleCarts', multipleCartsReducer),
    EffectsModule.forFeature([MultipleCartsEffects]),
  ],
})
export class MultipleCartsStoreModule {}
