import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CategorizedMaterialsRecord } from '../../services/order-guide/models/categorized-materials-record';
import { CategorizedMaterials } from '../../../shared/models/categorized-materials';
import { SortByType } from '../../../guides/shared/guides';

export interface OrderGuideState {
  hasLoaded: boolean;
  records: EntityState<CategorizedMaterialsRecord>;
  isOrderGuideEditable: boolean;
  sortBy: SortByType;
  searchText: string;
  hasUnsavedChanges: boolean;
}

export interface OrderGuideV1 {
  categorizedMaterials: CategorizedMaterials[];
  sortBy: SortByType;
}

export const orderGuideAdapter =
  createEntityAdapter<CategorizedMaterialsRecord>({
    selectId: (record) => record.categoryId,
  });

export const orderGuideInitialState: OrderGuideState = {
  hasLoaded: false,
  records: orderGuideAdapter.getInitialState(),
  isOrderGuideEditable: true,
  sortBy: SortByType.Description,
  searchText: '',
  hasUnsavedChanges: false,
};
