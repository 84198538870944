import { Injectable } from '@angular/core';
import { ParseSpreadsheetService } from '../parse-spreadsheet/parse-spreadsheet.service';
import { ImportGuideSanitizer } from './sanitizer/import-guide-sanitizer';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { ImportGuideValidationData } from '../../models/import-guide/import-guide-validation-data';
import {
  ImportGuideRow,
  ImportGuideRowErrors,
} from '../../models/import-guide/import-guide-row';
import { ImportGuideValidationResultType } from '../../models/import-guide/import-guide-validation-result-type';
import { MaterialValidationService } from '../material-validation/material-validation.service';
import { MaterialInfo } from '../../models/material-info';

class ImportGuideValidationError implements Error {
  name: string;
  message: string;
  resultData: ImportGuideValidationData;

  constructor(resultData: ImportGuideValidationData) {
    this.resultData = resultData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ImportGuideValidationService {
  static readonly CATEGORY_NAME_MAX_LENGTH = 30;

  constructor(
    private parseSpreadsheetService: ParseSpreadsheetService,
    private materialValidationService: MaterialValidationService,
  ) {}

  validateFile(file: Blob): Observable<ImportGuideValidationData> {
    let validationResult = new ImportGuideValidationData();
    return this.parseSpreadsheetService
      .parseFile(file, new ImportGuideSanitizer())
      .pipe(
        tap((fileData) => {
          if (!fileData || fileData.length === 0) {
            this.setValidationTypeAndThrowError(
              validationResult,
              ImportGuideValidationResultType.BlankFile,
            );
          }
          const dataWithoutHeader = this.removeHeader(fileData);
          if (dataWithoutHeader.length === 0) {
            this.setValidationTypeAndThrowError(
              validationResult,
              ImportGuideValidationResultType.BlankFile,
            );
          }
          validationResult = this.createResultData(dataWithoutHeader);
        }),
        mergeMap(() => {
          this.throwErrorIfThereAreNoValidItems(validationResult);
          return this.materialValidationService.validateMaterials(
            validationResult.validItemIds,
          );
        }),
        mergeMap((materialValidationResults) => {
          const nonNullMaterialInfos = Array.from(
            materialValidationResults.values(),
          ).filter((info) => info);
          if (nonNullMaterialInfos.length === 0) {
            this.throwAllItemsUnavailableError(validationResult);
          }
          this.addErrorToUnavailableItems(
            nonNullMaterialInfos,
            validationResult,
          );
          validationResult.validItemIds = nonNullMaterialInfos.map(
            (info) => info.materialNumber,
          );
          if (validationResult.errorsCount === 0) {
            validationResult.type = ImportGuideValidationResultType.NoErrors;
          } else {
            validationResult.type = ImportGuideValidationResultType.RowErrors;
          }
          return observableOf(validationResult);
        }),
        catchError((error) => {
          if (error instanceof ImportGuideValidationError) {
            return observableOf(error.resultData);
          }
          return throwError(error);
        }),
      );
  }

  private throwAllItemsUnavailableError(
    validationResult: ImportGuideValidationData,
  ) {
    validationResult.errorsCount += validationResult.validItemIds.length;

    validationResult.validItemIds.forEach((id) => {
      validationResult.data.find((row) => row.itemId === id).error =
        ImportGuideRowErrors.InvalidItem;
    });

    this.setValidationTypeAndThrowError(
      validationResult,
      ImportGuideValidationResultType.InvalidFileFormat,
    );
  }

  private throwErrorIfThereAreNoValidItems(
    validationResult: ImportGuideValidationData,
  ) {
    if (validationResult.data.length === 0) {
      this.setValidationTypeAndThrowError(
        validationResult,
        ImportGuideValidationResultType.BlankFile,
      );
    }

    if (
      validationResult.validItemIds.length === 0 &&
      validationResult.errorsCount === 0
    ) {
      // This will be flagged as InvalidFileFormat because of the scenario where only categories are supplied.
      this.setValidationTypeAndThrowError(
        validationResult,
        ImportGuideValidationResultType.InvalidFileFormat,
      );
    }
  }

  private addErrorToUnavailableItems(
    materialInfos: MaterialInfo[],
    validationResult: ImportGuideValidationData,
  ) {
    validationResult.validItemIds.forEach((itemId) => {
      if (
        !materialInfos.find((material) => material.materialNumber === itemId)
      ) {
        validationResult.data.find((row) => row.itemId === itemId).error =
          ImportGuideRowErrors.InvalidItem;
        validationResult.errorsCount++;
      }
    });
  }

  private removeHeader(fileData: string[][]): string[][] {
    fileData.splice(0, 1);
    return fileData;
  }

  private createResultData(fileData: string[][]): ImportGuideValidationData {
    const validationData = new ImportGuideValidationData();
    fileData.forEach((row, index) => {
      if (!this.canIgnoreRow(row)) {
        const category = this.getCategory(row[0]);
        const itemId = row[1];
        const importGuideRow = new ImportGuideRow(index + 1, category, itemId);
        if (itemId) {
          if (!this.isItemIdUnique(itemId, validationData.data)) {
            importGuideRow.error = ImportGuideRowErrors.DuplicateItem;
            validationData.errorsCount++;
          } else {
            validationData.validItemIds.push(itemId);
          }
        }
        validationData.data.push(importGuideRow);
      }
    });
    return validationData;
  }

  private canIgnoreRow(row: string[]): boolean {
    return !row[0] && !row[1];
  }

  private getCategory(cellValue: string): string {
    return cellValue === null
      ? ''
      : cellValue.substring(
          0,
          ImportGuideValidationService.CATEGORY_NAME_MAX_LENGTH,
        );
  }

  private isItemIdUnique(itemId: string, data: ImportGuideRow[]): boolean {
    return data.find((row) => row.itemId === itemId) === undefined;
  }

  private setValidationTypeAndThrowError(
    validationResult: ImportGuideValidationData,
    type: ImportGuideValidationResultType,
  ) {
    validationResult.type = type;
    throw new ImportGuideValidationError(validationResult);
  }
}
