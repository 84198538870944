export enum MaterialIndicatorListName {
  CONTRACT = 'CONTRACT',
  AVENDRA = 'AVENDRA',
  BID_LOCKED_ITEM = 'BID_LOCKED_ITEM',
  COMMITTED_MANUFACTURER_ALLOWANCE = 'COMMITTED_MANUFACTURER_ALLOWANCE',
  MWBE_VENDOR = 'MWBE_VENDOR',
  PREFERRED_ITEM = 'PREFERRED_ITEM',
  REBATE = 'REBATE',
  FOOD_BUY_S1 = 'FOOD_BUY_S1',
  FOOD_BUY_S2 = 'FOOD_BUY_S2',
  FOOD_BUY_S3 = 'FOOD_BUY_S3',
}

export type IndicatorListsRecord = {
  [key in MaterialIndicatorListName]?: string[];
};

export interface IndicatorListsResponse {
  indicatorLists: IndicatorListsRecord;
}
