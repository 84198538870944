import { getCookie } from '../../utilities/cookie-utilities';
import { NaooConstants } from '../../NaooConstants';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WebBffService {
  private readonly bffLocation: string;

  constructor(windowObject: Window) {
    this.bffLocation = this.generateBffLocation(windowObject);
  }

  private generateBffLocation({ location }: Partial<Window>) {
    if (!location) {
      return '';
    }
    const regionCookie = getCookie(NaooConstants.REGION_COOKIE);
    const regionPath = regionCookie ? `/${regionCookie}` : '';
    const port = location.port ? `:${location.port}` : '';

    return `${location.protocol}//${location.hostname}${port}${regionPath}`;
  }

  getBff(): string {
    return this.bffLocation;
  }

  isBffCall(url: string): boolean {
    return url.indexOf(this.getBff()) !== -1;
  }
}
