import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreEffects } from './store.effects';
import { storeReducer } from './store.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('store', storeReducer),
    EffectsModule.forFeature([StoreEffects]),
  ],
})
export class StoreStoreModule {}
