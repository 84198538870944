import { CustomGuideRecord } from '../../services/custom-guide/model/custom-guide-record';
import { CustomGuideCategoryRequest } from '../../services/custom-guide/model/custom-guide-request';
import { CategorizedMaterialNumbers } from './custom-guide.state';
import { SortByType, GroupByType } from 'src/app/guides/shared/guides';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const CustomGuideActions = createActionGroup({
  source: 'Custom Guide',
  events: {
    'Get All Custom Guides': emptyProps(),
    'Get All Custom Guides Success': (records: CustomGuideRecord[]) => ({
      records,
    }),
    'Get All Custom Guides Failure': (error: unknown) => ({ error }),
    'Refresh All Custom Guides': emptyProps(),
    'Create Custom Guide': (
      name: string,
      groupBy: GroupByType,
      materialNumbers: string[] = [],
    ) => ({ name, groupBy, materialNumbers }),
    'Duplicate Custom Guide': (
      id: string,
      name: string,
      groupBy: GroupByType,
    ) => ({ id, name, groupBy }),
    'Create Categorized Custom Guide': (
      name: string,
      groupBy: GroupByType,
      categorizedMaterials: CustomGuideCategoryRequest[],
      parOrderingEnabled: boolean = false,
    ) => ({ name, groupBy, categorizedMaterials, parOrderingEnabled }),
    'Create Categorized Custom Guide Success': (record: CustomGuideRecord) => ({
      record,
    }),
    'Import Custom Guide Success': (record: CustomGuideRecord) => ({ record }),
    'Delete Custom Guide': (id: string) => ({ id }),
    'Delete Custom Guide Success': (id: string, name: string) => ({ id, name }),
    'Update Custom Guide': (id: string) => ({ id }),
    'Update Custom Guide Success': (customGuideRecord: CustomGuideRecord) => ({
      customGuideRecord,
    }),
    'Rename Custom Guide': (id: string, name: string) => ({ id, name }),
    'Update Custom Guide Group By': (id: string, groupBy: GroupByType) => ({
      id,
      groupBy,
    }),
    'Update Custom Guide Sort By': (id: string, sortBy: SortByType) => ({
      id,
      sortBy,
    }),
    'Update Custom Guide Search Text': (id: string, searchText: string) => ({
      id,
      searchText,
    }),
    'Create Custom Guide Category': (id: string, categoryName: string) => ({
      id,
      categoryName,
    }),
    'Rename Custom Guide Category': (
      id: string,
      categoryIndex: number,
      categoryName: string,
    ) => ({ id, categoryIndex, categoryName }),
    'Remove Custom Guide Category': (id: string, categoryIndex: number) => ({
      id,
      categoryIndex,
    }),
    'Move Custom Guide Category': (
      id: string,
      originalCategoryIndex: number,
      newCategoryIndex: number,
    ) => ({ id, originalCategoryIndex, newCategoryIndex }),
    'Add Custom Guide Materials': (id: string, materialNumbers: string[]) => ({
      id,
      materialNumbers,
    }),
    'Add Custom Guide Materials Success': (
      id: string,
      materialNumbers: string[],
    ) => ({ id, materialNumbers }),
    'Remove Custom Guide Materials': (
      id: string,
      materialNumbers: string[],
    ) => ({ id, materialNumbers }),
    'Move Custom Guide Materials': (
      id: string,
      materialNumber: string,
      newCategoryIndex: number,
      newMaterialIndex?: number,
    ) => ({ id, materialNumber, newCategoryIndex, newMaterialIndex }),
    'Update Par Quantity': (
      id: string,
      materialNumber: string,
      uom: string,
      quantity: number,
    ) => ({ id, materialNumber, uom, quantity }),
    'Update Inventory Quantity': (
      id: string,
      materialNumber: string,
      uom: string,
      quantity: number,
    ) => ({ id, materialNumber, uom, quantity }),
    'Clear Inventory Quantities': (id: string) => ({ id }),
    'Toggle Par Ordering': (id: string, parOrderingEnabled: boolean) => ({
      id,
      parOrderingEnabled,
    }),
    'Get Categorized Custom Guide': (id: string, groupBy: GroupByType) => ({
      id,
      groupBy,
    }),
    'Get Categorized Custom Guide Success': (
      id: string,
      groupBy: GroupByType,
      categorizedMaterials: CategorizedMaterialNumbers[],
    ) => ({ id, groupBy, categorizedMaterials }),
    'Get Categorized Custom Guide Failure': (
      id: string,
      groupBy: GroupByType,
    ) => ({ id, groupBy }),
    'Clear Categorized Custom Guide': (id: string) => ({ id }),
  },
});
