import { createActionGroup, emptyProps } from '@ngrx/store';

export const MaterialComparisonActions = createActionGroup({
  source: 'Material Comparison',
  events: {
    'Toggle Material Comparison': (isEnabled: boolean) => ({ isEnabled }),
    'Toggle Collapse Material Comparison': (isCollapsed: boolean) => ({
      isCollapsed,
    }),
    'Add Material Comparison': (materialNumber: string) => ({ materialNumber }),
    'Remove Material Comparison': (materialNumber: string) => ({
      materialNumber,
    }),
    'Remove All Material Comparisons': emptyProps(),
    'Force Hide Material Comparison Docket': (shouldHideDocket: boolean) => ({
      shouldHideDocket,
    }),
    'Hydrate Material Comparison': emptyProps(),
    'Hydrate Material Comparison Success': (
      isComparisonEnabled: boolean,
      materialNumbers: string[],
    ) => ({ isComparisonEnabled, materialNumbers }),
    'Load From Material List': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
  },
});
