<mat-dialog-content>
  <ng-container *ngIf="title; else closeButton">
    <div class="title-container">
      <span class="title">{{ title | translate }}</span>
      <ng-container [ngTemplateOutlet]="closeButton"></ng-container>
    </div>
  </ng-container>

  <ng-template #closeButton>
    <button
      *ngIf="closeAction"
      class="close-button"
      (click)="closeAction()"
      attr.aria-label="{{ 'CLOSE' | translate }}"
      mat-button
    >
      <mat-icon class="close-button-img" svgIcon="clear_icon"></mat-icon>
    </button>
  </ng-template>

  <ng-container *ngIf="image">
    <img
      class="modal-image"
      *ngIf="!hasVideoData"
      [src]="image.imageLocation"
      alt="{{ image.altText | translate }}"
    />
    <video
      [autoplay]="true"
      [loop]="true"
      [muted]="true"
      playsinline="playsinline"
      [controls]="false"
      disablePictureInPicture="true"
      class="modal-image"
      *ngIf="hasVideoData"
      src="{{ image.imageLocation }}"
    ></video>
  </ng-container>

  <div
    class="message"
    *ngIf="messageData?.messageKey"
    [innerHTML]="messageData.messageKey | translate: messageData.parameters"
  ></div>
  <div
    class="helper-message"
    *ngIf="helperMessageData?.messageKey"
    [innerHTML]="
      helperMessageData.messageKey | translate: helperMessageData.parameters
    "
  ></div>

  <div
    *ngIf="primaryButtonText || secondaryButtonText"
    [ngClass]="
      primaryButtonText && secondaryButtonText
        ? 'two-button-container'
        : 'single-button-container'
    "
  >
    <button
      class="large-button multilingual-button"
      *ngIf="secondaryButtonText && secondaryButtonAction"
      (click)="secondaryButtonAction(); dialogRef.close()"
      [ngClass]="
        isDestructive ? 'secondary-destructive-button' : 'secondary-button'
      "
    >
      {{ secondaryButtonText | translate }}
    </button>

    <button
      class="large-button multilingual-button"
      *ngIf="primaryButtonText && primaryButtonAction"
      (click)="primaryButtonAction(); dialogRef.close()"
      [ngClass]="
        isDestructive ? 'primary-destructive-button' : 'primary-button'
      "
    >
      {{ primaryButtonText | translate }}
    </button>
  </div>
</mat-dialog-content>
