import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { RowSanitizer } from './row-sanitizer';

@Injectable({ providedIn: 'root' })
export class ParseSpreadsheetService {
  reader = new FileReader();

  parseFile(file: Blob, rowSanitizer?: RowSanitizer): Observable<string[][]> {
    return new Observable<string[][]>((observer: Observer<string[][]>) => {
      this.reader.onload = async (event: Event) => {
        try {
          const xlsx = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
          const dataBuffer = (event.target as FileReader).result as ArrayBuffer;
          const data = new Uint8Array(dataBuffer);

          const workbook = xlsx.read(data, { type: 'array' });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const rows: string[][] = xlsx.utils.sheet_to_json(sheet, {
            header: 1,
            defval: null,
            raw: false,
          });

          const trimmedRows = rows.map((row) =>
            row.map((value) => value?.trim() || null),
          );

          observer.next(
            rowSanitizer
              ? trimmedRows.map((row) => rowSanitizer.sanitize(row))
              : trimmedRows,
          );
          observer.complete();
        } catch (err) {
          observer.error(err);
        }
      };
      if (file.type === 'text/csv') {
        this.reader.readAsArrayBuffer(new Blob(['\ufeff', file]));
      } else {
        this.reader.readAsArrayBuffer(file);
      }
    });
  }
}
