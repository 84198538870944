import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { filter, takeUntil } from 'rxjs/operators';
import { OrdersTab } from '../../orders/orders/orders.component';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { combineLatest, Subject } from 'rxjs';

interface SubHeaderTab {
  link: string;
  isActive: boolean;
  text: string;
}

export interface OrderLink {
  label: string;
  link: string;
  queryParams?: {
    [param: string]: string;
  };
}

@Component({
  selector: 'naoo-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  readonly goPointsLabel = 'goPointsLabel';
  private readonly storePurchaseHistoryLabel =
    'ORDERS.STORE_PURCHASE.TAB_TITLE';
  private readonly specialOrderLabel = 'ORDERS.SPECIAL.TAB_TITLE';
  private readonly invoiceLabel = 'ORDERS.INVOICES_CREDITS.TAB_TITLE';

  @Input() isDisabled: boolean;
  isCartLoaded = false;

  public readonly guidesLink: SubHeaderTab = {
    link: '/guides',
    isActive: false,
    text: 'MENU.GUIDES',
  };

  public readonly categoriesLink: SubHeaderTab = {
    link: '/categories',
    isActive: false,
    text: 'MENU.CATEGORIES',
  };

  defaultMyOrderLinks: OrderLink[] = [
    {
      label: 'ORDERS.HISTORY.TITLE',
      link: '/orders',
      queryParams: { activeTab: OrdersTab.OrderHistory },
    },
    {
      label: this.specialOrderLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.SpecialOrders },
    },
    {
      label: this.invoiceLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.InvoicesCredits },
    },
    {
      label: this.storePurchaseHistoryLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.StorePurchaseHistory },
    },
    {
      label: this.goPointsLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.GoPoints },
    },
  ];

  myOrderLinks: OrderLink[] = [];
  hideOrderTools: boolean;
  isMenuOpen = false;
  isOrdersTab = false;
  currentUrl: string;
  destroyed$ = new Subject();

  constructor(
    public router: Router,
    private sessionFacade: SessionFacade,
    private cartFacade: CartFacade,
  ) {}

  ngOnInit() {
    this.hideOrderTools =
      this.router.url === NaooConstants.CUSTOMER_UNIT_SELECTION_PATH ||
      this.router.url === NaooConstants.SCHEDULED_MAINTENANCE_PATH;

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationStart,
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: NavigationEnd | NavigationStart) => {
        if (event instanceof NavigationEnd) {
          this.setActiveTab(event);
        } else {
          this.currentUrl = event.url;
        }
      });

    const hasStorePurchaseHistoryAccess$ =
      this.sessionFacade.hasStorePurchaseHistoryPermissions();
    const isLoyaltyProgramEligible$ =
      this.sessionFacade.isLoyaltyProgramEligible();
    const isSpecialOrderDashboardAccess$ =
      this.sessionFacade.isSpecialOrderDashboardAccess();

    combineLatest([
      hasStorePurchaseHistoryAccess$,
      isLoyaltyProgramEligible$,
      isSpecialOrderDashboardAccess$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([
          hasStorePurchaseHistoryAccess,
          isLoyaltyProgramEligible,
          isSpecialOrderDashboardAccess,
        ]) => {
          const linksToHide: string[] = [];
          if (!isSpecialOrderDashboardAccess) {
            linksToHide.push(this.specialOrderLabel);
          }
          if (!isLoyaltyProgramEligible) {
            linksToHide.push(this.goPointsLabel);
          }
          if (hasStorePurchaseHistoryAccess) {
            linksToHide.push(this.invoiceLabel);
          } else {
            linksToHide.push(this.storePurchaseHistoryLabel);
          }
          this.myOrderLinks = this.defaultMyOrderLinks.filter(
            (link) => !linksToHide.includes(link.label),
          );
        },
      );

    this.cartFacade
      .isCartLoaded()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isCartLoaded) => (this.isCartLoaded = isCartLoaded));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setActiveTab(event: NavigationEnd): void {
    this.hideOrderTools =
      event.urlAfterRedirects === NaooConstants.CUSTOMER_UNIT_SELECTION_PATH ||
      this.router.url === NaooConstants.SCHEDULED_MAINTENANCE_PATH;

    this.isOrdersTab = event.url.includes('/orders');
    this.categoriesLink.isActive =
      event.urlAfterRedirects.includes('/categories') ||
      event.url.includes('/search');
    this.guidesLink.isActive = event.urlAfterRedirects.includes('/guides');
  }

  trackLink(index: number, link: OrderLink): string {
    return link.label;
  }
}
