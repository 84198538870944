<div class="deleted-info">
  <span class="material-description" *ngIf="isOK || isUnorderable">
    {{ materialInfo.description | stringdefaulter }}
  </span>

  <span class="material-description" *ngIf="isUnavailable">
    {{ 'PRODUCT.UNAVAILABLE_ITEM' | translate }}
  </span>

  <span class="message">
    {{ 'CART.REMOVED_MATERIAL_MESSAGE' | translate }}
  </span>
</div>

<button
  class="restore-button"
  *ngIf="isOK && !isErrorProcessing"
  [attr.aria-label]="'CART.RESTORE_WCAG' | translate"
  (click)="restoreMaterial()"
>
  <mat-icon svgIcon="refresh_icon"></mat-icon>
  {{ 'CART.RESTORE' | translate }}
</button>
