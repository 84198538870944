import { createActionGroup, emptyProps } from '@ngrx/store';
import { SearchResultRecord } from '../../services/search/models/search-record';

export const SearchActions = createActionGroup({
  source: 'Search',
  events: {
    'New Search': (searchQuery: string) => ({ searchQuery }),
    'Get Search Results': emptyProps(),
    'Get Search Results Success': (
      isSearch: boolean,
      searchResults: SearchResultRecord,
      searchStateStartIndex: number,
    ) => ({ isSearch, searchResults, searchStateStartIndex }),
    'Get Search Results Error': emptyProps(),
    'Clear Search Results': (isNewSearch: boolean) => ({ isNewSearch }),
    'Clear All Filters': emptyProps(),
    'Load More Search Results': emptyProps(),
    'Collapse Default Filter': (
      filterGroupNameEN: string,
      isCollapsed: boolean,
    ) => ({ filterGroupNameEN, isCollapsed }),
    'View More Default Filter': (
      filterGroupNameEN: string,
      isViewingMore: boolean,
    ) => ({ filterGroupNameEN, isViewingMore }),
    'View More Taxonomy Filter': (isViewingMore: boolean) => ({
      isViewingMore,
    }),
    'Toggle Order Guide Filter': (isToggled: boolean) => ({
      isToggled,
    }),
    'Toggle Available Today Filter': (isToggled: boolean) => ({
      isToggled,
    }),
    'Toggle Default Filter': (
      filterGroupNameEN: string,
      filterParam: string,
      filterNameEN: string,
      queryParamValue: string,
      isToggled: boolean,
    ) => ({
      filterGroupNameEN,
      filterParam,
      filterNameEN,
      queryParamValue,
      isToggled,
    }),
    'Toggle Taxonomy Filter': (
      categoryKey: string,
      taxonomyNameEN: string,
    ) => ({
      categoryKey,
      taxonomyNameEN,
    }),
    'New Catalog Search': (categoryKey: string) => ({ categoryKey }),
    'Submit Search': (searchQuery: string) => ({ searchQuery }),
    'Search Material Click': (materialNumber: string) => ({ materialNumber }),
    'Clear Search State': emptyProps(),
    'Refresh Current Search': emptyProps(),
  },
});
