<google-map width="100%" height="100%">
  <map-marker
    *ngFor="
      let markerOption of storeMapMarkers?.storeMapMarkersMap | keyvalue;
      trackBy: trackByStorePlantId
    "
    [position]="markerOption.value.position"
    [icon]="getIcon(markerOption.value)"
    (mapClick)="selectPin(markerOption.value)"
  >
  </map-marker>
</google-map>
