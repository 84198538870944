import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  MaterialLine,
  MaterialRow,
  MaterialRowContext,
  MaterialRowStatus,
} from 'src/app/core/store/material-row/models/material-row';
import { trigger, transition, style, animate } from '@angular/animations';
import { naooAnimations } from 'src/app/shared/animations/animations';
import { MaterialWarningType } from '../../../core/store/material-warning/material-warning';

@Component({
  selector: 'naoo-material-row-slim-grid-content',
  templateUrl: './material-row-slim-grid-content.component.html',
  styleUrls: ['./material-row-slim-grid-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('fadeInOnly', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
    ]),
    naooAnimations.fadeAndSlide,
  ],
})
export class MaterialRowSlimGridContentComponent {
  @Input() materialRow: MaterialRow;
  @Input() isLast: boolean;
  @Input() isMobile: boolean;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();

  get isLoading(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Loading
    );
  }

  get isOk(): boolean {
    return !this.isLoading && !!this.materialRow;
  }

  get fullSize(): boolean {
    return (
      MaterialRowContext.OrderDetailsEditModal ===
      this.materialRow.materialRowOptions?.context
    );
  }

  get shouldShowRedBorder(): boolean {
    return this.isOverAllocatedAndNonModalContext();
  }

  private isOverAllocatedAndNonModalContext(): boolean {
    return (
      MaterialWarningType.OverAllocation ===
        this.materialRow?.materialOrdering?.materialWarning?.type &&
      MaterialRowContext.OverallocationModal !==
        this.materialRow.materialRowOptions?.context
    );
  }
}
