import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CustomerPreferencesRecord,
  ExportDetailsRecord,
  UpdateCustomerPreference,
} from './models/customer-preferences-record';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';

@Injectable({ providedIn: 'root' })
export class CustomerPreferencesService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getPreferences(): Observable<CustomerPreferencesRecord> {
    return this.httpClient.get<CustomerPreferencesRecord>(
      this.webBffService.getBff() + `/api/v3/customer/preferences`,
    );
  }

  updateOptOutEmailList(
    emailAddress: string,
  ): Observable<CustomerPreferencesRecord> {
    return this.httpClient.put<CustomerPreferencesRecord>(
      this.webBffService.getBff() +
        `/api/v3/customer/preferences/order-confirmation-opt-out`,
      { emailAddress: emailAddress },
    );
  }

  updateExportDetailPreferences(
    exportDetail: ExportDetailsRecord,
  ): Observable<CustomerPreferencesRecord> {
    return this.httpClient.put<CustomerPreferencesRecord>(
      this.webBffService.getBff() + `/api/v2/customer/preferences/export`,
      { exportDetail: exportDetail },
    );
  }

  updateCustomerPreferences(
    updatedCustomerPreferences: UpdateCustomerPreference,
  ): Observable<CustomerPreferencesRecord> {
    return this.httpClient.put<CustomerPreferencesRecord>(
      this.webBffService.getBff() + `/api/v1/customer/preferences`,
      updatedCustomerPreferences,
    );
  }
}
