<naoo-modal-header
  [title]="'IMPORT_GUIDE.TITLE' | translate"
  [showCloseButton]="showCloseButton"
  (closeModalEmitter)="close(true)"
></naoo-modal-header>
<section class="modal-body" [ngSwitch]="importGuideCurrentStep">
  <mat-progress-bar
    *ngSwitchCase="importGuideStep.Loading"
    [mode]="'indeterminate'"
  ></mat-progress-bar>
  <naoo-import-guide-create-custom-guide
    *ngSwitchCase="importGuideStep.CreateGuide"
    (cancel)="close()"
    (continue)="createGuideStepNext($event)"
    [customGuideName]="customGuideName"
  >
  </naoo-import-guide-create-custom-guide>
  <naoo-import-file
    *ngSwitchCase="importGuideStep.ImportFile"
    (fileSelected)="importFileStepNext($event)"
    [imagePath]="'IMPORT_GUIDE.IMPORT_FILE.GRID_IMAGE_URL'"
    [fileRequirements]="fileRequirements"
    [csvTemplate]="'IMPORT_GUIDE.CSV_TEMPLATE_URL'"
    [excelTemplate]="'IMPORT_GUIDE.EXCEL_TEMPLATE_URL'"
    [title]="'IMPORT_GUIDE.IMPORT_FILE.TITLE'"
    (templateDownload)="trackTemplateDownload($event)"
  ></naoo-import-file>
  <naoo-row-error
    *ngSwitchCase="importGuideStep.RowError"
    [totalImportRowsCount]="totalRowCount"
    [validImportRowsCount]="validImportGuideRows.length"
    (startOver)="startOver()"
    (importContinue)="rowErrorStepNext()"
  >
    <naoo-import-guide-error-table
      [importGuideRows]="invalidImportGuideRows"
    ></naoo-import-guide-error-table>
  </naoo-row-error>
  <naoo-file-error
    *ngSwitchCase="importGuideStep.FileError"
    [fileError]="fileError"
    [fileName]="fileName"
    [csvTemplate]="'IMPORT_GUIDE.CSV_TEMPLATE_URL'"
    [excelTemplate]="'IMPORT_GUIDE.EXCEL_TEMPLATE_URL'"
    [fileRequirements]="fileRequirements"
    [errorImage]="'IMPORT_GUIDE.FILE_ERROR.ERROR_IMAGE_URL'"
    (startOver)="startOver()"
    (templateDownload)="trackTemplateDownload($event)"
  ></naoo-file-error>
  <naoo-complete-modal
    *ngSwitchCase="importGuideStep.Complete"
    [title]="
      'IMPORT_GUIDE.COMPLETE.TITLE'
        | translate: { customGuideName: customGuideName }
    "
    [successIconPath]="completeImportImagePath"
    [subtitle]="
      completeImportSubtitle
        | translate
          : { valid: validImportGuideRows.length, category: categoriesCount }
    "
    [completeButtonMessage]="'IMPORT_GUIDE.COMPLETE.GO_TO_GUIDE' | translate"
    (navigateToResults)="goToGuide()"
  >
  </naoo-complete-modal>
</section>
