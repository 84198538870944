import { inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivateFn,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { NaooConstants } from '../NaooConstants';

export const redirectGuard: CanDeactivateFn<any> = (
  _component: any,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  _nextState?: RouterStateSnapshot,
): boolean => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const url = authService.getStashUrl();
  authService.clearStashUrl();

  if (!url || NaooConstants.ORDER_CONFIRMATION_URL === url) {
    return true;
  }

  router.navigateByUrl(url);
  return false;
};
