export enum MenuAction {
  AddToCriticalItemsGuide = 0,
  AddToExistingCustomGuide = 1,
  AddToNewCustomGuide = 2,
  EmptyCart = 3,
  RemoveItemFromCart = 4,
  RemoveItemFromCustomGuide = 5,
  RemoveItemFromCriticalItemGuide = 6,
  AddToOrderGuide = 7,
  RemoveFromOrderGuide = 8,
}

export interface MenuActionInfo {
  id?: string;
  name: string;
  action: MenuAction;
  disabled?: boolean;
}
