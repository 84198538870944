import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { cartReviewReducer } from './cart-review.reducer';
import { CartReviewEffects } from './cart-review.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('cartReview', cartReviewReducer),
    EffectsModule.forFeature([CartReviewEffects]),
  ],
})
export class CartReviewStoreModule {}
