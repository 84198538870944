import { Injectable } from '@angular/core';
import { EntitlementService } from '../../services/entitlement/entitlement.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { EntitlementActions } from './entitlement.actions';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ErrorActions } from '../error/error.actions';
import {
  CurrentSystem,
  CustomerPermission,
} from '../../services/session/models/session-record';
import { selectRouteDate } from '../cart/cart.selectors';
import { Action, Store } from '@ngrx/store';
import {
  selectCurrentSystem,
  selectHasPermissionEnabled,
} from '../session/session.selectors';
import {
  addDaysToDate,
  formatDate,
  parseDate,
} from '../../../shared/utilities/date-utilities';
import { YearEntitlement } from '../../services/entitlement/models/entitlement';
import { MaterialAdditionalInfoActions } from '../material-additional-info/material-additional-info.actions';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { SharedActions } from '../shared/shared.actions';

const mygfsDaysToQuery = 90;
const defaultDaysToQuery = 30;

@Injectable()
export class EntitlementEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private entitlementService: EntitlementService,
    private customDialogService: CustomDialogService,
  ) {}

  getEntitlement$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EntitlementActions.getEntitlement),
      concatLatestFrom(() => [
        this.store.select(selectCurrentSystem),
        this.store.select(selectRouteDate),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.CommodityAccess),
        ),
      ]),
      switchMap(([_, currentSystem, routeDate, hasCommodityAccess]) => {
        if (!hasCommodityAccess) {
          return of(EntitlementActions.abortEntitlement());
        }
        const daysToQuery =
          currentSystem === CurrentSystem.Mygfs
            ? mygfsDaysToQuery
            : defaultDaysToQuery;
        const deliveryDate = routeDate ? routeDate : tomorrow();

        return this.entitlementService
          .getEntitlements(daysToQuery, formatDate(deliveryDate))
          .pipe(
            map((response) => {
              if (
                isRouteDateWithinYearEntitlement(
                  deliveryDate,
                  response.currentSchoolYear,
                )
              ) {
                return EntitlementActions.getEntitlementSuccess(
                  response.currentSchoolYear,
                );
              } else if (
                isRouteDateWithinYearEntitlement(
                  deliveryDate,
                  response.nextSchoolYear,
                )
              ) {
                return EntitlementActions.getEntitlementSuccess(
                  response.nextSchoolYear,
                );
              } else {
                // Invalid school years in the response.
                return EntitlementActions.abortEntitlement();
              }
            }),
            catchError((err) =>
              of(
                ErrorActions.silentError(err),
                EntitlementActions.abortEntitlement(),
              ),
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  EntitlementActions.refreshEntitlement,
                  EntitlementActions.clearSelectedEntitlementMaterial,
                ),
              ),
            ),
          );
      }),
    );
  });

  setSelectedEntitlement$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EntitlementActions.setSelectedEntitlementMaterial),
      map((action) => {
        if (action.materialNumber) {
          this.customDialogService.closeMaterialOverallocationModal();
          return MaterialAdditionalInfoActions.loadMaterialAdditionalInfo([
            action.materialNumber,
          ]);
        }
        return SharedActions.noOperation(
          'No need to load additional material info',
        );
      }),
    );
  });

  refreshEntitlement$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EntitlementActions.refreshEntitlement),
      map((_) => EntitlementActions.getEntitlement()),
    );
  });
}

function isRouteDateWithinYearEntitlement(
  routeDate: Date,
  yearEntitlement: YearEntitlement | undefined,
): boolean {
  return (
    !!yearEntitlement &&
    routeDate >= parseDate(yearEntitlement.beginEffectiveDate) &&
    routeDate <= parseDate(yearEntitlement.endEffectiveDate)
  );
}

function tomorrow(): Date {
  return addDaysToDate(new Date(Date.now()), 1);
}
