import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { deliveryScheduleReducer } from './delivery-schedule.reducer';
import { DeliveryScheduleEffects } from './delivery-schedule.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('deliverySchedules', deliveryScheduleReducer),
    EffectsModule.forFeature([DeliveryScheduleEffects]),
  ],
})
export class DeliveryScheduleStoreModule {}
