import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { StoreService } from '../../services/store/store.service';
import {
  selectPickupStorePlantIds,
  selectStoreStateStatus,
} from './store.selectors';
import { StoreStateStatus } from './store.state';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { SharedActions } from '../shared/shared.actions';
import { StoreActions } from './store.actions';
import { PickupSchedulesActions } from '../pickup-schedules/pickup-schedules.actions';

@Injectable()
export class StoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private storeService: StoreService,
  ) {}

  loadStores$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreActions.loadStores),
      concatLatestFrom(() => this.store.select(selectStoreStateStatus)),
      mergeMap(([_, status]) => {
        return StoreStateStatus.Queued === status
          ? of(StoreActions.getStores())
          : of(SharedActions.noOperation('Already loading store data'));
      }),
    );
  });

  getStores$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreActions.getStores),
      concatLatestFrom(() => [
        this.store.select(selectStoreStateStatus),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.InStorePickup),
        ),
      ]),
      mergeMap(([_, status, hasInstorePickup]) => {
        if (!hasInstorePickup) {
          return of(StoreActions.getStoresFailure());
        }
        if (StoreStateStatus.Requested !== status) {
          return of(SharedActions.noOperation('Already requested store data'));
        }

        return this.storeService.getStores().pipe(
          map((stores) => StoreActions.getStoresSuccess(stores)),
          catchError(() => of(StoreActions.getStoresFailure())),
          takeUntil(
            this.actions$.pipe(
              ofType(StoreActions.getStores, StoreActions.refreshStores),
            ),
          ),
        );
      }),
    );
  });

  getStoresSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreActions.getStoresSuccess),
      concatLatestFrom(() => this.store.select(selectPickupStorePlantIds)),
      mergeMap(([_, pickupStorePlantIds]) => {
        if (!pickupStorePlantIds.length) {
          return of(SharedActions.noOperation('No pickup store plant ids'));
        }
        return of(
          PickupSchedulesActions.loadPickupSchedules(pickupStorePlantIds),
        );
      }),
    );
  });

  refreshStores$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreActions.refreshStores),
      map(() => StoreActions.loadStores()),
    );
  });
}
