import {
  CustomGuideCategoryRecord,
  CustomGuideMaterialRecord,
  CustomGuideParLineRecord,
  CustomGuideRecord,
} from './custom-guide-record';
import { GroupByType, SortByType } from 'src/app/guides/shared/guides';

export interface CustomGuideRequest {
  name: string;
  sortBy: SortByType;
  groupBy: GroupByType;
  parOrderingEnabled: boolean;
  categories: CustomGuideCategoryRequest[];
}

export interface CustomGuideCategoryRequest {
  name: string;
  materials: CustomGuideMaterialRequest[];
}

export interface CustomGuideMaterialRequest {
  materialNumber: string;
  parLines: CustomGuideParLineRequest[];
}

export interface CustomGuideParLineRequest {
  uom: string;
  parQuantity: number;
  inventoryQuantity?: number;
}

export interface CopyCustomGuideRequest {
  naooCustomerIds: string[];
}

export function convertToCustomGuideRequest(
  record: CustomGuideRecord,
): CustomGuideRequest {
  return {
    name: record.name,
    sortBy: record.sortBy,
    groupBy: record.groupBy,
    parOrderingEnabled: record.parOrderingEnabled,
    categories: convertToCustomGuideCategoryRequests(record.categories),
  };
}

export function convertToCustomGuideCategoryRequests(
  records: CustomGuideCategoryRecord[],
): CustomGuideCategoryRequest[] {
  const categoryMap = new Map<string, CustomGuideCategoryRecord>();
  records.forEach((categoryRecord) => {
    const trimmedName = categoryRecord.name.en.trim();
    if (categoryMap.has(trimmedName)) {
      const existingRecord = categoryMap.get(trimmedName);
      categoryMap.set(trimmedName, {
        name: existingRecord.name,
        materials: [...existingRecord.materials, ...categoryRecord.materials],
      });
    } else {
      categoryMap.set(trimmedName, categoryRecord);
    }
  });
  return Array.from(categoryMap.values()).map((categoryRecord) =>
    convertToCustomGuideCategoryRequest(categoryRecord),
  );
}

export function convertToCustomGuideCategoryRequest(
  record: CustomGuideCategoryRecord,
): CustomGuideCategoryRequest {
  return {
    // Language does not matter, custom category record names populate en and fr
    name: record.name.en,
    materials: convertToCustomGuideMaterialRequests(record.materials),
  };
}

export function convertToCustomGuideMaterialRequests(
  records: CustomGuideMaterialRecord[],
): CustomGuideMaterialRequest[] {
  return records.map((record) => convertToCustomGuideMaterialRequest(record));
}

export function convertToCustomGuideMaterialRequest(
  record: CustomGuideMaterialRecord,
): CustomGuideMaterialRequest {
  return {
    materialNumber: record.materialNumber,
    parLines: convertToCustomGuideParLineRequests(record.parLines),
  };
}

export function createEmptyCustomGuideMaterialRequests(
  materialNumbers: string[],
): CustomGuideMaterialRequest[] {
  return materialNumbers.map((materialNumber) => {
    return {
      materialNumber: materialNumber,
      parLines: [],
    };
  });
}

export function convertToCustomGuideParLineRequests(
  records: CustomGuideParLineRecord[],
): CustomGuideParLineRequest[] {
  return records.map((record) => convertToCustomGuideParLineRequest(record));
}

export function convertToCustomGuideParLineRequest(
  record: CustomGuideParLineRecord,
): CustomGuideParLineRequest {
  return {
    uom: record.uom,
    parQuantity: record.parQuantity,
    inventoryQuantity: record.inventoryQuantity,
  };
}
