import {
  MaterialLastOrdered,
  MaterialLine,
  MaterialRowContext,
  MaterialRowFooter,
} from '../models/material-row';
import { MaterialRecord } from '../../../services/material-info/models/materials-info-record';
import {
  MaterialAvailabilityRecord,
  UnitAvailabilityRecord,
} from '../../../services/material-availability/model/material-availabilities-record';
import { LastOrderedRecord } from '../../../services/last-ordered/models/last-ordered-records';
import { MaterialRowFooterTransformInput } from './material-row.transformer.model';
import { Injectable } from '@angular/core';

@Injectable()
export class MaterialRowFooterTransformer {
  static transform({
    availabilityRecord,
    compareCheckbox,
    context,
    infoRecord,
    lastOrderedRecord,
  }: MaterialRowFooterTransformInput): MaterialRowFooter | undefined {
    if (MaterialRowContext.OrderDetailsEditModal === context) {
      return undefined;
    }
    if (compareCheckbox) {
      compareCheckbox.isHidden = MaterialRowContext.Comparison === context;
    }
    return {
      compareCheckbox,
      lastOrdered: MaterialRowFooterTransformer.getMaterialRowFooterLastOrdered(
        availabilityRecord,
        infoRecord,
        lastOrderedRecord,
      ),
    };
  }

  private static getMaterialRowFooterLastOrdered(
    availabilityRecord?: MaterialAvailabilityRecord,
    infoRecord?: MaterialRecord,
    lastOrderedRecord?: LastOrderedRecord,
  ): MaterialLastOrdered {
    const date = lastOrderedRecord?.lastOrderDate;
    if (!availabilityRecord || !date) {
      return undefined;
    }
    return {
      date,
      line: MaterialRowFooterTransformer.getMaterialLastOrderedLine(
        availabilityRecord.units,
        lastOrderedRecord,
        infoRecord,
      ),
    };
  }

  private static getMaterialLastOrderedLine(
    availabilityRecordUnits: UnitAvailabilityRecord[],
    lastOrderedRecord: LastOrderedRecord,
    infoRecord?: MaterialRecord,
  ): MaterialLine {
    for (const availabilityUnit of availabilityRecordUnits) {
      const lineRecord = lastOrderedRecord.lines.find(
        (record) => record.uom === availabilityUnit.uom,
      );
      if (!lineRecord) {
        continue;
      }
      const { displayCode, quantity, uom } = lineRecord;

      if (quantity > 0) {
        const displayCodeValue =
          displayCode ??
          infoRecord?.units?.find(
            (infoUnit) => infoUnit.uom === availabilityUnit.uom,
          )?.displayCode;

        return { displayCode: displayCodeValue, quantity, uom };
      }
    }
    return undefined;
  }
}
