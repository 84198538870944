import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LastOrderedRecord } from '../../services/last-ordered/models/last-ordered-records';

export enum LastOrderedRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export interface LastOrderedRecordState {
  materialNumber: string;
  status: LastOrderedRecordStatus;
  record?: LastOrderedRecord;
}

export interface LastOrderedState {
  lastOrderedRecords: EntityState<LastOrderedRecordState>;
}

export const lastOrderedAdapter = createEntityAdapter<LastOrderedRecordState>({
  selectId: (state) => state.materialNumber,
});

export const initialLastOrderedState: LastOrderedState = {
  lastOrderedRecords: lastOrderedAdapter.getInitialState(),
};
