<div class="change-row desktop" [ngClass]="{ 'grey-row': isOdd }">
  <div class="cell description" [ngClass]="descriptionClass">
    <a
      [routerLink]="[productDetailsPath, changedItem.materialNumber]"
      (click)="closeModal()"
      *ngIf="isUserOnline && isProductAvailable; else noLinkDescription"
      attr.aria-label="{{ ariaRowDescription }}"
    >
      {{ changedItem.description | stringdefaulter }}
    </a>

    <ng-template #noLinkDescription>
      <span *ngIf="isProductAvailable; else missingProductDescription">
        {{ changedItem.description | stringdefaulter }}
      </span>
      <ng-template #missingProductDescription>
        <span>
          {{
            'ORDER_GUIDE.ORDER_GUIDE_CHANGES.PRODUCT_UNAVAILABLE' | translate
          }}
        </span>
      </ng-template>
    </ng-template>
  </div>

  <div class="cell item-code">
    {{ changedItem.materialNumber }}
  </div>
  <div class="cell status">
    {{ statusTranslationKey | translate }}
  </div>
  <div class="cell date">
    {{ changedItem.lastUpdate | naooDate: 'shortDate' : '' }}
  </div>

  <div class="cell actions">
    <naoo-order-guide-changes-menu
      [changedItem]="changedItem"
      [materialGuideMatch]="materialGuideMatch"
      [isUserOnline]="isUserOnline"
      [isAvailableItem]="isProductAvailable"
    ></naoo-order-guide-changes-menu>
  </div>
</div>

<div class="mobile">
  <div
    class="change-row"
    [ngClass]="{ 'grey-row': isOdd, 'toggled-row': isOpen }"
    (click)="toggle()"
  >
    <div class="cell description" [ngClass]="descriptionClass">
      <a
        [routerLink]="[productDetailsPath, changedItem.materialNumber]"
        (click)="closeModal()"
        *ngIf="isUserOnline && isProductAvailable; else noLinkDescription"
        attr.aria-label="{{ ariaRowDescription }}"
      >
        {{ changedItem.description | stringdefaulter }}
      </a>

      <ng-template #noLinkDescription>
        <span *ngIf="isProductAvailable; else missingProductDescription">
          {{ changedItem.description | stringdefaulter }}
        </span>
        <ng-template #missingProductDescription>
          <span>
            {{
              'ORDER_GUIDE.ORDER_GUIDE_CHANGES.PRODUCT_UNAVAILABLE' | translate
            }}
          </span>
        </ng-template>
      </ng-template>
    </div>
    <button
      class="cell button"
      [attr.aria-label]="(isOpen ? 'EXPAND' : 'COLLAPSE') | translate"
    >
      <mat-icon
        svgIcon="arrow-gray"
        [ngClass]="{ 'toggle-action': isOpen }"
      ></mat-icon>
    </button>
  </div>
  <div
    *ngIf="isOpen"
    class="change-row row-details"
    [ngClass]="{ 'grey-row': isOdd }"
  >
    <div class="mobile-item-code">
      <span class="header"
        >{{
          'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ITEM_CODE_MODAL' | translate
        }}:</span
      >
      {{ changedItem.materialNumber }}
    </div>
    <div class="mobile-status-message">
      <span class="header"
        >{{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.STATUS_MODAL' | translate }}:</span
      >
      <span>
        {{ statusTranslationKey | translate }}
      </span>
    </div>
    <div class="mobile-date">
      <span class="header"
        >{{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.DATE_MODAL' | translate }}:</span
      >
      <span class="date-value">
        {{ changedItem.lastUpdate | naooDate: 'shortDate' : '' }}</span
      >
    </div>

    <div class="mobile-actions">
      <naoo-order-guide-changes-menu
        [changedItem]="changedItem"
        [materialGuideMatch]="materialGuideMatch"
        [isUserOnline]="isUserOnline"
        [isAvailableItem]="isProductAvailable"
      ></naoo-order-guide-changes-menu>
    </div>
  </div>
</div>
