import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesCriticalItemsState } from './sales-critical-items.state';
import { SalesCriticalItem } from '../../services/sales-critical-items/model/sales-critical-items';
import { selectCartMaterialEntities } from '../cart/cart.selectors';
import { CartMaterialState } from '../cart/cart.state';
import { Dictionary } from '@ngrx/entity';
import { NaooConstants } from '../../../shared/NaooConstants';
import { doesQuantityExceedMaxHardStop } from './sales-critical-items.util';

const selectSalesCriticalItemsState =
  createFeatureSelector<SalesCriticalItemsState>('salesCriticalItems');

export const selectAllSalesCriticalItems = createSelector(
  selectSalesCriticalItemsState,
  (state) => state.salesCriticalItems,
);

export const selectSalesCriticalItem = (materialNumber: string) =>
  createSelector(
    selectAllSalesCriticalItems,
    (salesCriticalItems: SalesCriticalItem[]): SalesCriticalItem =>
      salesCriticalItems?.find(
        (item) => item.materialNumber === materialNumber,
      ),
  );

export const selectSalesCriticalItemsWithExceededHardStopQuantity =
  createSelector(
    selectAllSalesCriticalItems,
    selectCartMaterialEntities,
    (
      salesCriticalItems: SalesCriticalItem[],
      cartMaterials: Dictionary<CartMaterialState>,
    ): SalesCriticalItem[] => {
      const salesCriticalItemsInCart = salesCriticalItems?.filter(
        (item) => !!cartMaterials[item?.materialNumber],
      );

      return salesCriticalItemsInCart?.filter((salesCriticalItem) => {
        const quantity =
          cartMaterials[salesCriticalItem.materialNumber]?.lines.entities[
            NaooConstants.Uom.Case
          ]?.quantity ?? 0;
        return doesQuantityExceedMaxHardStop(salesCriticalItem, quantity);
      });
    },
  );
