import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  inventoryAvailabilityAdapter,
  InventoryAvailabilityState,
} from './inventory-availability.state';

const selectInventoryAvailabilityState =
  createFeatureSelector<InventoryAvailabilityState>('inventoryAvailability');

export const selectInventoryAvailabilityRecordStates = createSelector(
  selectInventoryAvailabilityState,
  inventoryAvailabilityAdapter.getSelectors().selectEntities,
);

export const selectInventoryAvailabilityRecordState = (itemId: string) => {
  return createSelector(
    selectInventoryAvailabilityRecordStates,
    (availabilities) => availabilities[itemId],
  );
};
