import { createActionGroup, emptyProps } from '@ngrx/store';
import { CartSortType } from './active-cart-summary.state';

export const ActiveCartSummaryActions = createActionGroup({
  source: 'Cart Summary',
  events: {
    'Disable Quick Add': emptyProps(),
    'Enable Quick Add': emptyProps(),
    'Update Quick Add Quantities': (
      materialNumber: string,
      caseQuantity: number,
    ) => ({ materialNumber, caseQuantity }),
    'Update Cart Sort': (sortBy: CartSortType) => ({ sortBy }),
    'Refresh Cart Sort': emptyProps(),
  },
});
