import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryScheduleWidgetComponent } from './delivery-schedule-widget/delivery-schedule-widget.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    MatIconModule,
  ],
  exports: [DeliveryScheduleWidgetComponent],
  declarations: [DeliveryScheduleWidgetComponent],
})
export class DeliveryScheduleWidgetModule {}
