import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { lastOrderedReducer } from './last-ordered.reducer';
import { LastOrderedEffects } from './last-ordered.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('lastOrdered', lastOrderedReducer),
    EffectsModule.forFeature([LastOrderedEffects]),
  ],
})
export class LastOrderedStoreModule {}
