import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialColumnContainerComponent } from './material-column/material-column-container/material-column-container.component';
import { CompareDocketContentComponent } from './compare-docket/compare-docket-content/compare-docket-content.component';
import { MatIconModule } from '@angular/material/icon';
import { ProductRowModule } from '../product-row/product-row.module';
import { MaterialComparisonComponent } from './material-comparison.component';
import { MatMenuModule } from '@angular/material/menu';
import { CompareDocketContainerComponent } from './compare-docket/compare-docket-container.component';
import { CompareDocketOverlayComponent } from './compare-docket-overlay/compare-docket-overlay.component';
import { MaterialListModule } from '../material-list/material-list.module';
import { ComparisonAttributesComponent } from './material-column/comparison-attributes/comparison-attributes.component';
import { ProductDetailsModule } from '../product-details/product-details.module';

const routes: Routes = [
  {
    path: '',
    component: MaterialComparisonComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    TranslateModule.forChild(),
    MatIconModule,
    ProductRowModule,
    MatMenuModule,
    MaterialListModule,
    ProductDetailsModule,
  ],
  declarations: [
    MaterialColumnContainerComponent,
    CompareDocketContentComponent,
    CompareDocketContainerComponent,
    CompareDocketOverlayComponent,
    MaterialComparisonComponent,
    ComparisonAttributesComponent,
  ],
  providers: [],
  exports: [
    CompareDocketContentComponent,
    CompareDocketContainerComponent,
    CompareDocketOverlayComponent,
  ],
})
export class MaterialComparisonModule {}
