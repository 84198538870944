import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { LastOrderedService } from '../../services/last-ordered/last-ordered.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LastOrderedActions } from './last-ordered.actions';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { selectAllLastOrderedRecords } from './last-ordered.selectors';
import { LastOrderedRecordStatus } from './last-ordered.state';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class LastOrderedEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private lastOrderedService: LastOrderedService,
  ) {}

  loadLastOrdered$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(LastOrderedActions.loadLastOrdered),
      concatLatestFrom(() => this.store.select(selectAllLastOrderedRecords)),
      mergeMap(([action, lastOrderedRecords]) => {
        const queuedMaterialNumbers =
          action.materialNumbers?.filter(
            (materialNumber) =>
              lastOrderedRecords[materialNumber].status ===
              LastOrderedRecordStatus.Queued,
          ) || [];
        return of(LastOrderedActions.getLastOrdered(queuedMaterialNumbers));
      }),
    );
  });

  getLastOrdered$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(LastOrderedActions.getLastOrdered),
      concatLatestFrom(() => this.store.select(selectAllLastOrderedRecords)),
      mergeMap(([action, lastOrderedRecords]) => {
        const requestedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            lastOrderedRecords[materialNumber].status ===
            LastOrderedRecordStatus.Requested,
        );
        if (requestedMaterialNumbers.length === 0) {
          return of(
            SharedActions.noOperation(
              'Last Order Dates for material numbers have been requested',
            ),
          );
        }

        return this.lastOrderedService
          .getLastOrdered(requestedMaterialNumbers)
          .pipe(
            switchMap((records) => {
              const successMaterialNumbers = records.lastOrdered.map(
                (lastOrderedRecord) => lastOrderedRecord.materialNumber,
              );
              const errorMaterialNumbers = requestedMaterialNumbers.filter(
                (requestedMaterialNumber) =>
                  !successMaterialNumbers.includes(requestedMaterialNumber),
              );

              const dispatchedActions: Action[] = [
                LastOrderedActions.getLastOrderedSuccess(records.lastOrdered),
              ];

              if (errorMaterialNumbers.length > 0) {
                dispatchedActions.push(
                  LastOrderedActions.getLastOrderedError(errorMaterialNumbers),
                );
              }

              return dispatchedActions;
            }),
            catchError(() =>
              of(
                LastOrderedActions.getLastOrderedError(
                  requestedMaterialNumbers,
                ),
              ),
            ),
            takeUntil(
              this.actions$.pipe(ofType(LastOrderedActions.clearLastOrdered)),
            ),
          );
      }),
    );
  });
}
