import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { StorePurchaseHistoryFacade } from '../../../../core/store/store-purchase-history/store-purchase-history.facade';
import { StorePurchaseKey } from '../../../../shared/models/store-purchase-key';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import {
  StorePurchase,
  StorePurchaseHistoryViewModel,
} from '../../../../core/store/store-purchase-history/models/store-purchase-history-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { DateRange } from '../../../../shared/date-range-selector/date-range';
import { NaooConstants } from '../../../../shared/NaooConstants';

@Component({
  selector: 'naoo-store-purchase-history-container',
  template: `
    <naoo-store-purchase-history-content
      [filteredViewModel]="filteredViewModel"
      [searchTerm]="searchTerm"
      [isTabletWidth]="
        (breakPointState$ | async)?.breakpoints[this.tabletBreakpoint]
      "
      (storePurchaseKeyEmitter)="goToPurchaseDetails($event)"
      (searchValueEmitter)="filterPurchases($event)"
      (dateRangeEmitter)="loadDateRange($event)"
    >
    </naoo-store-purchase-history-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorePurchaseHistoryContainerComponent
  implements OnDestroy, OnInit
{
  readonly tabletBreakpoint = '(max-width: 900px)';

  constructor(
    private storePurchaseHistoryFacade: StorePurchaseHistoryFacade,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  breakPointState$: Observable<BreakpointState> =
    this.breakpointObserver.observe([this.tabletBreakpoint]);
  filteredViewModel: StorePurchaseHistoryViewModel;
  searchTerm = '';

  private destroyed$ = new Subject();
  private viewModel: StorePurchaseHistoryViewModel;
  private filteredPurchases: StorePurchase[] = [];

  ngOnInit(): void {
    this.storePurchaseHistoryFacade
      .getStorePurchaseHistoryViewModel()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((viewModel) => {
        this.viewModel = viewModel;
        this.filteredPurchases = viewModel?.purchases;
        this.filterPurchases(this.searchTerm);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  filterPurchases(value: string): void {
    this.searchTerm = value;
    const filterByString = value ? value.trim().toLocaleLowerCase() : '';
    if (filterByString.length) {
      this.filteredPurchases = this.viewModel?.purchases.filter((order) =>
        order.searchableStrings?.some((field) =>
          field.includes(filterByString),
        ),
      );
    } else {
      this.filteredPurchases = this.viewModel?.purchases;
    }

    this.filteredViewModel = {
      ...this.viewModel,
      purchases: this.filteredPurchases,
    };
    this.changeDetectorRef.markForCheck();
  }

  goToPurchaseDetails(storePurchaseKey: StorePurchaseKey): void {
    const routeParams = [
      NaooConstants.STORE_PURCHASE_DETAILS_PATH_PREFIX,
      storePurchaseKey.storeId,
      storePurchaseKey.saleDate,
      storePurchaseKey.laneNumber,
      storePurchaseKey.saleNumber,
    ];

    this.router.navigate(routeParams, {
      relativeTo: this.activatedRoute,
    });
  }

  loadDateRange(dateRange: DateRange): void {
    this.storePurchaseHistoryFacade.loadStorePurchaseHistory(dateRange);
  }
}
