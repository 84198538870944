import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { offlineModeReducer } from './offline-mode.reducer';
import { OfflineModeEffects } from './offline-mode.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('offlineMode', offlineModeReducer),
    EffectsModule.forFeature([OfflineModeEffects]),
  ],
})
export class OfflineModeStoreModule {}
