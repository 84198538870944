import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SmartCartRecommendation,
  smartCartRecommendationAdapter,
  SmartCartRecommendationState,
} from './smart-cart-recommendations.state';
import {
  isMaterialOrderable,
  selectCartMaterialNumbers,
} from '../cart/cart.selectors';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';

const selectSmartCartRecommendationFeature =
  createFeatureSelector<SmartCartRecommendationState>(
    'smartCartRecommendations',
  );

const selectSmartCartRecommendationRecords = createSelector(
  selectSmartCartRecommendationFeature,
  (state): SmartCartRecommendation[] | undefined =>
    state.hasLoaded
      ? smartCartRecommendationAdapter
          .getSelectors()
          .selectAll(state.smartCartRecommendation)
      : undefined,
);

export const selectSmartCartRecommendationsHasLoaded = createSelector(
  selectSmartCartRecommendationFeature,
  (state): boolean => state.hasLoaded,
);

export const selectOrderableSmartCartRecommendationRecords = (limit?: number) =>
  createSelector(
    selectAllMaterialAvailabilityRecords,
    selectCartMaterialNumbers,
    selectAllMaterialInfoRecordStates,
    selectSmartCartRecommendationRecords,
    (
      availabilities,
      cartMaterialNumbers,
      infos,
      smartCartRecommendationRecords,
    ): SmartCartRecommendation[] => {
      const recommendations =
        smartCartRecommendationRecords?.filter((recommendation) => {
          const materialNumber = recommendation.product;
          const isValidUom = !!availabilities[
            materialNumber
          ]?.record?.units?.some((unit) => unit.uom === recommendation.uom);

          return (
            !cartMaterialNumbers.includes(materialNumber) &&
            !!infos[materialNumber]?.record?.description &&
            isValidUom &&
            isMaterialOrderable(materialNumber, infos, availabilities)
          );
        }) || [];

      return limit ? recommendations.slice(0, limit) : recommendations;
    },
  );
