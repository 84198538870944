<div class="main-container">
  <h2 class="title">{{ title | translate }}</h2>
  <div class="info-container">
    <div class="info-and-input-container">
      <span class="input-information">
        {{ 'IMPORT_ORDER.IMPORT_FILE.DESCRIPTION' | translate }}
      </span>
      <naoo-file-input
        class="file-input"
        (fileSelected)="onFileSelected($event)"
      ></naoo-file-input>
    </div>
    <div class="requirements-container">
      <img src="{{ imagePath | translate }}" alt="" />
      <div class="requirements-information">
        <span class="requirements-title">{{
          'IMPORT_ORDER.IMPORT_FILE.REQUIREMENTS_TITLE' | translate
        }}</span>
        <ul class="requirements-list">
          <li
            *ngFor="let requirement of fileRequirements"
            class="requirement-description"
          >
            {{ requirement | translate }}
          </li>
        </ul>
        <div class="templates-container">
          <a
            id="csv-template-link"
            href="{{ csvTemplate | translate }}"
            download
            (click)="onTemplateDownload('csv')"
            >{{ 'IMPORT_ORDER.IMPORT_FILE.CSV_TEMPLATE' | translate }}</a
          >
          <a
            id="excel-template-link"
            href="{{ excelTemplate | translate }}"
            download
            (click)="onTemplateDownload('excel')"
            >{{ 'IMPORT_ORDER.IMPORT_FILE.EXCEL_TEMPLATE' | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
