import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { StorePurchaseKey } from '../../../../../shared/models/store-purchase-key';
import {
  StorePurchase,
  StorePurchaseHistoryViewModel,
} from '../../../../../core/store/store-purchase-history/models/store-purchase-history-view-model';
import { CONTENT_SCROLL } from '../../../../../shared/services/scrollable-content/scrollable-content.service';
import { SearchFieldComponent } from '../../../../../shared/search-bar/search-field.component';
import { RouterExtrasService } from '../../../../../shared/services/router-extras/router-extras.service';
import { StoreDisplayContext } from '../../../../../shared/store-display/store-display';
import { DateRange } from '../../../../../shared/date-range-selector/date-range';
import { VirtualScrollerComponent } from '../../../../../vendor/ngx-virtual-scroller/virtual-scroller';

@Component({
  selector: 'naoo-store-purchase-history-content',
  templateUrl: './store-purchase-history-content.component.html',
  styleUrls: ['./store-purchase-history-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorePurchaseHistoryContentComponent {
  @Output() storePurchaseKeyEmitter = new EventEmitter<StorePurchaseKey>();
  @Output() searchValueEmitter = new EventEmitter<string>();
  @Output() dateRangeEmitter = new EventEmitter<DateRange>();

  @Input() searchTerm: string;
  @Input() isTabletWidth: boolean;
  @Input() set filteredViewModel(viewModel: StorePurchaseHistoryViewModel) {
    this._viewModel = viewModel;
    setTimeout(() => {
      if (
        this.routerExtrasService.getRestoreScrollPosition(window.location.href)
      ) {
        this.routerExtrasService.restoreScrollPosition(window.location.href);
      }
    });
  }

  get filteredViewModel(): StorePurchaseHistoryViewModel {
    return this._viewModel;
  }

  readonly storeDisplayContext = StoreDisplayContext.PurchaseHistory;

  @ViewChild('scroll')
  virtualScroll: VirtualScrollerComponent<StorePurchaseHistoryViewModel>;

  @ViewChild('searchField', {
    static: true,
  })
  searchField: SearchFieldComponent;

  constructor(
    private routerExtrasService: RouterExtrasService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  private _viewModel: StorePurchaseHistoryViewModel;

  compareViewModels(
    storePurchase: StorePurchase,
    otherStorePurchase: StorePurchase,
  ): boolean {
    return storePurchase?.purchaseKey === otherStorePurchase?.purchaseKey;
  }

  trackByPurchaseKey(index: number, storePurchase: StorePurchase): string {
    return storePurchase.purchaseKey;
  }

  selectStorePurchaseDetails(storePurchase: StorePurchase): void {
    if (!this.isTabletWidth) {
      this.storePurchaseKeyEmitter.emit(storePurchase);
    }
  }
}
