import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NaooLogger {
  error(message?: any, ...optionalParams: any[]) {
    // eslint:disable-next-line:no-console
    console.error(message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    // eslint:disable-next-line:no-console
    console.info(message, ...optionalParams);
  }

  log(message?: any, ...optionalParams: any[]) {
    // eslint:disable-next-line:no-console
    console.log(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    // eslint:disable-next-line:no-console
    console.warn(message, ...optionalParams);
  }
}
