<div
  class="recommended-material-container"
  *ngFor="let recommendedMaterial of recommendedMaterials"
>
  <ng-container
    *ngIf="
      getRecommendationEngineName(recommendedMaterial) &&
      recommendedMaterial.materialListRows.length > 0
    "
  >
    <div class="title">
      {{ recommendedMaterial.title | stringdefaulter | decodeHtmlEntity }}
    </div>
    <hr *ngIf="!isMobile" />
    <div class="centered-carousel">
      <naoo-material-carousel
        [materialListRows]="recommendedMaterial.materialListRows"
        [recommendationEngineName]="
          getRecommendationEngineName(recommendedMaterial)
        "
      ></naoo-material-carousel>
    </div>
  </ng-container>
</div>
