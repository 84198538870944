<div class="main-container">
  <div class="header-container">
    <div class="guide-cell import-guide-line">
      {{ 'IMPORT_GUIDE.ROW_ERROR.TABLE_HEADER.LINE' | translate }}
    </div>
    <div class="guide-cell import-guide-category">
      {{ 'IMPORT_GUIDE.ROW_ERROR.TABLE_HEADER.CATEGORY' | translate }}
    </div>
    <div class="guide-cell import-guide-item-code">
      {{ 'IMPORT_GUIDE.ROW_ERROR.TABLE_HEADER.ITEM_CODE' | translate }}
    </div>
    <div class="guide-cell import-guide-warning"></div>
    <div class="guide-cell import-guide-invalid-items">
      {{ 'IMPORT_GUIDE.ROW_ERROR.TABLE_HEADER.INVALID_ITEMS' | translate }}
    </div>
  </div>
  <div class="table-body">
    <div
      *ngFor="let importGuideRow of importGuideRows; let odd = odd"
      class="import-guide-row"
      [ngClass]="{ 'import-guide-row-odd': odd }"
    >
      <div class="guide-cell import-guide-line">
        <span class="import-guide-line-value">{{
          importGuideRow.rowNumber
        }}</span>
      </div>
      <div class="guide-cell import-guide-category">
        <span class="import-guide-category-value">{{
          importGuideRow.category
        }}</span>
      </div>
      <div class="guide-cell import-guide-item-code">
        <span class="import-guide-item-code-value">{{
          importGuideRow.itemId
        }}</span>
      </div>
      <div class="guide-cell import-guide-warning">
        <mat-icon
          svgIcon="warning-icon-v1"
          class="import-guide-warning-value"
        ></mat-icon>
      </div>
      <div class="guide-cell import-guide-invalid-items">
        <span class="import-guide-invalid-items-value">{{
          importGuideErrorMessage(importGuideRow.error) | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
