import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialListStyle } from './models/material-row';

export const MaterialRowActions = createActionGroup({
  source: 'Material Row',
  events: {
    'Update Preferred Material View': (viewType: MaterialListStyle) => ({
      viewType,
    }),
    'Hydrate Preferred Material View': emptyProps(),
  },
});
