import { Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { DefaultDialogService } from '../services/dialog/default-dialog/default-dialog.service';
import { filter, mergeMap, sampleTime } from 'rxjs/operators';
import { ZoneSchedulerService } from '../services/zone-scheduler/zone-scheduler.service';
import { NaooConstants } from '../NaooConstants';

@Injectable({ providedIn: 'root' })
export class RouteNavigationSpinnerService {
  private readonly routeNavigationDialogId = 'RouteNavigation';
  private readonly samplePeriod = 500;

  private hasStarted: boolean;

  constructor(
    private router: Router,
    private defaultDialogService: DefaultDialogService,
    private zoneSchedulerService: ZoneSchedulerService,
  ) {}

  public initialize(): void {
    if (!this.hasStarted) {
      this.hasStarted = true;
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationStart ||
              this.isNavigationCompletionEvent(event),
          ),
          sampleTime(
            this.samplePeriod,
            this.zoneSchedulerService.leaveZoneScheduler(),
          ),
          mergeMap((event) => this.zoneSchedulerService.reenterZone(event)),
        )
        .subscribe((event) => {
          if (
            event instanceof NavigationStart &&
            NaooConstants.ORDER_CONFIRMATION_URL !== event.url
          ) {
            this.defaultDialogService.openLoadingModal({
              id: this.routeNavigationDialogId,
              hasBackdrop: false,
            });
          } else {
            this.defaultDialogService.closeLoadingModal(
              this.routeNavigationDialogId,
            );
          }
        });
    }
  }

  private isNavigationCompletionEvent(event: Event): boolean {
    return (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    );
  }
}
