import { CurrentSystem } from '../core/services/session/models/session-record';

export enum MaterialFlagType {
  EarlyCutoff = 'Early Cutoff',
  SpecialOrder = 'Special Order',
  Contract = 'Contract',
  Local = 'Local',
  OrderGuide = 'Order Guide',
  DropShip = 'Drop Ship',
  Entitlement = 'Entitlement',
  Avendra = 'Avendra',
  BidLockedItem = 'Bid Locked Item',
  CommittedManufacturerAllowance = 'Committed Manufacturer Allowance',
  MWBEVendor = 'MWBE Vendor',
  PreferredItem = 'Preferred Item',
  Rebate = 'Rebate',
  ContractPrice = 'Contract Price',
  FoodBuy_S1 = 'FoodBuy S1',
  FoodBuy_S2 = 'FoodBuy S2',
  FoodBuy_S3 = 'FoodBuy S3',
  Bulk = 'Bulk',
}

export const materialFlagTypeSort: Record<MaterialFlagType, number> = {
  [MaterialFlagType.Entitlement]: 0,
  [MaterialFlagType.EarlyCutoff]: 1,
  [MaterialFlagType.SpecialOrder]: 2,
  [MaterialFlagType.DropShip]: 3,
  [MaterialFlagType.OrderGuide]: 4,
  [MaterialFlagType.FoodBuy_S1]: 5,
  [MaterialFlagType.FoodBuy_S2]: 6,
  [MaterialFlagType.FoodBuy_S3]: 7,
  [MaterialFlagType.Local]: 8,
  [MaterialFlagType.MWBEVendor]: 9,
  [MaterialFlagType.Contract]: 10,
  [MaterialFlagType.Avendra]: 11,
  [MaterialFlagType.BidLockedItem]: 12,
  [MaterialFlagType.CommittedManufacturerAllowance]: 13,
  [MaterialFlagType.PreferredItem]: 14,
  [MaterialFlagType.Rebate]: 15,
  [MaterialFlagType.ContractPrice]: 16,
  [MaterialFlagType.Bulk]: 17,
};

export interface MaterialFlag {
  type: MaterialFlagType;
  earlyCutoffParams?: EarlyCutoffParams;
  entitlementParams?: EntitlementParams;
  bulkParams?: BulkParams;
}

export interface EarlyCutoffParams {
  cutoffDateTime: Date;
  hasShipDate: boolean;
  currentSystem: CurrentSystem;
  haveCutoffsLoaded?: boolean;
}

export interface EntitlementParams {
  shieldColor: string;
  descriptionKey: string;
}

export interface BulkParams {
  threshold: number;
  uom: string;
  price: number;
}
