<div class="complimentary-product-wrapper" *ngIf="materialRow">
  <div class="border-wrapper">
    <div class="info-wrapper">
      <naoo-material-image
        [materialRowOptions]="materialRow.materialRowOptions"
      ></naoo-material-image>
      <div class="info-content">
        <naoo-material-info
          [materialRowOptions]="materialRow.materialRowOptions"
          [materialInfo]="materialRow.materialRowInfo"
        ></naoo-material-info>
        <div class="uom-content">
          <mat-chip-listbox [hideSingleSelectionIndicator]="true">
            <mat-chip-option
              *ngFor="
                let materialOrderingInfo of materialRow.materialOrdering
                  ?.materialOrderingInfos;
                let idx = index;
                trackBy: trackByDisplayCode
              "
              [highlighted]="idx === selectedIndex"
              (selectionChange)="chipSelectionChange(idx)"
            >
              <div class="uom-wrapper">
                <div class="uom-description">
                  {{
                    {
                      uomCode: materialOrderingInfo.displayCode,
                    } | materialunits
                  }}
                </div>
                {{ materialOrderingInfo.price | naooprice }}
              </div>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div class="ordering-wrapper">
          <div class="warning-content">
            <naoo-material-warning
              [warning]="materialRow.materialOrdering?.materialWarning"
              [materialRowOptions]="materialRow.materialRowOptions"
              [openDirection]="warningDirection"
              [isInventoryLoaded]="
                materialRow.materialOrdering?.isInventoryLoaded
              "
              [selectedOrderingInfoIndex]="selectedIndex"
            ></naoo-material-warning>
          </div>
          <naoo-material-ordering-info
            [orderingInfos]="
              materialRow.materialOrdering?.materialOrderingInfos
            "
            [currentOrderingInfo]="currentOrderingInfo"
            [materialRowOptions]="materialRow.materialRowOptions"
            [materialWarning]="materialRow.materialOrdering?.materialWarning"
            [isInventoryLoaded]="
              materialRow.materialOrdering?.isInventoryLoaded
            "
          >
          </naoo-material-ordering-info>
          <div class="total">
            {{ currentOrderingInfo?.totalEstimatedCost | naooprice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
