import { Injectable } from '@angular/core';
import { NaooLogger } from '../../../shared/logger/NaooLogger.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EcommerceAnalyticsService {
  constructor(
    private logger: NaooLogger,
    private _window: Window,
  ) {}

  trackEcommerceEvent(event: object): void {
    const dataLayer: object[] = this._window.dataLayer;
    if (!dataLayer || !dataLayer.push) {
      this.logger.error(
        `Unable to track ecommerce event dataLayer is unavailable. dataLayer == ${dataLayer}`,
      );
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  getClientId(): Observable<string> {
    const ga = this._window.ga;
    if (ga) {
      const clientId = ga?.getAll?.()?.[0]?.get('clientId');
      return of(clientId);
    } else {
      return of('');
    }
  }
}
