import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { SearchService } from '../../services/search/search.service';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  first,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import {
  selectCurrentUrl,
  selectRouterState,
} from '../router/router.selectors';
import {
  selectCurrentOffset,
  selectSearchFeature,
  selectTotalSearchResults,
} from './search.selectors';
import { SearchTransformationService } from '../../services/search/search-transformation.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import {
  ROUTER_NAVIGATED,
  RouterNavigatedAction,
  RouterReducerState,
} from '@ngrx/router-store';
import { SearchConstants } from '../../../shared/models/search/SearchConstants';
import { RouterStateUrl } from '../router/router-state-serializer';
import { ErrorActions } from '../error/error.actions';
import { LastOrderedActions } from '../last-ordered/last-ordered.actions';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { SearchState, SponsoredRecommendationsState } from './search.state';
import { CustomDimension } from 'src/app/shared/analytics/custom-dimension';
import { AnalyticsEventInfo } from 'src/app/shared/analytics/analytics-event-info';
import {
  selectFulfillmentType,
  selectIsOnlineCartLoaded,
} from '../cart/cart.selectors';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { InventoryAvailabilityActions } from '../inventory-availability/inventory-availability.actions';
import { SearchActions } from './search.actions';
import { SharedActions } from '../shared/shared.actions';
import { EcommerceAnalyticsActions } from '../ecommerce-analytics/ecommerce-analytics.actions';
import { UrlSerializerService } from '../../../shared/services/url-serializer/url-serializer.service';
import { MaterialRowContext } from '../material-row/models/material-row';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import { SearchTransformer } from './shared/search-transformer';
import { selectLanguage } from '../session/session.selectors';

@Injectable()
export class SearchEffects {
  private readonly searchErrorCustomAnalytic = 'Search for products';
  private readonly searchResultsCategory = 'search results';

  private readonly noResultsAction = 'returned';
  private readonly noResultsLabel = 'no results';

  private readonly loadMoreAction = 'scroll';
  private readonly loadMoreLabel = 'load more';

  private readonly postSearchAction = this.noResultsAction;
  private readonly postSearchLabel = 'post-search suggested - ';

  constructor(
    private actions$: Actions,
    private store: Store,
    private searchService: SearchService,
    private router: Router,
    private searchTransformationService: SearchTransformationService,
    private analytics: NaooAnalyticsManager,
    private urlSerializerService: UrlSerializerService,
  ) {}

  submitSearch$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.submitSearch),
      concatLatestFrom(() => [
        this.store.select(selectSearchFeature),
        this.store.select(selectRouterState),
      ]),
      map(([action, searchState, routerStateUrl]) => {
        if (
          routerStateUrl.state.url.includes(NaooConstants.SEARCH_URL) &&
          action.searchQuery.trim() === searchState.lastSearchText
        ) {
          return SharedActions.noOperation(
            'Search query param did not change from last search',
          );
        } else {
          return SearchActions.newSearch(action.searchQuery);
        }
      }),
    );
  });

  newSearch$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SearchActions.newSearch),
        concatLatestFrom(() => this.store.select(selectSearchFeature)),
        map(([action, searchState]) => {
          const params: NavigationExtras = {
            queryParams: {
              searchText: action.searchQuery,
            },
          };

          this.addOrderGuideToParams(params, searchState);
          this.addAvailableTodayToParams(params, searchState);

          this.router.navigate([NaooConstants.SEARCH_URL], params);
        }),
      );
    },
    { dispatch: false },
  );

  newCatalogSearch$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SearchActions.newCatalogSearch),
        concatLatestFrom(() => this.store.select(selectSearchFeature)),
        map(([action, searchState]) => {
          const params: NavigationExtras = {
            queryParams: {},
          };

          this.addOrderGuideToParams(params, searchState);

          this.router.navigate(
            [NaooConstants.CATEGORIES_RESULTS_PATH, action.categoryKey],
            params,
          );
        }),
      );
    },
    { dispatch: false },
  );

  searchNavigation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      filter(
        (action) =>
          action.payload.routerState.url.includes(NaooConstants.SEARCH_URL) ||
          action.payload.routerState.url.includes(
            NaooConstants.CATEGORIES_RESULTS_PATH,
          ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectRouterState),
        this.store.select(selectSearchFeature),
      ]),
      mergeMap(([action, routerStateUrl, searchState]) => {
        const isNewSearch =
          action.payload.routerState.url.includes(NaooConstants.SEARCH_URL) &&
          routerStateUrl.state.queryParams[SearchConstants.paramSearchText] !==
            searchState.lastSearchText;
        return [
          SearchActions.clearSearchResults(isNewSearch),
          SearchActions.getSearchResults(),
        ];
      }),
    );
  });

  getSearchResults$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.getSearchResults),
      delayWhen(() =>
        this.store.select(selectIsOnlineCartLoaded).pipe(
          first((isOnlineCartLoaded) => isOnlineCartLoaded),
          takeUntil(
            this.actions$.pipe(ofType(SearchActions.clearSearchResults)),
          ),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectRouterState),
        this.store.select(selectSearchFeature),
        this.store.select(selectIsOnlineCartLoaded),
        this.store.select(selectFulfillmentType),
        this.store.select(selectCustomerMaterialRecord),
      ]),
      mergeMap(
        ([
          _,
          router,
          searchState,
          isOnlineCartLoaded,
          fulfillmentType,
          customerMaterials,
        ]) => {
          if (!isOnlineCartLoaded) {
            return of(
              SharedActions.noOperation(
                'getSearchResults$: online cart is not loaded',
              ),
            );
          }
          const shouldRestorePreviousResults =
            !!searchState.resultSet &&
            searchState.resultSet.materialNumbers.length === 0 &&
            searchState.currentOffset > 0;
          let searchOffset: number, resultsLimit: number;
          if (shouldRestorePreviousResults) {
            searchOffset = 0;
            resultsLimit =
              searchState.currentOffset + SearchConstants.searchOffsetCount;
          } else {
            searchOffset = searchState.currentOffset;
          }

          if (router.state.url.includes('/search')) {
            const searchText =
              router.state.queryParams[SearchConstants.paramSearchText];
            const customMaterial = Object.keys(customerMaterials).find(
              (key) =>
                customerMaterials[key].customerMaterialNumber.toUpperCase() ===
                searchText.toUpperCase(),
            );

            if (customMaterial) {
              this.router.navigate(
                [NaooConstants.PDP_PATH_WITH_TRAILING_SLASH + customMaterial],
                { replaceUrl: true },
              );
              return [];
            }
          }

          return this.searchService
            .performSearch(
              router.state,
              fulfillmentType,
              searchOffset,
              resultsLimit,
            )
            .pipe(
              tap((response) => {
                if (response.suggestedSearchText) {
                  this.analytics.trackAnalyticsEvent({
                    action: this.postSearchAction,
                    category: this.searchResultsCategory,
                    label: this.postSearchLabel + response.suggestedSearchText,
                  });
                }
                if (response.totalResults === 0) {
                  this.analytics.trackAnalyticsEvent({
                    action: this.noResultsAction,
                    category: this.searchResultsCategory,
                    label: this.noResultsLabel,
                  });
                }
              }),
              map((response) =>
                SearchActions.getSearchResultsSuccess(
                  router.state.url.startsWith(NaooConstants.SEARCH_URL),
                  this.searchTransformationService.transformSearchResultResponse(
                    router.state,
                    response,
                  ),
                  searchState?.resultSet?.materialNumbers?.length ??
                    searchOffset,
                ),
              ),
              catchError((error) => [
                SearchActions.getSearchResultsError(),
                ErrorActions.fatalError(error, this.searchErrorCustomAnalytic),
              ]),
            );
        },
      ),
    );
  });

  getSearchResultsSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.getSearchResultsSuccess),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      mergeMap(([action, language]) => {
        const { breadCrumbTree, materialNumbers, sponsoredRecommendations } =
          action.searchResults.resultSet;

        const recommendedProducts = Array.from(
          sponsoredRecommendations?.productRecommendations?.keys() || [],
        );
        const allMaterialNumbers = [
          ...recommendedProducts,
          ...this.getBannerMaterialNumbers(sponsoredRecommendations),
          ...materialNumbers,
        ];

        const analyticMaterialNumbers =
          materialNumbers?.slice(action.searchStateStartIndex) ?? [];

        const localizedSearchText = action.isSearch
          ? action.searchResults.searchText
          : SearchTransformer.transformBreadCrumbTreeToString(breadCrumbTree);

        return [
          MaterialInfoActions.loadMaterialInfo(allMaterialNumbers),
          MaterialAvailabilityActions.loadMaterialAvailability(
            allMaterialNumbers,
          ),
          LastOrderedActions.loadLastOrdered(allMaterialNumbers),
          InventoryAvailabilityActions.loadCartInventoryAvailability(
            allMaterialNumbers,
          ),
          EcommerceAnalyticsActions.googleViewItemListEvent(
            analyticMaterialNumbers,
            MaterialRowContext.Browse,
            {
              analytics: {
                searchText: localizedSearchText?.[language],
              },
              startIndex: action.searchStateStartIndex,
            },
          ),
          EcommerceAnalyticsActions.sponsoredSearchResultsEvent(),
        ];
      }),
    );
  });

  loadMoreSearchResults$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.loadMoreSearchResults),
      concatLatestFrom(() => [
        this.store.select(selectCurrentOffset),
        this.store.select(selectTotalSearchResults),
      ]),
      map(([_action, offset, totalResults]) => {
        if (offset < totalResults) {
          this.analytics.trackAnalyticsEvent({
            action: this.loadMoreAction,
            category: this.searchResultsCategory,
            label: this.loadMoreLabel,
          });

          return SearchActions.getSearchResults();
        } else {
          return SharedActions.noOperation('No More Results to Load');
        }
      }),
    );
  });

  toggleOrderGuideFilter$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.toggleOrderGuideFilter),
      concatLatestFrom(() => this.store.select(selectRouterState)),
      map(([action, routerStateUrl]) => {
        const routeParams: NavigationExtras = {
          queryParams: { ...routerStateUrl.state.queryParams },
        };

        if (!action.isToggled) {
          delete routeParams.queryParams[SearchConstants.paramOrderGuideOnly];
        } else {
          routeParams.queryParams[SearchConstants.paramOrderGuideOnly] = true;
        }
        this.router.navigate(
          this.getNavigationPath(routerStateUrl),
          routeParams,
        );
        return EcommerceAnalyticsActions.searchFilterEvent(
          'OrderGuide',
          String(action.isToggled),
        );
      }),
    );
  });

  toggleAvailableTodayFilter$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.toggleAvailableTodayFilter),
      concatLatestFrom(() => [
        this.store.select(selectRouterState),
        this.store.select(selectFulfillmentType),
      ]),
      map(([action, routerStateUrl, fulfillmentType]) => {
        const routeParams: NavigationExtras = {
          queryParams: { ...routerStateUrl.state.queryParams },
        };
        const storeFulfillment = [
          FulfillmentType.EXPRESS,
          FulfillmentType.PICKUP,
        ].includes(fulfillmentType);

        if (!action.isToggled || !storeFulfillment) {
          delete routeParams.queryParams[
            SearchConstants.paramAvailableTodayFilter
          ];
        } else {
          routeParams.queryParams[SearchConstants.paramAvailableTodayFilter] =
            true;
        }
        this.router.navigate(
          this.getNavigationPath(routerStateUrl),
          routeParams,
        );
        return EcommerceAnalyticsActions.searchFilterEvent(
          'AvailableToday',
          String(action.isToggled),
        );
      }),
    );
  });

  toggleDefaultFilter$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.toggleDefaultFilter),
      concatLatestFrom(() => this.store.select(selectRouterState)),
      map(([action, routerStateUrl]) => {
        const routeParams: NavigationExtras = {
          queryParams: { ...routerStateUrl.state.queryParams },
        };

        let targetedQuery: string = routerStateUrl.state.queryParams[
          action.filterParam
        ] as string;

        if (targetedQuery) {
          const values = this.deserializeFilters(targetedQuery);
          if (values.has(action.queryParamValue) && !action.isToggled) {
            values.delete(action.queryParamValue);
            targetedQuery = this.serializeFilters(values);
          } else if (!values.has(action.queryParamValue) && action.isToggled) {
            targetedQuery = targetedQuery.concat(`,${action.queryParamValue}`);
          }
          routeParams.queryParams[action.filterParam] = targetedQuery;

          if (targetedQuery.length === 0) {
            delete routeParams.queryParams[action.filterParam];
          }
        } else if (action.isToggled) {
          routeParams.queryParams[action.filterParam] = action.queryParamValue;
        }

        this.router.navigate(
          this.getNavigationPath(routerStateUrl),
          routeParams,
        );
        return EcommerceAnalyticsActions.searchFilterEvent(
          action.filterGroupNameEN,
          action.filterNameEN,
        );
      }),
    );
  });

  toggleTaxonomyFilter$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.toggleTaxonomyFilter),
      concatLatestFrom(() => this.store.select(selectRouterState)),
      map(([action, routerStateUrl]) => {
        const routeParams: NavigationExtras = {
          queryParams: { ...routerStateUrl.state.queryParams },
        };

        if (!action.categoryKey) {
          delete routeParams.queryParams[
            SearchConstants.paramCategoryCoordinate
          ];
        } else {
          routeParams.queryParams[SearchConstants.paramCategoryCoordinate] =
            action.categoryKey;
        }

        this.router.navigate(
          this.getNavigationPath(routerStateUrl),
          routeParams,
        );
        return EcommerceAnalyticsActions.searchFilterEvent(
          'Taxonomy',
          action.taxonomyNameEN,
        );
      }),
    );
  });

  clearAllFilters$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SearchActions.clearAllFilters),
        concatLatestFrom(() => this.store.select(selectRouterState)),
        map(([_action, routerStateUrl]) => {
          const routeParams: NavigationExtras = {
            queryParams: {
              searchText:
                routerStateUrl.state.queryParams[
                  SearchConstants.paramSearchText
                ],
              onGuide:
                routerStateUrl.state.queryParams[
                  SearchConstants.paramOrderGuideOnly
                ],

              storeStocked:
                routerStateUrl.state.queryParams[
                  SearchConstants.paramAvailableTodayFilter
                ],
            },
          };

          this.router.navigate(
            this.getNavigationPath(routerStateUrl),
            routeParams,
          );
        }),
      );
    },
    { dispatch: false },
  );

  searchMaterialClick$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SearchActions.searchMaterialClick),
        concatLatestFrom(() => [
          this.store.select(selectRouterState),
          this.store.select(selectSearchFeature),
        ]),
        map(([action, routerStateUrl, searchState]) => {
          if (!routerStateUrl.state.url.includes(NaooConstants.SEARCH_URL)) {
            return;
          }

          const materialIndex = searchState.resultSet.materialNumbers.indexOf(
            action.materialNumber,
          );
          const customDimension50: CustomDimension = {
            index: 50,
            value: `${materialIndex + 1}`,
          };
          const searchArguments = routerStateUrl.state.url.substring(
            routerStateUrl.state.url.indexOf('?') + 1,
          );
          const eventInfo: AnalyticsEventInfo = {
            action: 'item click',
            category: this.searchResultsCategory,
            label: searchArguments,
          };

          this.analytics.trackAnalyticsEvent(eventInfo, [customDimension50]);
        }),
      );
    },
    { dispatch: false },
  );

  refreshCurrentSearch$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.refreshCurrentSearch),
      concatLatestFrom(() => this.store.select(selectCurrentUrl)),
      map(([_, currentUrl]) => {
        if (
          currentUrl?.startsWith(NaooConstants.SEARCH_URL) ||
          currentUrl?.startsWith(NaooConstants.CATEGORIES_RESULTS_PATH)
        ) {
          return SearchActions.getSearchResults();
        } else {
          return SharedActions.noOperation('No More Results to Load');
        }
      }),
    );
  });

  private getNavigationPath(
    routerStateUrl: RouterReducerState<RouterStateUrl>,
  ): string[] {
    if (routerStateUrl.state.url.startsWith(NaooConstants.SEARCH_URL)) {
      return [NaooConstants.SEARCH_URL];
    } else {
      return [
        NaooConstants.CATEGORIES_RESULTS_PATH,
        routerStateUrl.state.params[SearchConstants.paramCategoryCoordinate],
      ];
    }
  }

  private addOrderGuideToParams(
    params: NavigationExtras,
    searchState: SearchState,
  ) {
    if (searchState.orderGuideFilter?.isOrderGuideOptionSelected) {
      params.queryParams[SearchConstants.paramOrderGuideOnly] = true;
    }
  }

  private addAvailableTodayToParams(
    params: NavigationExtras,
    searchState: SearchState,
  ) {
    if (
      !!searchState.availableTodayFilter &&
      searchState.availableTodayFilter.isEnabled
    ) {
      params.queryParams[SearchConstants.paramAvailableTodayFilter] = true;
    }
  }

  private deserializeFilters(selectedFilters: string): Set<string> {
    const filters = new Set<string>();
    if (selectedFilters && selectedFilters.length > 0) {
      selectedFilters.split(',').forEach((selectedFilter) => {
        filters.add(selectedFilter);
      });
    }
    return filters;
  }

  private serializeFilters(values: Set<string>): string {
    return Array.from(values).join(',');
  }

  private getBannerMaterialNumbers(
    recommendations: SponsoredRecommendationsState,
  ): string[] {
    return this.urlSerializerService.getMaterialNumbersFromUrl(
      recommendations?.banners?.[0]?.destinationUrl,
    );
  }
}
