import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DeliverySchedule } from '../../shared/models/delivery-schedule';
import { Subject } from 'rxjs';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { DeliveryScheduleFacade } from 'src/app/core/store/delivery-schedule/delivery-schedule.facade';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { NaooDeliveryOrShipPipe } from '../../shared/pipes/delivery-or-ship.pipe';
import { Locale } from '../../core/services/session/models/session-record';
import { FulfillmentModalService } from '../../shared/services/fulfillment-modal/fulfillment-modal.service';
import { getLanguageTag } from '../../shared/utilities/locale-utilities';
import { formatDate } from '@angular/common';
import { dateFormats } from '../../shared/utilities/date-utilities';

const enum CalendarIcon {
  Valid = 'calendar-icon-v3',
  Invalid = 'calendar-invalid',
}

@Component({
  selector: 'naoo-delivery-schedule-widget',
  templateUrl: './delivery-schedule-widget.component.html',
  styleUrls: ['./delivery-schedule-widget.component.scss'],
})
export class DeliveryScheduleWidgetComponent implements OnInit, OnDestroy {
  static readonly ANALYTICS_CATEGORY = 'general';
  static readonly ANALYTICS_ACTION = 'click';
  static readonly ANALYTICS_LABEL = 'ship date widget';

  @Input() showArrowIcon = false;
  @Input() wideLayout = false;
  @Input() customAnalyticsCategory: string;
  @Input() disabled = false;
  @Input() hideBorders = false;

  title = '';
  subtitle = '';
  combinedTitle = '';
  combinedAriaLabel = '';
  hasLoaded: boolean;
  isCartLoaded = false;

  imageIcon = CalendarIcon.Invalid;

  currentLocale: Locale;
  availableDeliveryDates: DeliverySchedule[];
  routeDate: Date;
  destroyed$ = new Subject();

  constructor(
    private readonly deliveryScheduleFacade: DeliveryScheduleFacade,
    private readonly fulfillmentModalService: FulfillmentModalService,
    private readonly localizationService: LocalizationService,
    private readonly cartFacade: CartFacade,
    private readonly analytics: NaooAnalyticsManager,
    public changeDetectorRef: ChangeDetectorRef,
    private readonly deliveryOrShipPipe: NaooDeliveryOrShipPipe,
  ) {
    this.currentLocale = this.localizationService.currentLocale;
  }

  ngOnInit() {
    this.setUI();

    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((locale) => {
        this.currentLocale = locale;
        this.setUI();
      });

    this.deliveryScheduleFacade
      .getLoadedDeliverySchedules()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((deliverySchedules) => {
        this.availableDeliveryDates = deliverySchedules;
        this.setUI();
      });

    this.cartFacade
      .getRouteDate()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((routeDate) => {
        this.routeDate = routeDate;
        this.setUI();
      });

    this.cartFacade
      .isCartLoaded()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isCartLoaded) => (this.isCartLoaded = isCartLoaded));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onClick() {
    if (!this.hasDeliverySchedule()) {
      return;
    }
    this.trackClickEvent();
    this.fulfillmentModalService.openFulfillmentModal(true, false, true);
  }

  setUI() {
    let isLoading = false;
    if (this.routeDate) {
      this.setUIWithRouteDate();
    } else if (this.hasDeliverySchedule()) {
      this.setUIWithNoRouteDate();
    } else if (this.hasNoRouting()) {
      this.setToNoRouting();
    } else {
      this.setToLoading();
      isLoading = true;
    }
    this.hasLoaded = !isLoading;
    this.changeDetectorRef.markForCheck();
  }

  get isDisabled() {
    return (
      this.disabled ||
      !this.hasLoaded ||
      !this.isCartLoaded ||
      this.hasNoRouting()
    );
  }

  private hasDeliverySchedule(): boolean {
    return (
      !!this.availableDeliveryDates && this.availableDeliveryDates.length > 0
    );
  }

  private hasNoRouting(): boolean {
    return (
      !!this.availableDeliveryDates && this.availableDeliveryDates.length === 0
    );
  }

  private trackClickEvent() {
    const category = this.customAnalyticsCategory
      ? this.customAnalyticsCategory
      : DeliveryScheduleWidgetComponent.ANALYTICS_CATEGORY;

    const eventInfo: AnalyticsEventInfo = {
      action: DeliveryScheduleWidgetComponent.ANALYTICS_ACTION,
      label: DeliveryScheduleWidgetComponent.ANALYTICS_LABEL,
      category: category,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private setUIWithRouteDate() {
    const dateValue = formatDate(
      this.routeDate,
      dateFormats[this.currentLocale]['shortDayMonthDateNoYear'],
      getLanguageTag(this.currentLocale),
      '+0000',
    );

    if (dateValue) {
      this.subtitle = dateValue;
    }

    this.title = this.deliveryOrShipPipe.transform('DELIVERY_SCHEDULE_TITLE');
    const title = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_ON_DATE',
    );
    this.combinedTitle = this.localizationService.instant(title, {
      value: dateValue,
    });

    const ariaDeliveryOrShip = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_ON_DATE_ARIA_LABEL',
    );
    this.combinedAriaLabel = this.localizationService.instant(
      ariaDeliveryOrShip,
      {
        value: dateValue,
      },
    );

    this.imageIcon = CalendarIcon.Valid;
  }

  private setUIWithNoRouteDate() {
    this.title = this.deliveryOrShipPipe.transform('DELIVERY_SCHEDULE_TITLE');
    this.subtitle = 'DELIVERYSCHEDULE.WIDGET.SUBTITLE';
    this.combinedTitle = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_COMBINED_TITLE',
    );
    this.combinedAriaLabel = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_COMBINED_TITLE_ARIA_LABEL',
    );
    this.imageIcon = CalendarIcon.Invalid;
  }

  private setToNoRouting() {
    this.title = 'DELIVERYSCHEDULE.WIDGET.ROUTING.TITLE';
    this.subtitle = 'DELIVERYSCHEDULE.WIDGET.ROUTING.SUBTITLE';
    this.combinedTitle = 'DELIVERYSCHEDULE.WIDGET.ROUTING.COMBINED_TITLE';
    this.combinedAriaLabel = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_NO_ROUTING',
    );
    this.imageIcon = CalendarIcon.Invalid;
  }

  private setToLoading() {
    this.title = this.deliveryOrShipPipe.transform('DELIVERY_SCHEDULE_TITLE');
    this.subtitle = 'LOADING';
    this.combinedTitle = this.deliveryOrShipPipe.transform(
      'DELIVERY_SCHEDULE_COMBINED_TITLE_LOADING',
    );
    this.combinedAriaLabel = '';
    this.imageIcon = CalendarIcon.Valid;
  }
}
