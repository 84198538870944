import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllLastOrderedRecords } from '../last-ordered/last-ordered.selectors';
import {
  materialRelatedAdapter,
  MaterialRelatedState,
} from './material-related.state';
import { MaterialRelated } from './models/material-related';
import { transformMaterialRelated } from './material-related-transformer';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import {
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
} from '../material-availability/material-availability.state';
import { Dictionary } from '@ngrx/entity';

const selectMaterialRelatedFeature =
  createFeatureSelector<MaterialRelatedState>('materialRelated');

const selectAllMaterialRelatedRecords = createSelector(
  selectMaterialRelatedFeature,
  materialRelatedAdapter.getSelectors().selectAll,
);

export const selectMaterialRelatedRecordStateDictionary = createSelector(
  selectMaterialRelatedFeature,
  materialRelatedAdapter.getSelectors().selectEntities,
);

export const selectAllMaterialRelatedSubstitutesAndSimilar = createSelector(
  selectAllMaterialRelatedRecords,
  (materialRelatedRecords): string[] => {
    const similarMaterials: string[][] = materialRelatedRecords?.map(
      (state) => {
        const record = state.record;
        return record ? [...record.substitutes, ...record.similar] : [];
      },
    );
    return [...new Set(similarMaterials?.flat())];
  },
);

export const selectMaterialRelatedRecordState = (materialNumber: string) =>
  createSelector(
    selectMaterialRelatedRecordStateDictionary,
    (recordState) => recordState[materialNumber],
  );

export const selectHasMaterialRelated = (materialNumber: string) =>
  createSelector(
    selectMaterialRelatedRecordState(materialNumber),
    selectAllMaterialAvailabilityRecords,
    (related, availabilityRecords) => {
      return (
        !!related &&
        !!related.record &&
        (hasLoadedAndIsOrderable(related.record.similar, availabilityRecords) ||
          hasLoadedAndIsOrderable(
            related.record.substitutes,
            availabilityRecords,
          ))
      );
    },
  );

export const selectMaterialRelated = (materialNumber: string) =>
  createSelector(
    selectMaterialRelatedRecordStateDictionary,
    selectAllLastOrderedRecords,
    selectAllMaterialAvailabilityRecords,
    (
      relatedRecords,
      lastOrderRecords,
      availabilityRecords,
    ): MaterialRelated => {
      return transformMaterialRelated(
        materialNumber,
        relatedRecords,
        lastOrderRecords,
        availabilityRecords,
      );
    },
  );

function hasLoadedAndIsOrderable(
  materialNumbers: string[],
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
): boolean {
  const isEveryMaterialLoaded: boolean = materialNumbers.every(
    (materialNumber) => {
      const availabilityRecord = availabilityRecords[materialNumber];
      return (
        !!availabilityRecord &&
        (availabilityRecord.status ===
          MaterialAvailabilityRecordStatus.Success ||
          availabilityRecord.status === MaterialAvailabilityRecordStatus.Error)
      );
    },
  );

  return (
    isEveryMaterialLoaded &&
    materialNumbers.some(
      (materialNumber) =>
        !!availabilityRecords[materialNumber].record &&
        availabilityRecords[materialNumber].record.isOrderable,
    )
  );
}
