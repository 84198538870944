import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { entitlementReducer } from './entitlement.reducer';
import { EntitlementEffects } from './entitlement.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('entitlement', entitlementReducer),
    EffectsModule.forFeature([EntitlementEffects]),
  ],
})
export class EntitlementStoreModule {}
