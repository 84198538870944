<div
  #flag
  class="material-flag"
  [ngClass]="[flagClass, tooltipClass]"
  [attr.tooltip]="tooltipText"
  [attr.tabIndex]="0"
  [attr.aria-label]="tooltipText"
  (blur)="onBlur()"
  (click)="onClick()"
>
  <div class="material-flag-title">
    {{ flagText | translate }}
  </div>
</div>
