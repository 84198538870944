<ng-container *ngIf="!isMobileHeader; else mobileHeader">
  <div class="store-location">
    <span class="store-header">
      <div
        class="store-name"
        [ngClass]="{ 'non-bolded': isNonBoldedStoreName }"
      >
        {{ storeRecord?.name }}
      </div>
      <ng-container *ngIf="!isMobileWidth; else mobileWidthTelephone">
        {{ formattedPhoneNumber }}
      </ng-container>
    </span>
    <div class="address-line-one">{{ storeRecord?.address.address1 }}</div>
    <span class="address-line-two">
      {{ addressLineTwo }}
      <ng-container [ngTemplateOutlet]="infoButton"></ng-container>
    </span>
  </div>
</ng-container>

<ng-template #mobileWidthTelephone>
  <a href="{{ 'tel:' + formattedPhoneNumber }}">
    {{ formattedPhoneNumber }}
  </a>
</ng-template>

<ng-template #mobileHeader>
  <span class="mobile-display">
    {{ storeRecord?.name }}
    <ng-container [ngTemplateOutlet]="infoButton"></ng-container>
  </span>
</ng-template>

<ng-template #infoButton>
  <button
    #tooltip="matTooltip"
    mat-icon-button
    class="info-tooltip-button"
    matTooltipClass="line-broken-tooltip"
    matTooltipPosition="right"
    [matTooltip]="tooltipText"
    (click)="manualTooltip.toggle()"
  >
    <mat-icon svgIcon="filled_info_icon"></mat-icon>
  </button>
</ng-template>
