import { createActionGroup, emptyProps } from '@ngrx/store';

export const OfflineModeActions = createActionGroup({
  source: 'OfflineMode',
  events: {
    'Start Offline Mode Check': emptyProps(),
    'Update Offline Mode': (isOffline: boolean) => ({ isOffline }),
    'Stop Offline Mode Check': emptyProps(),
  },
});
