import { NgModule } from '@angular/core';
import { CommodityDocketComponent } from './commodity-docket.component';
import { CommodityDocketCarouselComponent } from './commodity-docket-carousel/commodity-docket-carousel.component';
import { CommodityAllocationComponent } from './commodity-allocation/commodity-allocation.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialListModule } from '../../material-list/material-list.module';
import { MatIconModule } from '@angular/material/icon';
import { GenericDocketModule } from '../generic-docket/generic-docket.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    MaterialListModule,
    MatIconModule,
    GenericDocketModule,
  ],
  declarations: [
    CommodityDocketComponent,
    CommodityDocketCarouselComponent,
    CommodityAllocationComponent,
  ],
  exports: [CommodityDocketComponent],
})
export class CommodityDocketModule {}
