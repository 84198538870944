import { OrderGuideChangeItem } from 'src/app/core/store/order-guide-change-history/order-guide-change-history.facade';
import { OrderGuideState } from 'src/app/core/store/order-guide/order-guide.state';
import { OrderGuide } from './models/order-guide';
import { MaterialListStyle } from 'src/app/core/store/material-row/models/material-row';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../material-info/material-info.state';
import { Dictionary } from '@ngrx/entity';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import {
  CategorizedGuideMaterials,
  GuidesTransformer,
} from '../guides/guides-transformer';
import { CategorizedMaterialsRecord } from '../../services/order-guide/models/categorized-materials-record';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { GuidesContext } from '../../../guides/shared/guides';
import { hasMaterialInfoFinishedLoading } from '../material-info/material-info.util';
import { ExportFeatureType } from '../../../shared/models/export/export-properties';
import { Localized } from '../../../shared/models/localized';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { Language } from '../../services/session/models/session-record';

export class OrderGuideTransformer {
  private static readonly orderGuideName: Localized<string> = {
    en: 'Order Guide',
    fr: 'Fiche de commande',
    es: 'Guía de pedidos',
  };

  public static transformOrderGuide(
    orderGuideState: OrderGuideState,
    orderGuideChanges: OrderGuideChangeItem[],
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    preferredView: MaterialListStyle,
    isOffline: boolean,
    language: Language,
  ): OrderGuide {
    if (!orderGuideState.hasLoaded) {
      return undefined;
    }

    const categories = Object.values(orderGuideState.records.ids).map(
      (categoryId) => orderGuideState.records.entities[categoryId],
    );
    const orderGuideMaterialNumbers = categories.reduce(
      (prev, curr) => prev.concat(curr.materialNumbers),
      [],
    );

    const materialInfos = orderGuideMaterialNumbers.map(
      (materialNumber) => materialInfoRecords[materialNumber],
    );
    const hasLoaded = materialInfos.every((materialInfo) =>
      hasMaterialInfoFinishedLoading(materialInfo),
    );
    if (!hasLoaded) {
      return undefined;
    }

    const categorizedMaterials = this.transformCategorizedMaterials(
      materialInfoRecords,
      categories,
    );

    const flattenedCategories = GuidesTransformer.flattenCategories(
      categorizedMaterials,
      materialInfoRecords,
      customerMaterialRecord,
      lastOrderedRecords,
      orderGuideState.sortBy,
      orderGuideState.searchText,
      language,
    );

    return {
      header: GuidesTransformer.transformGuideHeader(
        categorizedMaterials,
        customerMaterialRecord,
        this.orderGuideName,
        orderGuideState.searchText,
      ),
      materialListRows: flattenedCategories,
      sideBar: GuidesTransformer.transformGuideSideBar(
        flattenedCategories,
        orderGuideState.sortBy,
        GuidesContext.OrderGuide,
      ),
      preferredView: GuidesTransformer.getMaterialListStyle(preferredView),
      isOffline: isOffline,
      changes: orderGuideChanges,
    };
  }

  public static transformOrderGuideExportMaterialsInput(
    orderGuideState: OrderGuideState,
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    language: Language,
    isPrint: boolean,
  ): ExportMaterialsInput {
    const categories = Object.values(orderGuideState.records.ids).map(
      (categoryId) => orderGuideState.records.entities[categoryId],
    );
    const categorizedMaterials = this.transformCategorizedMaterials(
      materialInfoRecords,
      categories,
    );

    return GuidesTransformer.transformExportMaterialsInput(
      categorizedMaterials,
      materialInfoRecords,
      lastOrderedRecords,
      customerMaterialRecord,
      orderGuideState.sortBy,
      isPrint
        ? 'EXPORT_MODAL.PRINT_ORDER_GUIDE.TITLE'
        : 'EXPORT_MODAL.EXPORT_ORDER_GUIDE.TITLE',
      isPrint
        ? ExportFeatureType.ORDER_GUIDE_PRINT
        : ExportFeatureType.ORDER_GUIDE,
      this.orderGuideName,
      'Order guide',
      language,
    );
  }

  private static transformCategorizedMaterials(
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    categoryRecords: CategorizedMaterialsRecord[],
  ): CategorizedGuideMaterials[] {
    return categoryRecords.map((categoryRecord) => {
      return {
        categoryName: categoryRecord.categoryName,
        materialNumbers: categoryRecord.materialNumbers.filter(
          (materialNumber) =>
            MaterialInfoRecordStatus.Success ===
            materialInfoRecords[materialNumber]?.status,
        ),
        categoryType: categoryRecord.categoryType,
      };
    });
  }
}
