import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { mergeMap, Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MaterialRecommendationsService } from '../../../shared/services/material-recommendations/material-recommendations.service';
import { SmartCartRecommendationsActions } from './smart-cart-recommendations.actions';
import { catchError } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { selectCartMaterialNumbers } from '../cart/cart.selectors';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import { selectRemainingCriticalItemMaterials } from '../critical-items/critical-items.selector';
import { SharedActions } from '../shared/shared.actions';
import { selectSmartCartRecommendationsHasLoaded } from './smart-cart-recommendations.selectors';

@Injectable()
export class SmartCartRecommendationsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private materialRecommendationsService: MaterialRecommendationsService,
  ) {}

  loadSmartCartRecommendations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SmartCartRecommendationsActions.loadSmartCartRecommendations),
      concatLatestFrom(() => [
        this.store.select(selectCartMaterialNumbers),
        this.store.select(selectRemainingCriticalItemMaterials),
        this.store.select(selectSmartCartRecommendationsHasLoaded),
      ]),
      mergeMap(
        ([
          _,
          cartMaterialNumbers,
          criticalItemMaterials,
          smartCartRecommendationsHasLoaded,
        ]) => {
          if (smartCartRecommendationsHasLoaded) {
            return [
              SharedActions.noOperation(
                'loadSmartCartRecommendations$ smartCartRecommendationsHasLoaded is true',
              ),
            ];
          }

          const materials = [...cartMaterialNumbers, ...criticalItemMaterials];
          if (!materials.length) {
            return [
              SharedActions.noOperation(
                'loadSmartCartRecommendations$ materials empty',
              ),
            ];
          }
          return this.materialRecommendationsService
            .getSmartCartRecommendations({
              materialNumbers: materials,
            })
            .pipe(
              mergeMap((recommendations) => {
                const materialNumbers = recommendations.map(
                  (recommendation) => recommendation.product,
                );
                return [
                  MaterialPriceActions.loadMaterialPrices(materialNumbers),
                  SmartCartRecommendationsActions.getSmartCartRecommendationsSuccess(
                    recommendations,
                  ),
                ];
              }),
              catchError(() =>
                of(
                  SmartCartRecommendationsActions.getSmartCartRecommendationsError,
                ),
              ),
            );
        },
      ),
    );
  });
}
