import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DeliveryDatePickerAggregatorService } from './shared/delivery-date-picker-aggregator.service';
import {
  AvailableRouteDate,
  DeliveryDatePicker,
} from './shared/models/delivery-date-picker';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NaooAnalyticsManager } from '../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../analytics/analytics-event-info';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { CustomDialogService } from '../services/dialog/custom-dialog/custom-dialog.service';
import { BulkAddWorkflow } from '../bulk-add-modal/bulk-add-modal.component';

@Component({
  selector: 'naoo-delivery-date-picker-container',
  template: `
    <naoo-delivery-date-picker-content
      [deliveryDatePicker]="deliveryDatePicker$ | async"
      (routeDateSelected)="handleRouteDateSelected()"
      (saveRouteDate)="handleSaveRouteDate($event)"
      (justBrowsing)="handleJustBrowsing()"
      (closePicker)="handleClosePicker()"
    ></naoo-delivery-date-picker-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDatePickerContainerComponent implements OnInit, OnDestroy {
  deliveryDatePicker$: Observable<DeliveryDatePicker>;

  private readonly modalAnalyticsLabel = 'ship date modal';
  private readonly modalAnalyticsCategory = 'login';
  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<DeliveryDatePickerContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isFirstDisplay: boolean;
      isImportOrder: boolean;
      isPastCutoff: boolean;
      closeAction?: () => void;
    },
    private aggregatorService: DeliveryDatePickerAggregatorService,
    private cartFacade: CartFacade,
    private analytics: NaooAnalyticsManager,
    private customDialogService: CustomDialogService,
  ) {}

  ngOnInit() {
    this.deliveryDatePicker$ = this.aggregatorService.aggregateAndTransform(
      this.data.isFirstDisplay,
      this.data.isPastCutoff,
    );
    this.deliveryDatePicker$
      .pipe(
        filter(
          (deliveryDatePicker) =>
            deliveryDatePicker.content &&
            deliveryDatePicker.content.isRouteDateExpired,
        ),
        first(),
        tap(() => {
          this.trackExpiredRouteDate();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.data.closeAction?.();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleRouteDateSelected() {
    if (this.data.isFirstDisplay) {
      this.trackRouteDateSelected();
    }
  }

  handleSaveRouteDate(availableRouteDate: AvailableRouteDate) {
    if (this.data.isFirstDisplay) {
      this.trackRouteDateSaved();
    }

    this.cartFacade.updateRouteDate(
      availableRouteDate.routeDate,
      availableRouteDate.customerArrivalDate,
    );
    this.dialogRef.close();
    if (this.data.isImportOrder) {
      this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
    }
  }

  handleJustBrowsing() {
    this.trackJustBrowsing();
    this.dialogRef.close();
  }

  handleClosePicker() {
    this.dialogRef.close();
    if (this.data.isImportOrder) {
      this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
    }
  }

  private trackExpiredRouteDate() {
    const event: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'ship date',
      label: 'expired',
    };

    this.analytics.trackAnalyticsEvent(event);
  }

  private trackJustBrowsing() {
    const event: AnalyticsEventInfo = {
      action: 'just browsing',
      category: this.modalAnalyticsCategory,
      label: this.modalAnalyticsLabel,
    };

    this.analytics.trackAnalyticsEvent(event);
  }

  private trackRouteDateSelected() {
    const event: AnalyticsEventInfo = {
      action: 'changed',
      category: this.modalAnalyticsCategory,
      label: this.modalAnalyticsLabel,
    };

    this.analytics.trackAnalyticsEvent(event);
  }

  private trackRouteDateSaved() {
    const event: AnalyticsEventInfo = {
      action: 'save',
      category: this.modalAnalyticsCategory,
      label: this.modalAnalyticsLabel,
    };

    this.analytics.trackAnalyticsEvent(event);
  }
}
