import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ecommerceAnalyticsMaterialAdapter,
  EcommerceAnalyticsMaterialState,
  EcommerceAnalyticsState,
  MaterialRecommendationState,
  MaterialSponsoredSearchState,
} from './ecommerce-analytics.state';
import {
  CartPriceTotals,
  selectCartPriceTotals,
  selectCombinedPricingRecords,
  selectCurrency,
  selectTotalFromMaterialDetails,
} from '../material-price/material-price.selectors';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import { selectMaterialAdditionalInfoRecordStates } from '../material-additional-info/material-additional-info.selectors';
import { MaterialAdditionalInfoRecordState } from '../material-additional-info/material-additional-info.state';
import { MaterialAdditionalInfoRecord } from '../../services/material-additional-info/models/material-additional-infos-record';
import {
  selectAllCartMaterials,
  selectCartMaterialQuantities,
} from '../cart/cart.selectors';
import { MaterialQuantityDetail } from '../shared/shared';

import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { formatDate } from '../../../shared/utilities/date-utilities';
import {
  selectCartMaterialNumbersWithLimitedStockWarnings,
  selectLimitedStockWarnings,
} from '../material-warning/material-warning.selectors';
import { selectSponsoredSearchData } from '../search/search.selectors';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';
import { selectContextualRouteDate } from '../route-date/route-date.selectors';
import { Dictionary } from '@ngrx/entity';
import { MaterialWarningType } from '../material-warning/material-warning';

export interface BaseAnalyticInformation {
  materialRecords: Map<string, MaterialRecord>;
  materialPrices: Map<string, CombinedPricingRecord>;
  materialQuantities: MaterialQuantityDetail[];
  additionalInfos: Map<string, MaterialAdditionalInfoRecord>;
  sponsoredSearchAnalytics?: SponsoredSearchAnalytics;
  limitedStockWarnings: Map<string, MaterialWarningType>;
}

export interface MaterialAdjustmentAnalyticInformation
  extends BaseAnalyticInformation {
  value: number;
  currency: string;
}

export interface MaterialAnalyticInformation {
  materialNumbers: string[];
  combinedPricingMap: Map<string, CombinedPricingRecord>;
  additionalInfoMap: Map<string, MaterialAdditionalInfoRecord>;
  sponsoredSearchAnalytics: SponsoredSearchAnalytics;
}

export interface CartAnalyticInformation extends BaseAnalyticInformation {
  cartPriceTotals: CartPriceTotals;
  routeDate?: Date;
}

export interface LimitedStockPurchaseData {
  routeDate: string;
  materialInfoRecordStates: MaterialInfoRecordState[];
}

export interface SponsoredSearchAnalytics {
  allSponsoredSearchStatesMap: Map<string, MaterialSponsoredSearchState>;
  materialSearchStatesToPersist: MaterialSponsoredSearchState[];
}

const selectEcommerceAnalyticsFeature =
  createFeatureSelector<EcommerceAnalyticsState>('ecommerceAnalytics');

const selectMaterials = createSelector(
  selectEcommerceAnalyticsFeature,
  (ecommerceAnalytics) => ecommerceAnalytics.materials,
);

const selectEcommerceAnalyticsMaterials = createSelector(
  selectMaterials,
  ecommerceAnalyticsMaterialAdapter.getSelectors().selectAll,
);

export const selectMaterialRecommendations = createSelector(
  selectEcommerceAnalyticsMaterials,
  (ecommerceAnalyticsMaterials: EcommerceAnalyticsMaterialState[]) => {
    const materialRecommendations = new Map<
      string,
      MaterialRecommendationState
    >();
    ecommerceAnalyticsMaterials.forEach((material) => {
      materialRecommendations.set(
        material.materialNumber,
        material.recommendation,
      );
    });
    return materialRecommendations;
  },
);

export const selectMaterialAnalyticInformation = (materialNumbers: string[]) =>
  createSelector(
    selectCombinedPricingRecords(materialNumbers, true),
    selectMaterialAdditionalInfoRecordStates(materialNumbers),
    selectSponsoredSearchAnalytics(materialNumbers),
    (
      combinedPricingDictionary: Dictionary<CombinedPricingRecord>,
      additionalInfos: MaterialAdditionalInfoRecordState[],
      sponsoredSearchAnalytics: SponsoredSearchAnalytics,
    ): MaterialAnalyticInformation => {
      return {
        materialNumbers,
        combinedPricingMap: new Map(
          Object.values(combinedPricingDictionary).map((state) => [
            state?.materialNumber,
            state,
          ]),
        ),
        additionalInfoMap: new Map(
          additionalInfos.map((state) => [state?.materialNumber, state.record]),
        ),
        sponsoredSearchAnalytics,
      };
    },
  );

export const selectMaterialAdjustmentAnalyticInformation = (
  materialQuantities: MaterialQuantityDetail[],
  materialRecords: Map<string, MaterialRecord>,
  materialPrices: Map<string, CombinedPricingRecord>,
) => {
  const materialNumbers = materialQuantities.map(
    (detail) => detail.materialNumber,
  );
  return createSelector(
    selectMaterialAdditionalInfoRecordStates(materialNumbers),
    selectTotalFromMaterialDetails(materialQuantities, true),
    selectCurrency,
    selectSponsoredSearchAnalytics(materialNumbers),
    selectLimitedStockWarnings(materialNumbers),
    (
      additionalInfos: MaterialAdditionalInfoRecordState[],
      value: number,
      currency: string,
      sponsoredSearchAnalytics: SponsoredSearchAnalytics,
      limitedStockWarnings: Map<string, MaterialWarningType>,
    ): MaterialAdjustmentAnalyticInformation => {
      return {
        materialRecords,
        materialPrices,
        materialQuantities,
        additionalInfos: new Map(
          additionalInfos.map((info) => [info?.materialNumber, info?.record]),
        ),
        value,
        currency,
        sponsoredSearchAnalytics,
        limitedStockWarnings,
      };
    },
  );
};

export const selectCartAnalyticInformation = (
  materialNumbers: string[],
  materialRecords: Map<string, MaterialRecord>,
  materialPrices: Map<string, CombinedPricingRecord>,
) =>
  createSelector(
    selectCartMaterialQuantities,
    selectCartPriceTotals(true),
    selectMaterialAdditionalInfoRecordStates(materialNumbers),
    selectContextualRouteDate,
    selectSponsoredSearchAnalytics(materialNumbers),
    selectLimitedStockWarnings(materialNumbers),
    (
      materialQuantities: MaterialQuantityDetail[],
      cartPriceTotals: CartPriceTotals,
      additionalInfos: MaterialAdditionalInfoRecordState[],
      routeDate: Date,
      sponsoredSearchAnalytics: SponsoredSearchAnalytics,
      limitedStockWarnings: Map<string, MaterialWarningType>,
    ): CartAnalyticInformation => {
      return {
        materialRecords,
        materialPrices,
        materialQuantities,
        cartPriceTotals,
        additionalInfos: new Map(
          additionalInfos.map((info) => [info?.materialNumber, info?.record]),
        ),
        routeDate,
        sponsoredSearchAnalytics,
        limitedStockWarnings,
      };
    },
  );

export const selectLimitedStockPurchaseData = createSelector(
  selectCartMaterialNumbersWithLimitedStockWarnings,
  selectContextualRouteDate,
  selectAllMaterialInfoRecordStates,
  (materialNumbers, routeDate, materialRecords): LimitedStockPurchaseData => {
    return {
      routeDate: formatDate(routeDate),
      materialInfoRecordStates: materialNumbers.map(
        (materialNumber) => materialRecords[materialNumber],
      ),
    };
  },
);

export const selectAllMaterialSponsoredSearchStatesMap = createSelector(
  selectEcommerceAnalyticsFeature,
  (ecommerceState): Map<string, MaterialSponsoredSearchState> => {
    return new Map(
      Object.values(ecommerceState.materials.entities)
        .filter((state) => state.sponsoredSearch)
        .map((state) => [state?.materialNumber, state.sponsoredSearch]),
    );
  },
);

export const selectSponsoredSearchAnalytics = (materialNumbers: string[]) =>
  createSelector(
    selectSponsoredSearchData,
    selectAllMaterialSponsoredSearchStatesMap,
    selectAllCartMaterials,
    (
      sponsoredSearchData,
      allSponsoredSearchStatesMap,
      cartMaterials,
    ): SponsoredSearchAnalytics => {
      const cartMaterialNumbersToIgnore = cartMaterials
        .map((material) => material.materialNumber)
        .filter((materialNumber) =>
          allSponsoredSearchStatesMap.has(materialNumber),
        );

      const materialSearchStatesToPersist: MaterialSponsoredSearchState[] =
        materialNumbers
          .filter(
            (materialNumber) =>
              !cartMaterialNumbersToIgnore?.includes(materialNumber),
          )
          .map((materialNumber) => {
            const {
              hasBanners,
              hasMaterials,
              recommendationProducts,
              searchTerm,
              sponsoredMaterials,
            } = sponsoredSearchData;

            return {
              materialNumber,
              searchTerm,
              hasBanners,
              hasMaterials,
              campaignId:
                recommendationProducts.get(materialNumber)?.campaignTracking,
              sponsored: sponsoredMaterials.includes(materialNumber),
            };
          });

      return {
        allSponsoredSearchStatesMap: new Map([
          ...allSponsoredSearchStatesMap.entries(),
          ...new Map(
            materialSearchStatesToPersist.map((state) => [
              state?.materialNumber,
              state,
            ]),
          ).entries(),
        ]),
        materialSearchStatesToPersist,
      };
    },
  );

export const selectBehavioralAdvertising = createSelector(
  selectEcommerceAnalyticsFeature,
  (state: EcommerceAnalyticsState) => state.behavioralAdvertising,
);
