<div class="image-container">
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'CLOSE' | translate }}"
    cdkFocusInitial
  >
    <mat-icon svgIcon="clear_icon"></mat-icon>
  </button>
</div>
<div class="text-container">
  <h2 class="naoo-heading title">
    {{ 'CART_SUMMARY.QUICK_ADD.TITLE' | translate }}
  </h2>
  <div class="main-text">
    {{ 'CART_SUMMARY.QUICK_ADD.LEARN_MORE_HELPER_MODAL_TEXT' | translate }}
  </div>
</div>
