import { createReducer, on } from '@ngrx/store';
import {
  initialStorePurchaseHistoryRecordState,
  storePurchaseHistoryRecordAdapter,
  StorePurchaseHistoryRecordState,
  StorePurchaseHistoryState,
  StorePurchaseHistoryStateStatus,
} from './store-purchase-history.state';
import { StorePurchaseHistoryActions } from './store-purchase-history.actions';
import { StorePurchaseHistoryRecord } from '../../services/store-purchase-history/model/store-purchase-history-record';
import { createStorePurchaseEntityKey } from '../../../shared/utilities/store-purchase-utilities';

export const storePurchaseHistoryReducer = createReducer(
  initialStorePurchaseHistoryRecordState,
  on(
    StorePurchaseHistoryActions.loadStorePurchaseHistory,
    (state, action): StorePurchaseHistoryState =>
      loadPurchaseHistoryRecords(state, action),
  ),
  on(
    StorePurchaseHistoryActions.getStorePurchaseHistory,
    (state): StorePurchaseHistoryState => getPurchaseHistoryRecords(state),
  ),
  on(
    StorePurchaseHistoryActions.getStorePurchaseHistorySuccess,
    (state, action): StorePurchaseHistoryState =>
      insertPurchaseHistoryRecords(state, action.storePurchaseHistoryRecords),
  ),
  on(
    StorePurchaseHistoryActions.getStorePurchaseHistoryError,
    (state): StorePurchaseHistoryState => purchaseHistoryRecordsError(state),
  ),
  on(
    StorePurchaseHistoryActions.clearStorePurchaseHistory,
    (): StorePurchaseHistoryState => initialStorePurchaseHistoryRecordState,
  ),
);

function loadPurchaseHistoryRecords(
  state: StorePurchaseHistoryState,
  action: { startDate: string; endDate: string },
): StorePurchaseHistoryState {
  const hasDateChanged =
    state.loadedStartDate !== action.startDate ||
    state.loadedEndDate !== action.endDate;
  if (hasDateChanged) {
    return {
      ...state,
      records: storePurchaseHistoryRecordAdapter.getInitialState(),
      loadedStartDate: action.startDate,
      loadedEndDate: action.endDate,
      status: StorePurchaseHistoryStateStatus.Queued,
    };
  }
  return state;
}

function getPurchaseHistoryRecords(
  state: StorePurchaseHistoryState,
): StorePurchaseHistoryState {
  return {
    ...state,
    status: StorePurchaseHistoryStateStatus.Requested,
  };
}

function insertPurchaseHistoryRecords(
  state: StorePurchaseHistoryState,
  purchaseHistoryRecords: StorePurchaseHistoryRecord[],
): StorePurchaseHistoryState {
  const updatePurchaseHistoryStates: StorePurchaseHistoryRecordState[] =
    purchaseHistoryRecords.map((record) => {
      return {
        entityKey: createStorePurchaseEntityKey(record),
        record: record,
      };
    });
  return {
    ...state,
    records: storePurchaseHistoryRecordAdapter.upsertMany(
      updatePurchaseHistoryStates,
      state.records,
    ),
    status: StorePurchaseHistoryStateStatus.Success,
  };
}

function purchaseHistoryRecordsError(
  state: StorePurchaseHistoryState,
): StorePurchaseHistoryState {
  return {
    ...state,
    loadedStartDate: undefined,
    loadedEndDate: undefined,
    status: StorePurchaseHistoryStateStatus.Error,
  };
}
