import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialWarningType } from './material-warning';

export enum ChangedFieldType {
  Case = 'Case',
  Unit = 'Unit',
  Both = 'Both',
}

export interface ResetMaterialWarningRequest {
  materialNumber: string;
  changedField: ChangedFieldType;
}

export const MaterialWarningActions = createActionGroup({
  source: 'Material Warning',
  events: {
    'Reset Material Warning': (
      resetMaterialWarningRequest: ResetMaterialWarningRequest[],
    ) => ({ resetMaterialWarningRequest }),
    'Open Material Warning': (materialNumber: string) => ({ materialNumber }),
    'Close Material Warning': (materialNumber: string) => ({ materialNumber }),
    'Acknowledge Material Warning': (
      materialNumber: string,
      materialWarningType: MaterialWarningType,
    ) => ({ materialNumber, materialWarningType }),
    'Displayed Analytics Material Warning': (
      materialNumber: string,
      materialWarningType: MaterialWarningType,
    ) => ({ materialNumber, materialWarningType }),
    'Clear Material Warning': emptyProps(),
    'Toggle Hiding Warning': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Reset Toggle Hiding Warning': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
  },
});
