import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderGuideEffects } from './order-guide.effects';
import { orderGuideReducer } from './order-guide.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('orderGuide', orderGuideReducer),
    EffectsModule.forFeature([OrderGuideEffects]),
  ],
})
export class OrderGuideStoreModule {}
