import { createActionGroup, emptyProps } from '@ngrx/store';

export const OpenOrderItemsActions = createActionGroup({
  source: 'OpenOrderItems',
  events: {
    'Get Open Order Items': emptyProps(),
    'Get Open Order Items Success': (openOrderItems: string[]) => ({
      openOrderItems,
    }),
    'Get Open Order Items Error': emptyProps(),
    'Clear Open Order Items': emptyProps(),
    'Refresh Open Order Items': emptyProps(),
  },
});
