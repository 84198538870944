import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MaterialRelatedActions } from './material-related.actions';
import {
  selectHasMaterialRelated,
  selectMaterialRelated,
} from './material-related.selectors';
import { MaterialRelated } from './models/material-related';

@Injectable({ providedIn: 'root' })
export class MaterialRelatedFacade {
  constructor(private store: Store) {}

  loadMaterialRelated(materialNumbers: string[]): void {
    this.store.dispatch(
      MaterialRelatedActions.loadMaterialRelated(materialNumbers),
    );
  }

  hasMaterialRelated(materialNumber: string): Observable<boolean> {
    return this.store.select(selectHasMaterialRelated(materialNumber));
  }

  getMaterialRelated(materialNumber: string): Observable<MaterialRelated> {
    return this.store.select(selectMaterialRelated(materialNumber));
  }

  clearMaterialRelated(): void {
    this.store.dispatch(MaterialRelatedActions.clearMaterialRelated());
  }
}
