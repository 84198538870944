import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HelpModalExpansionSection } from '../generic-help-modal.component';

@Component({
  selector: 'naoo-generic-help-expansion-panel',
  templateUrl: './generic-help-expansion-panel.component.html',
  styleUrls: ['./generic-help-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericHelpExpansionPanelComponent {
  @Input() section: HelpModalExpansionSection;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
}
