import { WarningInitialValues } from './model/warning-initial-values';
import { Warning } from './model/warning';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { MaterialWarningType } from '../../store/material-warning/material-warning';

const REPEATING_DIGIT_WARNING_TITLE = 'PRODUCT.WARNING.REPEATING_DIGITS.TITLE';
const REPEATING_DIGIT_WARNING_MESSAGE =
  'PRODUCT.WARNING.REPEATING_DIGITS.MESSAGE';
const QUANTITY_THRESHOLD_WARNING_TITLE =
  'PRODUCT.WARNING.QUANTITY_THRESHOLD.TITLE';
const QUANTITY_THRESHOLD_WARNING_MESSAGE =
  'PRODUCT.WARNING.QUANTITY_THRESHOLD.MESSAGE';

export enum WarningActionMessage {
  DISMISS = 'PRODUCT.WARNING.DISMISS',
  REMOVE = 'PRODUCT.WARNING.REMOVE',
}

export function createAnalyticsEvent(
  action: string,
  category: string,
  label: string,
): AnalyticsEventInfo {
  return {
    action,
    category,
    label,
  };
}

export class GenericWarning implements Warning {
  readonly title: string;
  readonly message: string;
  readonly actionMessage?: WarningActionMessage;
  readonly urgent: boolean;
  readonly isCaseField?: boolean;
  readonly label: string;
  readonly type: MaterialWarningType;
  readonly offeringId: string;

  constructor(
    type: MaterialWarningType,
    initialValues: WarningInitialValues,
    private hasSentAnalytics = false,
  ) {
    this.type = type;
    this.offeringId = initialValues ? initialValues.offeringId : null;
  }

  getTitleParams(): object {
    return {};
  }

  getDisplayAnalyticsEvent(): AnalyticsEventInfo {
    if (this.hasSentAnalytics) {
      return undefined;
    }

    return {
      action: 'displayed',
      category: 'warning',
      label: this.label,
    };
  }

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return null;
  }

  getFixedAnalyticsEvent(): AnalyticsEventInfo {
    return null;
  }

  getMessageParams(): object {
    return {};
  }

  shouldRemoveFromCartOnDismiss(): boolean {
    return false;
  }
}

class RepeatingDigitWarning extends GenericWarning {
  readonly title = REPEATING_DIGIT_WARNING_TITLE;
  readonly message = REPEATING_DIGIT_WARNING_MESSAGE;
  readonly actionMessage = WarningActionMessage.DISMISS;
  readonly urgent = false;
  readonly label = `repeating digit message - ${this.offeringId}`;

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('dismissed', 'warning', this.label);
  }

  getFixedAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('fixed', 'warning', this.label);
  }
}

class RepeatingDigitCaseWarning extends RepeatingDigitWarning {
  readonly isCaseField = true;
}

class RepeatingDigitUnitWarning extends RepeatingDigitWarning {
  readonly isCaseField = false;
}

class NoStockWarning extends GenericWarning {
  readonly title = 'PRODUCT.WARNING.NO_STOCK.TITLE';
  readonly message = 'PRODUCT.WARNING.NO_STOCK.MESSAGE';
  readonly actionMessage = WarningActionMessage.REMOVE;
  readonly urgent = true;
  readonly isCaseField = true;
  readonly label = `no stock - ${this.offeringId}`;

  shouldRemoveFromCartOnDismiss(): boolean {
    return true;
  }

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent(
      'removed',
      'warning',
      `no stock - ${this.offeringId}`,
    );
  }
}

class QuantityThresholdExceededWarning extends GenericWarning {
  readonly title = QUANTITY_THRESHOLD_WARNING_TITLE;
  readonly message = QUANTITY_THRESHOLD_WARNING_MESSAGE;
  readonly actionMessage = WarningActionMessage.DISMISS;
  readonly urgent = false;
  readonly label = `large quantity message - ${this.offeringId}`;

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('dismissed', 'warning', this.label);
  }

  getFixedAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('fixed', 'warning', this.label);
  }
}

class QuantityThresholdExceededCaseWarning extends QuantityThresholdExceededWarning {
  readonly isCaseField = true;
}

class QuantityThresholdExceededUnitWarning extends QuantityThresholdExceededWarning {
  readonly isCaseField = false;
}

class UnitCaseConversionWarning extends GenericWarning {
  readonly title = 'PRODUCT.WARNING.UNIT_CASE_CONVERSION.TITLE';
  readonly message = 'PRODUCT.WARNING.UNIT_CASE_CONVERSION.MESSAGE';
  readonly actionMessage = WarningActionMessage.DISMISS;
  readonly urgent = false;
  readonly isCaseField = false;
  readonly label = `case unit conversion message - ${this.offeringId}`;
}

class PartialStockCaseUnitWarning extends GenericWarning {
  readonly title = 'PRODUCT.WARNING.PARTIAL_STOCK_CASE_UNIT.TITLE';
  readonly message = 'PRODUCT.WARNING.PARTIAL_STOCK_CASE_UNIT.MESSAGE';
  readonly actionMessage = WarningActionMessage.REMOVE;
  readonly urgent = true;
  readonly isCaseField = true;
  readonly label = `partial stock (case + unit) - ${this.offeringId}`;
  readonly numCases: number;
  readonly numUnits: number;

  constructor(
    type: MaterialWarningType,
    initialValues: WarningInitialValues,
    hasSentAnalytics = false,
  ) {
    super(type, initialValues, hasSentAnalytics);
    this.numCases = initialValues.numCases;
    this.numUnits = initialValues.numUnits;
  }

  shouldRemoveFromCartOnDismiss(): boolean {
    return true;
  }

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent(
      'removed',
      'warning',
      `partial stock - ${this.offeringId}`,
    );
  }

  getFixedAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent(
      'fixed',
      'warning',
      `partial stock - ${this.offeringId}`,
    );
  }

  getMessageParams(): object {
    return { numCases: this.numCases, numUnits: this.numUnits };
  }
}

class PartialStockCaseWarning extends GenericWarning {
  readonly title = 'PRODUCT.WARNING.PARTIAL_STOCK_CASE.TITLE';
  readonly message = 'PRODUCT.WARNING.PARTIAL_STOCK_CASE.MESSAGE';
  readonly actionMessage = WarningActionMessage.REMOVE;
  readonly urgent = true;
  readonly isCaseField = true;
  readonly label = `partial stock (case) - ${this.offeringId}`;
  readonly numCases: number;

  constructor(
    type: MaterialWarningType,
    initialValues: WarningInitialValues,
    hasSentAnalytics = false,
  ) {
    super(type, initialValues, hasSentAnalytics);
    this.numCases = initialValues.numCases;
  }

  shouldRemoveFromCartOnDismiss(): boolean {
    return true;
  }

  getDismissAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('removed', 'warning', this.label);
  }

  getFixedAnalyticsEvent(): AnalyticsEventInfo {
    return createAnalyticsEvent('fixed', 'warning', this.label);
  }

  getMessageParams(): object {
    return { numCases: this.numCases };
  }
}

export class WarningFactory {
  public static createWarning(
    type: MaterialWarningType,
    initialValues: WarningInitialValues,
    hasSentAnalytics?: boolean,
  ): Warning {
    switch (type) {
      case MaterialWarningType.NoStock:
        return new NoStockWarning(type, initialValues, hasSentAnalytics);
      case MaterialWarningType.UnitCaseConversion:
        return new UnitCaseConversionWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.PartialStockCaseUnit:
        return new PartialStockCaseUnitWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.PartialStockCase:
        return new PartialStockCaseWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.RepeatingDigitCase:
        return new RepeatingDigitCaseWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.RepeatingDigitUnit:
        return new RepeatingDigitUnitWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.QuantityThresholdExceededCase:
        return new QuantityThresholdExceededCaseWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
      case MaterialWarningType.QuantityThresholdExceededUnit:
        return new QuantityThresholdExceededUnitWarning(
          type,
          initialValues,
          hasSentAnalytics,
        );
    }
  }
}
