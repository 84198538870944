import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SessionFacade } from '../core/store/session/session.facade';

@Component({
  selector: 'naoo-offline-error',
  templateUrl: './offline-error.component.html',
  styleUrls: ['./offline-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineErrorComponent {
  isCustomerUnitSelected$ = this.sessionFacade.getLoadedActiveCustomer();

  constructor(private sessionFacade: SessionFacade) {}
}
