import {
  CustomerBrand,
  Language,
} from '../../services/session/models/session-record';
import {
  MaterialDetailsPageViewModel,
  MaterialDetailsSection,
} from './models/material-details.model';
import { CustomerMaterialInfo } from '../../services/customer-material/model/customer-material-info.model';
import { MaterialAdditionalInfoRecordState } from '../material-additional-info/material-additional-info.state';
import { getDimensions } from '../material-additional-info/material-additional-info.selectors';
import { sortBy } from 'lodash-es';
import { MaterialAttribute } from '../../../shared/models/material-additional-info';
import { ProductDetailsUtilities } from '../../../shared/utilities/product-details-utilities';
import { NutritionRecordState } from '../nutrition/nutrition.state';
import { buildMaterialAdditionalInfo } from '../material-additional-info/material-additional-info.util';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { MaterialRow } from '../material-row/models/material-row';
import {
  getGtinFromMaterialInfo,
  MaterialInfo,
} from '../../../shared/models/material-info';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { buildMaterialAvailability } from '../material-availability/material-availability.util';

export class MaterialDetailsTransformer {
  // eslint-disable-next-line max-params
  public static transformMaterialDetails(
    materialInfo: MaterialInfo,
    currentLanguage: Language,
    fulfillmentType: FulfillmentType,
    customerMaterialInfo: CustomerMaterialInfo,
    materialAdditionalInfoRecordState: MaterialAdditionalInfoRecordState,
    brand: CustomerBrand,
    nutritionLabelRecord: NutritionRecordState,
    materialRecommendations: MaterialRecommendations[],
    materialRow: MaterialRow,
    materialAvailabilityRecordState: MaterialAvailabilityRecordState,
    lastOrderDate: string,
    orderableComplimentaryMaterials: string[],
  ): MaterialDetailsPageViewModel {
    const materialAdditionalInfo = buildMaterialAdditionalInfo(
      materialAdditionalInfoRecordState?.record,
    );
    const dimensions = getDimensions(materialAdditionalInfoRecordState?.record);

    return {
      currentLanguage,
      customerMaterialNumber: customerMaterialInfo?.customerMaterialNumber,
      customerBrand: brand,
      cartFulfillmentType: fulfillmentType,
      displayableDimensionsCm: dimensions?.dimensionsCm,
      displayableDimensionsIn: dimensions?.dimensionsIn,
      isGfsUsCustomerBrand: CustomerBrand.GFS_US === brand,
      gtin: getGtinFromMaterialInfo(materialInfo),
      materialAdditionalInfo,
      materialAdditionalInfoList: this.createProductDetailsViewModel(),
      materialAttributes: this.getMaterialAttributes(
        materialAdditionalInfo?.materialAttributes,
        currentLanguage,
      ),
      materialAvailability: buildMaterialAvailability(
        materialAvailabilityRecordState,
      ),
      materialInfo: { ...materialInfo, lastOrderDate },
      materialMovementDetails: null, // merge outside transformer after
      materialRow,
      nutritionLabel: nutritionLabelRecord?.record,
      recommendedMaterials: materialRecommendations,
      orderableComplimentaryMaterials,
    };
  }

  private static getMaterialAttributes(
    attributes: MaterialAttribute[],
    currentLanguage: Language,
  ): MaterialAttribute[] {
    return attributes
      ? sortBy<MaterialAttribute>(attributes, [
          (attr) => {
            const localizedAttr = ProductDetailsUtilities.getLocalized(
              currentLanguage,
              attr.name,
            );
            return localizedAttr ? localizedAttr.toLowerCase() : undefined;
          },
        ]).filter((attr) => !!attr)
      : undefined;
  }

  private static createProductDetailsViewModel(): MaterialDetailsSection[] {
    return [
      MaterialDetailsSection.ServingSuggestions,
      MaterialDetailsSection.PreparationSuggestions,
      MaterialDetailsSection.ThawInstructions,
      MaterialDetailsSection.MaterialYield,
      MaterialDetailsSection.ShelfLife,
      MaterialDetailsSection.MaterialDimensions,
      MaterialDetailsSection.VendorInfo,
      MaterialDetailsSection.Ingredients,
      MaterialDetailsSection.NutritionFactsPrint,
      MaterialDetailsSection.NutritionFacts,
      MaterialDetailsSection.Allergens,
      MaterialDetailsSection.SchoolEquivalents,
      MaterialDetailsSection.VitaminsAndMinerals,
      MaterialDetailsSection.MaterialAttributes,
      MaterialDetailsSection.MaterialMovement,
      MaterialDetailsSection.ForMoreInformation,
      MaterialDetailsSection.MarketingTips,
    ];
  }
}
