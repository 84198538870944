import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrderMethodButtonContainerComponent } from './order-method-button-container/order-method-button-container.component';
import { OrderMethodStepContainerComponent } from './order-method-step-container/order-method-step-container.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FormsModule,
    MatIconModule,
    SharedModule,
  ],
  declarations: [
    OrderMethodButtonContainerComponent,
    OrderMethodStepContainerComponent,
  ],
  exports: [OrderMethodStepContainerComponent],
})
export class OrderMethodModalSharedModule {}
