import {
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
} from './material-availability.state';
import {
  MaterialAvailability,
  UnitAvailability,
} from '../../../shared/models/material-availability';
import { UnitAvailabilityRecord } from '../../services/material-availability/model/material-availabilities-record';

export function hasMaterialAvailabilityFinishedLoading(
  recordState: MaterialAvailabilityRecordState,
): boolean {
  return (
    !!recordState &&
    [
      MaterialAvailabilityRecordStatus.Error,
      MaterialAvailabilityRecordStatus.Success,
    ].includes(recordState.status)
  );
}

export function buildMaterialAvailability(
  recordState: MaterialAvailabilityRecordState,
): MaterialAvailability {
  const record = recordState?.record;
  if (!record) {
    return undefined;
  }
  const {
    materialNumber,
    cutoffCode,
    stockType,
    isContract,
    isLocal,
    isOrderable,
    salesStatus,
  } = record;
  return {
    materialNumber,
    cutoffCode,
    stockType,
    isContract,
    isLocal,
    isOrderable,
    salesStatus,
    units: buildUnits(recordState.record.units),
  };
}

function buildUnits(unitRecords: UnitAvailabilityRecord[]): UnitAvailability[] {
  return unitRecords.map((unitRecord) => {
    return {
      uom: unitRecord.uom,
      validationQty: unitRecord.validationQty,
    };
  });
}
