export function move<T>(
  array: T[],
  originalIndex: number,
  newIndex: number,
): T[] {
  const newArray = [...array];

  newArray.splice(newIndex, 0, newArray.splice(originalIndex, 1)[0]);
  return newArray;
}

export function chunkArray<T>(arr: T[], size: number): T[][] {
  const arrayChunks = [];
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    arrayChunks.push(chunk);
  }
  return arrayChunks;
}
