<header [ngClass]="{ sticky: stickyHeader, mobile: isMobile }">
  <div class="title">{{ title }}</div>
  <button
    *ngIf="showCloseButton"
    class="close-button"
    (click)="closeModal()"
    [attr.aria-label]="'CLOSE' | translate"
    #focusableButton
  >
    <img
      class="close-button-image"
      src="assets/images/close-icon.svg"
      alt="{{ 'CLOSE' | translate }}"
    />
  </button>
</header>
