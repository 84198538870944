<button
  #matMenuButton
  mat-icon-button
  [id]="materialRowOptions.materialNumber + '_OPTIONS'"
  (mousedown)="determineOpenDirection()"
  [matMenuTriggerFor]="addToGuideMenu.menu()"
  class="mat-icon-button"
  [ngClass]="{ mobile: isMobile, grid: verticalTripleDot }"
  [attr.aria-label]="'LISTS.MENU_OPTIONS' | translate"
>
  <mat-icon
    *ngIf="!verticalTripleDot"
    svgIcon="horizontal_triple_dot"
  ></mat-icon>
  <mat-icon *ngIf="verticalTripleDot" svgIcon="triple_dot_menu"></mat-icon>
</button>

<naoo-add-to-guide-menu
  #addToGuideMenu
  [isEmptyMaterial]="false"
  [isCommodityItem]="!!materialRowOptions.materialCommodityInfo"
  [isOrderGuideItem]="materialRowOptions.isOrderGuideItem"
  [materialNumber]="materialRowOptions.materialNumber"
  [menuTrigger]="menuButton"
  [additionalMenuItems]="additionalMenuItems"
  [yDirection]="openDirection"
  [isCriticalItemsGuide]="isCriticalItemsGuide"
  (menuActionSelected)="performMenuAction($event)"
>
</naoo-add-to-guide-menu>
