import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import {
  CustomerNotificationPreferencesRecord,
  NotificationRecord,
} from './models/customer-notification-preferences-record';

@Injectable({ providedIn: 'root' })
export class CustomerNotificationPreferencesService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getCustomerNotificationPreferences(): Observable<CustomerNotificationPreferencesRecord> {
    return this.httpClient.get<CustomerNotificationPreferencesRecord>(
      this.webBffService.getBff() + `/api/v1/customer/preference/notifications`,
    );
  }

  updateCustomerNotificationPreferences(
    updatedEmailNotifications: NotificationRecord[],
    updatedSmsNotifications: NotificationRecord[],
  ): Observable<CustomerNotificationPreferencesRecord> {
    return this.httpClient.put<CustomerNotificationPreferencesRecord>(
      this.webBffService.getBff() + `/api/v4/customer/preference/notifications`,
      {
        emailNotifications: updatedEmailNotifications,
        smsNotifications: updatedSmsNotifications,
      },
    );
  }
}
