import { Observable, of, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { NaooAnalyticsManager } from '../../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../analytics/analytics-event-info';
import { isPrintEvent } from '../../utilities/keyboard-event-utilities';

@Injectable({ providedIn: 'root' })
export class PrintService {
  static readonly PRINT_ANALYTICS_CATEGORY = 'general';
  static readonly PRINT_ANALYTICS_ACTION = 'print';
  static readonly CTRLP_PRINT_ANALYTICS_LABEL = 'ctrlp';
  static readonly PRINT_ICON_ANALYTICS_LABEL = 'print icon';

  beforePrint?: (() => Observable<boolean>) | null;
  afterPrint?: (() => void) | null;

  private triggerSubscription: Subscription;

  constructor(
    private analytics: NaooAnalyticsManager,
    private _window: Window,
  ) {
    this.onCtrlP = this.onCtrlP.bind(this);
  }

  /**
   * Registers event handlers on the native window object.
   *
   * Optionally sets up custom handlers for before and after printing (calling the native
   * window.print() function). The beforePrint handler, if provided, must return an
   * Observable<boolean> which will trigger the native print functionality once the source emits.
   * The afterPrint handler, if provided, will run after the native print functionality.
   *
   * @param {() => Observable<boolean>} beforePrint
   * @param {() => void} afterPrint
   */
  setUp(beforePrint?: () => Observable<boolean>, afterPrint?: () => void) {
    this._window.addEventListener('keydown', this.onCtrlP, true);
    this.beforePrint = beforePrint;
    this.afterPrint = afterPrint;

    this._window.addEventListener('beforeprint', this.handleBeforePrint, true);
  }

  /**
   * Unregisters event handlers on the native window object.
   *
   * IMPORTANT: Components using this service that called setUp() should call this function in
   * ngOnDestroy() to prevent event handlers registered in the setUp method from affecting other
   * components.
   */
  tearDown() {
    if (this.triggerSubscription) {
      this.triggerSubscription.unsubscribe();
    }

    this._window.removeEventListener('keydown', this.onCtrlP, true);
    this._window.removeEventListener(
      'beforeprint',
      this.handleBeforePrint,
      true,
    );

    this.beforePrint = null;
    this.afterPrint = null;
  }

  /**
   * Prints via native window.print() functionality.
   *
   * Optionally, if the beforePrint and afterPrint handlers have been registered through the
   * setUp() functionality, the call to window.print() will only be triggered when the beforePrint
   * source emits. The afterPrint handler will run after the call to window.print().
   */
  print() {
    const trigger$ = this.runBeforePrint();

    this.triggerSubscription = trigger$.subscribe(() => {
      // prevent freezing due to huge print jobs.
      // Enqueues anonymous print behavior to microtasks queue.
      setTimeout(() => {
        this.trackPrintEvent(PrintService.PRINT_ICON_ANALYTICS_LABEL);
        this.setupAfterPrint();
        this._window.print();
      }, 250);
    });
  }

  private readonly handleBeforePrint = () => {
    this.runBeforePrint();
    this.setupAfterPrint();
  };

  private runAfterPrint() {
    if (this.afterPrint) {
      this.afterPrint();
    }
  }

  private runBeforePrint() {
    if (this.beforePrint) {
      return this.beforePrint();
    }

    return of(true);
  }

  private setupAfterPrint() {
    const mediaQueryList = this._window.matchMedia('print');

    const onAfterPrint = () => {
      this._window.onfocus = null;

      if (!mediaQueryList.matches) {
        this.runAfterPrint();
        if (mediaQueryList.removeEventListener) {
          mediaQueryList.removeEventListener('change', onAfterPrint);
        } else {
          // eslint:disable-next-line:deprecation
          mediaQueryList.removeListener(onAfterPrint);
        }
      }
    };

    // emulate onbeforeprint/onafterprint
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', onAfterPrint);
    } else {
      // eslint:disable-next-line:deprecation
      mediaQueryList.addListener(onAfterPrint);
    }

    // if a user cancels printing in Safari's print confirmation dialog
    // then we will trigger a cleanup
    this._window.focus();
    this._window.onfocus = () => {
      onAfterPrint();
    };
  }

  private onCtrlP(event: KeyboardEvent) {
    if (
      isPrintEvent(event) &&
      (!this.triggerSubscription || this.triggerSubscription.closed)
    ) {
      this.trackPrintEvent(PrintService.CTRLP_PRINT_ANALYTICS_LABEL);
      this.print();
    }
  }

  private trackPrintEvent(label: string) {
    const eventInfo: AnalyticsEventInfo = {
      action: PrintService.PRINT_ANALYTICS_ACTION,
      category: PrintService.PRINT_ANALYTICS_CATEGORY,
      label: label,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
