import {
  materialPriceRecordEntityAdapter,
  materialPriceInitialState,
  MaterialPriceRecordStatus,
  MaterialPriceRecordState,
  MaterialPriceState,
} from './material-price.state';
import { MaterialPriceActions } from './material-price.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialPriceInfoRecord } from '../../services/material-price/material-price.service';

export const materialPriceReducer = createReducer(
  materialPriceInitialState,
  on(
    MaterialPriceActions.loadMaterialPrices,
    (state, action): MaterialPriceState =>
      loadPricesEntityState(state, action.materialNumbers),
  ),
  on(
    MaterialPriceActions.watchMaterialPrices,
    (state, action): MaterialPriceState => watchMaterialPrices(state, action),
  ),
  on(
    MaterialPriceActions.unwatchMaterialPrices,
    (state, action): MaterialPriceState => unwatchMaterialPrices(state, action),
  ),
  on(
    MaterialPriceActions.getMaterialPrices,
    (state, action): MaterialPriceState => getMaterialPrices(state, action),
  ),
  on(
    MaterialPriceActions.getMaterialPricesSuccess,
    (state, action): MaterialPriceState =>
      getMaterialPricesSuccess(state, action),
  ),
  on(
    MaterialPriceActions.getMaterialPricesError,
    (state, action): MaterialPriceState =>
      getMaterialPricesError(state, action),
  ),
  on(
    MaterialPriceActions.clearMaterialPrices,
    (): MaterialPriceState => materialPriceInitialState,
  ),
  on(
    MaterialPriceActions.refreshMaterialPrices,
    (state): MaterialPriceState => refreshMaterialPrices(state),
  ),
);

function loadPricesEntityState(
  state: MaterialPriceState,
  materialNumbers: string[],
): MaterialPriceState {
  const upsertedEntities: MaterialPriceRecordState[] = materialNumbers
    .filter((id) => {
      return (
        (!!id && !state.prices.entities[id]) ||
        MaterialPriceRecordStatus.Error === state.prices.entities[id]?.status
      );
    })
    .map((id) => {
      return {
        materialNumber: id,
        status: MaterialPriceRecordStatus.Queued,
        record: undefined,
      };
    });
  return {
    ...state,
    prices: materialPriceRecordEntityAdapter.upsertMany(
      upsertedEntities,
      state.prices,
    ),
  };
}

function watchMaterialPrices(
  state: MaterialPriceState,
  action: { materialNumbers: string[] },
): MaterialPriceState {
  const newWatchedIds = state.watchedMaterialNumbers
    .concat(action.materialNumbers)
    .filter((val, index, arr) => arr.indexOf(val) === index);
  return {
    ...state,
    watchedMaterialNumbers: newWatchedIds,
  };
}

function unwatchMaterialPrices(
  state: MaterialPriceState,
  action: { materialNumbers: string[] },
): MaterialPriceState {
  const newWatchedIds = state.watchedMaterialNumbers.filter(
    (id) => action.materialNumbers.indexOf(id) === -1,
  );
  return {
    ...state,
    watchedMaterialNumbers: newWatchedIds,
  };
}

function getMaterialPrices(
  state: MaterialPriceState,
  action: { materialNumbers: string[]; couponCodesToAdd: string[] },
): MaterialPriceState {
  const updatedEntities: Update<MaterialPriceRecordState>[] =
    action.materialNumbers
      .filter(
        (id) =>
          state.prices.entities[id] &&
          state.prices.entities[id].status === MaterialPriceRecordStatus.Queued,
      )
      .map((id) => {
        return {
          id: id,
          changes: {
            status: MaterialPriceRecordStatus.Requested,
          },
        };
      });
  return {
    ...state,
    prices: materialPriceRecordEntityAdapter.updateMany(
      updatedEntities,
      state.prices,
    ),
  };
}

function getMaterialPricesSuccess(
  state: MaterialPriceState,
  action: { materialPriceInfo: MaterialPriceInfoRecord },
): MaterialPriceState {
  const updatedMaterialRecordStates: Update<MaterialPriceRecordState>[] =
    action.materialPriceInfo.materialPrices.map((priceRecord) => {
      return {
        id: priceRecord.materialNumber,
        changes: {
          record: priceRecord,
          status: MaterialPriceRecordStatus.Success,
        },
      };
    });
  return {
    ...state,
    prices: materialPriceRecordEntityAdapter.updateMany(
      updatedMaterialRecordStates,
      state.prices,
    ),
    invalidCoupons: action.materialPriceInfo.invalidCoupons,
    currency: action.materialPriceInfo.currency,
  };
}

function getMaterialPricesError(
  state: MaterialPriceState,
  action: { materialNumbers: string[] },
): MaterialPriceState {
  const updatedEntities: Update<MaterialPriceRecordState>[] =
    action.materialNumbers.map((id) => {
      return {
        id: id,
        changes: {
          status: MaterialPriceRecordStatus.Error,
        },
      };
    });
  return {
    ...state,
    prices: materialPriceRecordEntityAdapter.updateMany(
      updatedEntities,
      state.prices,
    ),
  };
}

function refreshMaterialPrices(state: MaterialPriceState): MaterialPriceState {
  return {
    ...state,
    prices: materialPriceRecordEntityAdapter.removeAll(state.prices),
  };
}
