import { Injectable } from '@angular/core';
import { from, mergeMap, Observable, of, reduce } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { MaterialValidationResponse } from '../../models/material-validation-response';
import { MaterialInfo } from '../../models/material-info';
import { chunkArray } from '../../utilities/array-utilities';

@Injectable({ providedIn: 'root' })
export class MaterialValidationService {
  public static readonly MATERIAL_VALIDATION_API =
    '/api/v1/materials/validation';
  private materialValidationCache = new Map<string, MaterialInfo>();
  private readonly maxMaterialsPerRequest = 1000;

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  /**
   * Validates whether given material IDs are accessible. The material ID can be
   * either an material number or a gtin.
   *
   * This function returns an observable of Map<string, MaterialInfo>, where the
   * keys are the requested IDs (input parameter). If there are matching
   * materials, the first material will be selected. If no materials exist for a
   * requested ID, the value for the requested ID will be null.
   *
   * NOTE: The first material selected may not be deterministic, and depends on
   * the order of materials returned from the material validation api endpoint.
   *
   * @param materialIds - array of material numbers or gtin
   * @returns observable of map of requested ID to matching material
   */
  public validateMaterials(
    materialIds: string[],
  ): Observable<Map<string, MaterialInfo>> {
    const nonEmptyMaterials = materialIds.filter((id) => id !== '');
    const { cachedIds, nonCachedIds } = this.partition(nonEmptyMaterials);
    const cachedMaterials = this.getCacheSubset(cachedIds);
    if (nonCachedIds.length > 0) {
      return this.mergeBatchesOfResponses(nonCachedIds, cachedMaterials);
    } else {
      return of(cachedMaterials);
    }
  }

  private mergeBatchesOfResponses(
    nonCachedIds: string[],
    cachedMaterials: Map<string, MaterialInfo>,
  ): Observable<Map<string, MaterialInfo>> {
    const responses = this.getValidatedMaterials(nonCachedIds);

    return responses.pipe(
      reduce(
        (cachedMaterials, currentResponse) =>
          this.buildMaterialInfoMap(currentResponse, cachedMaterials),
        cachedMaterials,
      ),
    );
  }

  private getValidatedMaterials(
    nonCachedIds: string[],
  ): Observable<MaterialValidationResponse[]> {
    return from(chunkArray(nonCachedIds, this.maxMaterialsPerRequest)).pipe(
      mergeMap((chunk) => this.getValidatedResponse(chunk)),
      reduce((allResponses, response) => [...allResponses, response], []),
    );
  }

  private getValidatedResponse(
    records: string[],
  ): Observable<MaterialValidationResponse> {
    return this.httpClient.post<MaterialValidationResponse>(
      this.webBffService.getBff() +
        MaterialValidationService.MATERIAL_VALIDATION_API,
      records,
    );
  }

  private buildMaterialInfoMap(
    responses: MaterialValidationResponse[],
    cachedMaterials: Map<string, MaterialInfo>,
  ): Map<string, MaterialInfo> {
    return responses
      .map((response) => response.results)
      .reduce((acc, response) => {
        const currentMap = new Map<string, MaterialInfo>();
        Object.keys(response).forEach((id) => {
          const materialInfo = response[id]?.length ? response[id][0] : null;
          this.materialValidationCache.set(id, materialInfo);
          return currentMap.set(id, materialInfo);
        });
        return new Map([...acc.entries(), ...currentMap.entries()]);
      }, cachedMaterials);
  }

  /**
   * Clears cached material data.
   */
  public clearCache(): void {
    this.materialValidationCache.clear();
  }

  private getCacheSubset(materialIds: string[]): Map<string, MaterialInfo> {
    const subset = new Map<string, MaterialInfo>();

    materialIds.forEach((materialId) => {
      if (this.materialValidationCache.has(materialId)) {
        subset.set(materialId, this.materialValidationCache.get(materialId));
      }
    });

    return subset;
  }

  private partition(materialIds: string[]): {
    cachedIds: string[];
    nonCachedIds: string[];
  } {
    const allIds = new Set(materialIds);
    const cachedIds: string[] = [];
    const nonCachedIds: string[] = [];

    allIds.forEach((id) => {
      if (this.materialValidationCache.has(id)) {
        cachedIds.push(id);
      } else {
        nonCachedIds.push(id);
      }
    });

    return { cachedIds, nonCachedIds };
  }
}
