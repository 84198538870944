<mat-progress-bar mode="indeterminate"></mat-progress-bar>

<div class="container">
  <mat-icon
    svgIcon="copy-custom-guide-progress"
    [attr.aria-label]="'COPY_GUIDE_ACTION.IMAGE_ALT' | translate"
    class="image"
  ></mat-icon>
  <h2 class="title">
    {{
      'COPY_GUIDE_ACTION.TITLE' | translate: { guideName: nameOfGuideToCopy }
    }}
  </h2>
  <div class="sub-title">
    {{ subtitle | translate: { count: customersToCopyTo?.length } }}
  </div>
</div>
