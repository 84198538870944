<div *ngIf="inventory && materialAvailability">
  <div
    *ngIf="!!portionPricingPerBaseUom && !!portionPricingUom?.descriptions"
    class="inventory-row"
  >
    <div class="portion-pricing-label">
      {{ 'PRODUCT_DETAILS.PORTION_PER_CASE' | translate }}
    </div>
    <div class="portion-pricing-value">
      {{
        'PRODUCT_DETAILS.PORTION_CASE_VALUE'
          | translate
            : {
                portionPerCase: portionPricingPerBaseUom,
                portionPerCaseUom:
                  portionPricingUomDescription | stringdefaulter,
              }
      }}
    </div>
  </div>
  <div *ngIf="!shouldNotDisplayInventoryData">
    <div class="inventory-row">
      <div class="inventory-label">
        {{
          !!materialInfoAvailabilityDisplayCode
            ? ('INVENTORY.CURRENT'
              | translate
                : {
                    currentUom:
                      { uomCode: materialInfoAvailabilityDisplayCode }
                      | materialunits,
                  })
            : ('INVENTORY.CURRENT_UNSPECIFIED' | translate)
        }}
      </div>
      <div class="inventory-value">
        {{
          getUnitsAvailable(materialInfoAvailabilityUom) !== 0
            ? getUnitsAvailable(materialInfoAvailabilityUom)
            : '-'
        }}
      </div>
    </div>
    <div class="inventory-row">
      <div class="inventory-label">
        {{ 'INVENTORY.EST_IN_STOCK_DATE' | translate }}
      </div>
      <div class="inventory-value">
        {{
          getEstInStockDate()
            ? (getEstInStockDate() | naooDate: 'shortDate' : '')
            : '-'
        }}
      </div>
    </div>
    <div class="inventory-row">
      <div class="inventory-label">
        {{ 'INVENTORY.TOTAL_ON_ORDER' | translate }}
      </div>

      <div class="inventory-value">
        {{
          getTotalOnOrder() !== 0
            ? (getTotalOnOrder() | naoodecimal: '1.0-2')
            : '-'
        }}
      </div>
    </div>
  </div>
  <div class="inventory-row"></div>
</div>
