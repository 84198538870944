import { MaterialWarning, MaterialWarningType } from './material-warning';
import { Store } from '@ngrx/store';
import { MaterialWarningActions } from './material-warning.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { selectWarning } from './material-warning.selectors';

@Injectable({ providedIn: 'root' })
export class MaterialWarningFacade {
  constructor(private store: Store) {}

  getMaterialWarning(materialNumber: string): Observable<MaterialWarning> {
    return this.store.select(selectWarning(materialNumber));
  }

  acknowledgeWarning(materialNumber: string, warningType: MaterialWarningType) {
    this.store.dispatch(
      MaterialWarningActions.acknowledgeMaterialWarning(
        materialNumber,
        warningType,
      ),
    );
  }

  displayedAnalytics(materialNumber: string, warningType: MaterialWarningType) {
    this.store.dispatch(
      MaterialWarningActions.displayedAnalyticsMaterialWarning(
        materialNumber,
        warningType,
      ),
    );
  }

  openWarning(materialNumber: string) {
    this.store.dispatch(
      MaterialWarningActions.openMaterialWarning(materialNumber),
    );
  }

  toggleWarning(materialNumbers: string[]) {
    this.store.dispatch(
      MaterialWarningActions.toggleHidingWarning(materialNumbers),
    );
  }

  resetToggleWarning(materialNumbers: string[]) {
    this.store.dispatch(
      MaterialWarningActions.resetToggleHidingWarning(materialNumbers),
    );
  }

  closeWarning(materialNumber: string) {
    this.store.dispatch(
      MaterialWarningActions.closeMaterialWarning(materialNumber),
    );
  }
}
