import { NaooConstants } from '../NaooConstants';

export class DoubleTapDetector {
  private lastTapTime: number = new Date().getTime();

  suppressDoubleTap($event: MouseEvent, tapInterval?: number): boolean {
    const suppressEvent = tapInterval
      ? this.isDoubleTap(tapInterval)
      : this.isDoubleTap();
    if (suppressEvent) {
      $event.preventDefault();
    }
    this.lastTapTime = new Date().getTime();
    return suppressEvent;
  }

  private isDoubleTap(tapInterval?: number): boolean {
    const currentTime = new Date().getTime();
    return (
      currentTime - this.lastTapTime <
      (tapInterval ? tapInterval : NaooConstants.DOUBLE_TAP_INTERVAL)
    );
  }
}
