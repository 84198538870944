<div
  [ngClass]="{ invisible: !showWarning }"
  class="warning"
  [@displayWarning]="warningAnimationState"
  (@displayWarning.done)="animationComplete()"
>
  <naoo-warning-alert
    [isArrowVisible]="false"
    [warning]="copyWarning"
    (dismiss)="gotIt()"
  ></naoo-warning-alert>
</div>
<div class="multi-unit-section">
  <div class="select-header">{{ 'COPY_GUIDE.SELECT_HEADER' | translate }}</div>
  <naoo-multi-customer-select
    [customerUnits]="customerUnits$ | async"
    [options]="copyCustomSelectOption"
    (selectedCustomers)="selectCustomer($event)"
  >
  </naoo-multi-customer-select>
</div>
<div class="button-container">
  <button
    class="secondary-button large-button copy-guide-cancel"
    (click)="cancel.emit()"
  >
    {{ 'COPY_GUIDE.CANCEL' | translate }}
  </button>
  <button
    class="primary-button large-button copy-guide-continue"
    [disabled]="!enableCopyCustomGuide"
    (click)="copyToSelectedUnits()"
  >
    {{ 'COPY_GUIDE.COPY_CUSTOM_GUIDE' | translate }}
  </button>
</div>
