import { NaooConstants } from '../NaooConstants';

export interface NaooRequestConfiguration {
  readonly shouldShowConnectivityModal: boolean;
  readonly shouldRetry: boolean;
  readonly maxRetryCount: number;
  readonly timeout: number;
}

export const silentRequestConfiguration: NaooRequestConfiguration = {
  shouldShowConnectivityModal: false,
  shouldRetry: true,
  maxRetryCount: NaooConstants.SERVICE_RETRY_MAX_ATTEMPT,
  timeout: NaooConstants.RETRY_HTTP_REQUEST_TIMEOUT,
};

export const silentNoRetryRequestConfiguration: NaooRequestConfiguration = {
  shouldShowConnectivityModal: false,
  shouldRetry: false,
  maxRetryCount: 0,
  timeout: NaooConstants.RETRY_HTTP_REQUEST_TIMEOUT,
};

export const defaultRequestConfiguration: NaooRequestConfiguration = {
  shouldShowConnectivityModal: true,
  shouldRetry: true,
  maxRetryCount: NaooConstants.SERVICE_RETRY_MAX_ATTEMPT,
  timeout: NaooConstants.RETRY_HTTP_REQUEST_TIMEOUT,
};
