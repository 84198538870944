import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';

@Component({
  selector: 'naoo-search-button-mobile',
  templateUrl: './search-button-mobile.component.html',
  styleUrls: ['./search-button-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchButtonMobileComponent {
  @Input() isDisabled: boolean;
  constructor(public customDialogService: CustomDialogService) {}

  openMobileSearchModal() {
    this.customDialogService.openMobileSearchModal();
  }
}
