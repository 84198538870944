import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ExpressSchedulesService } from '../../services/express-schedules/express-schedules.service';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ExpressSchedulesStatus } from './express-schedules.state';
import { selectExpressSchedulesStatus } from './express-schedules.selectors';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { ExpressSchedulesActions } from './express-schedules.actions';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class ExpressSchedulesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private expressSchedulesService: ExpressSchedulesService,
  ) {}

  loadExpressSchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpressSchedulesActions.loadExpressSchedules),
      concatLatestFrom(() => this.store.select(selectExpressSchedulesStatus)),
      mergeMap(([_, status]) => {
        return ExpressSchedulesStatus.Queued === status
          ? of(ExpressSchedulesActions.getExpressSchedules())
          : of(
              SharedActions.noOperation(
                'Already loading express schedules data',
              ),
            );
      }),
    );
  });

  getExpressSchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpressSchedulesActions.getExpressSchedules),
      concatLatestFrom(() => [
        this.store.select(selectExpressSchedulesStatus),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.ExpressDelivery),
        ),
      ]),
      mergeMap(([_, status, hasExpressDelivery]) => {
        if (!hasExpressDelivery) {
          return of(ExpressSchedulesActions.getExpressSchedulesFailure());
        }
        if (ExpressSchedulesStatus.Requested !== status) {
          return of(
            SharedActions.noOperation(
              'Already requested express schedules data',
            ),
          );
        }
        return this.expressSchedulesService.getExpressSchedules().pipe(
          map((expressSchedules) =>
            ExpressSchedulesActions.getExpressSchedulesSuccess(
              expressSchedules,
            ),
          ),
          catchError(() =>
            of(ExpressSchedulesActions.getExpressSchedulesFailure()),
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(ExpressSchedulesActions.refreshExpressSchedules),
            ),
          ),
        );
      }),
    );
  });

  refreshExpressSchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpressSchedulesActions.refreshExpressSchedules),
      map(() => ExpressSchedulesActions.loadExpressSchedules()),
    );
  });
}
