import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomerBrand } from '../../core/services/session/models/session-record';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  useFavicon(customerBrand: CustomerBrand) {
    const favicon: HTMLElement = this._document.getElementById('favicon');
    const manifest: HTMLElement = this._document.getElementById('manifest');
    if (customerBrand === CustomerBrand.United) {
      this.setAttribute(
        favicon,
        'href',
        '/assets/images/favicon/urs-favicon-96x96-2.png',
      );
      this.setAttribute(manifest, 'href', 'assets/united-manifest.json');
    } else {
      this.setAttribute(
        favicon,
        'href',
        '/assets/images/favicon/favicon-96x96.png',
      );
      this.setAttribute(manifest, 'href', 'assets/manifest.json');
    }
  }

  private setAttribute(
    htmlElement: HTMLElement,
    qualifiedName: string,
    value: string,
  ) {
    if (htmlElement) {
      htmlElement.setAttribute(qualifiedName, value);
    }
  }
}
