import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'naoo-file-error',
  templateUrl: './file-error.component.html',
  styleUrls: ['./file-error.component.scss'],
})
export class FileErrorComponent {
  @Input() fileError: string;
  @Input() fileName: string;
  @Input() csvTemplate: string;
  @Input() excelTemplate: string;
  @Input() fileRequirements: string[];
  @Input() errorImage: string;
  @Output() startOver = new EventEmitter();
  @Output() templateDownload = new EventEmitter<string>();

  onStartOver() {
    this.startOver.emit();
  }

  onTemplateDownload(type: string) {
    this.templateDownload.emit(type);
  }
}
