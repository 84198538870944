import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import LatLngLiteral = google.maps.LatLngLiteral;
import Autocomplete = google.maps.places.Autocomplete;

@Component({
  selector: 'naoo-google-autocomplete',
  template: `
    <label>
      {{ 'ORDER_METHOD_MODAL.LOCATION.LABEL' | translate }}
      <input
        #addresstext
        class="input"
        type="text"
        [placeholder]="'SELECT_ORDER_METHOD.SELECT_LOCATION_INPUT' | translate"
      />
    </label>
  `,
  styleUrls: ['./google-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAutocompleteNaooComponent implements AfterViewInit {
  @Output()
  selectedLatLongEmitter: EventEmitter<LatLngLiteral> = new EventEmitter();

  @ViewChild('addresstext') addresstext: any;

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
      },
    );
    google.maps.event.addListener(
      autocomplete,
      'place_changed',
      this.emitLatLong(autocomplete),
    );
  }

  emitLatLong(autocomplete: Autocomplete) {
    return () => {
      const location = autocomplete.getPlace()?.geometry?.location;
      if (location) {
        const latLong: LatLngLiteral = {
          lat: location.lat(),
          lng: location.lng(),
        };
        this.selectedLatLongEmitter.emit(latLong);
      }
    };
  }
}
