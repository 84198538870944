export interface NutritionTableStructure {
  key: string;
  displayLabel: string;
  subsections?: NutritionTableStructure[];
}

export const tableStructure: {
  nutrition: NutritionTableStructure[];
  vitamins: NutritionTableStructure[];
} = {
  nutrition: [
    {
      key: 'totalFat',
      displayLabel: 'NUTRITION.TOTAL_FAT',
      subsections: [
        {
          key: 'saturatedFat',
          displayLabel: 'NUTRITION.SATURATED_FAT',
        },
        {
          key: 'transFat',
          displayLabel: 'NUTRITION.TRANS_FAT',
        },
        {
          key: 'polyunsaturatedFat',
          displayLabel: 'NUTRITION.POLY_UNSATURATED_FATS',
        },
        {
          key: 'omega6FattyAcids',
          displayLabel: 'NUTRITION.OMEGA6_FATTY_ACIDS',
        },
        {
          key: 'omega3FattyAcids',
          displayLabel: 'NUTRITION.OMEGA3_FATTY_ACIDS',
        },
        {
          key: 'monounsaturatedFat',
          displayLabel: 'NUTRITION.MONO_UNSATURATED_FATS',
        },
      ],
    },
    {
      key: 'cholesterol',
      displayLabel: 'NUTRITION.CHOLESTEROL',
    },
    {
      key: 'sodium',
      displayLabel: 'NUTRITION.SODIUM',
    },
    {
      key: 'potassium',
      displayLabel: 'NUTRITION.POTASSIUM',
    },
    {
      key: 'carbohydrates',
      displayLabel: 'NUTRITION.CARBOHYDRATES',
      subsections: [
        {
          key: 'fiber',
          displayLabel: 'NUTRITION.FIBER',
        },
        {
          key: 'sugars',
          displayLabel: 'NUTRITION.SUGARS',
        },
      ],
    },
    {
      key: 'protein',
      displayLabel: 'NUTRITION.PROTEIN',
    },
  ],
  vitamins: [
    {
      key: 'vitaminA',
      displayLabel: 'NUTRITION.VITAMIN_A',
    },
    {
      key: 'vitaminC',
      displayLabel: 'NUTRITION.VITAMIN_C',
    },
    {
      key: 'calcium',
      displayLabel: 'NUTRITION.CALCIUM',
    },
    {
      key: 'iron',
      displayLabel: 'NUTRITION.IRON',
    },
  ],
};
