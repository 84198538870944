<mat-spinner
  [diameter]="diameter"
  [strokeWidth]="strokeWidth"
  [mode]="'determinate'"
  [value]="progressValue"
></mat-spinner>
<div class="text-container">
  <span class="text-value">
    {{ progressMessageKey | translate }}
  </span>
  <div [ngClass]="{ ellipsis: isStillLoading }"></div>
</div>
