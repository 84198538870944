import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OpenSpecialOrderItemsState } from './open-special-order-items.state';

const selectOpenSpecialOrderItemsState =
  createFeatureSelector<OpenSpecialOrderItemsState>('openSpecialOrderItems');

export const selectOpenSpecialOrderItemMaterialNumbers = createSelector(
  selectOpenSpecialOrderItemsState,
  (state) => state.openSpecialOrderMaterialNumbers,
);

export const selectHasOpenSpecialOrder = (materialNumber: string) =>
  createSelector(
    selectOpenSpecialOrderItemMaterialNumbers,
    (openSpecialOrderMaterialNumbers) =>
      openSpecialOrderMaterialNumbers?.includes(materialNumber),
  );
