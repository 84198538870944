import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  deliveryScheduleAdapter,
  DeliveryScheduleState,
} from './delivery-schedule.state';
import { selectFulfillmentType, selectRouteDate } from '../cart/cart.selectors';
import { DeliverySchedule } from '../../../shared/models/delivery-schedule';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { selectAreCutoffsLoaded } from '../material-cutoff/material-cutoff.selectors';

const selectDeliveryScheduleState =
  createFeatureSelector<DeliveryScheduleState>('deliverySchedules');

export const selectHasDeliverySchedulesFinishedLoading = createSelector(
  selectDeliveryScheduleState,
  (state) => state.hasLoaded,
);

const selectDeliverySchedules = createSelector(
  selectDeliveryScheduleState,
  (deliveryScheduleState) => deliveryScheduleState.deliverySchedules,
);

const selectEntities = createSelector(
  selectDeliverySchedules,
  deliveryScheduleAdapter.getSelectors().selectEntities,
);

const selectAll = createSelector(
  selectDeliverySchedules,
  deliveryScheduleAdapter.getSelectors().selectAll,
);

export const selectAllDeliveryScheduleRecords = createSelector(
  selectHasDeliverySchedulesFinishedLoading,
  selectAll,
  (hasLoaded, deliveryScheduleRecords) => {
    return hasLoaded ? deliveryScheduleRecords : undefined;
  },
);

export const selectCurrentDeliverySchedule = createSelector(
  selectRouteDate,
  selectEntities,
  (shipDate, deliveryScheduleRecords) => {
    return shipDate
      ? deliveryScheduleRecords[DeliverySchedule.dateString(shipDate)]
      : undefined;
  },
);

export const selectRouteDateExpired = createSelector(
  selectRouteDate,
  selectCurrentDeliverySchedule,
  selectHasDeliverySchedulesFinishedLoading,
  (shipDate, currentDeliverySchedule, hasLoaded): boolean | undefined => {
    return hasLoaded ? !!shipDate && !currentDeliverySchedule : undefined;
  },
);

export const selectCurrentOrFirstAvailableDeliverySchedule = createSelector(
  selectCurrentDeliverySchedule,
  selectAllDeliveryScheduleRecords,
  (currentDeliverySchedule, deliverySchedules) =>
    currentDeliverySchedule || deliverySchedules?.[0],
);

export const selectHasSubmittableDeliveryFulfillment = createSelector(
  selectDeliveryScheduleState,
  selectFulfillmentType,
  selectCurrentDeliverySchedule,
  selectAreCutoffsLoaded,
  (state, fulfillmentType, currentDeliverySchedule, areCutoffsLoaded) =>
    FulfillmentType.TRUCK === fulfillmentType &&
    ((!!currentDeliverySchedule && areCutoffsLoaded) ||
      (state?.hasLoaded && state?.hasErrored)),
);
