import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import fr from 'src/assets/i18n/fr.json';
import es from 'src/assets/i18n/es.json';
import en from 'src/assets/i18n/en.json';
import { Language } from '../../core/services/session/models/session-record';

export class InlineTranslateLoader implements TranslateLoader {
  getTranslation(language: Language): Observable<any> {
    switch (language) {
      case Language.fr:
        return of(fr);
      case Language.es:
        return of(es);
      case Language.en:
        return of(en);
      default:
        return of({}); // fallback to DefaultLang as defined by our app.component
    }
  }
}
