import { createEntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { PickupScheduleRecord } from '../../services/pickup-schedules/models/pickup-schedule-record';

export interface PickupSchedulesState
  extends EntityState<PickupSchedulesRecordState> {}

export interface PickupSchedulesRecordState {
  storePlantId: string;
  pickupScheduleEntityState: EntityState<PickupScheduleRecord>;
  status: PickupSchedulesStatus;
}

export enum PickupSchedulesStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const pickupScheduleStateAdapter =
  createEntityAdapter<PickupSchedulesRecordState>({
    selectId: (state) => state.storePlantId,
  });

export const pickupScheduleStateInitialState =
  pickupScheduleStateAdapter.getInitialState();

export const pickupSchedulesAdapter = createEntityAdapter<PickupScheduleRecord>(
  {
    selectId: (state) => state.date,
  },
);

export const pickupSchedulesInitialState =
  pickupSchedulesAdapter.getInitialState();
