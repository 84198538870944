import { OpenSpecialOrderItemsActions } from './open-special-order-items.actions';
import {
  initialOpenSpecialOrderItemsState,
  OpenSpecialOrderItemsState,
} from './open-special-order-items.state';
import { createReducer, on } from '@ngrx/store';

export const openSpecialOrderItemsReducer = createReducer(
  initialOpenSpecialOrderItemsState,
  on(
    OpenSpecialOrderItemsActions.getOpenSpecialOrderItemsSuccess,
    (state, action): OpenSpecialOrderItemsState => ({
      ...state,
      openSpecialOrderMaterialNumbers: action.openSpecialOrderItemsResponse
        ? action.openSpecialOrderItemsResponse.openSpecialOrderMaterialNumbers
        : [],
    }),
  ),
  on(
    OpenSpecialOrderItemsActions.getOpenSpecialOrderItemsError,
    (state): OpenSpecialOrderItemsState => ({
      ...state,
      openSpecialOrderMaterialNumbers: [],
    }),
  ),
  on(
    OpenSpecialOrderItemsActions.refreshOpenSpecialOrderItems,
    (): OpenSpecialOrderItemsState => initialOpenSpecialOrderItemsState,
  ),
);
