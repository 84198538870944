import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NaooLogger } from '../logger/NaooLogger.service';
import { DefaultDialogService } from '../services/dialog/default-dialog/default-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NaooErrorUtils } from './NaooErrorUtils';

@Injectable({ providedIn: 'root' })
export class NaooErrorHandler implements ErrorHandler {
  constructor(
    private logger: NaooLogger,
    private injector: Injector,
  ) {}

  /**
   * Captures Angular errors and reports to our endpoint
   * @param error
   */
  handleError(error: HttpErrorResponse): void {
    if (this.isOfflineError(error)) {
      return;
    } else if (this.isIconError(error)) {
      this.logger.warn(`Unable to load icon: ${error}`);
      return;
    } else {
      this.logger.error(error);
      this.injector
        .get<DefaultDialogService>(DefaultDialogService)
        .defaultErrorModal(error.toString());
    }
  }

  private isOfflineError(error: HttpErrorResponse): boolean {
    return (
      error instanceof HttpErrorResponse &&
      error.status === NaooErrorUtils.offlineErrorCode
    );
  }

  private isIconError(error: HttpErrorResponse): boolean {
    if (error && error.message) {
      const lowercaseMessage = error.message.toLocaleLowerCase();
      return lowercaseMessage.includes('error retrieving icon');
    } else {
      return false;
    }
  }
}
