import { createActionGroup, emptyProps } from '@ngrx/store';
import { SpecialOrder } from './special-orders.state';

export const SpecialOrdersActions = createActionGroup({
  source: 'SpecialOrders',
  events: {
    'Get Special Orders': emptyProps(),
    'Get Special Orders Success': (specialOrders: SpecialOrder[]) => ({
      specialOrders,
    }),
    'Get Special Orders Error': emptyProps(),
    'Refresh Special Orders': emptyProps(),
  },
});
