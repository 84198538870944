import { ExportDialogFileFormat } from '../../modals/export-modal/export-modal.component';

export enum ExportDataPoint {
  Brand = 'BRAND',
  Unit = 'UNIT',
  Gtin = 'GTIN',
  ItemDescription = 'ITEM_DESCRIPTION',
  ProductDescription = 'PRODUCT_DESCRIPTION',
  ItemNumber = 'ITEM_NUMBER',
  ItemCode = 'ITEM_CODE',
  Pack = 'PACK',
  Price = 'PRICE',
  PriceUom = 'PRICE_UOM',
  Total = 'TOTAL',
  CasePrice = 'CASE_PRICE',
  ExtendedPrice = 'PRICE_EXTENDED',
  QuantityOrdered = 'QUANTITY_ORDERED',
  QuantityShipped = 'QUANTITY_SHIPPED',
  Size = 'SIZE',
  Category = 'CATEGORY',
  UnitSize = 'UNIT_SIZE',
  PackSize = 'PACK_SIZE',
  PackUom = 'PACK_UOM',
  PackUnit = 'PACK_UNIT',
  CatchWeight = 'CATCH_WEIGHT',
  CasePriceUom = 'CASE_PRICE_UOM',
  UnitPrice = 'UNIT_PRICE',
  UnitPriceUom = 'UNIT_PRICE_UOM',
  CustomerMaterialNumber = 'CUSTOMER_MATERIAL_NUMBER',
  ParQty = 'PAR_QTY',
  InventoryQty = 'INVENTORY_QTY',
  CustomCategory = 'CUSTOM_CATEGORY',
  NetWeight = 'NET_WEIGHT',
  VendorItemCode = 'VENDOR_ITEM_CODE',
  EarlyCutoffFlag = 'EARLY_CUTOFF_FLAG',
  SpecialOrderFlag = 'SPECIAL_ORDER_FLAG',
  DropShipFlag = 'DROP_SHIP_FLAG',
  ContractItemFlag = 'CONTRACT_ITEM_FLAG',
  StorageArea = 'STORAGE_AREA',
  PurchaseUnit = 'PURCHASE_UNIT',
  TotalCatchWeightActualWeight = 'TOTAL_CATCH_WEIGHT_ACTUAL_WEIGHT',
  Qt1 = 'QTY_1',
  Qt2 = 'QTY_2',
  Qt3 = 'QTY_3',
  Qt4 = 'QTY_4',
  Qt5 = 'QTY_5',
  Qt6 = 'QTY_6',
  Qt7 = 'QTY_7',
  Qt8 = 'QTY_8',
  Qt9 = 'QTY_9',
  Qt10 = 'QTY_10',
}

export enum ExportFileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  PDF = 'PDF',
}

export enum ExportFeatureType {
  INVOICE_DETAILS = 'INVOICE_DETAILS',
  ORDER_GUIDE = 'ORDER_GUIDE',
  CUSTOM_GUIDE = 'CUSTOM_GUIDE',
  CRITICAL_ITEM_GUIDE = 'CRITICAL_ITEM_GUIDE',
  ORDER_GUIDE_PRINT = 'ORDER_GUIDE_PRINT',
  CUSTOM_GUIDE_PRINT = 'CUSTOM_GUIDE_PRINT',
  CRITICAL_ITEM_GUIDE_PRINT = 'CRITICAL_ITEM_GUIDE_PRINT',
  ORDER_DETAILS = 'ORDER_DETAILS',
  MARKETING_GUIDE = 'MARKETING_GUIDE',
  MARKETING_GUIDE_PRINT = 'MARKETING_GUIDE_PRINT',
}

export const untranslatedDataPoints: ExportDataPoint[] = [
  ExportDataPoint.CasePriceUom,
  ExportDataPoint.UnitPriceUom,
  ExportDataPoint.PackUom,
];

export const csvFieldSeparator = 'SHARED.CSV_FIELD_SEPARATOR';

export const defaultOrderDetailsExportFileTypes: ExportDialogFileFormat[] = [
  {
    name: ExportFileType.EXCEL,
    value: 'excel',
    isChecked: true,
    i18nTag: 'EXPORT_MODAL.EXCEL',
  },
  {
    name: ExportFileType.CSV,
    value: 'csv',
    isChecked: false,
    i18nTag: 'EXPORT_MODAL.CSV',
  },
];

export enum ExportOrderDetailsTranslationKeys {
  ProductDescription = 'EXPORT.PRODUCT_DESCRIPTION',
  ItemCode = 'EXPORT.ITEM_CODE',
  Unit = 'EXPORT.UNIT',
  Price = 'EXPORT.PRICE',
  PriceUom = 'EXPORT.PRICE_UOM',
  CatchWeight = 'EXPORT.CATCH_WEIGHT',
  QuantityOrdered = 'EXPORT.QUANTITY_ORDERED',
  QuantityShipped = 'EXPORT.QUANTITY_SHIPPED',
  Total = 'EXPORT.TOTAL',
  Category = 'EXPORT.CATEGORY',
  NetWeight = 'EXPORT.NET_WEIGHT',
  PackSize = 'EXPORT.PACK_SIZE',
  UnitSize = 'EXPORT.UNIT_SIZE',
  Brand = 'EXPORT.BRAND',
  AffirmativeResponse = 'EXPORT.AFFIRMATIVE_RESPONSE',
  NegativeResponse = 'EXPORT.NEGATIVE_RESPONSE',
  PurchaseOrder = 'EXPORT.PO',
  Order = 'EXPORT.ORDER',
}
