import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialRowSharedOptions } from '../../../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-unavailable',
  templateUrl: './material-unavailable.component.html',
  styleUrls: ['./material-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialUnavailableComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;
  @Input() isDeleteHidden: boolean;
}
