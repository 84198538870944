<div class="parent-container" [ngClass]="{ 'red-border': shouldShowRedBorder }">
  <div *ngIf="isOk" class="content material-content" @fadeInOnly>
    <div
      class="details-container details-content"
      [ngClass]="{ 'full-size': fullSize }"
    >
      <naoo-material-info
        [materialRowOptions]="materialRow.materialRowOptions"
        [materialInfo]="materialRow.materialRowInfo"
        [isMobile]="isMobile"
      ></naoo-material-info>
      <div class="triple-dot">
        <naoo-material-options-menu
          [materialRowOptions]="materialRow.materialRowOptions"
          [isMobile]="isMobile"
        >
        </naoo-material-options-menu>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="content loading-content" @fadeInAndOut>
    <naoo-material-skeleton
      [isMobile]="isMobile"
      [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
    >
    </naoo-material-skeleton>
  </div>
</div>
