import { createSelector } from '@ngrx/store';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { selectCurrentExpressDeliveryWindow } from '../express-schedules/express-schedules.selectors';
import { selectCartEntity } from '../cart/cart.selectors';

export const selectContextualRouteDate = createSelector(
  selectCartEntity,
  selectCurrentExpressDeliveryWindow,
  (cart, expressDeliveryWindow): Date | undefined => {
    switch (cart?.fulfillmentType) {
      case FulfillmentType.EXPRESS:
        return expressDeliveryWindow?.orderEntryCutoff
          ? new Date(expressDeliveryWindow.orderEntryCutoff)
          : undefined;
      case FulfillmentType.PICKUP:
        return cart?.storeFulfillment?.requestedPickupTimestamp
          ? new Date(cart.storeFulfillment.requestedPickupTimestamp)
          : undefined;
      case FulfillmentType.TRUCK:
        return cart?.truckFulfillment?.routeDate;
      default:
        return undefined;
    }
  },
);
