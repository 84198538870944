<ng-container *ngIf="!isAccordionView; else accordionView">
  <div class="print-only">
    <div class="attributes-section">
      <div *ngIf="viewModel.materialAttributes?.length > 0">
        <div class="attributes-header-section">
          <div class="section-header attributes-header">
            {{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES' | translate }}
          </div>
        </div>
        <ul class="attribute-item-container">
          <li
            *ngFor="let attr of viewModel.materialAttributes"
            class="attribute-item"
          >
            {{
              ProductDetailsUtilities.getLocalized(
                viewModel?.currentLanguage,
                attr.name
              )
            }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="product-info-section"
    *ngFor="let section of viewModel.materialAdditionalInfoList"
  >
    <div
      class="dimensions-section"
      *ngIf="
        section === MaterialDetailsSection.MaterialDimensions &&
        ProductDetailsUtilities.hasMaterialDimensions(viewModel)
      "
    >
      <div class="dimensions-header-section">
        <div class="section-header dimensions-header">
          {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.TITLE' | translate }}
        </div>
      </div>

      <div>
        <div
          class="dimension-dictionary"
          *ngIf="!!viewModel.materialInfo?.baseUomWeight"
        >
          <div class="dictionary-key packaging-field-header">
            {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT' | translate }}
          </div>
          <div class="dictionary-value">
            {{ viewModel.materialInfo.baseUomWeight | naooweight }}
          </div>
        </div>
        <div
          *ngIf="viewModel.displayableDimensionsIn"
          class="dimension-dictionary"
        >
          <div class="dictionary-key packaging-field-header">
            {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS' | translate }}
          </div>
          <div class="dictionary-value">
            <span>{{
              'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                | translate: { dimension: viewModel.displayableDimensionsIn }
            }}</span>
            <span class="dimensions-separator"> / </span>
            <span>{{
              'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                | translate: { dimension: viewModel.displayableDimensionsCm }
            }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="packaging">
        <div class="packaging-field-header">
          {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING' | translate }}
        </div>
        <span class="packaging-storage">{{ packaging }}</span>
      </div>
    </div>

    <div
      class="vendor-section"
      *ngIf="
        section === MaterialDetailsSection.VendorInfo &&
        ProductDetailsUtilities.hasVendorInfo(viewModel)
      "
    >
      <div class="vendor-header-section">
        <div class="section-header vendor-header">
          {{ 'PRODUCT_DETAILS.VENDOR.TITLE' | translate }}
        </div>
      </div>
      <div>
        <div
          class="vendor-dictionary"
          *ngIf="!!viewModel.materialAdditionalInfo?.vendorInfo?.displayName"
        >
          <span class="dictionary-key"
            >{{ 'PRODUCT_DETAILS.VENDOR.NAME' | translate }}:&nbsp;</span
          >
          <span class="dictionary-value">{{
            viewModel.materialAdditionalInfo.vendorInfo.displayName
          }}</span>
        </div>
        <div
          class="vendor-dictionary"
          *ngIf="
            !!viewModel.materialAdditionalInfo?.vendorInfo?.vendorMaterialNumber
          "
        >
          <span class="dictionary-key"
            >{{ 'PRODUCT_DETAILS.VENDOR.ITEM_NUMBER' | translate }}:&nbsp;</span
          >
          <span class="dictionary-value">{{
            viewModel.materialAdditionalInfo.vendorInfo.vendorMaterialNumber
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="nutrition-section"
      *ngIf="section === MaterialDetailsSection.NutritionFacts"
    >
      <ng-container
        class="retalix-nutrition-section"
        *ngIf="
          ProductDetailsUtilities.hasAllergenInfo(viewModel) &&
          !viewModel.isGfsUsCustomerBrand
        "
      >
        <div class="nutrition-tables-container">
          <naoo-allergen-details
            class="allergen-details"
            [allergenInfo]="viewModel.materialAdditionalInfo.allergenInfo"
          ></naoo-allergen-details>
        </div>
      </ng-container>
      <div
        class="mygfs-nutrition-section"
        *ngIf="
          viewModel.isGfsUsCustomerBrand &&
          ProductDetailsUtilities.hasNutritionInformation(viewModel)
        "
      >
        <naoo-nutrition-container
          [nutritionLabel]="viewModel.nutritionLabel"
          [section]="section"
        ></naoo-nutrition-container>
      </div>
    </div>

    <div
      class="ingredients-section"
      *ngIf="
        section === MaterialDetailsSection.Ingredients &&
        ProductDetailsUtilities.hasIngredients(viewModel)
      "
    >
      <div class="ingredients-header-section">
        <div class="section-header ingredients-header">
          {{ 'PRODUCT_ATTRIBUTES.INGREDIENTS' | translate }}
        </div>
      </div>

      <naoo-ingredient-list
        [materialNumber]="viewModel.materialInfo.materialNumber"
        [nutritionLabel]="viewModel.nutritionLabel"
      ></naoo-ingredient-list>
    </div>

    <div
      class="material-movement"
      *ngIf="section === MaterialDetailsSection.MaterialMovement"
    >
      <div class="section-header">
        {{
          ProductDetailsUtilities.formatSectionText(section) + '.HEADER'
            | translate
        }}
      </div>
      <span class="material-movement-subsection">{{
        ProductDetailsUtilities.formatMaterialMovementSubSectionText(viewModel)
          | translate
      }}</span>
      <ng-container
        *ngIf="
          ProductDetailsUtilities.hasMaterialMovement(viewModel);
          else lastOrderDate
        "
      >
        <naoo-material-movement
          [materialMovementDetails]="viewModel.materialMovementDetails"
          [materialInfo]="viewModel.materialInfo"
        ></naoo-material-movement>
      </ng-container>

      <ng-template #lastOrderDate>
        <div *ngIf="!!viewModel.materialInfo?.lastOrderDate">
          <span class="last-order-date-label">
            {{
              'PRODUCT_DETAILS.MATERIAL_MOVEMENT.LAST_ORDER_DATE' | translate
            }}
          </span>
          <span class="last-order-date-value">
            {{
              viewModel.materialInfo.lastOrderDate
                | naooDate: 'shortYearDate' : ''
            }}
          </span>
        </div>
      </ng-template>
    </div>

    <div
      class="print-only"
      *ngIf="section === MaterialDetailsSection.NutritionFactsPrint"
    >
      <naoo-nutrition-facts-container
        *ngIf="viewModel.isGfsUsCustomerBrand"
        [materialNumber]="viewModel.materialInfo.materialNumber"
        [nutritionLabel]="viewModel.nutritionLabel"
      ></naoo-nutrition-facts-container>
      <div
        class="nutrition-tables-container"
        *ngIf="
          !viewModel.isGfsUsCustomerBrand &&
          ProductDetailsUtilities.hasNutritionInformation(viewModel)
        "
      >
        <naoo-nutrition-details
          class="nutrition-details"
          [nutritionDetails]="viewModel.materialAdditionalInfo.nutritionalInfo"
        ></naoo-nutrition-details>
      </div>
    </div>

    <div
      class="basic-section"
      *ngIf="
        ProductDetailsUtilities.isBasic(section) &&
        ProductDetailsUtilities.hasDataForSection(viewModel, section)
      "
    >
      <div class="section-header">
        {{ ProductDetailsUtilities.formatSectionText(section) | translate }}
      </div>
      <span class="product-details-section-info">
        {{
          viewModel?.materialAdditionalInfo[section] | stringdefaulter: true
        }}</span
      ><br />
    </div>
  </div>
</ng-container>

<ng-template #accordionView>
  <mat-accordion multi>
    <div
      class="product-info-section"
      *ngFor="let section of viewModel.materialAdditionalInfoList"
    >
      <div
        *ngIf="
          ProductDetailsUtilities.isBasic(section) &&
          ProductDetailsUtilities.hasDataForSection(viewModel, section)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{
                ProductDetailsUtilities.formatSectionText(section) | translate
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="product-details-section-info">{{
            viewModel?.materialAdditionalInfo[section] | stringdefaulter: true
          }}</span>
        </mat-expansion-panel>
      </div>
      <div
        class="dimensions-section"
        *ngIf="
          section === MaterialDetailsSection.MaterialDimensions &&
          ProductDetailsUtilities.hasMaterialDimensions(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.TITLE' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <div>
              <div
                class="attribute-dictionary"
                *ngIf="!!viewModel.materialInfo?.baseUomWeight"
              >
                <div class="dictionary-key packaging-field-header">
                  {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT' | translate }}
                </div>
                <div
                  class="dictionary-value"
                  [innerHTML]="
                    viewModel.materialInfo.baseUomWeight | naooweight: '<br>'
                  "
                ></div>
              </div>
              <div
                *ngIf="
                  !!viewModel.displayableDimensionsIn &&
                  !!viewModel.displayableDimensionsCm
                "
                class="attribute-dictionary"
              >
                <div class="dictionary-key packaging-field-header">
                  {{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS' | translate
                  }}
                </div>
                <div class="dictionary-value">
                  <span>{{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                      | translate
                        : { dimension: viewModel.displayableDimensionsIn }
                  }}</span>
                  <span class="dimensions-separator">
                    <br />
                  </span>
                  <span>{{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                      | translate
                        : { dimension: viewModel.displayableDimensionsCm }
                  }}</span>
                </div>
              </div>
            </div>
            <div class="packaging-storage" *ngIf="packaging">
              <div class="packaging-field-header">
                {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING' | translate }}
              </div>
              <span>{{ packaging }}</span>
            </div>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="vendor-section"
        *ngIf="
          section === MaterialDetailsSection.VendorInfo &&
          ProductDetailsUtilities.hasVendorInfo(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.VENDOR.TITLE' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <div
              class="attribute-dictionary"
              *ngIf="
                !!viewModel.materialAdditionalInfo?.vendorInfo?.displayName
              "
            >
              <span class="dictionary-key"
                >{{ 'PRODUCT_DETAILS.VENDOR.NAME' | translate }}:&nbsp;</span
              >
              <span class="dictionary-value">{{
                viewModel.materialAdditionalInfo.vendorInfo.displayName
              }}</span>
            </div>
            <div
              class="attribute-dictionary"
              *ngIf="
                !!viewModel.materialAdditionalInfo?.vendorInfo
                  ?.vendorMaterialNumber
              "
            >
              <span class="dictionary-key">
                {{ 'PRODUCT_DETAILS.VENDOR.ITEM_NUMBER' | translate }}:&nbsp;
              </span>
              <span class="dictionary-value">
                {{
                  viewModel.materialAdditionalInfo.vendorInfo
                    .vendorMaterialNumber
                }}
              </span>
            </div>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="allergen-section"
        *ngIf="
          section === MaterialDetailsSection.Allergens &&
          !viewModel.isGfsUsCustomerBrand &&
          ProductDetailsUtilities.hasAllergenInfo(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.ALLERGENS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="allergen-tables-container">
            <naoo-allergen-details
              class="allergen-details"
              [allergenInfo]="viewModel.materialAdditionalInfo.allergenInfo"
            ></naoo-allergen-details>
          </div>
        </mat-expansion-panel>
      </div>

      <div
        class="nutrition-section"
        *ngIf="
          section === MaterialDetailsSection.NutritionFacts &&
          ProductDetailsUtilities.hasNutritionInformation(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.NUTRITION_FACTS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="!viewModel.isGfsUsCustomerBrand"
            class="nutrition-tables-container"
          >
            <naoo-nutrition-details
              class="nutrition-details"
              [nutritionDetails]="
                viewModel.materialAdditionalInfo.nutritionalInfo
              "
            ></naoo-nutrition-details>
          </div>
          <span *ngIf="viewModel.isGfsUsCustomerBrand">
            <naoo-nutrition-facts-container
              [materialNumber]="viewModel.materialInfo.materialNumber"
              [nutritionLabel]="viewModel.nutritionLabel"
            ></naoo-nutrition-facts-container>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="school-equivalents-section"
        *ngIf="
          section === MaterialDetailsSection.SchoolEquivalents &&
          ProductDetailsUtilities.hasSchoolEquivalents(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.SCHOOL_EQUIVALENTS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <naoo-nutrition-container
              [section]="section"
              [nutritionLabel]="viewModel.nutritionLabel"
            ></naoo-nutrition-container>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="vitamins-section"
        *ngIf="
          section === MaterialDetailsSection.VitaminsAndMinerals &&
          ProductDetailsUtilities.hasVitaminsAndMinerals(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_DETAILS.VITAMINS_AND_MINERALS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <naoo-nutrition-container
              [section]="section"
              [nutritionLabel]="viewModel.nutritionLabel"
            ></naoo-nutrition-container>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="ingredients-section"
        *ngIf="
          section === MaterialDetailsSection.Ingredients &&
          ProductDetailsUtilities.hasIngredients(viewModel)
        "
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'PRODUCT_ATTRIBUTES.INGREDIENTS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <naoo-ingredient-list
              [materialNumber]="viewModel.materialInfo.materialNumber"
              [nutritionLabel]="viewModel.nutritionLabel"
            ></naoo-ingredient-list>
          </span>
        </mat-expansion-panel>
      </div>

      <div
        class="material-movement"
        *ngIf="section === MaterialDetailsSection.MaterialMovement"
      >
        <mat-expansion-panel class="product-details-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{
                ProductDetailsUtilities.formatSectionText(section) + '.HEADER'
                  | translate
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span>
            <span class="material-movement-subsection">{{
              ProductDetailsUtilities.formatMaterialMovementSubSectionText(
                viewModel
              ) | translate
            }}</span>
            <ng-container
              *ngIf="
                ProductDetailsUtilities.hasMaterialMovement(viewModel);
                else lastOrderDate
              "
            >
              <naoo-material-movement
                [materialMovementDetails]="viewModel.materialMovementDetails"
                [materialInfo]="viewModel.materialInfo"
              ></naoo-material-movement>
            </ng-container>

            <ng-template #lastOrderDate>
              <div *ngIf="!!viewModel.materialInfo?.lastOrderDate">
                <span class="last-order-date-label">
                  {{
                    'PRODUCT_DETAILS.MATERIAL_MOVEMENT.LAST_ORDER_DATE'
                      | translate
                  }}
                </span>
                <span class="last-order-date-value">
                  {{
                    viewModel.materialInfo.lastOrderDate
                      | naooDate: 'shortYearDate' : ''
                  }}
                </span>
              </div>
            </ng-template>
          </span>
        </mat-expansion-panel>
      </div>
    </div>
  </mat-accordion>
</ng-template>
