import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { OrderGuideChangeHistoryRecord } from '../../services/order-guide-change-history/models/order-guide-change-history-record';

export interface OrderGuideChangeHistoryState {
  hasLoaded: boolean;
  records: EntityState<OrderGuideChangeHistoryRecord>;
}

export const orderGuideChangeHistoryAdapter =
  createEntityAdapter<OrderGuideChangeHistoryRecord>({
    selectId: (record) => record.materialNumber,
  });

export const orderGuideChangeHistoryInitialState: OrderGuideChangeHistoryState =
  {
    hasLoaded: false,
    records: orderGuideChangeHistoryAdapter.getInitialState(),
  };
