import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'naoo-move-to-category-toast',
  templateUrl: './move-to-category-toast.component.html',
})
export class MoveToCategoryToastComponent implements OnInit {
  categoryName: string;
  productSuccessfullyMoved: boolean;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { categoryName: string; success: boolean },
  ) {}

  ngOnInit() {
    this.categoryName = this.data.categoryName;
    this.productSuccessfullyMoved = this.data.success;
  }
}
