import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialWarningType } from './material-warning';

export interface MaterialWarningState
  extends EntityState<MaterialWarningEntityState> {}

export interface MaterialWarningEntityState {
  materialNumber: string;
  displayedAnalyticSentWarnings: MaterialWarningType[];
  acknowledgedWarnings: MaterialWarningType[];
  isOpen: boolean;
  shouldHideWarning: boolean;
}

export const materialWarningEntityAdapter =
  createEntityAdapter<MaterialWarningEntityState>({
    selectId: (state) => state.materialNumber,
  });

export const initialMaterialWarningState: MaterialWarningState =
  materialWarningEntityAdapter.getInitialState();
