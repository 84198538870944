<ng-container *ngIf="isListView">
  <naoo-material-row-list-content
    observeVisibility
    (visible)="trackGoogleViewItem()"
    [materialRow]="materialRow$ | async"
    [isLast]="isLast"
    [isMobile]="isMobile"
    [materialAvailability]="materialAvailability$ | async"
    [fulfillmentType]="cartFulfillmentType$ | async"
    [ngClass]="{
      'last-row': isLast && !isCartReviewContext,
      'first-row': isFirst,
      mobile: isMobile,
    }"
    [displayGoPointsLogo]="displayGoPointsLogo"
    (quantityInputChanged)="quantityInputChanged.emit($event)"
    (viewCommodityDetailsClicked)="viewCommodityDetails()"
  ></naoo-material-row-list-content>
</ng-container>

<ng-container *ngIf="isSlimView">
  <naoo-material-row-slim-content
    observeVisibility
    (visible)="trackGoogleViewItem()"
    [materialRow]="materialRow$ | async"
    [isLast]="isLast"
    [isMobile]="isMobile"
    [materialAvailability]="materialAvailability$ | async"
    [ngClass]="{
      'last-row': isLast && !isCartReviewContext,
      'first-row': isFirst,
      mobile: isMobile,
    }"
    (viewCommodityDetailsClicked)="viewCommodityDetails()"
  ></naoo-material-row-slim-content>
</ng-container>

<ng-container *ngIf="isGridView" [style.min-width]="cardWidth">
  <naoo-material-row-grid-content
    observeVisibility
    (visible)="trackGoogleViewItem()"
    [materialRow]="materialRow$ | async"
    [isLast]="isLast"
    [isMobile]="isMobile"
    [materialAvailability]="materialAvailability$ | async"
    [fulfillmentType]="cartFulfillmentType$ | async"
    (quantityInputChanged)="quantityInputChanged.emit($event)"
    (viewCommodityDetailsClicked)="viewCommodityDetails()"
  >
  </naoo-material-row-grid-content>
</ng-container>

<ng-container *ngIf="isSlimGridView">
  <naoo-material-row-slim-grid-content
    observeVisibility
    (visible)="trackGoogleViewItem()"
    [materialRow]="materialRow$ | async"
    [isLast]="isLast"
    [isMobile]="isMobile"
    (quantityInputChanged)="quantityInputChanged.emit($event)"
    (viewCommodityDetailsClicked)="viewCommodityDetails()"
  >
  </naoo-material-row-slim-grid-content>
</ng-container>
