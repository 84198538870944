import { Store } from '@ngrx/store';
import { ActiveCartSummaryActions } from './active-cart-summary.actions';
import { CartSortType } from './active-cart-summary.state';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveCartSummary } from '../../../cart/cart-summary/active-cart/shared/models/active-cart-summary';
import { selectActiveCartSummary } from './active-cart-summary.selectors';

@Injectable({ providedIn: 'root' })
export class ActiveCartSummaryFacade {
  constructor(private store: Store) {}

  getActiveCartSummary(): Observable<ActiveCartSummary> {
    return this.store.select(selectActiveCartSummary);
  }

  enableQuickAdd() {
    this.store.dispatch(ActiveCartSummaryActions.enableQuickAdd());
  }

  disableQuickAdd() {
    this.store.dispatch(ActiveCartSummaryActions.disableQuickAdd());
  }

  updateCaseQuantityQuickAdd(materialNumber: string, caseQuantity: number) {
    this.store.dispatch(
      ActiveCartSummaryActions.updateQuickAddQuantities(
        materialNumber,
        caseQuantity,
      ),
    );
  }

  updateSortBy(sortBy: CartSortType) {
    this.store.dispatch(ActiveCartSummaryActions.updateCartSort(sortBy));
  }
}
