import {
  initialOfflineModeState,
  OfflineModeState,
} from './offline-mode.state';
import { OfflineModeActions } from './offline-mode.actions';
import { createReducer, on } from '@ngrx/store';

export const offlineModeReducer = createReducer(
  initialOfflineModeState,
  on(
    OfflineModeActions.updateOfflineMode,
    (state, action): OfflineModeState => ({
      ...state,
      isOffline: action.isOffline,
    }),
  ),
);
