export enum ImportGuideRowErrors {
  DuplicateItem,
  InvalidItem,
}

export class ImportGuideRow {
  rowNumber: number;
  itemId: string;
  category: string;
  error: ImportGuideRowErrors;

  constructor(rowNumber: number, category: string, itemId: string) {
    this.rowNumber = rowNumber;
    this.category = category;
    this.itemId = itemId;
  }
}
