<form>
  <mat-form-field>
    <mat-icon class="search-icon" matPrefix svgIcon="search_icon_v2"></mat-icon>
    <mat-label>{{ searchPlaceholderText | translate }}</mat-label>
    <input
      matInput
      autocomplete="off"
      name="searchText"
      [(ngModel)]="value"
      (ngModelChange)="search()"
      (blur)="blur()"
      [attr.aria-label]="'SEARCH.TITLE' | translate"
      [maxlength]="MAX_INPUT_LENGTH"
      [value]="value"
    />
    <button
      class="clear-button"
      type="button"
      disableRipple
      *ngIf="value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'CLEAR.CLEAR_SEARCH' | translate"
      (click)="clear()"
    >
      <mat-icon svgIcon="clear_icon"></mat-icon>
    </button>
    <mat-hint>{{ searchHintText | translate }}</mat-hint>
  </mat-form-field>
</form>
