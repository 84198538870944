import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SelectOrderMethodWidgetComponent } from './select-order-method-widget/select-order-method-widget.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    MatIconModule,
  ],
  exports: [SelectOrderMethodWidgetComponent],
  declarations: [SelectOrderMethodWidgetComponent],
})
export class SelectOrderMethodWidgetModule {}
