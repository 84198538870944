import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  MaterialLine,
  MaterialListStyle,
  MaterialRow,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { EntitlementFacade } from '../../core/store/entitlement/entitlement.facade';
import { MaterialAvailabilityFacade } from '../../core/store/material-availability/material-availability.facade';
import { MaterialAvailability } from '../../shared/models/material-availability';
import { FulfillmentType } from '../../core/services/cart/models/cart-record';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { AuxiliaryAnalyticsData } from '../../core/services/ecommerce-analytics/models/google-events';

@Component({
  selector: 'naoo-material-row-container',
  templateUrl: './material-row-container.component.html',
  styleUrls: ['./material-row-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialRowContainerComponent implements OnChanges {
  @Input() materialNumber: string;
  @Input() context: MaterialRowContext;
  @Input() listStyle: MaterialListStyle;
  @Input() isLast: boolean;
  @Input() isFirst: boolean;
  @Input() isMobile: boolean;
  @Input() cardWidth?: number;
  @Input() customGuideId?: string;
  @Input() materialLines?: MaterialLine[];
  @Input() displayGoPointsLogo: boolean;
  @Input() analytics?: AuxiliaryAnalyticsData;

  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  @HostBinding('class.grid-comparisons') get gridComparisons() {
    return (
      (this.isSlimGridView || this.isGridView) &&
      this.context == MaterialRowContext.Comparison
    );
  }

  @HostBinding('class.slim-grid') get slimGridComparisons() {
    return this.isSlimGridView && this.context == MaterialRowContext.Comparison;
  }

  @HostBinding('class.grid') get grid() {
    return (
      (this.isSlimGridView || this.isGridView) &&
      this.context != MaterialRowContext.Comparison
    );
  }

  materialRow$: Observable<MaterialRow>;
  materialAvailability$: Observable<MaterialAvailability>;
  cartFulfillmentType$: Observable<FulfillmentType> =
    this.cartFacade.getSelectedFulfillmentType();

  constructor(
    private materialRowFacade: MaterialRowFacade,
    private entitlementFacade: EntitlementFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private cartFacade: CartFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['materialNumber'] ||
      changes['context'] ||
      changes['listStyle'] ||
      changes['customGuideId'] ||
      changes['materialLines']
    ) {
      this.materialRow$ = this.materialRowFacade.getMaterialRow({
        analytics: this.analytics,
        context: this.context,
        customGuideId: this.customGuideId,
        materialLines: this.materialLines,
        materialListStyle: this.listStyle,
        materialNumber: this.materialNumber,
      });
      this.materialAvailability$ =
        this.materialAvailabilityFacade.getLoadedMaterialAvailability(
          this.materialNumber,
        );
    }
  }

  get isCartReviewContext(): boolean {
    return [
      MaterialRowContext.CartReview,
      MaterialRowContext.CriticalItem,
      MaterialRowContext.Substitutes,
    ].includes(this.context);
  }

  get isListView(): boolean {
    return this.listStyle === MaterialListStyle.List;
  }

  get isSlimView(): boolean {
    return this.listStyle === MaterialListStyle.Slim;
  }

  get isGridView(): boolean {
    return this.listStyle === MaterialListStyle.Grid;
  }

  get isSlimGridView(): boolean {
    return this.listStyle === MaterialListStyle.SlimGrid;
  }

  viewCommodityDetails(): void {
    this.entitlementFacade.setSelectedMaterialNumber(this.materialNumber);
  }

  trackGoogleViewItem(): void {
    this.ecommerceAnalyticsFacade.trackGoogleViewItem(
      this.materialNumber,
      this.context,
      this.analytics,
    );
  }
}
