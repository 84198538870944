import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeliveryDatePickerContainerComponent } from './delivery-date-picker-container.component';
import { DeliveryDatePickerContentComponent } from './delivery-date-picker-content/delivery-date-picker-content.component';
import { DeliveryDatePickerCalendarComponent } from './delivery-date-picker-content/delivery-date-picker-calendar/delivery-date-picker-calendar.component';
import { DeliveryDatePickerDetailsComponent } from './delivery-date-picker-content/delivery-date-picker-details/delivery-date-picker-details.component';
import { DeliveryDatePickerAggregatorService } from './shared/delivery-date-picker-aggregator.service';
import { DeliveryDatePickerTransformationService } from './shared/delivery-date-picker-transformation.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DeliveryDatePickerService } from './shared/delivery-date-picker.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    DeliveryDatePickerContainerComponent,
    DeliveryDatePickerContentComponent,
    DeliveryDatePickerCalendarComponent,
    DeliveryDatePickerDetailsComponent,
  ],
  providers: [
    DeliveryDatePickerService,
    DeliveryDatePickerAggregatorService,
    DeliveryDatePickerTransformationService,
  ],
})
export class DeliveryDatePickerModule {}
