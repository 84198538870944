import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAllOrderableCartMaterials,
  selectCartEntity,
  selectCartMaterial,
  selectFocusedMaterial,
} from '../cart/cart.selectors';
import { selectMaterialInfoRecordState } from '../material-info/material-info.selectors';
import { selectInventoryAvailabilityRecordState } from '../inventory-availability/inventory-availability.selectors';
import {
  transformMaterialWarningProperties,
  transformWarning,
} from './material-warning-transformer';
import { MaterialWarningState } from './material-warning.state';
import {
  selectConsiderInventory,
  selectMaterialCutoffEntities,
} from '../material-cutoff/material-cutoff.selectors';
import { selectMaterialAvailabilityRecordState } from '../material-availability/material-availability.selectors';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { InventoryAvailabilityRecordState } from '../inventory-availability/inventory-availability.state';
import { selectActiveCustomer } from '../session/session.selectors';
import { selectSalesCriticalItem } from '../sales-critical-items/sales-critical-items.selector';
import {
  selectEntitlementMaterialDetail,
  selectOverallocatedMaterialDetail,
} from '../entitlement/entitlement.selectors';
import { selectHasOpenSpecialOrder } from '../open-special-order-items/open-special-order-items.selector';
import { MaterialWarningType } from './material-warning';
import { selectContextualRouteDate } from '../route-date/route-date.selectors';

interface MaterialWarningInfo {
  info: MaterialInfoRecordState;
  availability: MaterialAvailabilityRecordState;
  inventory: InventoryAvailabilityRecordState;
}

const limitedStockStatuses = [
  MaterialWarningType.NoStock,
  MaterialWarningType.PartialStockCase,
  MaterialWarningType.PartialStockCaseUnit,
];

const selectMaterialWarningFeature =
  createFeatureSelector<MaterialWarningState>('materialWarning');

const selectWarningMaterialInfo = (materialNumber: string) =>
  createSelector(
    selectMaterialInfoRecordState(materialNumber),
    selectMaterialAvailabilityRecordState(materialNumber),
    selectInventoryAvailabilityRecordState(materialNumber),
    (info, availability, inventory): MaterialWarningInfo => {
      return {
        info,
        availability,
        inventory,
      };
    },
  );

const selectMaterial = (materialNumber: string) =>
  createSelector(
    selectCartMaterial(materialNumber),
    selectWarningMaterialInfo(materialNumber),
    selectFocusedMaterial,
    selectMaterialCutoffEntities,
    selectEntitlementMaterialDetail(materialNumber),
    selectOverallocatedMaterialDetail(materialNumber),
    (
      cartMaterial,
      materialInfo,
      focusedState,
      cutoffs,
      entitlementMaterialDetail,
      overallocatedMaterialDetail,
    ) => {
      return {
        cartMaterial,
        materialInfo,
        focusedState,
        cutoffs,
        entitlementMaterialDetail,
        overallocatedMaterialDetail,
      };
    },
  );

const selectWarningProperties = (materialNumber: string) => {
  return createSelector(
    selectMaterial(materialNumber),
    selectConsiderInventory,
    selectContextualRouteDate,
    selectHasOpenSpecialOrder(materialNumber),
    selectActiveCustomer,
    selectSalesCriticalItem(materialNumber),
    selectCartEntity,
    (
      material,
      shouldConsiderInventory,
      routeDate,
      hasOpenSpecialOrders,
      activeCustomer,
      salesCriticalItem,
      cartEntityState,
    ) => {
      const {
        materialInfo,
        cartMaterial,
        focusedState,
        cutoffs,
        entitlementMaterialDetail,
        overallocatedMaterialDetail,
      } = material;

      if (
        !materialInfo.info ||
        !materialInfo.info.record ||
        !materialInfo.availability ||
        !materialInfo.availability.record
      ) {
        return undefined;
      }

      const inventory = materialInfo.inventory
        ? materialInfo.inventory.record
        : undefined;

      return transformMaterialWarningProperties({
        materialNumber,
        cartMaterial,
        materialRecord: materialInfo.info.record,
        availability: materialInfo.availability.record,
        inventory,
        shouldConsiderInventory,
        routeDate,
        focusedMaterialState: focusedState,
        hasOpenSpecialOrders,
        cutoffs,
        activeCustomer,
        salesCriticalItem,
        entitlementMaterialDetail,
        overallocatedMaterialDetail,
        fulfillmentType: cartEntityState?.fulfillmentType,
      });
    },
  );
};

const selectMaterialWarningEntityState = (materialNumber: string) =>
  createSelector(
    selectMaterialWarningFeature,
    (state) => state.entities[materialNumber],
  );

export const selectAllMaterialWarningEntityStates = createSelector(
  selectMaterialWarningFeature,
  (state) => state.entities,
);

export const selectWarning = (materialNumber: string) =>
  createSelector(
    selectWarningProperties(materialNumber),
    selectMaterialWarningEntityState(materialNumber),
    (warningProperties, materialWarning) =>
      !warningProperties
        ? undefined
        : transformWarning(materialNumber, warningProperties, materialWarning),
  );

export const selectCartMaterialNumbersWithLimitedStockWarnings = createSelector(
  selectAllMaterialWarningEntityStates,
  selectAllOrderableCartMaterials,
  (materialWarningEntityStates, orderableCartMaterials): string[] =>
    orderableCartMaterials
      ?.map((material) => material.materialNumber)
      .filter((materialNumber) =>
        materialWarningEntityStates[
          materialNumber
        ]?.displayedAnalyticSentWarnings?.some((warning) =>
          limitedStockStatuses.includes(warning),
        ),
      ) ?? [],
);

export const selectLimitedStockWarnings = (materialNumbers: string[]) =>
  createSelector(
    selectAllMaterialWarningEntityStates,
    (materialWarningEntityStates): Map<string, MaterialWarningType> =>
      new Map(
        materialNumbers?.map((materialNumber) => {
          const warningState = materialWarningEntityStates[materialNumber];
          const firstDisplayedStockWarning =
            warningState?.displayedAnalyticSentWarnings.find((warning) =>
              limitedStockStatuses.includes(warning),
            );
          return [materialNumber, firstDisplayedStockWarning];
        }) || [],
      ),
  );
