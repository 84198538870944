import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionFacade } from '../../core/store/session/session.facade';

export const lockedToOrderGuideGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
  return isLockedToOrderGuide();
};

function isLockedToOrderGuide(): Observable<boolean> {
  const router = inject(Router);
  const sessionFacade = inject(SessionFacade);

  return sessionFacade.isLockedToOrderGuide().pipe(
    map((isLockedToOrderGuide: boolean) => {
      if (!isLockedToOrderGuide) {
        return true;
      } else {
        router.navigate(['guides']);
      }
    }),
  );
}
