<div class="header">
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'CLOSE' | translate }}"
  >
    <img
      class="close-button-img"
      alt="{{ 'CLOSE' | translate }}"
      src="assets/images/close-icon.svg"
    />
  </button>
</div>
<div class="empty-category-modal-container">
  <div class="modal-body">
    {{ 'LISTS.EMPTY_CATEGORY_MODAL.BODY' | translate }}
  </div>
  <div class="modal-button-bar">
    <button class="secondary-button large-button" (click)="closeModal()">
      {{ 'LISTS.EMPTY_CATEGORY_MODAL.BACK_BUTTON' | translate }}
    </button>
    <button class="primary-button large-button" (click)="deleteCategory()">
      {{ 'LISTS.EMPTY_CATEGORY_MODAL.SAVE_CHANGES' | translate }}
    </button>
  </div>
</div>
