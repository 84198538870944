import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState, SponsoredRecommendationsState } from './search.state';
import { SearchTransformer } from './shared/search-transformer';
import { selectPreferredMaterialView } from '../material-row/material-view.selectors';
import { RecommendationProduct } from '../../services/search/search.service';

export interface SponsoredSearchData {
  searchTerm: string;
  hasBanners: boolean;
  hasMaterials: boolean;
  firstCampaignId: string;
  sponsoredMaterials: string[];
  recommendationProducts: Map<string, RecommendationProduct>;
}

export const selectSearchFeature = createFeatureSelector<SearchState>('search');

export const selectSearchResults = createSelector(
  selectSearchFeature,
  selectPreferredMaterialView,
  (state, preferredView) =>
    SearchTransformer.transformSearchState(state, preferredView),
);

const selectHasLoaded = createSelector(
  selectSearchFeature,
  (state) => state.hasLoaded,
);

export const selectCurrentOffset = createSelector(
  selectSearchFeature,
  (state) => state?.currentOffset || 0,
);

export const selectResultSet = createSelector(
  selectHasLoaded,
  selectSearchFeature,
  (hasLoaded, state) => {
    return hasLoaded ? state.resultSet : undefined;
  },
);

export const selectSearchedMaterialsOrEmptyArray = createSelector(
  selectResultSet,
  (resultSet) => (resultSet ? resultSet.materialNumbers : []),
);

export const selectTotalSearchResults = createSelector(
  selectResultSet,
  (resultSet) => (resultSet ? resultSet.totalResults : 0),
);

export const selectSponsoredRecommendationBanners = createSelector(
  selectResultSet,
  (resultSet) => resultSet?.sponsoredRecommendations?.banners ?? [],
);

export const selectIsRecommendedProduct = (materialNumber: string) =>
  createSelector(
    selectResultSet,
    (resultSet): boolean =>
      !!resultSet?.sponsoredRecommendations?.productRecommendations.has(
        materialNumber,
      ),
  );

export const selectSponsoredSearchData = createSelector(
  selectSearchFeature,
  (searchState): SponsoredSearchData => {
    const recommendations: SponsoredRecommendationsState =
      searchState.resultSet?.sponsoredRecommendations;
    const hasBanners = !!recommendations?.banners.length;
    const hasMaterials = !!recommendations?.productRecommendations.size;
    const firstRecommendationProduct = recommendations?.productRecommendations
      .values()
      .next().value;
    const searchTerm =
      hasBanners || hasMaterials ? searchState.lastSearchText : undefined;

    return {
      searchTerm,
      hasBanners,
      hasMaterials,
      firstCampaignId: firstRecommendationProduct?.campaignTracking,
      sponsoredMaterials: Array.from(
        recommendations?.productRecommendations.keys() || [],
      ),
      recommendationProducts:
        recommendations?.productRecommendations || new Map(),
    };
  },
);
