import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface MaterialRecommendationsState
  extends EntityState<MaterialRecommendationsRecordState> {}

export interface MaterialRecommendationsRecordState {
  page: Page;
  status: MaterialRecommendationsRequestStatus;
  materialNumber?: string;
  materialRecommendations?: MaterialRecommendations[];
}

export enum Page {
  PDP = 'pdp',
  HOMEPAGE = 'homepage',
  RELATED = 'related',
}

export enum MaterialRecommendationsRequestStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const materialRecommendationsAdapter =
  createEntityAdapter<MaterialRecommendationsRecordState>({
    selectId: (state) => state.page,
  });

export const initialMaterialRecommendationsState =
  materialRecommendationsAdapter.getInitialState();
