import { Dictionary } from '@ngrx/entity';
import { StoreRecord } from '../../services/store/model/store-record';
import { StorePurchaseDetailsRecordState } from './store-purchase-details.state';
import {
  PurchaseMaterial,
  PurchaseTotalDetails,
  StorePurchaseDetailsViewModel,
} from './models/store-purchase-details-view-model';
import {
  StorePurchaseDetailItem,
  StorePurchaseDetailsRecord,
} from '../../services/store-purchase-details/model/store-purchase-details-record';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { SessionActiveCustomer } from '../../services/session/models/session-record';
import { MaterialInfoRecordState } from '../material-info/material-info.state';

export class StorePurchaseDetailsTransformer {
  public static getStorePurchaseDetailsViewModel(
    recordState: StorePurchaseDetailsRecordState,
    storeRecordDictionary: Dictionary<StoreRecord>,
    availabilityRecordStateDictionary: Dictionary<MaterialAvailabilityRecordState>,
    infoRecordStateDictionary: Dictionary<MaterialInfoRecordState>,
    fulfillmentType: FulfillmentType,
    sessionActiveCustomer: SessionActiveCustomer,
  ): StorePurchaseDetailsViewModel {
    const isLoading = !recordState?.hasLoaded;
    if (isLoading) {
      return {
        customerTimezone: sessionActiveCustomer.timeZone,
        isLoading,
        shouldDisplayErrorPage: false,
        fulfillmentType,
      };
    }

    const record = recordState?.record;
    if (!record) {
      return {
        customerTimezone: sessionActiveCustomer.timeZone,
        isLoading,
        shouldDisplayErrorPage: true,
        fulfillmentType,
      };
    }

    return {
      customerTimezone: sessionActiveCustomer.timeZone,
      isLoading,
      shouldDisplayErrorPage: false,
      fulfillmentType,
      purchaseDetails: {
        saleDate: record.saleDate,
        saleTimestamp: record.saleTimestamp,
        customerDetails: {
          customerBrand: sessionActiveCustomer.brand,
          customerName: sessionActiveCustomer.name,
          customerId: sessionActiveCustomer.naooCustomerId,
        },
        storeRecord: storeRecordDictionary[record.storePlantId],
        itemDetails: record.saleItems.map((saleItem) =>
          createPurchaseMaterial(
            saleItem,
            availabilityRecordStateDictionary,
            infoRecordStateDictionary,
          ),
        ),
        totalDetails: createPurchaseTotalDetails(record),
      },
    };
  }
}

function createPurchaseMaterial(
  item: StorePurchaseDetailItem,
  availabilityRecordStateDictionary: Dictionary<MaterialAvailabilityRecordState>,
  infoRecordStateDictionary: Dictionary<MaterialInfoRecordState>,
): PurchaseMaterial {
  const materialAvailabilityRecordState: MaterialAvailabilityRecordState =
    availabilityRecordStateDictionary[item.materialNumber];

  const { ['lineNumber']: _, ...rest } = item;
  const purchaseMaterial: PurchaseMaterial = rest;
  purchaseMaterial.uomCode = getDisplayCode(item, infoRecordStateDictionary);

  return {
    ...purchaseMaterial,
    materialAvailabilityRecordState,
  };
}

function createPurchaseTotalDetails(
  record: StorePurchaseDetailsRecord,
): PurchaseTotalDetails {
  const totalQuantity = record.saleItems
    .map((item) => item.quantitySold)
    .reduce((sum, current) => sum + current, 0);

  return {
    totalLines: record.saleItems.length,
    totalPoints: record.pointsEarned,
    totalCost: +record.netTransactionAmount,
    totalQuantity,
  };
}

function getDisplayCode(
  detailItem: StorePurchaseDetailItem,
  infoRecordStateDictionary: Dictionary<MaterialInfoRecordState>,
): string | undefined {
  const materialInfoRecordState: MaterialInfoRecordState =
    infoRecordStateDictionary[detailItem.materialNumber];

  return materialInfoRecordState?.record?.units.find(
    (unit) => unit.uom === detailItem.uom,
  )?.displayCode;
}
