import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StorePurchaseDetailsEffects } from './store-purchase-details.effects';
import { storePurchaseDetailsReducer } from './store-purchase-details.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('storePurchaseDetails', storePurchaseDetailsReducer),
    EffectsModule.forFeature([StorePurchaseDetailsEffects]),
  ],
})
export class StorePurchaseDetailsStoreModule {}
