import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  storePurchaseHistoryRecordAdapter,
  StorePurchaseHistoryRecordState,
  StorePurchaseHistoryState,
  StorePurchaseHistoryStateStatus,
} from './store-purchase-history.state';
import { selectAllStoreRecordStateEntities } from '../store/store.selectors';
import { Dictionary } from '@ngrx/entity';
import { StoreRecord } from '../../services/store/model/store-record';
import { selectActiveCustomerTimezone } from '../session/session.selectors';

export interface StorePurchaseHistoryTransformerData {
  storeRecordDictionary: Dictionary<StoreRecord>;
  historyRecords: StorePurchaseHistoryRecordState[];
  status: StorePurchaseHistoryStateStatus;
  customerTimezone: string;
  isFinishedLoading: boolean;
  startDate?: string;
}

const selectFeatureSelector = createFeatureSelector<StorePurchaseHistoryState>(
  'storePurchaseHistory',
);

export const selectStorePurchaseHistoryStatus = createSelector(
  selectFeatureSelector,
  (state): StorePurchaseHistoryStateStatus | undefined => state.status,
);

export const selectHasStorePurchaseHistoryFinishedLoading = createSelector(
  selectStorePurchaseHistoryStatus,
  (status): boolean =>
    [
      StorePurchaseHistoryStateStatus.Success,
      StorePurchaseHistoryStateStatus.Error,
    ].includes(status),
);

export const selectAllStorePurchaseHistoryRecords = createSelector(
  selectFeatureSelector,
  (state): StorePurchaseHistoryRecordState[] =>
    storePurchaseHistoryRecordAdapter.getSelectors().selectAll(state.records),
);

export const selectStorePurchaseHistoryTransformerData = createSelector(
  selectAllStoreRecordStateEntities,
  selectFeatureSelector,
  selectActiveCustomerTimezone,
  selectHasStorePurchaseHistoryFinishedLoading,
  (
    storeRecordDictionary: Dictionary<StoreRecord>,
    state: StorePurchaseHistoryState,
    customerTimezone: string,
    isFinishedLoading: boolean,
  ): StorePurchaseHistoryTransformerData => {
    return {
      storeRecordDictionary,
      historyRecords: storePurchaseHistoryRecordAdapter
        .getSelectors()
        .selectAll(state.records),
      status: state.status,
      customerTimezone,
      isFinishedLoading,
      startDate: state.loadedStartDate,
    };
  },
);
