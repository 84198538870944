import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SessionFacade } from '../../core/store/session/session.facade';
import { SessionActiveCustomer } from '../../core/services/session/models/session-record';

@Component({
  selector: 'naoo-customer-unit-info',
  templateUrl: './customer-unit-info.component.html',
  styleUrls: ['./customer-unit-info.component.scss'],
})
export class CustomerUnitInfoComponent implements OnInit, OnDestroy {
  customer: SessionActiveCustomer;
  destroyed$ = new Subject();

  constructor(private sessionFacade: SessionFacade) {}

  ngOnInit(): void {
    this.sessionFacade
      .getLoadedActiveCustomer()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customer) => {
        this.customer = customer;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
