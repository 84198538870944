import { OrderType } from '../../../../shared/models/order-type';
import { Localized } from '../../../../shared/models/localized';
import { CartOrder } from '../../cart-order/models/cart-order';
import {
  CreditCardDetails,
  FreightInformation,
  ShipToAddressSap,
  StoreFulfillmentDetails,
} from '../../../../shared/models/order-info';

export interface OrderConfirmationInfoRecord {
  orders: OrderConfirmationRecord[];
}

export interface OrderConfirmationRecord {
  pooOrderId: string;
  orderNumber: string;
  orderType: OrderType;
  requestedCustomerArrivalDate?: string;
  poNumber: string;
  shippingAddress: ShippingAddress;
  submittedDateTime: Date;
  totalLines: number;
  totalQuantityOrdered: number;
  totalPrice: number;
  orderEditable: boolean;
  orderStatusCode: string;
  orderStatusSeverity: Severity;
  orderStatusDescription: Localized<string>;
  orderBlockDescription: Localized<string> | null;
  materials: OrderConfirmationMaterial[];
  orderWarnings: OrderConfirmationWarning[];
  totalDiscountAmount?: number | null;
  totalExtendedPrice?: number;
  salesOrganization?: string;
  distributionChannel?: string;
  division?: string;
  customerNumber?: string;
  groupNumber?: string;
  totalNetWeight?: number;
  totalGrossWeight?: number;
  totalNetWeightUom?: string;
  orderCurrency?: string;
  totalShipping?: number;
  totalPiecesShipped?: number;
  submittedByUserId?: string;
  submittedUserIdType?: string;
  submittedUserName?: string;
  pooSystemId?: string;
  actualDeliveryDate?: string;
  atpRetry?: boolean;
  requestedDeliveryType?: string;
  userObjectStatus?: string;
  usdaOrderAllocationId?: string;
  requestedRouteDate?: string;
  storeFulfillment?: StoreFulfillmentDetails;
  creditCardDetails?: CreditCardDetails;
  freightInformation?: FreightInformation[];
  estimatedDeliveryDate?: string;
  estimatedDeliveryTime?: string;
  blockCode?: string;
  shipToAddressSap?: ShipToAddressSap;
}

export interface ShippingAddress {
  streetAddress: string;
  streetAddress2: string | null;
  city: string | null;
  region: string | null;
  country: string;
  postalCode: string | null;
}

export interface OrderConfirmationMaterial {
  materialNumber: string;
  uom: string;
  catchWeightUom: string;
  quantityOrdered: number;
  quantityConfirmed: number;
  price: number;
  totalLinePrice: number;
  lineNumber: string;
  substituteForMaterial?: string;
  lineStatusSeverity: Severity;
  lineStatusDescription: Localized<string>;
  lineStatusErrorDescription: Localized<string>;
  lineWarnings: OrderConfirmationWarning[];
  editable: boolean;
  totalLineDiscountAmount: number | null;
  extendedPrice?: number;
  catchWeightPrice?: number;
  lineStatusCode?: string;
  result?: string;
  customerItemCode?: string;
  externalItemNumber?: string;
  usdaOrderLineAllocationId?: string;
  lessCaseFlag?: boolean;
  digitalCouponCode?: string;
  plant?: string;
  catchweightIndicator?: boolean;
  grossWeightItem?: string;
  netWeightItem?: string;
  freightInformation?: FreightInformation[];
}

export interface OrderConfirmationWarning {
  severity: Severity;
  shortDescription: Localized<string>;
}

export enum Severity {
  None = 'NONE',
  Warning = 'WARNING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export interface OrderConfirmationPollRecord {
  processingOrderIds: string[];
  orders: OrderConfirmationRecord[];
}

export interface OrderConfirmationCompleteResponse {
  processingCartOrders: CartOrder[];
  orders: OrderConfirmationRecord[];
}
