import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectInventoryAvailabilityRecordState } from './inventory-availability.selectors';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { InventoryAvailabilityViewModel } from '../../../shared/models/inventory-availability';
import {
  InventoryAvailabilityRecordState,
  InventoryAvailabilityRecordStatus,
} from './inventory-availability.state';
import { InventoryAvailabilityActions } from './inventory-availability.actions';

@Injectable({ providedIn: 'root' })
export class InventoryAvailabilityFacade {
  constructor(private store: Store) {}

  loadCartInventoryAvailability(itemIds: string[]) {
    this.store.dispatch(
      InventoryAvailabilityActions.loadCartInventoryAvailability(itemIds),
    );
  }

  refreshCartInventoryAvailability() {
    this.store.dispatch(
      InventoryAvailabilityActions.refreshCartInventoryAvailability(),
    );
  }

  loadInventoryAvailability(itemId: string) {
    this.loadInventoryAvailabilities([itemId]);
  }

  loadInventoryAvailabilities(itemIds: string[]) {
    this.store.dispatch(
      InventoryAvailabilityActions.loadInventoryAvailability(itemIds),
    );
  }

  getLoadedInventoryAvailability(
    itemId: string,
  ): Observable<InventoryAvailabilityViewModel> {
    return this.store
      .select(selectInventoryAvailabilityRecordState(itemId))
      .pipe(
        filter((recordState) => this.hasFinishedLoading(recordState)),
        map((recordState) =>
          this.convertInventoryAvailabilityRecordState(recordState),
        ),
      );
  }

  private hasFinishedLoading(
    recordState: InventoryAvailabilityRecordState,
  ): boolean {
    return (
      !!recordState &&
      [
        InventoryAvailabilityRecordStatus.Error,
        InventoryAvailabilityRecordStatus.Success,
      ].includes(recordState.status)
    );
  }

  private convertInventoryAvailabilityRecordState(
    recordState: InventoryAvailabilityRecordState,
  ): InventoryAvailabilityViewModel {
    return !!recordState && !!recordState.record
      ? {
          materialNumber: recordState.record.materialNumber,
          currentUnitsAvailability: recordState.record.unitsAvailability.map(
            (item) => {
              return {
                uom: item.uom,
                currentUnitsAvailable: item.unitsAvailable,
                estInStockDate: item.nextPoDate,
                totalOnOrder: item.qtyOnPO,
              };
            },
          ),
          considerInventory: recordState.record.considerInventory,
        }
      : undefined;
  }
}
