import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { OrderGuideChangeHistoryService } from '../../services/order-guide-change-history/order-guide-change-history.service';
import { Observable, of } from 'rxjs';
import { OrderGuideChangeHistoryActions } from './order-guide-change-history.actions';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { selectOrderGuideChangeHistoryRecords } from './order-guide-change-history.selectors';
import { SharedActions } from '../shared/shared.actions';
import { ErrorActions } from '../error/error.actions';
import { MaterialInfoActions } from '../material-info/material-info.actions';

@Injectable()
export class OrderGuideChangeHistoryEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private orderGuideChangeHistoryService: OrderGuideChangeHistoryService,
  ) {}

  getOrderGuideChangeHistory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideChangeHistoryActions.getOrderGuideChangeHistory),
      concatLatestFrom(() =>
        this.store.select(selectOrderGuideChangeHistoryRecords),
      ),
      mergeMap(([_, records]) => {
        if (records.length > 0) {
          return of(
            SharedActions.noOperation(
              'Order Guide Change History has been loaded.',
            ),
          );
        } else {
          return this.orderGuideChangeHistoryService
            .getOrderGuideChangeHistory()
            .pipe(
              map((orderGuideChangeHistory) => {
                return OrderGuideChangeHistoryActions.getOrderGuideChangeHistorySuccess(
                  orderGuideChangeHistory,
                );
              }),
              catchError(() => of(ErrorActions.silentError(''))),
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory,
                  ),
                ),
              ),
            );
        }
      }),
    );
  });

  getOrderGuideChangeHistorySuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideChangeHistoryActions.getOrderGuideChangeHistorySuccess),
      concatLatestFrom(() =>
        this.store.select(selectOrderGuideChangeHistoryRecords),
      ),
      map(([_, orderGuideChangesHistoryRecords]) => {
        const materialNumbers = orderGuideChangesHistoryRecords.map(
          (history) => history.materialNumber,
        );
        return MaterialInfoActions.loadMaterialInfo(materialNumbers);
      }),
    );
  });

  refreshOrderGuideChangeHistory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory),
      map(() => OrderGuideChangeHistoryActions.getOrderGuideChangeHistory()),
    );
  });
}
