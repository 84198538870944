import { Injectable } from '@angular/core';
import {
  CartOrder,
  CartOrderDestinationSystem,
  CartOrderMaterialRecord,
  CartOrderResponse,
  CartOrderStatus,
  RetalixOrderStatus,
  RetalixSpecialOrderStatus,
} from '../../core/services/cart-order/models/cart-order';
import {
  HeaderError,
  HeaderTotals,
  ItemDetail,
  LineDetail,
  LineStatusDetail,
  Order,
  OrderConfirmation,
  OrderConfirmationHeader,
  OrderItem,
  QuantityConfirmedIcon,
  SeverityIcon,
  ShipToAddress,
  StatusClass,
  UnitTotals,
} from './models/order-confirmation';
import { Localized } from '../../shared/models/localized';
import { CustomerMaterialRecord } from '../../core/services/customer-material/model/customer-material-record';
import { getCustomerMaterialNumberFromCustomerMaterialInfo } from '../../core/store/customer-material/utilities/customer-material.utilities';
import { MaterialInfo } from '../../shared/models/material-info';
import { NaooConstants } from '../../shared/NaooConstants';
import { getQuantityFromCartOrderMaterial } from '../../shared/utilities/cart-material-utilities';
import { SessionActiveCustomer } from '../../core/services/session/models/session-record';
import { Severity } from '../../core/services/order-confirmation/models/order-confirmation-record';
import { DateService } from '../../shared/services/date/date.service';
import { CombinedPricingRecord } from '../../core/store/material-price/material-price.util';
import { CombinedPriceCalculation } from '../../core/store/material-price/material-price.facade';

interface SortedOrders {
  stockOrders: CartOrder[];
  nonStockOrders: CartOrder[];
  stillProcessingOrders: CartOrder[];
}

interface ErrorItems {
  id: string;
  casesOrdered: number;
  casesShipped: number;
  unitsOrdered: number;
  unitsShipped: number;
  errors: Errors;
}

interface Errors {
  caseErrors?: ErrorDetails[];
  unitErrors?: ErrorDetails[];
  headerErrors?: ErrorDetails[];
}

export interface ErrorDetails {
  primaryLineStatus: Localized<string>;
  secondaryLineStatus?: Localized<string>;
}

interface CaseUnitErrorStatus {
  hasCaseError: boolean;
  hasUnitError: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationRetalixTransformationService {
  constructor(private readonly dateService: DateService) {}

  private readonly deniedErrorText: Localized<string[]> = {
    en: ['Denied - Contact your Sales Representative'],
    fr: ['Refusé - contacter votre représentant'],
    es: ['Rechazado: comuníquese con su representante de ventas'],
  };

  private readonly creditHoldErrorText: Localized<string> = {
    en: 'There is an accounting issue that may impact the processing of your order. Please contact the Accounting Department.',
    fr:
      'Il y a un problème au niveau de la comptabilité, ceci peut impacter ' +
      'le processus de votre commande. Veuillez contacter le département de la comptabilité.',
    es: 'Hay un problema contable que puede afectar el procesamiento de su pedido. Por favor contacte al Departamento de Contabilidad.',
  };

  transformOrderConfirmation(
    cartOrderResponse: CartOrderResponse,
    customer: SessionActiveCustomer,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    cartRouteDate?: Date,
  ): OrderConfirmation {
    const sortedOrders = this.parseOrders(cartOrderResponse.cartOrders);
    const errorItems = this.filterStockErrorItems(
      cartOrderResponse.cartOrders,
    ).concat(this.filterNonStockErrorItems(cartOrderResponse.cartOrders));

    const materialsWithLineErrors: Map<string, CaseUnitErrorStatus> = new Map<
      string,
      CaseUnitErrorStatus
    >();
    errorItems.forEach((item) => {
      const caseUnitErrorStatus: CaseUnitErrorStatus = {
        hasCaseError: !!item.errors.caseErrors || !!item.errors.headerErrors,
        hasUnitError: !!item.errors.unitErrors || !!item.errors.headerErrors,
      };
      materialsWithLineErrors.set(item.id, caseUnitErrorStatus);
    });
    const errorItemIds = errorItems.map((item) => item.id);

    const cartOrderWithPoNumber = cartOrderResponse.cartOrders
      .filter((cartOrder) => !!cartOrder && !!cartOrder.customerPoNumber)
      .pop();
    const poNumber = cartOrderWithPoNumber
      ? cartOrderWithPoNumber.customerPoNumber
      : undefined;

    return {
      headerErrors: this.buildOrderConfirmationHeaderErrors(cartOrderResponse),
      header: this.buildOrderConfirmationHeader(
        cartOrderResponse.cartOrders,
        errorItems,
        errorItemIds,
        sortedOrders,
        customer,
        combinedPriceMap,
      ),
      orders: {
        errorMaterials: this.buildItemExceptions(
          errorItems,
          combinedPriceMap,
          materialInfoMap,
          customerMaterial,
        ),
        warningMaterials: [],
        stillProcessingMaterials: this.buildProcessingOrders(
          sortedOrders.stillProcessingOrders,
          combinedPriceMap,
          materialInfoMap,
          customerMaterial,
        ),
        nonStockOrders: this.buildNonStockOrders(
          sortedOrders.nonStockOrders,
          errorItemIds,
          combinedPriceMap,
          materialInfoMap,
          customerMaterial,
          poNumber,
        ),
        dropShipOrders: [],
        mainOrders: sortedOrders.stockOrders
          .map((standardOrder) =>
            this.buildStockOrder(
              standardOrder,
              materialsWithLineErrors,
              combinedPriceMap,
              materialInfoMap,
              customer,
              customerMaterial,
            ),
          )
          .filter((order) => !!order),
      },
      materialRelatedRouteDate: cartRouteDate,
    };
  }

  private filterStockErrorItems(cartOrders: CartOrder[]): ErrorItems[] {
    const nestedErrorItems = cartOrders
      .filter((cartOrder) => cartOrder.retalixOrderConfirmation)
      .map((cartOrder) => {
        const confirmation = cartOrder.retalixOrderConfirmation;

        if (confirmation.status === RetalixOrderStatus.REJECTED) {
          return cartOrder.materials.map((material) => {
            return {
              id: material.materialNumber,
              casesOrdered: getQuantityFromCartOrderMaterial(
                material,
                NaooConstants.Uom.Case,
              ),
              casesShipped: 0,
              unitsOrdered: getQuantityFromCartOrderMaterial(
                material,
                NaooConstants.Uom.Unit,
              ),
              unitsShipped: 0,
              errors: {
                headerErrors: this.transformHeaderErrorsToErrorDetails(
                  confirmation.headerError.rejectReasons,
                ),
              },
            };
          });
        }

        return confirmation.orderItemErrors
          .filter((itemError) =>
            cartOrder.materials.find(
              (material) => material.materialNumber === itemError.itemId,
            ),
          )
          .map((itemError) => {
            const orderMaterial = cartOrder.materials.find(
              (material) => material.materialNumber === itemError.itemId,
            );

            const allCasesShipped = itemError.caseItemDetails.map(
              (caseDetail) => caseDetail.shippedQuantity,
            );
            const casesOrdered = getQuantityFromCartOrderMaterial(
              orderMaterial,
              NaooConstants.Uom.Case,
            );
            const casesShipped =
              itemError.caseItemDetails.length > 0
                ? Math.min(...allCasesShipped)
                : casesOrdered;

            const allUnitsShipped = itemError.unitItemDetails.map(
              (unitDetail) => unitDetail.shippedQuantity,
            );
            const unitsOrdered = getQuantityFromCartOrderMaterial(
              orderMaterial,
              NaooConstants.Uom.Unit,
            );
            const unitsShipped =
              itemError.unitItemDetails.length > 0
                ? Math.min(...allUnitsShipped)
                : unitsOrdered;

            const allCaseErrors = itemError.caseItemDetails.map(
              (caseDetail) => caseDetail.errorMessageText,
            );
            const allUnitErrors = itemError.unitItemDetails.map(
              (unitDetail) => unitDetail.errorMessageText,
            );

            return {
              id: orderMaterial.materialNumber,
              casesOrdered: casesOrdered,
              casesShipped: casesShipped,
              unitsOrdered: unitsOrdered,
              unitsShipped: unitsShipped,
              errors: {
                caseErrors: this.parseLineErrors(allCaseErrors),
                unitErrors: this.parseLineErrors(allUnitErrors),
              },
            };
          });
      });

    return ([] as ErrorItems[]).concat(...nestedErrorItems);
  }

  private parseLineErrors(itemLineErrors: Localized<string>[]): ErrorDetails[] {
    const errorDetails: ErrorDetails[] = [];
    if (itemLineErrors.length > 0) {
      itemLineErrors.forEach((error) => {
        errorDetails.push({
          primaryLineStatus: error,
        });
      });
    }

    return errorDetails.length > 0 ? errorDetails : undefined;
  }

  private transformHeaderErrorsToErrorDetails(
    headerErrors: Localized<string[]>,
  ): ErrorDetails[] {
    const errorDetails: ErrorDetails[] = [];
    let i = 0;
    while (i < headerErrors.en?.length || i < headerErrors.fr?.length) {
      errorDetails.push({
        primaryLineStatus: {
          en: headerErrors.en?.[i],
          fr: headerErrors.fr?.[i],
          es: headerErrors.es?.[i],
        },
      });
      i++;
    }
    return errorDetails;
  }

  private filterNonStockErrorItems(cartOrders: CartOrder[]): ErrorItems[] {
    const deniedSpecialOrderItems = cartOrders
      .filter((cartOrder) => {
        return (
          cartOrder.retalixSpecialOrderConfirmation &&
          cartOrder.retalixSpecialOrderConfirmation.status &&
          cartOrder.retalixSpecialOrderConfirmation.status ===
            RetalixSpecialOrderStatus.DENIED
        );
      })
      .map((cartOrder) =>
        this.createNonStandardErrorItem(cartOrder, this.deniedErrorText),
      );

    return ([] as ErrorItems[]).concat(...deniedSpecialOrderItems);
  }

  private parseOrders(cartOrders: CartOrder[]): SortedOrders {
    const standardOrders = cartOrders
      .filter((cartOrder) => this.isSubmittedStandardOrder(cartOrder))
      .sort(
        (a, b) =>
          Date.parse(a.cartOrderTruckFulfillment.requestedRouteDate) -
          Date.parse(b.cartOrderTruckFulfillment.requestedRouteDate),
      );

    const specialOrders = cartOrders.filter((cartOrder) =>
      this.isSubmittedSpecialOrder(cartOrder),
    );

    const processingOrders = cartOrders.filter((cartOrder) =>
      this.isProcessingOrder(cartOrder),
    );

    return {
      stockOrders: standardOrders,
      nonStockOrders: specialOrders,
      stillProcessingOrders: processingOrders,
    };
  }

  private buildOrderConfirmationHeaderErrors(
    cartOrderResponse: CartOrderResponse,
  ): HeaderError[] {
    const isCreditHold = cartOrderResponse.cartOrders.some(
      (cartOrder) =>
        cartOrder.retalixOrderConfirmation?.headerError?.creditHold,
    );
    return isCreditHold
      ? [
          {
            primaryText: null,
            secondaryText: this.creditHoldErrorText,
          },
        ]
      : [];
  }

  private buildOrderConfirmationHeader(
    cartOrders: CartOrder[],
    errorItems: ErrorItems[],
    errorItemIds: string[],
    sortedOrders: SortedOrders,
    customer: SessionActiveCustomer,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
  ): OrderConfirmationHeader {
    return {
      customerUnitName: customer.name,
      customerNumber: customer.customerDisplayId,
      customerTimeZone: customer.timeZone,
      shipToAddress: this.buildShipToAddressFromCustomer(customer),
      submittedOn: cartOrders
        .map((cartOrder) => cartOrder.submitTimestamp)
        .filter((submitTimeStamp) => !!submitTimeStamp)
        .pop(),
      headerTotals: this.buildHeaderTotals(
        cartOrders,
        errorItems,
        errorItemIds,
        combinedPriceMap,
      ),
      userName: sortedOrders.stockOrders
        .map((standardOrder) => standardOrder.userFullName)
        .pop(),
      shipToLabel: 'ORDER_CONFIRMATION.HEADER.SHIP_TO',
    };
  }

  private buildShipToAddressFromCustomer(
    customer: SessionActiveCustomer,
  ): ShipToAddress {
    const address = customer.address;
    return {
      shipAddress1: address.line1 || '',
      shipAddress2: address.line2 || '',
      city: address.city || '',
      province: address.provinceState || '',
      zipCode: address.postalCode || '',
    };
  }

  private buildHeaderTotals(
    cartOrders: CartOrder[],
    errorItems: ErrorItems[],
    errorItemIds: string[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
  ): HeaderTotals {
    const processedUnitTotals = this.getUnitTotals(
      cartOrders,
      errorItemIds,
      combinedPriceMap,
      errorItems,
    );
    const linesCount = processedUnitTotals.totalLinesCount;
    const estimatedTotalCost = processedUnitTotals.estimatedTotalCost;
    const unitTotals = processedUnitTotals.unitTotals;

    return {
      lineCount: linesCount,
      itemCount: unitTotals
        .map((unitTotal) => unitTotal.total)
        .reduce((a, v) => a + v, 0),
      estimatedCost: estimatedTotalCost,
    };
  }

  private getUnitTotals(
    cartOrders: CartOrder[],
    errorItemIds: string[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    errorItems: ErrorItems[],
  ): {
    unitTotals: UnitTotals[];
    totalLinesCount: number;
    estimatedTotalCost: number;
  } {
    let totalLinesCount = 0;
    let estimatedTotalCost = 0;
    let unitTotals: UnitTotals[] = [];

    cartOrders.forEach((cartOrder) => {
      cartOrder.materials
        .filter((orderMaterial) => {
          return !errorItemIds.includes(orderMaterial.materialNumber);
        })
        .forEach((material) => {
          material.lines.forEach((line) =>
            this.updateUnitTotals(unitTotals, line.uom, line.quantity),
          );

          const combinedPrice = combinedPriceMap.get(material.materialNumber);

          totalLinesCount++;
          if (combinedPrice) {
            const caseQuantity = getQuantityFromCartOrderMaterial(
              material,
              NaooConstants.Uom.Case,
            );
            const unitQuantity = getQuantityFromCartOrderMaterial(
              material,
              NaooConstants.Uom.Unit,
            );
            const calculatedPrice = this.calculatePrice(
              combinedPrice,
              caseQuantity,
              unitQuantity,
            );

            estimatedTotalCost += Array.from(
              calculatedPrice.lineTotals.values(),
            ).reduce((a, b) => a + b, 0);
          }
        });
    });

    const errorItemsTotals = this.getErrorItemTotals(
      errorItems,
      combinedPriceMap,
      unitTotals,
    );

    unitTotals = errorItemsTotals.unitTotals;
    totalLinesCount += errorItemsTotals.totalLinesCount;
    estimatedTotalCost += errorItemsTotals.estimatedTotalCost;

    return { unitTotals, totalLinesCount, estimatedTotalCost };
  }

  private getErrorItemTotals(
    errorItems: ErrorItems[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    unitTotals: UnitTotals[],
  ): {
    unitTotals: UnitTotals[];
    totalLinesCount: number;
    estimatedTotalCost: number;
  } {
    let totalLinesCount = 0;
    let estimatedTotalCost = 0;

    errorItems
      .filter((item) => item.casesShipped + item.unitsShipped > 0)
      .forEach((errorItem) => {
        const combinedPrice = combinedPriceMap.get(errorItem.id);
        this.updateUnitTotals(
          unitTotals,
          NaooConstants.Uom.Case,
          errorItem.casesShipped,
        );
        this.updateUnitTotals(
          unitTotals,
          NaooConstants.Uom.Unit,
          errorItem.unitsShipped,
        );
        totalLinesCount++;
        if (combinedPrice) {
          const calculatedPrice = this.calculatePrice(
            combinedPrice,
            errorItem.casesShipped,
            errorItem.unitsShipped,
          );
          estimatedTotalCost += Array.from(
            calculatedPrice.lineTotals.values(),
          ).reduce((a, b) => a + b, 0);
        }
      });
    return { unitTotals, totalLinesCount, estimatedTotalCost };
  }

  private buildItemExceptions(
    errorItems: ErrorItems[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfosMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
  ): OrderItem[] {
    return errorItems.length > 0
      ? errorItems.map((item) => {
          const materialInfo = materialInfosMap.get(item.id);
          const combinedPrice: CombinedPricingRecord = combinedPriceMap.get(
            item.id,
          );
          return this.buildItemException(
            item,
            combinedPrice,
            materialInfo,
            customerMaterial,
          );
        })
      : [];
  }

  private buildItemException(
    item: ErrorItems,
    combinedPrice: CombinedPricingRecord,
    materialInfo: MaterialInfo,
    customerMaterial: CustomerMaterialRecord,
  ): OrderItem {
    const calculatedPrice = this.calculatePrice(
      combinedPrice,
      item.casesShipped,
      item.unitsShipped,
    );
    const orderLines: LineDetail[] = [];

    const hasCaseError = item.errors.headerErrors || item.errors.caseErrors;
    const hasUnitError = item.errors.headerErrors || item.errors.unitErrors;

    if (hasCaseError && item.casesOrdered > 0) {
      const caseDetails = this.buildLineDetail(
        NaooConstants.Uom.Case,
        item.casesOrdered,
        combinedPrice,
        calculatedPrice,
        item.casesShipped,
        [],
      );

      if (item.errors.caseErrors) {
        caseDetails.lineStatusDetails = item.errors.caseErrors.map(
          (errorDetail) => this.transformLineStatusDetail(errorDetail),
        );
      }

      if (item.errors.headerErrors) {
        caseDetails.lineStatusDetails.push(
          ...item.errors.headerErrors.map((errorDetail) =>
            this.transformLineStatusDetail(errorDetail),
          ),
        );
      }

      orderLines.push(caseDetails);
    }

    if (hasUnitError && item.unitsOrdered > 0) {
      const unitDetails = this.buildLineDetail(
        NaooConstants.Uom.Unit,
        item.unitsOrdered,
        combinedPrice,
        calculatedPrice,
        item.unitsShipped,
        [],
      );

      if (item.errors.unitErrors) {
        unitDetails.lineStatusDetails = item.errors.unitErrors.map(
          (errorDetail) => this.transformLineStatusDetail(errorDetail),
        );
      }

      if (item.errors.headerErrors) {
        unitDetails.lineStatusDetails.push(
          ...item.errors.headerErrors.map((errorDetail) =>
            this.transformLineStatusDetail(errorDetail),
          ),
        );
      }

      orderLines.push(unitDetails);
    }
    return {
      orderLines: orderLines,
      itemDetail: this.buildItemDetail(materialInfo, customerMaterial),
    };
  }

  private transformLineStatusDetail(
    errorDetail: ErrorDetails,
  ): LineStatusDetail {
    return {
      lineStatusClass: StatusClass.Error,
      icon: SeverityIcon.Error,
      ...errorDetail,
    };
  }

  private buildProcessingOrders(
    processingOrders: CartOrder[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
  ): OrderItem[] {
    const nestedProcessingItems = processingOrders.map((order) => {
      return order.materials.map((processingOrderMaterial) => {
        const materialInfo = materialInfoMap.get(
          processingOrderMaterial.materialNumber,
        );
        const combinedPrice = combinedPriceMap.get(
          processingOrderMaterial.materialNumber,
        );
        const caseQuantity = getQuantityFromCartOrderMaterial(
          processingOrderMaterial,
          NaooConstants.Uom.Case,
        );
        const unitQuantity = getQuantityFromCartOrderMaterial(
          processingOrderMaterial,
          NaooConstants.Uom.Unit,
        );

        const calculatedPrice = this.calculatePrice(
          combinedPrice,
          caseQuantity,
          unitQuantity,
        );

        const caseDetails = this.buildLineDetail(
          NaooConstants.Uom.Case,
          caseQuantity,
          combinedPrice,
          calculatedPrice,
        );
        const eachDetails = this.buildLineDetail(
          NaooConstants.Uom.Unit,
          unitQuantity,
          combinedPrice,
          calculatedPrice,
        );

        const orderLines: LineDetail[] = this.addCaseAndUnitDetails(
          caseDetails,
          eachDetails,
        );

        return {
          orderLines: orderLines,
          itemDetail: this.buildItemDetail(materialInfo, customerMaterial),
        };
      });
    });
    return ([] as OrderItem[]).concat(...nestedProcessingItems);
  }

  private buildNonStockOrder(
    cartOrder: CartOrder,
    errorItemIds: string[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfosMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    poNumber: string,
  ): Order | undefined {
    const orderItems: OrderItem[] = [];
    let totalQuantity = 0;
    let totalPrice = 0;

    cartOrder.materials
      .filter(
        (specialOrderMaterial) =>
          !errorItemIds.includes(specialOrderMaterial.materialNumber),
      )
      .forEach((specialOrderMaterial) => {
        const materialInfo = materialInfosMap.get(
          specialOrderMaterial.materialNumber,
        );
        const combinedPrice = combinedPriceMap.get(
          specialOrderMaterial.materialNumber,
        );

        const orderLines = this.createRetalixLineDetails(
          specialOrderMaterial,
          combinedPrice,
        );
        const orderItem: OrderItem = {
          orderLines: orderLines,
          itemDetail: this.buildItemDetail(materialInfo, customerMaterial),
        };
        const newQuantity: number = orderLines
          .map((line) => line.quantityOrdered)
          .reduce((acc, ordered) => acc + ordered, 0);
        const totalPriceOfLines = orderLines
          .map((line) => line.totalLinePrice)
          .reduce((acc, linePrice) => acc + linePrice, 0);

        orderItems.push(orderItem);
        totalPrice += totalPriceOfLines;
        totalQuantity += newQuantity;
      });

    return orderItems.length > 0
      ? {
          totalQuantity: totalQuantity,
          estimatedTotal: totalPrice,
          items: orderItems,
          poNumber,
        }
      : undefined;
  }

  private buildNonStockOrders(
    nonStockCartOrders: CartOrder[],
    errorItemIds: string[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfosMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    poNumber: string,
  ): Order[] {
    let orderItems: OrderItem[] = [];
    let totalQuantity = 0;
    let totalPrice = 0;
    nonStockCartOrders.forEach((dropShipCartOrder) => {
      const order = this.buildNonStockOrder(
        dropShipCartOrder,
        errorItemIds,
        combinedPriceMap,
        materialInfosMap,
        customerMaterial,
        poNumber,
      );

      if (order) {
        orderItems = orderItems.concat(order.items);
        totalPrice += order.estimatedTotal;
        totalQuantity += order.totalQuantity;
      }
    });

    return orderItems.length > 0
      ? [
          {
            totalQuantity: totalQuantity,
            estimatedTotal: totalPrice,
            items: orderItems,
            poNumber,
          },
        ]
      : [];
  }

  private createRetalixLineDetails(
    orderMaterial: CartOrderMaterialRecord,
    combinedPrice: CombinedPricingRecord,
  ): LineDetail[] {
    const caseQuantity = getQuantityFromCartOrderMaterial(
      orderMaterial,
      NaooConstants.Uom.Case,
    );
    const unitQuantity = getQuantityFromCartOrderMaterial(
      orderMaterial,
      NaooConstants.Uom.Unit,
    );
    const calculatedPrice = this.calculatePrice(
      combinedPrice,
      caseQuantity,
      unitQuantity,
    );

    const caseDetails = this.buildLineDetail(
      NaooConstants.Uom.Case,
      caseQuantity,
      combinedPrice,
      calculatedPrice,
    );

    const eachDetails = this.buildLineDetail(
      NaooConstants.Uom.Unit,
      unitQuantity,
      combinedPrice,
      calculatedPrice,
    );
    return this.addCaseAndUnitDetails(caseDetails, eachDetails);
  }

  private buildStockOrder(
    stockCartOrder: CartOrder,
    errorItemIds: Map<string, CaseUnitErrorStatus>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfosMap: Map<string, MaterialInfo>,
    customer: SessionActiveCustomer,
    customerMaterial: CustomerMaterialRecord,
  ): Order {
    if (!stockCartOrder) {
      return undefined;
    }
    let totalQuantity = 0;
    let totalPrice = 0;

    const orders = stockCartOrder.materials
      .filter((filteredMaterial) =>
        this.filterErrorMaterials(filteredMaterial, errorItemIds),
      )
      .map((material) => {
        const hasUnitError: boolean =
          errorItemIds.has(material.materialNumber) &&
          errorItemIds.get(material.materialNumber).hasUnitError;
        const hasCaseError: boolean =
          errorItemIds.has(material.materialNumber) &&
          errorItemIds.get(material.materialNumber).hasCaseError;
        const combinedPrice = combinedPriceMap.get(material.materialNumber);
        const materialInfo = materialInfosMap.get(material.materialNumber);
        const caseQuantity = getQuantityFromCartOrderMaterial(
          material,
          NaooConstants.Uom.Case,
        );
        const unitQuantity = getQuantityFromCartOrderMaterial(
          material,
          NaooConstants.Uom.Unit,
        );

        const calculatedPrice = this.calculatePrice(
          combinedPrice,
          caseQuantity,
          unitQuantity,
        );

        let caseDetails: LineDetail;
        let eachDetails: LineDetail;
        if (!hasCaseError && caseQuantity > 0) {
          caseDetails = this.buildLineDetail(
            NaooConstants.Uom.Case,
            caseQuantity,
            combinedPrice,
            calculatedPrice,
          );
          totalPrice += caseDetails.totalLinePrice || 0;
        }

        if (!hasUnitError && unitQuantity > 0) {
          eachDetails = this.buildLineDetail(
            NaooConstants.Uom.Unit,
            unitQuantity,
            combinedPrice,
            calculatedPrice,
          );
          totalPrice += eachDetails.totalLinePrice || 0;
        }

        totalQuantity += caseQuantity + unitQuantity;

        const orderLines: LineDetail[] = this.addCaseAndUnitDetails(
          caseDetails,
          eachDetails,
        );

        return {
          orderLines: orderLines,
          itemDetail: this.buildItemDetail(materialInfo, customerMaterial),
        };
      });

    if (!orders || orders.length < 1) {
      return undefined;
    }

    return {
      estimatedTotal: totalPrice,
      totalQuantity: totalQuantity,
      requestedCustomerArrivalDate:
        stockCartOrder.cartOrderTruckFulfillment.requestedCustomerArrivalDate,
      orderNumber: stockCartOrder.externalOrderId,
      items: orders,
      poNumber: stockCartOrder.customerPoNumber,
      labelsAndDates: {
        orderTitle: 'ORDER_CONFIRMATION.STANDARD_ORDERS.TITLE',
        splitOrderTitle: 'ORDER_CONFIRMATION.STANDARD_ORDERS.TITLE',
        deliveryDateLabel: 'ORDER_CONFIRMATION.SHIP_DATE',
        deliveryDate: this.dateService.getLocalizedDateString(
          stockCartOrder.cartOrderTruckFulfillment.requestedRouteDate,
          'weekdayMonthDay',
          customer.timeZone,
          false,
        ),
      },
    };
  }

  private filterErrorMaterials(
    filteredMaterial: CartOrderMaterialRecord,
    errorItemIds: Map<string, CaseUnitErrorStatus>,
  ) {
    return !(
      errorItemIds.has(filteredMaterial.materialNumber) &&
      (errorItemIds.get(filteredMaterial.materialNumber).hasUnitError ||
        getQuantityFromCartOrderMaterial(
          filteredMaterial,
          NaooConstants.Uom.Unit,
        ) === 0) &&
      (errorItemIds.get(filteredMaterial.materialNumber).hasCaseError ||
        getQuantityFromCartOrderMaterial(
          filteredMaterial,
          NaooConstants.Uom.Case,
        ) === 0)
    );
  }

  private isProcessingOrder(cartOrder: CartOrder): boolean {
    return (
      cartOrder.status === CartOrderStatus.QUEUED ||
      cartOrder.status === CartOrderStatus.PROCESSING ||
      (cartOrder.status === CartOrderStatus.SUBMITTED &&
        cartOrder.destinationSystem ===
          CartOrderDestinationSystem.RETALIX_ORDER &&
        cartOrder.retalixOrderConfirmation.status ===
          RetalixOrderStatus.PENDING) ||
      (cartOrder.status === CartOrderStatus.SUBMITTED &&
        cartOrder.destinationSystem ===
          CartOrderDestinationSystem.RETALIX_SPECIAL_ORDER &&
        !cartOrder.retalixSpecialOrderConfirmation.status)
    );
  }

  private isSubmittedSpecialOrder(cartOrder: CartOrder): boolean {
    return (
      cartOrder.destinationSystem ===
        CartOrderDestinationSystem.RETALIX_SPECIAL_ORDER &&
      cartOrder.status === CartOrderStatus.SUBMITTED &&
      cartOrder.retalixSpecialOrderConfirmation &&
      cartOrder.retalixSpecialOrderConfirmation.status &&
      cartOrder.retalixSpecialOrderConfirmation.status !==
        RetalixSpecialOrderStatus.DENIED
    );
  }

  private isSubmittedStandardOrder(cartOrder: CartOrder): boolean {
    return (
      cartOrder.destinationSystem ===
        CartOrderDestinationSystem.RETALIX_ORDER &&
      cartOrder.status === CartOrderStatus.SUBMITTED &&
      cartOrder.retalixOrderConfirmation.status === RetalixOrderStatus.CONFIRMED
    );
  }

  private buildLineDetail(
    uom: string,
    quantity: number,
    combinedPrice: CombinedPricingRecord,
    totalPrice: CombinedPriceCalculation,
    shipped?: number,
    lineExceptions?: LineStatusDetail[],
  ): LineDetail {
    const lineDetail: LineDetail = {
      uom: uom,
      displayCode: uom,
      catchWeightUom: combinedPrice?.isCatchWeight
        ? combinedPrice?.weightUom
        : undefined,
      price: this.getDisplayedPrice(combinedPrice, uom),
      quantityOrdered: quantity,
      totalLinePrice: totalPrice.lineTotals.get(uom) || 0,
      lineSeverity: Severity.Success,
      quantityConfirmedClass: StatusClass.None,
      quantityConfirmedIcon: QuantityConfirmedIcon.None,
    };
    if (!!shipped || shipped === 0) {
      lineDetail.quantityConfirmed = shipped;
    }
    if (lineExceptions) {
      lineDetail.lineStatusDetails = lineExceptions;
      lineDetail.lineSeverity = Severity.Error;
      lineDetail.quantityConfirmedClass = StatusClass.Error;
      lineDetail.quantityConfirmedIcon = QuantityConfirmedIcon.Error;
    }
    return lineDetail;
  }

  private buildItemDetail(
    materialInfo: MaterialInfo,
    customerMaterialRecord: CustomerMaterialRecord,
  ): ItemDetail {
    return {
      id: materialInfo.materialNumber,
      customerMaterialNumber: getCustomerMaterialNumberFromCustomerMaterialInfo(
        customerMaterialRecord[materialInfo.materialNumber],
      ),
      description: materialInfo.description,
      brand: {
        en: materialInfo.brand?.en,
        fr: materialInfo.brand?.fr,
        es: materialInfo.brand?.es,
      },
      dimensions: {
        innerPackSize: materialInfo.innerPackSize,
        units: materialInfo.units,
        isCatchWeight: materialInfo.isCatchWeight,
        baseUomNetWeight: materialInfo.baseUomWeight.net,
      },
    };
  }

  private getDisplayedPrice(
    combinedPrice: CombinedPricingRecord,
    uom: string,
  ): number {
    const unitPrice = combinedPrice?.unitPrices.find(
      (unit) => unit.salesUom === uom,
    );
    return combinedPrice?.isCatchWeight
      ? unitPrice?.catchWeightPrice
      : unitPrice?.price;
  }

  private calculatePrice(
    combinedPrice: CombinedPricingRecord,
    caseItemQuantity: number,
    unitItemQuantity: number,
  ): CombinedPriceCalculation {
    if (!combinedPrice) {
      return new CombinedPriceCalculation();
    }

    const casePrice = combinedPrice.unitPrices?.find(
      (unit) => NaooConstants.Uom.Case === unit.salesUom,
    );
    const unitPrice = combinedPrice.unitPrices?.find(
      (unit) => NaooConstants.Uom.Unit === unit.salesUom,
    );

    return new CombinedPriceCalculation(
      combinedPrice,
      new Map([
        [
          NaooConstants.Uom.Case,
          (casePrice ? casePrice.price : 0) * caseItemQuantity,
        ],
        [
          NaooConstants.Uom.Unit,
          (unitPrice ? unitPrice.price : 0) * unitItemQuantity,
        ],
      ]),
    );
  }

  private createNonStandardErrorItem(
    cartOrder: CartOrder,
    errorMessage: Localized<string[]>,
  ): ErrorItems[] {
    return cartOrder.materials.map((material) => {
      return {
        id: material.materialNumber,
        casesOrdered: getQuantityFromCartOrderMaterial(
          material,
          NaooConstants.Uom.Case,
        ),
        casesShipped: 0,
        unitsOrdered: getQuantityFromCartOrderMaterial(
          material,
          NaooConstants.Uom.Unit,
        ),
        unitsShipped: 0,
        errors: {
          headerErrors: this.transformHeaderErrorsToErrorDetails(errorMessage),
        },
      };
    });
  }

  private addCaseAndUnitDetails(
    caseDetails: LineDetail,
    unitDetails: LineDetail,
  ): LineDetail[] {
    const orderLines: LineDetail[] = [];

    if (!!caseDetails && caseDetails.quantityOrdered > 0) {
      orderLines.push(caseDetails);
    }

    if (!!unitDetails && unitDetails.quantityOrdered > 0) {
      orderLines.push(unitDetails);
    }

    return orderLines;
  }

  private updateUnitTotals(
    unitTotals: UnitTotals[],
    unitType: string,
    quantity: number,
  ) {
    if (!quantity) {
      return;
    }

    if (unitTotals.some((unitTotal) => unitTotal.uom === unitType)) {
      unitTotals.find((unitTotal) => unitTotal.uom === unitType).total +=
        quantity;
    } else {
      unitTotals.push({ uom: unitType, total: quantity });
    }
  }
}
