<div class="step-buttons" [ngClass]="{ 'bottom-space': isLast }">
  <ng-container *ngIf="isFirst; else backButton">
    <button
      *ngIf="!isSecondaryButtonHidden"
      [disabled]="isSecondaryButtonDisabled"
      (click)="handleSecondaryButtonClick()"
      class="browsing-button"
      mat-button
    >
      {{ 'ORDER_METHOD_MODAL.BUTTON.BROWSING' | translate }}
    </button>
  </ng-container>

  <ng-container *ngIf="!isPrimaryButtonHidden">
    <ng-container *ngIf="isLast; else nextButton">
      <button
        [disabled]="isPrimaryButtonDisabled"
        (click)="handlePrimaryButtonClick()"
        class="done-button"
        mat-button
      >
        {{ 'ORDER_METHOD_MODAL.BUTTON.START_SHOPPING' | translate }}
      </button>
    </ng-container>
  </ng-container>
</div>

<ng-template #backButton>
  <button
    [disabled]="isSecondaryButtonDisabled"
    (click)="handleSecondaryButtonClick()"
    class="back-button"
    mat-button
    matStepperPrevious
  >
    {{ 'ORDER_METHOD_MODAL.BUTTON.BACK' | translate }}
  </button>
</ng-template>

<ng-template #nextButton>
  <button
    [disabled]="isPrimaryButtonDisabled"
    (click)="handlePrimaryButtonClick()"
    class="next-button"
    mat-button
    matStepperNext
  >
    {{ 'ORDER_METHOD_MODAL.BUTTON.NEXT' | translate }}
  </button>
</ng-template>
