import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DeliveryDatePicker } from './models/delivery-date-picker';
import { DeliveryDatePickerTransformationService } from './delivery-date-picker-transformation.service';
import { map } from 'rxjs/operators';
import { DeliveryScheduleFacade } from 'src/app/core/store/delivery-schedule/delivery-schedule.facade';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { SessionFacade } from '../../../core/store/session/session.facade';

@Injectable({ providedIn: 'root' })
export class DeliveryDatePickerAggregatorService {
  constructor(
    private cartFacade: CartFacade,
    private sessionFacade: SessionFacade,
    private deliveryScheduleService: DeliveryScheduleFacade,
    private transformationService: DeliveryDatePickerTransformationService,
  ) {}

  aggregateAndTransform(
    isFirstDisplay: boolean,
    isPastCutoff: boolean,
  ): Observable<DeliveryDatePicker> {
    const cart$ = this.cartFacade.getLoadedCart();
    const deliverySchedules$ =
      this.deliveryScheduleService.getLoadedDeliverySchedules();
    const activeCustomer$ = this.sessionFacade.getLoadedActiveCustomer();
    const currentSystem$ = this.sessionFacade.getLoadedCurrentSystem();

    return combineLatest([
      cart$,
      deliverySchedules$,
      activeCustomer$,
      currentSystem$,
    ]).pipe(
      map(([cart, deliverySchedules, activeCustomer, currentSystem]) => {
        return this.transformationService.transform(
          cart,
          deliverySchedules,
          activeCustomer,
          isFirstDisplay,
          currentSystem,
          isPastCutoff,
        );
      }),
    );
  }
}
