<div class="page-header">
  <div class="container-title">
    <h1 *ngIf="!isMobile || options.showTitle">
      {{ 'MULTI_CUSTOMER_SELECTION.LOCATIONS' | translate }}
    </h1>
    <section class="total-locations-selected">
      ({{ chosenCustomers.length }}/{{ customerUnits.length }}
      {{ 'MULTI_CUSTOMER_SELECTION.SELECTED' | translate }})
    </section>
  </div>
  <naoo-search-field
    (searchTextEmitter)="updateFilter($event)"
    [searchPlaceholderText]="'CUSTOMER_UNIT.SEARCH'"
    [searchHintText]="'CUSTOMER_UNIT.SEARCH_HINT'"
  ></naoo-search-field>
</div>
<form
  [formGroup]="customersForm"
  *ngIf="(searchableCustomers | filterBy: customerFilter)?.length > 0"
>
  <header>
    <mat-checkbox
      class="select-all"
      formControlName="selectAll"
      name="selectAll"
      (change)="toggleSelectAll($event)"
      >{{
        customerFilter.searchableCustomerAttributes.length === 0
          ? ('MULTI_CUSTOMER_SELECTION.SELECT_ALL'
            | translate: { totalLocations: customerUnits.length })
          : ('MULTI_CUSTOMER_SELECTION.SELECT_ALL_RESULTS'
            | translate: { totalLocations: customerUnits.length })
      }}</mat-checkbox
    >
    <button
      class="clear-button"
      (click)="clear()"
      [disabled]="chosenCustomers.length === 0"
    >
      {{ 'MULTI_CUSTOMER_SELECTION.CLEAR' | translate }}
    </button>
  </header>
  <section
    formArrayName="customerUnits"
    [tabIndex]="-1"
    class="locations-section"
    [style.height]="options.cardContainerHeight"
  >
    <mat-grid-list
      [cols]="numberOfColumns | async"
      gutterSize="1px"
      [rowHeight]="options.rowHeight"
      [style.overflowY]="options.overflowY"
      class="card-grid"
    >
      <mat-grid-tile
        *ngFor="
          let searchCustomer of searchableCustomers | filterBy: customerFilter;
          let i = index
        "
      >
        <mat-checkbox
          [formControlName]="i"
          class="select-customer-box"
          [checked]="searchCustomer.isChecked"
          (change)="valueChange(searchableCustomers, i, searchCustomer, $event)"
        >
          <div class="customer-info">
            <div class="customer-name">
              {{ searchCustomer.customer.name }}
            </div>
            <div class="customer-number">
              #{{ searchCustomer.customer.customerDisplayId }}
            </div>
            <div class="customer-address">
              {{ searchCustomer.customer.address.line2 }}
            </div>
          </div>
        </mat-checkbox>
      </mat-grid-tile>
    </mat-grid-list>
  </section>
</form>
<naoo-no-results
  *ngIf="(searchableCustomers | filterBy: customerFilter)?.length === 0"
  [searchText]="searchText"
  [isMobile]="isMobile"
></naoo-no-results>
