import { Localized } from 'src/app/shared/models/localized';
import { MaterialUnitInfo } from '../../../shared/models/material-info';
import { Severity } from '../../../core/services/order-confirmation/models/order-confirmation-record';
import { OrderType } from '../../../shared/models/order-type';

export interface OrderConfirmation {
  materialRelatedRouteDate?: Date;
  headerErrors: HeaderError[];
  header: OrderConfirmationHeader;
  orders: OrderConfirmationOrders;
}

export interface OrderItemIssues {
  errorMaterials: OrderItem[];
  warningMaterials: OrderItem[];
}

export interface OrderConfirmationOrders extends OrderItemIssues {
  stillProcessingMaterials: OrderItem[];
  mainOrders: Order[];
  nonStockOrders: Order[];
  dropShipOrders: Order[];
}

export interface HeaderError {
  primaryText: Localized<string>;
  secondaryText: Localized<string>;
}

export interface OrderConfirmationHeader {
  customerUnitName: string;
  customerNumber: string;
  customerTimeZone: string;
  shipToAddress: ShipToAddress;
  submittedOn: Date;
  headerTotals: HeaderTotals;
  userName?: string;
  shipToLabel?: string;
}

export interface ShipToAddress {
  shipAddress1: string;
  shipAddress2: string;
  city: string;
  province: string;
  zipCode: string;
}

export interface UnitTotals {
  uom: string;
  total: number;
}

export interface HeaderTotals {
  lineCount: number;
  itemCount: number;
  estimatedCost: number;
  totalDiscountAmount?: number;
}

export interface Order {
  totalQuantity: number;
  subTotalCost?: number;
  shippingCost?: number;
  shipToAddress?: ShipToAddress;
  dropShipOrderIndex?: number;
  estimatedTotal: number;
  items: OrderItem[];
  orderNumber?: string;
  poNumber?: string;
  requestedCustomerArrivalDate?: string;
  orderType?: OrderType;
  requestedPickupTimestamp?: string;
  deliveryWindowStartTimestamp?: string;
  totalExtendedPrice?: number;
  totalShipping?: number;
  labelsAndDates?: OrderLabelsAndDates;
}

export interface OrderItem {
  orderLines: LineDetail[];
  itemDetail: ItemDetail;
}

export interface LineDetail {
  uom: string;
  displayCode: string;
  catchWeightUom?: string;
  price?: number;
  quantityOrdered: number;
  totalLinePrice?: number;
  quantityConfirmed?: number;
  lineSeverity: Severity;
  quantityConfirmedIcon: QuantityConfirmedIcon;
  quantityConfirmedClass: StatusClass;
  lineStatusDetails?: LineStatusDetail[];
  discountAmount?: number | null;
  substituteForMaterial?: string;
}

export enum StatusClass {
  Error = 'error',
  Warning = 'warning',
  None = '',
}

export enum QuantityConfirmedIcon {
  Error = 'exception-icon-v1',
  Warning = 'warning-icon-v3',
  None = '',
}

export enum SeverityIcon {
  Error = 'exception-icon-v1',
  Warning = 'warning-icon-v3',
  None = '',
}

export interface LineStatusDetail {
  lineStatusClass: StatusClass;
  icon: SeverityIcon;
  primaryLineStatus: Localized<string>;
  secondaryLineStatus?: Localized<string>;
}

export interface ItemDetail {
  id: string;
  customerMaterialNumber: string;
  description: Localized<string>;
  brand: Localized<string>;
  dimensions: MaterialDimensions;
  totalDiscountAmount?: number;
  substitutedItem?: ItemDetail;
}

export interface MaterialDimensions {
  innerPackSize: string;
  units: MaterialUnitInfo[];
  isCatchWeight: boolean;
  baseUomNetWeight: string;
}

export interface OrderLabelsAndDates {
  splitOrderTitle?: string;
  orderTitle?: string;
  deliveryDateLabel?: string;
  deliveryDate?: Localized<string>;
  deliveryTime?: Localized<string>;
}
