import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EMPTY, firstValueFrom } from 'rxjs';
import { OrderConfirmation } from './models/order-confirmation';
import { OrderConfirmationRetalixAggregatorService } from './order-confirmation-retalix-aggregator.service';
import { CartOrderService } from '../../core/services/cart-order/cart-order.service';
import { exhaustMap, finalize } from 'rxjs/operators';
import { CartOrderResponse } from 'src/app/core/services/cart-order/models/cart-order';
import { LocationChangeEvent, LocationStrategy } from '@angular/common';
import { NaooConstants } from '../../shared/NaooConstants';
import { SessionFacade } from '../../core/store/session/session.facade';
import { OrderConfirmationAggregatorService } from './order-confirmation-aggregator.service';
import { OrderConfirmationCompleteResponse } from '../../core/services/order-confirmation/models/order-confirmation-record';
import { CurrentSystem } from 'src/app/core/services/session/models/session-record';
import { CartFacade } from '../../core/store/cart/cart.facade';

export const orderConfirmationResolver: ResolveFn<OrderConfirmation> = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Promise<OrderConfirmation> => {
  const cartFacade = inject(CartFacade);
  const cartOrderService = inject(CartOrderService);
  const orderConfirmationRetalixAggregatorService = inject(
    OrderConfirmationRetalixAggregatorService,
  );
  const orderConfirmationAggregatorService = inject(
    OrderConfirmationAggregatorService,
  );
  const locationStrategy = inject(LocationStrategy);
  const router = inject(Router);
  const sessionFacade = inject(SessionFacade);
  const window = inject(Window);

  let navigatedFromHistory = false;
  await locationStrategy.onPopState((event: LocationChangeEvent) => {
    if (
      event.type === 'popstate' &&
      window.location.href.includes(NaooConstants.ORDER_CONFIRMATION_URL)
    ) {
      navigatedFromHistory = true;
    }
  });

  let isRetalix: boolean;
  await sessionFacade.getLoadedCurrentSystem().subscribe((value) => {
    isRetalix = CurrentSystem.Retalix === value;
  });

  if (
    previousUrlWasConfirmation(router) ||
    navigatedFromHistory ||
    !router.getCurrentNavigation().previousNavigation
  ) {
    navigatedFromHistory = false;
    await router.navigate([NaooConstants.CART_PATH]);
    return firstValueFrom(EMPTY);
  }

  cartFacade.updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded();

  return firstValueFrom(
    cartOrderService.submit(isRetalix).pipe(
      exhaustMap(([submitResponse, cartRouteDate]) => {
        if (isRetalix) {
          return orderConfirmationRetalixAggregatorService.aggregateAndTransform(
            submitResponse as CartOrderResponse,
            cartRouteDate,
          );
        }
        return orderConfirmationAggregatorService.aggregateAndTransform(
          submitResponse as OrderConfirmationCompleteResponse,
          cartRouteDate,
        );
      }),
      finalize(() => finalizeRedirect(cartFacade)),
    ),
  );
};

function finalizeRedirect(cartFacade: CartFacade): void {
  cartFacade.refreshCart();
}

function previousUrlWasConfirmation(router: Router): boolean {
  return (
    NaooConstants.ORDER_CONFIRMATION_URL ===
    router
      .getCurrentNavigation()
      ?.previousNavigation?.extractedUrl?.queryParamMap?.get('from')
  );
}
