import {
  initialMaterialInfoState,
  materialInfoAdapter,
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
  MaterialInfoState,
} from './material-info.state';
import { MaterialInfoActions } from './material-info.actions';
import { Update } from '@ngrx/entity';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';
import { createReducer, on } from '@ngrx/store';

export const materialInfoReducer = createReducer(
  initialMaterialInfoState,
  on(
    MaterialInfoActions.loadMaterialInfo,
    (state, action): MaterialInfoState =>
      loadMaterialInfo(state, action.materialNumbers),
  ),
  on(
    MaterialInfoActions.getMaterialInfo,
    (state, action): MaterialInfoState =>
      getMaterialInfo(state, action.materialNumbers),
  ),
  on(
    MaterialInfoActions.getMaterialInfoSuccess,
    (state, action): MaterialInfoState =>
      getMaterialInfoSuccess(state, action.materialInfoRecords),
  ),
  on(
    MaterialInfoActions.getMaterialInfoUnavailable,
    (state, action): MaterialInfoState =>
      getMaterialInfoUnavailable(state, action.materialNumbers),
  ),
  on(
    MaterialInfoActions.getMaterialInfoError,
    (state, action): MaterialInfoState =>
      getMaterialInfoError(state, action.materialNumbers),
  ),
  on(
    MaterialInfoActions.clearMaterialInfo,
    (): MaterialInfoState => initialMaterialInfoState,
  ),
);

function loadMaterialInfo(
  state: MaterialInfoState,
  materialNumbers: string[],
): MaterialInfoState {
  const upsertedEntities: MaterialInfoRecordState[] = materialNumbers
    .filter(
      (id) =>
        !state.entities[id] ||
        state.entities[id].status === MaterialInfoRecordStatus.Error,
    )
    .map((id) => {
      return {
        materialNumber: id,
        status: MaterialInfoRecordStatus.Queued,
        record: undefined,
      };
    });
  return materialInfoAdapter.upsertMany(upsertedEntities, state);
}

function getMaterialInfo(
  state: MaterialInfoState,
  materialNumbers: string[],
): MaterialInfoState {
  const queuedEntities: Update<MaterialInfoRecordState>[] = materialNumbers
    .filter(
      (id) => state.entities[id]?.status === MaterialInfoRecordStatus.Queued,
    )
    .map((id) => {
      return {
        id: id,
        changes: {
          status: MaterialInfoRecordStatus.Requested,
        },
      };
    });
  return materialInfoAdapter.updateMany(queuedEntities, state);
}

function getMaterialInfoSuccess(
  state: MaterialInfoState,
  materialRecords: MaterialRecord[],
): MaterialInfoState {
  const updatedMaterialRecordStates: Update<MaterialInfoRecordState>[] =
    materialRecords.map((materialRecord) => {
      return {
        id: materialRecord.materialNumber,
        changes: {
          record: materialRecord,
          status: MaterialInfoRecordStatus.Success,
        },
      };
    });
  return materialInfoAdapter.updateMany(updatedMaterialRecordStates, state);
}

function getMaterialInfoUnavailable(
  state: MaterialInfoState,
  materialNumbers: string[],
): MaterialInfoState {
  const updatedMaterialRecordStates: Update<MaterialInfoRecordState>[] =
    materialNumbers.map((id) => {
      return {
        id: id,
        changes: {
          status: MaterialInfoRecordStatus.Unavailable,
        },
      };
    });
  return materialInfoAdapter.updateMany(updatedMaterialRecordStates, state);
}

function getMaterialInfoError(
  state: MaterialInfoState,
  materialNumbers: string[],
): MaterialInfoState {
  const updatedMaterialRecordStates: Update<MaterialInfoRecordState>[] =
    materialNumbers.map((id) => {
      return {
        id: id,
        changes: {
          status: MaterialInfoRecordStatus.Error,
        },
      };
    });
  return materialInfoAdapter.updateMany(updatedMaterialRecordStates, state);
}
