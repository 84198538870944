import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialRecommendations } from '../../shared/services/material-recommendations/models/material-recommendations';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialListRow } from '../models/material-list';
import { MaterialRowContext } from '../../core/store/material-row/models/material-row';
import { Language } from '../../core/services/session/models/session-record';
import { LocalizationService } from '../../shared/services/translation/localization.service';

@Component({
  selector: 'naoo-material-recommendation',
  templateUrl: './material-recommendation.component.html',
  styleUrls: ['./material-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialRecommendationComponent implements OnInit, OnDestroy {
  @Input() recommendedMaterials: MaterialRecommendations[] = [];

  @Output() materialClick = new EventEmitter<string>();

  isMobile: boolean;
  destroyed$ = new Subject();

  constructor(
    private deviceIdentifierService: DeviceIdentifierService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private localizationService: LocalizationService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setAppDeviceType();

    this.recommendedMaterials.forEach((recommendedMaterial) => {
      const materialNumbers = recommendedMaterial.materialListRows.map(
        (listRow: MaterialListRow) => listRow.value as string,
      );
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        materialNumbers,
        MaterialRowContext.Recommendation,
        {
          analytics: {
            recommendationEngineName:
              this.getRecommendationEngineName(recommendedMaterial),
          },
        },
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get language(): Language {
    return this.localizationService.currentLanguage;
  }

  getRecommendationEngineName(
    recommendedMaterial: MaterialRecommendations,
  ): string {
    return recommendedMaterial.title[this.language];
  }

  private setAppDeviceType(): void {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetector.markForCheck();
      });
  }
}
