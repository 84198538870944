import { GuideCategoryHeader } from 'src/app/guides/shared/guides';

export enum MaterialListRowType {
  CategoryHeader = 'Category Header',
  MaterialRow = 'Material Row',
}

export interface MaterialListRow {
  type: MaterialListRowType;
  value: string | GuideCategoryHeader;
  sponsored?: boolean;
  viewableSponsor?: boolean;
  searchResultsIndex?: number;
}
