import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  nutritionAdapter,
  NutritionRecordState,
  NutritionState,
} from './nutrition.state';
import { Dictionary } from '@ngrx/entity';

const selectNutritionFeature =
  createFeatureSelector<NutritionState>('nutrition');

export const selectAllNutritionRecords = createSelector(
  selectNutritionFeature,
  (state) =>
    nutritionAdapter.getSelectors().selectEntities(state.nutritionRecords),
);

export const selectNutritionRecord = (materialNumber: string) =>
  createSelector(
    selectNutritionFeature,
    (records) => records.nutritionRecords.entities[materialNumber],
  );

export const selectLoadedNutrition = (materialNumber: string) =>
  createSelector(
    selectNutritionRecord(materialNumber),
    (nutritionRecord) => nutritionRecord?.record,
  );

export const selectNutritionRecordDictionary = (materialNumbers: string[]) =>
  createSelector(selectAllNutritionRecords, (records) => {
    const dictionary: Dictionary<NutritionRecordState> = {};
    materialNumbers.forEach((materialNumber: string | number) => {
      dictionary[materialNumber] = records[materialNumber];
    });
    return dictionary;
  });
