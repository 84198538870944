import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomGuideNameExistsValidator } from '../../../shared/services/validators/custom-guide-name-exists-validator.service';
import { NaooConstants } from '../../../shared/NaooConstants';
import { ErrorStateMatcher } from '@angular/material/core';
import { CreateCustomGuideErrorStateMatcher } from '../../../lists/create-custom-guide-modal/create-custom-guide-error-state-matcher';

@Component({
  selector: 'naoo-import-guide-create-custom-guide',
  templateUrl: './import-guide-create-custom-guide.component.html',
  styleUrls: ['./import-guide-create-custom-guide.component.scss'],
})
export class ImportGuideCreateCustomGuideComponent implements OnInit {
  readonly VALIDATION = {
    CUSTOM_GUIDE_NAME: {
      MAX_LENGTH: 40,
    },
  };

  createGuideForm: FormGroup;
  customGuideNameErrorStateMatcher: ErrorStateMatcher;
  @Output() cancel = new EventEmitter();
  @Output() continue = new EventEmitter<string>();
  @Input() customGuideName = '';
  @ViewChild('customGuideInput', { static: true }) customGuideInput: ElementRef;

  constructor(
    private customGuideNameExistsValidator: CustomGuideNameExistsValidator,
  ) {}

  ngOnInit(): void {
    this.customGuideNameErrorStateMatcher =
      new CreateCustomGuideErrorStateMatcher();

    const customGuideNameFormControl = new FormControl(
      '',
      [
        Validators.required,
        Validators.maxLength(this.VALIDATION.CUSTOM_GUIDE_NAME.MAX_LENGTH),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
      ],
      this.customGuideNameExistsValidator
        .validate()
        .bind(this.customGuideNameExistsValidator),
    );

    this.createGuideForm = new FormGroup({
      customGuideName: customGuideNameFormControl,
    });

    if (this.customGuideName.length > 0) {
      customGuideNameFormControl.setValue(this.customGuideName);
    }
    this.customGuideInput.nativeElement.focus();
  }

  closeModal() {
    this.cancel.emit();
  }

  nextStep() {
    const customGuideName = this.createGuideForm.value['customGuideName'];
    this.continue.emit(customGuideName);
  }
}
