import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductRowDetailsComponent } from './product-row-details/product-row-details.component';
import { ProductMenuComponent } from './product-menu/product-menu.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { ProductGuideEditComponent } from './product-guide-edit/product-guide-edit.component';
import { ProductEditMenuComponent } from './product-edit-menu/product-edit-menu.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCustomGuideModalComponent } from '../lists/create-custom-guide-modal/create-custom-guide-modal.component';
import { ProductFlagComponent } from './product-flag/product-flag.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProductParOrderQuantityBoxComponent } from './product-par-order-quantity-box/product-par-order-quantity-box.component';
import { ProductDecimalQuantityBoxComponent } from './product-decimal-quantity-box/product-decimal-quantity-box.component';
import { NutritionDetailsComponent } from '../product-details/nutrition-details/nutrition-details.component';
import { AllergenDetailsComponent } from '../product-details/allergen-details/allergen-details.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    OverlayModule,
    TranslateModule.forChild(),
    MatButtonModule,
  ],
  declarations: [
    ProductRowDetailsComponent,
    ProductGuideEditComponent,
    ProductMenuComponent,
    ProductEditMenuComponent,
    ProductFlagComponent,
    CreateCustomGuideModalComponent,
    ProductParOrderQuantityBoxComponent,
    ProductDecimalQuantityBoxComponent,
    NutritionDetailsComponent,
    AllergenDetailsComponent,
  ],
  exports: [
    ProductRowDetailsComponent,
    ProductGuideEditComponent,
    ProductEditMenuComponent,
    ProductMenuComponent,
    ProductFlagComponent,
    NutritionDetailsComponent,
    AllergenDetailsComponent,
  ],
})
export class ProductRowModule {}
