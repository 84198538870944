<div class="main-container">
  <div class="header-container">
    <div class="order-cell import-order-line">
      {{ 'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.LINE' | translate }}
    </div>
    <div
      class="order-cell import-order-customer-material-number customer-material-header"
      *ngIf="includeCustomerMaterial"
    >
      {{
        'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.CUSTOMER_MATERIAL_NUMBER'
          | translate
      }}
    </div>
    <div class="order-cell import-order-item-code">
      {{ 'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.ITEM_CODE' | translate }}
    </div>
    <div class="order-cell import-order-case-quantity">
      {{ 'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.CASE_QTY' | translate }}
    </div>
    <div class="order-cell import-order-unit-quantity">
      {{ 'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.UNIT_QTY' | translate }}
    </div>
    <div class="order-cell import-order-warning"></div>
    <div class="order-cell import-order-invalid-items">
      {{ 'IMPORT_ORDER.ROW_ERROR.TABLE_HEADER.INVALID_ITEMS' | translate }}
    </div>
  </div>
  <div class="table-body" [ngClass]="{ 'short-table': shouldShortenTable }">
    <div
      *ngFor="let importOrderRow of importOrderRows; let odd = odd"
      class="import-order-row"
      [ngClass]="{ 'import-order-row-odd': odd }"
    >
      <div class="order-cell import-order-line">
        <span class="import-order-line-value">{{
          importOrderRow.rowNumber
        }}</span>
      </div>
      <div
        class="order-cell import-order-customer-material-number customer-material-content"
        *ngIf="includeCustomerMaterial"
      >
        <span class="import-order-customer-material-number-value">{{
          importOrderRow.customerMaterialNumber
        }}</span>
      </div>
      <div class="order-cell import-order-item-code">
        <span class="import-order-item-code-value">{{
          importOrderRow.rawId
        }}</span>
      </div>
      <div class="order-cell import-order-case-quantity">
        <span class="import-order-case-quantity-value">{{
          importOrderRow.caseQuantity
        }}</span>
      </div>
      <div class="order-cell import-order-unit-quantity">
        <span class="import-order-unit-quantity-value">{{
          importOrderRow.eachQuantity
        }}</span>
      </div>
      <div class="order-cell import-order-warning">
        <mat-icon
          svgIcon="warning-icon-v1"
          class="import-order-warning-value"
        ></mat-icon>
      </div>
      <div class="order-cell import-order-invalid-items">
        <span class="import-order-invalid-items-value">{{
          importOrderErrorMessage(importOrderRow.error) | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
