<ng-container *ngIf="hasLoaded$ | async">
  <div class="store-selection-wrapper">
    <naoo-google-autocomplete
      (selectedLatLongEmitter)="selectLatLong($event)"
    ></naoo-google-autocomplete>
    <div class="store-list">
      <ng-container
        *ngFor="
          let storeRecord of visibleStoreRecords;
          index as indexPosition;
          trackBy: trackByStorePlantId
        "
      >
        <div
          class="store-select"
          tabindex="0"
          [ngClass]="{
            'selected-store': storeRecord.storePlantId === selectedStorePlantId,
          }"
          (click)="selectStorePlantId(storeRecord.storePlantId)"
          (keydown.enter)="selectStorePlantId(storeRecord.storePlantId)"
          (keydown.arrowdown)="focusIndexPosition(indexPosition + 1)"
          (keydown.arrowup)="focusIndexPosition(indexPosition - 1)"
        >
          <naoo-store-display
            [storeRecord]="storeRecord"
            [isMobileWidth]="isMobileWidth"
            [customerTimeZone]="customerTimeZone"
          ></naoo-store-display>
        </div>
      </ng-container>
    </div>
  </div>

  <naoo-google-map
    [userLatLong]="userLatLong$ | async"
    [storeMapMarkers]="storeMapMarkers$ | async"
    [selectedStorePlantId]="selectedStorePlantId"
    [selectedAddressLatLong]="selectedAddressLatLong"
    (selectedStorePlantIdEmitter)="selectStorePlantId($event)"
    (visibleStoreRecords)="updateVisibleStores($event)"
  >
  </naoo-google-map>
</ng-container>
