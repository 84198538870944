import { NaooWeightPipe } from './pipes/naoo-weight.pipe';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NaooPricePipe } from './pipes/naoo-price.pipe';
import { TableHeaderComponent } from './table-header/table-header.component';
import { AddToGuideToastComponent } from './toast-messages/add-to-guide-toast/add-to-guide-toast.component';
import { AddToCriticalItemsGuideToastComponent } from './toast-messages/add-to-critical-items-guide-toast/add-to-critical-items-guide-toast.component';
import { NaooDatePipe } from './pipes/naoo-date.pipe';
import { Naoo24to12HrPipe } from './pipes/naoo-24to12-hr.pipe';
import { MoveToCategoryToastComponent } from './toast-messages/move-to-category-toast/move-to-category-toast.component';
import { RemoveFromCategoryToastComponent } from './toast-messages/remove-from-category-toast/remove-from-category-toast.component';
import { DeleteCategoryToastComponent } from './toast-messages/delete-category-toast/delete-category-toast.component';
import { DeleteCustomGuideToastComponent } from './toast-messages/delete-custom-guide-toast/delete-custom-guide-toast.component';
import { NaooStringDefaulterPipe } from './string-defaulter/naoo-string-defaulter.pipe';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { AddToGuideMenuComponent } from './add-to-guide-menu/add-to-guide-menu.component';
import { LoadingModalComponent } from './modals/loading-modal/loading-modal.component';
import { RouterModule } from '@angular/router';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { HeroImagesComponent } from './hero-images/hero-images.component';
import { MatMenuModule } from '@angular/material/menu';
import { CustomerUnitSelectorComponent } from '../customer-unit-selection/customer-unit-selector/customer-unit-selector.component';
import { CustomerUnitInfoComponent } from '../customer-unit-selection/customer-unit-info/customer-unit-info.component';
import { GuideCategoryHeaderComponent } from '../lists/guide-category-header/guide-category-header.component';
import { ActionIconComponent } from './action-icon/action-icon.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ImportOrderButtonComponent } from './import-order-button/import-order-button.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { GenericModalComponent } from './modals/generic-modal/generic-modal.component';
import { FileInputComponent } from './file-input/file-input.component';
import { ImportFileComponent } from './modals/import-modals/import-file/import-file.component';
import { NaooConstants } from './NaooConstants';
import { NaooIconService } from './services/images/naoo-icon.service';
import { ProductImageComponent } from './product-image/product-image.component';
import { RowErrorComponent } from './modals/import-modals/error-modals/row-error/row-error.component';
import { CompleteModalComponent } from './modals/complete-modal/complete-modal.component';
import { FileErrorComponent } from './modals/import-modals/error-modals/file-error/file-error.component';
import { CopyGuideModalComponent } from './modals/copy-guide-modal/copy-guide-modal.component';
import { IconBadgeComponent } from './icon-badge/icon-badge.component';
import { BannerMessageComponent } from './banner-message/banner-message.component';
import { BannerOfflineContainerComponent } from './banner-offline-container/banner-offline-container.component';
import { BannerOfflineMessageComponent } from './banner-offline-message/banner-offline-message.component';
import { DuplicateCustomGuideToastComponent } from './toast-messages/duplicate-custom-guide-toast/duplicate-custom-guide-toast.component';
import { MultiCustomerSelectComponent } from './multi-customer-select/multi-customer-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WarningAlertComponent } from './warning-alert/warning-alert.component';
import { CopyGuideSelectCustomerComponent } from './modals/copy-guide-modal/steps/copy-guide-select-customer/copy-guide-select-customer.component';
import { CopyGuideCopyActionComponent } from './modals/copy-guide-modal/steps/copy-guide-copy-action/copy-guide-copy-action.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { NaooBrandPipe } from './pipes/naoo-brand.pipe';
import { NoResultsComponent } from './no-results/no-results.component';
import { CompareCheckboxComponent } from './compare-checkbox/compare-checkbox.component';
import { CompareCardComponent } from '../material-comparison/compare-docket/compare-docket-content/compare-card/compare-card.component';
import { RemoveFromCriticalItemsGuideToastComponent } from './toast-messages/remove-from-critical-items-guide-toast/remove-from-critical-items-guide-toast.component';
import { SearchFieldComponent } from './search-bar/search-field.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ExportModalComponent } from './modals/export-modal/export-modal.component';
import { ExportAvailableDataComponent } from './modals/export-modal/export-available-data/export-available-data.component';
import { ExportSelectedDataComponent } from './modals/export-modal/export-selected-data/export-selected-data.component';
import { CreateCustomGuideToastComponent } from './toast-messages/create-custom-guide-toast/create-custom-guide-toast.component';
import { DecodeHtmlEntityPipe } from './pipes/decode-html-entity.pipe';
import { AddToCustomGuideComponent } from './add-to-custom-guide/add-to-custom-guide.component';
import { GenericExpansionPanelComponent } from './generic-expansion-panel/generic-expansion-panel.component';
import { PrintHeaderComponent } from './print-header/print-header.component';
import { MaterialUnitsPipe } from './pipes/material-units.pipe';
import { PackSizePipe } from './pipes/pack-size.pipe';
import { MaterialViewSelectComponent } from './material-view-select/material-view-select.component';
import { GenericDisableableLinkComponent } from './generic-disableable-link/generic-disableable-link.component';
import { GenericHelpModalComponent } from './modals/generic-helper-modal/generic-help-modal.component';
import { GenericHelpExpansionPanelComponent } from './modals/generic-helper-modal/expansion-panel/generic-help-expansion-panel.component';
import { NaooFloatingPointPipe } from './pipes/naoo-floating-point.pipe';
import { NaooImagePipe } from './pipes/naoo-image.pipe';
import { NaooDeliveryOrShipPipe } from './pipes/delivery-or-ship.pipe';
import { NaooDecimalPipe } from './pipes/naoo-decimal.pipe';
import { NaooCurrencyPipe } from './pipes/naoo-currency.pipe';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { BannerAdComponent } from './banner-ad/banner-ad.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FulfillmentModalService } from './services/fulfillment-modal/fulfillment-modal.service';
import { SelectOrderMethodService } from './services/select-order-method/select-order-method.service';
import { NaooTimeRangePipe } from './pipes/naoo-time-range.pipe';
import { NaooPickupDatePipe } from './pipes/naoo-pickup-date.pipe';
import { StoreDisplayComponent } from './store-display/store-display.component';
import { CartImportToastComponent } from './toast-messages/cart-import/cart-import-toast/cart-import-toast.component';
import { NaooLoyaltyPipe } from './pipes/naoo-loyalty-points.pipe';
import { CreditCardModalComponent } from './modals/credit-card-modal/credit-card-modal.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DateRangeSelectorComponent } from './date-range-selector/date-range-selector.component';
import { GoPointsLogoComponent } from './go-points-logo/go-points-logo.component';
import { VirtualScrollerModule } from '../vendor/ngx-virtual-scroller/virtual-scroller';
import { SubmitLoadingModalComponent } from './modals/submit-loading-modal/submit-loading-modal.component';
import { ObserveVisibilityDirective } from './directives/observe-visibility-directive';
import { GenericBannerComponent } from './generic-banner/generic-banner.component';
import { NaooCarouselImagePipe } from './pipes/naoo-carousel-image.pipe';

const { icons } = NaooConstants;

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    Angulartics2Module,
    VirtualScrollerModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    RouterModule,
    MatSelectModule,
    MatMenuModule,
    MatSlideToggleModule,
    FilterPipeModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    MatTooltipModule,
  ],
  declarations: [
    NaooWeightPipe,
    LoadingModalComponent,
    NaooPricePipe,
    NaooPickupDatePipe,
    TableHeaderComponent,
    AddToGuideToastComponent,
    AddToCriticalItemsGuideToastComponent,
    NaooDatePipe,
    NaooDecimalPipe,
    NaooCurrencyPipe,
    Naoo24to12HrPipe,
    NaooTimeRangePipe,
    MoveToCategoryToastComponent,
    RemoveFromCategoryToastComponent,
    DeleteCategoryToastComponent,
    DeleteCustomGuideToastComponent,
    DuplicateCustomGuideToastComponent,
    CreateCustomGuideToastComponent,
    NaooStringDefaulterPipe,
    MaterialUnitsPipe,
    BreadcrumbComponent,
    AddToGuideMenuComponent,
    LanguageMenuComponent,
    BackButtonComponent,
    HeroImagesComponent,
    CustomerUnitSelectorComponent,
    CustomerUnitInfoComponent,
    GuideCategoryHeaderComponent,
    ActionIconComponent,
    HighlightPipe,
    ImportOrderButtonComponent,
    ModalHeaderComponent,
    GenericModalComponent,
    GenericHelpModalComponent,
    GenericHelpExpansionPanelComponent,
    GenericExpansionPanelComponent,
    FileInputComponent,
    ImportFileComponent,
    ProductImageComponent,
    RowErrorComponent,
    CompleteModalComponent,
    FileErrorComponent,
    CopyGuideModalComponent,
    WarningAlertComponent,
    BannerAdComponent,
    BannerMessageComponent,
    BannerOfflineContainerComponent,
    BannerOfflineMessageComponent,
    IconBadgeComponent,
    MultiCustomerSelectComponent,
    CopyGuideSelectCustomerComponent,
    CopyGuideCopyActionComponent,
    SlideToggleComponent,
    NaooBrandPipe,
    NoResultsComponent,
    CompareCheckboxComponent,
    CompareCardComponent,
    SearchFieldComponent,
    ExportModalComponent,
    RemoveFromCriticalItemsGuideToastComponent,
    ExportAvailableDataComponent,
    ExportSelectedDataComponent,
    AddToCustomGuideComponent,
    ExportSelectedDataComponent,
    DecodeHtmlEntityPipe,
    PrintHeaderComponent,
    PackSizePipe,
    MaterialViewSelectComponent,
    GenericDisableableLinkComponent,
    NaooFloatingPointPipe,
    NaooImagePipe,
    NaooCarouselImagePipe,
    NaooDeliveryOrShipPipe,
    CartImportToastComponent,
    StoreDisplayComponent,
    CreditCardModalComponent,
    ErrorMessageComponent,
    DateRangeSelectorComponent,
    NaooLoyaltyPipe,
    GoPointsLogoComponent,
    SubmitLoadingModalComponent,
    ObserveVisibilityDirective,
    GenericBannerComponent,
  ],
  exports: [
    NaooWeightPipe,
    AddToGuideMenuComponent,
    CommonModule,
    Angulartics2Module,
    VirtualScrollerModule,
    NaooPricePipe,
    NaooDatePipe,
    NaooPickupDatePipe,
    NaooDecimalPipe,
    NaooCurrencyPipe,
    Naoo24to12HrPipe,
    NaooTimeRangePipe,
    HighlightPipe,
    NaooStringDefaulterPipe,
    NaooBrandPipe,
    DecodeHtmlEntityPipe,
    MaterialUnitsPipe,
    PackSizePipe,
    TableHeaderComponent,
    BreadcrumbComponent,
    LanguageMenuComponent,
    BackButtonComponent,
    HeroImagesComponent,
    CustomerUnitSelectorComponent,
    CustomerUnitInfoComponent,
    GuideCategoryHeaderComponent,
    ActionIconComponent,
    ImportOrderButtonComponent,
    ModalHeaderComponent,
    FileInputComponent,
    ImportFileComponent,
    ProductImageComponent,
    FileErrorComponent,
    RowErrorComponent,
    CompleteModalComponent,
    CopyGuideModalComponent,
    WarningAlertComponent,
    BannerAdComponent,
    BannerMessageComponent,
    BannerOfflineContainerComponent,
    GenericExpansionPanelComponent,
    IconBadgeComponent,
    MultiCustomerSelectComponent,
    SlideToggleComponent,
    NoResultsComponent,
    CompareCheckboxComponent,
    CompareCardComponent,
    SearchFieldComponent,
    ExportModalComponent,
    AddToCustomGuideComponent,
    PrintHeaderComponent,
    MaterialViewSelectComponent,
    GenericDisableableLinkComponent,
    NaooFloatingPointPipe,
    NaooImagePipe,
    NaooCarouselImagePipe,
    NaooDeliveryOrShipPipe,
    StoreDisplayComponent,
    CreditCardModalComponent,
    ErrorMessageComponent,
    NaooLoyaltyPipe,
    DateRangeSelectorComponent,
    GoPointsLogoComponent,
    ObserveVisibilityDirective,
    GenericBannerComponent,
  ],
  providers: [
    NaooWeightPipe,
    NaooDeliveryOrShipPipe,
    MaterialUnitsPipe,
    NaooCurrencyPipe,
    NaooBrandPipe,
    SelectOrderMethodService,
    FulfillmentModalService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class SharedModule {
  readonly sharedIcons = [
    icons.copy_custom_guide_in_progress,
    icons.copy_custom_guide_failed,
    icons.copy_custom_guide_successful,
  ];

  constructor(public naooIconService: NaooIconService) {
    naooIconService.registerIcons(this.sharedIcons);
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
