import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExpressSchedulesEffects } from './express-schedules.effects';
import { expressSchedulesReducer } from './express-schedules.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('expressSchedules', expressSchedulesReducer),
    EffectsModule.forFeature([ExpressSchedulesEffects]),
  ],
})
export class ExpressSchedulesStoreModule {}
