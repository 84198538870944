import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NaooLocalStorage } from '../storage/local-storage/local-storage.service';
import { EnvironmentSpecificService } from '../services/environment-specific/environment-specific.service';

@Injectable({ providedIn: 'root' })
export class WiremockOnlyInterceptor implements HttpInterceptor {
  private shouldIntercept = false;

  constructor(
    private naooLocalStorage: NaooLocalStorage,
    environmentSpecificService: EnvironmentSpecificService,
  ) {
    this.shouldIntercept = environmentSpecificService.isWireMock();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.shouldIntercept) {
      const localStorageHeaders =
        this.naooLocalStorage.getItem('naoo-mock-state') || '';
      const key = 0;
      const value = 1;

      const headerList = localStorageHeaders
        .split(';')
        .map((header) => header.split('='));
      let requestHeaders = request.headers;
      for (const headerInfo of headerList) {
        if (headerInfo[key] && headerInfo[value]) {
          requestHeaders = requestHeaders.append(
            headerInfo[key],
            headerInfo[value],
          );
        }
      }
      request = request.clone({ headers: requestHeaders });
    }
    return next.handle(request);
  }
}
