import { Injectable } from '@angular/core';
import {
  NutritionBlock,
  NutritionBlockLine,
  NutritionInfo,
  NutritionLabel,
  NutritionLine,
} from './models/nutrition';
import { PicRecord } from '../../services/nutrition/models/pic-record';
import { Language } from '../../services/session/models/session-record';
import { getCollator } from '../../../shared/collator/collator';

@Injectable({ providedIn: 'root' })
export class NutritionTransformerService {
  private static readonly detectUrlsRegex =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?\S*)?/gi;
  private static readonly detectEmailsRegex = /([\w.-]+@[\w.-]+\.[\w-]+)/gi;
  private static readonly detectExtensionRegex =
    /((\.)(biz|com|edu|gov|net|org)\b)/gi;

  transformPicRecords(picRecords: PicRecord[]): NutritionLabel {
    return {
      nutritionInfos: picRecords
        .map(NutritionTransformerService.convertPicRecord)
        .sort((a, b) => getCollator(Language.en).compare(a.name, b.name)),
    };
  }

  private static convertPicRecord(record: PicRecord): NutritionInfo {
    const ingredients = record.itemIngredients;
    const ingredientsUrlMap = new Map<string, string>();
    ingredients
      ?.replace(NutritionTransformerService.detectEmailsRegex, '')
      .match(NutritionTransformerService.detectUrlsRegex)
      ?.filter(
        (url) => !!url.match(NutritionTransformerService.detectExtensionRegex),
      )
      .forEach((url) => {
        const updatedUrl = url.includes('://') ? url : `https://${url}`;
        ingredientsUrlMap.set(url, updatedUrl);
      });

    return {
      name: record.itemDescription,
      ingredients,
      ingredientsUrlMap,
      servingInformation: NutritionTransformerService.buildServingInfo(record),
      calorieInformation: NutritionTransformerService.buildCalorieInfo(record),
      nutrients: NutritionTransformerService.buildNutrients(record),
      focusedNutrients:
        NutritionTransformerService.buildFocusedNutrients(record),
      nutritionBlocks: NutritionTransformerService.buildNutritionBlocks(record),
    };
  }

  private static buildServingInfo(record: PicRecord): NutritionLine {
    return {
      amount: record.servingSize,
      roundedAmount: undefined,
      referenceDailyIntakeAmount: 0,
      uom: record.servingSizeUnit,
      label: record.gramEquivalent ? record.gramEquivalent + 'g' : null,
      level: 0,
      shouldReferenceDailyIntake: true,
    };
  }

  private static buildCalorieInfo(record: PicRecord): NutritionLine {
    return {
      amount: record.caloriesAmt,
      roundedAmount: record.caloriesNLEAAmt,
      referenceDailyIntakeAmount: 0,
      uom: record.caloriesUnitOfMeasure,
      label: record.caloriesLabel,
      level: 0,
      shouldReferenceDailyIntake: true,
    };
  }

  private static buildNutritionBlocks(record: PicRecord): NutritionBlock[] {
    return [
      {
        label: 'School Equivalents',
        lineItems:
          NutritionTransformerService.buildSchoolLunchEquivalents(record),
      },
      {
        label: 'Fat Soluble Vitamins',
        lineItems: NutritionTransformerService.buildFatSolubleVitamins(record),
      },
      {
        label: 'Water Soluble Vitamins',
        lineItems:
          NutritionTransformerService.buildWaterSolubleVitaminsBlockLines(
            record,
          ),
      },
      {
        label: 'Minerals',
        lineItems: NutritionTransformerService.buildMineralBlockLines(record),
      },
    ];
  }

  private static buildNutrients(record: PicRecord): NutritionLine[] {
    return [
      this.createNutritionLine(
        record.fatAmt,
        record.fatRDIAmt,
        record.fatUnitOfMeasure,
        record.fatLabel,
        record.fatNLEAAmt,
      ),
      this.createNutritionLine(
        record.saturatedFatAmt,
        record.saturatedFatRDIAmt,
        record.saturatedFatUnitOfMeasure,
        record.saturatedFatLabel,
        record.saturatedFatNLEAAmt,
        1,
      ),
      this.createNutritionLine(
        record.transFattyAcidAmt,
        record.transFattyAcidRDIAmt,
        record.transFattyAcidUnitOfMeasure,
        record.transFattyAcidLabel,
        record.transFattyAcidNLEAAmt,
        1,
        false,
      ),
      this.createNutritionLine(
        record.polyUnsaturatedFatAmt,
        record.polyUnsaturatedFatRDIAmt,
        record.polyUnsaturatedFatUnitOfMeasure,
        record.polyUnsaturatedFatLabel,
        record.polyUnsaturatedFatNLEAAmt,
        1,
        false,
      ),
      this.createNutritionLine(
        record.monoUnsaturatedFatAmt,
        record.monoUnsaturatedFatRDIAmt,
        record.monoUnsaturatedFatUnitOfMeasure,
        record.monoUnsaturatedFatLabel,
        record.monoUnsaturatedFatNLEAAmt,
        1,
        false,
      ),
      this.createNutritionLine(
        record.cholesterolAmt,
        record.cholesterolRDIAmt,
        record.cholesterolUnitOfMeasure,
        record.cholesterolLabel,
        record.cholesterolNLEAAmt,
      ),
      this.createNutritionLine(
        record.sodiumAmt,
        record.sodiumRDIAmt,
        record.sodiumUnitOfMeasure,
        record.sodiumLabel,
        record.sodiumNLEAAmt,
      ),
      this.createNutritionLine(
        record.carbohydrateAmt,
        record.carbohydrateRDIAmt,
        record.carbohydrateUnitOfMeasure,
        record.carbohydrateLabel,
        record.carbohydrateNLEAAmt,
      ),
      this.createNutritionLine(
        record.fiberAmt,
        record.fiberRDIAmt,
        record.fiberUnitOfMeasure,
        record.fiberLabel,
        record.fiberNLEAAmt,
        1,
      ),
      this.createNutritionLine(
        record.sugarsAmt,
        record.sugarsRDIAmt,
        record.sugarsUnitOfMeasure,
        record.sugarsLabel,
        record.sugarsNLEAAmt,
        1,
        false,
      ),
      this.createNutritionLine(
        record.addedSugarAmt,
        record.addedSugarRDIAmt,
        record.addedSugarUnitOfMeasure,
        record.addedSugarLabel,
        record.addedSugarNLEAAmt,
        2,
      ),
      this.createNutritionLine(
        record.proteinAmt,
        record.proteinRDIAmt,
        record.proteinUnitOfMeasure,
        record.proteinLabel,
        record.proteinNLEAAmt,
      ),
    ];
  }

  private static buildFocusedNutrients(record: PicRecord): NutritionLine[] {
    return [
      this.createNutritionLine(
        record.vitaminDMcgAmt,
        record.vitaminDMcgRDIAmt,
        record.vitaminDMcgUnitOfMeasure,
        record.vitaminDMcgLabel,
        undefined,
      ),
      this.createNutritionLine(
        record.calciumAmt,
        record.calciumRDIAmt,
        record.calciumUnitOfMeasure,
        record.calciumLabel,
        record.calciumNLEAAmt,
      ),
      this.createNutritionLine(
        record.ironAmt,
        record.ironRDIAmt,
        record.ironUnitOfMeasure,
        record.ironLabel,
        record.ironNLEAAmt,
      ),
      this.createNutritionLine(
        record.potassiumAmt,
        record.potassiumRDIAmt,
        record.potassiumUnitOfMeasure,
        record.potassiumLabel,
        record.potassiumNLEAAmt,
      ),
    ];
  }

  private static buildFatSolubleVitamins(
    record: PicRecord,
  ): NutritionBlockLine[] {
    return [
      this.createBlockLine(
        record.vitaminEMgLabel,
        record.vitaminEMgAmt,
        record.vitaminEMgUnitOfMeasure,
      ),
      this.createBlockLine(
        record.vitaminKLabel,
        record.vitaminKAmt,
        record.vitaminKUnitOfMeasure,
      ),
      this.createBlockLine(
        record.vitaminARAELabel,
        record.vitaminARAEAmt,
        record.vitaminARAEUnitOfMeasure,
      ),
    ];
  }

  private static buildMineralBlockLines(
    record: PicRecord,
  ): NutritionBlockLine[] {
    return [
      this.createBlockLine(
        record.phosphorousLabel,
        record.phosphorousAmt,
        record.phosphorousUnitOfMeasure,
      ),
      this.createBlockLine(
        record.zincLabel,
        record.zincAmt,
        record.zincUnitOfMeasure,
      ),
      this.createBlockLine(
        record.magnesiumLabel,
        record.magnesiumAmt,
        record.magnesiumUnitOfMeasure,
      ),
      this.createBlockLine(
        record.copperLabel,
        record.copperAmt,
        record.copperUnitOfMeasure,
      ),
      this.createBlockLine(
        record.iodineLabel,
        record.iodineAmt,
        record.iodineUnitOfMeasure,
      ),
    ];
  }

  private static buildWaterSolubleVitaminsBlockLines(
    record: PicRecord,
  ): NutritionBlockLine[] {
    return [
      this.createBlockLine(
        record.thiaminLabel,
        record.thiaminAmt,
        record.thiaminUnitOfMeasure,
      ),
      this.createBlockLine(
        record.riboflavinLabel,
        record.riboflavinAmt,
        record.riboflavinUnitOfMeasure,
      ),
      this.createBlockLine(
        record.niacinMgLabel,
        record.niacinMgAmt,
        record.niacinMgUnitOfMeasure,
      ),
      this.createBlockLine(
        record.pantothenicAcidLabel,
        record.pantothenicAcidAmt,
        record.pantothenicAcidUnitOfMeasure,
      ),
      this.createBlockLine(
        record.folicAcidLabel,
        record.folicAcidAmt,
        record.folicAcidUnitOfMeasure,
      ),
      this.createBlockLine(
        record.vitaminCLabel,
        record.vitaminCAmt,
        record.vitaminCUnitOfMeasure,
      ),
      this.createBlockLine(
        record.vitaminB6Label,
        record.vitaminB6Amt,
        record.vitaminB6UnitOfMeasure,
      ),
      this.createBlockLine(
        record.vitaminB12Label,
        record.vitaminB12Amt,
        record.vitaminB12UnitOfMeasure,
      ),
    ];
  }

  private static buildSchoolLunchEquivalents(
    record: PicRecord,
  ): NutritionBlockLine[] {
    if (record.servingSize) {
      return [
        this.createBlockLine(
          record.servingSizeLabel,
          record.childNutritionInfo,
        ),
        this.createBlockLine(
          record.schoolMeatLabel,
          record.schoolMeat,
          record.schoolMeatUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolFruitLabel,
          record.schoolFruit,
          record.schoolFruitUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolBreadLabel,
          record.schoolBread,
          record.schoolBreadUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolRedOrangeLabel,
          record.schoolRedOrange,
          record.schoolRedOrangeUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolDarkGreenLabel,
          record.schoolDarkGreen,
          record.schoolDarkGreenUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolStarchyLabel,
          record.schoolStarchy,
          record.schoolStarchyUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolBeansLabel,
          record.schoolBeans,
          record.schoolBeansUnitOfMeasure,
        ),
        this.createBlockLine(
          record.schoolOtherLabel,
          record.schoolOther,
          record.schoolOtherUnitOfMeasure,
        ),
        this.createBlockLine(
          record.childNutritionNotesLabel,
          record.childNutritionNotes,
        ),
      ];
    }
    return [
      this.createBlockLine(record.servingSizeLabel),
      this.createBlockLine(record.schoolMeatLabel),
      this.createBlockLine(record.schoolFruitLabel),
      this.createBlockLine(record.schoolBreadLabel),
      this.createBlockLine(record.schoolRedOrangeLabel),
      this.createBlockLine(record.schoolDarkGreenLabel),
      this.createBlockLine(record.schoolStarchyLabel),
      this.createBlockLine(record.schoolBeansLabel),
      this.createBlockLine(record.schoolOtherLabel),
      this.createBlockLine(record.childNutritionNotesLabel),
    ];
  }

  private static createBlockLine(
    label: string,
    amount?: number | string | undefined,
    uom?: string | undefined,
  ): NutritionBlockLine {
    return {
      label,
      amount,
      uom,
    };
  }

  private static createNutritionLine(
    amount: number,
    referenceDailyIntakeAmount: number,
    uom: string,
    label: string,
    roundedAmount: number,
    level: number = 0,
    shouldReferenceDailyIntake: boolean = true,
  ): NutritionLine {
    return {
      amount,
      referenceDailyIntakeAmount,
      uom,
      label,
      level,
      shouldReferenceDailyIntake,
      roundedAmount,
    };
  }
}
