import { createActionGroup, emptyProps } from '@ngrx/store';
import { YearEntitlement } from '../../services/entitlement/models/entitlement';

export const EntitlementActions = createActionGroup({
  source: 'Entitlement',
  events: {
    'Refresh Entitlement': emptyProps(),
    'Clear Entitlement': emptyProps(),
    'Get Entitlement': emptyProps(),
    'Abort Entitlement': emptyProps(),
    'Get Entitlement Success': (yearEntitlement: YearEntitlement) => ({
      yearEntitlement,
    }),
    'Set Selected Entitlement Material': (materialNumber: string) => ({
      materialNumber,
    }),
    'Clear Selected Entitlement Material': emptyProps(),
    'Set Overallocated Material Totals': (
      overallocatedMaterialsMap: Map<string, object>,
    ) => ({ overallocatedMaterialsMap }),
    'Clear Overallocated Material Totals': emptyProps(),
  },
});
