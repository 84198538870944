import {
  initialNutritionState,
  nutritionAdapter,
  NutritionRecordState,
  NutritionRecordStatus,
  NutritionState,
} from './nutrition.state';
import { NutritionActions } from './nutrition.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NutritionLabel } from './models/nutrition';

export const nutritionReducer = createReducer(
  initialNutritionState,
  on(
    NutritionActions.loadNutrition,
    (state, action): NutritionState =>
      loadNutrition(state, action.materialNumbers),
  ),
  on(
    NutritionActions.getNutrition,
    (state, action): NutritionState => getNutrition(state, action),
  ),
  on(
    NutritionActions.getNutritionSuccess,
    (state, action): NutritionState => getNutritionSuccess(state, action),
  ),
  on(
    NutritionActions.getNutritionError,
    (state, action): NutritionState => getNutritionError(state, action),
  ),
  on(
    NutritionActions.clearNutrition,
    (): NutritionState => initialNutritionState,
  ),
);

export function loadNutrition(
  state: NutritionState,
  materialNumbers: string[],
): NutritionState {
  const upsertedEntities = materialNumbers
    .filter(
      (id) =>
        (!!id && !state.nutritionRecords.entities[id]) ||
        NutritionRecordStatus.Error ===
          state.nutritionRecords.entities[id]?.status,
    )
    .map(
      (id) =>
        <NutritionRecordState>{
          materialNumber: id,
          status: NutritionRecordStatus.Queued,
          record: undefined,
        },
    );

  return {
    nutritionRecords: nutritionAdapter.upsertMany(
      upsertedEntities,
      state.nutritionRecords,
    ),
  };
}

export function getNutrition(
  state: NutritionState,
  action: { materialNumber: string },
): NutritionState {
  if (
    !!state.nutritionRecords.entities[action.materialNumber] &&
    state.nutritionRecords.entities[action.materialNumber]?.status !==
      NutritionRecordStatus.Queued
  ) {
    return state;
  }

  const newRecordState: NutritionRecordState = {
    materialNumber: action.materialNumber,
    status: NutritionRecordStatus.Requested,
    record: undefined,
  };

  return {
    nutritionRecords: nutritionAdapter.upsertOne(
      newRecordState,
      state.nutritionRecords,
    ),
  };
}

export function getNutritionSuccess(
  state: NutritionState,
  action: {
    materialNumber: string;
    nutritionLabel: NutritionLabel;
  },
): NutritionState {
  const recordUpate: Update<NutritionRecordState> = {
    id: action.materialNumber,
    changes: {
      status: NutritionRecordStatus.Success,
      record: action.nutritionLabel,
    },
  };

  return {
    nutritionRecords: nutritionAdapter.updateOne(
      recordUpate,
      state.nutritionRecords,
    ),
  };
}

export function getNutritionError(
  state: NutritionState,
  action: { materialNumber: string },
): NutritionState {
  const recordUpdate: Update<NutritionRecordState> = {
    id: action.materialNumber,
    changes: {
      status: NutritionRecordStatus.Error,
      record: undefined,
    },
  };

  return {
    nutritionRecords: nutritionAdapter.updateOne(
      recordUpdate,
      state.nutritionRecords,
    ),
  };
}
