import { Localized } from './localized';
import { StatusSeverity } from './status-severity';

export interface FreightInformation {
  freightCarrier?: string;
  freightCarrierServiceCode?: string;
  freightMethod?: string;
  shippedDateTime?: string;
  trackingNumbers?: string[];
}

export interface CreditCardDetails {
  expirationMonth?: number;
  expirationYear?: number;
  lastFourDigits?: string;
  creditCardToken?: string;
  creditCardType?: string;
  tenderAmount?: string;
  tenderType?: string;
}

export interface StoreFulfillmentDetails {
  requestedPickupTimestamp?: string;
  deliveryWindowStartTimestamp?: string;
  deliveryWindowEndTimestamp?: string;
  expressId?: number;
  retailInventoryAllocationToken?: string;
  storePlantId?: string;
  requestedDeliveryType: string;
  expressTrackingUrl?: string;
  customerSiteId?: string;
  orderReason?: string;
}

export interface ShipToAddressSap {
  shipToAddressHouseNumber?: string;
  shipToAddressStreetAddress?: string;
  shipToAddressStreetAddress2?: string;
  shipToAddressCity?: string;
  shipToAddressRegion?: string;
  shipToAddressPostalCode?: string;
  shipToAddressTimeZone?: string;
  shipToAddressCountry?: string;
}

export interface OrderInfo {
  orderNumber: string;
  orderDate: string;
  orderDateTime?: string;
  customerPurchaseOrder: string;
  requestedCustomerArrivalDate?: string;
  estimatedDeliveryDate?: string;
  estimatedDeliveryTime?: string;
  actualDeliveryDate?: string;
  shippedOnDate?: string;
  totalExtendedPrice: number;
  orderStatus: string;
  orderStatusSeverity: StatusSeverity;
  orderStatusDescription: Localized<string>;
  orderBlockDescription: Localized<string>;
  orderType: string;
  totalWeight: number;
  totalWeightUom: string;
  quantityShipped: number;
  quantityOrdered: number;
  groupNumber?: string;
  freightInformation?: FreightInformation[];
  requestedRouteDate?: string;
  totalNetPrice?: number;
  totalNetWeight?: number;
  blockCode?: string;
  creditCardDetails?: CreditCardDetails;
  storeFulfillment?: StoreFulfillmentDetails;
  usdaOrderAllocationId?: string;
  requestedDeliveryType?: string;
  shipToAddress?: ShipToAddressSap;
  submittedUserName?: string;
  totalLines?: number;
  totalShipping?: string;
  totalDiscounts?: string;
  orderStatusSeverityString?: string;
}

export enum OrderStatus {
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Invoiced = 'INVOICED',
  CreditHold = 'CREDIT_HOLD',
  Partial = 'PARTIAL',
  Requested = 'REQUESTED',
  CancelledByRep = 'CANCELLED_BY_REP',
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  CancelledByVendor = 'CANCELLED_BY_VENDOR',
  EnRoute = 'EN_ROUTE',
  Delivered = 'DELIVERED',
  OnOrder = 'ON_ORDER',
}
