import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  MaterialRow,
  MaterialRowStatus,
} from '../../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../../core/store/material-row/material-row.facade';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'naoo-special-order-material-row-container',
  templateUrl: './special-order-material-row-container.component.html',
  styleUrls: ['./special-order-material-row-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialOrderMaterialRowContainerComponent implements OnInit {
  @Input() materialNumber: string;

  materialRow$: Observable<MaterialRow>;

  constructor(private materialRowFacade: MaterialRowFacade) {}

  ngOnInit(): void {
    this.materialRow$ = this.materialRowFacade
      .getMaterialRow({ materialNumber: this.materialNumber })
      .pipe(shareReplay(1));
  }

  isUnavailable(status: MaterialRowStatus): boolean {
    return MaterialRowStatus.Unavailable === status;
  }
}
