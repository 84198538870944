import { HttpErrorResponse } from '@angular/common/http';
import { NaooError } from '../models/naoo-error';
import { NaooErrorWrapper } from '../models/naoo-error-wrapper';

export enum NAOOErrorCode {
  DELIVERYSCHEDULE_CUTOFF = 'cart.cut-off',
  CART_MAX_ITEM_COUNT = 'cart.max-item-count',
  CART_MAX_SPECIAL_ORDER_COUNT = 'cart.max-special-orders',
  CART_ALREADY_SUBMITTED = 'cart.cart-already-submitted',
  INACTIVE_CART = 'cart.inactive-cart',
  SCHEDULED_MAINTENANCE = 'authentication.migration.error',
  CART_RETRYABLE_ERROR = 'cart.retryable-error',
  CART_STATUS_INVALID = 'cart.status-invalid',
  COMMODITY_OVER_ALLOCATED = 'commodity-service.over-allocated-materials',
  COMMODITY_SERVICE_UNAVAILABLE = 'commodity-service.service-unavailable',
  REQUIRED_PAYMENT_UNAVAILABLE = 'payment.payment-unavailable',
  PRE_AUTHORIZATION_CARD_ERROR = 'payment.pre-authorization.card-error',
  PRE_AUTHORIZATION_TRY_AGAIN_LATER = 'payment.pre-authorization.try-again-later',
  PRE_AUTHORIZATION_PAYMENT_INVALID = 'payment.pre-authorization.payment-invalid',
  PRE_AUTHORIZATION_UNKNOWN_ERROR = 'payment.pre-authorization.unknown-error',
  EXTERNAL_PAYMENT_METHOD_REQUIRED = 'cart.external-payment-method-required',
  INVALID_CATEGORY = 'material.invalid-category-key',
  INVALID_SESSION = 'session.details-missing',
  ORDER_EDIT_SERVICE_TIMEOUT = 'order.service-timeout',
  DELIVERY_WINDOW_NOT_AVAILABLE = 'store-service.delivery-window-unavailable',
  STORE_NOT_ACCEPTING_ORDERS = 'store-service.store-not-accepting-orders',
}

export class NaooErrorUtils {
  static offlineErrorCode = 0;
  static retryCodes = [
    -1,
    NaooErrorUtils.offlineErrorCode,
    408,
    409,
    498,
    502,
    503,
    504,
    598,
    599,
  ];

  public static getNaooError(error: HttpErrorResponse): NaooError {
    return this.getNaooErrorWrapper(error).error;
  }

  public static isOfflineError(error: unknown): boolean {
    return (
      (error instanceof HttpErrorResponse || error instanceof NaooError) &&
      error.status === NaooErrorUtils.offlineErrorCode
    );
  }

  private static getNaooErrorWrapper(
    error: HttpErrorResponse,
  ): NaooErrorWrapper {
    if (this.isConnectivityError(error)) {
      return new NaooErrorWrapper(new NaooError(error.name, error.status));
    } else if (this.hasErrorCodeAndMessage(error)) {
      return new NaooErrorWrapper(
        new NaooError(
          error.error.error.code,
          error.status,
          error.error.error.message,
        ),
      );
    } else if (this.hasErrorCode(error)) {
      return new NaooErrorWrapper(
        new NaooError(error.error.error.code, error.status),
      );
    } else {
      return new NaooErrorWrapper(new NaooError('default.error', 500));
    }
  }

  private static hasErrorCode(error: HttpErrorResponse): boolean {
    return !!error?.error?.error?.code;
  }

  private static hasErrorCodeAndMessage(error: HttpErrorResponse): boolean {
    return this.hasErrorCode(error) && !!error?.error?.error?.message;
  }

  private static isConnectivityError(error: HttpErrorResponse): boolean {
    return error !== null && this.retryCodes.includes(error.status);
  }
}
