import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateCustomGuideErrorStateMatcher } from '../../create-custom-guide-modal/create-custom-guide-error-state-matcher';
import { CustomGuideNameExistsValidator } from '../../../shared/services/validators/custom-guide-name-exists-validator.service';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { NotOnlyWhitespaceValidator } from '../../../shared/services/validators/not-only-whitespace-validator.service';
import { ListsAnalyticsConstants } from '../../lists-analytics.constants';

@Component({
  selector: 'naoo-rename-custom-guide-modal',
  templateUrl: './rename-custom-guide-modal.component.html',
  styleUrls: ['./rename-custom-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameCustomGuideModalComponent implements OnInit {
  customGuideNameFormControl: FormControl;
  matcher = new CreateCustomGuideErrorStateMatcher();
  customGuideNameMaxLength = NaooConstants.CUSTOM_GUIDE_NAME_MAX_LENGTH;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { customGuideId: string; customGuideName: string },
    private dialogRef: MatDialogRef<RenameCustomGuideModalComponent>,
    private notOnlyWhitespaceValidator: NotOnlyWhitespaceValidator,
    private customGuideNameExistsValidator: CustomGuideNameExistsValidator,
    private customGuideFacade: CustomGuideFacade,
    private analytics: NaooAnalyticsManager,
  ) {}

  get newCustomGuideName() {
    return this.customGuideNameFormControl.value.toString().trim();
  }

  ngOnInit(): void {
    this.customGuideNameFormControl = new FormControl(
      this.data.customGuideName,
      [
        Validators.maxLength(this.customGuideNameMaxLength),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.notOnlyWhitespaceValidator.validate(),
      ],
      this.customGuideNameExistsValidator
        .validate(this.data.customGuideName)
        .bind(this.customGuideNameExistsValidator),
    );
  }

  closeModal(): void {
    this.dialogRef.close();
    this.trackCloseNameModal();
  }

  renameCustomGuide(): void {
    if (!this.hasCustomGuideNameChanged()) {
      this.dialogRef.close();
      return;
    }
    if (this.isFormValid()) {
      this.customGuideFacade.renameCustomGuide(
        this.data.customGuideId,
        this.newCustomGuideName,
      );
      this.trackRenameCustomGuide();
      this.dialogRef.close();
    }
  }

  isFormValid(): boolean {
    return (
      !!this.newCustomGuideName.length && this.customGuideNameFormControl.valid
    );
  }

  private hasCustomGuideNameChanged() {
    return this.data.customGuideName !== this.newCustomGuideName;
  }

  private trackCloseNameModal() {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'cancel - rename',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackRenameCustomGuide() {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'save changes - rename',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
