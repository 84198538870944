import {
  orderGuideChangeHistoryAdapter,
  orderGuideChangeHistoryInitialState,
  OrderGuideChangeHistoryState,
} from './order-guide-change-history.state';
import { OrderGuideChangeHistoryActions } from './order-guide-change-history.actions';
import { OrderGuideChangeHistoryRecord } from '../../services/order-guide-change-history/models/order-guide-change-history-record';
import { createReducer, on } from '@ngrx/store';

export const OrderGuideChangeHistoryReducer = createReducer(
  orderGuideChangeHistoryInitialState,
  on(
    OrderGuideChangeHistoryActions.getOrderGuideChangeHistory,
    (state): OrderGuideChangeHistoryState => ({ ...state }),
  ),
  on(
    OrderGuideChangeHistoryActions.getOrderGuideChangeHistorySuccess,
    (state, action): OrderGuideChangeHistoryState =>
      upsertRecords(state, action.payload),
  ),
  on(
    OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory,
    (): OrderGuideChangeHistoryState => orderGuideChangeHistoryInitialState,
  ),
);

function upsertRecords(
  state: OrderGuideChangeHistoryState,
  records: OrderGuideChangeHistoryRecord[],
): OrderGuideChangeHistoryState {
  return {
    hasLoaded: true,
    records: orderGuideChangeHistoryAdapter.upsertMany(records, state.records),
  };
}
