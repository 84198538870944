<div class="qty">
  <button
    #decrementButton
    class="rounded-button active-mobile button-decrement disable-save"
    *ngIf="shouldRenderButtons"
    (click)="decrementQuantity($event)"
    [disabled]="isDecrementDisabled"
    [attr.aria-label]="'MATERIAL_ROW.INPUT.DECREMENT' | translate"
  >
    <mat-icon svgIcon="minus"></mat-icon>
  </button>

  <input
    #quantityInput
    class="qty-input"
    [ngClass]="[inputBorder]"
    [class]="currentOrderingInfo.uom"
    [value]="
      !!currentOrderingInfo.line.quantity
        ? currentOrderingInfo.line.quantity
        : ''
    "
    [disabled]="shouldDisableInput"
    (contextmenu)="suppressContextMenu($event)"
    (keypress)="onKeyPress($event)"
    (textInput)="onTextInput($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (mouseup)="selectAll()"
    (keyup.arrowdown)="selectAll()"
    (keyup.arrowup)="selectAll()"
    (keydown.enter)="nextInput()"
    (keydown.shift.enter)="previousInput()"
    (keydown.arrowdown)="nextInput()"
    (keydown.arrowup)="previousInput()"
    (input)="updateQuantity()"
    type="tel"
    maxlength="3"
    autocomplete="off"
    [attr.aria-labelledby]="'quantity-aria-label'"
    aria-live="assertive"
  />
  <span id="quantity-aria-label" class="quantity-aria-label">
    {{
      !!materialWarning
        ? ('MATERIAL_ROW.INPUT.QUANTITY' | translate) +
          ': ' +
          currentOrderingInfo.line.quantity +
          ', ' +
          ('MATERIAL_WARNING.WARNING_DISPLAYED' | translate) +
          ', ' +
          (materialWarning.message | translate: materialWarning.messageParams)
        : ('MATERIAL_ROW.INPUT.QUANTITY' | translate) +
          ': ' +
          currentOrderingInfo.line.quantity
    }}
  </span>

  <button
    #incrementButton
    class="rounded-button active-mobile button-increment disable-save case-button"
    *ngIf="shouldRenderButtons"
    (click)="incrementQuantity($event)"
    [disabled]="isIncrementDisabled"
    [attr.aria-label]="'MATERIAL_ROW.INPUT.INCREMENT' | translate"
  >
    <mat-icon svgIcon="plus"></mat-icon>
  </button>
</div>
