<div
  tabindex="0"
  #docketContainer
  class="docket"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <naoo-generic-docket-header
    *ngIf="!!title"
    [title]="title"
    [tooltipKey]="tooltipKey"
    (close)="onClose()"
  ></naoo-generic-docket-header>
  <ng-content></ng-content>
</div>
