<div class="close-button-container">
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'CLOSE' | translate }}"
    cdkFocusInitial
  >
    <img
      class="close-button-img"
      alt="{{ 'CLOSE' | translate }}"
      src="assets/images/close-icon.svg"
    />
  </button>
</div>
<mat-icon svgIcon="critical_item_star"></mat-icon>

<div class="critical-item-helper-container">
  <div class="main-text">
    {{ 'LISTS.CRITICAL_ITEM_HELPER_MODAL.COMPARE_YOUR_CART' | translate }}
  </div>
</div>
