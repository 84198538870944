export interface SessionRecord {
  firstName: string;
  lastName: string;
  language: Language;
  email: string;
  guid: string;
  isInternalUser: boolean;
  isPunchOut: boolean;
  enabledFeatures: string[];
  customers: SessionCustomer[];
  activeCustomer?: SessionActiveCustomer;
}

export interface SessionActiveCustomer {
  naooCustomerId: string;
  customerDisplayId: string;
  warehouseId: string;
  brand: CustomerBrand;
  name: string;
  locale: Locale;
  address: SessionCustomerAddress;
  timeZone: string;
  currentSystem: CurrentSystem;
  permissions: CustomerPermission[];
  plantNumber: string;
  primarySiteId: string;
  primaryChannel: string;
  segmentId: number;
  sites: Site[];
  fourPartKey: CustomerFourPartKey;
  defaultStoreId: number;
  isLoyaltyProgramEligible: boolean;
  isDigitalOnly: boolean;
  showPurchaseHistory: boolean;
  countryCode: string;
  showDigitalCoupon: boolean;
}

export interface Site {
  siteId: string;
  address: SiteAddressRecord;
}

export interface SiteAddressRecord {
  address1: string;
  address2?: string;
  address3?: string;
  addressAttention: string;
  city: string;
  countryCode: string;
  postalCode: string;
  stateProvinceCode: string;
}

export interface SessionCustomer {
  naooCustomerId: string;
  customerDisplayId: string;
  name: string;
  primaryChannel: string;
  address: SessionCustomerAddress;
  permissions: CustomerPermission[];
}

export interface CustomerFourPartKey {
  salesOrganization: string;
  distributionChannel: string;
  division: string;
  customerId: string;
}

export interface SessionCustomerAddress {
  line1: string;
  line2: string;
  city: string;
  provinceState: string;
  postalCode: string;
  country: string;
}

export enum Language {
  en = 'en',
  fr = 'fr',
  es = 'es',
}

export enum Locale {
  en_CA = 'en_CA',
  en_US = 'en_US',
  fr_CA = 'fr_CA',
  es_US = 'es_US',
}

export enum CurrentSystem {
  Mygfs = 'MYGFS',
  Retalix = 'RETALIX',
  Sap = 'SAP',
  Migrating = 'MIGRATING',
}

export enum FeatureFlag {
  PLACEHOLDER = '',
  SPANISH_LANGUAGE = 'spanish-language',
  BAYMARD_NAVIGATION = 'baymard-navigation-bar-updates',
}

// TODO: NAOO-30062 Refactor usage in follow-up MR
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CurrentSystem {
  const newSystems = [CurrentSystem.Mygfs, CurrentSystem.Sap];

  export function isMygfsOrSap(currentSystem: CurrentSystem): boolean {
    return newSystems.includes(currentSystem);
  }
}

export enum CustomerBrand {
  Global = 'GLOBAL',
  GFS_CA = 'GFS_CA',
  GFS_US = 'GFS_US',
  United = 'UNITED',
}

export enum CustomerPermission {
  InventoryAccess = 'INVENTORY_ACCESS',
  LockedToOrderGuide = 'LOCKED_TO_ORDER_GUIDE',
  OrderSubmission = 'ORDER_SUBMISSION',
  PoRequired = 'PO_REQUIRED',
  RecipeAccess = 'RECIPE_ACCESS',
  OrderGuideEditAccess = 'ORDER_GUIDE_EDIT_ACCESS',
  CommodityAccess = 'COMMODITY_ACCESS',
  InStorePickup = 'IN_STORE_PICKUP',
  ExpressDelivery = 'EXPRESS_DELIVERY',
  TruckDelivery = 'TRUCK_DELIVERY',
  ExperienceAccess = 'EXPERIENCE_ACCESS',
  ResourcesAccess = 'RESOURCES_ACCESS',
  OnlinePaymentAccess = 'ONLINE_PAYMENT_ACCESS',
  CycleMenuManagementAccess = 'CYCLE_MENU_MANAGEMENT_ACCESS',
  GordonRestaurantProAccess = 'GORDON_RESTAURANT_PRO_ACCESS',
  GordonReportingAccess = 'GORDON_REPORTING_ACCESS',
  GordonEducationReportingAccess = 'GORDON_EDUCATION_REPORTING_ACCESS',
  GordonHealthReportingAccess = 'GORDON_HEALTH_REPORTING_ACCESS',
  SpecialOrderDashboardAccess = 'SPECIAL_ORDER_DASHBOARD_ACCESS',
  DiningRdAccess = 'DINING_RD_ACCESS',
}
