<ng-container *ngIf="!isMobile; else mobile">
  <button
    class="more-tools-menu-link"
    [ngClass]="{ disabled: isDisabled }"
    [matMenuTriggerFor]="menu"
    (menuClosed)="isMenuOpen = false"
    (menuOpened)="isMenuOpen = true"
    [disabled]="isDisabled"
  >
    <span>{{ 'TITLE.MORE_TOOLS' | translate }}</span>
    <mat-icon
      class="dropdown-arrow-icon"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="naoo-more-tools-links">
    <button
      *ngFor="let permission of permissions"
      class="more-tools-menu-item"
      mat-menu-item
      (click)="redirectTo(moreToolsLinks[permission].headerLinkType)"
    >
      <span>{{ moreToolsLinks[permission].translationName | translate }}</span>
      <div
        class="open-new-tab-icon-wrapper"
        [title]="'TITLE.NEW_TAB_TEXT' | translate"
      >
        <mat-icon class="open-new-tab-icon" svgIcon="open-new-tab"></mat-icon>
      </div>
    </button>
  </mat-menu>
</ng-container>

<ng-template #mobile>
  <button
    id="more-tools-menu-item-trigger"
    class="more-tools-menu-item-trigger menu-item"
    tabindex="-1"
    [ngClass]="{ disabled: isDisabled, open: isMenuOpen }"
    [disabled]="isDisabled"
    aria-haspopup="true"
    [attr.aria-expanded]="isMenuOpen"
    aria-controls="more-tools-menu-items-wrapper"
    (click)="toggleSubMenu()"
  >
    <span>{{ 'TITLE.MORE_TOOLS' | translate }}</span>
    <mat-icon
      class="dropdown-arrow-icon"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
  <div
    role="menu"
    aria-labelledby="more-tools-menu-item-trigger"
    id="more-tools-menu-items-wrapper"
    class="more-tools-menu-items-wrapper"
    [ngClass]="{ open: isMenuOpen }"
    [@showHide]="showHideAnimationStatus"
  >
    <button
      *ngFor="let permission of permissions"
      class="more-tools-menu-item"
      role="menuitem"
      tabindex="-1"
      [ngClass]="{ 'menu-item': isMenuOpen }"
      (click)="redirectTo(moreToolsLinks[permission].headerLinkType)"
    >
      <span>{{ moreToolsLinks[permission].translationName | translate }}</span>
      <div
        class="open-new-tab-icon-wrapper"
        [title]="'TITLE.NEW_TAB_TEXT' | translate"
      >
        <mat-icon class="open-new-tab-icon" svgIcon="open-new-tab"></mat-icon>
      </div>
    </button>
  </div>
</ng-template>
