import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { ImportOrderStep } from './import-order-modal-step';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ImportOrderModalAnalytics {
  private readonly category = 'Import Order';

  private readonly uploadAction = 'Upload';
  private readonly unknownFileTypeLabel = 'Unknown';

  private readonly downloadAction = 'Download';
  private readonly csvTemplateLabel = 'CSV Template';
  private readonly excelTemplateLabel = 'Excel Template';

  private readonly completeAction = 'Complete';
  private readonly completeLabel = 'Import Order Complete';

  private readonly errorAction = 'Error';
  private readonly rowErrorLabel = 'Row Level';
  private readonly fileErrorLabel = 'File Level';

  private readonly cancelAction = 'Cancel';
  private readonly cancelLabel = 'X Button';

  private readonly clickAction = 'click';
  private readonly importWithInvalidLabel = 'Import Order with invalid items';
  private readonly importWithoutInvalidLabel =
    'Import Order without invalid items';

  constructor(private analytics: NaooAnalyticsManager) {}

  trackTemplateDownload(currentStep: ImportOrderStep, type: string) {
    const templateLabel =
      type === 'csv' ? this.csvTemplateLabel : this.excelTemplateLabel;
    const eventInfo: AnalyticsEventInfo = {
      action: this.downloadAction,
      category: this.category,
      label: `${currentStep} ${templateLabel}`,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  trackValidationResult(step: ImportOrderStep | null) {
    if (
      step !== ImportOrderStep.RowError &&
      step !== ImportOrderStep.FileError
    ) {
      return;
    }
    const eventInfo: AnalyticsEventInfo = {
      action: this.errorAction,
      category: this.category,
      label:
        step === ImportOrderStep.RowError
          ? this.rowErrorLabel
          : this.fileErrorLabel,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  trackImportComplete(cartTotal: number) {
    const eventInfo: AnalyticsEventInfo = {
      action: this.completeAction,
      category: this.category,
      label: this.completeLabel,
      value: Math.round(cartTotal),
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  trackSelectedFileType(fileName: string) {
    const fileNameParts = fileName.split('.');
    const fileType =
      fileNameParts.length > 1
        ? fileNameParts[fileNameParts.length - 1]
        : this.unknownFileTypeLabel;
    const eventInfo: AnalyticsEventInfo = {
      action: this.uploadAction,
      category: this.category,
      label: fileType,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  trackCloseModal(currentStep: ImportOrderStep) {
    const eventInfo: AnalyticsEventInfo = {
      action: this.cancelAction,
      category: this.category,
      label: `${currentStep} ${this.cancelLabel}`,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  trackImportWithErrors(includesInvalidRows: boolean) {
    const eventInfo: AnalyticsEventInfo = {
      action: this.clickAction,
      category: this.category,
      label: includesInvalidRows
        ? this.importWithInvalidLabel
        : this.importWithoutInvalidLabel,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
