import { fromEvent, Observable, ReplaySubject } from 'rxjs';

import { debounceTime, startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ResizeEventService {
  private eventSubject = new ReplaySubject<boolean>(1);

  constructor(private windowService: Window) {
    this.subscribeToResizeEvent();
  }

  private subscribeToResizeEvent(): void {
    fromEvent(this.windowService, 'resize')
      .pipe(
        startWith({ target: { innerWidth: this.windowService.innerWidth } }),
        debounceTime(10),
      )
      .subscribe(() => {
        this.pushEvent();
      });
  }

  private pushEvent() {
    this.eventSubject.next(true);
  }

  public getEvents(): Observable<boolean> {
    return this.eventSubject.asObservable();
  }
}
