import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { CustomGuideActions } from './custom-guide.actions';
import { CustomGuideService } from '../../services/custom-guide/custom-guide.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { NaooConstants } from '../../../shared/NaooConstants';
import {
  selectAllCustomGuideRecordStateEntities,
  selectHasParQuantities,
} from './custom-guide.selectors';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import {
  convertToCustomGuideCategoryRequests,
  convertToCustomGuideRequest,
  createEmptyCustomGuideMaterialRequests,
  CustomGuideCategoryRequest,
  CustomGuideRequest,
} from '../../services/custom-guide/model/custom-guide-request';
import {
  buildCategorizedMaterialNumbers,
  getMaterialNumbersForCategories,
  getParLineRecordForMaterial,
} from './custom-guide.util';
import {
  CategorizedCustomGuideRecordState,
  CustomGuideRecordState,
} from './custom-guide.state';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { difference } from 'lodash-es';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { Router } from '@angular/router';
import { GroupByType, SortByType } from 'src/app/guides/shared/guides';
import { SharedActions } from '../shared/shared.actions';
import { MaterialRowContext } from '../material-row/models/material-row';
import { CartFacade } from '../cart/cart.facade';

@Injectable()
export class CustomGuideEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private customDialogService: CustomDialogService,
    private customGuideService: CustomGuideService,
    private defaultDialogService: DefaultDialogService,
    private toastMessageService: ToastMessageService,
    private cartFacade: CartFacade,
  ) {}

  getAllCustomGuides$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.getAllCustomGuides),
      mergeMap(() =>
        this.customGuideService.getAllCustomGuides().pipe(
          map((customGuideRecords) =>
            CustomGuideActions.getAllCustomGuidesSuccess(customGuideRecords),
          ),
          catchError((error) =>
            of(CustomGuideActions.getAllCustomGuidesFailure(error)),
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomGuideActions.refreshAllCustomGuides),
            ),
          ),
        ),
      ),
    );
  });

  getAllCustomGuidesSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.getAllCustomGuidesSuccess),
      mergeMap((action) => {
        const materialNumbers: string[] = action.records.reduce(
          (customGuideMaterialNumbers, guide) =>
            customGuideMaterialNumbers.concat(
              getMaterialNumbersForCategories(guide.categories),
            ),
          [],
        );

        return [
          MaterialInfoActions.loadMaterialInfo(materialNumbers),
          MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers),
        ];
      }),
    );
  });

  getAllCustomGuidesFailure$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.getAllCustomGuidesFailure),
      map((action) => {
        return ErrorActions.fatalError(action.error);
      }),
    );
  });

  refreshAllCustomGuides$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.refreshAllCustomGuides),
      map(() => CustomGuideActions.getAllCustomGuides()),
    );
  });

  createCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.createCustomGuide),
      map((action) => {
        const defaultCategory: CustomGuideCategoryRequest[] =
          action.materialNumbers.length > 0
            ? [
                {
                  name: NaooConstants.UNASSIGNED_GUIDE_CATEGORY_KEY,
                  materials: createEmptyCustomGuideMaterialRequests(
                    action.materialNumbers,
                  ),
                },
              ]
            : [];

        return CustomGuideActions.createCategorizedCustomGuide(
          action.name,
          action.groupBy,
          defaultCategory,
        );
      }),
    );
  });

  duplicateCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.duplicateCustomGuide),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      map(([action, customGuidesState]) => {
        const customGuideRecordState: CustomGuideRecordState =
          customGuidesState[action.id];
        const record = customGuideRecordState.record;

        return CustomGuideActions.createCategorizedCustomGuide(
          action.name,
          action.groupBy,
          convertToCustomGuideCategoryRequests(record.categories),
          record.parOrderingEnabled,
        );
      }),
    );
  });

  createCategorizedCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.createCategorizedCustomGuide),
      mergeMap((action) => {
        const request: CustomGuideRequest = {
          name: action.name,
          sortBy:
            action.groupBy === GroupByType.Custom
              ? SortByType.Custom
              : SortByType.Description,
          groupBy: action.groupBy,
          parOrderingEnabled: action.parOrderingEnabled,
          categories: action.categorizedMaterials,
        };
        return this.customGuideService.createCustomGuide(request).pipe(
          map((customGuideRecord) =>
            CustomGuideActions.createCategorizedCustomGuideSuccess(
              customGuideRecord,
            ),
          ),
          catchError((error) => of(ErrorActions.fatalError(error))),
        );
      }),
    );
  });

  createCategorizedCustomGuideSuccess$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CustomGuideActions.createCategorizedCustomGuideSuccess),
        mergeMap((action) => {
          this.customDialogService.closeCreateCustomGuideModal();
          this.toastMessageService.showCreatedCustomGuideMessage(
            action.record.id,
            action.record.name,
          );
          const materialNumbers = getMaterialNumbersForCategories(
            action.record.categories,
          );
          return [
            MaterialInfoActions.loadMaterialInfo(materialNumbers),
            MaterialAvailabilityActions.loadMaterialAvailability(
              materialNumbers,
            ),
          ];
        }),
      );
    },
  );

  importCustomGuideSuccessAction$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.importCustomGuideSuccess),
      mergeMap((action) => {
        const materialNumbers = getMaterialNumbersForCategories(
          action.record.categories,
        );
        return [
          MaterialInfoActions.loadMaterialInfo(materialNumbers),
          MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers),
        ];
      }),
    );
  });

  deleteCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.deleteCustomGuide),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      mergeMap(([action, customGuidesState]) => {
        const customGuideRecordState: CustomGuideRecordState =
          customGuidesState[action.id];
        return this.customGuideService.deleteCustomGuide(action.id).pipe(
          map(() =>
            CustomGuideActions.deleteCustomGuideSuccess(
              action.id,
              customGuideRecordState.record.name,
            ),
          ),
          catchError((error) => of(ErrorActions.fatalError(error))),
        );
      }),
    );
  });

  deleteCustomGuideSuccess$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CustomGuideActions.deleteCustomGuideSuccess),
        map((action) =>
          this.toastMessageService.showDeletedCustomGuideMessage(action.name),
        ),
      );
    },
    { dispatch: false },
  );

  updateCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.updateCustomGuide),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      switchMap(([action, customGuidesState]) => {
        if (!customGuidesState || !customGuidesState[action.id]) {
          return of(SharedActions.noOperation('Custom Guide not found'));
        }
        const record = customGuidesState[action.id].record;
        const updateRequest = convertToCustomGuideRequest(record);

        return this.customGuideService
          .updateCustomGuide(action.id, updateRequest)
          .pipe(
            map((serverRecord) =>
              CustomGuideActions.updateCustomGuideSuccess(serverRecord),
            ),
            catchError((error) => of(ErrorActions.fatalError(error))),
          );
      }),
    );
  });

  renameCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.renameCustomGuide),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  updateCustomGuideGroupBy$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.updateCustomGuideGroupBy),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  updateCustomGuideSortBy$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.updateCustomGuideSortBy),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  createCustomGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.createCustomGuideCategory),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  renameCustomGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.renameCustomGuideCategory),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  removeCustomGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.removeCustomGuideCategory),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  moveCustomGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.moveCustomGuideCategory),
      map((action) => {
        if (action.newCategoryIndex === action.originalCategoryIndex) {
          return SharedActions.noOperation('Category index has not changed');
        }

        return CustomGuideActions.updateCustomGuide(action.id);
      }),
    );
  });

  addCustomGuideMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.addCustomGuideMaterials),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      map(([action, customGuidesState]) => {
        const record = customGuidesState[action.id].record;
        const materialsToAdd = difference(
          action.materialNumbers,
          getMaterialNumbersForCategories(record.categories),
        );

        if (materialsToAdd.length === 0) {
          const failureKey =
            action.materialNumbers.length === 1
              ? 'LISTS.PRODUCT_ALREADY_FOUND_TOAST'
              : 'LISTS.PRODUCTS_ALREADY_FOUND_TOAST';
          this.toastMessageService.showAddedToGuideMessage(
            failureKey,
            action.id,
            record.name,
          );
          return SharedActions.noOperation(
            'No new items being added to custom guide',
          );
        }

        const successKey =
          action.materialNumbers.length === 1
            ? 'LISTS.PRODUCT_ADDED_TOAST'
            : 'LISTS.PRODUCTS_ADDED_TOAST';
        this.toastMessageService.showAddedToGuideMessage(
          successKey,
          action.id,
          record.name,
        );

        return CustomGuideActions.addCustomGuideMaterialsSuccess(
          action.id,
          materialsToAdd,
        );
      }),
    );
  });

  addCustomGuideMaterialsSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.addCustomGuideMaterialsSuccess),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  removeCustomGuideMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.removeCustomGuideMaterials),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      map(([action, customGuidesState]) => {
        const record = customGuidesState[action.id].record;
        this.toastMessageService.showRemovedFromCustomGuideMessage(
          record.name,
          true,
        );

        return CustomGuideActions.updateCustomGuide(action.id);
      }),
    );
  });

  moveCustomGuideMaterials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.moveCustomGuideMaterials),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  updateParQuantity$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.updateParQuantity),
      debounceTime(750),
      map((action) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  updateInventoryQuantity$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.updateInventoryQuantity),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      tap(([action, customGuidesState]) => {
        const record = customGuidesState[action.id].record;
        const parLineRecord = getParLineRecordForMaterial(
          record,
          action.materialNumber,
          action.uom,
        );

        let updatedCartQuantity = 0;
        if (parLineRecord.inventoryQuantity < parLineRecord.parQuantity) {
          updatedCartQuantity = Math.ceil(
            parLineRecord.parQuantity - parLineRecord.inventoryQuantity,
          );
        }
        this.cartFacade.updateCartQuantities([
          {
            materialNumber: action.materialNumber,
            lines: [
              {
                uom: action.uom,
                quantity: updatedCartQuantity,
              },
            ],
            context: MaterialRowContext.CustomGuide,
          },
        ]);
      }),
      debounceTime(750),
      map(([action]) => CustomGuideActions.updateCustomGuide(action.id)),
    );
  });

  clearInventoryQuantities$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.clearInventoryQuantities),
      map((action) => {
        this.toastMessageService.showLocalizedToastMessage(
          'LISTS.CLEAR_INVENTORY_TOAST_MESSAGE',
        );

        return CustomGuideActions.updateCustomGuide(action.id);
      }),
    );
  });

  toggleParOrderingEnabled$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.toggleParOrdering),
      concatLatestFrom((action) =>
        this.store.select(selectHasParQuantities(action.id)),
      ),
      map(([action, hasParQuantities]) => {
        if (action.parOrderingEnabled && !hasParQuantities) {
          this.defaultDialogService.oneButtonModal(
            'custom-guide-no-pars-set',
            'LISTS.NO_PAR_LEVEL_SET_MODAL.MESSAGE',
            'LISTS.NO_PAR_LEVEL_SET_MODAL.BUTTON_TEXT',
            () => {
              this.router.navigate([
                'guides',
                'custom-guide',
                action.id,
                'organize',
              ]);
            },
            true,
          );
        }
        return CustomGuideActions.updateCustomGuide(action.id);
      }),
    );
  });

  getStorageAreaCategorizedCustomGuide$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CustomGuideActions.getCategorizedCustomGuide),
        filter((action) => action.groupBy === GroupByType.Storage),
        concatLatestFrom(() =>
          this.store.select(selectAllCustomGuideRecordStateEntities),
        ),
        exhaustMap(([action, customGuidesState]) => {
          const customGuideRecordState = customGuidesState[action.id];
          return this.getCategorizedCustomGuide(
            action,
            customGuideRecordState.storageAreaCategory,
          );
        }),
      );
    },
  );

  getTaxonomyCategorizedCustomGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomGuideActions.getCategorizedCustomGuide),
      filter((action) => action.groupBy === GroupByType.Taxonomy),
      concatLatestFrom(() =>
        this.store.select(selectAllCustomGuideRecordStateEntities),
      ),
      exhaustMap(([action, customGuidesState]) => {
        const customGuideRecordState = customGuidesState[action.id];
        return this.getCategorizedCustomGuide(
          action,
          customGuideRecordState.taxonomyCategory,
        );
      }),
    );
  });

  private getCategorizedCustomGuide(
    action: {
      id: string;
      groupBy: GroupByType;
    },
    categorizedRecordState: CategorizedCustomGuideRecordState,
  ): Observable<Action> {
    if (!!categorizedRecordState && categorizedRecordState.hasLoaded) {
      return of(
        SharedActions.noOperation(
          'Request for CategorizedMaterials is either unnecessary or already underway',
        ),
      );
    }

    return this.customGuideService
      .getCategorizedCustomGuide(action.id, action.groupBy)
      .pipe(
        map((categorizedRecord) =>
          CustomGuideActions.getCategorizedCustomGuideSuccess(
            action.id,
            action.groupBy,
            buildCategorizedMaterialNumbers(categorizedRecord),
          ),
        ),
        catchError(() =>
          of(
            CustomGuideActions.getCategorizedCustomGuideFailure(
              action.id,
              action.groupBy,
            ),
          ),
        ),
        takeUntil(
          this.actions$.pipe(
            ofType(
              CustomGuideActions.refreshAllCustomGuides,
              CustomGuideActions.clearCategorizedCustomGuide,
            ),
          ),
        ),
      );
  }
}
