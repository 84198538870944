<div class="nutrition-table" [ngClass]="{ 'hide-border': hideBorder }">
  <div class="title">{{ 'NUTRITION.NUTRITION_FACTS' | translate }}</div>
  <div
    class="main-value header no-underline calculation-size"
    *ngIf="getCalculationSize() !== ''"
  >
    {{ 'NUTRITION.PER_SERVING' | translate }} {{ getCalculationSize() }}
  </div>
  <div class="main-value header heavy-underline">
    {{ 'NUTRITION.CALORIES' | translate }} {{ getCalories() }}
  </div>
  <div class="daily-value-header">
    % {{ 'NUTRITION.DAILY_VALUE' | translate }}
  </div>

  <div
    class="nutrition-items"
    *ngFor="let tableItem of nutritionTableStructure; let last = last"
  >
    <div
      class="main-value nutrition-item"
      [ngClass]="{ 'heavy-underline': last }"
    >
      {{ tableItem.displayLabel | translate }}
      {{ measurableValueForKey(tableItem.key) }}
      <div class="daily-value">{{ dailyValueForKey(tableItem.key) }}</div>
    </div>
    <div
      *ngFor="let subItem of tableItem.subsections"
      class="sub-value nutrition-item"
    >
      {{ subItem.displayLabel | translate }}
      {{ measurableValueForKey(subItem.key) }}
      <div class="daily-value">{{ dailyValueForKey(subItem.key) }}</div>
    </div>
  </div>
  <div
    *ngFor="let tableItem of vitaminsTableStructure; let last = last"
    class="main-value vitamin-item"
    [ngClass]="{ 'no-underline': last }"
  >
    {{ tableItem.displayLabel | translate }}
    {{ measurableValueForKey(tableItem.key) }}
    <div class="daily-value">{{ dailyValueForKey(tableItem.key) }}</div>
  </div>
</div>
