import {
  initialMaterialUnitsState,
  materialUnitsAdapter,
  MaterialUnitsState,
} from './material-units.state';
import { MaterialUnitsActions } from './material-units.actions';
import { createReducer, on } from '@ngrx/store';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';

export const materialUnitsReducer = createReducer(
  initialMaterialUnitsState,
  on(
    MaterialUnitsActions.getMaterialUnitsInformationSuccess,
    (state, action): MaterialUnitsState =>
      getMaterialUnitsSuccess(state, action),
  ),
  on(
    MaterialUnitsActions.getMaterialUnitsInformationError,
    (): MaterialUnitsState => getMaterialUnitsError(),
  ),
  on(
    MaterialUnitsActions.refreshMaterialUnitsInformation,
    MaterialUnitsActions.getMaterialUnitsInformation,
    (): MaterialUnitsState => initialMaterialUnitsState,
  ),
);

function getMaterialUnitsSuccess(
  state: MaterialUnitsState,
  action: {
    records: MaterialUnitsRecord[];
  },
) {
  return {
    hasLoaded: true,
    materialUnits: materialUnitsAdapter.setAll(
      action.records,
      state.materialUnits,
    ),
  };
}

function getMaterialUnitsError() {
  return {
    hasLoaded: true,
    materialUnits: materialUnitsAdapter.getInitialState(),
  };
}
