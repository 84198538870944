export enum HeaderLinkType {
  RECIPE = 'recipe',
  INVENTORY = 'inventory',
  EXPERIENCE = 'experience',
  RESOURCES = 'resources',
  ONLINE_PAYMENT = 'online_payment',
  CYCLE_MENU_MANAGEMENT = 'cycle_menu_management',
  DINING_RD = 'dining_rd',
  GORDON_RESTAURANT_PRO = 'gordon_restaurant_pro',
  GORDON_REPORTING = 'gordon_reporting',
  GORDON_REPORTING_FR = 'gordon_reporting_fr',
  GORDON_EDUCATION_REPORTING = 'gordon_education_reporting',
  GORDON_EDUCATION_REPORTING_FR = 'gordon_education_reporting_fr',
  GORDON_HEALTH_REPORTING = 'gordon_health_reporting',
  GORDON_HEALTH_REPORTING_FR = 'gordon_health_reporting_fr',
}

export type MoreToolsLink = {
  translationName: string;
  headerLinkType: HeaderLinkType;
};
