import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialEntitlementMapping } from '../../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-commodity-allocation',
  templateUrl: './commodity-allocation.component.html',
  styleUrls: ['./commodity-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommodityAllocationComponent {
  @Input() mapping: MaterialEntitlementMapping;
}
