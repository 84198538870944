import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { GuideCategoryHeader } from 'src/app/guides/shared/guides';
import {
  MaterialListStyle,
  MaterialRowContext,
} from 'src/app/core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-category-header',
  templateUrl: './material-category-header.component.html',
  styleUrls: ['./material-category-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCategoryHeaderComponent {
  @Input() categoryHeader: GuideCategoryHeader;
  @Input() listStyle: MaterialListStyle;
  @Input() context: MaterialRowContext;
  @Input() isMobile: boolean;
  @Input() isFirst: boolean;
  @Input() isParEnabled: boolean;
  @Input() isLoyaltyProgramEligible: boolean;

  @Output() categoryJump: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('class.list') get list() {
    return this.listStyle === MaterialListStyle.List;
  }

  @HostBinding('class.slim') get slim() {
    return this.listStyle === MaterialListStyle.Slim;
  }

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  @HostBinding('class.first') get first() {
    return this.isFirst;
  }

  jumpToCategory(index: number) {
    this.categoryJump.emit(index);
  }
}
