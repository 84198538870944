import { NutritionLabel } from './models/nutrition';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export enum NutritionRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export interface NutritionRecordState {
  materialNumber: string;
  status: NutritionRecordStatus;
  record?: NutritionLabel;
}

export interface NutritionState {
  nutritionRecords: EntityState<NutritionRecordState>;
}

export const nutritionAdapter = createEntityAdapter<NutritionRecordState>({
  selectId: (state) => state.materialNumber,
});

export const initialNutritionState: NutritionState = {
  nutritionRecords: nutritionAdapter.getInitialState(),
};
