import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialUnitsActions } from './material-units.actions';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';
import { selectAllMaterialUnitsRecords } from './material-units.selectors';
import { EntityState } from '@ngrx/entity';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MaterialUnitsFacade {
  constructor(private store: Store) {}

  public loadMaterialUnitsInfo() {
    this.store.dispatch(MaterialUnitsActions.getMaterialUnitsInformation());
  }

  public getLoadedMaterialUnitsRecords(): Observable<
    EntityState<MaterialUnitsRecord>
  > {
    return this.store.select(selectAllMaterialUnitsRecords);
  }
}
