import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { LastOrderedRecords } from './models/last-ordered-records';

@Injectable({ providedIn: 'root' })
export class LastOrderedService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getLastOrdered(materialNumbers: string[]): Observable<LastOrderedRecords> {
    return this.httpClient.post<LastOrderedRecords>(
      this.webBffService.getBff() + '/api/v1/last-ordered',
      {
        materialNumbers: materialNumbers,
      },
    );
  }
}
