import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialComparisonActions } from './material-comparison.actions';
import { Observable } from 'rxjs';
import {
  selectCanCompareMoreMaterials,
  selectIsMaterialComparisonCollapsed,
  selectMaterialComparisonNumbers,
  selectShouldShowComparingDocket,
} from './material-comparison.selectors';

@Injectable({ providedIn: 'root' })
export class MaterialComparisonFacade {
  constructor(private store: Store) {}

  toggleMaterialComparison(isEnabled: boolean) {
    this.store.dispatch(
      MaterialComparisonActions.toggleMaterialComparison(isEnabled),
    );
  }

  toggleCollapseMaterialComparison(isCollapsed: boolean) {
    this.store.dispatch(
      MaterialComparisonActions.toggleCollapseMaterialComparison(isCollapsed),
    );
  }

  addMaterialComparison(materialNumber: string) {
    this.store.dispatch(
      MaterialComparisonActions.addMaterialComparison(materialNumber),
    );
  }

  removeMaterialComparison(materialNumber: string) {
    this.store.dispatch(
      MaterialComparisonActions.removeMaterialComparison(materialNumber),
    );
  }

  removeAllMaterialComparison() {
    this.store.dispatch(
      MaterialComparisonActions.removeAllMaterialComparisons(),
    );
  }

  forceHideMaterialComparisonDocket(shouldHideDocket: boolean) {
    this.store.dispatch(
      MaterialComparisonActions.forceHideMaterialComparisonDocket(
        shouldHideDocket,
      ),
    );
  }

  shouldShowComparingDocket(): Observable<boolean> {
    return this.store.select(selectShouldShowComparingDocket);
  }

  isMaterialComparisonCollapsed(): Observable<boolean> {
    return this.store.select(selectIsMaterialComparisonCollapsed);
  }

  getMaterialComparisonNumbers(): Observable<string[]> {
    return this.store.select(selectMaterialComparisonNumbers);
  }

  canCompareMoreMaterials(): Observable<boolean> {
    return this.store.select(selectCanCompareMoreMaterials);
  }

  loadFromMaterialList(materialNumbers: string[]) {
    this.store.dispatch(
      MaterialComparisonActions.loadFromMaterialList(materialNumbers),
    );
  }
}
