import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BaseUomWeight } from '../models/material-info';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'naooweight',
})
export class NaooWeightPipe implements PipeTransform {
  private readonly kgToLbs = 2.205;

  transform(value: BaseUomWeight, separator: string = '/'): string {
    if (!value?.net || !value?.uom) {
      return undefined;
    }
    const displayNet = `${(+value.net).toFixed(2)} ${value.uom}`;
    if ('kg' !== value.uom.toLowerCase()) {
      return displayNet.toLowerCase();
    }
    const lbs = +value.net * this.kgToLbs;
    return `${lbs.toFixed(2)} lbs ${separator} ${displayNet}`.toLowerCase();
  }
}
