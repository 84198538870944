import { Injectable } from '@angular/core';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { CustomerMaterialRecord } from './model/customer-material-record';
import { map } from 'rxjs/operators';
import { NaooHttpClient } from 'src/app/shared/http-client/naoo-http-client';

@Injectable({ providedIn: 'root' })
export class CustomerMaterialService {
  constructor(
    private readonly httpClient: NaooHttpClient,
    private readonly webBffService: WebBffService,
  ) {}

  getCustomerMaterial(): Observable<CustomerMaterialRecord> {
    return this.httpClient
      .get<CustomerMaterialResponse>(
        `${this.webBffService.getBff()}/api/v1/customer-material`,
      )
      .pipe(map((response) => response.materialInfo));
  }
}

interface CustomerMaterialResponse {
  materialInfo: CustomerMaterialRecord;
}
