import { CustomerPreferencesRecord } from '../../services/customer-preferences/models/customer-preferences-record';

export interface CustomerPreferencesState {
  customerPreferences?: CustomerPreferencesRecord;
  hasError?: boolean;
}

export const initialCustomerPreferencesState: CustomerPreferencesState = {
  customerPreferences: undefined,
  hasError: false,
};
