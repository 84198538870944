<naoo-modal-header
  class="contact-support-modal-header"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>
<div class="create-modal-container">
  <div class="modal-title">
    {{ 'SUPPORT_CONTACT.MODAL.TITLE' | translate }}
  </div>
  <div class="modal-body">
    {{ 'SUPPORT_CONTACT.MODAL.BODY' | translate }}
    <a
      href="tel:{{
        telephoneTranslationBase | naoobrand: customerBrand | translate
      }}"
      >{{ telephoneTranslationBase | naoobrand: customerBrand | translate }}</a
    >
    <div class="questions">
      {{ 'SUPPORT_CONTACT.MODAL.QUESTIONS' | translate }}
    </div>
  </div>
</div>
