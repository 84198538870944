<div class="label-container">
  <div class="header">Nutrition Facts</div>
  @if (servingInfo) {
    <div class="divider-line small"></div>
    <div class="nutrition-line">
      <span class="line-label">Serving Size</span>
      <span class="line-value">{{ servingInfo }}</span>
    </div>
  }
  <div class="divider-line large"></div>
  <div class="line-label small">Amount Per Serving</div>
  <div class="nutrition-line">
    <span class="line-label large">Calories</span>
    <span class="line-value large">{{
      roundedAmount(nutritionInfo.calorieInformation)
    }}</span>
  </div>
  <div class="divider-line"></div>
  <div class="daily-value">% Daily Value*</div>
  <div class="nutrient" *ngFor="let nutrient of nutritionInfo.nutrients">
    <div
      class="divider-line small"
      [ngClass]="{ indented: isAddedSugars(nutrient.label) }"
    ></div>
    <div class="nutrition-line" [ngClass]="'level' + nutrient.level">
      <span>
        <span class="line-label" [ngClass]="'level' + nutrient.level">
          {{ getNutrientLabelText(nutrient.label) }}
        </span>
        {{ parsedAmount(roundedAmount(nutrient), nutrient.uom) }}
        <span *ngIf="isAddedSugars(nutrient.label)">{{ nutrient.label }}</span>
      </span>
      <span class="line-value" *ngIf="nutrient.shouldReferenceDailyIntake">
        {{ percentageAmount(nutrient.referenceDailyIntakeAmount) }}
      </span>
    </div>
  </div>
  <div class="divider-line large"></div>
  <div
    class="focused-nutrient"
    *ngFor="
      let focusedNutrient of nutritionInfo.focusedNutrients;
      let isFirst = first
    "
  >
    <div class="divider-line small" *ngIf="!isFirst"></div>
    <div class="nutrition-line">
      <span>
        <span class="line-label">{{ focusedNutrient.label }}</span>
        {{ parsedAmount(focusedNutrient.amount, focusedNutrient.uom) }}
      </span>
      <span
        class="line-value"
        *ngIf="focusedNutrient.shouldReferenceDailyIntake"
      >
        {{ percentageAmount(focusedNutrient.referenceDailyIntakeAmount) }}
      </span>
    </div>
  </div>
  <div class="divider-line"></div>
  <div class="footer">
    * The % Daily Value (DV) tells you how much a nutrient in a serving of food
    contributes to a daily diet. 2,000 calories a day is used for general
    nutrition advice.
  </div>
</div>
