import { Component, Input } from '@angular/core';
import { NaooConstants } from '../../shared/NaooConstants';

@Component({
  selector: 'naoo-customer-unit-selector',
  templateUrl: './customer-unit-selector.component.html',
  styleUrls: ['./customer-unit-selector.component.scss'],
})
export class CustomerUnitSelectorComponent {
  @Input() showArrowIcon = false;
  @Input() isDisabled = false;

  readonly customerUnitRoute = NaooConstants.CUSTOMER_UNIT_SELECTION_PATH;
}
