import { createActionGroup, emptyProps } from '@ngrx/store';
import { SalesCriticalItem } from '../../services/sales-critical-items/model/sales-critical-items';

export const SalesCriticalItemsActions = createActionGroup({
  source: 'SalesCriticalItems',
  events: {
    'Get Sales Critical Items': (routeDate?: string) => ({ routeDate }),
    'Get Sales Critical Items Success': (
      salesCriticalItems: SalesCriticalItem[],
    ) => ({ salesCriticalItems }),
    'Get Sales Critical Items Failure': emptyProps(),
    'Refresh Sales Critical Items': emptyProps(),
  },
});
