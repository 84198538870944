import { Localized } from '../../../../shared/models/localized';
import { MaterialFlag } from '../../../../material-flag/material-flag';
import { MaterialWarning } from '../../material-warning/material-warning';
import { MaterialUnitInfo } from '../../../../shared/models/material-info';
import {
  MaterialListRow,
  MaterialListRowType,
} from 'src/app/material-list/models/material-list';
import { Locale } from 'src/app/core/services/session/models/session-record';
import { VendorInfoRecord } from '../../../services/material-additional-info/models/material-additional-infos-record';
import { AuxiliaryAnalyticsData } from '../../../services/ecommerce-analytics/models/google-events';

export interface MaterialRow {
  status: MaterialRowStatus;
  isDeleted: boolean;
  materialRowOptions: MaterialRowSharedOptions;
  materialRowInfo?: MaterialRowInfo;
  flags: MaterialFlag[];
  materialOrdering?: MaterialOrdering;
  materialRowFooter?: MaterialRowFooter;
}

export enum MaterialRowContext {
  OrderGuide = 'Order Guide',
  CartSummary = 'Cart Summary',
  Browse = 'Browse',
  CustomGuide = 'Custom Guide',
  MarketingGuide = 'Marketing Guide',
  CartReview = 'Cart Review',
  CriticalItem = 'Critical Item',
  Comparison = 'Comparison',
  Substitutes = 'Substitutes',
  CriticalItemsGuide = 'Critical Items Guide',
  Recommendation = 'Recommendation',
  ProductDetails = 'Product Details',
  NonEditableItem = 'Non Editable Item',
  ErrorProcessingItem = 'Error Processing Item',
  PdpLinkItem = 'Non Editable Product details Link',
  OverallocationModal = 'Overallocation Modal',
  OrderDetailsEditModal = 'Order Details Edit Modal',
  ReOrder = 'Re-Order Modal',
  QuickAdd = 'Quick Add',
  ImportOrder = 'Import Order',
  ActivateCart = 'Activate Cart',
  ComplimentaryProduct = 'Complimentary Product',
  HandPickedForYou = 'Handpicked for you',
}

export enum MaterialListStyle {
  Slim = 'Slim',
  SlimGrid = 'SlimGrid',
  List = 'List',
  Grid = 'Grid',
}

export enum MaterialRowSize {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}

export enum MaterialRowStatus {
  Unavailable = 'Unavailable',
  Unorderable = 'Unorderable',
  Ok = 'Ok',
  Loading = 'Loading',
}

export enum MaterialCommodityShieldColor {
  GREEN,
  YELLOW,
  RED,
  BLUE,
}

export interface MaterialRowSharedOptions {
  materialNumber: string;
  context: MaterialRowContext;
  materialListStyle: MaterialListStyle;
  isOffline: boolean;
  isCartLoaded: boolean;
  isParEnabled: boolean;
  locale: Locale;
  isLoyaltyProgramEligible: boolean;
  customerMaterialNumber?: string;
  customGuideId?: string;
  materialCommodityInfo?: MaterialCommodityInfo;
  isSponsoredMaterial?: boolean;
  isSponsoredMaterialView?: boolean;
  isOrderGuideItem?: boolean;
  analytics?: AuxiliaryAnalyticsData;
}

export interface MaterialRowInfo {
  description: Localized<string>;
  brand: Localized<string>;
  innerPackSize: string;
  units: MaterialUnitInfo[];
}

export interface MaterialCommodityInfo {
  displayMaxAllocation: boolean;
  maxAllocationDisplay: string;
  maxAllocationDisplayKey: string;
  shieldColor?: MaterialCommodityShieldColor;
  passThroughMethod?: string;
  mappings?: MaterialEntitlementMapping[];
  vendorInfo?: VendorInfoRecord;
  isUnorderable?: boolean;
}

export interface MaterialEntitlementMapping {
  allocationUom: string;
  allocationAvailable: number;
  allocationTotal: number;
  allocationBackgroundColor: AllocationBackgroundColor;
  drawdownFactor: number;
  drawdownFactorUom: string;
  passThroughValue: string;
  passThroughValueUom: string;
  remainingAllocationPercentage: string;
  usdaMaterialDescription: string;
  usdaMaterialId: string;
  fullDescription: string;
}

export enum AllocationBackgroundColor {
  GREEN = 'green',
  GRAY = 'gray',
}

export interface MaterialOrderingTab {
  uom: string;
  displayCode: string;
  quantity: number;
}

export interface MaterialOrdering {
  isInventoryLoaded: boolean;
  materialOrderingInfos: MaterialOrderingInfo[];
  tabs: MaterialOrderingTab[];
  materialWarning?: MaterialWarning;
  materialEstimatedSavings?: number;
}

export interface MaterialOrderingInfo {
  uom: string;
  displayCode: string;
  isCatchWeight: boolean;
  catchWeightAvg: number;
  catchWeightUom: string;
  price: number;
  totalEstimatedCost: number;
  totalEstimatedSavings: number;
  line: MaterialLine;
  parLine?: MaterialParLine;
  pricingPortionUom?: string;
  pricingPortionPerBaseUom?: number;
  pricingPortion?: number;
  loyaltyPoints?: number;
}

export interface MaterialLine {
  uom: string;
  displayCode: string;
  quantity: number;
}

export interface MaterialParLine {
  uom: string;
  parQuantity: number;
  inventoryQuantity?: number;
}

export interface MaterialCompareCheckbox {
  isComparing: boolean;
  canCompare: boolean;
  isHidden?: boolean;
}

export interface MaterialLastOrdered {
  date: string;
  line?: MaterialLine;
}

export interface MaterialRowFooter {
  compareCheckbox: MaterialCompareCheckbox;
  lastOrdered?: MaterialLastOrdered;
}

export function createMaterialListRow(materialNumber: string): MaterialListRow {
  return {
    type: MaterialListRowType.MaterialRow,
    value: materialNumber,
  };
}
