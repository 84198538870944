import { Inject, Injectable } from '@angular/core';
import { CONTENT_SCROLL } from '../scrollable-content/scrollable-content.service';
import { fromEvent, Observable, ReplaySubject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollListenerService {
  private readonly THROTTLE_TIME = 150;
  private eventSubject = new ReplaySubject<boolean>(1);

  constructor(@Inject(CONTENT_SCROLL) private parentScrollElement: Element) {
    this.getScrollEvent();
  }

  private getScrollEvent() {
    fromEvent(this.parentScrollElement, 'scroll')
      .pipe(throttleTime(this.THROTTLE_TIME))
      .subscribe(() => {
        this.pushEvent();
      });
  }

  private pushEvent() {
    this.eventSubject.next(true);
  }

  public getEvents(): Observable<boolean> {
    return this.eventSubject.asObservable();
  }
}
