import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { MaterialRecord } from '../material-info/models/materials-info-record';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  timeout,
} from 'rxjs/operators';
import { selectMaterialInfoRecordStates } from '../../store/material-info/material-info.selectors';
import { MaterialInfoRecordState } from '../../store/material-info/material-info.state';
import { selectCombinedPricingRecords } from '../../store/material-price/material-price.selectors';
import { hasMaterialInfoFinishedLoading } from '../../store/material-info/material-info.util';
import {
  CombinedPricingRecord,
  convertCombinedPriceToMap,
} from '../../store/material-price/material-price.util';
import { Dictionary } from '@ngrx/entity';
import { CartEntityState } from '../../store/cart/cart.state';
import { selectCartEntity } from '../../store/cart/cart.selectors';
import { MaterialQuantityDetail } from '../../store/shared/shared';
import { NaooConstants } from '../../../shared/NaooConstants';
import {
  CartAnalyticInformation,
  MaterialAdjustmentAnalyticInformation,
  selectCartAnalyticInformation,
  selectMaterialAdjustmentAnalyticInformation,
} from '../../store/ecommerce-analytics/ecommerce-analytics.selectors';

@Injectable({ providedIn: 'root' })
export class EcommerceAnalyticsMaterialService {
  private readonly timeoutDuration = 5000;

  constructor(private store: Store) {}

  getLoadedCart(): Observable<CartEntityState> {
    return this.store.select(selectCartEntity).pipe(
      filter((cart) => !!cart),
      first(),
    );
  }

  getLoadedMaterialRecord(materialNumber: string): Observable<MaterialRecord> {
    return this.getLoadedMaterialRecords([materialNumber]).pipe(
      map((materialInfos) => materialInfos.get(materialNumber)),
    );
  }

  getLoadedMaterialRecords(
    materialNumbers: string[],
  ): Observable<Map<string, MaterialRecord>> {
    return this.store
      .select(selectMaterialInfoRecordStates(materialNumbers))
      .pipe(
        filter((recordStates: MaterialInfoRecordState[]) =>
          recordStates.every((recordState) =>
            hasMaterialInfoFinishedLoading(recordState),
          ),
        ),
        timeout(this.timeoutDuration),
        catchError(() =>
          this.store.select(selectMaterialInfoRecordStates(materialNumbers)),
        ),
        first(),
        map((materialInfos) => {
          const materialInfosMap = new Map<string, MaterialRecord>();
          materialInfos
            ?.filter((recordState) => !!recordState && !!recordState.record)
            .forEach((recordState) =>
              materialInfosMap.set(
                recordState.materialNumber,
                recordState.record,
              ),
            );
          return materialInfosMap;
        }),
      );
  }

  getMaterialAdjustmentAnalyticInformation(
    materialNumbers: string[],
    startIndex?: number,
    materialQuantityDetails?: MaterialQuantityDetail[],
  ): Observable<MaterialAdjustmentAnalyticInformation> {
    return forkJoin([
      this.getLoadedMaterialRecords(materialNumbers),
      this.getLoadedCombinedPricingRecords(materialNumbers),
    ]).pipe(
      switchMap(([materialRecords, materialPrices]) => {
        let index = startIndex ?? 0;
        const materialQuantities =
          materialQuantityDetails ??
          materialNumbers
            ?.filter((materialNumber) => materialRecords.get(materialNumber))
            .map((material) =>
              this.buildMaterialQuantityDetail(
                material,
                materialRecords,
                ++index,
              ),
            );
        return this.store
          .select(
            selectMaterialAdjustmentAnalyticInformation(
              materialQuantities,
              materialRecords,
              materialPrices,
            ),
          )
          .pipe(first());
      }),
    );
  }

  getCartAnalyticInformation(
    materialNumbers: string[],
  ): Observable<CartAnalyticInformation> {
    return forkJoin([
      this.getLoadedMaterialRecords(materialNumbers),
      this.getLoadedCombinedPricingRecords(materialNumbers),
    ]).pipe(
      switchMap(([materialRecords, materialPrices]) => {
        return this.store
          .select(
            selectCartAnalyticInformation(
              materialNumbers,
              materialRecords,
              materialPrices,
            ),
          )
          .pipe(first());
      }),
    );
  }

  getLoadedCombinedPricingRecord(
    materialNumber: string,
  ): Observable<CombinedPricingRecord> {
    return this.getLoadedCombinedPricingRecords([materialNumber]).pipe(
      map((materialPrices) => materialPrices.get(materialNumber)),
    );
  }

  getLoadedCombinedPricingRecords(
    materialNumbers: string[],
  ): Observable<Map<string, CombinedPricingRecord>> {
    return this.store
      .select(selectCombinedPricingRecords(materialNumbers, true))
      .pipe(
        filter((combinedPricingDictionary: Dictionary<CombinedPricingRecord>) =>
          Object.values(combinedPricingDictionary).every(
            (recordState: CombinedPricingRecord) => !!recordState?.hasLoaded,
          ),
        ),
        timeout(this.timeoutDuration),
        catchError(() =>
          this.store.select(
            selectCombinedPricingRecords(materialNumbers, true),
          ),
        ),
        first(),
        map((combinedPricingDictionary: Dictionary<CombinedPricingRecord>) => {
          return convertCombinedPriceToMap(
            Object.values(combinedPricingDictionary),
          );
        }),
      );
  }

  protected buildMaterialQuantityDetail(
    materialNumber: string,
    materialRecords: Map<string, MaterialRecord>,
    index: number,
  ): MaterialQuantityDetail {
    return {
      materialNumber,
      lines: [
        {
          index,
          uom:
            materialRecords.get(materialNumber)?.baseUom ||
            NaooConstants.Uom.Case,
          quantity: 1,
        },
      ],
    };
  }
}
