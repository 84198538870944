import { NgModule } from '@angular/core';
import { AppStoreModule } from './store/app-store.module';
import {
  ORDER_CONFIRMATION_POLLING_DELAY_MILLIS,
  ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS,
} from './injection-tokens';

@NgModule({
  declarations: [],
  imports: [AppStoreModule],
  providers: [
    {
      provide: ORDER_CONFIRMATION_POLLING_DELAY_MILLIS,
      useValue: 5000,
    },
    {
      provide: ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS,
      useValue: 2000,
    },
  ],
})
export class CoreModule {}
