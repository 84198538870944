import { createActionGroup, emptyProps } from '@ngrx/store';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';

export const DeliveryScheduleActions = createActionGroup({
  source: 'Delivery Schedule',
  events: {
    'Get Delivery Schedules': emptyProps(),
    'Get Delivery Schedules Success': (
      payload: DeliveryScheduleEntryRecord[],
    ) => ({ payload }),
    'Get Delivery Schedules Failure': emptyProps(),
    'Refresh Delivery Schedules': emptyProps(),
  },
});
