import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Notification,
  NotificationCallback,
  NotificationMessage,
  NotificationPriority,
} from '../../models/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  notificationSubject = new BehaviorSubject<Notification | null>(null);

  public sendNotification(
    message: NotificationMessage,
    callback?: NotificationCallback,
    priority: NotificationPriority = NotificationPriority.LOW,
  ) {
    const notification = this.notificationSubject.getValue();
    if (!notification || priority >= notification.priority) {
      this.notificationSubject.next(
        new Notification(message, callback, priority),
      );
    }
  }

  public clearNotification(
    priority: NotificationPriority = NotificationPriority.LOW,
    messageTitleToClear?: string,
  ) {
    const notification = this.notificationSubject.getValue();
    if (notification && notification.priority === priority) {
      if (
        !!messageTitleToClear &&
        notification.message.title !== messageTitleToClear
      ) {
        return;
      }
      this.notificationSubject.next(null);
    }
  }

  public clearAllNotifications() {
    this.notificationSubject.next(null);
  }

  public getNotifications(): Observable<Notification | null> {
    return this.notificationSubject.asObservable();
  }
}
