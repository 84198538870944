import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

// Needed for gestures
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    /**
     * Enable the change detection profiler when we're developing
     * run "ng.profiler.timeChangeDetection();" in console
     * https://github.com/angular/angular/blob/master/docs/TOOLS.md#performance
     */
    if (!environment.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);
    }
  })
  .catch((err) => console.log(err));
