import {
  getCartReviewSectionGroupDisplayOrder,
  getCutoffSplitMaterials,
  getDropShipRetalixMaterials,
  getEmailOptInStatus,
  getSpecialMaterials,
  getStandardMaterials,
  getStockWarningMaterials,
  getUnavailableMaterials,
} from './cart-review-util';
import {
  createCutoffSplitItemsSection,
  createDropShipItemsSections,
  createDropShipRetalixItemsSection,
  createFulfillmentSystemErrorSection,
  createSpecialItemsSection,
  createStandardItemsSection,
  createStockWarningItemsSection,
  createStoreItemsSections,
  createUnavailableItemsSection,
} from './cart-review-factory';
import { DeliveryScheduleEntryRecord } from '../../../services/delivery-schedule/models/delivery-schedules-record';
import { MaterialListRow } from '../../../../material-list/models/material-list';
import {
  CurrentSystem,
  CustomerPermission,
  Site,
} from '../../../services/session/models/session-record';
import {
  CartReview,
  CartReviewTransformerData,
  StoreOrderPaymentOptions,
} from '../cart-review.selectors';
import {
  CartReviewMetadata,
  CartReviewSection,
  CartReviewSectionGroup,
  CartReviewSectionName,
} from '../cart-review.state';
import { FulfillmentType } from '../../../services/cart/models/cart-record';
import {
  SelectableButton,
  SelectableButtonType,
} from '../../../../cart/selectable-button/selectable-button.component';
import moment from 'moment';

export function transformCartReview(
  data: CartReviewTransformerData,
  metadata: CartReviewMetadata,
  isOffline: boolean,
): CartReview {
  let usedMaterials: MaterialListRow[] = [];
  const failedDropShipSplitMaterials = [
    ...(data?.failedDropShipSplitMaterials || []),
    ...(metadata?.failedMaterials || []),
  ];

  const failedMaterials = data.materialInfos.filter((materialInfo) =>
    failedDropShipSplitMaterials.includes(materialInfo?.materialNumber),
  );
  const failedMaterialInfos = getStandardMaterials(failedMaterials, []);
  usedMaterials = usedMaterials.concat(failedMaterialInfos);
  const dropShipSystemErrorSection = buildCartReviewSectionGroup(
    CartReviewSectionName.FulfillmentSystemErrorSection,
    [createFulfillmentSystemErrorSection(failedMaterialInfos)],
  );

  const dropShipSection: CartReviewSectionGroup = transformCartReviewDropShip(
    data,
    metadata,
  );
  dropShipSection.sections.forEach((section) => {
    usedMaterials = usedMaterials.concat(section.materialNumbers);
  });

  const storeSection: CartReviewSectionGroup = transformCartReviewStore(
    data,
    metadata,
  );
  storeSection.sections.forEach((section) => {
    usedMaterials = usedMaterials.concat(section.materialNumbers);
  });

  const unavailableMaterials = getUnavailableMaterials(
    data.availabilities,
    data.materialInfos,
    usedMaterials,
  );
  usedMaterials = usedMaterials.concat(unavailableMaterials);

  const specialMaterials = getSpecialMaterials(
    data.availabilities,
    usedMaterials,
  );
  usedMaterials = usedMaterials.concat(specialMaterials);

  const cutoffSplitRecord = metadata?.cutoffSplitRecord;
  let cutoffMaterials: MaterialListRow[] = [];
  let nextValidDeliverySchedules: DeliveryScheduleEntryRecord[] = [];
  if (cutoffSplitRecord) {
    cutoffMaterials = getCutoffSplitMaterials(
      cutoffSplitRecord.materialsPastCutoff,
      usedMaterials,
    );
    usedMaterials = usedMaterials.concat(cutoffMaterials);
    nextValidDeliverySchedules = cutoffSplitRecord.nextValidDeliverySchedules;
  }

  const stockWarningMaterials = getStockWarningMaterials(
    data.warnings,
    usedMaterials,
  );
  usedMaterials = usedMaterials.concat(stockWarningMaterials);

  const standardMaterials = getStandardMaterials(
    data.materialInfos,
    usedMaterials,
  );
  usedMaterials = usedMaterials.concat(standardMaterials);

  const header = data.headerData.cartReviewHeaderData;
  const customerHeader = header.customerHeaderInfo;
  const routeDate = header.routeDate;
  const poNumber = header.poNumber;

  const groups: CartReviewSectionGroup[] = [
    dropShipSystemErrorSection,
    storeSection,
    buildCartReviewSectionGroup(CartReviewSectionName.CutoffSplitItemsSection, [
      createCutoffSplitItemsSection(
        cutoffMaterials,
        nextValidDeliverySchedules,
        poNumber,
      ),
    ]),

    buildCartReviewSectionGroup(CartReviewSectionName.UnavailableItemsSection, [
      createUnavailableItemsSection(unavailableMaterials),
    ]),
    buildCartReviewSectionGroup(
      CartReviewSectionName.StockWarningItemsSection,
      [
        createStockWarningItemsSection(
          stockWarningMaterials,
          routeDate,
          poNumber,
        ),
      ],
    ),
    buildCartReviewSectionGroup(CartReviewSectionName.SpecialItemsSection, [
      createSpecialItemsSection(specialMaterials, routeDate, poNumber),
    ]),
    dropShipSection,
    buildCartReviewSectionGroup(CartReviewSectionName.StandardItemsSection, [
      createStandardItemsSection(
        standardMaterials,
        customerHeader.customerName,
        customerHeader.customerId,
        routeDate,
        poNumber,
      ),
    ]),
  ].filter((group) => group.hasMaterials);

  return {
    emailOptIn: {
      email: data.session?.email ? data.session.email : '',
      status: getEmailOptInStatus(
        data.headerData.emailNotifications,
        data.headerData.emailOptOuts,
        data.session?.email,
      ),
    },
    groups,
    isOffline,
    failedDropShipSplitMaterials,
    storeOrderPaymentDetails: transformPaymentOptions(metadata),
  };
}

function transformPaymentOptions(
  metadata: CartReviewMetadata,
): StoreOrderPaymentOptions {
  const paymentInfo = metadata?.paymentInfo;
  if (paymentInfo) {
    const isCreditDisabled = paymentInfo.checkoutId == null;
    const isPayInStoreAvailable = !paymentInfo.paymentRequired;
    const disabledIcon = 'clear_filter_icon';

    const paymentChoices: SelectableButton[] = [
      {
        text: 'CART_REVIEW.ADD_PAYMENT.CREDIT_CARD',
        icon: isCreditDisabled ? disabledIcon : 'credit_card_icon',
        disabled: isCreditDisabled,
        buttonType: SelectableButtonType.CREDIT_PAYMENT,
      },
    ];
    if (isPayInStoreAvailable) {
      paymentChoices.push({
        text: 'CART_REVIEW.ADD_PAYMENT.PAY_IN_STORE',
        icon: 'store_icon_gray',
        buttonType: SelectableButtonType.IN_STORE_PAYMENT,
      });
    }

    return {
      paymentChoices,
      checkoutId: paymentInfo.checkoutId,
      paymentRequired: paymentInfo.paymentRequired,
      paymentAvailable: paymentInfo.paymentAvailable,
    };
  }
  return undefined;
}

export function transformCartReviewDropShip(
  data: CartReviewTransformerData,
  metadata: CartReviewMetadata,
): CartReviewSectionGroup {
  if (data.session?.activeCustomer?.currentSystem == CurrentSystem.Retalix) {
    return buildRetalixCartReviewSectionGroup(data);
  }
  const isPoNumberRequired =
    !!data?.session?.activeCustomer?.permissions.includes(
      CustomerPermission.PoRequired,
    );
  const activeCustomer = data.session?.activeCustomer;
  const fulfillmentOrderSplits = metadata?.fulfillmentOrderSplits;
  const sites: Site[] = activeCustomer?.sites;
  const selectedSiteId = metadata?.selectedDropShipSiteId;
  const poNumber = data.headerData?.cartReviewHeaderData.poNumber;
  const dropShipOrderSections = createDropShipItemsSections(
    data.availabilities,
    fulfillmentOrderSplits,
    sites,
    selectedSiteId,
    poNumber,
    isPoNumberRequired,
  );
  return buildCartReviewSectionGroup(
    CartReviewSectionName.DropShipItemsSection,
    dropShipOrderSections,
  );
}

export function transformCartReviewStore(
  data: CartReviewTransformerData,
  metadata: CartReviewMetadata,
): CartReviewSectionGroup {
  const header = data.headerData.cartReviewHeaderData;
  const customerTz = data.session.activeCustomer.timeZone;
  const storeOrderSections = createStoreItemsSections(
    data.storeData,
    moment.tz(header.routeDate, customerTz),
    header.poNumber,
    metadata?.storeOrderSplitOptions,
    customerTz,
  );
  return buildCartReviewSectionGroup(
    FulfillmentType.EXPRESS === data.storeData.fulfillmentType
      ? CartReviewSectionName.ExpressItemsSection
      : CartReviewSectionName.PickupItemsSection,
    storeOrderSections,
  );
}

function buildRetalixCartReviewSectionGroup(data: CartReviewTransformerData) {
  const header = data.headerData.cartReviewHeaderData;
  const routeDate = header.routeDate;
  const poNumber = header.poNumber;

  const dropShipV1Materials: MaterialListRow[] = getDropShipRetalixMaterials(
    data.availabilities,
  );
  return buildCartReviewSectionGroup(
    CartReviewSectionName.DropShipRetalixItemsSection,
    [
      createDropShipRetalixItemsSection(
        dropShipV1Materials,
        routeDate,
        poNumber,
      ),
    ],
  );
}

function buildCartReviewSectionGroup(
  name: CartReviewSectionName,
  sections: CartReviewSection[],
): CartReviewSectionGroup {
  return {
    name,
    sections,
    displayOrder: getCartReviewSectionGroupDisplayOrder(name),
    hasMaterials: sections?.some(
      (section) => section.materialNumbers.length > 0,
    ),
  };
}
