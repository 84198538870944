import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PickupSchedulesReducer } from './pickup-schedules.reducer';
import { PickupSchedulesEffects } from './pickup-schedules.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('pickupSchedules', PickupSchedulesReducer),
    EffectsModule.forFeature([PickupSchedulesEffects]),
  ],
})
export class PickupSchedulesStoreModule {}
