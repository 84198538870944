import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SharedModule } from '../../../../shared.module';
import { SelectDateStepCalendarComponent } from './select-date-step/select-date-step-calendar/select-date-step-calendar.component';
import { SelectDateContainerComponent } from './select-date-container.component';
import { SelectDateStepIspuComponent } from './select-date-step/select-date-step-ispu/select-date-step-ispu.component';
import { SelectDateStepTruckComponent } from './select-date-step/select-date-step-truck/select-date-step-truck.component';
import { SelectDateStepExpressComponent } from './select-date-step/select-date-step-express/select-date-step-express.component';
import { OrderMethodModalSharedModule } from '../../shared/order-method-modal-shared.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatDatepickerModule,
    FormsModule,
    SharedModule,
    MatRadioModule,
    OrderMethodModalSharedModule,
  ],
  declarations: [
    SelectDateContainerComponent,
    SelectDateStepCalendarComponent,
    SelectDateStepExpressComponent,
    SelectDateStepIspuComponent,
    SelectDateStepTruckComponent,
  ],
  exports: [SelectDateContainerComponent],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SelectDateStepModule {}
