<button
  class="select-order-method-button select-order-method-widget"
  [disabled]="disabled"
  (click)="openFulfillmentModal()"
  [ngClass]="{
    'normal-layout': !wideLayout,
    'wide-layout': wideLayout && !displayingDateOnly,
    'flex-column': !isFulfillmentTypeNone,
    disabled: disabled,
  }"
>
  <mat-icon
    class="calendar-image"
    [ngClass]="{ disabled: disabled }"
    [svgIcon]="icon"
  ></mat-icon>
  <ng-container *ngIf="!this.isMobile; else mobileTemplate">
    <div
      class="text"
      [ngClass]="{ disabled: disabled, 'flex-row': isFulfillmentTypeNone }"
    >
      <div class="title">
        {{ title | translate }}
        <span *ngIf="isFulfillmentTypeExpress">
          {{ this.viewModel?.routeDate | naooDate: 'monthDay' }}
        </span>
      </div>
      <div class="subtitle" [ngSwitch]="this.viewModel?.fulfillmentType">
        <ng-container *ngSwitchCase="FulfillmentType.TRUCK">
          {{ displayTruckDate | naooDate: 'monthDay' : '+0000' }}
        </ng-container>
        <ng-container *ngSwitchCase="FulfillmentType.PICKUP">
          {{ this.viewModel?.routeDate | naooDate: 'monthDay' : '+0000' }}
        </ng-container>
        <ng-container *ngSwitchCase="FulfillmentType.EXPRESS">
          {{
            this.viewModel?.deliveryWindowStartTimestamp
              | naooTimeRange
                : this.viewModel?.deliveryWindowEndTimestamp
                : this.viewModel?.customerTimeZone
          }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ 'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SUBTITLE' | translate }}
        </ng-container>
      </div>
    </div>
  </ng-container>
</button>

<ng-template #mobileTemplate>
  <div
    class="fulfillment-text"
    *ngIf="!isFulfillmentTypeNone; else noFulfillmentTypeText"
  >
    <div class="fulfillment-title" [ngSwitch]="this.viewModel?.fulfillmentType">
      <ng-container *ngSwitchCase="FulfillmentType.TRUCK">
        {{ displayTruckDate | naooDate: 'monthDay' : '+0000' }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ this.viewModel?.routeDate | naooDate: 'monthDay' }}
      </ng-container>
    </div>
  </div>

  <ng-template #noFulfillmentTypeText>
    <div class="text flex-row">
      <div class="title">
        {{ 'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SHORT_TITLE' | translate }}
      </div>
      <div class="subtitle">
        {{
          'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SHORT_SUBTITLE' | translate
        }}
      </div>
    </div>
  </ng-template>
</ng-template>
