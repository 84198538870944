import { Injectable } from '@angular/core';
import {
  combineLatest,
  fromEvent,
  merge,
  Observable,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  delayWhen,
  exhaustMap,
  first,
  last,
  map,
  mergeMap,
  pairwise,
  repeat,
  retryWhen,
  skip,
  startWith,
} from 'rxjs/operators';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { ZoneSchedulerService } from '../../../shared/services/zone-scheduler/zone-scheduler.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineModeService {
  private readonly onlineHeartbeatInterval = 10_000; // Time between each /heartbeat call when online
  private readonly offlineHeartbeatInterval = 5_000; // Time between each /heartbeat call when offline
  private readonly heartbeatDelayInterval = 1_500; // Time to delay between /heartbeat retry/repeat attempts
  private readonly heartbeatRetryAttempts = 2; // Number of failed retries required to put user offline
  private readonly heartbeatRepeatAttempts = 3; // Number of success repeats required to put user online

  private readonly isOfflineWindowEvent$: Observable<boolean>;
  private readonly isOfflineWhenInOnlineMode$: Observable<boolean>;
  private readonly isOfflineWhenInOfflineMode$: Observable<boolean>;

  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
    private zoneSchedulerService: ZoneSchedulerService,
  ) {
    this.isOfflineWindowEvent$ = this.createIsOfflineWindowEvent();
    this.isOfflineWhenInOnlineMode$ = this.createIsOfflineWhenInOnlineMode();
    this.isOfflineWhenInOfflineMode$ = this.createIsOfflineWhenInOfflineMode();
  }

  isOffline(isInOfflineMode: boolean): Observable<boolean> {
    return isInOfflineMode
      ? this.isOfflineWhenInOfflineMode$
      : this.isOfflineWhenInOnlineMode$;
  }

  isOfflineManualCheck(): Observable<boolean> {
    return this.heartbeatStatus().pipe(
      map(() => false),
      first(),
      catchError(() => of(true)),
    );
  }

  /**
   * While user is online, we send a heartbeat every `onlineHeartbeatInterval`.
   *
   * On any failure, we retry `heartbeatRetryAttempts` times with a delay of
   * `heartbeatDelayInterval` between each failed call. After 3 consecutive
   * failed calls, the user will move to offline mode.
   *
   * The window offline event is also considered when putting a user into
   * offline mode. If fired, it's considered reliable enough to automatically
   * push them into offline mode.
   *
   * Note: After entering offline mode, the user will begin observing
   * `createIsOfflineWhenInOfflineMode` instead of this one.
   */
  private createIsOfflineWhenInOnlineMode(): Observable<boolean> {
    const checkInterval$ = timer(
      0,
      this.onlineHeartbeatInterval,
      this.zoneSchedulerService.leaveZoneScheduler(),
    ).pipe(startWith(0)); // Kick off /heartbeat call immediately

    return combineLatest([checkInterval$, this.isOfflineWindowEvent$]).pipe(
      pairwise(),
      exhaustMap(([[_p, prevWindowOffline], [_c, currWindowOffline]]) => {
        // If offline event was fired, trust it and put user in offline mode
        if (currWindowOffline && currWindowOffline !== prevWindowOffline) {
          return of(true);
        }

        return this.heartbeatStatus().pipe(
          map(() => false), // Valid heartbeat sets offline to false
          retryWhen((errors) =>
            errors.pipe(
              mergeMap((error, retryIndex) =>
                // Retry 2 more times to get heartbeat with delay between calls
                // Otherwise, set user as offline
                retryIndex < this.heartbeatRetryAttempts
                  ? timer(this.heartbeatDelayInterval)
                  : throwError(error),
              ),
            ),
          ),
          catchError(() => of(true)), // Set offline after 2 failed checks
        );
      }),
      mergeMap((isOffline) => this.zoneSchedulerService.reenterZone(isOffline)),
    );
  }

  /**
   * While user is offline, we send a heartbeat every `offlineHeartbeatInterval`.
   *
   * On any success, we repeat `heartbeatRepeatAttempts` times with a delay of
   * `heartbeatDelayInterval` between each successful call. After 3 consecutive
   * successful calls, the user will move to online mode.
   *
   * The window online event is not considered to be reliable enough to move the
   * user back to online mode, but we will immediately trigger a /heartbeat
   * call based on it.
   *
   * Note: After entering online mode, the user will begin observing
   * `createIsOfflineWhenInOnlineMode` instead of this one.
   */
  private createIsOfflineWhenInOfflineMode(): Observable<boolean> {
    const checkInterval$ = timer(
      0,
      this.offlineHeartbeatInterval,
      this.zoneSchedulerService.leaveZoneScheduler(),
    );

    // The window online event is not reliable enough to trust the user is
    // back online, but it can be used to trigger /heartbeat check
    return combineLatest([checkInterval$, this.isOfflineWindowEvent$]).pipe(
      skip(1), // Interval will immediately fire, skip that first one
      exhaustMap(() => {
        let repeatIndex = 0;

        return this.heartbeatStatus().pipe(
          map(() => false), // Valid heartbeat sets offline to false
          delayWhen(
            (isOffline) =>
              ++repeatIndex < this.heartbeatRepeatAttempts
                ? timer(this.heartbeatDelayInterval).pipe(map(() => isOffline)) // Delay between calls
                : of(isOffline), // No delay after last successful call
          ),
          repeat(this.heartbeatRepeatAttempts), // Need 3 consecutive successes to move back online
          last(), // Use last response
          catchError(() => of(true)), // Offline for any error
        );
      }),
      mergeMap((isOffline) => this.zoneSchedulerService.reenterZone(isOffline)),
    );
  }

  private createIsOfflineWindowEvent(): Observable<boolean> {
    const windowOfflineEvent$ = fromEvent(window, 'offline').pipe(
      map(() => true),
    );
    const windowOnlineEvent$ = fromEvent(window, 'online').pipe(
      map(() => false),
    );

    return merge(windowOfflineEvent$, windowOnlineEvent$).pipe(
      startWith(false), // User starts the application online
    );
  }

  private heartbeatStatus(): Observable<void> {
    return this.httpClient.get(
      `${this.webBffService.getBff()}/heartbeat`,
      undefined,
      { ...silentRequestConfiguration, shouldRetry: false, timeout: 3_000 },
    );
  }
}
