<naoo-material-ordering-info-header
  *ngIf="displayMobileHeader"
></naoo-material-ordering-info-header>
<div
  class="ordering-info-content"
  *ngIf="!!currentOrderingInfo"
  [ngClass]="contentStyleClass"
>
  <ng-container *ngIf="!isComplimentaryProduct">
    <div *ngIf="displayMaxAllocation" class="commodity-available">
      <button (click)="viewCommodityDetailsClicked.emit()">
        {{ materialRowOptions.materialCommodityInfo.maxAllocationDisplay }}
      </button>
    </div>

    <ng-container
      *ngIf="
        !shouldUseInlineUom &&
        !isProductDetails &&
        !isMobile &&
        !currentOrderingInfo.pricingPortion
      "
    >
      <ng-container [ngTemplateOutlet]="loyaltyPoints"></ng-container>
      <div class="size info-section non-drop-down">
        {{ { uomCode: currentOrderingInfo.displayCode } | materialunits }}
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !shouldUseInlineUom && !isMobile && !!currentOrderingInfo.pricingPortion
      "
    >
      <ng-container [ngTemplateOutlet]="loyaltyPoints"></ng-container>
      <div [ngClass]="{ 'info-price-section-grid': isPortionPricingGrid }">
        <div
          class="info-section non-drop-down expanded-size"
          [ngClass]="isPortionPricingGrid ? 'info-section-grid' : 'size'"
        >
          <ng-container *ngIf="!isProductDetails">
            {{ { uomCode: currentOrderingInfo.displayCode } | materialunits }}
          </ng-container>
          <ng-container *ngIf="isPortionPricingGrid">
            {{
              currentOrderingInfo.price
                | naooprice: currentOrderingInfo.catchWeightUom
            }}
          </ng-container>
        </div>
        <div class="portion-pricing-grid" *ngIf="isPortionPricingGrid">
          {{
            currentOrderingInfo.pricingPortion
              | naooprice: currentOrderingInfo.pricingPortionUom
          }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="shouldUseInlineUom">
      <div class="naoo-material-uom-select uom-button-container size">
        <mat-form-field class="uom-select-field">
          <mat-select
            [value]="materialOrderingTabs[selectedIndex].uom"
            (selectionChange)="changeTab($event)"
            [ngClass]="{ 'highlight-quantity': hasHiddenQuantity }"
          >
            <mat-select-trigger class="uom-select-trigger">
              {{
                { uomCode: materialOrderingTabs[selectedIndex].displayCode }
                  | materialunits
              }}
            </mat-select-trigger>
            <mat-option
              class="naoo-material-uom-option"
              *ngFor="let tab of materialOrderingTabs; index as index"
              [value]="tab.uom"
            >
              {{ { uomCode: tab.displayCode } | materialunits }}
              <ng-container *ngIf="index !== selectedIndex && tab.quantity > 0">
                ({{ tab.quantity }})
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container
      *ngIf="!isPortionPricingGrid || !currentOrderingInfo.pricingPortion"
    >
      <div class="price-container" [ngClass]="{ mobile: isMobile }">
        <div
          *ngIf="!shouldHidePortionPrice"
          class="portion-pricing info-section"
        >
          <ng-container *ngIf="!!currentOrderingInfo.pricingPortion">
            {{
              currentOrderingInfo.pricingPortion
                | naooprice: currentOrderingInfo.pricingPortionUom
            }}
          </ng-container>
        </div>
        <div class="price info-section" [ngClass]="{ mobile: isMobile }">
          {{
            currentOrderingInfo.price
              | naooprice: currentOrderingInfo.catchWeightUom
          }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="quantity-container">
    <div
      class="par-quantity"
      *ngIf="materialRowOptions.isParEnabled"
      [ngClass]="{ mobile: isMobile }"
    >
      <naoo-material-par-quantity-input
        *ngIf="!!currentOrderingInfo.parLine"
        [currentOrderingInfo]="currentOrderingInfo"
        [materialRowOptions]="materialRowOptions"
        [isMobile]="isMobile"
      ></naoo-material-par-quantity-input>
    </div>

    <ng-container *ngIf="!isNonEditableItem; else nonEditableQuantity">
      <naoo-material-quantity-input
        [orderingInfos]="orderingInfos"
        [currentOrderingInfo]="currentOrderingInfo"
        [materialRowOptions]="materialRowOptions"
        [materialWarning]="materialWarning"
        [isMobile]="isMobile"
        (quantityInputChanged)="handleQuantityInputChanged($event)"
      >
      </naoo-material-quantity-input>
    </ng-container>
  </div>

  <div
    *ngIf="!isComplimentaryProduct"
    class="total info-section"
    [ngClass]="{ mobile: isMobile }"
  >
    {{ totalEstimatedCost | naooprice }}
  </div>
</div>

<ng-template #nonEditableQuantity>
  <span class="non-editable-quantity-content">
    {{
      !!currentOrderingInfo.line.quantity
        ? currentOrderingInfo.line.quantity
        : ''
    }}
  </span>
</ng-template>

<ng-template #loyaltyPoints>
  <span *ngIf="shouldDisplayLoyaltyPoints" class="points info-section">
    {{ currentOrderingInfo.loyaltyPoints | naooloyalty }}
  </span>
</ng-template>
