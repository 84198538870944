import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OpenOrderItemsState } from './open-order-items.state';

const selectOpenOrderItemsState =
  createFeatureSelector<OpenOrderItemsState>('openOrderItems');

export const selectOpenOrderItems = createSelector(
  selectOpenOrderItemsState,
  (state) => state.openOrderItems,
);
