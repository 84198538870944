import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { materialWarningReducer } from './material-warning.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('materialWarning', materialWarningReducer),
  ],
})
export class MaterialWarningStoreModule {}
