import {
  ActiveCartSummaryState,
  CartSortType,
  initialActiveCartState,
} from './active-cart-summary.state';
import { createReducer, on } from '@ngrx/store';
import { ActiveCartSummaryActions } from './active-cart-summary.actions';

export const activeCartSummaryReducer = createReducer(
  initialActiveCartState,
  on(
    ActiveCartSummaryActions.updateQuickAddQuantities,
    (state): ActiveCartSummaryState => state,
  ),
  on(
    ActiveCartSummaryActions.enableQuickAdd,
    (state): ActiveCartSummaryState => ({
      ...state,
      quickAddEnabled: true,
    }),
  ),
  on(
    ActiveCartSummaryActions.disableQuickAdd,
    (state): ActiveCartSummaryState => ({
      ...state,
      quickAddEnabled: false,
    }),
  ),
  on(
    ActiveCartSummaryActions.updateCartSort,
    (state, { sortBy }): ActiveCartSummaryState => ({
      ...state,
      sortBy,
    }),
  ),
  on(
    ActiveCartSummaryActions.refreshCartSort,
    (state): ActiveCartSummaryState => ({
      ...state,
      sortBy: CartSortType.AsAdded,
    }),
  ),
);
