import { NgModule } from '@angular/core';
import { MaterialListHeaderComponent } from './material-list-header/material-list-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialListComponent } from './material-list.component';
import { MaterialRowContainerComponent } from './material-row-container/material-row-container.component';
import { MaterialRowListContentComponent } from './material-row-container/material-row-list-content/material-row-list-content.component';
import { CommonModule } from '@angular/common';
import { MaterialImageComponent } from './material-row-container/shared/material-image/material-image.component';
import { MaterialInfoComponent } from './material-row-container/shared/material-info/material-info.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MaterialFlagComponent } from './material-row-container/shared/material-flag/material-flag.component';
import { MaterialFlagContainerComponent } from './material-row-container/shared/material-flag-container/material-flag-container.component';
import { MaterialOrderingTabComponent } from './material-row-container/shared/material-ordering/material-ordering-tab/material-ordering-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MaterialOrderingComponent } from './material-row-container/shared/material-ordering/material-ordering.component';
import { MaterialOrderingInfoComponent } from './material-row-container/shared/material-ordering/material-ordering-info/material-ordering-info.component';
import { MaterialQuantityInputComponent } from './material-row-container/shared/material-ordering/material-ordering-info/material-quantity-input/material-quantity-input.component';
import { MaterialOptionsMenuComponent } from './material-row-container/shared/material-options-menu/material-options-menu.component';
import { MaterialSkeletonComponent } from './material-row-container/shared/material-skeleton/material-skeleton.component';
import { MaterialUnorderableComponent } from './material-row-container/shared/material-unorderable/material-unorderable.component';
import { MaterialTrashButtonComponent } from './material-row-container/shared/material-trash-button/material-trash-button.component';
import { MaterialUnavailableComponent } from './material-row-container/shared/material-unavailable/material-unavailable.component';
import { MaterialDeletedComponent } from './material-row-container/shared/material-deleted/material-deleted.component';
import { MaterialWarningComponent } from './material-row-container/shared/material-ordering/material-warning/material-warning.component';
import { MaterialRowFooterComponent } from './material-row-container/shared/material-row-footer/material-row-footer.component';
import { MaterialCompareCheckboxComponent } from './material-row-container/shared/material-row-footer/material-compare-checkbox/material-compare-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MaterialRowSlimContentComponent } from './material-row-container/material-row-slim-content/material-row-slim-content.component';
import { MaterialRowGridContentComponent } from './material-row-container/material-row-grid-content/material-row-grid-content.component';
import { MaterialCategoryHeaderComponent } from './material-category-header/material-category-header.component';
import { MaterialCategoryJumpButtonsComponent } from './material-category-header/material-category-jump-buttons/material-category-jump-buttons.component';
import { MaterialParQuantityInputComponent } from './material-row-container/shared/material-ordering/material-ordering-info/material-par-quantity-input/material-par-quantity-input.component';
import { MaterialOrderingInfoHeaderComponent } from './material-row-container/shared/material-ordering/material-ordering-info/material-ordering-info-header/material-ordering-info-header.component';
import { MaterialRecommendationComponent } from './material-recommendation/material-recommendation.component';
import { MaterialCarouselComponent } from './material-recommendation/material-carousel/material-carousel.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialRowSlimGridContentComponent } from './material-row-container/material-row-slim-grid-content/material-row-slim-grid-content.component';
import { MaterialStoreStockComponent } from './material-row-container/shared/material-store-stock/material-store-stock.component';
import { NaooIconService } from '../shared/services/images/naoo-icon.service';
import { NaooConstants } from '../shared/NaooConstants';
import { MaterialSimilarItemsComponent } from './material-row-container/shared/material-similar-items/material-similar-items.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    MaterialCompareCheckboxComponent,
    MaterialDeletedComponent,
    MaterialFlagComponent,
    MaterialFlagContainerComponent,
    MaterialImageComponent,
    MaterialInfoComponent,
    MaterialListComponent,
    MaterialListHeaderComponent,
    MaterialOptionsMenuComponent,
    MaterialOptionsMenuComponent,
    MaterialOrderingComponent,
    MaterialOrderingInfoComponent,
    MaterialOrderingInfoComponent,
    MaterialOrderingInfoHeaderComponent,
    MaterialOrderingTabComponent,
    MaterialQuantityInputComponent,
    MaterialQuantityInputComponent,
    MaterialRowContainerComponent,
    MaterialRowFooterComponent,
    MaterialRowListContentComponent,
    MaterialSkeletonComponent,
    MaterialTrashButtonComponent,
    MaterialUnavailableComponent,
    MaterialUnorderableComponent,
    MaterialWarningComponent,
    MaterialRowSlimContentComponent,
    MaterialRowGridContentComponent,
    MaterialRowSlimGridContentComponent,
    MaterialCategoryHeaderComponent,
    MaterialCategoryJumpButtonsComponent,
    MaterialParQuantityInputComponent,
    MaterialRecommendationComponent,
    MaterialCarouselComponent,
    MaterialStoreStockComponent,
    MaterialSimilarItemsComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
  ],
  exports: [
    MaterialListHeaderComponent,
    MaterialListComponent,
    MaterialRowContainerComponent,
    MaterialRecommendationComponent,
    MaterialOrderingComponent,
    MaterialFlagComponent,
    MaterialInfoComponent,
    MaterialFlagContainerComponent,
    MaterialCarouselComponent,
    MaterialStoreStockComponent,
    MaterialImageComponent,
    MaterialOptionsMenuComponent,
    MaterialSimilarItemsComponent,
    MaterialRowListContentComponent,
    MaterialUnavailableComponent,
    MaterialOrderingInfoComponent,
    MaterialWarningComponent,
  ],
})
export class MaterialListModule {
  readonly materialListIcons = [NaooConstants.icons.ispu_in_stock_icon];

  constructor(private naooIconService: NaooIconService) {
    this.naooIconService.registerIcons(this.materialListIcons);
  }
}
