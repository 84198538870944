import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AvailableRouteDate,
  DeliveryDatePicker,
} from '../shared/models/delivery-date-picker';
import { DeliveryDatePickerDetailsComponent } from './delivery-date-picker-details/delivery-date-picker-details.component';

@Component({
  selector: 'naoo-delivery-date-picker-content',
  templateUrl: './delivery-date-picker-content.component.html',
  styleUrls: ['./delivery-date-picker-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDatePickerContentComponent {
  @Input() deliveryDatePicker: DeliveryDatePicker;
  @Output() routeDateSelected = new EventEmitter<void>();
  @Output() saveRouteDate = new EventEmitter<AvailableRouteDate>();
  @Output() justBrowsing = new EventEmitter<void>();
  @Output() closePicker = new EventEmitter<void>();
  @ViewChild('mobileSaveButton')
  mobileSaveButton: ElementRef<HTMLButtonElement>;
  @ViewChild(DeliveryDatePickerDetailsComponent)
  deliveryDatePickerDetails: DeliveryDatePickerDetailsComponent;

  selectedAvailableRouteDate?: AvailableRouteDate;

  handleRouteDateSelected(availableRouteDate: AvailableRouteDate) {
    this.routeDateSelected.emit();
    this.selectedAvailableRouteDate = availableRouteDate;
  }

  handleSaveRouteDate(routeDate: Date) {
    const availableRouteDate =
      this.deliveryDatePicker.content.availableRouteDates.find(
        (availableDate) =>
          availableDate.routeDate.getTime() === routeDate.getTime(),
      );
    this.saveRouteDate.emit(availableRouteDate);
  }

  handleDatePickerShiftFocus() {
    this.focusMobileSaveButton();
    this.focusDetailsDesktopSaveButton();
    return false;
  }

  focusMobileSaveButton() {
    this.mobileSaveButton.nativeElement.focus();
  }

  focusDetailsDesktopSaveButton() {
    this.deliveryDatePickerDetails.focusDesktopSaveButton();
  }

  handleJustBrowsing() {
    this.justBrowsing.emit();
  }

  handleClosePicker() {
    this.closePicker.emit();
  }

  get isDoneLoading(): boolean {
    return (
      !!this.deliveryDatePicker &&
      !!this.deliveryDatePicker.content &&
      !this.deliveryDatePicker.isLoading
    );
  }

  get isCloseable(): boolean {
    return !this.deliveryDatePicker?.content?.isRouteDateExpired;
  }

  get isJustBrowsing(): boolean {
    return (
      this.deliveryDatePicker.content.isFirstDisplay &&
      !this.deliveryDatePicker.content.isRouteDateExpired
    );
  }

  get calculatedAvailableRouteDate(): AvailableRouteDate {
    return (
      this.selectedAvailableRouteDate ||
      this.deliveryDatePicker.content.defaultRouteDate
    );
  }
}
