import { createActionGroup } from '@ngrx/store';

export const ErrorActions = createActionGroup({
  source: 'Error',
  events: {
    'Fatal Error': (error: unknown, customAnalytic?: string) => ({
      error,
      customAnalytic,
    }),
    'Silent Error': (payload: unknown) => ({ payload }),
  },
});
