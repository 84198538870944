import { StoreRecord } from '../../services/store/model/store-record';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const StoreActions = createActionGroup({
  source: 'Store',
  events: {
    'Load Stores': emptyProps(),
    'Get Stores': emptyProps(),
    'Get Stores Success': (stores: StoreRecord[]) => ({ stores }),
    'Get Stores Failure': emptyProps(),
    'Refresh stores': emptyProps(),
  },
});
