<naoo-modal-header
  [title]="'COPY_GUIDE.MODAL_TITLE' | translate: { guideName: copyGuideName }"
  (closeModalEmitter)="closeModal()"
  [showCloseButton]="currentStep !== copyGuideStep.CopyInProgress"
></naoo-modal-header>
<div class="content-container" [ngSwitch]="currentStep">
  <naoo-copy-guide-select-customer
    *ngSwitchCase="copyGuideStep.SelectCustomer"
    (cancel)="closeModal()"
    (beginCopy)="copyToCustomerUnits($event)"
  >
  </naoo-copy-guide-select-customer>

  <naoo-copy-guide-copy-action
    *ngSwitchCase="copyGuideStep.CopyInProgress"
    (copySuccess)="copySucceeded()"
    (copyFailure)="copyFailed()"
    [nameOfGuideToCopy]="copyGuideName"
    [customGuideId]="copyGuideId"
    [customersToCopyTo]="selectedCustomerCompositeIds"
  >
  </naoo-copy-guide-copy-action>

  <naoo-complete-modal
    *ngSwitchCase="copyGuideStep.Fail"
    (navigateToResults)="closeModal()"
    [successIconPath]="failureImage"
    [imageAltText]="'COPY_GUIDE_FAILURE.IMAGE_ALT' | translate"
    [title]="
      'COPY_GUIDE_FAILURE.TITLE' | translate: { guideName: copyGuideName }
    "
    [completeButtonMessage]="'COPY_GUIDE_FAILURE.BUTTON' | translate"
  >
  </naoo-complete-modal>

  <naoo-complete-modal
    *ngSwitchCase="copyGuideStep.Success"
    (navigateToResults)="closeModal()"
    [successIconPath]="successImage"
    [imageAltText]="'COPY_GUIDE_SUCCESS.IMAGE_ALT' | translate"
    [title]="
      'COPY_GUIDE_SUCCESS.TITLE' | translate: { guideName: copyGuideName }
    "
    [subtitle]="
      subtitle | translate: { count: selectedCustomerCompositeIds?.length }
    "
    [completeButtonMessage]="'COPY_GUIDE_SUCCESS.BUTTON' | translate"
  >
  </naoo-complete-modal>
</div>
