import { MaterialFlagType } from '../../../material-flag/material-flag';

export type MaterialIndicatorFlags = Record<string, MaterialFlagType[]>;

export interface IndicatorListsState {
  indicatorLists: MaterialIndicatorFlags;
  hasLoaded: boolean;
}

export const indicatorListsInitialState: IndicatorListsState = {
  indicatorLists: {},
  hasLoaded: false,
};
