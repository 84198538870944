import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary, EntityState } from '@ngrx/entity';
import {
  CartCounts,
  selectAllCartCouponCodes,
  selectAllOrderableCartMaterials,
  selectCartCounts,
  selectCartMaterialEntities,
  selectCartMaterialNumbers,
  selectDropShipOrders,
  selectIsCartLoaded,
  selectTotalQuantity,
} from '../cart/cart.selectors';
import { CartMaterialState } from '../cart/cart.state';
import {
  CommodityPricingData,
  selectAllCommodityPricingDataRecords,
} from '../commodity-price/commodity-price.selectors';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import {
  CartCoupon,
  CartCoupons,
} from '../../../cart/cart-coupon/shared/cart-coupon';
import { NaooConstants } from '../../../shared/NaooConstants';
import { selectCurrentUrl } from '../router/router.selectors';
import {
  CombinedPricingRecord,
  getCombinedPricingRecord,
  hasCombinedPricingSourcesFinishedLoaded,
} from './material-price.util';
import {
  materialPriceRecordEntityAdapter,
  MaterialPriceRecordState,
  MaterialPriceState,
} from './material-price.state';
import { MaterialQuantityDetail, MaterialQuantityLine } from '../shared/shared';

export interface CartPriceTotals {
  estimatedCost: number;
  estimatedSavings: number;
  hasAllPricesLoaded: boolean;
  cartCounts: CartCounts;
  cartPriceMap: Map<string, number>;
  cartLoyaltyPoints: number;
  currency: string;
  shippingCost: number;
}

const selectMaterialPriceFeature =
  createFeatureSelector<MaterialPriceState>('materialPrice');

const selectPrices = createSelector(
  selectMaterialPriceFeature,
  (state: MaterialPriceState) => state.prices,
);

export const selectIsCouponPriceDisplayRoute = createSelector(
  selectCurrentUrl,
  (url) => {
    return (
      !!url &&
      (url.startsWith(NaooConstants.CART_REVIEW_URL) ||
        url.startsWith(NaooConstants.ORDER_CONFIRMATION_URL))
    );
  },
);

export const selectWatchedIds = createSelector(
  selectMaterialPriceFeature,
  (state: MaterialPriceState) => {
    return state.watchedMaterialNumbers;
  },
);

export const selectCurrency = createSelector(
  selectMaterialPriceFeature,
  (state: MaterialPriceState) => state.currency,
);

export const selectAllMaterialPriceRecordIds = createSelector(
  selectPrices,
  materialPriceRecordEntityAdapter.getSelectors().selectIds,
);

export const selectAllMaterialPriceRecordStates = createSelector(
  selectPrices,
  materialPriceRecordEntityAdapter.getSelectors().selectEntities,
);

export const selectAllMaterialPriceRecordStatesArray = createSelector(
  selectPrices,
  materialPriceRecordEntityAdapter.getSelectors().selectAll,
);

export const selectMaterialPriceRecordState = (materialNumber: string) =>
  createSelector(
    selectAllMaterialPriceRecordStates,
    (materialPriceRecordStates): MaterialPriceRecordState | undefined =>
      materialPriceRecordStates[materialNumber],
  );

export const selectAllCombinedPricingRecords = (
  isAnalyticPricing = false,
  materialNumbers?: string[],
) =>
  createSelector(
    selectPrices,
    selectAllMaterialInfoRecordStates,
    selectAllCommodityPricingDataRecords,
    selectHasPermissionEnabled(CustomerPermission.CommodityAccess),
    selectIsCouponPriceDisplayRoute,
    selectCartMaterialEntities,
    selectCurrency,
    (
      materialPriceRecordState: EntityState<MaterialPriceRecordState>,
      materialInfoRecords: Dictionary<MaterialInfoRecordState>,
      commodityPricingDataRecords: Dictionary<CommodityPricingData>,
      hasCommodityAccess: boolean,
      isCouponPriceDisplayRoute: boolean,
      cartMaterialEntities: Dictionary<CartMaterialState>,
      currency: string,
    ): Dictionary<CombinedPricingRecord> => {
      const displayCoupon = isAnalyticPricing || isCouponPriceDisplayRoute;
      const dictionary: Dictionary<CombinedPricingRecord> = {};
      (materialNumbers || materialPriceRecordState.ids).forEach(
        (materialNumber: string | number) => {
          const materialPriceRecord =
            materialPriceRecordState.entities[materialNumber];
          const cartMaterialState = cartMaterialEntities[materialNumber];
          const hasLoaded = hasCombinedPricingSourcesFinishedLoaded(
            commodityPricingDataRecords[materialNumber],
            materialPriceRecord,
            materialInfoRecords[materialNumber],
            hasCommodityAccess,
          );
          dictionary[materialNumber] = getCombinedPricingRecord(
            String(materialNumber),
            materialInfoRecords[materialNumber],
            commodityPricingDataRecords[materialNumber],
            materialPriceRecord,
            hasLoaded,
            displayCoupon,
            cartMaterialState,
            currency,
          );
        },
      );
      return dictionary;
    },
  );

export const selectCombinedPricingRecords = (
  materialNumbers: string[],
  isAnalyticPricing?: boolean,
) => {
  return createSelector(
    selectAllCombinedPricingRecords(isAnalyticPricing, materialNumbers),
    (
      recordStates: Dictionary<CombinedPricingRecord>,
    ): Dictionary<CombinedPricingRecord> => {
      const dictionary: Dictionary<CombinedPricingRecord> = {};
      materialNumbers?.forEach(
        (materialNumber) =>
          (dictionary[materialNumber] = recordStates[materialNumber]),
      );
      return dictionary;
    },
  );
};

export const selectCombinedPricingRecord = (
  materialNumber: string,
  isAnalyticPricing?: boolean,
) => {
  return createSelector(
    selectAllCombinedPricingRecords(isAnalyticPricing, [materialNumber]),
    (recordStates: Dictionary<CombinedPricingRecord>): CombinedPricingRecord =>
      recordStates[materialNumber],
  );
};

export const selectAllCombinedPrices = (materialNumbers: string[]) =>
  createSelector(
    selectPrices,
    selectAllCombinedPricingRecords(false, materialNumbers),
    (
      materialPriceRecordState: EntityState<MaterialPriceRecordState>,
      loadedCombinedPrices: Dictionary<CombinedPricingRecord>,
    ) => {
      const allPricesLoaded = materialNumbers.every(
        (material) => loadedCombinedPrices[material]?.hasLoaded,
      );

      return allPricesLoaded
        ? materialNumbers.map((material) => loadedCombinedPrices[material])
        : undefined;
    },
  );

export const selectCombinedPrices = (materialNumbers: string[]) =>
  createSelector(
    selectCombinedPricingRecords(materialNumbers),
    (records: Dictionary<CombinedPricingRecord>) =>
      materialNumbers.map((material) => records[material]),
  );

export const selectShippingCostOfSplitOrders = createSelector(
  selectDropShipOrders,
  (dropShipOrders): number =>
    dropShipOrders
      ?.map((data) => data.carrierFulfillment.shippingPrice)
      .filter((price) => price > 0)
      .reduce((a, b) => a + b, 0) ?? 0,
);

export const selectCartPriceTotals = (isAnalyticPricing?: boolean) =>
  createSelector(
    selectAllOrderableCartMaterials,
    selectAllCombinedPricingRecords(isAnalyticPricing),
    selectCartCounts,
    selectCurrency,
    selectShippingCostOfSplitOrders,
    (
      cartMaterials: CartMaterialState[],
      combinedPrices: Dictionary<CombinedPricingRecord>,
      cartCounts: CartCounts,
      currency: string,
      shippingCost: number,
    ): CartPriceTotals => {
      let estimatedCost = 0;
      let estimatedSavings = 0;
      let cartLoyaltyPoints = 0;
      const cartPriceMap: Map<string, number> = new Map<string, number>();

      cartMaterials
        .filter(
          (material) => !!combinedPrices[material.materialNumber]?.hasLoaded,
        )
        .forEach((material) => {
          const combinedPricingRecord = combinedPrices[material.materialNumber];
          combinedPricingRecord.unitPrices
            .filter((price) => !!material.lines.entities[price.salesUom])
            .forEach((price) => {
              const line = material.lines.entities[price.salesUom];
              const linePrice = line.quantity * price.price;
              const loyaltyPoints = price.loyaltyPoints
                ? line.quantity * price.loyaltyPoints
                : 0;
              cartLoyaltyPoints += loyaltyPoints;
              estimatedCost += linePrice;
              estimatedSavings += price.discountAmount
                ? line.quantity * price.discountAmount
                : 0;
              const priceInCartMap = cartPriceMap.get(material.materialNumber);
              cartPriceMap.set(
                material.materialNumber,
                priceInCartMap ? priceInCartMap + linePrice : linePrice,
              );
            });
        });

      const allPricesLoaded = cartMaterials.every(
        (material) => combinedPrices[material.materialNumber]?.hasLoaded,
      );

      return {
        estimatedCost,
        estimatedSavings,
        hasAllPricesLoaded: allPricesLoaded,
        cartCounts,
        cartPriceMap,
        cartLoyaltyPoints,
        currency,
        shippingCost,
      };
    },
  );

export const selectCartSectionTotal = (materialNumbers: string[]) =>
  createSelector(selectCartPriceTotals(), (cartPriceTotals: CartPriceTotals) =>
    materialNumbers.reduce(
      (sum, materialNumber) =>
        sum + (cartPriceTotals.cartPriceMap.get(materialNumber) || 0),
      0,
    ),
  );

export const selectCartCouponsFromPrice = createSelector(
  selectAllMaterialPriceRecordStatesArray,
  selectCartMaterialNumbers,
  selectAllCartCouponCodes,
  selectIsOffline,
  selectIsCartLoaded,
  selectTotalQuantity,
  (
    materialPriceRecordStates,
    cartMaterialNumbers,
    cartCouponCodes,
    isOffline,
    isCartLoaded,
    cartTotalQuantity,
  ): CartCoupons => {
    const isEmptyCart = isCartLoaded && cartTotalQuantity === 0;
    return {
      cartCoupons: cartCouponCodes.map((couponCode) =>
        createCartCoupon(
          materialPriceRecordStates,
          cartMaterialNumbers,
          couponCode,
          isEmptyCart,
        ),
      ),
      isMaxCoupons: cartCouponCodes.length >= NaooConstants.maximumCartCoupons,
      isOffline,
    };
  },
);

export const selectTotalFromMaterialDetails = (
  materialDetails: MaterialQuantityDetail[],
  isAnalyticPricing: boolean,
) => {
  const materials = materialDetails.map((detail) => detail.materialNumber);
  return createSelector(
    selectCombinedPricingRecords(materials, isAnalyticPricing),
    (priceRecords: Dictionary<CombinedPricingRecord>): number =>
      +materialDetails
        .reduce(
          (totalCost, detail) =>
            totalCost +
            detail.lines.reduce(
              (linesCost, line) =>
                linesCost +
                calculateLineCost(line, priceRecords[detail.materialNumber]),
              0,
            ),
          0,
        )
        .toFixed(2),
  );
};

function calculateLineCost(
  line: MaterialQuantityLine,
  materialCost: CombinedPricingRecord,
): number {
  const uomPrice = materialCost?.unitPrices?.find(
    (unitPrice) => unitPrice.salesUom === line.uom,
  );
  if (uomPrice?.price) {
    const cost = uomPrice.price - uomPrice.discountAmount;
    return cost * line.quantity;
  }
  return 0;
}

function createCartCoupon(
  materialPriceRecordStates: MaterialPriceRecordState[],
  cartMaterialNumbers: string[],
  couponCode: string,
  isEmptyCart: boolean,
): CartCoupon {
  const couponExists = materialPriceRecordStates
    .filter((i) => cartMaterialNumbers?.includes(i.materialNumber))
    .some((i) => i?.record?.unitPrices?.some((j) => j?.coupon === couponCode));

  return {
    couponCode,
    isInvalid: isEmptyCart || !couponExists,
  };
}
