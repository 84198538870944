import {
  AllocationBackgroundColor,
  MaterialCommodityInfo,
  MaterialCommodityShieldColor,
  MaterialEntitlementMapping,
  MaterialRowContext,
  MaterialRowStatus,
} from '../models/material-row';
import { MaterialFlagType } from '../../../../material-flag/material-flag';
import {
  MaterialRowContextTransformInput,
  MaterialRowIsDeletedTransformInput,
} from './material-row.transformer.model';
import { Injectable } from '@angular/core';
import { EntitlementMaterialDetail } from '../../../services/entitlement/models/entitlement';
import { OverallocatedMaterialDetail } from '../../entitlement/entitlement.state';
import { VendorInfoRecord } from '../../../services/material-additional-info/models/material-additional-infos-record';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../../material-info/material-info.state';
import {
  getMaterialCommodityShieldColor,
  isEntitlementMaterialDetailUnorderable,
} from '../../entitlement/entitlement.util';
import {
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
} from '../../material-availability/material-availability.state';

@Injectable()
export class MaterialRowTransformer {
  static transformContext({
    context,
    flags,
  }: MaterialRowContextTransformInput): MaterialRowContext {
    const hasDropShip = flags.some(
      (flag) => MaterialFlagType.DropShip === flag.type,
    );
    if (MaterialRowContext.CriticalItem === context && hasDropShip) {
      return MaterialRowContext.PdpLinkItem;
    }
    return context;
  }

  static transformIsDeleted({
    cartMaterial,
    focusedMaterial,
    materialNumber,
  }: MaterialRowIsDeletedTransformInput): boolean {
    return focusedMaterial?.materialNumber === materialNumber
      ? false
      : !!cartMaterial?.isDeleted;
  }

  static transformMaterialCommodityInfo(
    entitlementMaterialDetail: EntitlementMaterialDetail,
    overallocatedMaterialDetail: OverallocatedMaterialDetail,
    vendorInfoRecord: VendorInfoRecord,
    info?: MaterialInfoRecordState,
  ): MaterialCommodityInfo | undefined {
    if (!entitlementMaterialDetail && !overallocatedMaterialDetail) {
      return undefined;
    }

    if (!entitlementMaterialDetail) {
      const maxAllocationDisplay =
        MaterialRowTransformer.getMaxAllocationDisplay(
          overallocatedMaterialDetail?.maxCommodityCasesAmount,
        );
      return {
        displayMaxAllocation: true,
        maxAllocationDisplay,
        maxAllocationDisplayKey:
          MaterialRowTransformer.getMaxAllocationDisplayKey(
            maxAllocationDisplay,
          ),
      };
    }
    const isCatchWeight: boolean = info?.record?.isCatchWeight;

    const viewMappings: MaterialEntitlementMapping[] =
      entitlementMaterialDetail.mappings
        .map((mapping) => {
          const allocationBackgroundColor: AllocationBackgroundColor =
            mapping.availableAllocationBalanceAmount >= mapping.drawdownAmount
              ? AllocationBackgroundColor.GREEN
              : AllocationBackgroundColor.GRAY;

          return {
            allocationUom: mapping.allocationAccountUom?.toLowerCase(),
            allocationAvailable: mapping.availableAllocationBalanceAmount,
            allocationTotal: mapping.beginningAllocationBalanceAmount,
            allocationBackgroundColor,
            remainingAllocationPercentage:
              MaterialRowTransformer.toPercentageString(
                mapping.availableAllocationBalanceAmount,
                mapping.beginningAllocationBalanceAmount,
              ),
            drawdownFactor: mapping.drawdownAmount,
            drawdownFactorUom: mapping.drawdownUom?.toLowerCase(),
            passThroughValue: mapping.priceValuePassThroughAmount,
            passThroughValueUom: isCatchWeight ? 'lbs' : 'case',
            usdaMaterialId: mapping.usdaMaterialId,
            usdaMaterialDescription: mapping.usdaMaterialDescription,
            fullDescription: mapping.fullDescription,
          };
        })
        .sort(
          (a, b): number =>
            b.allocationAvailable / b.allocationTotal -
            a.allocationAvailable / a.allocationTotal,
        );

    const shieldColor: MaterialCommodityShieldColor =
      getMaterialCommodityShieldColor(entitlementMaterialDetail);

    const displayMaxAllocation: boolean =
      MaterialCommodityShieldColor.GREEN === shieldColor &&
      !!entitlementMaterialDetail.maxCommodityCasesAmount;

    const maxCommodityCasesAmount = overallocatedMaterialDetail
      ? overallocatedMaterialDetail.maxCommodityCasesAmount
      : entitlementMaterialDetail.maxCommodityCasesAmount;

    const maxAllocationDisplay = MaterialRowTransformer.getMaxAllocationDisplay(
      maxCommodityCasesAmount,
    );
    return {
      shieldColor,
      displayMaxAllocation,
      maxAllocationDisplay,
      maxAllocationDisplayKey:
        MaterialRowTransformer.getMaxAllocationDisplayKey(maxAllocationDisplay),
      passThroughMethod: entitlementMaterialDetail.vendorVptMethodName,
      vendorInfo: vendorInfoRecord,
      mappings: viewMappings,
      isUnorderable: isEntitlementMaterialDetailUnorderable(
        entitlementMaterialDetail,
      ),
    };
  }

  static transformMaterialRowStatus(
    info: MaterialInfoRecordState | undefined,
    availability: MaterialAvailabilityRecordState,
    isCommodityUnorderable?: boolean,
  ): MaterialRowStatus {
    if (
      !info ||
      [
        MaterialInfoRecordStatus.Queued,
        MaterialInfoRecordStatus.Requested,
      ].includes(info.status)
    ) {
      return MaterialRowStatus.Loading;
    }

    if (
      [
        MaterialInfoRecordStatus.Unavailable,
        MaterialInfoRecordStatus.Error,
      ].includes(info.status)
    ) {
      return MaterialRowStatus.Unavailable;
    }

    if (
      !availability ||
      [
        MaterialAvailabilityRecordStatus.Queued,
        MaterialAvailabilityRecordStatus.Requested,
      ].includes(availability.status)
    ) {
      return MaterialRowStatus.Loading;
    }

    if (
      MaterialAvailabilityRecordStatus.Error === availability.status ||
      !availability?.record?.isOrderable ||
      isCommodityUnorderable
    ) {
      return MaterialRowStatus.Unorderable;
    }

    return MaterialRowStatus.Ok;
  }

  private static getMaxAllocationDisplay(caseAmount: number) {
    return caseAmount > 999 ? '>999' : caseAmount?.toString();
  }

  private static getMaxAllocationDisplayKey(maxAllocationDisplay: string) {
    return '1' === maxAllocationDisplay
      ? 'MATERIAL_ROW.ALLOCATION'
      : 'MATERIAL_ROW.ALLOCATIONS';
  }

  private static toPercentageString(portion: number, total: number): string {
    if (total === 0 || portion === 0) {
      return '0';
    }
    const percentage = (portion / total) * 100;
    return percentage > 0.0 && percentage < 1.0 ? '<1' : percentage.toFixed(1);
  }
}
