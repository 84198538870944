import {
  ecommerceAnalyticsMaterialAdapter,
  EcommerceAnalyticsMaterialState,
  EcommerceAnalyticsState,
  initialEcommerceAnalyticsState,
  MaterialSponsoredSearchState,
} from './ecommerce-analytics.state';
import { EcommerceAnalyticsActions } from './ecommerce-analytics.actions';
import { createReducer, on } from '@ngrx/store';
import { MaterialRecommendationsRecord } from '../../../shared/services/material-recommendations/models/material-recommendations-record';

export const ecommerceAnalyticsReducer = createReducer(
  initialEcommerceAnalyticsState,
  on(
    EcommerceAnalyticsActions.updateMaterialRecommendations,
    (state, action): EcommerceAnalyticsState =>
      updateMaterialRecommendations(state, action),
  ),
  on(
    EcommerceAnalyticsActions.clearMaterialRecommendations,
    (state): EcommerceAnalyticsState => ({
      ...state,
      materials: ecommerceAnalyticsMaterialAdapter.getInitialState(),
    }),
  ),
  on(
    EcommerceAnalyticsActions.updateSponsoredData,
    (state, action): EcommerceAnalyticsState =>
      updateSponsoredMaterialState(state, action),
  ),
  on(
    EcommerceAnalyticsActions.updateBehavioralAdvertising,
    (state, action): EcommerceAnalyticsState => {
      return {
        ...state,
        behavioralAdvertising: action.behavioralAdvertising,
      };
    },
  ),
);

function updateMaterialRecommendations(
  state: EcommerceAnalyticsState,
  action: {
    materialRecommendations: MaterialRecommendationsRecord[];
  },
): EcommerceAnalyticsState {
  const materialsState: EcommerceAnalyticsMaterialState[] = [];

  action.materialRecommendations?.forEach((materialRecommendation) => {
    materialRecommendation.items.forEach((item, index) => {
      materialsState.push({
        materialNumber: item.sku_id,
        recommendation: {
          name: materialRecommendation.name,
          title: materialRecommendation.title,
          position: index,
        },
        sponsoredSearch: state.materials.entities[item.sku_id]?.sponsoredSearch,
      });
    });
  });

  return {
    ...state,
    materials: ecommerceAnalyticsMaterialAdapter.upsertMany(
      materialsState,
      state.materials,
    ),
  };
}

function updateSponsoredMaterialState(
  state: EcommerceAnalyticsState,
  action: {
    materialSponsoredSearchStates: MaterialSponsoredSearchState[];
  },
): EcommerceAnalyticsState {
  const materialsState: EcommerceAnalyticsMaterialState[] = [];

  action.materialSponsoredSearchStates?.forEach((sponsoredMaterial) => {
    const storedState =
      state.materials.entities[sponsoredMaterial.materialNumber];

    materialsState.push({
      materialNumber: sponsoredMaterial.materialNumber,
      recommendation: storedState?.recommendation,
      sponsoredSearch: sponsoredMaterial,
    });
  });

  return {
    ...state,
    materials: ecommerceAnalyticsMaterialAdapter.upsertMany(
      materialsState,
      state.materials,
    ),
  };
}
