import { Localized } from './localized';

export interface MaterialInfo {
  materialNumber: string;
  brand: {
    id: string;
    en: string;
    fr: string;
    es: string;
  };
  description: Localized<string>;
  innerPackSize: string;
  units: MaterialUnitInfo[];
  baseUom: string;
  baseUomWeight: BaseUomWeight;
  isCatchWeight: boolean;
  image?: Localized<{ url: string }>;
  lastOrderDate?: string;
  isEmpty?: boolean;
  pricingPortionPerBaseUom?: number;
  pricingPortionUom?: string;
  complimentaryMaterials?: string[];
}

export interface MaterialUnitInfo {
  uom: string;
  displayCode: string;
  parentUom: string;
  qtyInParent: string;
  primaryGtin: string;
  alternateGtins: string;
}

export interface BaseUomWeight {
  net: string;
  gross: string;
  uom: string;
}

export function getBrandFromMaterialInfo({ brand }: MaterialInfo) {
  return {
    id: brand?.id,
    en_CA: brand?.en,
    en: brand?.en,
    fr_CA: brand?.fr,
    fr: brand?.fr,
    es_US: brand?.es,
    es: brand?.es,
  };
}

export function getDescriptionFromMaterialInfo(
  materialInfo: MaterialInfo,
): Localized<string> {
  return {
    en: materialInfo.description?.en,
    fr: materialInfo.description?.fr,
    es: materialInfo.description?.es,
  };
}

export function getQtyPerMasterSellUnitFromMaterialInfo(
  materialInfo: MaterialInfo,
): number {
  const unitInfo = materialInfo?.units.find(
    (unit) => unit.parentUom === materialInfo.baseUom,
  );
  return unitInfo?.qtyInParent ? +unitInfo.qtyInParent : null;
}

export function getGtinFromMaterialInfo(materialInfo: MaterialInfo): string {
  const baseUnit = materialInfo?.units?.find(
    (unit) => unit.uom === materialInfo.baseUom,
  );
  return baseUnit?.primaryGtin ?? null;
}
