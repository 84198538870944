import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NaooConstants } from '../NaooConstants';
export const scheduledMaintenanceGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<boolean> => {
  return isNotMigrating();
};

function isNotMigrating(): Observable<boolean> {
  const router = inject(Router);
  const sessionFacade = inject(SessionFacade);
  return sessionFacade.isCurrentlyMigrating().pipe(
    map((isMigrating) => {
      if (isMigrating) {
        router.navigateByUrl(NaooConstants.SCHEDULED_MAINTENANCE_PATH);
        return false;
      }
      return true;
    }),
  );
}
