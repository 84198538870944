export interface ActiveCartSummaryState {
  quickAddEnabled: boolean;
  sortBy: CartSortType;
}

export enum CartSortType {
  AsAdded = 'As Added',
  Brand = 'Brand',
  Description = 'Description',
  ItemCode = 'Item Code',
}

export const initialActiveCartState: ActiveCartSummaryState = {
  quickAddEnabled: false,
  sortBy: CartSortType.AsAdded,
};
