import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { MaterialAdditionalInfoActions } from './material-additional-info.actions';
import { selectMaterialAdditionalInfosRecord } from './material-additional-info.selectors';
import { MaterialAdditionalInfoRecordStatus } from './material-additional-info.state';
import { ErrorActions } from '../error/error.actions';
import { MaterialAdditionalInfoService } from '../../services/material-additional-info/material-additional-info.service';
import { SharedActions } from '../shared/shared.actions';
import { chunkArray } from '../../../shared/utilities/array-utilities';

@Injectable()
export class MaterialAdditionalInfoEffects {
  private readonly maxMaterialsPerRequest = 1000;

  constructor(
    private actions$: Actions,
    private store: Store,
    private materialAdditionalInfoService: MaterialAdditionalInfoService,
  ) {}

  loadMaterialAdditionalInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAdditionalInfoActions.loadMaterialAdditionalInfo),
      concatLatestFrom(() =>
        this.store.select(selectMaterialAdditionalInfosRecord),
      ),
      mergeMap(([action, storedMaterialAdditionalInfo]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            storedMaterialAdditionalInfo[materialNumber].status ===
            MaterialAdditionalInfoRecordStatus.Queued,
        );
        if (queuedMaterialNumbers.length < this.maxMaterialsPerRequest) {
          return of(
            MaterialAdditionalInfoActions.getMaterialAdditionalInfo(
              queuedMaterialNumbers,
            ),
          );
        }

        const dispatchActions: Action[] = [];

        chunkArray(queuedMaterialNumbers, this.maxMaterialsPerRequest).forEach(
          (idBatch) =>
            dispatchActions.push(
              MaterialAdditionalInfoActions.getMaterialAdditionalInfo(idBatch),
            ),
        );

        return dispatchActions;
      }),
    );
  });

  getMaterialAdditionalInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAdditionalInfoActions.getMaterialAdditionalInfo),
      concatLatestFrom(() =>
        this.store.select(selectMaterialAdditionalInfosRecord),
      ),
      mergeMap(([action, storedMaterialAdditionalInfo]) => {
        const requestedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            storedMaterialAdditionalInfo[materialNumber].status ===
            MaterialAdditionalInfoRecordStatus.Requested,
        );

        if (requestedMaterialNumbers.length === 0) {
          return of(
            SharedActions.noOperation(
              'MaterialAdditionalInfo item is being requested',
            ),
          );
        }

        return this.materialAdditionalInfoService
          .getMaterialAdditionalInfo(requestedMaterialNumbers)
          .pipe(
            map(
              (MaterialAdditionalInfosRecord) =>
                MaterialAdditionalInfosRecord.additionalInfos,
            ),
            mergeMap((materialAdditionalInfos) => {
              const successMaterialNumbers = materialAdditionalInfos.map(
                (record) => record.materialNumber,
              );
              const errorMaterialNumbers = requestedMaterialNumbers.filter(
                (requestedMaterialNumber) =>
                  !successMaterialNumbers.includes(requestedMaterialNumber),
              );

              const dispatchedActions: Action[] = [
                MaterialAdditionalInfoActions.getMaterialAdditionalInfoSuccess(
                  materialAdditionalInfos,
                ),
              ];
              if (errorMaterialNumbers.length > 0) {
                dispatchedActions.push(
                  MaterialAdditionalInfoActions.getMaterialAdditionalInfoError(
                    errorMaterialNumbers,
                  ),
                );
              }
              return dispatchedActions;
            }),
            catchError((error) => {
              return of(
                MaterialAdditionalInfoActions.getMaterialAdditionalInfoError(
                  requestedMaterialNumbers,
                ),
                ErrorActions.fatalError(error),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  MaterialAdditionalInfoActions.clearMaterialAdditionalInfo,
                ),
              ),
            ),
          );
      }),
    );
  });
}
