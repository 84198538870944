import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowFooter,
  MaterialRowSharedOptions,
} from 'src/app/core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-row-footer',
  templateUrl: './material-row-footer.component.html',
  styleUrls: ['./material-row-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialRowFooterComponent {
  @Input() materialRowFooter: MaterialRowFooter;
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;

  @Output() viewCommodityDetailsClicked = new EventEmitter();

  get displayMaxAllocation(): boolean {
    return (
      !this.isMobile &&
      !!this.materialRowOptions?.materialCommodityInfo?.displayMaxAllocation &&
      MaterialListStyle.List === this.materialRowOptions?.materialListStyle &&
      MaterialRowContext.CustomGuide === this.materialRowOptions?.context &&
      this.materialRowOptions?.isParEnabled
    );
  }

  get hideCheckbox(): boolean {
    return !!this.materialRowFooter.compareCheckbox.isHidden;
  }
}
