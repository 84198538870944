import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialComparisonFacade } from '../../core/store/material-comparison/material-comparison.facade';

@Component({
  selector: 'naoo-compare-docket-container',
  template: `
    <naoo-compare-docket-content
      [shouldShowCompareDocket]="shouldShowCompareDocket$ | async"
      [isCollapsed]="isMaterialComparisonCollapsed$ | async"
      [materialComparisonNumbers]="materialComparisonNumbers$ | async"
    ></naoo-compare-docket-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDocketContainerComponent {
  shouldShowCompareDocket$: Observable<boolean> =
    this.materialComparisonFacade.shouldShowComparingDocket();
  isMaterialComparisonCollapsed$: Observable<boolean> =
    this.materialComparisonFacade.isMaterialComparisonCollapsed();
  materialComparisonNumbers$: Observable<string[]> =
    this.materialComparisonFacade.getMaterialComparisonNumbers();

  constructor(private materialComparisonFacade: MaterialComparisonFacade) {}
}
