import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { CategorizedCriticalItemRecord } from './model/categorized-critical-items';
import { map } from 'rxjs/operators';

interface CriticalItemsGuideRecord {
  customCategories: CategorizedCriticalItemRecord[];
}

@Injectable({ providedIn: 'root' })
export class CriticalItemsService {
  static readonly CRITICAL_ITEMS_ENDPOINT = '/api/v2/lists/critical-items';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getCriticalItems(): Observable<CategorizedCriticalItemRecord[]> {
    return this.httpClient
      .get<CriticalItemsGuideRecord>(
        this.webBffService.getBff() +
          CriticalItemsService.CRITICAL_ITEMS_ENDPOINT,
      )
      .pipe(map((record) => record.customCategories));
  }

  updateCriticalItems(
    categorizedCriticalItem: CategorizedCriticalItemRecord[],
  ): Observable<CategorizedCriticalItemRecord[]> {
    const criticalItemsRequest = { customCategories: categorizedCriticalItem };

    return this.httpClient
      .post<CriticalItemsGuideRecord>(
        this.webBffService.getBff() +
          CriticalItemsService.CRITICAL_ITEMS_ENDPOINT,
        criticalItemsRequest,
      )
      .pipe(map((record) => record.customCategories));
  }
}
