<div [hidden]="true">
  <img
    *ngFor="let imageUrl of imagesToPreload"
    class="preload-image"
    alt=""
    [src]="imageUrl"
  />

  <mat-icon
    *ngFor="let svgUrl of svgsToPreload"
    class="preload-svg"
    [svgIcon]="svgUrl.name"
  ></mat-icon>
</div>
