import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  commodityPriceRecordEntityAdapter,
  CommodityPriceRecordState,
  CommodityPriceState,
} from './commodity-price.state';
import { Dictionary } from '@ngrx/entity';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { hasCommodityPricingFinishedLoading } from './commodity-price.util';

export interface CommodityPricingData {
  materialNumber: string;
  isCommodityPricingType: boolean;
  hasLoaded: boolean;
  casePrice?: number;
  catchWeightPrice?: number;
}

const selectCommodityPriceFeature =
  createFeatureSelector<CommodityPriceState>('commodityPrice');

const selectPrices = createSelector(
  selectCommodityPriceFeature,
  (state: CommodityPriceState) => state.prices,
);

export const selectAllCommodityPriceRecordStates = createSelector(
  selectPrices,
  commodityPriceRecordEntityAdapter.getSelectors().selectEntities,
);

export const selectAllCommodityPricingDataRecords = createSelector(
  selectPrices,
  selectHasPermissionEnabled(CustomerPermission.CommodityAccess),
  (
    commodityEntityState,
    hasCommodityAccess,
  ): Dictionary<CommodityPricingData> => {
    const dictionary: Dictionary<CommodityPricingData> = {};
    commodityEntityState.ids.forEach((materialNumber: string | number) => {
      const commodityPriceRecordState =
        commodityEntityState.entities[materialNumber];
      dictionary[materialNumber] = createCommodityPricingData(
        String(materialNumber),
        commodityPriceRecordState,
        hasCommodityAccess,
      );
    });
    return dictionary;
  },
);

function createCommodityPricingData(
  materialNumber: string,
  commodityPriceRecordState: CommodityPriceRecordState,
  hasCommodityAccess: boolean,
): CommodityPricingData {
  const isPriceLoaded = hasCommodityPricingFinishedLoading(
    hasCommodityAccess,
    commodityPriceRecordState,
  );
  const isRegularPrice = isRegularPricedMaterial(
    hasCommodityAccess,
    isPriceLoaded,
    commodityPriceRecordState,
  );

  return {
    materialNumber,
    isCommodityPricingType: !isRegularPrice,
    hasLoaded: isPriceLoaded,
    casePrice: commodityPriceRecordState?.record?.unitPrices[0]?.price,
    catchWeightPrice:
      commodityPriceRecordState?.record?.unitPrices[0]?.catchWeightPrice,
  };
}

function isRegularPricedMaterial(
  hasCommodityAccess: boolean,
  hasCommodityFinishedLoading: boolean,
  recordState: CommodityPriceRecordState,
) {
  return (
    !hasCommodityAccess ||
    (hasCommodityFinishedLoading && recordState?.isRegularPricing)
  );
}
