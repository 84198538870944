import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OpenSpecialOrderItemsEffects } from './open-special-order-items.effects';
import { openSpecialOrderItemsReducer } from './open-special-order-items.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'openSpecialOrderItems',
      openSpecialOrderItemsReducer,
    ),
    EffectsModule.forFeature([OpenSpecialOrderItemsEffects]),
  ],
})
export class OpenSpecialOrderItemsModule {}
