import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationLink } from '../models/navigation-link';
import { Location } from '@angular/common';

@Component({
  selector: 'naoo-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() navigationLink: NavigationLink;

  constructor(
    private _window: Window,
    private location: Location,
  ) {}

  get iconName(): string {
    return this.isBackButtonDisabled ? 'arrow-gray' : 'arrow';
  }

  get isBackButtonDisabled(): boolean {
    return this._window.history.length <= 1;
  }

  navigateToPreviousPage() {
    if (!this.isBackButtonDisabled) {
      this.location.back();
    }
  }
}
