<ng-container *ngIf="!isFallbackImage; else basicProductImageTemplate">
  <button class="image-modal-button" (tap)="openImageModal()">
    <div
      class="main-image-slider"
      [ngClass]="{ 'single-image': images?.length === 1 }"
    >
      <div
        #sliderElement
        class="slider"
        (pan)="handlePanEvent($event)"
        [style.touch-action]="'pan-y'"
      >
        <naoo-product-image
          *ngFor="let image of images; trackBy: trackByImage"
          [alt]="imageAlt"
          [src]="image?.largeImageUrl?.src"
          [srcSet]="image?.largeImageUrl?.srcSet"
        ></naoo-product-image>
      </div>
    </div>
  </button>
</ng-container>
<div class="button-row" *ngIf="images?.length > 1">
  <button
    class="arrow-left"
    [ngClass]="{ 'disabled-arrow': leftArrowDisabled }"
    [attr.aria-label]="'PRODUCT_DETAILS.IMAGE_ARROW.BACK' | translate"
    (click)="arrowClick($event, arrowDirectionBack)"
    (contextmenu)="suppressContextMenu($event)"
  >
    <mat-icon [svgIcon]="'arrow-icon'"></mat-icon>
  </button>
  <div
    class="thumbnail-row"
    (swipeleft)="adjustThumbnailIndex(arrowDirectionNext)"
    (swiperight)="adjustThumbnailIndex(arrowDirectionBack)"
    [style.touch-action]="'pan-y'"
  >
    <ng-container *ngFor="let thumbnail of thumbnails; trackBy: trackByImage">
      <button
        [ngClass]="{ selected: isThumbnailSelected(thumbnail) }"
        [attr.aria-label]="
          'PRODUCT_DETAILS.IMAGE_INDEX'
            | translate
              : { index: images.indexOf(thumbnail) + 1, count: images.length }
        "
        (click)="selectImage(thumbnail)"
      >
        <naoo-product-image
          class="thumbnail-image"
          [src]="thumbnail?.thumbnailImageUrl?.src"
          [srcSet]="thumbnail?.thumbnailImageUrl?.srcSet"
        ></naoo-product-image>
      </button>
    </ng-container>
  </div>
  <button
    class="arrow-right"
    [ngClass]="{ 'disabled-arrow': rightArrowDisabled }"
    [attr.aria-label]="'PRODUCT_DETAILS.IMAGE_ARROW.FORWARD' | translate"
    (click)="arrowClick($event, arrowDirectionNext)"
    (contextmenu)="suppressContextMenu($event)"
  >
    <mat-icon [svgIcon]="'arrow-icon'"></mat-icon>
  </button>
</div>

<ng-template #basicProductImageTemplate>
  <button
    [ngClass]="{ disabled: !isFallbackImage }"
    class="image-modal-button"
    (click)="openImageModal()"
    *ngIf="thereIsAnImage; else basicProductImageTemplateNoButton"
  >
    <naoo-product-image
      *ngFor="let image of images; trackBy: trackByImage"
      [ngClass]="{ 'fallback-image': isFallbackImage }"
      class="fill-container"
      [alt]="imageAlt"
      [src]="image?.largeImageUrl?.src"
      [srcSet]="image?.largeImageUrl?.srcSet"
    ></naoo-product-image>
  </button>
</ng-template>

<ng-template #basicProductImageTemplateNoButton>
  <naoo-product-image
    *ngFor="let image of images; trackBy: trackByImage"
    [ngClass]="{ 'fallback-image': isFallbackImage }"
    class="fill-container"
    [alt]="imageAlt"
    [src]="image?.largeImageUrl?.src"
    [srcSet]="image?.largeImageUrl?.srcSet"
  ></naoo-product-image>
</ng-template>
