import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ExpressSchedulesState,
  ExpressSchedulesStatus,
  expressScheduleStateAdapter,
} from './express-schedules.state';
import {
  ExpressDeliveryWindow,
  ExpressScheduleRecord,
} from '../../services/express-schedules/models/express-schedule-record';
import {
  selectExpressDeliveryDateString,
  selectFulfillmentType,
  selectStoreFulfillment,
} from '../cart/cart.selectors';
import {
  selectActiveCustomerTimezone,
  selectHasPermissionEnabled,
} from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import moment, { Moment } from 'moment';
import { FulfillmentType } from '../../services/cart/models/cart-record';

const selectExpressSchedulesFeature =
  createFeatureSelector<ExpressSchedulesState>('expressSchedules');

const selectExpressSchedules = createSelector(
  selectExpressSchedulesFeature,
  (expressSchedulesState) => expressSchedulesState?.records,
);

const selectEntities = createSelector(
  selectExpressSchedules,
  (expressSchedules) =>
    expressSchedules
      ? expressScheduleStateAdapter
          .getSelectors()
          .selectEntities(expressSchedules)
      : expressScheduleStateAdapter.getInitialState().entities,
);

export const selectExpressSchedulesStatus = createSelector(
  selectExpressSchedulesFeature,
  (state): ExpressSchedulesStatus | undefined => state?.status,
);

export const selectHasExpressSchedulesLoaded = createSelector(
  selectExpressSchedulesStatus,
  selectHasPermissionEnabled(CustomerPermission.ExpressDelivery),
  (status, hasExpressPermission): boolean => {
    const expressSchedulesLoaded = [
      ExpressSchedulesStatus.Success,
      ExpressSchedulesStatus.Error,
    ].includes(status);
    return !hasExpressPermission || expressSchedulesLoaded;
  },
);

export const selectLoadedExpressSchedules = createSelector(
  selectHasExpressSchedulesLoaded,
  selectExpressSchedulesFeature,
  (hasExpressSchedulesLoaded, state): ExpressScheduleRecord[] | undefined =>
    hasExpressSchedulesLoaded
      ? expressScheduleStateAdapter.getSelectors().selectAll(state.records)
      : undefined,
);

export const selectCurrentExpressSchedule = createSelector(
  selectEntities,
  selectExpressDeliveryDateString,
  (expressScheduleRecords, deliveryDate): ExpressScheduleRecord | undefined => {
    return deliveryDate ? expressScheduleRecords?.[deliveryDate] : undefined;
  },
);

export const selectCurrentExpressDeliveryWindow = createSelector(
  selectCurrentExpressSchedule,
  selectStoreFulfillment,
  (
    currentExpressSchedule,
    storeFulfillment,
  ): ExpressDeliveryWindow | undefined => {
    return currentExpressSchedule?.deliveryWindows?.find(
      (window) =>
        +window.storeDeliveryScheduleId === +storeFulfillment?.expressRouteId,
    );
  },
);

export const selectExpressScheduleExpired = createSelector(
  selectHasExpressSchedulesLoaded,
  selectExpressDeliveryDateString,
  selectCurrentExpressDeliveryWindow,
  (hasLoaded, deliveryDate, currentExpressWindow): boolean | undefined => {
    return hasLoaded ? !!deliveryDate && !currentExpressWindow : undefined;
  },
);

export const selectDaysAfterFromExpressSchedules = (
  startMoment: Moment,
  count: number,
) => {
  return createSelector(
    selectLoadedExpressSchedules,
    selectActiveCustomerTimezone,
    (
      expressSchedulesRecordStates: ExpressScheduleRecord[] | undefined,
      customerTimeZone: string,
    ): ExpressDeliveryWindow[] => {
      if (!expressSchedulesRecordStates) {
        return [];
      }
      return expressSchedulesRecordStates
        .flatMap((x) => x.deliveryWindows)
        .filter((deliveryWindow) =>
          moment(deliveryWindow.orderEntryCutoff)
            .tz(customerTimeZone)
            .isSameOrAfter(startMoment),
        )
        .slice(0, count);
    },
  );
};

export const selectIsCurrentExpressDeliveryWindowAfterCutoff = createSelector(
  selectHasExpressSchedulesLoaded,
  selectCurrentExpressDeliveryWindow,
  selectFulfillmentType,
  selectActiveCustomerTimezone,
  (
    hasLoaded,
    currentWindow,
    fulfillmentType,
    customerTimeZone,
  ): boolean | undefined => {
    if (FulfillmentType.EXPRESS !== fulfillmentType) {
      return false;
    }
    if (hasLoaded === false) {
      return undefined;
    }
    if (!currentWindow) {
      return true;
    }
    return moment()
      .tz(customerTimeZone)
      .isAfter(moment.tz(currentWindow.orderEntryCutoff, customerTimeZone));
  },
);

export const selectHasSubmittableExpressFulfillment = createSelector(
  selectExpressSchedulesStatus,
  selectFulfillmentType,
  selectCurrentExpressSchedule,
  (status, fulfillmentType, currentExpressSchedule) =>
    FulfillmentType.EXPRESS === fulfillmentType &&
    (!!currentExpressSchedule || ExpressSchedulesStatus.Error === status),
);
