import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'naoo-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
})
export class ImportFileComponent {
  @Input() csvTemplate: string;
  @Input() excelTemplate: string;
  @Input() fileRequirements: string[];
  @Input() imagePath: string;
  @Input() title: string;
  @Output() fileSelected = new EventEmitter<Blob>();
  @Output() templateDownload = new EventEmitter<string>();

  onFileSelected(file: Blob) {
    this.fileSelected.emit(file);
  }

  onTemplateDownload(type: string) {
    this.templateDownload.emit(type);
  }
}
