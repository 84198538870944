import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'naoo-delete-category-toast',
  templateUrl: './delete-category-toast.component.html',
})
export class DeleteCategoryToastComponent implements OnInit {
  categoryName: string;
  guideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { categoryName: string; guideName: string },
  ) {}

  ngOnInit() {
    this.categoryName = this.data.categoryName;
    this.guideName = this.data.guideName;
  }
}
