<div *ngIf="!!mapping" class="allocation-container">
  <div class="pane details">
    <div class="usda-material-info">
      <span class="title">
        {{ 'ENTITLEMENT.TITLES.USDA' | translate }} #{{
          mapping.usdaMaterialId
        }}:
      </span>
      {{ mapping.fullDescription }}
    </div>
    <div>
      <span class="title"> {{ 'ENTITLEMENT.TITLES.PTV' | translate }}: </span>
      {{ mapping.passThroughValue }}/{{ mapping.passThroughValueUom }}
    </div>
    <div>
      <span class="title"> {{ 'ENTITLEMENT.TITLES.DDF' | translate }}: </span>
      {{ mapping.drawdownFactor }}/{{ mapping.drawdownFactorUom }}
    </div>
  </div>

  <div class="pane totals" [ngClass]="[mapping.allocationBackgroundColor]">
    <div class="allocation-total">
      <span class="title"> {{ 'ENTITLEMENT.ALLOCATIONS' | translate }}: </span>
      {{ mapping.allocationTotal }} {{ mapping.allocationUom }}
    </div>
    <div class="available">
      {{ 'ENTITLEMENT.AVAILABLE_BALANCES' | translate }}:
      {{ mapping.allocationAvailable }} {{ mapping.allocationUom }}
    </div>
    <div class="remaining">
      {{ mapping.remainingAllocationPercentage }}%
      {{ 'ENTITLEMENT.REMAINING' | translate }}
    </div>
  </div>
</div>
