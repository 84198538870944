import { CustomerPreferencesActions } from './customer-preferences.actions';
import {
  CustomerPreferencesState,
  initialCustomerPreferencesState,
} from './customer-preferences.state';
import { createReducer, on } from '@ngrx/store';
import { CustomerPreferencesRecord } from '../../services/customer-preferences/models/customer-preferences-record';

export const customerPreferencesReducer = createReducer(
  initialCustomerPreferencesState,
  on(
    CustomerPreferencesActions.getCustomerPreferencesSuccess,
    (state, action): CustomerPreferencesState =>
      getCustomerPreferencesSuccess(state, action),
  ),
  on(
    CustomerPreferencesActions.getCustomerPreferencesFailure,
    (state): CustomerPreferencesState => getCustomerPreferencesFailure(state),
  ),
  on(
    CustomerPreferencesActions.updateCustomerPreferencesSuccess,
    (state, action): CustomerPreferencesState =>
      updateCustomerPreferencesSuccess(state, action),
  ),
  on(
    CustomerPreferencesActions.refreshCustomerPreferences,
    (): CustomerPreferencesState => initialCustomerPreferencesState,
  ),
  on(
    CustomerPreferencesActions.updateExportDetailsPreferences,
    CustomerPreferencesActions.getCustomerPreferences,
    CustomerPreferencesActions.orderConfirmationEmailOptIn,
    CustomerPreferencesActions.orderConfirmationEmailOptOut,
    (state): CustomerPreferencesState => state,
  ),
);

function getCustomerPreferencesSuccess(
  state: CustomerPreferencesState,
  action: {
    customerPreferences: CustomerPreferencesRecord;
  },
): CustomerPreferencesState {
  return {
    ...state,
    customerPreferences: action.customerPreferences,
    hasError: false,
  };
}

function getCustomerPreferencesFailure(
  state: CustomerPreferencesState,
): CustomerPreferencesState {
  return {
    ...state,
    hasError: true,
  };
}

function updateCustomerPreferencesSuccess(
  state: CustomerPreferencesState,
  action: {
    customerPreferenceRecord: CustomerPreferencesRecord;
  },
): CustomerPreferencesState {
  return {
    ...state,
    customerPreferences: action.customerPreferenceRecord,
    hasError: false,
  };
}
