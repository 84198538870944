import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CustomGuideRecord } from '../../services/custom-guide/model/custom-guide-record';
import { Localized } from '../../../shared/models/localized';

export interface CustomGuidesState {
  recordsState: EntityState<CustomGuideRecordState>;
  hasLoaded: boolean;
}

export interface CustomGuideRecordState {
  id: string;
  searchText: string;
  record?: CustomGuideRecord;
  storageAreaCategory?: CategorizedCustomGuideRecordState;
  taxonomyCategory?: CategorizedCustomGuideRecordState;
}

export interface CategorizedCustomGuideRecordState {
  hasLoaded: boolean;
  categories?: CategorizedMaterialNumbers[];
}

export interface CategorizedMaterialNumbers {
  categoryName: Localized<string>;
  materialNumbers: string[];
}

export const customGuideAdapter = createEntityAdapter<CustomGuideRecordState>({
  selectId: (record) => record.id,
});

export const customGuideInitialState: CustomGuidesState = {
  recordsState: customGuideAdapter.getInitialState(),
  hasLoaded: false,
};
