<naoo-header
  *ngIf="!hideHeader"
  [customerBrand]="customerBrand"
  [isOffline]="isOffline"
></naoo-header>
<div
  id="scrolling-content"
  class="scrolling-content"
  tabindex="-1"
  [ngClass]="{ 'scrolling-fix': hideHeader }"
  cdkScrollable
>
  <div
    class="inner-content"
    [ngClass]="{
      'hero-page': isHeroPage,
      'centered-page': isCenteredPage,
      'flush-page': isFlushPage,
    }"
  >
    <img
      *ngIf="isMcuPage"
      alt=""
      class="lemons-bg"
      src="/assets/images/lemons_v2@2x.jpg"
      srcset="
        assets/images/lemons_v2@1x.jpg 1x,
        assets/images/lemons_v2@2x.jpg 2x
      "
    />
    <router-outlet *ngIf="currentRouteSupportsOffline"></router-outlet>
    <naoo-offline-error
      *ngIf="!currentRouteSupportsOffline"
    ></naoo-offline-error>
  </div>
</div>
<naoo-commodity-docket></naoo-commodity-docket>
<naoo-compare-docket-overlay></naoo-compare-docket-overlay>
<naoo-preload-images
  [imagesToPreload]="this.imagesToPreload"
  [svgsToPreload]="this.iconsToPreload"
></naoo-preload-images>

<!-- This mat-icon is in place to assert against icon load error handling - it is intended to always fail -->
<div [hidden]="true">
  <mat-icon svgIcon="missing-img.svg"></mat-icon>
</div>
