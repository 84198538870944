import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  selectSpecialOrderRows,
  SpecialOrderInfo,
} from './special-orders.selectors';
import { SpecialOrdersActions } from './special-orders.actions';

@Injectable({ providedIn: 'root' })
export class SpecialOrdersFacade {
  constructor(private store: Store) {}

  getLoadedSpecialOrderInfo(): Observable<SpecialOrderInfo> {
    return this.store
      .select(selectSpecialOrderRows)
      .pipe(filter((specialOrderInfo) => !!specialOrderInfo));
  }

  refreshSpecialOrders() {
    this.store.dispatch(SpecialOrdersActions.refreshSpecialOrders());
  }
}
