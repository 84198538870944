import { SpecialOrdersActions } from './special-orders.actions';
import {
  initialSpecialOrdersState,
  SpecialOrdersState,
  SpecialOrdersStatus,
} from './special-orders.state';
import { createReducer, on } from '@ngrx/store';

export const specialOrdersReducer = createReducer(
  initialSpecialOrdersState,
  on(
    SpecialOrdersActions.getSpecialOrders,
    (state): SpecialOrdersState => ({
      ...state,
      status: SpecialOrdersStatus.Requested,
    }),
  ),
  on(
    SpecialOrdersActions.getSpecialOrdersSuccess,
    (state, action): SpecialOrdersState => ({
      ...state,
      status: SpecialOrdersStatus.Success,
      records: action.specialOrders,
    }),
  ),
  on(
    SpecialOrdersActions.getSpecialOrdersError,
    (state): SpecialOrdersState => ({
      ...state,
      status: SpecialOrdersStatus.Error,
      records: [],
    }),
  ),
  on(
    SpecialOrdersActions.refreshSpecialOrders,
    (): SpecialOrdersState => initialSpecialOrdersState,
  ),
);
