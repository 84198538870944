import { createReducer, on } from '@ngrx/store';
import {
  initialMarketingGuideState,
  marketingGuideAdapter,
  MarketingGuideRecordState,
  MarketingGuideState,
} from './marketing-guide.state';
import { MarketingGuideActions } from './marketing-guide.actions';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';

export const marketingGuideReducer = createReducer(
  initialMarketingGuideState,
  on(
    MarketingGuideActions.getMarketingGuide,
    (state, action): MarketingGuideState =>
      getMarketingGuide(state, action.pathUrl),
  ),
  on(
    MarketingGuideActions.getMarketingGuideSuccess,
    (state, action): MarketingGuideState =>
      getMarketingGuideSuccess(state, action.marketingGuideRecord),
  ),
  on(
    MarketingGuideActions.getMarketingGuideFailure,
    (state, action): MarketingGuideState =>
      getMarketingGuideFailure(state, action.pathUrl),
  ),
  on(
    MarketingGuideActions.updateMarketingGuideSearchText,
    (state, action): MarketingGuideState =>
      updateMarketingGuideSearchText(state, action),
  ),
);

function getMarketingGuide(
  state: MarketingGuideState,
  pathUrl: string,
): MarketingGuideState {
  const recordExists = !!state.marketingGuideRecords.entities[pathUrl];
  return {
    marketingGuideRecords: marketingGuideAdapter.upsertOne(
      {
        hasLoaded: recordExists,
        pathUrl: pathUrl,
      },
      state.marketingGuideRecords,
    ),
  };
}

function getMarketingGuideSuccess(
  state: MarketingGuideState,
  newRecord: MarketingGuideRecord,
): MarketingGuideState {
  const changes: Partial<MarketingGuideRecordState> = {
    record: newRecord,
    hasLoaded: true,
  };

  return updateMarketingGuideRecordState(state, newRecord.pathUrl, changes);
}

function getMarketingGuideFailure(
  state: MarketingGuideState,
  pathUrl: string,
): MarketingGuideState {
  const changes: Partial<MarketingGuideRecordState> = {
    hasLoaded: true,
  };

  return updateMarketingGuideRecordState(state, pathUrl, changes);
}

function updateMarketingGuideSearchText(
  state: MarketingGuideState,
  action: {
    pathUrl: string;
    searchText: string;
  },
): MarketingGuideState {
  const change: Partial<MarketingGuideRecordState> = {
    searchText: action.searchText,
  };
  return updateMarketingGuideRecordState(state, action.pathUrl, change);
}

function updateMarketingGuideRecordState(
  state: MarketingGuideState,
  pathUrl: string,
  partialChange: Partial<MarketingGuideRecordState>,
): MarketingGuideState {
  return {
    ...state,
    marketingGuideRecords: marketingGuideAdapter.updateOne(
      {
        id: pathUrl,
        changes: partialChange,
      },
      state.marketingGuideRecords,
    ),
  };
}
