import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrderGuideRecord } from './models/order-guide-record';
import { OrderGuideUpdateRecord } from './models/order-guide-update-record';

@Injectable({ providedIn: 'root' })
export class OrderGuideService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getOrderGuide(): Observable<OrderGuideRecord> {
    return this.httpClient.get<OrderGuideRecord>(
      this.webBffService.getBff() + '/api/v6/lists/order-guide',
    );
  }

  addItemToOrderGuide(materialNumber: string) {
    return this.httpClient.post<OrderGuideUpdateRecord>(
      this.webBffService.getBff() + '/api/v1/order-guide/add',
      {
        materialNumbers: [materialNumber],
      },
    );
  }

  removeItemFromOrderGuide(materialNumber: string) {
    return this.httpClient.post<void>(
      this.webBffService.getBff() + '/api/v1/order-guide/remove',
      {
        materialNumbers: [materialNumber],
      },
    );
  }

  updateOrderGuide(
    orderGuideUpdateRecord: OrderGuideUpdateRecord,
  ): Observable<OrderGuideUpdateRecord> {
    return this.httpClient.put<OrderGuideUpdateRecord>(
      this.webBffService.getBff() + '/api/v1/order-guide',
      orderGuideUpdateRecord,
    );
  }
}
