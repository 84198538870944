import {
  IndicatorListsRecord,
  MaterialIndicatorListName,
} from '../../services/indicator-lists/models/indicator-lists-record';
import { IndicatorListsActions } from './indicator-lists.actions';
import {
  indicatorListsInitialState,
  IndicatorListsState,
  MaterialIndicatorFlags,
} from './indicator-lists.state';
import { MaterialFlagType } from '../../../material-flag/material-flag';
import { createReducer, on } from '@ngrx/store';

export const indicatorListsReducer = createReducer(
  indicatorListsInitialState,
  on(
    IndicatorListsActions.getAllIndicatorLists,
    (state): IndicatorListsState => getIndicatorLists(state),
  ),
  on(
    IndicatorListsActions.getAllIndicatorListsSuccess,
    (state, action): IndicatorListsState =>
      getIndicatorListsSuccess(state, action),
  ),
  on(
    IndicatorListsActions.getAllIndicatorListFailure,
    (): IndicatorListsState => getIndicatorListsFailure(),
  ),
  on(
    IndicatorListsActions.refreshIndicatorLists,
    (): IndicatorListsState => refreshIndicatorLists(),
  ),
);

function getIndicatorLists(state: IndicatorListsState): IndicatorListsState {
  return {
    ...state,
    hasLoaded: false,
  };
}

function getIndicatorListsSuccess(
  state: IndicatorListsState,
  action: {
    indicatorLists: IndicatorListsRecord;
  },
): IndicatorListsState {
  return {
    ...state,
    indicatorLists: mapToIndicatorFlags(action.indicatorLists),
    hasLoaded: true,
  };
}

function getIndicatorListsFailure(): IndicatorListsState {
  return {
    indicatorLists: indicatorListsInitialState.indicatorLists,
    hasLoaded: true,
  };
}

function refreshIndicatorLists(): IndicatorListsState {
  return indicatorListsInitialState;
}

function getMaterialFlagByIndicatorListName(
  listName: MaterialIndicatorListName,
): MaterialFlagType | undefined {
  switch (listName) {
    case MaterialIndicatorListName.CONTRACT:
      return MaterialFlagType.Contract;
    case MaterialIndicatorListName.AVENDRA:
      return MaterialFlagType.Avendra;
    case MaterialIndicatorListName.BID_LOCKED_ITEM:
      return MaterialFlagType.BidLockedItem;
    case MaterialIndicatorListName.COMMITTED_MANUFACTURER_ALLOWANCE:
      return MaterialFlagType.CommittedManufacturerAllowance;
    case MaterialIndicatorListName.MWBE_VENDOR:
      return MaterialFlagType.MWBEVendor;
    case MaterialIndicatorListName.PREFERRED_ITEM:
      return MaterialFlagType.PreferredItem;
    case MaterialIndicatorListName.REBATE:
      return MaterialFlagType.Rebate;
    case MaterialIndicatorListName.FOOD_BUY_S1:
      return MaterialFlagType.FoodBuy_S1;
    case MaterialIndicatorListName.FOOD_BUY_S2:
      return MaterialFlagType.FoodBuy_S2;
    case MaterialIndicatorListName.FOOD_BUY_S3:
      return MaterialFlagType.FoodBuy_S3;
    default:
      return undefined;
  }
}

function mapToIndicatorFlags(
  indicatorLists: IndicatorListsRecord,
): MaterialIndicatorFlags {
  const materialIndicatorFlags: MaterialIndicatorFlags = {};

  Object.entries(indicatorLists).forEach(([listName, materialNumbers]) => {
    const flag = getMaterialFlagByIndicatorListName(
      listName as MaterialIndicatorListName,
    );
    if (flag) {
      materialNumbers.forEach((materialNumber) => {
        if (materialIndicatorFlags[materialNumber]) {
          materialIndicatorFlags[materialNumber].push(flag);
        } else {
          materialIndicatorFlags[materialNumber] = [flag];
        }
      });
    }
  });
  return materialIndicatorFlags;
}
