import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialPriceInfoRecord } from '../../services/material-price/material-price.service';

export const MaterialPriceActions = createActionGroup({
  source: 'Material Price',
  events: {
    'Load Material Prices': (
      materialNumbers: string[],
      couponCodeToAdd?: string,
    ) => ({ materialNumbers, couponCodeToAdd }),
    'Watch Material Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Unwatch Material Prices': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Prices': (
      materialNumbers: string[],
      couponCodesToAdd: string[],
    ) => ({ materialNumbers, couponCodesToAdd }),
    'Get Material Prices Success': (
      materialPriceInfo: MaterialPriceInfoRecord,
    ) => ({ materialPriceInfo }),
    'Get Material Prices Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Material Prices': emptyProps(),
    'Refresh Material Prices': (couponCodeToAdd?: string) => ({
      couponCodeToAdd,
    }),
    'Add Material Price Coupon': (couponCodeToAdd: string) => ({
      couponCodeToAdd,
    }),
    'Remove Material Price Coupon': (couponCodeToRemove: string) => ({
      couponCodeToRemove,
    }),
  },
});
