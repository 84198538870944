import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SmartCartRecommendationsEffects } from './smart-cart-recommendations.effects';
import { StoreModule } from '@ngrx/store';
import { smartCartRecommendationsReducer } from './smart-cart-recommendations.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'smartCartRecommendations',
      smartCartRecommendationsReducer,
    ),
    EffectsModule.forFeature([SmartCartRecommendationsEffects]),
  ],
})
export class SmartCartRecommendationsModule {}
