import { createSelector } from '@ngrx/store';
import { selectCustomerMaterialInfo } from '../customer-material/customer-material.selector';
import { MaterialDetailsTransformer } from './material-details.transformer';
import {
  selectCustomerBrand,
  selectLanguage,
} from '../session/session.selectors';
import { selectLoadedMaterialAdditionalInfoRecordState } from '../material-additional-info/material-additional-info.selectors';
import { selectNutritionRecord } from '../nutrition/nutrition.selectors';
import { selectFulfillmentType } from '../cart/cart.selectors';
import { selectMaterialRecommendations } from '../material-recommendations/material-recommendations.selector';
import { Page } from '../material-recommendations/material-recommendations.state';
import {
  selectMaterialRow,
  selectOkStatusMaterialRowMaterials,
} from '../material-row/material-row.selector';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../material-row/models/material-row';
import { selectMaterialAvailabilityRecordLoadedState } from '../material-availability/material-availability.selectors';
import { selectLoadedLastOrderedRecordState } from '../last-ordered/last-ordered.selectors';
import { ProductDetailsData } from '../../../product-details/product-details-resolver/product-details-resolver';

export const selectMaterialDetails = (details: ProductDetailsData) =>
  createSelector(
    selectLanguage,
    selectFulfillmentType,
    selectCustomerMaterialInfo(details.materialInfo.materialNumber),
    selectLoadedMaterialAdditionalInfoRecordState(
      details.materialInfo.materialNumber,
    ),
    selectCustomerBrand,
    selectNutritionRecord(details.materialInfo.materialNumber),
    selectMaterialRecommendations(Page.PDP),
    selectMaterialRow({
      context: MaterialRowContext.ProductDetails,
      materialListStyle: MaterialListStyle.List,
      materialNumber: details.materialInfo.materialNumber,
      analytics: details.analytics,
    }),
    selectMaterialAvailabilityRecordLoadedState(
      details.materialInfo.materialNumber,
    ),
    selectLoadedLastOrderedRecordState(details.materialInfo.materialNumber),
    selectOkStatusMaterialRowMaterials(
      details.materialInfo.complimentaryMaterials,
    ),
    (
      language,
      fulfillmentType,
      customerMaterialInfo,
      materialAdditionalInfoRecordState,
      brand,
      nutritionLabel,
      materialRecommendations,
      materialRow,
      materialAvailabilityRecordState,
      lastOrderDate,
      orderableComplimentaryMaterials,
      // eslint-disable-next-line max-params
    ) =>
      MaterialDetailsTransformer.transformMaterialDetails(
        details.materialInfo,
        language,
        fulfillmentType,
        customerMaterialInfo,
        materialAdditionalInfoRecordState,
        brand,
        nutritionLabel,
        materialRecommendations,
        materialRow,
        materialAvailabilityRecordState,
        lastOrderDate,
        orderableComplimentaryMaterials,
      ),
  );
