import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { criticalItemsReducer } from './critical-items.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CriticalItemsEffects } from './critical-items.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('criticalItems', criticalItemsReducer),
    EffectsModule.forFeature([CriticalItemsEffects]),
  ],
})
export class CriticalItemsStoreModule {}
