import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NaooConstants } from '../../NaooConstants';

@Component({
  selector: 'naoo-add-to-critical-items-guide-toast',
  templateUrl: './add-to-critical-items-guide-toast.component.html',
  styleUrls: ['./add-to-critical-items-guide-toast.component.scss'],
})
export class AddToCriticalItemsGuideToastComponent {
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
    },
    private router: Router,
  ) {
    this.message = this.data.message;
  }

  navigate() {
    this.router.navigate([NaooConstants.GUIDES_PATH, 'critical-items-guide']);
  }
}
