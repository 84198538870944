import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  lastOrderedAdapter,
  LastOrderedRecordState,
  LastOrderedState,
} from './last-ordered.state';
import { Dictionary } from '@ngrx/entity';
import { hasLastOrderedFinishedLoading } from './last-ordered.util';

const selectLastOrderedFeature =
  createFeatureSelector<LastOrderedState>('lastOrdered');

export const selectAllLastOrderedRecords = createSelector(
  selectLastOrderedFeature,
  (state) =>
    lastOrderedAdapter.getSelectors().selectEntities(state.lastOrderedRecords),
);

export const selectLastOrderedRecordState = (
  lastOrderedMaterialNumber: string,
) =>
  createSelector(
    selectAllLastOrderedRecords,
    (
      lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    ): LastOrderedRecordState | undefined =>
      lastOrderedRecords[lastOrderedMaterialNumber],
  );

export const selectLoadedLastOrderedRecordState = (
  lastOrderedMaterialNumber: string,
) =>
  createSelector(
    selectAllLastOrderedRecords,
    (lastOrderedRecords: Dictionary<LastOrderedRecordState>) =>
      hasLastOrderedFinishedLoading(
        lastOrderedRecords[lastOrderedMaterialNumber],
      )
        ? lastOrderedRecords[lastOrderedMaterialNumber]?.record?.lastOrderDate
        : undefined,
  );

export const selectLastOrderedRecordStates = (materialNumbers: string[]) =>
  createSelector(
    selectAllLastOrderedRecords,
    (lastOrderedRecords: Dictionary<LastOrderedRecordState>) =>
      materialNumbers.map(
        (materialNumber) => lastOrderedRecords[materialNumber],
      ),
  );

export const selectLoadedLastOrderDate = (materialNumber: string) =>
  createSelector(
    selectLastOrderedRecordState(materialNumber),
    (recordState: LastOrderedRecordState | undefined) => {
      const hasLoaded = hasLastOrderedFinishedLoading(recordState);
      const lastOrderDate = recordState?.record?.lastOrderDate;
      if (hasLoaded && !lastOrderDate) {
        return undefined;
      } else if (hasLoaded && lastOrderDate) {
        return lastOrderDate;
      }
      return null;
    },
  );
