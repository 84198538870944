import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Message, MessageInfo } from '../../models/message';
import { NotificationMessage } from '../../models/notification';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private static readonly MESSAGE_API = '/api/v2/messages';
  private messages$ = new ReplaySubject<Message[]>(1);
  private collapsed$ = new ReplaySubject<boolean>(1);
  private dismissed$ = new ReplaySubject<boolean>(1);
  private lastSystemNotification$ = new ReplaySubject<NotificationMessage>(1);

  isCollapsed(): Observable<boolean> {
    return this.collapsed$;
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed$.next(collapsed);
  }

  toggleCollapsed() {
    this.collapsed$.pipe(first()).subscribe((state) => {
      this.collapsed$.next(!state);
    });
  }

  isDismissed(): Observable<boolean> {
    return this.dismissed$;
  }

  setDismissed(dismissed: boolean) {
    this.dismissed$.next(dismissed);
  }

  getLastSystemNotification(): Observable<NotificationMessage> {
    return this.lastSystemNotification$;
  }

  setLastSystemNotification(notification: NotificationMessage) {
    this.lastSystemNotification$.next(notification);
  }

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  loadMessages(): void {
    this.reset();
    this.httpClient
      .get<MessageInfo>(
        this.webBffService.getBff() + MessageService.MESSAGE_API,
      )
      .subscribe(
        (messageInfo) => this.messages$.next(messageInfo.messages),
        (errorResponse) => this.messages$.error(errorResponse.error),
      );
  }

  getMessages(): Observable<Message[]> {
    return this.messages$.asObservable();
  }

  private reset() {
    this.messages$.next([]);
    this.collapsed$.next(false);
    this.dismissed$.next(false);
    this.lastSystemNotification$.next(null);
  }
}
