<button
  class="date-picker-button delivery-schedule-widget"
  [disabled]="isDisabled"
  (click)="onClick()"
  [ngClass]="{
    'normal-layout': !wideLayout,
    'wide-layout': wideLayout,
    'no-borders': hideBorders,
    disabled: isDisabled,
  }"
  [attr.aria-label]="combinedAriaLabel | translate"
>
  <mat-icon
    class="calendar-image"
    [ngClass]="{ disabled: isDisabled }"
    svgIcon="{{ imageIcon }}"
  ></mat-icon>
  <div class="text" [ngClass]="{ disabled: isDisabled }">
    <div class="title" *ngIf="!wideLayout">{{ title | translate }}</div>
    <div class="subtitle" *ngIf="!wideLayout">{{ subtitle | translate }}</div>
    <div class="combined-title" *ngIf="wideLayout">
      {{ combinedTitle | translate }}
    </div>
  </div>
  <mat-icon *ngIf="showArrowIcon" svgIcon="arrow-v2" class="arrow"></mat-icon>
</button>
