<div class="header">
  <div class="modal-title">
    {{ 'LISTS.RENAME_CUSTOM_GUIDE_MODAL.TITLE' | translate }}
  </div>
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'CLOSE' | translate }}"
  >
    <img
      class="close-button-img"
      alt="{{ 'CLOSE' | translate }}"
      src="assets/images/close-icon.svg"
    />
  </button>
</div>
<div class="rename-modal-container">
  <mat-form-field>
    <mat-label>{{
      'LISTS.RENAME_CUSTOM_GUIDE_MODAL.HELPER_TEXT' | translate
    }}</mat-label>
    <input
      class="custom-guide-name"
      matInput
      [maxlength]="customGuideNameMaxLength"
      [formControl]="customGuideNameFormControl"
      [errorStateMatcher]="matcher"
      autocomplete="off"
      (keyup.enter)="renameCustomGuide()"
      [value]="data.customGuideName"
      cdkFocusInitial
    />
    <mat-hint align="end"
      >{{ customGuideNameFormControl.value.length }} /
      {{ customGuideNameMaxLength }}</mat-hint
    >
    <mat-error
      *ngIf="
        customGuideNameFormControl.hasError('alreadyExistingCustomGuideName')
      "
    >
      {{ 'LISTS.RENAME_CUSTOM_GUIDE_MODAL.DUPLICATE_ERROR' | translate }}
    </mat-error>
    <mat-error *ngIf="customGuideNameFormControl.errors?.pattern">
      {{ 'INVALID_CHARACTERS' | translate }}
    </mat-error>
  </mat-form-field>
  <div class="modal-button-bar">
    <button class="secondary-button large-button" (click)="closeModal()">
      {{ 'CANCEL.TEXT' | translate }}
    </button>
    <button
      class="primary-button large-button"
      (click)="renameCustomGuide()"
      [disabled]="!isFormValid()"
    >
      {{ 'LISTS.RENAME_CUSTOM_GUIDE_MODAL.SAVE' | translate }}
    </button>
  </div>
</div>
