import { Localized } from '../../../../shared/models/localized';
import { FulfillmentType } from '../../cart/models/cart-record';
import {
  StoreFulfillment,
  TruckFulfillment,
} from '../../../store/cart/cart.state';

export interface SubmitRecord {
  cartOrderIds: string[];
}

export interface CartOrderResponse {
  cartOrders: CartOrder[];
}

export interface CartOrder {
  id: string;
  status: CartOrderStatus;
  materials: CartOrderMaterialRecord[];
  submitTimestamp?: Date;
  userFullName?: string;
  customerPoNumber?: string;
  destinationSystem?: CartOrderDestinationSystem;
  retalixOrderConfirmation?: RetalixOrderConfirmation;
  retalixSpecialOrderConfirmation?: RetalixSpecialOrderConfirmation;
  externalOrderId?: string;
  fulfillmentType?: FulfillmentType;
  cartOrderTruckFulfillment?: CartOrderTruckFulfillment;
  cartOrderStoreFulfillment?: CartOrderStoreFulfillment;
  cartOrderCarrierFulfillment?: CartOrderCarrierFulfillment;
}

export enum CartOrderStatus {
  QUEUED = 'QUEUED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SUBMITTED = 'SUBMITTED',
}

export enum CartOrderDestinationSystem {
  RETALIX_ORDER = 'RETALIX_ORDER',
  RETALIX_SPECIAL_ORDER = 'RETALIX_SPECIAL_ORDER',
}

export interface CartOrderMaterialRecord {
  materialNumber: string;
  lines: CartOrderLineRecord[];
}

export interface CartOrderLineRecord {
  uom: string;
  quantity: number;
}

export interface RetalixOrderConfirmation {
  status: RetalixOrderStatus;
  headerError: RetalixOrderHeaderError;
  orderItemErrors: RetalixOrderItemError[];
}

export enum RetalixOrderStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

class RetalixOrderHeaderError {
  creditHold = false;
  rejectReasons: Localized<string[]>;
}

export interface RetalixOrderItemError {
  itemId: string;
  caseItemDetails: ItemErrorDetail[];
  unitItemDetails: ItemErrorDetail[];
}

export interface ItemErrorDetail {
  orderedQuantity: number;
  shippedQuantity: number;
  errorMessageText: Localized<string>;
}

export interface RetalixSpecialOrderConfirmation {
  status: RetalixSpecialOrderStatus;
}

export interface SplitOrder {
  orderType: SplitOrderType;
  customerPoNumber: string;
  materialNumbers: string[];
  carrierFulfillment?: CarrierFulfillment;
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;
}

export interface CarrierFulfillment {
  deliverySiteId: string;
  carrierDeliveryTypeCode: string;
  carrierShippingMethodCode: string;
  shippingPrice: number;
  shipmentId?: string;
  estimatedCustomerArrivalDate?: string;
}

export interface SplitOrderRequest {
  splitOrders: SplitOrder[];
  creditCardCheckoutId?: string;
}

export enum SplitOrderType {
  EARLY_CUTOFF = 'EARLY_CUTOFF',
  DROP_SHIP = 'DROP_SHIP',
  PICKUP = 'PICKUP',
  EXPRESS = 'EXPRESS',
}

export enum RetalixSpecialOrderStatus {
  ON_ORDER = 'ON_ORDER',
  RECEIVED = 'RECEIVED',
  READY_TO_SHIP = 'READY_TO_SHIP',
  CANCELLED = 'CANCELLED',
  PARTIAL_SHIPMENT = 'PARTIAL_SHIPMENT',
  DELIVERED = 'DELIVERED',
  CREDIT_HOLD = 'CREDIT_HOLD',
  PENDING = 'PENDING',
  PENDING_SOURCING = 'PENDING_SOURCING',
  WAITING_FOR_PO = 'WAITING_FOR_PO',
  REQUESTED = 'REQUESTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  RESUBMITTED = 'RESUBMITTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CANCELLED_SALES_REP = 'CANCELLED_SALES_REP',
  ERROR = 'ERROR',
}

export interface CartOrderStoreFulfillment {
  storePlantId?: string;
  requestedPickupTimestamp?: Date;
  deliveryWindowStartTimestamp?: Date;
  deliveryWindowEndTimestamp?: Date;
  retailInventoryAllocationToken?: string;
  expressRouteId?: number;
  requestedDeliveryType?: RequestedDeliveryType;
}

export enum RequestedDeliveryType {
  STORE_EXPRESS = 'STORE_EXPRESS',
  IN_STORE_PICKUP = 'IN_STORE_PICKUP',
}

export interface CartOrderCarrierFulfillment {
  fulfillmentSiteId?: string;
  carrierTypeCode?: string;
  carrierShippingMethodCode?: string;
  carrierServiceCode?: string;
  estimatedCustomerArrivalDate?: Date;
  shippingPriceAmt?: number;
}

export interface CartOrderTruckFulfillment {
  requestedRouteDate?: string;
  requestedCustomerArrivalDate?: string;
  requestedDeliveryDateMethod?: RequestedDeliveryDateMethod;
}

export enum RequestedDeliveryDateMethod {
  FIXED = 'FIXED',
  NEXT_AVAILABLE = 'NEXT_AVAILABLE',
  NO_LATER_THAN = 'NO_LATER_THAN',
  AS_SOON_AS_POSSIBLE = 'AS_SOON_AS_POSSIBLE',
}
