import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  MaterialMovementDetails,
  MaterialMovementEntry,
  MaterialMovementOrder,
  MaterialMovementOrderLineRecord,
} from '../../shared/models/material-movement-entry';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import {
  MaterialInfo,
  MaterialUnitInfo,
} from '../../shared/models/material-info';

@Component({
  selector: 'naoo-material-movement',
  templateUrl: './material-movement.component.html',
  styleUrls: ['./material-movement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialMovementComponent implements OnInit {
  @Input() materialMovementDetails: MaterialMovementDetails;
  @Input() materialInfo: MaterialInfo;

  numberOfWeeks = 6;
  hasMoreData = true;
  availableUnits: MaterialUnitInfo[] = [];

  constructor(private analytics: NaooAnalyticsManager) {}

  ngOnInit(): void {
    this.availableUnits = this.materialInfo?.units?.filter((unit) =>
      this.materialMovementDetails?.includedUoms.has(unit.uom),
    );
  }

  loadMore() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'Click',
      label: 'Load More',
      category: 'product details page',
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
    this.numberOfWeeks += 6;
    if (
      this.numberOfWeeks >=
      this.materialMovementDetails?.materialMovementEntries.length
    ) {
      this.hasMoreData = false;
    }
  }

  findOrderLineForUom(
    order: MaterialMovementOrder,
    uom: string,
  ): MaterialMovementOrderLineRecord {
    return order.lines.find((line) => line.uom === uom);
  }

  trackByMaterialMovementWeek(
    index: number,
    materialMovementEntry: MaterialMovementEntry,
  ): string {
    return materialMovementEntry.week;
  }
}
