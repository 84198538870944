import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from 'src/app/shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { SpecialOrdersResponse } from 'src/app/shared/models/special-orders-response';

@Injectable({ providedIn: 'root' })
export class SpecialOrdersService {
  readonly specialOrdersEndpoint = '/api/v2/special-orders';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getSpecialOrders(): Observable<SpecialOrdersResponse> {
    return this.httpClient.get<SpecialOrdersResponse>(
      this.webBffService.getBff() + this.specialOrdersEndpoint,
    );
  }
}
