<naoo-modal-header
  [title]="'IMPORT_ORDER.MODAL_TITLE' | translate"
  [showCloseButton]="showCloseButton"
  (closeModalEmitter)="closeModal(true)"
></naoo-modal-header>
<div [ngSwitch]="importOrderCurrentStep" class="step-container">
  <mat-progress-bar
    *ngSwitchCase="importOrderStep.Loading"
    [mode]="'indeterminate'"
  ></mat-progress-bar>
  <ng-container *ngIf="!cartTransferData">
    <naoo-import-file
      *ngSwitchCase="importOrderStep.ImportFile"
      [imagePath]="'IMPORT_ORDER.IMPORT_FILE.GRID_IMAGE_URL'"
      [fileRequirements]="fileRequirements"
      [csvTemplate]="'IMPORT_ORDER.FILE_ERROR.CSV_TEMPLATE_URL'"
      [excelTemplate]="'IMPORT_ORDER.FILE_ERROR.EXCEL_TEMPLATE_URL'"
      [title]="'IMPORT_ORDER.IMPORT_FILE.TITLE'"
      (fileSelected)="importContentStepNext($event)"
      (templateDownload)="trackTemplateDownload($event)"
    ></naoo-import-file>
  </ng-container>
  <naoo-row-error
    *ngSwitchCase="importOrderStep.RowError"
    canAddInvalidRows="true"
    [totalImportRowsCount]="totalRowCount"
    [validImportRowsCount]="importableRowCount"
    (startOver)="startOver()"
    (importContinue)="rowErrorStepNext()"
    (includeInvalidRows)="includeInvalidRows($event)"
  >
    <naoo-import-order-error-table
      [importOrderRows]="invalidImportOrderRows"
      [includeCustomerMaterial]="customerMaterialEnabled"
    ></naoo-import-order-error-table>
  </naoo-row-error>
  <naoo-file-error
    *ngSwitchCase="importOrderStep.FileError"
    [fileRequirements]="fileRequirements"
    [csvTemplate]="'IMPORT_ORDER.FILE_ERROR.CSV_TEMPLATE_URL'"
    [excelTemplate]="'IMPORT_ORDER.FILE_ERROR.EXCEL_TEMPLATE_URL'"
    [fileName]="fileName"
    [fileError]="fileError"
    [errorImage]="'IMPORT_ORDER.FILE_ERROR.ERROR_IMAGE_URL'"
    (startOver)="startOver()"
    (templateDownload)="trackTemplateDownload($event)"
  ></naoo-file-error>
</div>
