import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialComparisonFacade } from 'src/app/core/store/material-comparison/material-comparison.facade';
import { MaterialInfoFacade } from '../../../../core/store/material-info/material-info.facade';
import { MaterialInfo } from '../../../../shared/models/material-info';

@Component({
  selector: 'naoo-compare-card',
  templateUrl: './compare-card.component.html',
  styleUrls: ['./compare-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCardComponent implements OnInit, OnDestroy {
  @Input() materialNumber: string;

  readonly listImageDimension = 35;

  materialInfo: MaterialInfo;

  private destroyed$ = new Subject<void>();

  constructor(
    private materialInfoFacade: MaterialInfoFacade,
    private materialComparisonFacade: MaterialComparisonFacade,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.materialInfoFacade.loadMaterialInfos([this.materialNumber]);
    this.materialInfoFacade
      .getLoadedMaterialInfos([this.materialNumber])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRecord) => {
        if (materialRecord && materialRecord[0]) {
          this.materialInfo = materialRecord[0];
          this.changeDetector.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  removeMaterial() {
    this.materialComparisonFacade.removeMaterialComparison(this.materialNumber);
  }
}
