export const pageMargin = {
  top: 69,
  right: 23,
  bottom: 48,
  left: 23,
};

export const tableWidth = {
  portrait: 567,
  landscape: 747,
};

export const logo = {
  fileType: 'JPEG',
  width: 57,
  height: 18,
  margin: 22,
};

export const font = 'helvetica';

export const fontSize = {
  medium: 8,
  large: 10,
};

export const fontStyle = {
  normal: 'normal',
  bold: 'bold',
};

export const textCharSpace = {
  body: 0,
  footer: 0,
};

export const headerHeight = 45;
export const headerFooterLineHeight = 1;
export const titleMarginTop = 26;
export const subtitleMarginTop = 39;
export const footerMarginBottom = 38;
export const footerTranslationKey = 'PRINT.PAGE_FOOTER';
export const footerTotalPagesPlaceholder = '{}';

export const categorySectionMarginTop = 25;
export const categoryHeaderBottomMargin = 3;

export const minCellHeight = 10;
export const quantityDividerLineOffset = 10;
export const unitBoxSize = 10;
export const unitBoxRadius = 1;

export const pageBottomThreshold = pageMargin.bottom + minCellHeight * 2;
