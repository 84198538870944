import { Injectable } from '@angular/core';
import { LocalizationService } from '../services/translation/localization.service';
import { Locale } from 'src/app/core/services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class DecimalUtilities {
  private readonly decimalRegex = /^(\d{0,3}([.,]\d{0,2})?|[.,]?\d{1,2})$/;

  constructor(private localizationService: LocalizationService) {}

  getLocalizedValue(value: string): string {
    const valueAsString = value ? `${value}` : '';

    if (this.localizationService.currentLocale === Locale.fr_CA) {
      return valueAsString.replace('.', ',');
    } else {
      return valueAsString.replace(',', '.');
    }
  }

  parseValue(value: string): number {
    const strippedValue = value.replace(',', '.');
    if (strippedValue === '') {
      return undefined;
    }

    return parseFloat(strippedValue);
  }

  validateValue(value: string): boolean {
    return this.decimalRegex.test(value);
  }
}
