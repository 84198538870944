<div class="step">
  <div class="step-content">
    <ng-content></ng-content>
  </div>
  <naoo-order-method-button-container
    [isFirst]="isFirst"
    [isLast]="isLast"
    [primaryButtonAction]="primaryButtonAction"
    [isPrimaryButtonDisabled]="isPrimaryButtonDisabled"
    [isPrimaryButtonHidden]="isPrimaryButtonHidden"
    [secondaryButtonAction]="secondaryButtonAction"
    [isSecondaryButtonDisabled]="isSecondaryButtonDisabled"
    [isSecondaryButtonHidden]="isSecondaryButtonHidden"
  >
  </naoo-order-method-button-container>
</div>
