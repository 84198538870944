<div *ngIf="isDisabled; else activeLink" [class]="'disabled ' + className">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #activeLink>
  <a
    class="links"
    tabindex="0"
    [class]="className"
    [routerLink]="routingUrl"
    (click)="clickEvent.emit()"
    (keyup.enter)="clickEvent.emit()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
