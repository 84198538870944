import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  OrderMethodModalComponent,
  OrderMethodStep,
} from '../../modals/order-method-modal/order-method-modal.component';
import { DeliveryScheduleFacade } from '../../../core/store/delivery-schedule/delivery-schedule.facade';

@Injectable({ providedIn: 'root' })
export class SelectOrderMethodService {
  constructor(
    private dialog: MatDialog,
    private deliveryScheduleFacade: DeliveryScheduleFacade,
  ) {}

  private static isOpen = false;

  openSelectOrderMethodDialog(
    step?: OrderMethodStep,
    isImportOrder?: boolean,
    closeAction?: () => void,
    isPastCutoff?: boolean,
  ) {
    if (!SelectOrderMethodService.isOpen) {
      SelectOrderMethodService.isOpen = true;

      if (isPastCutoff) {
        this.deliveryScheduleFacade.refreshDeliverySchedules();
      }

      this.dialog
        .open(OrderMethodModalComponent, {
          id: 'order-method-modal',
          panelClass: 'naoo-order-method-modal',
          data: { step, isImportOrder, closeAction },
        })
        .afterClosed()
        .subscribe(() => {
          SelectOrderMethodService.isOpen = false;
        });
    }
  }
}
