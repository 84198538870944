import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'naoo-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() showCloseButton: boolean;
  @Input() stickyHeader?: boolean;
  @Input() isMobile?: boolean;
  @Output() closeModalEmitter = new EventEmitter();

  @ViewChild('focusableButton') focusableButton: ElementRef;

  focusCloseButton(): void {
    if (this.showCloseButton) {
      this.focusableButton.nativeElement.focus();
    }
  }

  closeModal() {
    this.closeModalEmitter.emit();
  }
}
