import { createActionGroup, emptyProps } from '@ngrx/store';
import { InventoryAvailabilityRecord } from '../../services/inventory-availability/models/inventory-availability-record';

export const InventoryAvailabilityActions = createActionGroup({
  source: 'InventoryAvailability',
  events: {
    'Load Inventory Availability': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Load Cart Inventory Availability': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Inventory Availability': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Inventory Availability Success': (
      inventoryAvailabilityRecords: InventoryAvailabilityRecord[],
    ) => ({
      inventoryAvailabilityRecords,
    }),
    'Get Inventory Availability Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Refresh Cart Inventory Availability': emptyProps(),
    'Clear Inventory Availability': emptyProps(),
  },
});
