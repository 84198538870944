import { Observable, Observer, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { LoadingService } from '../loading-service/loading.service';
import { OrderResponse } from '../../models/order-response';
import { MaterialMovement } from '../../models/material-movement';
import { MaterialMovementDetails } from '../../models/material-movement-entry';
import { OrderDetails } from '../../models/order-details';
import { OrderType } from '../../models/order-type';

export interface OrderDetailsRequest {
  orderNumber: string;
  orderType: OrderType;
  groupNumber: string | null;
}

@Injectable({ providedIn: 'root' })
export class OrderService {
  ORDERS_ENDPOINT = '/api/v6/orders';
  ORDER_DETAILS_ENDPOINT = '/api/v5/order-details';
  MATERIAL_MOVEMENT_ENDPOINT = '/api/v2/material-movement';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
    private loadingService: LoadingService,
  ) {}

  getOrders(): Observable<OrderResponse> {
    return this.genericGetOrders<OrderResponse>(
      this.webBffService.getBff() + this.ORDERS_ENDPOINT,
    );
  }

  getOrderDetails(
    orderNumber: string,
    orderType: OrderType,
    groupNumber: string | undefined,
  ): Observable<OrderDetails> {
    const urlString = this.webBffService.getBff() + this.ORDER_DETAILS_ENDPOINT;
    const orderDetailsRequest: OrderDetailsRequest = {
      orderNumber,
      orderType,
      groupNumber,
    };

    return this.retrieveOrderDetails<OrderDetails>(
      urlString,
      orderDetailsRequest,
    );
  }

  getMaterialMovement(
    materialNumber: string,
  ): Observable<MaterialMovementDetails> {
    const urlString =
      this.webBffService.getBff() +
      this.MATERIAL_MOVEMENT_ENDPOINT +
      `/${materialNumber}`;

    return new Observable<MaterialMovementDetails>(
      (observer: Observer<MaterialMovementDetails>) => {
        this.loadingService.start();

        this.httpClient
          .get<MaterialMovement>(urlString)
          .pipe(
            catchError(() => {
              return observableOf(null);
            }),
          )
          .subscribe((response: MaterialMovement) => {
            this.loadingService.stop();
            if (response) {
              observer.next(this.createMaterialMovementDetails(response));
            } else {
              observer.error(null);
            }
          });
      },
    );
  }

  private genericGetOrders<T>(urlString: string): Observable<T> {
    return new Observable<T>((observer: Observer<T>) => {
      this.loadingService.start();

      this.httpClient.get<T>(urlString).subscribe(
        (response) => {
          this.loadingService.stop();
          observer.next(response);
        },
        (error) => {
          this.loadingService.stop();
          observer.error(error);
        },
      );
    });
  }

  private retrieveOrderDetails<T>(
    urlString: string,
    orderDetailsRequest: OrderDetailsRequest,
  ): Observable<T> {
    return new Observable<T>((observer: Observer<T>) => {
      this.loadingService.start();

      this.httpClient.post<T>(urlString, orderDetailsRequest).subscribe(
        (response) => {
          this.loadingService.stop();
          observer.next(response);
        },
        (error) => {
          this.loadingService.stop();
          observer.error(error);
        },
      );
    });
  }

  private createMaterialMovementDetails(
    response: MaterialMovement,
  ): MaterialMovementDetails {
    const availableUoms: Set<string> = new Set<string>();
    response.movements.forEach((materialMovement) => {
      materialMovement.orders?.forEach((order) => {
        order.lines?.forEach((line) => {
          if (line.quantity) {
            availableUoms.add(line.uom);
          }
        });
      });
    });
    return {
      materialMovementEntries: response.movements,
      includedUoms: availableUoms,
    };
  }
}
