import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ecommerceAnalyticsReducer } from './ecommerce-analytics.reducer';
import { EcommerceAnalyticsEffects } from './ecommerce-analytics.effects';
import { GoogleAnalyticsEffects } from './google-analytics.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('ecommerceAnalytics', ecommerceAnalyticsReducer),
    EffectsModule.forFeature([
      EcommerceAnalyticsEffects,
      GoogleAnalyticsEffects,
    ]),
  ],
})
export class EcommerceAnalyticsStoreModule {}
