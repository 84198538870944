import { Injectable } from '@angular/core';
import {
  AvailableRouteDate,
  DeliveryDatePicker,
  RouteDateContent,
} from './models/delivery-date-picker';
import { Cart } from '../../models/cart';
import { DeliverySchedule } from '../../models/delivery-schedule';
import moment from 'moment-timezone';
import {
  CurrentSystem,
  SessionActiveCustomer,
} from 'src/app/core/services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class DeliveryDatePickerTransformationService {
  transform(
    cart: Cart,
    deliverySchedules: DeliverySchedule[] | null,
    customer: SessionActiveCustomer,
    isFirstDisplay: boolean,
    currentSystem: CurrentSystem,
    isPastCutoff: boolean,
  ): DeliveryDatePicker {
    return {
      isLoading: !deliverySchedules,
      content: this.transformContent(
        cart,
        deliverySchedules,
        customer,
        isFirstDisplay,
        currentSystem,
        isPastCutoff,
      ),
    };
  }

  private transformContent(
    cart: Cart,
    deliverySchedules: DeliverySchedule[] | null,
    customer: SessionActiveCustomer,
    isFirstDisplay: boolean,
    currentSystem: CurrentSystem,
    isPastCutoff: boolean,
  ): RouteDateContent {
    if (!deliverySchedules || !deliverySchedules.length) {
      return undefined;
    }

    const selectedSchedule = deliverySchedules.find((schedule) =>
      moment(this.transformDateString(schedule.routeDate)).isSame(
        cart.routeDate,
        'day',
      ),
    );
    const defaultSchedule = selectedSchedule || deliverySchedules[0];
    return {
      availableRouteDates: deliverySchedules.map((schedule) =>
        this.transformDeliverySchedule(schedule, customer),
      ),
      defaultRouteDate: this.transformDeliverySchedule(
        defaultSchedule,
        customer,
      ),
      customerTimeZone: customer.timeZone,
      isRouteDateExpired: !!cart?.routeDate && !selectedSchedule,
      isFirstDisplay: isFirstDisplay,
      currentSystem: currentSystem,
      isPastCutoff: isPastCutoff,
    };
  }

  private transformDeliverySchedule(
    deliverySchedule: DeliverySchedule,
    customer: SessionActiveCustomer,
  ): AvailableRouteDate {
    if (!deliverySchedule) {
      return undefined;
    }

    const cutoffDateTime = moment
      .tz(deliverySchedule.cutoffDateTime, customer.timeZone)
      .toDate();

    return {
      routeDate: this.transformDateString(deliverySchedule.routeDate),
      customerArrivalDate: this.transformDateString(
        deliverySchedule.customerArrivalDate,
      ),
      cutoffDateTime: cutoffDateTime,
    };
  }

  private transformDateString(dateString: string): Date {
    if (!dateString) {
      return null;
    }
    const [year, month, day] = dateString
      .split('-')
      .map((str) => parseInt(str, 10));
    return new Date(Date.UTC(year, month - 1, day));
  }
}
