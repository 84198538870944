import { FulfillmentType } from '../../services/cart/models/cart-record';
import { createSelector } from '@ngrx/store';
import {
  selectCurrentExpressDeliveryWindow,
  selectExpressScheduleExpired,
  selectExpressSchedulesStatus,
  selectLoadedExpressSchedules,
} from '../express-schedules/express-schedules.selectors';
import {
  selectActiveStoreFulfillment,
  selectPickupStoreRecords,
} from '../store/store.selectors';
import {
  DisplayData,
  OrderFulfillmentViewModel,
  OrderMethodModalSharedViewModel,
  StoreDetailsViewModel,
} from './order-method-modal-view-model';
import moment from 'moment';
import { ExpressScheduleRecord } from '../../services/express-schedules/models/express-schedule-record';
import {
  selectExpressDeliveryDateString,
  selectFulfillmentType,
  selectRouteDate,
  selectStoreFulfillment,
} from '../cart/cart.selectors';
import {
  selectActiveCustomerTimezone,
  selectCurrentSystem,
  selectHasPermissionEnabled,
} from '../session/session.selectors';
import {
  selectAllDeliveryScheduleRecords,
  selectRouteDateExpired,
} from '../delivery-schedule/delivery-schedule.selectors';
import {
  CurrentSystem,
  CustomerPermission,
} from '../../services/session/models/session-record';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';
import { ExpressSchedulesStatus } from '../express-schedules/express-schedules.state';
import {
  selectAllPickupSchedulesStateEntities,
  selectIsPickupPastCurrentCutoffTime,
  selectPickupScheduleExpired,
} from '../pickup-schedules/pickup-schedules.selectors';
import { selectPreferredStorePlantId } from '../customer-preferences/customer-preferences.selectors';
import { Dictionary } from '@ngrx/entity';
import { StoreRecord } from '../../services/store/model/store-record';
import { PickupSchedulesRecordState } from '../pickup-schedules/pickup-schedules.state';

export interface OrderMethodModalPermissions {
  hasExpressPermission: boolean;
  hasPickupPermission: boolean;
  hasTruckPermission: boolean;
}

export const selectOrderMethodModalPermissions = createSelector(
  selectHasPermissionEnabled(CustomerPermission.ExpressDelivery),
  selectHasPermissionEnabled(CustomerPermission.InStorePickup),
  selectHasPermissionEnabled(CustomerPermission.TruckDelivery),
  (
    hasExpressPermission,
    hasPickupPermission,
    hasTruckPermission,
  ): OrderMethodModalPermissions => {
    return {
      hasExpressPermission,
      hasPickupPermission,
      hasTruckPermission,
    };
  },
);

export const selectStoreDetailsMap = createSelector(
  selectPickupStoreRecords,
  selectAllPickupSchedulesStateEntities,
  selectIsPickupPastCurrentCutoffTime,
  (
    storeRecords: StoreRecord[],
    pickupSchedules: Dictionary<PickupSchedulesRecordState>,
    isPastCutoffTime: boolean,
  ): Map<string, StoreDetailsViewModel> => {
    const sliceStartIndex = isPastCutoffTime ? 1 : 0;
    const storeDetailsMap = new Map<string, StoreDetailsViewModel>();
    storeRecords.forEach((storeRecord) => {
      const pickupScheduleRecordDictionary =
        pickupSchedules?.[storeRecord.storePlantId]?.pickupScheduleEntityState
          .entities;
      const storeDetailsViewModel: StoreDetailsViewModel = {
        storeRecord: storeRecord,
        availableDates: pickupScheduleRecordDictionary
          ? Object.keys(pickupScheduleRecordDictionary).slice(sliceStartIndex)
          : [],
      };
      storeDetailsMap.set(storeRecord.storePlantId, storeDetailsViewModel);
    });
    return storeDetailsMap;
  },
);

export const selectOrderMethodModalTruckView = createSelector(
  selectAllDeliveryScheduleRecords,
  selectRouteDate,
  selectCurrentSystem,
  selectRouteDateExpired,
  (
    deliverySchedules,
    selectedRouteDate,
    currentSystem,
    isSelectedDateExpired,
  ): OrderFulfillmentViewModel => {
    const isMygfsOrSap = CurrentSystem.isMygfsOrSap(currentSystem);
    const deliveryScheduleMap: Map<string, DeliveryScheduleEntryRecord> =
      new Map();
    deliverySchedules?.forEach((schedule) => {
      deliveryScheduleMap.set(
        getDeliveryDate(schedule, isMygfsOrSap),
        schedule,
      );
    });
    const dateString = selectedRouteDate?.toUTCString().slice(0, -4);
    return {
      fulfillmentType: FulfillmentType.TRUCK,
      hasAvailableDates: !!deliveryScheduleMap.size,
      availableDates: Array.from(deliveryScheduleMap.keys()),
      selectedDate: dateString ? moment(new Date(dateString)) : undefined,
      isSelectedDateExpired,
      deliveryScheduleMap,
    };
  },
);

export const selectOrderMethodModalExpressView = createSelector(
  selectExpressDeliveryDateString,
  selectLoadedExpressSchedules,
  selectCurrentExpressDeliveryWindow,
  selectExpressScheduleExpired,
  selectStoreFulfillment,
  (
    deliveryDate,
    expressSchedules,
    currentExpressDeliveryWindow,
    isSelectedDateExpired,
    storeFulfillment,
  ): OrderFulfillmentViewModel => {
    const expressScheduleMap: Map<string, ExpressScheduleRecord> = new Map();
    expressSchedules?.forEach((schedule) => {
      expressScheduleMap.set(schedule.deliveryDate, schedule);
    });

    return {
      fulfillmentType: FulfillmentType.EXPRESS,
      hasAvailableDates: !!expressScheduleMap.size,
      availableDates: Array.from(expressScheduleMap.keys()),
      selectedDate: deliveryDate ? moment(deliveryDate) : undefined,
      isSelectedDateExpired,
      selectedExpressDeliveryWindow: currentExpressDeliveryWindow,
      expressScheduleMap,
      cartDeliveryWindowStartTimestamp:
        storeFulfillment?.deliveryWindowStartTimestamp,
      cartDeliveryWindowEndTimestamp:
        storeFulfillment?.deliveryWindowEndTimestamp,
    };
  },
);

export const selectOrderMethodModalPickupView = createSelector(
  selectStoreDetailsMap,
  selectActiveStoreFulfillment,
  selectFulfillmentType,
  selectPickupScheduleExpired,
  selectPreferredStorePlantId,
  (
    storeDetailsMap,
    activeStoreFulfillment,
    fulfillmentType,
    isSelectedDateExpired,
    preferredStorePlantId,
  ): OrderFulfillmentViewModel => {
    const isPickup = FulfillmentType.PICKUP === fulfillmentType;
    const storeFulfillment = isPickup ? activeStoreFulfillment : undefined;
    const storePlantId = storeFulfillment?.store?.storePlantId;
    return {
      fulfillmentType: FulfillmentType.PICKUP,
      hasAvailableDates: !!storeDetailsMap.size,
      selectedDate: storeFulfillment?.selectedDate,
      isSelectedDateExpired,
      storePlantId: storePlantId || preferredStorePlantId,
      storeDetailsMap,
    };
  },
);

export const selectViewModelMap = createSelector(
  selectOrderMethodModalTruckView,
  selectOrderMethodModalExpressView,
  selectOrderMethodModalPickupView,
  (
    truckView,
    expressView,
    pickupView,
  ): Map<FulfillmentType, OrderFulfillmentViewModel> => {
    const fulfillmentViewModelMap = new Map();
    fulfillmentViewModelMap.set(truckView.fulfillmentType, truckView);
    fulfillmentViewModelMap.set(expressView.fulfillmentType, expressView);
    fulfillmentViewModelMap.set(pickupView.fulfillmentType, pickupView);

    return fulfillmentViewModelMap;
  },
);

export const selectDisplayDataMap = createSelector(
  selectOrderMethodModalPermissions,
  selectLoadedExpressSchedules,
  selectAllDeliveryScheduleRecords,
  (
    permissions,
    expressSchedules,
    truckSchedules,
  ): Map<FulfillmentType, DisplayData> => {
    // determine how fulfillment types are displayed
    const fulfillmentView = new Map<FulfillmentType, DisplayData>();
    fulfillmentView.set(FulfillmentType.EXPRESS, {
      isDisabled: !expressSchedules?.length,
      isDisplayed: permissions.hasExpressPermission,
    });
    fulfillmentView.set(FulfillmentType.PICKUP, {
      isDisabled: false,
      isDisplayed: permissions.hasPickupPermission,
    });
    fulfillmentView.set(FulfillmentType.TRUCK, {
      isDisabled: !truckSchedules?.length,
      isDisplayed: permissions.hasTruckPermission,
    });
    return fulfillmentView;
  },
);

export const selectDefaultModalFulfillmentType = createSelector(
  selectOrderMethodModalPermissions,
  selectExpressSchedulesStatus,
  selectAllDeliveryScheduleRecords,
  (permissions, expressSchedulesStatus, deliverySchedules): FulfillmentType => {
    const isTruckRoutingEnabled = deliverySchedules?.length > 0;
    if (permissions.hasTruckPermission && isTruckRoutingEnabled) {
      return FulfillmentType.TRUCK;
    }

    const isExpressRoutingEnabled =
      ExpressSchedulesStatus.Success === expressSchedulesStatus;
    const isExpressSupported =
      permissions.hasExpressPermission && isExpressRoutingEnabled;

    if (isExpressSupported && !permissions.hasPickupPermission) {
      return FulfillmentType.EXPRESS;
    } else if (!isExpressSupported && permissions.hasPickupPermission) {
      return FulfillmentType.PICKUP;
    }
    return FulfillmentType.NONE;
  },
);

export const selectOrderMethodModalSharedViewModel = createSelector(
  selectActiveCustomerTimezone,
  selectDisplayDataMap,
  selectViewModelMap,
  selectDefaultModalFulfillmentType,
  selectFulfillmentType,
  (
    customerTimeZone,
    displayDataMap,
    viewModelMap,
    defaultFulfillmentType,
    selectedFulfillmentType,
  ): OrderMethodModalSharedViewModel => {
    return {
      customerTimeZone,
      displayDataMap,
      viewModelMap,
      defaultFulfillmentType,
      selectedFulfillmentType:
        FulfillmentType.NONE !== selectedFulfillmentType
          ? selectedFulfillmentType
          : undefined,
    };
  },
);

function getDeliveryDate(
  deliverySchedule: DeliveryScheduleEntryRecord,
  isMygfsOrSap: boolean,
) {
  return isMygfsOrSap
    ? deliverySchedule.customerArrivalDate
    : deliverySchedule.routeDate;
}
