import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { LocalizationService } from '../services/translation/localization.service';
import { map, takeUntil } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { dateFormats, timeZoneCode } from '../utilities/date-utilities';
import {
  getAdjustedTimeUsingOffset,
  getOffsetDifferenceInMinutes,
} from '../../core/store/store/store.utilities';
import { PickupSchedulesFacade } from '../../core/store/pickup-schedules/pickup-schedules.facade';
import { PickupScheduleRecord } from '../../core/services/pickup-schedules/models/pickup-schedule-record';
import { StoreRecord } from '../../core/services/store/model/store-record';
import { SessionFacade } from '../../core/store/session/session.facade';
import { NaooConstants } from '../NaooConstants';

@Pipe({
  name: 'naooPickupDate',
  pure: false,
})
export class NaooPickupDatePipe implements PipeTransform, OnDestroy {
  private readonly dateFormat = 'YYYY-MM-DD';
  private destroyed$ = new Subject<void>();
  private customerTimeZone: string;

  constructor(
    private pickupSchedulesFacade: PickupSchedulesFacade,
    private sessionFacade: SessionFacade,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
    this.sessionFacade
      .getLoadedActiveCustomerTimeZone()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((timeZone) => (this.customerTimeZone = timeZone));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    storeRecord: StoreRecord,
    selectedDate: Moment,
  ): Observable<string> {
    if (!storeRecord || !selectedDate) {
      return of('');
    }
    const dateString = selectedDate.format(this.dateFormat);
    return this.pickupSchedulesFacade
      .getPickupScheduleRecord(storeRecord.storePlantId, dateString)
      .pipe(
        map((pickupScheduleRecord) =>
          this.pickupDateDisplayText(storeRecord, pickupScheduleRecord),
        ),
      );
  }

  private pickupDateDisplayText(
    storeRecord: StoreRecord,
    pickupScheduleRecord: PickupScheduleRecord,
  ): string | undefined {
    if (!pickupScheduleRecord) {
      return undefined;
    }
    const locale = this.localizationService.currentLocale;
    const offsetInMinutes = getOffsetDifferenceInMinutes(
      this.customerTimeZone,
      storeRecord.timezone,
    );

    const dateDisplay = moment
      .tz(new Date(pickupScheduleRecord.date), storeRecord.timezone)
      .utc()
      .locale(locale)
      .format(dateFormats[locale]['fullDayShortDate']);

    const earliestPickupTime = getAdjustedTimeUsingOffset(
      pickupScheduleRecord.openingTime,
      offsetInMinutes + NaooConstants.pickupOpeningBufferMinutes, // provide store time to prepare order
      locale,
    );

    const closingTime = getAdjustedTimeUsingOffset(
      pickupScheduleRecord.closingTime,
      offsetInMinutes,
      locale,
    );

    const localizedTimezoneAbbr = this.getLocalizedTimezoneAbbr(
      pickupScheduleRecord.date,
      this.customerTimeZone,
    );

    return `${dateDisplay}, ${earliestPickupTime} ${NaooConstants.NDASH} ${closingTime} ${localizedTimezoneAbbr}`;
  }

  private getLocalizedTimezoneAbbr(date: string, timeZone: string) {
    return this.localizationService.instant(
      `TIMEZONES.${timeZoneCode(date, timeZone)}`,
    );
  }
}
