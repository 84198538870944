<ng-container
  *ngIf="navigationLink && navigationLink.link; else browserBackTemplate"
>
  <a
    class="back-button"
    tabindex="0"
    [routerLink]="navigationLink.link"
    attr.aria-label="{{ navigationLink.name }}"
    [ngClass]="{ 'disabled-link': isBackButtonDisabled }"
  >
    <img
      class="back-arrow-icon"
      [src]="'assets/images/' + iconName + '.svg'"
      alt="{{ 'BACK' | translate }}"
    />
    <span>{{ navigationLink.name | translate }}</span>
  </a>
</ng-container>

<ng-template #browserBackTemplate>
  <a
    class="back-button"
    *ngIf="navigationLink"
    tabindex="{{ isBackButtonDisabled ? -1 : 0 }}"
    role="link"
    attr.aria-label="{{ navigationLink.name }}"
    (click)="navigateToPreviousPage()"
    (keydown.enter)="navigateToPreviousPage()"
    [ngClass]="{ 'disabled-link': isBackButtonDisabled }"
  >
    <img
      class="back-arrow-icon"
      [src]="'assets/images/' + iconName + '.svg'"
      alt="{{ 'BACK' | translate }}"
    />
    <span>{{ navigationLink.name | translate }}</span>
  </a>
</ng-template>
