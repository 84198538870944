<div class="no-results-card">
  <div class="binoculars">
    <img alt="" src="assets/images/binoculars.svg" />
  </div>
  <div class="no-results-description">
    <div
      class="no-matches"
      *ngIf="isZeroWithOG && !isCategories && !isZeroWithAT"
      [innerHTML]="
        'SEARCH.NO_MATCHES_ON_ORDER_GUIDE'
          | translate: { searchText: searchText }
      "
    ></div>
    <div
      class="no-matches"
      *ngIf="isZeroWithOG && isCategories && !isZeroWithAT"
    >
      {{ 'SEARCH.NO_MATCHES_ON_ORDER_GUIDE_CATEGORIES' | translate }}
    </div>
    <div class="no-matches" *ngIf="!isZeroWithOG && !isZeroWithAT">
      {{ 'SEARCH.NO_MATCHES' | translate }}&nbsp;<span class="search-name">{{
        searchText
      }}</span>
    </div>
    <div class="no-matches" *ngIf="isZeroWithAT">
      {{ 'SEARCH.NO_MATCHES_ON_AVAILABLE_TODAY' | translate }}
    </div>
    <div class="try-again">
      {{
        (isInvoiceSearch
          ? 'ORDERS.INVOICES_CREDITS.SEARCH_TRY_AGAIN'
          : 'SEARCH.TRY_AGAIN'
        ) | translate
      }}
    </div>
  </div>
</div>
<div class="search-tips-box">
  <div
    class="arrow"
    [ngClass]="{ 'arrow-left': !isMobile, 'arrow-up': isMobile }"
  ></div>
  <div class="search-tips-content">
    <div class="search-tips-title">{{ 'SEARCH.TIPS' | translate }}</div>
    <ul>
      <li class="search-tips">{{ 'SEARCH.CHECK_SPELLING' | translate }}</li>
      <li class="search-tips">{{ 'SEARCH.SIMPLE_TERMS' | translate }}</li>
      <li class="search-tips">{{ 'SEARCH.LESS_SPECIFIC' | translate }}</li>
      <li *ngIf="isZeroWithOG" class="search-tips">
        {{ 'SEARCH.TURN_OFF_ORDER_GUIDE' | translate }}
      </li>
    </ul>
  </div>
</div>
