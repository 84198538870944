import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'naoo-row-error',
  templateUrl: './row-error.component.html',
  styleUrls: ['./row-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowErrorComponent {
  @Input() canAddInvalidRows = false;
  @Input() validImportRowsCount: number;
  @Input() totalImportRowsCount: number;
  @Output() startOver = new EventEmitter();
  @Output() importContinue = new EventEmitter();
  @Output() includeInvalidRows = new EventEmitter<boolean>();

  onStartOver() {
    this.startOver.emit();
  }

  onImport() {
    this.importContinue.emit();
  }

  onIncludeInvalidRows(includeInvalid: boolean) {
    this.includeInvalidRows.emit(includeInvalid);
  }

  get disableImportButton() {
    return this.validImportRowsCount === 0;
  }
}
