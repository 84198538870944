import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NaooAnalyticsManager } from '../../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../analytics/analytics-event-info';
import { ExportModalDataPoint } from '../../models/export-modal-data-point';
import { CustomerPreferencesFacade } from '../../../core/store/customer-preferences/customer-preferences.facade';
import { ExportDetailsRecord } from '../../../core/services/customer-preferences/models/customer-preferences-record';
import {
  ExportFeatureType,
  ExportFileType,
} from '../../models/export/export-properties';
import { OfflineModeFacade } from '../../../core/store/offline-mode/offline-mode.facade';
import { naooAnimations } from '../../animations/animations';
import { Observable } from 'rxjs';
import { ExportMaterialsPrintService } from '../../services/export-materials/export-materials-print.service';
import { PageOrientation } from '../../services/export-materials/models/export-materials-print-styles';

export interface ExportDialogData {
  title: string;
  fileFormats: ExportDialogFileFormat[];
  analyticsEventCategory: string;
  dataPoints: ExportModalDataPoint[];
  exportFeatureType: ExportFeatureType;
  isPrintModal?: boolean;
}

export interface ExportDialogFileFormat {
  name: ExportFileType;
  value: string;
  isChecked: boolean;
  i18nTag: string;
}

export interface TranslatedDataPoint {
  name: string;
  dataPoint: ExportModalDataPoint;
}

export interface ExportModalResponse {
  fileFormat: ExportFileType;
  dataPoints: ExportModalDataPoint[];
  closedByCancel?: boolean;
}

@Component({
  selector: 'naoo-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
  animations: [naooAnimations.displayWarning],
})
export class ExportModalComponent implements OnInit {
  private static readonly ANALYTICS_DOWNLOAD_ACTION = 'download';
  private static readonly ANALYTICS_EXPORT_LABEL = 'export';
  private static readonly ANALYTICS_EXPORT_EXCEL_EXTENSION = 'xlsx';
  private static readonly ANALYTICS_EXPORT_CSV_EXTENSION = 'csv';
  private static readonly PRINT_ANALYTICS_CATEGORY = 'general';
  private static readonly PRINT_ANALYTICS_ACTION = 'print';
  private static readonly PRINT_ANALYTICS_LABEL = 'print icon';

  private static readonly WARNING_MESSAGE_EXPORT_NO_COLUMNS =
    'EXPORT_MODAL.WARNING_MESSAGE';
  private static readonly WARNING_MESSAGE_PRINT_NO_COLUMNS =
    'EXPORT_MODAL.PRINT_NO_COLUMNS_MESSAGE';
  private static readonly WARNING_MESSAGE_PRINT_INVALID_COLUMNS =
    'EXPORT_MODAL.PRINT_INVALID_COLUMNS_MESSAGE';
  private static readonly WARNING_MESSAGE_PRINT_LANDSCAPE_COLUMNS =
    'EXPORT_MODAL.PRINT_LANDSCAPE_COLUMNS_MESSAGE';
  readonly ANIMATE_OUT = 'animateOutFromBottom';
  readonly ANIMATE_IN = 'animateInFromTop';

  title: string;
  warningMessage: string;
  exportFormGroup: FormGroup;
  fileFormat: FormControl;
  possibleFileFormats: ExportDialogFileFormat[];
  selected: ExportModalDataPoint[];
  unselected: ExportModalDataPoint[];
  exportFeatureType: ExportFeatureType;
  isPrintModal: boolean;
  isButtonDisabled = false;
  isWarningMessageVisible = false;
  warningAnimationState = this.ANIMATE_OUT;
  isOffline$: Observable<boolean>;

  private analyticsEventCategory: string;

  constructor(
    private analyticsManager: NaooAnalyticsManager,
    public dialogRef: MatDialogRef<ExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportDialogData,
    private customerPrefFacade: CustomerPreferencesFacade,
    private exportMaterialsPrintService: ExportMaterialsPrintService,
    private offlineModeFacade: OfflineModeFacade,
  ) {}

  ngOnInit() {
    this.isOffline$ = this.offlineModeFacade.getIsOffline();
    if (this.data) {
      this.isPrintModal = this.data.isPrintModal;
      this.title = this.data.title;
      this.possibleFileFormats = this.data.fileFormats;
      this.analyticsEventCategory = this.data.analyticsEventCategory;

      if (this.data.exportFeatureType) {
        this.exportFeatureType = this.data.exportFeatureType;
      }

      if (this.data.dataPoints) {
        this.selected = this.data.dataPoints.filter(
          (dataPoint) => dataPoint.isSelected,
        );
        this.unselected = this.data.dataPoints.filter(
          (dataPoint) => !dataPoint.isSelected,
        );
        this.evaluateWarningMessage(this.selected);
      }

      const preselectedFormat = this.possibleFileFormats.find(
        (format) => format.isChecked,
      );
      this.fileFormat = new FormControl(
        preselectedFormat.value
          ? preselectedFormat.value
          : this.possibleFileFormats[0].value,
        Validators.required,
      );

      this.exportFormGroup = new FormGroup({
        fileFormat: this.fileFormat,
      });
    }
  }

  updateSelection(event: ExportModalDataPoint[]) {
    if (event) {
      event.forEach((item) => {
        if (item.isSelected) {
          this.selected = [...this.selected, item];
          this.unselected.splice(this.unselected.indexOf(item), 1);
          this.unselected = this.unselected.slice(0);
        } else {
          this.unselected = [...this.unselected, item];
          this.selected.splice(this.selected.indexOf(item), 1);
          this.selected = this.selected.slice(0);
        }
      });

      this.evaluateWarningMessage(this.selected);
    }
  }

  updateOrder(event: ExportModalDataPoint[]) {
    if (event) {
      this.selected = event;
    }
  }

  submitSelection(): void {
    this.trackAnalytics(this.analyticsEventCategory);
    const close: ExportModalResponse = {
      fileFormat: this.evaluateSelectedFileType(
        this.exportFormGroup.get('fileFormat').value,
      ),
      dataPoints: this.selected,
    };

    this.saveCustomerExportPreferences();
    this.dialogRef.close(close);
  }

  closeModal(closedByCancel: boolean = false): void {
    this.dialogRef.close({ closedByCancel } as ExportModalResponse);
  }

  saveCustomerExportPreferences() {
    const exportDetailRequest: ExportDetailsRecord = {
      exportFeature: this.exportFeatureType,
      selectedType: this.evaluateSelectedFileType(
        this.exportFormGroup.get('fileFormat').value,
      ),
      selectedColumns: this.selected.map((selected) => selected.name),
    };
    this.customerPrefFacade.updateCustomerExportDetailsPreferences(
      exportDetailRequest,
    );
  }

  animationComplete() {
    this.isWarningMessageVisible =
      this.warningAnimationState === this.ANIMATE_IN;
  }

  private evaluateWarningMessage(
    selectedColumns: ExportModalDataPoint[],
  ): void {
    if (!this.selected.length) {
      this.warningMessage = this.isPrintModal
        ? ExportModalComponent.WARNING_MESSAGE_PRINT_NO_COLUMNS
        : ExportModalComponent.WARNING_MESSAGE_EXPORT_NO_COLUMNS;
      this.isWarningMessageVisible = true;
      this.warningAnimationState = this.ANIMATE_IN;
      this.isButtonDisabled = true;
    } else if (this.isPrintModal) {
      const orientation = this.exportMaterialsPrintService.getPageOrientation(
        selectedColumns.map((item) => item.name),
      );
      if (orientation === PageOrientation.Invalid) {
        this.isButtonDisabled = true;
        this.warningMessage =
          ExportModalComponent.WARNING_MESSAGE_PRINT_INVALID_COLUMNS;
        this.isWarningMessageVisible = true;
        this.warningAnimationState = this.ANIMATE_IN;
      } else if (orientation === PageOrientation.Landscape) {
        this.isButtonDisabled = false;
        this.isWarningMessageVisible = true;
        this.warningAnimationState = this.ANIMATE_IN;
        this.warningMessage =
          ExportModalComponent.WARNING_MESSAGE_PRINT_LANDSCAPE_COLUMNS;
      } else {
        this.warningAnimationState = this.ANIMATE_OUT;
        this.isButtonDisabled = false;
      }
    } else {
      this.warningAnimationState = this.ANIMATE_OUT;
      this.isButtonDisabled = false;
    }
  }

  private evaluateSelectedFileType(type: string): ExportFileType {
    switch (type) {
      case 'csv':
        return ExportFileType.CSV;
      case 'excel':
        return ExportFileType.EXCEL;
      default:
        return ExportFileType.PDF;
    }
  }

  private trackAnalytics(eventCategory: string): void {
    let eventInfo: AnalyticsEventInfo;
    if (this.isPrintModal) {
      eventInfo = {
        action: ExportModalComponent.PRINT_ANALYTICS_ACTION,
        category: ExportModalComponent.PRINT_ANALYTICS_CATEGORY,
        label: ExportModalComponent.PRINT_ANALYTICS_LABEL,
      };
    } else {
      const fileType: string =
        this.exportFormGroup.get('fileFormat').value ===
        ExportModalComponent.ANALYTICS_EXPORT_CSV_EXTENSION
          ? ExportModalComponent.ANALYTICS_EXPORT_CSV_EXTENSION
          : ExportModalComponent.ANALYTICS_EXPORT_EXCEL_EXTENSION;

      eventInfo = {
        action: ExportModalComponent.ANALYTICS_DOWNLOAD_ACTION,
        category: eventCategory,
        label: `${ExportModalComponent.ANALYTICS_EXPORT_LABEL} - ${fileType}`,
      };
    }

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }
}
