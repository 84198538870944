import {
  MaterialLine,
  MaterialOrdering,
  MaterialOrderingInfo,
  MaterialOrderingTab,
  MaterialParLine,
  MaterialRowContext,
  MaterialRowStatus,
} from '../models/material-row';
import { CartMaterialState } from '../../cart/cart.state';
import {
  CustomGuideMaterialRecord,
  CustomGuideParLineRecord,
} from '../../../services/custom-guide/model/custom-guide-record';
import { getQuantityFromCartMaterial } from '../../../../shared/utilities/cart-material-utilities';
import { MaterialRecord } from '../../../services/material-info/models/materials-info-record';
import {
  MaterialOrderingInfosWrapperTransformInput,
  MaterialOrderingTransformInput,
} from './material-row.transformer.model';
import { CombinedPricingRecord } from '../../material-price/material-price.util';
import { Injectable } from '@angular/core';

interface MaterialOrderingInfosWrapper {
  materialEstimatedSavings: number;
  materialOrderingInfos: MaterialOrderingInfo[];
  tabs: MaterialOrderingTab[];
}

@Injectable()
export class MaterialOrderingTransformer {
  static transform({
    availabilityRecord,
    cartMaterial,
    combinedPrice,
    context,
    customGuideMaterial,
    infoRecord,
    isInventoryLoaded,
    materialLines,
    materialWarning,
    status,
  }: MaterialOrderingTransformInput): MaterialOrdering | undefined {
    if (
      !availabilityRecord ||
      !availabilityRecord.isOrderable ||
      [
        MaterialRowStatus.Loading,
        MaterialRowStatus.Unorderable,
        MaterialRowStatus.Unavailable,
      ].includes(status)
    ) {
      return undefined;
    }
    const { materialEstimatedSavings, materialOrderingInfos, tabs } =
      MaterialOrderingTransformer.transformInfosWrapper({
        availabilityRecordUnits: availabilityRecord.units,
        cartMaterial,
        combinedPrice,
        context,
        customGuideMaterial,
        infoRecord,
        materialLines,
      });
    return {
      isInventoryLoaded,
      materialEstimatedSavings,
      materialOrderingInfos,
      materialWarning,
      tabs,
    };
  }

  static transformInfosWrapper({
    availabilityRecordUnits,
    cartMaterial,
    combinedPrice,
    context,
    customGuideMaterial,
    infoRecord,
    materialLines,
  }: MaterialOrderingInfosWrapperTransformInput): MaterialOrderingInfosWrapper {
    if (!infoRecord) {
      return {} as MaterialOrderingInfosWrapper;
    }
    const materialOrderingInfos = availabilityRecordUnits
      .filter((unitAvailabilityRecord) => unitAvailabilityRecord)
      .slice(0, 2)
      .map((unitAvailabilityRecord) =>
        MaterialOrderingTransformer.transformInfo(
          cartMaterial,
          combinedPrice,
          context,
          customGuideMaterial,
          infoRecord,
          materialLines,
          unitAvailabilityRecord.uom,
        ),
      );
    return {
      materialEstimatedSavings: materialOrderingInfos.reduce(
        (sum, current) => sum + current.totalEstimatedSavings,
        0,
      ),
      materialOrderingInfos,
      tabs: materialOrderingInfos.map((orderingInfo) => {
        const { displayCode, line, uom } = orderingInfo;
        return {
          displayCode,
          quantity: line?.quantity,
          uom,
        };
      }),
    };
  }

  private static transformInfo(
    cartMaterial: CartMaterialState,
    combinedPrice: CombinedPricingRecord,
    context: MaterialRowContext,
    customGuideMaterial: CustomGuideMaterialRecord,
    infoRecord: MaterialRecord,
    materialLines: MaterialLine[],
    uom: string,
  ): MaterialOrderingInfo {
    const priceUnit = combinedPrice?.unitPrices.find(
      (price) => price.salesUom === uom,
    );
    const quantity =
      MaterialOrderingTransformer.getMaterialOrderingInfoQuantity(
        cartMaterial,
        context,
        materialLines,
        uom,
      );
    const {
      isCatchWeight,
      pricingPortionPerBaseUom,
      pricingPortionUom,
      units,
    } = infoRecord;
    const unitInfoRecord = units.find((unit) => unit.uom === uom);
    const displayCode = unitInfoRecord?.displayCode;
    const pricingCoefficient = unitInfoRecord?.pricingPortionCoefficient;

    let catchWeightAvg: number;
    let catchWeightUom: string;
    let price = priceUnit?.price;
    if (isCatchWeight) {
      catchWeightAvg = priceUnit?.price;
      catchWeightUom = combinedPrice?.weightUom;
      price = priceUnit?.catchWeightPrice;
    }
    let loyaltyPoints: number;
    let totalEstimatedCost = 0;
    let totalEstimatedSavings = 0;
    if (priceUnit) {
      loyaltyPoints = priceUnit.loyaltyPoints;
      totalEstimatedCost = quantity * priceUnit.price;
      totalEstimatedSavings = quantity * priceUnit.discountAmount;
    }
    return {
      catchWeightAvg,
      catchWeightUom,
      displayCode,
      isCatchWeight,
      line: {
        displayCode,
        quantity,
        uom,
      },
      loyaltyPoints,
      parLine: MaterialOrderingTransformer.getMaterialParLine(
        customGuideMaterial?.parLines,
        uom,
      ),
      price,
      pricingPortion: MaterialOrderingTransformer.getPricingPortion(
        pricingCoefficient,
        priceUnit?.price,
      ),
      pricingPortionPerBaseUom,
      pricingPortionUom,
      totalEstimatedCost,
      totalEstimatedSavings,
      uom,
    };
  }

  private static getMaterialParLine(
    parLines: CustomGuideParLineRecord[],
    uom: string,
  ): MaterialParLine {
    const parLineRecord = parLines?.find(
      (line) => line.uom === uom && !!line.parQuantity,
    );
    if (!parLineRecord) {
      return undefined;
    }
    const { inventoryQuantity, parQuantity } = parLineRecord;
    return {
      inventoryQuantity,
      parQuantity,
      uom,
    };
  }

  private static getMaterialOrderingInfoQuantity(
    cartMaterial: CartMaterialState,
    context: MaterialRowContext,
    materialLines: MaterialLine[],
    uom: string,
  ) {
    if (MaterialRowContext.OrderDetailsEditModal === context) {
      return materialLines?.find((line) => line.uom === uom)?.quantity ?? 0;
    }
    return cartMaterial ? getQuantityFromCartMaterial(cartMaterial, uom) : 0;
  }

  private static getPricingPortion(
    pricingCoefficient: number,
    price: number,
  ): number {
    return !!pricingCoefficient && !!price ? price * pricingCoefficient : 0;
  }
}
