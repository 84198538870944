import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';

export interface MaterialWarning {
  type: MaterialWarningType;
  isOpen: boolean;
  isHardWarning?: boolean;
  isMultiLine?: boolean;
  message: string;
  messageParams?: MaterialWarningMessageParam;
  analytics: {
    displayed?: AnalyticsEventInfo;
    suppressed?: AnalyticsEventInfo;
  };
}

export enum MaterialWarningType {
  NoStock = 'NoStock',
  PartialStockCase = 'PartialStockCase',
  PartialStockCaseUnit = 'PartialStockCaseUnit',
  UnitCaseConversion = 'UnitCaseConversion',
  RepeatingDigitCase = 'RepeatingDigitCase',
  RepeatingDigitUnit = 'RepeatingDigitUnit',
  QuantityThresholdExceededCase = 'QuantityThresholdExceededCase',
  QuantityThresholdExceededUnit = 'QuantityThresholdExceededUnit',
  OpenSpecialOrders = 'OpenSpecialOrders',
  MissedCutoff = 'MissedCutoff',
  MaximumQuantityHardStop = 'MaximumQuantityHardStop',
  MaximumQuantitySoftStop = 'MaximumQuantitySoftStop',
  MinimumQuantitySoftStop = 'MinimumQuantitySoftStop',
  OverAllocation = 'OverAllocation',
}

export interface MaterialWarningMessageParam {
  caseCount?: number;
  unitCount?: number;
  onOrderCount?: number;
  maxAllocationCount?: number;
  nextAvailable?: string;
}

export enum WarningOpenDirection {
  Up = 'Up',
  UpOffset = 'UpOffset',
  DownLeft = 'DownLeft',
  Left = 'Left',
}
