import { Localized } from './localized';
import { NaooConstants } from '../NaooConstants';

export interface MaterialAvailability {
  materialNumber: string;
  cutoffCode: string;
  stockType: string;
  units: UnitAvailability[];
  isContract: boolean;
  isLocal: boolean;
  isOrderable: boolean;
  salesStatus: Localized<string>;
}

export interface UnitAvailability {
  uom: string;
  validationQty: number;
}

export function isAvailableByUom(
  materialAvailability: MaterialAvailability,
  uom: string,
): boolean {
  return materialAvailability
    ? materialAvailability.isOrderable &&
        materialAvailability.units.map((unit) => unit.uom).includes(uom)
    : false;
}

export function isAvailableByNonCase(
  materialAvailability: MaterialAvailability,
): boolean {
  return materialAvailability
    ? materialAvailability.isOrderable &&
        materialAvailability.units.some(
          (unit) => unit.uom !== NaooConstants.Uom.Case,
        )
    : false;
}
