export const environment = {
  production: true,
  hasGtm: true,
  gtmId: 'GTM-5LK4WS8',
  gtmProps: '',
  usesWiremock: false,
  enableStoreDevTools: false,
  hasRelevantRecommendationsTracking: true,
  hasFacebookTracking: true,
};
