@if (!isMobile) {
  @if (isCustomerLoaded) {
    <div class="application-selection header-disabled">
      <a
        href="javascript:void(0)"
        class="header-link ordering-link link selected"
        role="link"
        >{{ 'TITLE.ORDERING' | translate }}</a
      >
      @if (isRecipeEnabled) {
        @if (isDisabled) {
          <div class="header-disabled">
            {{ 'TITLE.RECIPE' | translate }}
          </div>
        } @else {
          <div class="recipe-header">
            @if (!hideRecipeBanner) {
              <mat-icon class="coachmark-icon" svgIcon="header-coachmark" />
              <div class="arrow-up"></div>
              <div class="coachmark-message">
                <mat-icon
                  class="starburst-icon"
                  svgIcon="header-starburst"
                ></mat-icon>
                <span
                  [innerHTML]="'TITLE.MESSAGE.RECIPE_BATCH' | translate"
                ></span>
                <button class="dismiss-button" (click)="dismissRecipeBanner()">
                  {{ 'TITLE.MESSAGE.DISMISS' | translate }}
                </button>
              </div>
            }
            <a
              href="javascript:void(0)"
              class="header-link recipe-link"
              (click)="redirectTo(HeaderLinksTypes.RECIPE)"
            >
              {{ 'TITLE.RECIPE' | translate }}
            </a>
          </div>
        }
      }

      @if (isInventoryEnabled) {
        @if (isDisabled) {
          <div class="header-disabled">
            {{ 'TITLE.INVENTORY' | translate }}
          </div>
        } @else {
          <div>
            <a
              class="header-link inventory-link"
              href="javascript:void(0)"
              (click)="redirectTo(HeaderLinksTypes.INVENTORY)"
            >
              {{ 'TITLE.INVENTORY' | translate }}
            </a>
          </div>
        }
      }
      @if (isMoreToolsEnabled) {
        <naoo-more-tools
          [isMobile]="isMobile"
          [isDisabled]="isDisabled"
          (headerRoutingEvent)="redirectTo($event, true)"
          [permissions]="customerMoreToolsPermissions"
        ></naoo-more-tools>
      }
    </div>
  }
} @else {
  @if (!isInventoryEnabled && !isRecipeEnabled && !isMoreToolsEnabled) {
    <a href="javascript:void(0)" class="ordering-link" role="link">{{
      'TITLE.ORDERING' | translate
    }}</a>
  }
  @if (isInventoryEnabled || isRecipeEnabled || isMoreToolsEnabled) {
    <button
      #orderingButton
      id="ordering-mobile-menu-trigger"
      class="menu-button"
      (click)="toggleMenu()"
      [disabled]="isOffline"
      [ngClass]="{ disabled: isOffline }"
      aria-haspopup="true"
      [attr.aria-expanded]="isMenuOpen"
      aria-controls="ordering-mobile-menu"
    >
      <span>{{ 'TITLE.ORDERING' | translate }}</span>
      <mat-icon
        class="dropdown-arrow-icon"
        [ngClass]="{ 'rotate-arrow': isMenuOpen }"
        svgIcon="arrow-v2"
      ></mat-icon>
    </button>
  }

  @if (isMenuOpen) {
    <button class="mobile-menu-overlay" (click)="toggleMenu()"></button>
  }
  <div
    #orderingMobileMenu
    id="ordering-mobile-menu"
    class="mobile-menu"
    [attr.aria-hidden]="!isMenuOpen"
    [ngClass]="{ visible: isMenuOpen }"
    (keydown.arrowdown)="nextMenuItem()"
    (keydown.arrowup)="previousMenuItem()"
    (keydown.escape)="toggleMenu()"
    (keydown.tab)="tabKeydown($event)"
    (keydown.shift.tab)="tabKeydown($event)"
  >
    <div
      class="menu-items-wrapper"
      role="menu"
      aria-labelledby="ordering-mobile-menu-trigger"
    >
      <button class="menu-item selected" role="menuitem" tabindex="-1">
        {{ 'TITLE.ORDERING' | translate }}
      </button>

      @if (isRecipeEnabled) {
        <button
          role="menuitem"
          tabindex="-1"
          class="menu-item"
          (click)="redirectTo(HeaderLinksTypes.RECIPE)"
        >
          {{ 'TITLE.RECIPE' | translate }}
        </button>
      }
      @if (isInventoryEnabled) {
        <button
          role="menuitem"
          tabindex="-1"
          class="menu-item"
          (click)="redirectTo(HeaderLinksTypes.INVENTORY)"
        >
          {{ 'TITLE.INVENTORY' | translate }}
        </button>
      }
      @if (isMoreToolsEnabled) {
        <naoo-more-tools
          [isMobile]="isMobile"
          (headerRoutingEvent)="redirectTo($event, true)"
          [permissions]="customerMoreToolsPermissions"
        ></naoo-more-tools>
      }
      <button
        role="menuitem"
        tabindex="-1"
        class="menu-item cancel-button"
        (click)="toggleMenu()"
      >
        <mat-icon class="cancel-button-icon" svgIcon="clear_icon_v2"></mat-icon>
        {{ 'CANCEL.TEXT' | translate }}
      </button>
    </div>
  </div>
}
