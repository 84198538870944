import { NutritionEffects } from './nutrition.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { nutritionReducer } from './nutrition.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('nutrition', nutritionReducer),
    EffectsModule.forFeature([NutritionEffects]),
  ],
})
export class NutritionStoreModule {}
