import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { openOrderItemsReducer } from './open-order-items.reducer';
import { OpenOrderItemsEffects } from './open-order-items.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('openOrderItems', openOrderItemsReducer),
    EffectsModule.forFeature([OpenOrderItemsEffects]),
  ],
})
export class OpenOrderItemsStoreModule {}
