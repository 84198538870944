import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  orderGuideChangeHistoryAdapter,
  OrderGuideChangeHistoryState,
} from './order-guide-change-history.state';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { OrderGuideChangeHistoryRecord } from '../../services/order-guide-change-history/models/order-guide-change-history-record';
import {
  createMaterialInfoFromMaterialInfoRecordState,
  createUnavailableMaterialInfo,
} from '../material-info/material-info.util';
import { OrderGuideChangeItem } from './order-guide-change-history.facade';
import {
  selectAllCustomGuideRecords,
  selectHasCustomGuidesLoaded,
} from '../custom-guide/custom-guide.selectors';
import { CustomGuideRecord } from '../../services/custom-guide/model/custom-guide-record';
import { getCategoryRecordForMaterial } from '../custom-guide/custom-guide.util';
import { selectAllCriticalItems } from '../critical-items/critical-items.selector';
import { sortBy } from 'lodash-es';
import {
  MatchedCustomGuideInfo,
  MaterialGuideMapping,
} from './models/order-guide-change-history';

const selectOrderGuideChangeHistoryState =
  createFeatureSelector<OrderGuideChangeHistoryState>(
    'orderGuideChangeHistory',
  );

const selectHasLoaded = createSelector(
  selectOrderGuideChangeHistoryState,
  (state) => state.hasLoaded,
);

export const selectOrderGuideChangeHistoryRecords = createSelector(
  selectOrderGuideChangeHistoryState,
  (state) =>
    state
      ? orderGuideChangeHistoryAdapter.getSelectors().selectAll(state.records)
      : [],
);

export const selectLoadedOrderGuideChangeHistory = createSelector(
  selectHasLoaded,
  selectOrderGuideChangeHistoryRecords,
  selectAllMaterialInfoRecordStates,
  (hasLoaded, orderGuideChangeHistoryRecords, materialInfoRecordStates) => {
    if (!hasLoaded) {
      return undefined;
    }
    return orderGuideChangeHistoryRecords.map((record) => {
      const { materialNumber, status, lastUpdate } = record;
      const materialInfo = createMaterialInfoFromMaterialInfoRecordState(
        materialInfoRecordStates[materialNumber],
      );
      const description =
        materialInfo?.description ??
        createUnavailableMaterialInfo(materialNumber).description;
      return <OrderGuideChangeItem>{
        materialNumber,
        status,
        lastUpdate,
        description,
      };
    });
  },
);

export const selectMaterialGuideMapping = createSelector(
  selectOrderGuideChangeHistoryRecords,
  selectAllCustomGuideRecords,
  selectAllCriticalItems,
  selectHasCustomGuidesLoaded,
  (
    changes: OrderGuideChangeHistoryRecord[],
    customGuideRecords: CustomGuideRecord[],
    criticalItemsMaterialNumbers: string[],
    hasCustomGuidesLoaded: boolean,
  ): MaterialGuideMapping => {
    const sortedGuides = sortBy(customGuideRecords, (customGuideRecord) =>
      customGuideRecord.name.toLowerCase(),
    );
    const materialGuideMapping: MaterialGuideMapping = {};

    changes.forEach((change) => {
      const materialMapping = {
        materialNumber: change.materialNumber,
        containingGuides: [] as MatchedCustomGuideInfo[],
        excludingGuides: [] as MatchedCustomGuideInfo[],
        isCriticalItem:
          !!criticalItemsMaterialNumbers &&
          criticalItemsMaterialNumbers.includes(change.materialNumber),
        hasLoaded: !!criticalItemsMaterialNumbers && hasCustomGuidesLoaded,
      };

      sortedGuides.forEach((record) => {
        if (getCategoryRecordForMaterial(record, change.materialNumber)) {
          materialMapping.containingGuides.push({
            id: record.id,
            name: record.name,
          });
        } else {
          materialMapping.excludingGuides.push({
            id: record.id,
            name: record.name,
          });
        }
      });

      materialGuideMapping[change.materialNumber] = materialMapping;
    });

    return materialGuideMapping;
  },
);
