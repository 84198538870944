import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerPreferencesState } from './customer-preferences.state';
import {
  selectActiveCustomer,
  selectSession,
} from '../session/session.selectors';
import {
  isAtOrOverLimit,
  selectCustomerNotificationPreferencesSelector,
} from '../customer-notification-preferences/customer-notification-preferences.selectors';
import { selectPickupStoreRecords } from '../store/store.selectors';

export enum OrderConfirmationEmailOptInStatus {
  Hidden,
  Prompt,
  LimitReached,
}

export const selectCustomerPreferencesStateSelector =
  createFeatureSelector<CustomerPreferencesState>('customerPreferences');

export const selectCustomerPreferences = createSelector(
  selectCustomerPreferencesStateSelector,
  (state) => state.customerPreferences,
);

export const selectOrderConfirmationEmailNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state) =>
    state.customerNotificationPreferences
      ? state.customerNotificationPreferences.emailNotifications
      : undefined,
);

export const selectOrderConfirmationEmailNotificationsOptOut = createSelector(
  selectCustomerPreferences,
  (state) => state?.orderConfirmationEmailNotificationsOptOut,
);

export const selectOrderConfirmationEmailOptInStatus = createSelector(
  selectOrderConfirmationEmailNotifications,
  selectOrderConfirmationEmailNotificationsOptOut,
  selectSession,
  (emailNotifications, emailOptOuts, session) => {
    if (!emailNotifications || !emailOptOuts || !session || !session.email) {
      return OrderConfirmationEmailOptInStatus.Hidden;
    }

    const lowercaseNotifications = emailNotifications.map((notification) =>
      notification.methodValue.toLowerCase(),
    );
    const lowercaseOptOuts = emailOptOuts.map((optOutEmail) =>
      optOutEmail.toLowerCase(),
    );
    const lowercaseCombinedList =
      lowercaseNotifications.concat(lowercaseOptOuts);

    if (lowercaseCombinedList.includes(session.email.toLowerCase())) {
      return OrderConfirmationEmailOptInStatus.Hidden;
    }

    if (isAtOrOverLimit(emailNotifications.length)) {
      return OrderConfirmationEmailOptInStatus.LimitReached;
    }

    return OrderConfirmationEmailOptInStatus.Prompt;
  },
);

export const selectExportDetails = createSelector(
  selectCustomerPreferences,
  (state) => {
    return state?.exportDetails;
  },
);

export const selectPreferredStorePlantId = createSelector(
  selectCustomerPreferences,
  selectActiveCustomer,
  selectPickupStoreRecords,
  (state, customer, storeRecords): string | undefined => {
    return state?.preferredStorePlantId
      ? state.preferredStorePlantId
      : storeRecords?.find(
          (store) => store.storeId === customer?.defaultStoreId?.toString(),
        )?.storePlantId;
  },
);
