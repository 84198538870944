import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { MaterialUnitsRecord } from '../../core/services/material-units/models/material-units-record';
import { MaterialUnitsFacade } from '../../core/store/material-units/material-units.facade';
import { takeUntil } from 'rxjs/operators';
import { EntityState } from '@ngrx/entity';
import { Subject } from 'rxjs';
import { getMaterialUnitFromUomCode } from '../utilities/material-units-utilities';
import { LocalizationService } from '../services/translation/localization.service';

@Pipe({
  name: 'materialunits',
  pure: false,
})
export class MaterialUnitsPipe implements PipeTransform, OnDestroy {
  private materialUnitsRecords: EntityState<MaterialUnitsRecord>;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialUnitsFacade: MaterialUnitsFacade,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    this.materialUnitsFacade
      .getLoadedMaterialUnitsRecords()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((entities: EntityState<MaterialUnitsRecord>) => {
        this.materialUnitsRecords = entities;
        if (this._ref) {
          this._ref.markForCheck();
        }
      });

    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: { uomCode: string; quantity?: number },
    displayQty: boolean = true,
  ): string {
    return getMaterialUnitFromUomCode(
      value.quantity,
      value.uomCode,
      this.materialUnitsRecords.entities,
      this.localizationService.currentLanguage,
      false,
      displayQty,
    );
  }
}
