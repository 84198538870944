import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ErrorActions } from '../error/error.actions';
import { DeliveryScheduleService } from '../../services/delivery-schedule/delivery-schedule.service';
import { DeliveryScheduleActions } from './delivery-schedule.actions';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import {
  NotificationCallback,
  NotificationPriority,
} from '../../../shared/models/notification';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { MaterialCutoffActions } from '../material-cutoff/material-cutoff.actions';
import {
  selectCurrentSystem,
  selectHasPermissionEnabled,
  selectIsDigitalOnlyCustomer,
} from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';

@Injectable()
export class DeliveryScheduleEffects {
  private static readonly deliveryScheduleAction = 'displayed';
  private static readonly deliveryScheduleClickAction = 'click';
  private static readonly deliveryScheduleCategory = 'ship date';
  private static readonly deliveryScheduleErrorLabel = 'routing error';
  private static readonly deliveryScheduleNoRoutingLabel = 'no routing';
  private static readonly deliveryScheduleRetryRouting = 'no routing retry';

  constructor(
    private store: Store,
    private actions$: Actions,
    private deliveryScheduleService: DeliveryScheduleService,
    private notificationService: NotificationService,
    private analytics: NaooAnalyticsManager,
  ) {}

  getDeliverySchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeliveryScheduleActions.getDeliverySchedules),
      concatLatestFrom(() => [
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.TruckDelivery),
        ),
        this.store.select(selectIsDigitalOnlyCustomer),
      ]),
      mergeMap(([_, hasTruckPermission, isDigitalOnlyCustomer]) => {
        if (!hasTruckPermission) {
          return of(DeliveryScheduleActions.getDeliverySchedulesSuccess([]));
        }
        return this.deliveryScheduleService.getDeliverySchedules().pipe(
          map((deliveryScheduleRecords) => {
            if (deliveryScheduleRecords.deliverySchedules?.length === 0) {
              this.sendNoRoutingDefinedMessage(isDigitalOnlyCustomer);
            }
            return DeliveryScheduleActions.getDeliverySchedulesSuccess(
              deliveryScheduleRecords.deliverySchedules,
            );
          }),
          catchError((error) => {
            this.sendRoutingErrorMessage(isDigitalOnlyCustomer);

            return [
              ErrorActions.silentError(error),
              DeliveryScheduleActions.getDeliverySchedulesFailure(),
            ];
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(DeliveryScheduleActions.refreshDeliverySchedules),
            ),
          ),
        );
      }),
    );
  });

  getDeliverySchedulesSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeliveryScheduleActions.getDeliverySchedulesSuccess),
      concatLatestFrom(() => this.store.select(selectCurrentSystem)),
      map(([_, currentSystem]) =>
        MaterialCutoffActions.refreshMaterialCutoffs(currentSystem),
      ),
    );
  });

  refreshDeliverySchedules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeliveryScheduleActions.refreshDeliverySchedules),
      map(() => {
        this.clearSentMessages();
        return DeliveryScheduleActions.getDeliverySchedules();
      }),
    );
  });

  private sendNoRoutingDefinedMessage(isDigitalOnlyCustomer: boolean): void {
    this.trackNoRoutingDefined();
    if (!isDigitalOnlyCustomer) {
      this.notificationService.sendNotification(
        {
          title:
            'DELIVERYSCHEDULE.ERROR_NOTIFICATION_BANNER.ROUTING_UNAVAILABLE',
          body: 'DELIVERYSCHEDULE.ERROR_NOTIFICATION_BANNER.NO_ROUTING',
        },
        null,
        NotificationPriority.MEDIUM,
      );
    }
  }

  private sendRoutingErrorMessage(isDigitalOnlyCustomer: boolean): void {
    this.trackRoutingError();
    if (!isDigitalOnlyCustomer) {
      this.notificationService.sendNotification(
        {
          title:
            'DELIVERYSCHEDULE.ERROR_NOTIFICATION_BANNER.ROUTING_UNAVAILABLE',
          body: 'DELIVERYSCHEDULE.ERROR_NOTIFICATION_BANNER.ROUTING_ERROR',
        },
        new NotificationCallback(this, () => {
          this.trackRetryInvoked();
          this.store.dispatch(
            DeliveryScheduleActions.refreshDeliverySchedules(),
          );
        }),
        NotificationPriority.MEDIUM,
      );
    }
  }

  private clearSentMessages(): void {
    this.notificationService.clearNotification(NotificationPriority.MEDIUM);
  }

  private trackNoRoutingDefined() {
    const eventInfo: AnalyticsEventInfo = {
      action: DeliveryScheduleEffects.deliveryScheduleAction,
      category: DeliveryScheduleEffects.deliveryScheduleCategory,
      label: DeliveryScheduleEffects.deliveryScheduleNoRoutingLabel,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackRoutingError() {
    const eventInfo: AnalyticsEventInfo = {
      action: DeliveryScheduleEffects.deliveryScheduleAction,
      category: DeliveryScheduleEffects.deliveryScheduleCategory,
      label: DeliveryScheduleEffects.deliveryScheduleErrorLabel,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackRetryInvoked() {
    const eventInfo: AnalyticsEventInfo = {
      action: DeliveryScheduleEffects.deliveryScheduleClickAction,
      category: DeliveryScheduleEffects.deliveryScheduleCategory,
      label: DeliveryScheduleEffects.deliveryScheduleRetryRouting,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
