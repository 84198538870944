import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'naoo-critical-item-helper-modal',
  templateUrl: './critical-item-helper-modal.component.html',
  styleUrls: ['./critical-item-helper-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriticalItemHelperModalComponent {
  constructor(
    private dialogRef: MatDialogRef<CriticalItemHelperModalComponent>,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
