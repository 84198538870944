import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { GoPointsViewModel } from '../../../../core/services/go-points/go-points.service';

@Component({
  selector: 'naoo-go-points-content',
  templateUrl: './go-points-content.component.html',
  styleUrls: ['./go-points-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoPointsContentComponent {
  @Input() viewModel: GoPointsViewModel;
  @Output() checkRedemptionEvent = new EventEmitter<number>();
  @ViewChild('input', { static: false }) input: ElementRef;

  numberOfChecks = 0;
  GO_POINTS_LEARN_MORE_URL = 'https://gfsstore.com/gordon-go-details/';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  adjustNumberOfChecks(number: number) {
    this.numberOfChecks = number;
    this.changeDetectorRef.markForCheck();
  }

  redeemCheck() {
    if (this.numberOfChecks <= this.viewModel.maxNumberOfChecks) {
      this.checkRedemptionEvent.emit(this.numberOfChecks);
    }
  }

  getInputValue(): number {
    return parseInt(this.input.nativeElement.value, 10) || 0;
  }

  validateInput(): void {
    const inpNumberOfChecks = this.getInputValue();
    if (inpNumberOfChecks < 1) {
      this.input.nativeElement.value = 0;
      this.numberOfChecks = 0;
    } else if (inpNumberOfChecks > this.viewModel.maxNumberOfChecks) {
      this.input.nativeElement.value = this.viewModel.maxNumberOfChecks;
      this.numberOfChecks = this.viewModel.maxNumberOfChecks;
    } else {
      this.numberOfChecks = inpNumberOfChecks;
    }
    this.changeDetectorRef.markForCheck();
  }
}
