import { NaooConstants } from '../../NaooConstants';
import { environment } from '../../../../environments/environment';
import { NaooLocalStorage } from '../../storage/local-storage/local-storage.service';
import { Injectable } from '@angular/core';

// eslint:disable no-empty
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private windowObject: Window,
    private naooLocalStorage: NaooLocalStorage,
  ) {}

  private redirectToLogin(loginPageUrl?: string): void {
    if (loginPageUrl && loginPageUrl.indexOf('http') !== -1) {
      // BFF has returned to us the location of the login page that we should use so use it
      this.windowObject.location.href = loginPageUrl;
    } else {
      // we are probably wiremock so redirect to the known fake login page
      this.windowObject.location.href =
        this.windowObject.location.protocol +
        '//' +
        this.windowObject.location.hostname +
        (this.windowObject.location.port
          ? ':' + this.windowObject.location.port
          : '') +
        NaooConstants.LOGIN_URL;
    }
  }

  public isMissingToken(): boolean {
    return this.getToken() === null;
  }

  public getToken(): string | null {
    return this.naooLocalStorage.getItem(NaooConstants.X_AUTH_TOKEN_HEADER);
  }

  public setToken(tokenValue: string): void {
    this.naooLocalStorage.setItem(
      NaooConstants.X_AUTH_TOKEN_HEADER,
      tokenValue,
    );
  }

  private removeToken(): void {
    this.naooLocalStorage.removeItem(NaooConstants.X_AUTH_TOKEN_HEADER);
  }

  public processAuthError(loginPageUrl?: string): void {
    if (environment.usesWiremock) {
      this.removeToken();
    }
    this.setStashUrl();
    this.redirectToLogin(loginPageUrl);
  }

  public setStashUrl(): void {
    try {
      this.naooLocalStorage.setItem(
        NaooConstants.PREVIOUS_URL,
        this.getCurrentPageUrl(),
      );
    } catch (e) {}
  }

  public getCurrentPageUrl(): string {
    return this.windowObject.location.href.replace(
      this.windowObject.location.origin,
      '',
    );
  }

  public getStashUrl(): string | null {
    try {
      return this.naooLocalStorage.getItem(NaooConstants.PREVIOUS_URL);
    } catch (e) {}
    return null;
  }

  public clearStashUrl() {
    try {
      this.naooLocalStorage.removeItem(NaooConstants.PREVIOUS_URL);
    } catch (e) {}
  }
}
