<div class="select-date-step">
  <div class="calendar-wrapper">
    <ng-container
      *ngIf="isMobileWidth && isRouteDateExpired"
      [ngTemplateOutlet]="expiredRoute"
    ></ng-container>
    <naoo-select-date-step-calendar
      [fulfillmentType]="fulfillmentType"
      [availableDates]="availableDates"
      [selectedDate]="selectedDate"
      (dateChangeEmitter)="dateChangeEmitter.emit($event)"
    >
    </naoo-select-date-step-calendar>
  </div>
  <div class="store-details-wrapper">
    <ng-container
      *ngIf="!isMobileWidth && isRouteDateExpired"
      [ngTemplateOutlet]="expiredRoute"
    ></ng-container>

    <naoo-store-display
      *ngIf="!isMobileWidth"
      [storeRecord]="storeDetailsViewModel?.storeRecord"
      [customerTimeZone]="customerTimeZone"
      [isMobileWidth]="isMobileWidth"
    ></naoo-store-display>

    <div class="pickup-details" [ngClass]="{ 'extra-padding': !isSameDay }">
      <div *ngIf="!isMobileWidth" class="pickup-header">
        {{ 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.PICKUP_DATE' | translate }}
      </div>
      <div>
        {{
          storeDetailsViewModel?.storeRecord
            | naooPickupDate: selectedDate
            | async
        }}
      </div>
      <div class="pickup-hours-text" *ngIf="isSameDay">
        {{
          'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.PICKUP_TWO_HOURS' | translate
        }}
      </div>
    </div>
  </div>
</div>

<ng-template #expiredRoute>
  <div class="invalid-date-text">
    {{
      'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_PICKUP_MESSAGE'
        | translate
    }}
  </div>
</ng-template>
