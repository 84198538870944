<div class="nutrition-info">
  <div class="nutrition-blocks">
    <div class="block-container" *ngFor="let group of blockGroups">
      <ng-container
        *ngFor="let block of group"
        [ngTemplateOutlet]="renderBlock"
        [ngTemplateOutletContext]="{ block: block }"
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template #renderBlock let-block="block">
  <h4>{{ block.label }}</h4>
  <div
    class="nutrition-block-line"
    *ngFor="let line of block.lineItems; let last = last"
    [ngClass]="{ last: last }"
  >
    {{ line.label }}
    <span>{{ parsedAmount(line.amount, line.uom) }}</span>
  </div>
  <div class="break"></div>
</ng-template>
