<div class="mobile-search-screen">
  <div class="search-bar-mobile">
    <naoo-search-bar
      [isMobile]="true"
      (closeMobileSearchModalEmitter)="closeModal()"
    ></naoo-search-bar>
    <a
      class="cancel-button"
      href="javascript:void(0)"
      title="{{ 'CANCEL.TEXT' | translate }}"
      (click)="closeModal()"
      >{{ 'CANCEL.TEXT' | translate }}</a
    >
  </div>
  <hr />
</div>
