import { first, map } from 'rxjs/operators';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionFacade } from '../../core/store/session/session.facade';
import { NaooConstants } from '../NaooConstants';
import { SessionRecord } from 'src/app/core/services/session/models/session-record';
import { AuthService } from '../services/auth/auth.service';

export const customerUnitSelectionGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<boolean> => {
  return isSessionLoadedWithActiveCustomer(_next.routeConfig.path);
};

function isSessionLoadedWithActiveCustomer(
  routePath: string,
): Observable<boolean> {
  const router = inject(Router);
  const sessionFacade = inject(SessionFacade);
  const authService = inject(AuthService);
  return sessionFacade.getLoadedSession().pipe(
    first(),
    map((sessionRecord: SessionRecord) => {
      if (
        sessionRecord.activeCustomer ||
        NaooConstants.CUSTOMER_UNIT_SELECTION_PATH === `/${routePath}`
      ) {
        return true;
      } else {
        authService.setStashUrl();
        router.navigateByUrl(NaooConstants.CUSTOMER_UNIT_SELECTION_PATH);
        return false;
      }
    }),
  );
}
