<div
  class="content"
  [ngClass]="{
    'border-left':
      selectedDataPoints.length >= totalDataPoints - selectedDataPoints.length,
    disabled: disabled,
  }"
>
  <div class="header">
    <div class="title">
      {{
        (isPrintModal
          ? 'EXPORT_MODAL.SELECTED_COLUMNS'
          : 'EXPORT_MODAL.SELECTED_DATA'
        )
          | translate
            : {
                selected: translatedDataPoints.length,
                total: totalDataPoints,
              }
      }}
    </div>

    <button
      class="remove-all-button"
      type="button"
      (click)="removeAll()"
      [attr.aria-label]="'EXPORT_MODAL.REMOVE_ALL' | translate"
      [disabled]="disabled"
    >
      {{ 'EXPORT_MODAL.REMOVE_ALL' | translate }}
    </button>
  </div>
  <div class="row" *ngFor="let data of translatedDataPoints; let i = index">
    <div class="name">
      {{ data.name }}
    </div>
    <div class="edit-buttons">
      <button
        type="button"
        [ngClass]="{ 'arrow-up-active': i !== 0, 'arrow-up-inactive': i === 0 }"
        (click)="moveUp(i)"
        [disabled]="i === 0"
        [attr.aria-label]="
          data.name + ', ' + ('EXPORT_MODAL.MOVE_UP' | translate)
        "
      ></button>
      <button
        type="button"
        (click)="moveDown(i)"
        [ngClass]="{
          'arrow-down-active': i < selectedDataPoints.length - 1,
          'arrow-down-inactive': i === selectedDataPoints.length - 1,
        }"
        [disabled]="i === selectedDataPoints.length - 1"
        [attr.aria-label]="
          data.name + ', ' + ('EXPORT_MODAL.MOVE_DOWN' | translate)
        "
      ></button>
      <div class="remove-wrapper">
        <button
          (click)="remove(data.dataPoint)"
          class="remove-btn"
          [attr.aria-label]="
            data.name + ', ' + ('EXPORT_MODAL.REMOVE_DATA' | translate)
          "
        ></button>
      </div>
    </div>
  </div>
</div>
