import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialRelatedListRecord } from '../../services/material-related/model/material-related-record';

export const MaterialRelatedActions = createActionGroup({
  source: 'Material Related',
  events: {
    'Load Material Related': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Related': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Related Success': (records: MaterialRelatedListRecord) => ({
      records,
    }),
    'Get Material Related Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Material Related': emptyProps(),
  },
});
