import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NutritionLabel } from './models/nutrition';
import { NutritionActions } from './nutrition.actions';
import { selectLoadedNutrition } from './nutrition.selectors';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NutritionFacade {
  constructor(private store: Store) {}

  loadNutrition(materialNumbers: string[]) {
    this.store.dispatch(NutritionActions.loadNutrition(materialNumbers));
  }

  getLoadedNutrition(materialNumber: string): Observable<NutritionLabel> {
    return this.store
      .select(selectLoadedNutrition(materialNumber))
      .pipe(filter((nutritionLabel) => !!nutritionLabel));
  }
}
