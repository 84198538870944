import {
  initialMaterialAvailabilityState,
  materialAvailabilityAdapter,
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
  MaterialAvailabilityState,
} from './material-availability.state';
import { MaterialAvailabilityActions } from './material-availability.actions';
import { Update } from '@ngrx/entity';
import { MaterialAvailabilityRecord } from '../../services/material-availability/model/material-availabilities-record';
import { createReducer, on } from '@ngrx/store';

export const materialAvailabilityReducer = createReducer(
  initialMaterialAvailabilityState,
  on(
    MaterialAvailabilityActions.loadMaterialAvailability,
    (state, action): MaterialAvailabilityState =>
      loadMaterialAvailability(state, action.materialNumbers),
  ),
  on(
    MaterialAvailabilityActions.getMaterialAvailability,
    (state, action): MaterialAvailabilityState =>
      getMaterialAvailability(state, action.materialNumbers),
  ),
  on(
    MaterialAvailabilityActions.getMaterialAvailabilitySuccess,
    (state, action): MaterialAvailabilityState =>
      getMaterialAvailabilitySuccess(state, action.materialAvailabilityRecords),
  ),
  on(
    MaterialAvailabilityActions.getMaterialAvailabilityError,
    (state, action): MaterialAvailabilityState =>
      getMaterialAvailabilityError(state, action.materialNumbers),
  ),
  on(
    MaterialAvailabilityActions.clearMaterialAvailability,
    (): MaterialAvailabilityState => initialMaterialAvailabilityState,
  ),
);

function loadMaterialAvailability(
  state: MaterialAvailabilityState,
  materialNumbers: string[],
): MaterialAvailabilityState {
  const upsertedEntities: MaterialAvailabilityRecordState[] = materialNumbers
    .filter(
      (materialNumber) =>
        !state.entities[materialNumber] ||
        state.entities[materialNumber].status ===
          MaterialAvailabilityRecordStatus.Error,
    )
    .map((materialNumber) => {
      return {
        materialNumber: materialNumber,
        status: MaterialAvailabilityRecordStatus.Queued,
        record: undefined,
      };
    });
  return materialAvailabilityAdapter.upsertMany(upsertedEntities, state);
}

function getMaterialAvailability(
  state: MaterialAvailabilityState,
  materialNumbers: string[],
): MaterialAvailabilityState {
  const queuedEntities: Update<MaterialAvailabilityRecordState>[] =
    materialNumbers
      .filter(
        (materialNumber) =>
          state.entities[materialNumber]?.status ===
          MaterialAvailabilityRecordStatus.Queued,
      )
      .map((materialNumber) => {
        return {
          id: materialNumber,
          changes: {
            status: MaterialAvailabilityRecordStatus.Requested,
          },
        };
      });

  return materialAvailabilityAdapter.updateMany(queuedEntities, state);
}

function getMaterialAvailabilitySuccess(
  state: MaterialAvailabilityState,
  materialAvailabilityRecords: MaterialAvailabilityRecord[],
): MaterialAvailabilityState {
  const updatedMaterialRecordStates: Update<MaterialAvailabilityRecordState>[] =
    materialAvailabilityRecords.map((record) => {
      return {
        id: record.materialNumber,
        changes: {
          record: record,
          status: MaterialAvailabilityRecordStatus.Success,
        },
      };
    });
  return materialAvailabilityAdapter.updateMany(
    updatedMaterialRecordStates,
    state,
  );
}

function getMaterialAvailabilityError(
  state: MaterialAvailabilityState,
  materialNumbers: string[],
): MaterialAvailabilityState {
  const updatedMaterialRecordStates: Update<MaterialAvailabilityRecordState>[] =
    materialNumbers.map((materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: MaterialAvailabilityRecordStatus.Error,
        },
      };
    });
  return materialAvailabilityAdapter.updateMany(
    updatedMaterialRecordStates,
    state,
  );
}
