import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'naoo-generic-disableable-link',
  templateUrl: './generic-disableable-link.component.html',
  styleUrls: ['./generic-disableable-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericDisableableLinkComponent {
  @Input() routingUrl: string = null;
  @Input() isDisabled = false;
  @Input() className = '';
  @Output() clickEvent = new EventEmitter();
}
