import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { CriticalItemsService } from '../../services/critical-items/critical-items.service';
import { Observable, of } from 'rxjs';
import { CriticalItemsActions } from './critical-items.actions';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { selectAllCategorizedCriticalItems } from './critical-items.selector';
import { CategorizedCriticalItemRecord } from '../../services/critical-items/model/categorized-critical-items';
import { ErrorActions } from '../error/error.actions';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class CriticalItemsEffects {
  private readonly analyticsLabel = 'add to critical items guide - ';
  private readonly toastFail = 'LISTS.PRODUCT_ALREADY_FOUND_TOAST';
  private readonly toastSuccess = 'LISTS.PRODUCT_ADDED_TOAST';

  constructor(
    private actions$: Actions,
    private store: Store,
    private criticalItemsService: CriticalItemsService,
    private toastMessageService: ToastMessageService,
    private analyticsManager: NaooAnalyticsManager,
  ) {}

  refreshCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CriticalItemsActions.refreshCriticalItems),
      map(() => CriticalItemsActions.getCriticalItems()),
    );
  });

  getCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CriticalItemsActions.getCriticalItems),
      mergeMap(() =>
        this.criticalItemsService.getCriticalItems().pipe(
          mergeMap((categorizedCriticalItems) => {
            const flattenedCriticalItems = this.getFlattenedCriticalItems(
              categorizedCriticalItems,
            );
            return [
              MaterialInfoActions.loadMaterialInfo(flattenedCriticalItems),
              MaterialAvailabilityActions.loadMaterialAvailability(
                flattenedCriticalItems,
              ),
              MaterialPriceActions.loadMaterialPrices(flattenedCriticalItems),
              CriticalItemsActions.getCriticalItemsSuccess(
                categorizedCriticalItems,
              ),
            ];
          }),
          catchError(() => of(CriticalItemsActions.getCriticalItemsFailure())),
          takeUntil(
            this.actions$.pipe(
              ofType(CriticalItemsActions.refreshCriticalItems),
            ),
          ),
        ),
      ),
    );
  });

  addCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CriticalItemsActions.addCriticalItem),
      concatLatestFrom(() =>
        this.store.select(selectAllCategorizedCriticalItems),
      ),
      mergeMap(([action, criticalItems]) => {
        if (!criticalItems) {
          return of(
            SharedActions.noOperation('Critical item list failed to load'),
          );
        }

        if (this.isCriticalItemPresent(action.materialNumber, criticalItems)) {
          this.toastMessageService.showAddedToCriticalItemsGuideMessage(
            this.toastFail,
          );
          return of(
            SharedActions.noOperation(
              'Material number is already in the critical item list',
            ),
          );
        }

        const updatedCriticalItems = this.getAddedCriticalItems(
          action.materialNumber,
          criticalItems,
        );

        return this.criticalItemsService
          .updateCriticalItems(updatedCriticalItems)
          .pipe(
            tap(() => {
              this.trackAddItemToCriticalItemsCustomGuide(
                action.materialNumber,
              );
              this.toastMessageService.showAddedToCriticalItemsGuideMessage(
                this.toastSuccess,
              );
            }),
            map((categorizedCriticalItems) =>
              CriticalItemsActions.getCriticalItemsSuccess(
                categorizedCriticalItems,
              ),
            ),
            catchError((error) => of(ErrorActions.silentError(error))),
            takeUntil(
              this.actions$.pipe(
                ofType(CriticalItemsActions.refreshCriticalItems),
              ),
            ),
          );
      }),
    );
  });

  deleteCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CriticalItemsActions.deleteCriticalItem),
      concatLatestFrom(() =>
        this.store.select(selectAllCategorizedCriticalItems),
      ),
      mergeMap(([action, criticalItems]) => {
        if (!this.isCriticalItemPresent(action.materialNumber, criticalItems)) {
          return of(
            SharedActions.noOperation(
              'Material number is not in the critical item list',
            ),
          );
        }

        const updatedCriticalItems = this.getDeletedCriticalItems(
          action.materialNumber,
          criticalItems,
        );

        return this.criticalItemsService
          .updateCriticalItems(updatedCriticalItems)
          .pipe(
            tap(() =>
              this.toastMessageService.showRemovedFromCriticalItemsGuideMessage(),
            ),
            map((categorizedCriticalItems) =>
              CriticalItemsActions.getCriticalItemsSuccess(
                categorizedCriticalItems,
              ),
            ),
            catchError(() =>
              of(
                ErrorActions.silentError(
                  new Error('Critical Items Guide update unsuccessful'),
                ),
              ),
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(CriticalItemsActions.refreshCriticalItems),
              ),
            ),
          );
      }),
    );
  });

  private getAddedCriticalItems(
    materialNumber: string,
    criticalItems: CategorizedCriticalItemRecord[],
  ): CategorizedCriticalItemRecord[] {
    const unassignedCategoryIndex = criticalItems
      ? criticalItems.findIndex(
          (customCategory) => customCategory.categoryName === null,
        )
      : -1;

    if (unassignedCategoryIndex === -1) {
      return [
        ...(criticalItems ? criticalItems : []),
        {
          categoryName: null,
          materialNumbers: [materialNumber],
        },
      ];
    } else {
      const listCopy = [...criticalItems];
      const criticalItemCopy = {
        ...criticalItems[unassignedCategoryIndex],
      };
      criticalItemCopy.materialNumbers = [
        ...criticalItemCopy.materialNumbers,
        materialNumber,
      ];

      listCopy[unassignedCategoryIndex] = criticalItemCopy;
      return listCopy;
    }
  }

  private getDeletedCriticalItems(
    materialNumberToDelete: string,
    criticalItems: CategorizedCriticalItemRecord[],
  ): CategorizedCriticalItemRecord[] {
    return criticalItems.map((categorizedCriticalItem) => {
      const materialNumbers = categorizedCriticalItem.materialNumbers.filter(
        (materialNumber) => materialNumber !== materialNumberToDelete,
      );
      return {
        categoryName: categorizedCriticalItem.categoryName,
        materialNumbers,
      };
    });
  }

  private isCriticalItemPresent(
    materialNumber: string,
    criticalItems: CategorizedCriticalItemRecord[],
  ): boolean {
    return (
      !!criticalItems &&
      !criticalItems.every(
        (customCategory) =>
          !customCategory.materialNumbers.includes(materialNumber),
      )
    );
  }

  private getFlattenedCriticalItems(
    categorizedCriticalItems: CategorizedCriticalItemRecord[],
  ): string[] {
    return categorizedCriticalItems
      .map((categorizedCriticalItem) => categorizedCriticalItem.materialNumbers)
      .reduce((acc, materialNumbers) => acc.concat(materialNumbers), []);
  }

  private trackAddItemToCriticalItemsCustomGuide(materialNumber: string) {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.criticalItemsCategory,
      label: this.analyticsLabel + materialNumber,
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }
}
