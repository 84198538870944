import { Injectable, NgZone } from '@angular/core';
import { fromEvent, mergeMap, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, first, map } from 'rxjs/operators';
import { EcommerceAnalyticsService } from '../../services/ecommerce-analytics/ecommerce-analytics.service';
import { EcommerceAnalyticsActions } from './ecommerce-analytics.actions';
import {
  selectHasPermissionEnabled,
  selectSession,
} from '../session/session.selectors';
import { GoogleAnalyticsTransformerService } from '../../services/ecommerce-analytics/google-analytics-transformer-service';
import { MatDialog } from '@angular/material/dialog';
import { SharedActions } from '../shared/shared.actions';
import { EcommerceAnalyticsMaterialService } from '../../services/ecommerce-analytics/ecommerce-analytics-material.service';
import {
  selectLimitedStockPurchaseData,
  selectMaterialAnalyticInformation,
} from './ecommerce-analytics.selectors';
import { selectSponsoredSearchData } from '../search/search.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import { CustomerPermission } from '../../services/session/models/session-record';
import { selectPreviousCartMaterialQuantities } from '../cart/cart.selectors';
import { CartActions, CartQuantityUpdate } from '../cart/cart.actions';
import { MaterialQuantityDetail, MaterialQuantityLine } from '../shared/shared';
import { CriticalItemsActions } from '../critical-items/critical-items.actions';
import { CustomGuideActions } from '../custom-guide/custom-guide.actions';
import { OrderGuideActions } from '../order-guide/order-guide.actions';
import {
  ROUTER_NAVIGATED,
  ROUTER_REQUEST,
  RouterNavigatedAction,
  RouterRequestAction,
} from '@ngrx/router-store';
import { RouterStateUrl } from '../router/router-state-serializer';
import { MaterialWarningActions } from '../material-warning/material-warning.actions';
import { MaterialWarningType } from '../material-warning/material-warning';
import { selectMaterialInfoRecordState } from '../material-info/material-info.selectors';
import {
  AuxiliaryAnalyticsData,
  GAEcommerceEvent,
  GAEventName,
  GAMessageType,
  PromotionEventData,
} from '../../services/ecommerce-analytics/models/google-events';
import { MaterialRowContext } from '../material-row/models/material-row';
import { SearchActions } from '../search/search.actions';
import { EcommerceAnalyticsFacade } from './ecommerce-analytics.facade';
import { CartOfflineStorageService } from '../../../shared/services/cart-offline-storage/cart-offline-storage.service';
import { Router } from '@angular/router';
import { EnvironmentSpecificService } from '../../../shared/services/environment-specific/environment-specific.service';
import { merge } from 'lodash-es';

interface UpdateCartQuantities {
  cartAdditions: MaterialQuantityDetail[];
  cartRemovals: MaterialQuantityDetail[];
}

interface UpdateMaterialQuantities {
  materialNumber: string;
  lineAdditions: MaterialQuantityLine[];
  lineRemovals: MaterialQuantityLine[];
}

@Injectable()
export class GoogleAnalyticsEffects {
  // eslint-disable-next-line max-params
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly ngZone: NgZone,
    private readonly ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private readonly ecommerceAnalyticsService: EcommerceAnalyticsService,
    private readonly transformationService: GoogleAnalyticsTransformerService,
    private readonly materialDataService: EcommerceAnalyticsMaterialService,
    private readonly router: Router,
    private readonly cartOfflineStorageService: CartOfflineStorageService,
    private readonly environmentSpecificService: EnvironmentSpecificService,
  ) {}

  gaEvents$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EcommerceAnalyticsActions.googleAnalyticsEvent),
        map((action) => {
          this.ecommerceAnalyticsService.trackEcommerceEvent(
            action.googleAnalyticsEvent,
          );
        }),
      );
    },
    { dispatch: false },
  );

  routerRequest$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterRequestAction<RouterStateUrl>>(ROUTER_REQUEST),
      map((action) => {
        const requestUrl = action?.payload?.event?.url;
        const isFirstRequest = !action?.payload?.routerState?.url;
        const { analytics, context } =
          this.router.getCurrentNavigation()?.extras?.state ?? {};

        if (
          !isFirstRequest &&
          requestUrl?.startsWith(NaooConstants.PDP_PATH_WITH_TRAILING_SLASH)
        ) {
          const materialNumber = requestUrl.substring(
            NaooConstants.PDP_PATH_WITH_TRAILING_SLASH.length,
          );
          return EcommerceAnalyticsActions.googleSelectItemEvent(
            materialNumber,
            context ?? MaterialRowContext.ProductDetails,
            analytics,
          );
        }
        return SharedActions.noOperation('No action required');
      }),
    );
  });

  routerNavigated$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_NAVIGATED),
      mergeMap((action) => {
        const routerStateUrl = action?.payload?.routerState?.url;
        if (!routerStateUrl) {
          return [
            SharedActions.noOperation(
              'routerNavigated$: routerStateUrl is invalid',
            ),
          ];
        }
        const { analytics } =
          this.router.getCurrentNavigation()?.extras?.state ?? {};

        const actions: Action[] = [
          EcommerceAnalyticsActions.pageViewEvent(routerStateUrl),
        ];
        if (NaooConstants.CART_REVIEW_URL === routerStateUrl) {
          actions.push(EcommerceAnalyticsActions.beginCheckoutEvent());
        }
        if (
          routerStateUrl.startsWith(NaooConstants.PDP_PATH_WITH_TRAILING_SLASH)
        ) {
          const materialNumber = routerStateUrl.substring(
            NaooConstants.PDP_PATH_WITH_TRAILING_SLASH.length,
          );
          actions.push(
            EcommerceAnalyticsActions.googleViewItemEvent(
              materialNumber,
              MaterialRowContext.ProductDetails,
              analytics,
            ),
          );
        }
        return actions;
      }),
    );
  });

  pageViewEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.pageViewEvent),
      concatLatestFrom(() => [
        this.store.select(selectSession),
        this.ecommerceAnalyticsService.getClientId(),
      ]),
      map(([action, sessionRecord, clientId]) => {
        if (!sessionRecord?.activeCustomer) {
          return SharedActions.noOperation('Missing active customer record');
        }
        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGAPageViewEvent(
            action.url,
            sessionRecord,
            clientId,
          ),
        );
      }),
    );
  });

  searchFilterEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.searchFilterEvent),
      map((action) => {
        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGAFilterEvent(
            action.category,
            action.value,
          ),
        );
      }),
    );
  });

  selectShipDateEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.selectShipDateEvent),
      map((action) => {
        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGASelectShipDateEvent(
            action.selectedDate,
          ),
        );
      }),
    );
  });

  selectModalEvent$: Observable<void> = createEffect(
    () => {
      return this.dialog.afterOpened.pipe(
        map((matDialogRef) => {
          this.ngZone.run(() => {
            this.ecommerceAnalyticsFacade.trackGoogleAnalyticsEvent(
              this.transformationService.buildGAViewModalEvent(matDialogRef.id),
            );
          });
        }),
      );
    },
    { dispatch: false },
  );

  recommendationEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EcommerceAnalyticsActions.googleViewItemEvent,
        EcommerceAnalyticsActions.googleSelectItemEvent,
      ),
      mergeMap((action) => {
        return this.materialDataService
          .getLoadedMaterialRecords([action.materialNumber])
          .pipe(
            concatLatestFrom(() => [
              this.store.select(
                selectMaterialAnalyticInformation([action.materialNumber]),
              ),
            ]),
            mergeMap(([materialRecordMap, additionalMaterialInfo]) => [
              EcommerceAnalyticsActions.googleAnalyticsEvent(
                this.transformationService.buildGAEcommerceEvent({
                  eventName: this.getEventName(action),
                  additionalMaterialInfo,
                  data: {
                    analytics: action.analytics,
                    context: action.materialRowContext,
                    materialRecordMap,
                  },
                }),
              ),
              EcommerceAnalyticsActions.updateSponsoredData(
                additionalMaterialInfo.sponsoredSearchAnalytics
                  .materialSearchStatesToPersist,
              ),
            ]),
          );
      }),
    );
  });

  promotionDataEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EcommerceAnalyticsActions.promotionViewEvent,
        EcommerceAnalyticsActions.promotionClickEvent,
      ),
      mergeMap((action) => {
        const { data, externalEvent } = action;

        let promotionDataArray =
          this.transformationService.buildPromotionDataArray(data);

        if (!promotionDataArray?.length && externalEvent) {
          promotionDataArray = [{ materialNumbers: data.materials }];
        }

        const eventName = this.getEventName(action);
        return promotionDataArray.map((promotionData) => {
          if (
            promotionData.promotionType &&
            !promotionData.materialNumbers.length
          ) {
            return EcommerceAnalyticsActions.googleAnalyticsEvent(
              this.transformationService.buildNoMaterialsGAPromotionEvent(
                eventName,
                promotionData,
              ),
            );
          }
          return EcommerceAnalyticsActions.googlePromotionEvent(
            eventName,
            promotionData,
            externalEvent,
          );
        });
      }),
    );
  });

  promotionEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.googlePromotionEvent),
      mergeMap((action) => {
        const materialNumbers = action.promotionData.materialNumbers;
        return this.materialDataService
          .getLoadedMaterialRecords(materialNumbers)
          .pipe(
            concatLatestFrom(() => [
              this.store.select(
                selectMaterialAnalyticInformation(materialNumbers),
              ),
            ]),
            mergeMap(([materialRecordMap, additionalMaterialInfo]) => {
              const { eventName, promotionData, externalEvent } = action;

              const internalEvent =
                this.transformationService.buildGAEcommerceEvent({
                  eventName,
                  additionalMaterialInfo,
                  data: {
                    materialRecordMap,
                    promotionData,
                  },
                });

              const combinedEvent = externalEvent
                ? merge({}, internalEvent, externalEvent)
                : internalEvent;

              return [
                EcommerceAnalyticsActions.googleAnalyticsEvent(combinedEvent),
                EcommerceAnalyticsActions.updateSponsoredData(
                  additionalMaterialInfo.sponsoredSearchAnalytics
                    .materialSearchStatesToPersist,
                ),
              ];
            }),
          );
      }),
    );
  });

  sponsoredSearchResultsEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.sponsoredSearchResultsEvent),
      concatLatestFrom(() => this.store.select(selectSponsoredSearchData)),
      map(([_, sponsoredSearchData]) => {
        if (
          !sponsoredSearchData.hasBanners &&
          !sponsoredSearchData.hasMaterials
        ) {
          return SharedActions.noOperation(
            'Search Results Lack SponsoredRecommendations',
          );
        }

        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGASponsoredSearchResultsEvent(
            sponsoredSearchData,
          ),
        );
      }),
    );
  });

  cartCheckoutEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EcommerceAnalyticsActions.checkoutEvent,
        EcommerceAnalyticsActions.beginCheckoutEvent,
        EcommerceAnalyticsActions.purchaseEvent,
      ),
      concatLatestFrom(() => this.materialDataService.getLoadedCart()),
      mergeMap(([action, cartEntityState]) => {
        const materialNumbers = cartEntityState.materials.ids as string[];
        const hasPendingOrders =
          EcommerceAnalyticsActions.purchaseEvent.type === action.type
            ? action.hasPendingOrders
            : undefined;

        return this.materialDataService
          .getCartAnalyticInformation(materialNumbers)
          .pipe(
            mergeMap((cartAnalyticInformation) => [
              EcommerceAnalyticsActions.googleAnalyticsEvent(
                this.transformationService.buildGACartEvent(
                  this.getEventName(action),
                  cartEntityState,
                  cartAnalyticInformation,
                  hasPendingOrders,
                ),
              ),
              EcommerceAnalyticsActions.updateSponsoredData(
                cartAnalyticInformation.sponsoredSearchAnalytics
                  .materialSearchStatesToPersist,
              ),
            ]),
          );
      }),
    );
  });

  materialAdjustmentEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EcommerceAnalyticsActions.googleAddCartEvent,
        EcommerceAnalyticsActions.googleRemoveCartEvent,
      ),
      mergeMap((action) => {
        const materialNumbers = action.materialQuantities.map(
          (materialQuantity) => materialQuantity.materialNumber,
        );
        return this.materialDataService
          .getMaterialAdjustmentAnalyticInformation(
            materialNumbers,
            0,
            action.materialQuantities,
          )
          .pipe(
            mergeMap((materialAdjustmentAnalyticInformation) => {
              const googleAddCartEventAction =
                EcommerceAnalyticsActions.googleAddCartEvent.type ===
                action.type
                  ? action
                  : undefined;

              return [
                EcommerceAnalyticsActions.googleAnalyticsEvent(
                  this.transformationService.buildGACartItemAdjustmentEvent(
                    this.getEventName(action),
                    materialAdjustmentAnalyticInformation,
                    {
                      analytics: action.analytics,
                      context: googleAddCartEventAction?.context,
                    },
                  ),
                ),
                EcommerceAnalyticsActions.updateSponsoredData(
                  materialAdjustmentAnalyticInformation.sponsoredSearchAnalytics
                    .materialSearchStatesToPersist,
                ),
              ];
            }),
          );
      }),
    );
  });

  savedCartEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.googleSavedCartEvent),
      concatLatestFrom(() =>
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.OrderSubmission),
        ),
      ),
      map(([action, hasSubmissionRole]) => {
        let eventName = GAEventName.MoveSavedCart;
        let linkText = 'Move to Saved Carts';
        if (action.activateSavedCart) {
          eventName = GAEventName.ActivateSavedCart;
          linkText = 'Activate';
        }

        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGASavedCartEvent(
            eventName,
            hasSubmissionRole,
            linkText,
          ),
        );
      }),
    );
  });

  loginEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.googleCustomerLoginEvent),
      map((action) => {
        const eventName = action.isLoggingIn
          ? GAEventName.Login
          : GAEventName.Logout;
        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildBaseGAEvent(eventName),
        );
      }),
    );
  });

  updateCartEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CartActions.clearCartMaterials,
        CartActions.updateCartQuantities,
        CartActions.deleteCartMaterial,
        CartActions.restoreCartMaterial,
      ),
      concatLatestFrom(() => [
        this.store.select(selectPreviousCartMaterialQuantities),
      ]),
      mergeMap(([action, previousCartMaterialQuantities]) => {
        switch (action.type) {
          case CartActions.clearCartMaterials.type:
            return this.updateCartEvent$clearCartMaterials(
              previousCartMaterialQuantities,
            );
          case CartActions.deleteCartMaterial.type:
            return this.updateCartEvent$deleteCartMaterial(
              action,
              previousCartMaterialQuantities,
            );
          case CartActions.restoreCartMaterial.type:
            return this.updateCartEvent$restoreCartMaterial(
              action,
              previousCartMaterialQuantities,
            );
          case CartActions.updateCartQuantities.type:
            return this.updateCartEvent$updateCartQuantities(
              action,
              previousCartMaterialQuantities,
            );
        }
      }),
    );
  });

  addToWishListEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CustomGuideActions.addCustomGuideMaterials,
        CriticalItemsActions.addCriticalItem,
        OrderGuideActions.addItemToOrderGuide,
      ),
      mergeMap((action) => {
        const materialNumbers =
          CustomGuideActions.addCustomGuideMaterials.type === action.type
            ? action.materialNumbers
            : [action.materialNumber];

        return this.materialDataService
          .getMaterialAdjustmentAnalyticInformation(materialNumbers)
          .pipe(
            mergeMap((materialAdjustmentAnalyticInformation) => {
              return [
                EcommerceAnalyticsActions.googleAnalyticsEvent(
                  this.transformationService.buildGAWishlistEvent(
                    GAEventName.AddToWishlist,
                    materialAdjustmentAnalyticInformation,
                    this.getGuideType(action),
                  ),
                ),
                EcommerceAnalyticsActions.updateSponsoredData(
                  materialAdjustmentAnalyticInformation.sponsoredSearchAnalytics
                    .materialSearchStatesToPersist,
                ),
              ];
            }),
          );
      }),
    );
  });

  viewItemListEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.googleViewItemListEvent),
      mergeMap((action) =>
        this.materialDataService
          .getMaterialAdjustmentAnalyticInformation(
            action.materialNumbers,
            action.data?.startIndex,
          )
          .pipe(
            mergeMap((materialAdjustmentAnalyticInformation) => {
              if (!action.materialNumbers?.length) {
                return [
                  EcommerceAnalyticsActions.googleAnalyticsEvent(
                    this.transformationService.buildGAViewItemListEventForNoResults(
                      action.data?.analytics?.searchText,
                    ),
                  ),
                ];
              }
              const response = [];
              this.transformationService
                .buildGAViewItemListEvents(
                  GAEventName.ViewItemList,
                  materialAdjustmentAnalyticInformation,
                  action.context,
                  action.data?.analytics,
                )
                .forEach((event) =>
                  response.push(
                    EcommerceAnalyticsActions.googleAnalyticsEvent(event),
                  ),
                );
              response.push(
                EcommerceAnalyticsActions.updateSponsoredData(
                  materialAdjustmentAnalyticInformation.sponsoredSearchAnalytics
                    .materialSearchStatesToPersist,
                ),
              );
              return response;
            }),
          ),
      ),
    );
  });

  outOfStockEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialWarningActions.displayedAnalyticsMaterialWarning),
      mergeMap((action) => {
        return this.store
          .select(selectMaterialInfoRecordState(action.materialNumber))
          .pipe(
            first(),
            map((material) => {
              if (MaterialWarningType.NoStock !== action.materialWarningType) {
                return SharedActions.noOperation('No action required');
              }
              return EcommerceAnalyticsActions.googleAnalyticsEvent(
                this.transformationService.buildGANoStockEvent(
                  GAEventName.ViewOutOfStock,
                  action.materialNumber,
                  material?.record?.description?.en,
                ),
              );
            }),
          );
      }),
    );
  });

  outOfStockPurchaseEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.submitCartEvent),
      concatLatestFrom(() => this.store.select(selectLimitedStockPurchaseData)),
      mergeMap(([_, limitedStockPurchaseData]) => {
        return limitedStockPurchaseData.materialInfoRecordStates.map((record) =>
          EcommerceAnalyticsActions.googleAnalyticsEvent(
            this.transformationService.buildGANoStockPurchaseEvent(
              record.materialNumber,
              record.record?.description?.en,
              limitedStockPurchaseData.routeDate,
            ),
          ),
        );
      }),
    );
  });

  loyaltyCheckRequestEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.redeemCheckRequest),
      map((action) =>
        EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildLoyaltyCheckRequestEvent(
            action.numberOfChecks,
          ),
        ),
      ),
    );
  });

  searchRequestEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.submitSearch),
      map((action) =>
        EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildSearchRequestEvent(
            action.searchQuery,
          ),
        ),
      ),
    );
  });

  addToCartConflict$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.addToCartConflict),
      map((action) => {
        return EcommerceAnalyticsActions.googleAnalyticsEvent(
          this.transformationService.buildGAAddToCartConflictEvent(
            action.modalName,
            action.addToCartType,
            action.index,
            action.actionId,
            action.ctaText,
          ),
        );
      }),
    );
  });

  contentPageEvent$: Observable<Action> = createEffect(() => {
    return fromEvent(window, 'message').pipe(
      filter(
        (event: MessageEvent) =>
          event.origin ===
            this.environmentSpecificService.getEcomContentUrl() &&
          event.data?.['type'] === GAMessageType.ContentAnalytics,
      ),
      map((event: MessageEvent) => {
        const externalEvent = event.data?.['data'] as GAEcommerceEvent;
        const materialNumbers = externalEvent?.ecommerce?.items?.map(
          (item) => item.item_id,
        );
        const data: PromotionEventData = { materials: materialNumbers ?? [] };
        switch (externalEvent.event) {
          case GAEventName.ViewPromotion:
            return EcommerceAnalyticsActions.promotionViewEvent(
              data,
              externalEvent,
            );
          case GAEventName.SelectPromotion:
            return EcommerceAnalyticsActions.promotionClickEvent(
              data,
              externalEvent,
            );
          default:
            return SharedActions.noOperation(
              `contentPageEvent$ unsupported event "${externalEvent.event}"`,
            );
        }
      }),
    );
  });

  private updateCartEvent$clearCartMaterials(
    previousCartMaterialQuantities: MaterialQuantityDetail[],
  ): Action[] {
    return [
      EcommerceAnalyticsActions.googleRemoveCartEvent(
        previousCartMaterialQuantities,
      ),
    ];
  }

  private updateCartEvent$deleteCartMaterial(
    action: { materialNumber: string },
    previousCartMaterialQuantities: MaterialQuantityDetail[],
  ): Action[] {
    const materialQuantityDetail = this.getMaterialQuantityDetail(
      previousCartMaterialQuantities,
      action.materialNumber,
    );
    return [
      EcommerceAnalyticsActions.googleRemoveCartEvent(
        materialQuantityDetail ? [materialQuantityDetail] : [],
      ),
    ];
  }

  private updateCartEvent$restoreCartMaterial(
    action: {
      materialNumber: string;
      context: MaterialRowContext;
      analytics?: AuxiliaryAnalyticsData;
    },
    previousCartMaterialQuantities: MaterialQuantityDetail[],
  ): Action[] {
    const materialQuantityDetail = this.getMaterialQuantityDetail(
      previousCartMaterialQuantities,
      action.materialNumber,
    );
    return [
      EcommerceAnalyticsActions.googleAddCartEvent(
        materialQuantityDetail ? [materialQuantityDetail] : [],
        action.context,
        action.analytics,
      ),
    ];
  }

  private updateCartEvent$updateCartQuantities(
    action: {
      cartQuantityUpdates: CartQuantityUpdate[];
      analytics?: AuxiliaryAnalyticsData;
    },
    previousCartMaterialQuantities: MaterialQuantityDetail[],
  ): Action[] {
    const updateCartQuantities = this.buildUpdateCartQuantities(
      action.cartQuantityUpdates,
      previousCartMaterialQuantities,
    );

    const dispatchedActions: Action[] = [];
    if (updateCartQuantities.cartAdditions.length) {
      dispatchedActions.push(
        EcommerceAnalyticsActions.googleAddCartEvent(
          updateCartQuantities.cartAdditions,
          action.cartQuantityUpdates?.[0].context,
          action.analytics,
        ),
      );
    }
    if (updateCartQuantities.cartRemovals.length) {
      dispatchedActions.push(
        EcommerceAnalyticsActions.googleRemoveCartEvent(
          updateCartQuantities.cartRemovals,
          action.analytics,
        ),
      );
    }
    if (!dispatchedActions.length) {
      dispatchedActions.push(
        SharedActions.noOperation('No update cart analytics required'),
      );
    }
    return dispatchedActions;
  }

  protected getEventName(action: Action): GAEventName {
    switch (action.type) {
      case EcommerceAnalyticsActions.googleSelectItemEvent.type:
        return GAEventName.SelectItem;
      case EcommerceAnalyticsActions.googleViewItemEvent.type:
        return GAEventName.ViewItem;
      case EcommerceAnalyticsActions.promotionViewEvent.type:
        return GAEventName.ViewPromotion;
      case EcommerceAnalyticsActions.promotionClickEvent.type:
        return GAEventName.SelectPromotion;
      case EcommerceAnalyticsActions.checkoutEvent.type:
        return GAEventName.ViewCart;
      case EcommerceAnalyticsActions.beginCheckoutEvent.type:
        return GAEventName.BeginCheckout;
      case EcommerceAnalyticsActions.purchaseEvent.type:
        return GAEventName.Purchase;
      case EcommerceAnalyticsActions.googleAddCartEvent.type:
        return GAEventName.AddToCart;
      case EcommerceAnalyticsActions.googleRemoveCartEvent.type:
        return GAEventName.RemoveFromCart;
      default:
        return undefined;
    }
  }

  protected getGuideType(action: Action): string {
    switch (action.type) {
      case CustomGuideActions.addCustomGuideMaterials.type:
        return 'custom guide';
      case CriticalItemsActions.addCriticalItem.type:
        return 'critical items guide';
      case OrderGuideActions.addItemToOrderGuide.type:
        return 'order guide';
      default:
        return undefined;
    }
  }

  protected getMaterialQuantityDetail(
    previousCartMaterialQuantities: MaterialQuantityDetail[],
    materialNumber: string,
  ): MaterialQuantityDetail {
    const syncedMaterialQuantityDetail = previousCartMaterialQuantities.find(
      (materialQuantityDetail) =>
        materialNumber === materialQuantityDetail.materialNumber,
    );

    if (syncedMaterialQuantityDetail) {
      return syncedMaterialQuantityDetail;
    }

    return this.cartOfflineStorageService.getMaterialQuantityDetailOffline(
      materialNumber,
    );
  }

  private buildUpdateCartQuantities(
    changedMaterials: CartQuantityUpdate[],
    previousMaterials: MaterialQuantityDetail[],
  ): UpdateCartQuantities {
    const cartAdditions: MaterialQuantityDetail[] = [];
    const cartRemovals: MaterialQuantityDetail[] = [];

    const previousMaterialsMap = new Map(
      previousMaterials.map((i) => [i?.materialNumber, i]),
    );

    let additionIndex = 0;
    let removalIndex = 0;
    changedMaterials?.forEach((cartQuantityUpdate) => {
      const updateMaterialQuantities = this.buildUpdateMaterialQuantities(
        cartQuantityUpdate,
        previousMaterialsMap,
        additionIndex,
        removalIndex,
      );

      if (updateMaterialQuantities.lineAdditions.length) {
        additionIndex += updateMaterialQuantities.lineAdditions.length;
        cartAdditions.push({
          materialNumber: updateMaterialQuantities.materialNumber,
          lines: updateMaterialQuantities.lineAdditions,
        });
      }

      if (updateMaterialQuantities.lineRemovals.length) {
        removalIndex += updateMaterialQuantities.lineAdditions.length;
        cartRemovals.push({
          materialNumber: updateMaterialQuantities.materialNumber,
          lines: updateMaterialQuantities.lineRemovals,
        });
      }
    });

    return {
      cartAdditions,
      cartRemovals,
    };
  }

  private buildUpdateMaterialQuantities(
    cartQuantityUpdate: CartQuantityUpdate,
    previousMaterialsMap: Map<string, MaterialQuantityDetail>,
    additionIndex: number,
    removalIndex: number,
  ): UpdateMaterialQuantities {
    const lineAdditions: MaterialQuantityLine[] = [];
    const lineRemovals: MaterialQuantityLine[] = [];
    const materialNumber = cartQuantityUpdate.materialNumber;

    const previousMaterial =
      previousMaterialsMap.get(materialNumber) ??
      this.cartOfflineStorageService.getMaterialQuantityDetailOffline(
        materialNumber,
      );

    cartQuantityUpdate.lines.forEach((changedLine) => {
      const previousQuantity =
        previousMaterial?.lines?.find((line) => line.uom === changedLine.uom)
          ?.quantity || 0;

      if (changedLine.quantity > previousQuantity) {
        lineAdditions.push({
          index: additionIndex + 1 + lineAdditions.length,
          uom: changedLine.uom,
          quantity: changedLine.quantity - previousQuantity,
        });
      }

      if (previousQuantity > changedLine.quantity) {
        lineRemovals.push({
          index: removalIndex + 1 + lineRemovals.length,
          uom: changedLine.uom,
          quantity: previousQuantity - changedLine.quantity,
        });
      }
    });

    return {
      materialNumber: cartQuantityUpdate.materialNumber,
      lineAdditions,
      lineRemovals,
    };
  }
}
