<naoo-modal-header
  class="credit-card-header"
  [title]="'CREDIT_CARD.TITLE' | translate"
  (closeModalEmitter)="closeModal()"
  [showCloseButton]="true"
></naoo-modal-header>
<mat-dialog-content class="content-container">
  <img
    class="credit-card-icon"
    alt=""
    src="assets/images/credit-card-alert.svg"
  />
  <div class="description-container">
    <h2 class="sub-header">{{ 'CREDIT_CARD.SUB_HEADER' | translate }}</h2>
    <div class="description">{{ 'CREDIT_CARD.DESCRIPTION' | translate }}</div>
  </div>
  <mat-dialog-actions class="button-container">
    <button
      mat-button
      [mat-dialog-close]="true"
      [attr.aria-label]="'CREDIT_CARD.SECONDARY_BUTTON' | translate"
      class="secondary-button large-button"
    >
      {{ 'CREDIT_CARD.SECONDARY_BUTTON' | translate }}
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
      [attr.aria-label]="'CREDIT_CARD.PRIMARY_BUTTON' | translate"
      (click)="redirectToOnlinePayment()"
      class="primary-button large-button"
    >
      {{ 'CREDIT_CARD.PRIMARY_BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
