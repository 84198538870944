import moment, { Moment } from 'moment';
import {
  ExpressDeliveryWindow,
  ExpressScheduleRecord,
} from '../../../../core/services/express-schedules/models/express-schedule-record';
import { FulfillmentType } from '../../../../core/services/cart/models/cart-record';
import { OrderFulfillmentViewModel } from '../../../../core/store/order-method-modal/order-method-modal-view-model';
import { DeliveryScheduleEntryRecord } from '../../../../core/services/delivery-schedule/models/delivery-schedules-record';
import { StoreAddress } from '../../../../core/services/store/model/store-record';

const dateFormat = 'YYYY-MM-DD';

export function getDefaultDate(availableDates: string[]): Moment | undefined {
  return availableDates?.length ? moment(availableDates?.[0]) : undefined;
}

export function getAddressLineTwo(address: StoreAddress): string {
  if (!address) {
    return '';
  }
  const shortPostalCode = address.postalCode.substring(0, 5);

  return `${address.city}, ${address.state} ${shortPostalCode}`;
}

export function getFormattedPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) {
    return '';
  }
  const matcher = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  return matcher ? '(' + matcher[1] + ') ' + matcher[2] + '-' + matcher[3] : '';
}

export function getFulfillmentText(fulfillmentType: FulfillmentType): string {
  switch (fulfillmentType) {
    case FulfillmentType.PICKUP: {
      return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.PICKUP';
    }
    case FulfillmentType.EXPRESS: {
      return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.EXPRESS';
    }
    case FulfillmentType.TRUCK: {
      return 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.TRUCK';
    }
    default: {
      return '';
    }
  }
}

export function momentExistsInAvailableDates(
  moment: Moment,
  availableDates: string[],
): boolean {
  const dateString = moment?.format(dateFormat);
  return (
    dateString &&
    !!availableDates?.find((deliveryDate) => deliveryDate === dateString)
  );
}

export function getDefaultExpressDeliveryWindow(
  viewModel: OrderFulfillmentViewModel,
  fulfillmentType: FulfillmentType,
  selectedDate: Moment,
): ExpressDeliveryWindow | undefined {
  if (FulfillmentType.EXPRESS != fulfillmentType) {
    return undefined;
  }
  if (userHasChangedDate(viewModel, selectedDate)) {
    return getSelectedExpressScheduleRecord(viewModel, selectedDate)
      ?.deliveryWindows?.[0];
  }
  return (
    viewModel?.selectedExpressDeliveryWindow ||
    getSelectedExpressScheduleRecord(viewModel, selectedDate)
      ?.deliveryWindows?.[0]
  );
}

export function getSelectedExpressScheduleRecord(
  viewModel: OrderFulfillmentViewModel,
  selectedDate: Moment,
): ExpressScheduleRecord | undefined {
  return viewModel?.expressScheduleMap?.get(selectedDate?.format(dateFormat));
}

export function getSelectedDeliveryScheduleEntryRecord(
  viewModel: OrderFulfillmentViewModel,
  selectedDate: Moment,
): DeliveryScheduleEntryRecord | undefined {
  return viewModel?.deliveryScheduleMap?.get(selectedDate?.format(dateFormat));
}

export function isDateStepCompleted(
  fulfillmentType: FulfillmentType,
  selectedDate: Moment,
  selectedExpressDeliveryWindow?: ExpressDeliveryWindow,
  selectedStoreId?: string,
): boolean {
  if (FulfillmentType.PICKUP === fulfillmentType) {
    return !!selectedStoreId && !!selectedDate;
  } else if (FulfillmentType.EXPRESS === fulfillmentType) {
    return !!selectedDate && !!selectedExpressDeliveryWindow;
  }
  return !!selectedDate;
}

function userHasChangedDate(
  viewModel: OrderFulfillmentViewModel,
  selectedDate: Moment,
): boolean {
  return !!selectedDate && !viewModel?.selectedDate?.isSame(selectedDate);
}
