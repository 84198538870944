import {
  expressScheduleInitialState,
  ExpressSchedulesState,
  ExpressSchedulesStatus,
  expressScheduleStateAdapter,
} from './express-schedules.state';
import { ExpressSchedulesActions } from './express-schedules.actions';
import { ExpressScheduleRecord } from '../../services/express-schedules/models/express-schedule-record';
import { createReducer, on } from '@ngrx/store';

export const expressSchedulesReducer = createReducer(
  expressScheduleInitialState,
  on(
    ExpressSchedulesActions.loadExpressSchedules,
    (state): ExpressSchedulesState => loadExpressSchedules(state),
  ),
  on(
    ExpressSchedulesActions.getExpressSchedules,
    (state): ExpressSchedulesState => getExpressSchedules(state),
  ),
  on(
    ExpressSchedulesActions.getExpressSchedulesSuccess,
    (state, action): ExpressSchedulesState =>
      getExpressSchedulesSuccess(state, action),
  ),
  on(
    ExpressSchedulesActions.getExpressSchedulesFailure,
    (): ExpressSchedulesState => getExpressSchedulesFailure(),
  ),
  on(
    ExpressSchedulesActions.refreshExpressSchedules,
    (): ExpressSchedulesState => expressScheduleInitialState,
  ),
);

function loadExpressSchedules(
  state: ExpressSchedulesState,
): ExpressSchedulesState {
  if (!state.status || ExpressSchedulesStatus.Error === state.status) {
    return {
      ...state,
      status: ExpressSchedulesStatus.Queued,
    };
  }
  return state;
}

function getExpressSchedules(
  state: ExpressSchedulesState,
): ExpressSchedulesState {
  if (ExpressSchedulesStatus.Queued === state.status) {
    return {
      ...state,
      status: ExpressSchedulesStatus.Requested,
    };
  }
  return state;
}

function getExpressSchedulesSuccess(
  state: ExpressSchedulesState,
  action: { expressSchedules: ExpressScheduleRecord[] },
): ExpressSchedulesState {
  return {
    ...state,
    records: expressScheduleStateAdapter.setAll(
      action.expressSchedules.map((expressSchedule) => expressSchedule),
      expressScheduleInitialState.records,
    ),
    status: ExpressSchedulesStatus.Success,
  };
}

function getExpressSchedulesFailure(): ExpressSchedulesState {
  return {
    records: expressScheduleStateAdapter.getInitialState(),
    status: ExpressSchedulesStatus.Error,
  };
}
