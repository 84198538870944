import { RowSanitizer } from '../../parse-spreadsheet/row-sanitizer';

export class ImportOrderSanitizer implements RowSanitizer {
  static readonly UNTRUSTED_CHARACTERS = new RegExp(/[^A-Za-z0-9.,+-]/gi);

  sanitize(row: string[]): string[] {
    if (row !== null) {
      return row.map((value) => {
        if (value) {
          return value.replace(ImportOrderSanitizer.UNTRUSTED_CHARACTERS, '');
        }
        return value;
      });
    } else {
      return [];
    }
  }
}
