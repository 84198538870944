import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { OfflineModeActions } from './offline-mode.actions';
import { Observable } from 'rxjs';
import { selectIsOffline, selectIsOnline } from './offline-mode.selectors';

@Injectable({ providedIn: 'root' })
export class OfflineModeFacade {
  constructor(private store: Store) {}

  startCheckingOffline() {
    this.store.dispatch(OfflineModeActions.startOfflineModeCheck());
  }

  getIsOffline(): Observable<boolean> {
    return this.store.select(selectIsOffline);
  }

  getIsOnline(): Observable<boolean> {
    return this.store.select(selectIsOnline);
  }
}
