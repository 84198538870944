import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooIcon } from '../services/images/naoo-icon.service';

@Component({
  selector: 'naoo-preload-images',
  templateUrl: './preload-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloadImagesComponent {
  @Input() imagesToPreload: string[];
  @Input() svgsToPreload: NaooIcon[];
}
