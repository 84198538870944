<article class="error-state">
  <img class="error-icon" alt="{{ alt }}" src="{{ errorImageSource }}" />
  <h2 class="error-title">{{ errorTitle }}</h2>
  <div class="error-subtitle">{{ errorSubtitle }}</div>
  <div class="error-buttons" *ngIf="showErrorButton">
    <button
      class="naoo-button primary-button large-button button-nav-back"
      (click)="navigateBack()"
    >
      {{ errorButtonTitle }}
    </button>
  </div>
</article>
