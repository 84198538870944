import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderHeightService {
  private headerHeight$ = new BehaviorSubject(0);

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  triggerHeaderHeightChange() {
    this.calculateHeaderHeight();
  }

  observeHeaderHeight(): Observable<number> {
    return this.headerHeight$.asObservable().pipe(distinctUntilChanged());
  }

  getHeaderHeight(): number {
    this.calculateHeaderHeight();
    return this.headerHeight$.getValue();
  }

  private calculateHeaderHeight() {
    const headerElement = this._document.getElementsByTagName('naoo-header')[0];
    if (headerElement) {
      this.headerHeight$.next(headerElement.clientHeight);
    }
  }
}
