<div
  *ngIf="!(dismissed$ | async) && isVisible"
  class="banner-message-container"
>
  <div class="messages" [ngClass]="{ expanded: !(collapsed$ | async) }">
    <div *ngIf="collapsed$ | async" class="collapsed-messages">
      {{ 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES' | translate }}
    </div>
    <div *ngIf="!(collapsed$ | async)" class="expanded-messages">
      <mat-icon class="important-icon">error</mat-icon>
      <div class="message-sources">
        <div
          class="system-notifications-container message-source"
          *ngIf="notificationMessage?.title || notificationMessage?.body"
        >
          <div *ngIf="notificationMessage.title" class="msg-title">
            {{ notificationMessage.title | translate }}
          </div>
          <span class="msg-body"
            >{{ notificationMessage.body | translate }}&#32;</span
          >
          <a *ngIf="callback" (click)="executeCallback()">{{
            'NOTIFICATION_BANNER.RETRY_TEXT' | translate
          }}</a>
          <a
            id="router-link"
            *ngIf="notificationMessage.route"
            [routerLink]="notificationMessage.route.routerLink"
            [queryParams]="notificationMessage.route.queryParams"
            >{{ notificationMessage.route.text | translate }}</a
          >
        </div>
        <div
          *ngFor="let customerMessage of customerMessages"
          class="customer-message message-source"
        >
          <div class="msg-title">
            {{ customerMessage.title | stringdefaulter }}
          </div>
          <span class="msg-body"
            >{{ customerMessage.bodyText | stringdefaulter }}&#32;</span
          >
          <a
            *ngIf="customerMessage.linkUrl"
            [href]="customerMessage.linkUrl | stringdefaulter"
            target="_blank"
          >
            <span
              >{{
                customerMessage.linkLabel || customerMessage.linkUrl
                  | stringdefaulter
              }}&nbsp;<mat-icon
                class="open-in-new-window"
                [attr.aria-hidden]="true"
                >open_in_new</mat-icon
              >
            </span>
          </a>
        </div>
      </div>
    </div>
    <button
      class="toggle-button"
      (click)="toggle()"
      [attr.aria-label]="
        (!(collapsed$ | async)
          ? 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES_LABEL_COLLAPSE'
          : 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES_LABEL_EXPAND'
        ) | translate
      "
    >
      <mat-icon
        class="toggle-icon"
        [@rotate]="(collapsed$ | async) ? ROTATE_180 : ROTATE_DEFAULT"
        [attr.aria-hidden]="true"
        >expand_less</mat-icon
      >
    </button>
  </div>
  <div class="dismiss-button-container">
    <button
      *ngIf="!(collapsed$ | async)"
      mat-button
      disableRipple
      class="dismiss-button"
      (click)="dismiss()"
    >
      {{ 'NOTIFICATION_BANNER.DISMISS' | translate }}
    </button>
  </div>
</div>
