import {
  InventoryAvailabilityRecord,
  UnitsAvailability,
} from '../../services/inventory-availability/models/inventory-availability-record';
import { CartMaterialState, FocusedMaterialState } from '../cart/cart.state';
import {
  MaterialWarning,
  MaterialWarningMessageParam,
  MaterialWarningType,
} from './material-warning';
import { MaterialWarningEntityState } from './material-warning.state';
import { MaterialWarningFactory } from './material-warning-factory';
import { filterNonEmptyCartLines } from '../../../shared/utilities/cart-material-utilities';
import {
  isAvailableByUom,
  MaterialAvailabilityRecord,
  StockType,
} from '../../services/material-availability/model/material-availabilities-record';
import {
  getNextPoDate,
  getNextStoreDeliveryTime,
  getUnitsAvailable,
} from '../../../shared/utilities/inventory-utilities';
import { NaooConstants } from '../../../shared/NaooConstants';
import {
  getQtyPerMasterSellUnitFromMaterialRecord,
  MaterialRecord,
} from '../../services/material-info/models/materials-info-record';
import { MaterialCutoffEntryRecord } from '../../services/material-cutoff/models/material-cutoff-record';
import { MaterialCutoffRecordState } from '../material-cutoff/material-cutoff.state';
import { Dictionary } from '@ngrx/entity';
import {
  CurrentSystem,
  Locale,
  SessionActiveCustomer,
} from '../../services/session/models/session-record';
import moment from 'moment-timezone';
import { SalesCriticalItem } from '../../services/sales-critical-items/model/sales-critical-items';
import {
  doesQuantityExceedMaxHardStop,
  doesQuantityExceedMaxSoftStop,
  doesQuantityHaveMinSoftStop,
} from '../sales-critical-items/sales-critical-items.util';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';
import { getDoesQuantityExceedMaxAllocationHardStop } from '../entitlement/entitlement.util';
import { OverallocatedMaterialDetail } from '../entitlement/entitlement.state';
import { formatDate } from '@angular/common';
import { dateFormats } from '../../../shared/utilities/date-utilities';
import isMygfsOrSap = CurrentSystem.isMygfsOrSap;
import { FulfillmentType } from '../../services/cart/models/cart-record';

const salesCriticalItemsWarnings = [
  MaterialWarningType.MaximumQuantityHardStop,
  MaterialWarningType.MaximumQuantitySoftStop,
  MaterialWarningType.MinimumQuantitySoftStop,
];

const defaultMaterialWarningEntityState: MaterialWarningEntityState = {
  materialNumber: undefined,
  isOpen: false,
  acknowledgedWarnings: [],
  displayedAnalyticSentWarnings: [],
  shouldHideWarning: false,
};

export interface MaterialWarningProperties {
  warningType: MaterialWarningType;
  messageParam?: MaterialWarningMessageParam;
}

export function transformWarning(
  materialNumber: string,
  warningProperties: MaterialWarningProperties,
  state: MaterialWarningEntityState = defaultMaterialWarningEntityState,
): MaterialWarning | undefined {
  if (!warningProperties.warningType) {
    return undefined;
  }
  if (
    state.shouldHideWarning &&
    warningProperties.warningType === MaterialWarningType.MissedCutoff
  ) {
    return undefined;
  }

  const hasAcknowledged = state.acknowledgedWarnings.includes(
    warningProperties.warningType,
  );

  return MaterialWarningFactory.get(
    materialNumber,
    warningProperties.warningType,
    state.isOpen || !hasAcknowledged,
    state.displayedAnalyticSentWarnings.includes(warningProperties.warningType),
    hasAcknowledged,
    warningProperties.messageParam,
  );
}

export interface MaterialWarningPropertiesContext {
  materialNumber: string;
  cartMaterial: CartMaterialState;
  materialRecord: MaterialRecord;
  availability: MaterialAvailabilityRecord;
  inventory: InventoryAvailabilityRecord;
  shouldConsiderInventory: boolean;
  routeDate: Date;
  focusedMaterialState: FocusedMaterialState;
  hasOpenSpecialOrders: boolean;
  cutoffs: Dictionary<MaterialCutoffRecordState>;
  activeCustomer: SessionActiveCustomer;
  salesCriticalItem: SalesCriticalItem;
  entitlementMaterialDetail: EntitlementMaterialDetail;
  overallocatedMaterialDetail: OverallocatedMaterialDetail;
  fulfillmentType: FulfillmentType;
}

export function transformMaterialWarningProperties(
  warningPropertiesContext: Partial<MaterialWarningPropertiesContext>,
): MaterialWarningProperties {
  const {
    materialNumber,
    cartMaterial,
    materialRecord,
    availability,
    inventory,
    shouldConsiderInventory,
    routeDate,
    focusedMaterialState,
    hasOpenSpecialOrders,
    cutoffs,
    activeCustomer,
    salesCriticalItem,
    entitlementMaterialDetail,
    overallocatedMaterialDetail,
    fulfillmentType,
  } = warningPropertiesContext;
  const warningType = transformMaterialWarningType({
    materialNumber,
    cartMaterial,
    materialRecord,
    availability,
    inventory,
    shouldConsiderInventory,
    routeDate,
    focusedMaterialState,
    hasOpenSpecialOrders,
    cutoffRecord: availability?.cutoffCode
      ? cutoffs[availability.cutoffCode]?.record
      : undefined,
    activeCustomer,
    salesCriticalItem,
    entitlementMaterialDetail,
    overallocatedMaterialDetail,
  });

  return {
    warningType,
    messageParam: transformMaterialWarningValues(
      warningType,
      materialRecord,
      availability,
      inventory,
      salesCriticalItem,
      entitlementMaterialDetail,
      overallocatedMaterialDetail,
      activeCustomer?.locale,
      fulfillmentType,
    ),
  };
}

interface MaterialWarningContext {
  materialNumber: string;
  cartMaterial: CartMaterialState;
  materialRecord: MaterialRecord;
  availability: MaterialAvailabilityRecord;
  inventory: InventoryAvailabilityRecord;
  shouldConsiderInventory: boolean;
  routeDate: Date;
  focusedMaterialState: FocusedMaterialState;
  hasOpenSpecialOrders: boolean;
  cutoffRecord: MaterialCutoffEntryRecord;
  activeCustomer: SessionActiveCustomer;
  salesCriticalItem: SalesCriticalItem;
  entitlementMaterialDetail: EntitlementMaterialDetail;
  overallocatedMaterialDetail: OverallocatedMaterialDetail;
  cartMaterialQuantity: { case: number; unit: number };
  cartEntityState: CartMaterialState;
}

type WarningTypeProcessor = (
  context: Partial<MaterialWarningContext>,
) => MaterialWarningType | undefined;

const overAllocation: WarningTypeProcessor = ({
  entitlementMaterialDetail,
  overallocatedMaterialDetail,
  cartMaterialQuantity,
}) => {
  if (
    getDoesQuantityExceedMaxAllocationHardStop(
      entitlementMaterialDetail,
      overallocatedMaterialDetail,
      cartMaterialQuantity.case,
    )
  ) {
    return MaterialWarningType.OverAllocation;
  }
};

const maximumHardStop: WarningTypeProcessor = ({
  salesCriticalItem,
  cartMaterialQuantity,
}) => {
  if (
    doesQuantityExceedMaxHardStop(salesCriticalItem, cartMaterialQuantity.case)
  ) {
    return MaterialWarningType.MaximumQuantityHardStop;
  }
};

const openSpecialOrders: WarningTypeProcessor = ({ hasOpenSpecialOrders }) => {
  if (hasOpenSpecialOrders) {
    return MaterialWarningType.OpenSpecialOrders;
  }
};

const maximumQuantitySoftStop: WarningTypeProcessor = ({
  salesCriticalItem,
  cartMaterialQuantity,
}) => {
  if (
    doesQuantityExceedMaxSoftStop(salesCriticalItem, cartMaterialQuantity.case)
  ) {
    return MaterialWarningType.MaximumQuantitySoftStop;
  }
};

const minimumQuantitySoftStop: WarningTypeProcessor = ({
  salesCriticalItem,
  cartMaterialQuantity,
}) => {
  if (
    doesQuantityHaveMinSoftStop(salesCriticalItem, cartMaterialQuantity.case)
  ) {
    return MaterialWarningType.MinimumQuantitySoftStop;
  }
};

const inventoryCheck: WarningTypeProcessor = ({
  availability,
  shouldConsiderInventory,
  inventory,
  routeDate,
  cartMaterialQuantity,
  materialRecord,
}): MaterialWarningType | undefined => {
  if (
    !shouldEvaluateForInventoryWarning(
      availability,
      shouldConsiderInventory,
      inventory,
      routeDate,
    )
  ) {
    return undefined;
  }
  if (hasNoStock(inventory, routeDate, availability)) {
    return MaterialWarningType.NoStock;
  }
  if (
    hasPartialStockCase(
      cartMaterialQuantity.case,
      cartMaterialQuantity.unit,
      materialRecord,
      inventory,
      routeDate,
      availability,
    )
  ) {
    return verifyItemUnitUom(materialRecord, availability)
      ? MaterialWarningType.PartialStockCaseUnit
      : MaterialWarningType.PartialStockCase;
  }
  return undefined;
};

const repeatingDigits: WarningTypeProcessor = ({ cartMaterialQuantity }) => {
  if (hasRepeatingDigits(cartMaterialQuantity.case)) {
    return MaterialWarningType.RepeatingDigitCase;
  } else if (hasRepeatingDigits(cartMaterialQuantity.unit)) {
    return MaterialWarningType.RepeatingDigitUnit;
  }
};

const quantityThreshold: WarningTypeProcessor = ({
  cartMaterialQuantity,
  availability,
}) => {
  if (
    isQuantityAboveValidThreshold(
      cartMaterialQuantity.case,
      NaooConstants.Uom.Case,
      availability,
    )
  ) {
    return MaterialWarningType.QuantityThresholdExceededCase;
  } else if (
    isQuantityAboveValidThreshold(
      cartMaterialQuantity.unit,
      NaooConstants.Uom.Unit,
      availability,
    )
  ) {
    return MaterialWarningType.QuantityThresholdExceededUnit;
  }
};

const rollupConversion: WarningTypeProcessor = ({
  cartMaterial,
  focusedMaterialState,
}) => {
  if (getUnitCaseConversion(cartMaterial, focusedMaterialState)) {
    return MaterialWarningType.UnitCaseConversion;
  }
};

const missedCutoff: WarningTypeProcessor = ({
  routeDate,
  availability,
  activeCustomer,
  cutoffRecord,
}) => {
  if (
    routeDate &&
    availability?.cutoffCode &&
    activeCustomer &&
    isMygfsOrSap(activeCustomer.currentSystem) &&
    materialPastCutoff(cutoffRecord, activeCustomer.timeZone)
  ) {
    return MaterialWarningType.MissedCutoff;
  }
};

function transformMaterialWarningType(
  warningContext: Partial<MaterialWarningContext>,
): MaterialWarningType | undefined {
  const { materialNumber, cartMaterial, focusedMaterialState } = warningContext;
  if (
    doesNotHaveCartMaterialData(
      materialNumber,
      cartMaterial,
      focusedMaterialState,
    )
  ) {
    return undefined;
  }

  warningContext.cartMaterialQuantity = getCartMaterialQuantity(
    cartMaterial,
    focusedMaterialState,
  );

  // ordering matters
  const warningProcessors: WarningTypeProcessor[] = [
    overAllocation,
    maximumHardStop,
    openSpecialOrders,
    maximumQuantitySoftStop,
    minimumQuantitySoftStop,
    inventoryCheck,
    repeatingDigits,
    quantityThreshold,
    rollupConversion,
    missedCutoff,
  ];

  for (const processor of warningProcessors) {
    const warningType = processor(warningContext);
    if (warningType) {
      return warningType;
    }
  }

  return undefined;
}

function transformMaterialWarningValues(
  warningType: MaterialWarningType,
  materialRecord: MaterialRecord,
  availability: MaterialAvailabilityRecord,
  inventory: InventoryAvailabilityRecord,
  salesCriticalItem: SalesCriticalItem,
  entitlementMaterialDetail: EntitlementMaterialDetail,
  overallocatedMaterialDetail: OverallocatedMaterialDetail,
  locale: Locale,
  fulfillmentType: FulfillmentType,
): MaterialWarningMessageParam {
  if (!warningType) {
    return undefined;
  }

  if (MaterialWarningType.OverAllocation === warningType) {
    const maxCommodityCasesAmount = overallocatedMaterialDetail
      ? overallocatedMaterialDetail.maxCommodityCasesAmount
      : entitlementMaterialDetail.maxCommodityCasesAmount;

    return {
      caseCount: undefined,
      maxAllocationCount: maxCommodityCasesAmount,
    };
  }

  if (isMinMaxWarning(warningType)) {
    if (warningType === MaterialWarningType.MinimumQuantitySoftStop) {
      return {
        caseCount: salesCriticalItem.minimumQuantity,
        onOrderCount: salesCriticalItem.onOrderQuantity,
      };
    }
    return {
      caseCount: salesCriticalItem.maximumQuantity,
      onOrderCount: salesCriticalItem.onOrderQuantity,
    };
  }

  if (MaterialWarningType.NoStock === warningType) {
    const nextAvailableDate = getNextStoreDeliveryTime(
      inventory?.unitsAvailability,
    );

    const formattedDate = formatNextAvailableDate(
      nextAvailableDate,
      fulfillmentType,
      locale,
    );

    return {
      caseCount: undefined,
      nextAvailable: formattedDate,
    };
  }

  if (!isPartialStockMaterialWarning(warningType)) {
    return undefined;
  }

  const unitsPerCase = getQtyPerMasterSellUnitFromMaterialRecord(
    materialRecord,
    availability,
  );

  const currentInventoryCases = getUnitsAvailableForUom(
    inventory.unitsAvailability,
    NaooConstants.Uom.Case,
  );
  const currentInventoryUnits =
    getUnitsAvailableForUom(
      inventory.unitsAvailability,
      NaooConstants.Uom.Unit,
    ) % unitsPerCase;

  switch (warningType) {
    case MaterialWarningType.PartialStockCase:
      return {
        caseCount: currentInventoryCases,
      };
    case MaterialWarningType.PartialStockCaseUnit:
      return {
        caseCount: currentInventoryCases,
        unitCount: currentInventoryUnits,
      };
    default:
      break;
  }
  return undefined;
}

function formatNextAvailableDate(
  nextAvailableDate: string,
  fulfillmentType: FulfillmentType,
  locale: Locale,
) {
  if (FulfillmentType.EXPRESS === fulfillmentType) {
    return nextAvailableDate && locale
      ? moment(nextAvailableDate)
          .tz('America/New_York')
          .locale(locale.valueOf())
          .format(dateFormats[locale.valueOf()]['monthDayTime2'])
      : undefined;
  } else {
    return nextAvailableDate && locale
      ? formatDate(
          nextAvailableDate,
          dateFormats[locale.valueOf()]['monthDay'],
          locale.valueOf(),
        )
      : undefined;
  }
}

function materialPastCutoff(
  cutoffRecord: MaterialCutoffEntryRecord | null,
  timeZone: string,
): boolean {
  if (cutoffRecord?.cutoffDateTime == null) {
    return false;
  }
  const cutoffTime = moment.tz(cutoffRecord.cutoffDateTime, timeZone).valueOf();
  const currentTime = moment.tz(new Date(), timeZone).valueOf();
  return cutoffTime < currentTime;
}

function isPartialStockMaterialWarning(warningType: MaterialWarningType) {
  return (
    warningType === MaterialWarningType.PartialStockCase ||
    warningType === MaterialWarningType.PartialStockCaseUnit
  );
}

function isMinMaxWarning(warningType: MaterialWarningType) {
  return salesCriticalItemsWarnings.includes(warningType);
}

function getCartMaterialQuantity(
  cartMaterial: CartMaterialState,
  focusedMaterialState: FocusedMaterialState,
): { case: number; unit: number } {
  const targetMaterialQuantity = {
    case: 0,
    unit: 0,
  };
  const targetMaterial = isFocusStateForMaterial(
    cartMaterial,
    focusedMaterialState,
  )
    ? focusedMaterialState
    : cartMaterial;

  targetMaterial.lines.ids.forEach((uom) => {
    const quantity = targetMaterial.lines.entities[uom].quantity;
    if (NaooConstants.Uom.Case === uom) {
      targetMaterialQuantity.case = quantity || 0;
    } else {
      targetMaterialQuantity.unit = quantity || 0;
    }
  });
  return targetMaterialQuantity;
}

function getUnitCaseConversion(
  cartMaterial: CartMaterialState,
  focusedMaterialState: FocusedMaterialState,
): boolean {
  if (isFocusStateForMaterial(cartMaterial, focusedMaterialState)) {
    return focusedMaterialState.isRollUpConversion;
  }
  return cartMaterial.isRollUpConversion;
}

function materialNotInCartAndNotFocused(
  cartMaterial: CartMaterialState,
  focusedMaterialState: FocusedMaterialState,
  materialNumber: string,
) {
  return (
    !cartMaterial &&
    (!focusedMaterialState ||
      (!!focusedMaterialState &&
        focusedMaterialState.materialNumber !== materialNumber))
  );
}

function cartMaterialIsDeleted(cartMaterial: CartMaterialState) {
  return !!cartMaterial && cartMaterial.isDeleted;
}

function materialIsFocusedAndHasEmptyQuantities(
  focusedMaterialState: FocusedMaterialState,
  materialNumber: string,
) {
  return (
    !!focusedMaterialState &&
    focusedMaterialState.materialNumber === materialNumber &&
    filterNonEmptyCartLines(focusedMaterialState.lines.entities).length === 0
  );
}

function doesNotHaveCartMaterialData(
  materialNumber: string,
  cartMaterial: CartMaterialState,
  focusedMaterialState: FocusedMaterialState,
) {
  return (
    materialNotInCartAndNotFocused(
      cartMaterial,
      focusedMaterialState,
      materialNumber,
    ) ||
    cartMaterialIsDeleted(cartMaterial) ||
    materialIsFocusedAndHasEmptyQuantities(focusedMaterialState, materialNumber)
  );
}

function isFocusStateForMaterial(
  cartMaterial: CartMaterialState,
  focusedMaterialState: FocusedMaterialState,
): boolean {
  return (
    !cartMaterial ||
    (!!focusedMaterialState &&
      focusedMaterialState.materialNumber === cartMaterial.materialNumber)
  );
}

function shouldEvaluateForInventoryWarning(
  availability: MaterialAvailabilityRecord,
  shouldConsiderInventory: boolean,
  inventory?: InventoryAvailabilityRecord,
  routeDate?: Date,
): boolean {
  const combinedConsiderInventory =
    shouldConsiderInventory === undefined
      ? inventory?.considerInventory
      : shouldConsiderInventory && inventory?.considerInventory;
  return (
    !!routeDate &&
    !isSpecialOrderOrJit(availability) &&
    combinedConsiderInventory
  );
}

function isSpecialOrderOrJit(
  availability: MaterialAvailabilityRecord,
): boolean {
  return (
    availability.stockType === StockType.SpecialOrder ||
    availability.stockType === StockType.SpecialOrderSAP ||
    availability.stockType === StockType.Jit
  );
}

function hasNoStock(
  inventory: InventoryAvailabilityRecord,
  routeDate: Date,
  availability: MaterialAvailabilityRecord,
): boolean {
  const isAnyUnitAvailable = availability.units.some(
    (unit) => getUnitsAvailable(inventory.unitsAvailability, unit.uom) > 0,
  );

  return (
    !isAnyUnitAvailable &&
    !isReStockBeforeShip(
      getNextStoreDeliveryTime(inventory.unitsAvailability),
      routeDate,
    )
  );
}

function hasPartialStockCase(
  caseQuantity: number,
  unitQuantity: number,
  materialRecord: MaterialRecord,
  inventory: InventoryAvailabilityRecord,
  routeDate: Date,
  availability: MaterialAvailabilityRecord,
): boolean {
  const unitsPerCase = getQtyPerMasterSellUnitFromMaterialRecord(
    materialRecord,
    availability,
  );
  const totalUnitsAvailable = findTotalAvailable(
    materialRecord,
    inventory,
    unitsPerCase,
    availability,
  );
  const unitsRequested = caseQuantity * unitsPerCase + unitQuantity;

  return (
    totalUnitsAvailable > 0 &&
    totalUnitsAvailable < unitsRequested &&
    !isReStockBeforeShip(getNextPoDate(inventory.unitsAvailability), routeDate)
  );
}

function findTotalAvailable(
  materialRecord: MaterialRecord,
  inventory: InventoryAvailabilityRecord,
  unitsPerCase: number,
  availability: MaterialAvailabilityRecord,
): number {
  const primaryUom = materialRecord.baseUom;
  const unitsUom = availability.units.find(
    (unit) => unit.uom != primaryUom,
  )?.uom;

  const unitsAvailable = inventory.unitsAvailability.find(
    (unit) => unit.uom === unitsUom,
  )?.unitsAvailable;

  const casesAvailable = inventory.unitsAvailability.find(
    (unit) => unit.uom === materialRecord.baseUom,
  )?.unitsAvailable;
  return unitsAvailable ?? casesAvailable * unitsPerCase;
}

function isReStockBeforeShip(estInStockDate: string, routeDate: Date): boolean {
  if (!estInStockDate) {
    return false;
  }
  const todayMoment = moment();
  const convertedEstInStockDateMoment = moment(estInStockDate);
  const convertedRouteMoment = moment(routeDate);

  return (
    todayMoment.isSameOrBefore(convertedEstInStockDateMoment, 'day') &&
    convertedEstInStockDateMoment.isSameOrBefore(convertedRouteMoment, 'day')
  );
}

function hasRepeatingDigits(quantity: number): boolean {
  if (quantity === 0) {
    return false;
  }
  const repeatDigitMatcher = new RegExp('([0-9])\\1+');
  return repeatDigitMatcher.test(quantity.toString());
}

function isQuantityAboveValidThreshold(
  quantity: number,
  uom: string,
  availability: MaterialAvailabilityRecord,
): boolean {
  if (quantity === 0) {
    return false;
  }
  const unitsAvailability = isAvailableByUom(availability, uom)
    ? availability.units.find((unit) => unit.uom === uom)
    : undefined;

  return (
    !!unitsAvailability &&
    !!unitsAvailability.validationQty &&
    quantity > unitsAvailability.validationQty
  );
}

function verifyItemUnitUom(
  materialRecord: MaterialRecord,
  materialAvailability: MaterialAvailabilityRecord,
): boolean {
  const primaryUom = materialRecord.baseUom;

  return materialAvailability.units.some(
    (unitAvailable) =>
      unitAvailable.uom !== primaryUom && materialAvailability.isOrderable,
  );
}

function getUnitsAvailableForUom(
  unitsAvailability: UnitsAvailability[],
  unitOfMeasure: string,
): number {
  let unitAvailable: UnitsAvailability;
  if (unitOfMeasure === NaooConstants.Uom.Unit) {
    unitAvailable = unitsAvailability.find(
      (unit) => unit.uom !== NaooConstants.Uom.Case,
    );
  } else if (unitOfMeasure === NaooConstants.Uom.Case) {
    unitAvailable = unitsAvailability.find(
      (unit) => unit.uom === NaooConstants.Uom.Case,
    );
  }

  return unitAvailable ? unitAvailable.unitsAvailable : 0;
}
