<ng-container *ngIf="selectedInfo">
  <div class="packaging-selection">
    <mat-form-field>
      <mat-select
        class="packaging-name"
        [placeholder]="'PRODUCT_ATTRIBUTES.PACKAGING' | translate"
        [(value)]="selectedInfo"
      >
        <mat-option
          *ngFor="let nutritionInfo of nutritionInfos"
          [value]="nutritionInfo"
        >
          {{ nutritionInfo.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="rounding-toggle" matTooltip="Round values for nutrients">
      <mat-slide-toggle
        [labelPosition]="'before'"
        [checked]="shouldRound"
        (toggleChange)="shouldRound = !shouldRound"
      >
        Rounding:
        <span class="print-value">{{
          shouldRound.toString() | titlecase
        }}</span>
      </mat-slide-toggle>
    </div>
  </div>
  <div class="nutrition-info">
    <naoo-nutrition-label
      [nutritionInfo]="selectedInfo"
      [shouldRound]="shouldRound"
    ></naoo-nutrition-label>
  </div>
</ng-container>
