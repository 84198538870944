import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';

export interface OpenSpecialOrderItemsRecord {
  openSpecialOrderMaterialNumbers: string[];
}

@Injectable({ providedIn: 'root' })
export class OpenSpecialOrderItemsService {
  static readonly OPEN_SPECIAL_ORDER_ITEMS_ENDPOINT =
    '/api/v1/open-special-order-items';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getOpenSpecialOrderItems(): Observable<OpenSpecialOrderItemsRecord> {
    return this.httpClient.get<OpenSpecialOrderItemsRecord>(
      this.webBffService.getBff() +
        OpenSpecialOrderItemsService.OPEN_SPECIAL_ORDER_ITEMS_ENDPOINT,
    );
  }
}
