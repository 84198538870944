import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomerPreferencesEffects } from './customer-preferences.effects';
import { customerPreferencesReducer } from './customer-preferences.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('customerPreferences', customerPreferencesReducer),
    EffectsModule.forFeature([CustomerPreferencesEffects]),
  ],
})
export class CustomerPreferencesStoreModule {}
