import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MaterialDetailsPageViewModel,
  MaterialDetailsSection,
} from '../../core/store/material-details/models/material-details.model';
import { ProductDetailsUtilities } from '../../shared/utilities/product-details-utilities';

@Component({
  selector: 'naoo-product-secondary-details',
  templateUrl: './product-secondary-details.component.html',
  styleUrls: ['./product-secondary-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSecondaryDetailsComponent {
  @Input() viewModel: MaterialDetailsPageViewModel;
  @Input() isAccordionView: boolean;

  get MaterialDetailsSection(): typeof MaterialDetailsSection {
    return MaterialDetailsSection;
  }

  get ProductDetailsUtilities(): typeof ProductDetailsUtilities {
    return ProductDetailsUtilities;
  }

  get packaging(): string | undefined {
    return this.viewModel.materialAdditionalInfo?.packagingStorage?.[
      this.viewModel.currentLanguage
    ];
  }
}
