import {
  CustomerMaterialState,
  initialCustomerMaterialState,
} from './customer-material.state';
import { CustomerMaterialActions } from './customer-material.actions';
import { createReducer, on } from '@ngrx/store';

export const customerMaterialReducer = createReducer(
  initialCustomerMaterialState,
  on(
    CustomerMaterialActions.getCustomerMaterialSuccess,
    (state, action): CustomerMaterialState => ({
      ...state,
      customerMaterialRecord: { ...action.payload },
    }),
  ),
  on(
    CustomerMaterialActions.refreshCustomerMaterialCodes,
    (): CustomerMaterialState => initialCustomerMaterialState,
  ),
  on(
    CustomerMaterialActions.getCustomerMaterial,
    (state): CustomerMaterialState => state,
  ),
);
