import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'naoo-generic-docket',
  templateUrl: './generic-docket.component.html',
  styleUrls: ['./generic-docket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericDocketComponent {
  @Input() title?: string;
  @Input() tooltipKey?: string;
  @Output() closeDocketEmitter = new EventEmitter();
  @ViewChild('docketContainer') docket: ElementRef;
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const value = (event.target as HTMLInputElement).tagName;
    if ('NAOO-GENERIC-DOCKET' === value) {
      this.onClose();
    }
  }

  onClose(): void {
    this.closeDocketEmitter.emit();
  }

  requestFocus(): void {
    this.docket?.nativeElement?.focus();
  }
}
