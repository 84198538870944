import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { Observable } from 'rxjs';
import moment from 'moment-timezone';
import { InvoiceCreditResponse } from '../../models/invoice-credit-response';
import { InvoiceDetail } from '../../models/invoice-detail';

@Injectable({
  providedIn: 'root',
})
export class InvoicesCreditsService {
  public static readonly ISO_DATE_FORMAT = 'YYYY-MM-DD';
  public static readonly INVOICES_PATH = '/api/v1/invoices';
  public static readonly INVOICE_DETAILS_PATH = 'api/v2/invoice-details';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getHistory(
    startDate: Date,
    endDate?: Date,
  ): Observable<InvoiceCreditResponse> {
    const params: { [key: string]: string } = {
      startDate: moment(startDate).format(
        InvoicesCreditsService.ISO_DATE_FORMAT,
      ),
    };

    if (endDate) {
      params['endDate'] = moment(endDate).format(
        InvoicesCreditsService.ISO_DATE_FORMAT,
      );
    }

    return this.httpClient.get<InvoiceCreditResponse>(
      this.webBffService.getBff() + InvoicesCreditsService.INVOICES_PATH,
      { params },
    );
  }

  getInvoiceDetails(
    invoiceNumber: string,
    orderDate: string,
  ): Observable<InvoiceDetail> {
    return this.httpClient.get<InvoiceDetail>(
      `${this.webBffService.getBff()}/${
        InvoicesCreditsService.INVOICE_DETAILS_PATH
      }/${invoiceNumber}/${orderDate}`,
    );
  }
}
