import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface MaterialUnitsState {
  hasLoaded: boolean;
  materialUnits: EntityState<MaterialUnitsRecord>;
}

export const materialUnitsAdapter = createEntityAdapter<MaterialUnitsRecord>({
  selectId: (record) => record.displayCode,
});

export const initialMaterialUnitsState: MaterialUnitsState = {
  hasLoaded: false,
  materialUnits: materialUnitsAdapter.getInitialState(),
};
