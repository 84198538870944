import { MarketingGuideViewModel } from './models/marketing-guide';
import { MarketingGuideRecordState } from './marketing-guide.state';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';
import { hasMaterialInfoFinishedLoading } from '../material-info/material-info.util';
import {
  CategorizedGuideMaterials,
  GuidesTransformer,
} from '../guides/guides-transformer';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { Language } from '../../services/session/models/session-record';
import { GuidesContext, SortByType } from '../../../guides/shared/guides';
import { MaterialListStyle } from '../material-row/models/material-row';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { ExportFeatureType } from '../../../shared/models/export/export-properties';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';

export class MarketingGuideTransformer {
  public static transformMarketingGuide(
    recordState: MarketingGuideRecordState,
    preferredView: MaterialListStyle,
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    isOffline: boolean,
    language: Language,
  ): MarketingGuideViewModel {
    const record = recordState.record;
    const materialNumbers = getMarketingGuideMaterials(record);
    const searchText = recordState.searchText ?? '';

    const materialInfos = materialNumbers.map(
      (materialNumber) => materialInfoRecords[materialNumber],
    );
    const materialInfosLoaded = materialInfos.every((materialInfo) =>
      hasMaterialInfoFinishedLoading(materialInfo),
    );

    if (!recordState.hasLoaded || !recordState.record || !materialInfosLoaded) {
      return undefined;
    }

    const categorizedMaterials = this.getCategorizedMaterials(record);

    const materialListRows = GuidesTransformer.flattenCategories(
      categorizedMaterials,
      materialInfoRecords,
      customerMaterialRecord,
      undefined,
      SortByType.Custom,
      searchText,
      language,
    );

    return {
      pathUrl: record.pathUrl,
      header: GuidesTransformer.transformGuideHeader(
        categorizedMaterials,
        customerMaterialRecord,
        record.listName,
        searchText,
      ),
      imageUrl: record.imageUrl,
      materialListRows,
      preferredView: GuidesTransformer.getMaterialListStyle(preferredView),
      isOffline,
      sideBar: GuidesTransformer.transformGuideSideBar(
        materialListRows,
        undefined,
        GuidesContext.MarketingGuide,
      ),
    };
  }

  public static transformMarketingGuideExportMaterials(
    record: MarketingGuideRecord,
    infos: Dictionary<MaterialInfoRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    language: Language,
    isPrint: boolean,
  ): ExportMaterialsInput {
    return GuidesTransformer.transformExportMaterialsInput(
      this.getCategorizedMaterials(record),
      infos,
      undefined,
      customerMaterialRecord,
      SortByType.Custom,
      isPrint ? 'EXPORT_MODAL.PRINT' : 'EXPORT_MODAL.EXPORT',
      isPrint
        ? ExportFeatureType.MARKETING_GUIDE_PRINT
        : ExportFeatureType.MARKETING_GUIDE,
      record.listName,
      ListsAnalyticsConstants.marketingGuideCategory,
      language,
    );
  }

  private static getCategorizedMaterials(
    record: MarketingGuideRecord,
  ): CategorizedGuideMaterials[] {
    return record.categories.map((category) => ({
      categoryName: category.name,
      materialNumbers: category.materials,
    }));
  }
}

export function getMarketingGuideMaterials(
  record: MarketingGuideRecord,
): string[] {
  return (
    record?.categories
      ?.flatMap((category) => category.materials)
      .filter((material) => !!material) ?? []
  );
}
