<div
  class="parent-container"
  [ngClass]="{
    'red-border': shouldShowRedBorder,
    'gray-border': !shouldShowRedBorder,
    'no-side-borders': isInsideContainer,
    'last-row': isLast,
    mobile: isMobile,
  }"
>
  <div
    *ngIf="isOK"
    class="content material-content consistent-height"
    [ngClass]="{ mobile: isMobile }"
    @fadeInOnly
  >
    <naoo-material-image
      [materialRowOptions]="materialRow.materialRowOptions"
      [isUnorderable]="false"
      [isMobile]="isMobile"
      *ngIf="!isMobile"
    ></naoo-material-image>
    <div class="details-container" [ngClass]="{ mobile: isMobile }">
      <div class="row-details" [ngClass]="{ mobile: isMobile }">
        <div
          class="info-content"
          [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
        >
          <naoo-material-image
            [materialRowOptions]="materialRow.materialRowOptions"
            [isUnorderable]="false"
            [isMobile]="isMobile"
            *ngIf="isMobile"
          ></naoo-material-image>
          <div
            class="details-content"
            [ngClass]="{
              mobile: isMobile,
              'full-width': isInsideContainer,
            }"
          >
            <naoo-material-info
              [materialRowOptions]="materialRow.materialRowOptions"
              [materialInfo]="materialRow.materialRowInfo"
              [status]="materialRow.status"
              [isMobile]="isMobile"
            ></naoo-material-info>
            <div
              class="mobile-triple-dot"
              *ngIf="!isInsideContainer && isMobile"
            >
              <naoo-material-options-menu
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
          </div>
        </div>

        <div
          class="ordering-content"
          [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
        >
          <naoo-material-ordering
            *ngIf="!!materialRow.materialOrdering"
            [materialOrdering]="materialRow.materialOrdering"
            [materialRowOptions]="materialRow.materialRowOptions"
            [isMobile]="isMobile"
            [ngClass]="{ mobile: isMobile }"
            (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
          ></naoo-material-ordering>
          <div class="triple-dot" *ngIf="!isInsideContainer && !isMobile">
            <naoo-material-options-menu
              [materialRowOptions]="materialRow.materialRowOptions"
              [isMobile]="isMobile"
            >
            </naoo-material-options-menu>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isUnorderable"
    class="content unorderable-content consistent-height"
    [ngClass]="{ mobile: isMobile }"
    @fadeInOnly
  >
    <naoo-material-image
      [materialRowOptions]="materialRow.materialRowOptions"
      [isUnorderable]="true"
      [isMobile]="isMobile"
      *ngIf="!isMobile"
    ></naoo-material-image>
    <div class="details-container" [ngClass]="{ mobile: isMobile }">
      <div class="row-details" [ngClass]="{ mobile: isMobile }">
        <div
          class="info-content"
          [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
        >
          <naoo-material-image
            [materialRowOptions]="materialRow.materialRowOptions"
            [isUnorderable]="true"
            [isMobile]="isMobile"
            *ngIf="isMobile"
          ></naoo-material-image>
          <div class="details-content" [ngClass]="{ mobile: isMobile }">
            <naoo-material-info
              [materialRowOptions]="materialRow.materialRowOptions"
              [materialInfo]="materialRow.materialRowInfo"
              [status]="materialRow.status"
              [isMobile]="isMobile"
            ></naoo-material-info>
            <div
              class="mobile-triple-dot"
              *ngIf="!isInsideContainer && isMobile"
            >
              <naoo-material-options-menu
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
          </div>
        </div>
        <div
          class="ordering-content"
          [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
        >
          <naoo-material-unorderable
            [materialRowOptions]="materialRow.materialRowOptions"
            [isMobile]="isMobile"
            [materialAvailability]="materialAvailability"
          >
          </naoo-material-unorderable>
          <div class="triple-dot" *ngIf="!isInsideContainer && !isMobile">
            <naoo-material-options-menu
              [materialRowOptions]="materialRow.materialRowOptions"
              [isMobile]="isMobile"
            >
            </naoo-material-options-menu>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="content consistent-height unavailable-content"
    *ngIf="isUnavailable"
    [ngClass]="{ mobile: isMobile }"
    @fadeInOnly
  >
    <naoo-material-unavailable
      [materialRowOptions]="materialRow.materialRowOptions"
      [isMobile]="isMobile"
      [isDeleteHidden]="shouldHideTrashCan"
    >
    </naoo-material-unavailable>
  </div>

  <div
    class="content deleted-content consistent-height"
    [ngClass]="{ mobile: isMobile }"
    *ngIf="isDeleted"
    @fadeInOnly
  >
    <naoo-material-deleted
      [materialInfo]="materialRow.materialRowInfo"
      [status]="materialRow.status"
      [materialRowOptions]="materialRow.materialRowOptions"
      [isMobile]="isMobile"
    >
    </naoo-material-deleted>
  </div>

  <div
    *ngIf="isLoading"
    class="content loading-content consistent-height"
    [ngClass]="{ mobile: isMobile }"
    @fadeInAndOut
  >
    <naoo-material-skeleton
      [isMobile]="isMobile"
      [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
    >
    </naoo-material-skeleton>
  </div>
</div>
