import { Component } from '@angular/core';

@Component({
  selector: 'naoo-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent {
  readonly diameter: number = 70;
  readonly strokeWidth: number = 7;
}
