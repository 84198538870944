<span class="par-quantity">
  {{ currentOrderingInfo.parLine?.parQuantity }}
</span>

<input
  #inventoryInput
  class="inventory-input"
  [id]="inventoryInputId"
  [value]="inventoryInputValue | naoofloatingpoint"
  [disabled]="!materialRowOptions.isCartLoaded"
  (contextmenu)="suppressContextMenu($event)"
  (keydown)="onKeyDown()"
  (input)="onInput()"
  (focus)="onFocus()"
  (blur)="onBlur()"
  (mouseup)="selectAll()"
  (keyup.arrowdown)="selectAll()"
  (keyup.arrowup)="selectAll()"
  (keydown.enter)="nextInput()"
  (keydown.shift.enter)="previousInput()"
  (keydown.arrowdown)="nextInput()"
  (keydown.arrowup)="previousInput()"
  type="text"
  inputmode="decimal"
  maxlength="6"
  autocomplete="off"
/>
