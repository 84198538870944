import { InventoryAvailabilityActions } from './inventory-availability.actions';
import {
  initialInventoryAvailabilityState,
  inventoryAvailabilityAdapter,
  InventoryAvailabilityRecordState,
  InventoryAvailabilityRecordStatus,
  InventoryAvailabilityState,
} from './inventory-availability.state';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { InventoryAvailabilityRecord } from '../../services/inventory-availability/models/inventory-availability-record';

export const inventoryAvailabilityReducer = createReducer(
  initialInventoryAvailabilityState,
  on(
    InventoryAvailabilityActions.loadCartInventoryAvailability,
    (state): InventoryAvailabilityState => state,
  ),
  on(
    InventoryAvailabilityActions.loadInventoryAvailability,
    (state, action): InventoryAvailabilityState =>
      loadInventoryAvailability(state, action),
  ),
  on(
    InventoryAvailabilityActions.getInventoryAvailability,
    (state, action): InventoryAvailabilityState =>
      getInventoryAvailability(state, action),
  ),
  on(
    InventoryAvailabilityActions.getInventoryAvailabilitySuccess,
    (state, action): InventoryAvailabilityState =>
      getInventoryAvailabilitySuccess(state, action),
  ),
  on(
    InventoryAvailabilityActions.getInventoryAvailabilityError,
    (state, action): InventoryAvailabilityState =>
      getInventoryAvailabilityError(state, action),
  ),
  on(
    InventoryAvailabilityActions.refreshCartInventoryAvailability,
    InventoryAvailabilityActions.clearInventoryAvailability,
    (): InventoryAvailabilityState => initialInventoryAvailabilityState,
  ),
);

function loadInventoryAvailability(
  state: InventoryAvailabilityState,
  action: {
    materialNumbers: string[];
  },
): InventoryAvailabilityState {
  const upsertedEntities: InventoryAvailabilityRecordState[] =
    action.materialNumbers
      .filter((id) => {
        return (
          !state.entities[id] ||
          state.entities[id].status === InventoryAvailabilityRecordStatus.Error
        );
      })
      .map((id) => {
        return {
          materialNumber: id,
          status: InventoryAvailabilityRecordStatus.Queued,
          record: undefined,
        };
      });
  return inventoryAvailabilityAdapter.upsertMany(upsertedEntities, state);
}

function getInventoryAvailability(
  state: InventoryAvailabilityState,
  action: {
    materialNumbers: string[];
  },
): InventoryAvailabilityState {
  const queuedEntities: Update<InventoryAvailabilityRecordState>[] =
    action.materialNumbers
      .filter((id) => {
        return (
          state.entities[id].status === InventoryAvailabilityRecordStatus.Queued
        );
      })
      .map((id) => {
        return {
          id: id,
          changes: {
            status: InventoryAvailabilityRecordStatus.Requested,
          },
        };
      });
  return inventoryAvailabilityAdapter.updateMany(queuedEntities, state);
}

function getInventoryAvailabilitySuccess(
  state: InventoryAvailabilityState,
  action: {
    inventoryAvailabilityRecords: InventoryAvailabilityRecord[];
  },
): InventoryAvailabilityState {
  const updatedProductRecordStates: Update<InventoryAvailabilityRecordState>[] =
    action.inventoryAvailabilityRecords.map((inventoryAvailabilityRecord) => {
      return {
        id: inventoryAvailabilityRecord.materialNumber,
        changes: {
          record: inventoryAvailabilityRecord,
          status: InventoryAvailabilityRecordStatus.Success,
        },
      };
    });
  return inventoryAvailabilityAdapter.updateMany(
    updatedProductRecordStates,
    state,
  );
}

function getInventoryAvailabilityError(
  state: InventoryAvailabilityState,
  action: {
    materialNumbers: string[];
  },
): InventoryAvailabilityState {
  const updatedProductRecordStates: Update<InventoryAvailabilityRecordState>[] =
    action.materialNumbers.map((id) => {
      return {
        id: id,
        changes: {
          status: InventoryAvailabilityRecordStatus.Error,
        },
      };
    });
  return inventoryAvailabilityAdapter.updateMany(
    updatedProductRecordStates,
    state,
  );
}
