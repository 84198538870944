import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  materialAvailabilityAdapter,
  MaterialAvailabilityRecordState,
  MaterialAvailabilityState,
} from './material-availability.state';
import { Dictionary } from '@ngrx/entity';
import {
  buildMaterialAvailability,
  hasMaterialAvailabilityFinishedLoading,
} from './material-availability.util';

const selectMaterialAvailabilityFeature =
  createFeatureSelector<MaterialAvailabilityState>('materialAvailability');

export const selectAllMaterialAvailabilityRecords = createSelector(
  selectMaterialAvailabilityFeature,
  materialAvailabilityAdapter.getSelectors().selectEntities,
);

export const selectMaterialAvailabilityRecordState = (materialNumber: string) =>
  createSelector(
    selectAllMaterialAvailabilityRecords,
    (
      availabilities: Dictionary<MaterialAvailabilityRecordState>,
    ): MaterialAvailabilityRecordState | undefined =>
      availabilities[materialNumber],
  );

export const selectMaterialAvailabilityRecordLoadedState = (
  materialNumber: string,
) => {
  return createSelector(
    selectAllMaterialAvailabilityRecords,
    (availabilities: Dictionary<MaterialAvailabilityRecordState>) => {
      const availabilityRecordState = availabilities[materialNumber];
      return hasMaterialAvailabilityFinishedLoading(availabilityRecordState)
        ? availabilityRecordState
        : undefined;
    },
  );
};

export const selectMaterialAvailabilityRecordStates = (
  materialNumbers: string[],
) => {
  return createSelector(
    selectAllMaterialAvailabilityRecords,
    (availabilities: Dictionary<MaterialAvailabilityRecordState>) =>
      materialNumbers.map((materialNumber) => availabilities[materialNumber]),
  );
};

export const selectMaterialAvailability = (materialNumber: string) => {
  return createSelector(
    selectMaterialAvailabilityRecordState(materialNumber),
    (recordState: MaterialAvailabilityRecordState) => {
      return buildMaterialAvailability(recordState);
    },
  );
};

export const selectMaterialAvailabilities = (materialNumber: string[]) => {
  return createSelector(
    selectMaterialAvailabilityRecordStates(materialNumber),
    (recordStates: MaterialAvailabilityRecordState[]) => {
      return recordStates.map((state) => buildMaterialAvailability(state));
    },
  );
};
