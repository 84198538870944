import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared.module';
import { ErrorStateComponent } from './error-state/error-state.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), SharedModule],
  declarations: [ErrorStateComponent],
  exports: [ErrorStateComponent],
})
export class ErrorStateModule {}
