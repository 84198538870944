import { Pipe, PipeTransform } from '@angular/core';
import { CustomerBrand } from '../../core/services/session/models/session-record';

@Pipe({
  name: 'naoobrand',
})
export class NaooBrandPipe implements PipeTransform {
  transform(value: string, brand: CustomerBrand): string {
    brand = brand ? brand : CustomerBrand.Global;
    return `${value}.${brand}`;
  }
}
