import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MaterialImageSourcesService } from '../services/images/material-image-sources.service';
import { LocalizedUtilities } from '../utilities/localized-utilities';
import { LocalizationService } from '../services/translation/localization.service';
import { Localized } from '../models/localized';
import { MaterialImage } from '../models/material-additional-info';
import { CarouselImage } from '../../product-details/image-carousel/image-carousel.component';
import { Language } from '../../core/services/session/models/session-record';

@Pipe({
  name: 'naoocarouselimage',
  pure: false,
})
export class NaooCarouselImagePipe implements PipeTransform, OnDestroy {
  static readonly carouselThumbnailResolution = 80;
  static readonly carouselImageResolution = 350;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialImageSourcesService: MaterialImageSourcesService,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    localizedMaterialImages: Localized<MaterialImage[]>,
    materialNumber: string,
  ): CarouselImage[] {
    return this.displayAdditionalImagesIfAvailable(
      localizedMaterialImages,
      materialNumber,
    );
  }

  private displayAdditionalImagesIfAvailable(
    materialImages: Localized<MaterialImage[]>,
    materialNumber: string,
  ): CarouselImage[] {
    const language = this.localizationService.currentLanguage;
    const additionalImages = this.getAdditionalImagesForLanguage(
      materialImages,
      language,
    );

    const carouselImages = additionalImages.map((url) => {
      const highResImages = this.materialImageSourcesService.createImageUrls(
        url,
        NaooCarouselImagePipe.carouselImageResolution,
      );
      const thumbnailImages = this.materialImageSourcesService.createImageUrls(
        url,
        NaooCarouselImagePipe.carouselThumbnailResolution,
      );
      return {
        largeImageUrl: highResImages,
        thumbnailImageUrl: thumbnailImages,
      };
    });

    if (carouselImages.length === 0) {
      const fallbackImages = this.materialImageSourcesService.getImageSources(
        materialNumber,
        NaooCarouselImagePipe.carouselImageResolution,
      );
      const fallbackThumbnailImages =
        this.materialImageSourcesService.getImageSources(
          materialNumber,
          NaooCarouselImagePipe.carouselThumbnailResolution,
        );
      return [
        {
          largeImageUrl: LocalizedUtilities.getLocalizedValue(
            fallbackImages,
            language,
          ),
          thumbnailImageUrl: LocalizedUtilities.getLocalizedValue(
            fallbackThumbnailImages,
            language,
          ),
        },
      ];
    }

    return carouselImages;
  }

  private getAdditionalImagesForLanguage(
    materialImages: Localized<MaterialImage[]>,
    language: Language,
  ): string[] {
    if (!materialImages || !language) {
      return [];
    }

    let response: MaterialImage[];
    if (materialImages?.[language]?.length) {
      response = materialImages[language];
    } else if (Language.en === language && !!materialImages?.fr) {
      response = materialImages.fr;
    } else if (Language.fr === language && !!materialImages?.en) {
      response = materialImages.en;
    } else if (Language.es === language && !!materialImages?.en) {
      response = materialImages.en;
    } else {
      response = [];
    }
    return [...new Set(response.map((img) => img.url))];
  }
}
