import { Injectable } from '@angular/core';
import { MaterialInfo } from '../../../shared/models/material-info';
import { selectMaterialDetails } from './material-details.selector';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { MaterialDetailsPageViewModel } from './models/material-details.model';
import { OrderService } from '../../../shared/services/order/order.service';
import { map } from 'rxjs/operators';
import { MaterialDetailsActions } from './material-details.action';
import { ProductDetailsData } from '../../../product-details/product-details-resolver/product-details-resolver';

@Injectable({ providedIn: 'root' })
export class MaterialDetailsFacade {
  constructor(
    private store: Store,
    private orderService: OrderService,
  ) {}

  loadOnMaterialDetailsPage(materialInfo: MaterialInfo) {
    this.store.dispatch(
      MaterialDetailsActions.loadMaterialDetails(materialInfo),
    );
  }

  getMaterialDetailsPageViewModel(
    details: ProductDetailsData,
  ): Observable<MaterialDetailsPageViewModel> {
    return combineLatest([
      this.store.select(selectMaterialDetails(details)),
      this.orderService.getMaterialMovement(
        details.materialInfo.materialNumber,
      ),
    ]).pipe(
      map(([materialDetailsPageViewModel, materialMovementDetails]) => {
        return {
          ...materialDetailsPageViewModel,
          materialMovementDetails,
        };
      }),
    );
  }
}
