<naoo-modal-header
  [title]="'ENTITLEMENT.OVERALLOCATED_MODAL.TITLE' | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>

<ng-container *ngIf="!!materialListRows">
  <div class="material-warning-message">
    {{ 'ENTITLEMENT.OVERALLOCATED_MODAL.INSTRUCTIONS' | translate }}
  </div>
  <div class="material-warning-number">
    {{
      'ENTITLEMENT.OVERALLOCATED_MODAL.ERRORS'
        | translate: { count: materialListRows.length }
    }}
  </div>

  <naoo-material-list
    class="material-list-container"
    [materialListItems]="materialListRows"
    [listStyle]="listStyle"
    [context]="context"
  ></naoo-material-list>

  <div class="buttons-container">
    <button
      (click)="closeModal()"
      [disabled]="hasOverallocatedCartMaterials"
      class="done-button"
    >
      {{ 'DONE' | translate }}
    </button>
  </div>
</ng-container>
