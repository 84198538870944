import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FulfillmentType } from 'src/app/core/services/cart/models/cart-record';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../core/store/material-row/models/material-row';
import { MaterialAvailability } from '../../../../shared/models/material-availability';

@Component({
  selector: 'naoo-material-store-stored-button',
  templateUrl: './material-store-stock.component.html',
  styleUrls: ['./material-store-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialStoreStockComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() materialAvailability: MaterialAvailability;
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isPdp: boolean;

  private readonly storeStockType = 'STORE';

  get isInStock(): boolean {
    return (
      this.storeStockType === this.materialAvailability?.stockType &&
      this.fulfillmentType === FulfillmentType.PICKUP
    );
  }

  get classIconLocation(): string {
    return `${this.getIconType()}-in-stock-icon`;
  }

  private getIconType(): string {
    if (
      MaterialRowContext.Recommendation === this.materialRowOptions?.context
    ) {
      return 'recommendation';
    }
    if (MaterialRowContext.CartSummary === this.materialRowOptions?.context) {
      return 'cart-summary';
    }
    if (this.isPdp) {
      return 'pdp';
    }
    if (MaterialListStyle.Grid === this.materialRowOptions?.materialListStyle) {
      return 'grid';
    } else {
      return 'list';
    }
  }
}
