import { Localized } from '../../../../shared/models/localized';
import { MaterialAvailabilityRecord } from '../../material-availability/model/material-availabilities-record';

export interface MaterialsInfoRecord {
  materialInfos: MaterialRecord[];
}

export interface MaterialRecord {
  materialNumber: string;
  brand: {
    id: string;
    en: string;
    fr: string;
    es: string;
  };
  description: Localized<string>;
  innerPackSize: string;
  units: UnitInfoRecord[];
  baseUom: string;
  baseUomWeight: BaseUomWeightRecord;
  isCatchWeight: boolean;
  image?: Localized<{ url: string }>;
  pricingPortionUom?: string;
  pricingPortionPerBaseUom?: number;
  complimentaryMaterials?: string[];
}

export interface UnitInfoRecord {
  uom: string;
  displayCode: string;
  parentUom: string;
  qtyInParent: string;
  primaryGtin: string;
  alternateGtins: string;
  discountAmount?: number;
  pricingPortionCoefficient?: number;
}

export interface BaseUomWeightRecord {
  net: string;
  gross: string;
  uom: string;
}

export function unavailableMaterialRecord(
  materialNumber: string,
): MaterialRecord {
  return {
    materialNumber: materialNumber,
    brand: {
      id: '',
      en: '',
      fr: '',
      es: '',
    },
    description: {
      en: 'Item Details Unavailable',
      fr: 'Détails du produits non-disponibles',
      es: '',
    },
    innerPackSize: '',
    units: [
      {
        uom: '',
        displayCode: '',
        parentUom: '',
        qtyInParent: '0',
        primaryGtin: '',
        alternateGtins: '',
        discountAmount: 0,
      },
    ],
    baseUom: '',
    baseUomWeight: {
      net: '0',
      gross: '',
      uom: '',
    },
    isCatchWeight: false,
  };
}

export function getQtyPerMasterSellUnitFromMaterialRecord(
  materialRecord: MaterialRecord,
  availability: MaterialAvailabilityRecord,
): number {
  const { baseUom, units } = materialRecord;
  const unitInfoMap = new Map(units.map((unit) => [unit.uom, unit]));
  const unitsUom = availability.units.find((unit) => unit.uom != baseUom)?.uom;
  return getMultiple(baseUom, unitsUom, unitInfoMap, 0);
}

function getMultiple(
  baseUom: string,
  currentUom: string,
  unitInfoMap: Map<string, UnitInfoRecord>,
  counter: number,
): number {
  const currentUnitInfo = unitInfoMap.get(currentUom);
  if (
    counter > 5 ||
    !currentUnitInfo?.parentUom ||
    baseUom === currentUnitInfo.uom
  ) {
    return 1;
  }
  counter++;

  return (
    +currentUnitInfo.qtyInParent *
    getMultiple(baseUom, currentUnitInfo.parentUom, unitInfoMap, counter)
  );
}
