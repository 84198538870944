<div
  *ngIf="showSponsoredMaterial"
  class="sponsored-material"
  [ngClass]="isGrid ? 'sponsored-grid-view' : 'sponsored-list-view'"
>
  {{ 'LISTS.SEARCH.SPONSORED_PRODUCT' | translate }}
</div>
<a
  class="description multi-line-clamp"
  *ngIf="!isLinkDisabled; else disabledLink"
  [ngClass]="{
    mobile: isMobile,
    slim: isSlim,
    'grid-description': isGrid || isSlimGrid,
    complimentary: isComplimentaryProduct,
  }"
  (click)="handleDetailsClick()"
  (keyup.enter)="handleDetailsClick()"
  [routerLink]="detailsLink"
  [state]="{
    analytics: materialRowOptions.analytics,
    context: materialRowOptions.context,
  }"
  [matTooltip]="materialInfo?.description | stringdefaulter"
  [matTooltipShowDelay]="500"
  [matTooltipDisabled]="isMobile"
>
  {{ materialInfo?.description | stringdefaulter }}
</a>
<ng-template #disabledLink>
  <div
    class="description multi-line-clamp disable-link"
    [ngClass]="{
      mobile: isMobile,
      slim: isSlim,
      'grid-description': isGrid || isSlimGrid,
      complimentary: isComplimentaryProduct,
    }"
  >
    {{ materialInfo?.description | stringdefaulter }}
  </div>
</ng-template>
<div
  *ngIf="!isComplimentaryProduct"
  class="details"
  [ngClass]="{ 'multi-line-clamp': isMobile || isGrid || isSlimGrid }"
>
  <ng-container *ngIf="!!materialRowOptions.customerMaterialNumber"
    >{{ materialRowOptions.customerMaterialNumber }} :
  </ng-container>
  {{ pipeDelimitedDetails }}
</div>
