import { PickupScheduleRecord } from '../../services/pickup-schedules/models/pickup-schedule-record';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const PickupSchedulesActions = createActionGroup({
  source: 'PickupSchedules',
  events: {
    'Load Pickup Schedules': (storePlantIds: string[]) => ({ storePlantIds }),
    'Get PickupSchedules': (storePlantIds: string[]) => ({ storePlantIds }),
    'Get Single PickupSchedule': (storePlantId: string) => ({ storePlantId }),
    'Get Pickup Schedules Success': (pickupSchedulesMap: {
      [key: string]: PickupScheduleRecord[];
    }) => ({ pickupSchedulesMap }),
    'Get Pickup Schedules Failure': (storePlantIds: string[]) => ({
      storePlantIds,
    }),
    'Clear Pickup Schedules': emptyProps(),
  },
});
