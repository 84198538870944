import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import {
  CheckRedemptionRequest,
  CustomerPointsRecord,
  StoreRecord,
  StoreRecords,
} from './model/store-record';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getStores(): Observable<StoreRecord[]> {
    return this.httpClient
      .get<StoreRecords>(`${this.webBffService.getBff()}/api/v1/stores`)
      .pipe(map((response) => response?.storeInformation || []));
  }

  checkRedemption(request: CheckRedemptionRequest): Observable<any> {
    return this.httpClient.post(
      `${this.webBffService.getBff()}/api/v1/stores/check-request`,
      request,
    );
  }

  retrieveCustomerPoints(): Observable<CustomerPointsRecord> {
    return this.httpClient.get<CustomerPointsRecord>(
      `${this.webBffService.getBff()}/api/v2/stores/points`,
    );
  }
}
