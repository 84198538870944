import { createActionGroup, emptyProps } from '@ngrx/store';
import { LastOrderedRecord } from '../../services/last-ordered/models/last-ordered-records';

export const LastOrderedActions = createActionGroup({
  source: 'Last Ordered',
  events: {
    'Load Last Ordered': (materialNumbers: string[]) => ({ materialNumbers }),
    'Get Last Ordered': (materialNumbers: string[]) => ({ materialNumbers }),
    'Get Last Ordered Success': (
      lastOrderedDateRecords: LastOrderedRecord[],
    ) => ({ lastOrderedDateRecords }),
    'Get Last Ordered Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Last Ordered': emptyProps(),
  },
});
