import { Injectable } from '@angular/core';
import { SessionRecord } from '../../../core/services/session/models/session-record';
import { FacebookConversionsRequest } from './models/facebook-conversions-request';
import {
  FacebookEvent,
  FacebookEventType,
} from '../facebook-tracking/facebook-events';
import { Observable } from 'rxjs';
import { NaooHttpClient } from '../../http-client/naoo-http-client';
import { getSha256Hash } from '../../utilities/hash-utilities';
import { getCurrentUnixTime } from '../../utilities/date-utilities';
import { FacebookConversionsResponse } from './models/facebook-conversions-response';

@Injectable({ providedIn: 'root' })
export class FacebookConversionsService {
  constructor(private httpClient: NaooHttpClient) {}

  async sendFacebookConversions(
    sessionRecord: SessionRecord,
    eventType: FacebookEventType,
    event?: FacebookEvent,
  ): Promise<Observable<FacebookConversionsResponse>> {
    const request = await this.getFacebookConversionsRequest(
      sessionRecord,
      eventType,
      event,
    );
    return this.httpClient.post<FacebookConversionsResponse>(
      '/facebook-conversions',
      request,
    );
  }

  private async getFacebookConversionsRequest(
    sessionRecord: SessionRecord,
    eventType: FacebookEventType,
    event?: FacebookEvent,
  ): Promise<FacebookConversionsRequest> {
    return {
      data: [
        {
          event_name: eventType,
          event_time: getCurrentUnixTime(),
          action_source: 'website',
          user_data: {
            em: [await getSha256Hash(sessionRecord?.email)],
            fn: [await getSha256Hash(sessionRecord?.firstName)],
            ln: [await getSha256Hash(sessionRecord?.lastName)],
          },
          custom_data: event,
        },
      ],
    };
  }
}
