import { createActionGroup, emptyProps } from '@ngrx/store';
import { CartEntityState, CartUpdateType } from './cart.state';
import {
  CartLineRecord,
  CartUpdateRequest,
} from '../../services/cart/models/cart-record';
import { SplitOrder } from '../../services/cart-order/models/cart-order';
import { Moment } from 'moment';
import { ExpressDeliveryWindow } from '../../services/express-schedules/models/express-schedule-record';
import { MaterialRowContext } from '../material-row/models/material-row';
import { AuxiliaryAnalyticsData } from '../../services/ecommerce-analytics/models/google-events';

export interface CartQuantityUpdate {
  materialNumber: string;
  lines: CartLineRecord[];
  context: MaterialRowContext;
  isAddedFromCriticalItem?: boolean;
  isAddedFromMaterialComparison?: boolean;
  merge?: boolean;
  isRollUpConversion?: boolean;
}

export const CartActions = createActionGroup({
  source: 'Cart',
  events: {
    'Open Fulfillment Modal': emptyProps(),
    'Update Express Store Fulfillment': (
      expressDeliveryWindow: ExpressDeliveryWindow,
    ) => ({ expressDeliveryWindow }),
    'Update Pickup Store Fulfillment For Cart Review': (
      storePlantId: string,
      date: Moment,
    ) => ({ storePlantId, date }),
    'Update Pickup Store Fulfillment For Order Method Modal': (
      storePlantId: string,
      date: Moment,
    ) => ({ storePlantId, date }),
    'Update Pickup Store Fulfillment For Same Day Delivery If Needed':
      emptyProps(),
    'Clear Early Cutoff Split Order': emptyProps(),
    'Clear Critical Item Section Flag': emptyProps(),
    'Remove Coupon From Cart': (couponCodeToRemove: string) => ({
      couponCodeToRemove,
    }),
    'Add Coupon To Cart': (couponCodeToAdd: string) => ({ couponCodeToAdd }),
    'Sync Offline Cart': emptyProps(),
    'Refresh Cart Success': (cart: CartEntityState) => ({ cart }),
    'Refresh Cart': (openFulfillmentModal?: boolean) => ({
      openFulfillmentModal,
    }),
    'Blur Cart Quantity Success': (materialNumber: string) => ({
      materialNumber,
    }),
    'Blur Cart Quantity': (
      materialNumber: string,
      uom: string,
      context: MaterialRowContext,
    ) => ({
      materialNumber,
      uom,
      context,
    }),
    'Focus Cart Quantity': (materialNumber: string) => ({ materialNumber }),
    'Clear Deleted Cart Materials': emptyProps(),
    'Restore Cart Material': (
      materialNumber: string,
      context: MaterialRowContext,
      analytics?: AuxiliaryAnalyticsData,
    ) => ({
      materialNumber,
      context,
      analytics,
    }),
    'Delete Cart Material': (
      materialNumber: string,
      analytics?: AuxiliaryAnalyticsData,
    ) => ({ materialNumber, analytics }),
    'Clear Cart Materials': emptyProps(),
    'Update Cart Materials': (materialNumbersToUpdate: string[]) => ({
      materialNumbersToUpdate,
    }),
    'Update Cart Quantities': (
      cartQuantityUpdates: CartQuantityUpdate[],
      analytics?: AuxiliaryAnalyticsData,
    ) => ({
      cartQuantityUpdates,
      analytics,
    }),
    'Update DropShip SiteId': (siteId: string) => ({ siteId }),
    'Reset Split Orders': emptyProps(),
    'Update Split Order': (splitOrder: SplitOrder) => ({ splitOrder }),
    'Update Po Number': (poNumber: string) => ({ poNumber }),
    'Update Route Date Success': (
      routeDate: Date,
      customerArrivalDate?: Date,
    ) => ({ routeDate, customerArrivalDate }),
    'Update Route Date': (routeDate: Date, customerArrivalDate?: Date) => ({
      routeDate,
      customerArrivalDate,
    }),
    'Update Cart Success': (cart: CartEntityState) => ({ cart }),
    'Update Cart Offline': emptyProps(),
    'Update Cart Online': (
      cartUpdateRequest: CartUpdateRequest,
      updateType: CartUpdateType,
      isFulfillmentDataChanged?: boolean,
    ) => ({ cartUpdateRequest, updateType, isFulfillmentDataChanged }),
    'Update Cart': (
      cartUpdateRequest: CartUpdateRequest,
      updateType: CartUpdateType,
    ) => ({ cartUpdateRequest, updateType }),
    'Submit Cart Event': emptyProps(),
  },
});
