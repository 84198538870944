import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  materialInfoAdapter,
  MaterialInfoRecordState,
  MaterialInfoState,
} from './material-info.state';
import { Dictionary } from '@ngrx/entity';
import {
  createMaterialInfoFromMaterialInfoRecordState,
  hasMaterialInfoFinishedLoading,
} from './material-info.util';

const selectFeature = createFeatureSelector<MaterialInfoState>('materialInfo');

export const selectAllMaterialInfoRecordStates = createSelector(
  selectFeature,
  materialInfoAdapter.getSelectors().selectEntities,
);

export const selectMaterialInfoRecordState = (materialNumber: string) =>
  createSelector(
    selectAllMaterialInfoRecordStates,
    (
      materialInfos: Dictionary<MaterialInfoRecordState>,
    ): MaterialInfoRecordState | undefined => materialInfos[materialNumber],
  );

export const selectMaterialInfoRecordStates = (materialNumbers: string[]) =>
  createSelector(
    selectAllMaterialInfoRecordStates,
    (
      materialInfos: Dictionary<MaterialInfoRecordState>,
    ): MaterialInfoRecordState[] =>
      materialNumbers?.map((id) => materialInfos[id]),
  );

export const selectIsCatchWeight = (materialNumber: string) =>
  createSelector(
    selectMaterialInfoRecordState(materialNumber),
    (materialInfo: MaterialInfoRecordState): boolean =>
      !!materialInfo?.record?.isCatchWeight,
  );

export const selectLoadedMaterialInfos = (materialNumbers: string[]) =>
  createSelector(
    selectMaterialInfoRecordStates(materialNumbers),
    (recordStates: MaterialInfoRecordState[]) => {
      const allLoaded = recordStates.every((recordState) =>
        hasMaterialInfoFinishedLoading(recordState),
      );

      if (!allLoaded) {
        return undefined;
      }

      return recordStates.map((recordState) =>
        createMaterialInfoFromMaterialInfoRecordState(recordState),
      );
    },
  );
