import {
  GenericWarning,
  WarningActionMessage,
} from '../../../../../core/services/product-warning/warning-factory';
import { AnalyticsEventInfo } from '../../../../analytics/analytics-event-info';

export class CustomGuideOverwriteWarning extends GenericWarning {
  readonly message = 'COPY_GUIDE.OVERWRITE_WARNING';
  readonly actionMessage = WarningActionMessage.DISMISS;
  readonly title = '';
  readonly urgent = false;

  constructor() {
    super(undefined, undefined);
  }

  getDisplayAnalyticsEvent(): AnalyticsEventInfo {
    return undefined;
  }
}
