@if ((!isMobile || shouldRenderPrint) && shouldRenderHeader) {
  <naoo-material-list-header
    class="material-list-header"
    [context]="context"
    [listStyle]="listStyle"
    [isParEnabled]="isParEnabled"
    [isSlimView]="isSlimView"
    [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
    [displayGoPointsLogo]="displayGoPointsLogo"
  />
}

<div #rowContainers class="not-printable">
  <virtual-scroller
    #scroll
    [items]="materialListItems"
    [bufferAmount]="virtualScrollBuffer$ | async"
    [scrollAnimationTime]="virtualScrollAnimationTime"
    [useMarginInsteadOfTranslate]="true"
    [parentScroll]="parentScroll"
    [childHeight]="rowHeight$ | async"
    (vsEnd)="vsEnd($event)"
    [ngClass]="{ 'centered-grid': isGridView }"
    [firstItemMargin]="firstItemMargin$ | async"
  >
    @for (
      materialListItem of scroll.viewPortItems;
      track materialListItem?.value
    ) {
      @if (materialListItem) {
        @if (materialListItem.type === materialRowType) {
          <naoo-material-row-container
            [materialNumber]="materialListItem.value"
            [context]="context"
            [listStyle]="listStyle"
            [isLast]="$last"
            [isFirst]="$first"
            [isMobile]="isMobile"
            [customGuideId]="customGuideId"
            [displayGoPointsLogo]="true"
            [analytics]="getAnalytics(materialListItem)"
          />
        } @else {
          <naoo-material-category-header
            [categoryHeader]="materialListItem.value"
            [listStyle]="listStyle"
            [context]="context"
            [isMobile]="isMobile"
            [isFirst]="$first && scroll.viewPortInfo.startIndexWithBuffer === 0"
            [isParEnabled]="isParEnabled"
            [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
            (categoryJump)="scrollToIndex($event)"
          />
        }
      }
    }
  </virtual-scroller>
</div>

@if (shouldRenderPrint) {
  <div class="print-list">
    @for (
      materialListItem of materialListItems;
      track materialListItem?.value
    ) {
      <naoo-material-row-container
        [materialNumber]="materialListItem.value"
        [context]="context"
        [listStyle]="listStyle"
        [isLast]="$last"
        [isFirst]="$first"
        [isMobile]="false"
        [analytics]="getAnalytics(materialListItem)"
      />
    }
  </div>
}
