import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CustomGuideFacade } from '../../../../../core/store/custom-guide/custom-guide.facade';

@Component({
  selector: 'naoo-copy-guide-copy-action',
  templateUrl: './copy-guide-copy-action.component.html',
  styleUrls: ['./copy-guide-copy-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyGuideCopyActionComponent implements AfterViewInit {
  @Input() nameOfGuideToCopy: string;
  @Input() customGuideId: string;
  @Input() customersToCopyTo: string[];

  @Output() copySuccess = new EventEmitter<void>();
  @Output() copyFailure = new EventEmitter<void>();

  constructor(private customGuideFacade: CustomGuideFacade) {}

  ngAfterViewInit() {
    this.customGuideFacade
      .copyCustomGuide(this.customGuideId, this.customersToCopyTo)
      .subscribe(
        () => {
          this.copySuccess.emit();
        },
        () => {
          this.copyFailure.emit();
        },
      );
  }

  get subtitle(): string {
    return this.customersToCopyTo && this.customersToCopyTo.length > 1
      ? 'COPY_GUIDE_ACTION.SUB_TITLE_PLURAL'
      : 'COPY_GUIDE_ACTION.SUB_TITLE';
  }
}
