import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { customerMaterialReducer } from './customer-material.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerMaterialEffects } from './customer-material.effects';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('customerMaterial', customerMaterialReducer),
    EffectsModule.forFeature([CustomerMaterialEffects]),
  ],
})
export class CustomerMaterialStoreModule {}
