import { MaterialCutoffActions } from './material-cutoff.actions';
import {
  initialMaterialCutoffState,
  materialCutoffAdapter,
  MaterialCutoffRecordState,
  MaterialCutoffRecordStatus,
  MaterialCutoffState,
} from './material-cutoff.state';
import { Update } from '@ngrx/entity';
import { CurrentSystem } from '../../services/session/models/session-record';
import { createReducer, on } from '@ngrx/store';
import { MaterialCutoffRecord } from '../../services/material-cutoff/models/material-cutoff-record';

export const materialCutoffReducer = createReducer(
  initialMaterialCutoffState,
  on(MaterialCutoffActions.loadMaterialCutoffs, (state, action) =>
    loadMaterialCutoffs(state, action),
  ),
  on(MaterialCutoffActions.getMaterialCutoffs, (state, action) =>
    getMaterialCutoffs(state, action),
  ),
  on(MaterialCutoffActions.getMaterialCutoffsSuccess, (state, action) =>
    getMaterialCutoffsSuccess(state, action),
  ),
  on(MaterialCutoffActions.getMaterialCutoffsError, (state, action) =>
    getMaterialCutoffsError(state, action),
  ),
  on(MaterialCutoffActions.refreshMaterialCutoffs, (state, action) =>
    refreshMaterialCutoffs(state, action),
  ),
  on(MaterialCutoffActions.clearMaterialCutoffs, (state, action) =>
    clearMaterialCutoffs(state, action),
  ),
);

function loadMaterialCutoffs(
  state: MaterialCutoffState,
  action: { currentSystem: CurrentSystem; cutoffCodes?: string[] },
) {
  switch (action.currentSystem) {
    case CurrentSystem.Sap:
    case CurrentSystem.Mygfs:
      return initialMaterialCutoffState;
    default:
      const upsertedEntities: MaterialCutoffRecordState[] = action.cutoffCodes
        ?.filter((cutoffCode) => {
          return (
            !state.cutoffs.entities[cutoffCode] ||
            state.cutoffs.entities[cutoffCode].status ===
              MaterialCutoffRecordStatus.Error
          );
        })
        .map((id) => {
          return {
            cutoffCode: id,
            status: MaterialCutoffRecordStatus.Queued,
            record: undefined,
          };
        });

      return {
        ...state,
        cutoffs: materialCutoffAdapter.upsertMany(
          upsertedEntities,
          state.cutoffs,
        ),
      };
  }
}

function getMaterialCutoffs(
  state: MaterialCutoffState,
  action: { currentSystem: CurrentSystem; cutoffCodes?: string[] },
) {
  switch (action.currentSystem) {
    case CurrentSystem.Sap:
    case CurrentSystem.Mygfs:
      return state;
    default:
      const queuedEntities: Update<MaterialCutoffRecordState>[] =
        action.cutoffCodes
          ?.filter((cutoffCode) => {
            return (
              state.cutoffs.entities[cutoffCode].status ===
              MaterialCutoffRecordStatus.Queued
            );
          })
          .map((cutoffCode) => {
            return {
              id: cutoffCode,
              changes: {
                status: MaterialCutoffRecordStatus.Requested,
                record: undefined,
              },
            };
          });

      return {
        ...state,
        cutoffs: materialCutoffAdapter.updateMany(
          queuedEntities,
          state.cutoffs,
        ),
      };
  }
}

function getMaterialCutoffsSuccess(
  state: MaterialCutoffState,
  action: {
    currentSystem: CurrentSystem;
    materialCutoffRecord: MaterialCutoffRecord;
  },
) {
  const upsertedCutoffRecords: MaterialCutoffRecordState[] =
    action.materialCutoffRecord.cutoffs.map((record) => ({
      cutoffCode: record.cutoffCode,
      status: MaterialCutoffRecordStatus.Success,
      record: record,
    }));

  const loadedAllCutoffs = CurrentSystem.isMygfsOrSap(action.currentSystem);
  return {
    ...state,
    cutoffs: materialCutoffAdapter.upsertMany(
      upsertedCutoffRecords,
      state.cutoffs,
    ),
    hasLoadedAllCutoffs: loadedAllCutoffs,
    considerInventory: action.materialCutoffRecord.considerInventory,
  };
}

function getMaterialCutoffsError(
  state: MaterialCutoffState,
  action: { currentSystem: CurrentSystem; cutoffCodes?: string[] },
) {
  switch (action.currentSystem) {
    case CurrentSystem.Sap:
    case CurrentSystem.Mygfs:
      return {
        ...state,
        hasLoadedAllCutoffs: true,
        considerInventory: false,
      };
    default:
      const updatedCutoffRecords: Update<MaterialCutoffRecordState>[] =
        action.cutoffCodes?.map((cutoffCode) => ({
          id: cutoffCode,
          changes: {
            status: MaterialCutoffRecordStatus.Error,
            record: undefined,
          },
        }));

      return {
        ...state,
        cutoffs: materialCutoffAdapter.updateMany(
          updatedCutoffRecords,
          state.cutoffs,
        ),
        considerInventory: false,
      };
  }
}

function refreshMaterialCutoffs(
  state: MaterialCutoffState,
  action: { currentSystem: CurrentSystem },
) {
  switch (action.currentSystem) {
    case CurrentSystem.Sap:
    case CurrentSystem.Mygfs:
      return initialMaterialCutoffState;
    default:
      const cutoffRecords = materialCutoffAdapter
        .getSelectors()
        .selectAll(state.cutoffs);

      const refreshedCutoffRecords: Update<MaterialCutoffRecordState>[] =
        cutoffRecords.map((record) => ({
          id: record.cutoffCode,
          changes: {
            status: MaterialCutoffRecordStatus.Queued,
            record: undefined,
          },
        }));

      return {
        ...state,
        cutoffs: materialCutoffAdapter.updateMany(
          refreshedCutoffRecords,
          state.cutoffs,
        ),
      };
  }
}

function clearMaterialCutoffs(
  state: MaterialCutoffState,
  action: { currentSystem: CurrentSystem },
) {
  switch (action.currentSystem) {
    case CurrentSystem.Sap:
    case CurrentSystem.Mygfs:
      return initialMaterialCutoffState;
    default:
      const cutoffRecords = materialCutoffAdapter
        .getSelectors()
        .selectAll(state.cutoffs);
      const clearedCutoffRecords: Update<MaterialCutoffRecordState>[] =
        cutoffRecords.map((record) => ({
          id: record.cutoffCode,
          changes: {
            status: MaterialCutoffRecordStatus.Cleared,
            record: undefined,
          },
        }));

      return {
        ...state,
        cutoffs: materialCutoffAdapter.updateMany(
          clearedCutoffRecords,
          state.cutoffs,
        ),
      };
  }
}
