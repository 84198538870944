import { createActionGroup, emptyProps } from '@ngrx/store';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';

export const CustomerMaterialActions = createActionGroup({
  source: 'Custom Item Codes',
  events: {
    'Get Customer Material': emptyProps(),
    'Get Customer Material Success': (payload: CustomerMaterialRecord) => ({
      payload,
    }),
    'Refresh Customer Material Codes': emptyProps(),
  },
});
