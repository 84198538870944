import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AvailableRouteDate,
  RouteDateContent,
} from '../../shared/models/delivery-date-picker';
import moment from 'moment-timezone';
import { CurrentSystem } from '../../../../core/services/session/models/session-record';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';

@Component({
  selector: 'naoo-delivery-date-picker-details',
  templateUrl: './delivery-date-picker-details.component.html',
  styleUrls: ['./delivery-date-picker-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDatePickerDetailsComponent {
  @Input() routeDateContent: RouteDateContent;
  @Input() selectedAvailableRouteDate?: AvailableRouteDate;
  @Output() saveRouteDate = new EventEmitter<Date>();
  @Output() justBrowsing = new EventEmitter<void>();
  @ViewChild('desktopSaveButton')
  desktopSaveButton: ElementRef<HTMLButtonElement>;

  constructor(private localizationService: LocalizationService) {}

  get isJustBrowsing(): boolean {
    return (
      this.routeDateContent.isFirstDisplay &&
      !this.routeDateContent.isRouteDateExpired
    );
  }

  get calculatedAvailableRouteDate(): AvailableRouteDate {
    return (
      this.selectedAvailableRouteDate || this.routeDateContent.defaultRouteDate
    );
  }

  get customerCutoffTimeZoneAbbreviation(): string {
    return moment
      .tz(
        this.calculatedAvailableRouteDate.cutoffDateTime,
        this.routeDateContent.customerTimeZone,
      )
      .zoneAbbr();
  }

  get localizedCustomerCutoffTimeZoneAbbreviation(): string {
    return this.localizationService.instant(
      'TIMEZONES.' + this.customerCutoffTimeZoneAbbreviation,
    );
  }

  get shouldUseCustomerArrivalDate(): boolean {
    return CurrentSystem.isMygfsOrSap(this.routeDateContent.currentSystem);
  }

  get shouldDisplayCutoffDeadline(): boolean {
    const cutoffDateTime =
      this.calculatedAvailableRouteDate?.cutoffDateTime?.toString();
    return cutoffDateTime ? !!Date.parse(cutoffDateTime) : false;
  }

  onSaveClicked() {
    this.saveRouteDate.emit(this.calculatedAvailableRouteDate.routeDate);
  }

  onJustBrowsingClicked() {
    this.justBrowsing.emit();
  }

  focusDesktopSaveButton() {
    this.desktopSaveButton.nativeElement.focus();
  }
}
