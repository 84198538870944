import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { MaterialRelatedListRecord } from './model/material-related-record';

@Injectable({ providedIn: 'root' })
export class MaterialRelatedService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getMaterialRelated(
    materialNumbers: string[],
  ): Observable<MaterialRelatedListRecord> {
    return this.httpClient.post<MaterialRelatedListRecord>(
      `${this.webBffService.getBff()}/api/v1/materials/related`,
      materialNumbers,
    );
  }
}
