import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { MaterialInfoActions } from './material-info.actions';
import {
  selectAllMaterialInfoRecordStates,
  selectLoadedMaterialInfos,
} from './material-info.selectors';
import { MaterialInfoRecordState } from './material-info.state';
import { MaterialInfo } from '../../../shared/models/material-info';
import { Dictionary } from '@ngrx/entity';

@Injectable({ providedIn: 'root' })
export class MaterialInfoFacade {
  constructor(private store: Store) {}

  loadMaterialInfos(materialNumbers: string[]) {
    this.store.dispatch(MaterialInfoActions.loadMaterialInfo(materialNumbers));
  }

  getLoadedMaterialInfos(
    materialNumbers: string[],
  ): Observable<MaterialInfo[]> {
    return this.store
      .select(selectLoadedMaterialInfos(materialNumbers))
      .pipe(filter((materialInfo) => !!materialInfo));
  }

  getLoadedMaterialInfoMap(
    materialNumbers: string[],
  ): Observable<Map<string, MaterialInfo>> {
    return this.getLoadedMaterialInfos(materialNumbers).pipe(
      map(
        (materialInfos) =>
          new Map(
            materialInfos.filter((i) => !!i).map((i) => [i.materialNumber, i]),
          ),
      ),
    );
  }

  clearMaterialInfo() {
    this.store.dispatch(MaterialInfoActions.clearMaterialInfo());
  }

  getAllMaterialInfos(): Observable<Dictionary<MaterialInfoRecordState>> {
    return this.store.select(selectAllMaterialInfoRecordStates);
  }
}
