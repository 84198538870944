import { createFeatureSelector, createSelector } from '@ngrx/store';
import { customGuideAdapter, CustomGuidesState } from './custom-guide.state';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import {
  getCustomGuideCategorizedMaterials,
  getCustomGuideMaterialParOrderMap,
  getGuideMaterialCount,
  getMaterialNumbersForCategories,
} from './custom-guide.util';
import {
  CustomGuideParLineRecord,
  CustomGuideRecord,
} from '../../services/custom-guide/model/custom-guide-record';
import { selectAllLastOrderedRecords } from '../last-ordered/last-ordered.selectors';
import { CategorizedCustomGuide } from '../../../shared/models/categorized-materials';
import { CustomGuideTransformer } from './custom-guide-transformer';
import { selectPreferredMaterialView } from '../material-row/material-view.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import { CustomGuide } from './models/custom-guide';
import { selectLanguage } from '../session/session.selectors';
import { MaterialInfoRecordStatus } from '../material-info/material-info.state';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';

export interface CustomGuideMaterialParOrder {
  id: string;
  materialNumber: string;
  parLineRecords: CustomGuideParLineRecord[];
  isParOrderingEnabled: boolean;
}

export interface CustomGuideInfo {
  id: string;
  name: string;
  totalCustomGuideProducts: number;
}

const selectCustomGuideFeature =
  createFeatureSelector<CustomGuidesState>('customGuide');

const selectCustomGuideRecordStates = createSelector(
  selectCustomGuideFeature,
  (state) => customGuideAdapter.getSelectors().selectAll(state.recordsState),
);

export const selectHasCustomGuidesLoaded = createSelector(
  selectCustomGuideFeature,
  (state) => state.hasLoaded,
);

export const selectCustomGuide = (customGuideId: string) =>
  createSelector(
    selectCustomGuideFeature,
    selectPreferredMaterialView,
    selectAllMaterialInfoRecordStates,
    selectCustomerMaterialRecord,
    selectAllLastOrderedRecords,
    selectIsOffline,
    selectLanguage,
    (
      customGuideState,
      preferredView,
      materialInfoRecords,
      customerMaterial,
      lastOrderedRecords,
      isOffline,
      currentLanguage,
    ): CustomGuide =>
      CustomGuideTransformer.transformCustomGuide(
        customGuideId,
        customGuideState,
        preferredView,
        materialInfoRecords,
        customerMaterial,
        lastOrderedRecords,
        isOffline,
        currentLanguage,
      ),
  );

export const selectAllCustomGuideRecordStateEntities = createSelector(
  selectCustomGuideFeature,
  (state) =>
    customGuideAdapter.getSelectors().selectEntities(state.recordsState),
);

export const selectAllCustomGuideRecords = createSelector(
  selectCustomGuideRecordStates,
  (recordsState) =>
    recordsState ? recordsState.map((recordState) => recordState.record) : [],
);

export const selectAllCustomGuideMaterialNumbers = createSelector(
  selectAllCustomGuideRecords,
  (customGuides: CustomGuideRecord[]): string[] =>
    customGuides
      .map((record) => getMaterialNumbersForCategories(record?.categories))
      .reduce(
        (acc, customGuideMaterialNumbers) =>
          acc.concat(customGuideMaterialNumbers),
        [],
      ),
);

export const selectCustomGuideRecordState = (customGuideId: string) =>
  createSelector(selectAllCustomGuideRecordStateEntities, (entities) =>
    entities ? entities[customGuideId] : undefined,
  );

export const selectCustomGuideRecord = (customGuideId: string) =>
  createSelector(
    selectHasCustomGuidesLoaded,
    selectCustomGuideRecordState(customGuideId),
    (hasLoaded, recordState) =>
      hasLoaded && recordState ? recordState.record : undefined,
  );

export const selectCategorizedCustomGuide = (customGuideId: string) =>
  createSelector(
    selectCustomGuideRecordState(customGuideId),
    selectAllMaterialInfoRecordStates,
    selectAllLastOrderedRecords,
    (
      recordState,
      materialInfos,
      lastOrderedRecords,
    ): CategorizedCustomGuide => {
      if (!recordState || !recordState.record) {
        return undefined;
      }
      const groupBy = recordState.record.groupBy;
      const categorizedMaterials = getCustomGuideCategorizedMaterials(
        recordState,
        materialInfos,
        lastOrderedRecords,
        groupBy,
      );
      if (!categorizedMaterials) {
        return undefined;
      }
      const sortBy = recordState.record.sortBy;
      return {
        groupBy,
        sortBy,
        categorizedMaterials,
      };
    },
  );

export const selectCustomGuideMaterialParOrders = (customGuideId: string) =>
  createSelector(
    selectCustomGuideRecord(customGuideId),
    (customGuideRecord) => {
      if (!customGuideRecord || !customGuideRecord.categories) {
        return new Map();
      }
      return getCustomGuideMaterialParOrderMap(customGuideRecord);
    },
  );

export const selectAllCustomGuideInfoRecords = createSelector(
  selectAllCustomGuideRecords,
  selectAllMaterialInfoRecordStates,
  (customGuideRecords, materialInfoRecords): CustomGuideInfo[] => {
    return customGuideRecords.map((customGuide): CustomGuideInfo => {
      const totalCustomGuideProducts = getGuideMaterialCount(
        customGuide.categories,
        materialInfoRecords,
      );
      return {
        id: customGuide.id,
        name: customGuide.name,
        totalCustomGuideProducts,
      };
    });
  },
);

export const selectHasParQuantities = (customGuideId: string) =>
  createSelector(
    selectCustomGuideRecord(customGuideId),
    selectAllMaterialInfoRecordStates,
    (record, materialInfos) => {
      if (!record) {
        return false;
      }

      return record.categories.some((category) =>
        category.materials.some((material) => {
          const materialInfo = materialInfos[material.materialNumber];
          if (
            !!materialInfo &&
            materialInfo.status !== MaterialInfoRecordStatus.Unavailable
          ) {
            return material.parLines.some((parLine) => parLine.parQuantity > 0);
          } else {
            return false;
          }
        }),
      );
    },
  );

export const selectCustomGuideExportMaterialsInput = (
  customGuideId: string,
  isPrint: boolean,
) =>
  createSelector(
    selectCustomGuideRecordState(customGuideId),
    selectAllMaterialInfoRecordStates,
    selectAllLastOrderedRecords,
    selectCustomerMaterialRecord,
    selectLanguage,
    (
      customGuideState,
      materialInfos,
      lastOrderedRecords,
      customerMaterialRecord,
      language,
    ): ExportMaterialsInput =>
      CustomGuideTransformer.transformCustomGuideExportMaterialsInput(
        customGuideState,
        materialInfos,
        lastOrderedRecords,
        customerMaterialRecord,
        language,
        isPrint,
      ),
  );
