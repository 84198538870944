import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { MaterialUnitsActions } from './material-units.actions';
import { catchError, exhaustMap, map, takeUntil } from 'rxjs/operators';
import { MaterialUnitsService } from '../../services/material-units/material-units.service';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';

@Injectable({ providedIn: 'root' })
export class MaterialUnitsEffects {
  constructor(
    private actions$: Actions,
    private unitsService: MaterialUnitsService,
  ) {}

  getMaterialUnits$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialUnitsActions.getMaterialUnitsInformation),
      exhaustMap(() => {
        return this.unitsService.getMaterialUnitsInformation().pipe(
          map((displayCodes: MaterialUnitsRecord[]) =>
            MaterialUnitsActions.getMaterialUnitsInformationSuccess(
              displayCodes,
            ),
          ),
          catchError(() =>
            of(MaterialUnitsActions.getMaterialUnitsInformationError()),
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(MaterialUnitsActions.refreshMaterialUnitsInformation),
            ),
          ),
        );
      }),
    );
  });

  refreshMaterialUnitsInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialUnitsActions.refreshMaterialUnitsInformation),
      map(() => {
        return MaterialUnitsActions.getMaterialUnitsInformation();
      }),
    );
  });
}
