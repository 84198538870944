export class ListsAnalyticsConstants {
  // Categories
  static readonly customGuideCategory = 'custom guide';
  static readonly criticalItemsCategory = 'critical items';
  static readonly orderGuideCategory = 'order guide';
  static readonly marketingGuideCategory = 'marketing guide';

  // Actions
  static readonly clickAction = 'click';
  static readonly enterAction = 'Enter';
}

export enum CustomGuideCreationSourceAnalyticLabel {
  Cart = 'create new guide from cart options',
  MaterialMenu = 'add to new guide',
  CreateNewGuide = 'Create a new guide',
  Duplicate = 'duplicate guide',
  OrderGuideChanges = 'Create a new guide - OG Changes',
}
