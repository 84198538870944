import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartActions, CartQuantityUpdate } from './cart.actions';
import { Observable } from 'rxjs';
import { Cart } from '../../../shared/models/cart';
import {
  selectFulfillmentChangesHaveCompleted,
  selectFulfillmentType,
  selectInvalidDropShipOrders,
  selectIsCartLoaded,
  selectIsDoneUpdating,
  selectIsPoNumberInvalid,
  selectLoadedCart,
  selectRouteDate,
} from './cart.selectors';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  CartPriceTotals,
  selectCartPriceTotals,
} from '../material-price/material-price.selectors';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { SplitOrder } from '../../services/cart-order/models/cart-order';
import { Moment } from 'moment';
import { ExpressDeliveryWindow } from '../../services/express-schedules/models/express-schedule-record';
import { MaterialRowContext } from '../material-row/models/material-row';
import { AuxiliaryAnalyticsData } from '../../services/ecommerce-analytics/models/google-events';

@Injectable({ providedIn: 'root' })
export class CartFacade {
  constructor(private store: Store) {}

  refreshCart(): void {
    this.store.dispatch(CartActions.refreshCart());
  }

  openFulfillmentModal(): void {
    this.store.dispatch(CartActions.openFulfillmentModal());
  }

  updateRouteDate(routeDate: Date, customerArrivalDate?: Date) {
    this.store.dispatch(
      CartActions.updateRouteDate(routeDate, customerArrivalDate),
    );
  }

  updatePoNumber(poNumber: string): void {
    this.store.dispatch(CartActions.updatePoNumber(poNumber));
  }

  clearCartMaterials(): void {
    this.store.dispatch(CartActions.clearCartMaterials());
  }

  updateSplitOrder(splitOrder: SplitOrder): void {
    this.store.dispatch(CartActions.updateSplitOrder(splitOrder));
  }

  clearSplitOrders(): void {
    this.store.dispatch(CartActions.resetSplitOrders());
  }

  clearEarlyCutOffSplitOrder(): void {
    this.store.dispatch(CartActions.clearEarlyCutoffSplitOrder());
  }

  clearAddedFromCriticalItemsSectionFlag(): void {
    this.store.dispatch(CartActions.clearCriticalItemSectionFlag());
  }

  updateDropShipSiteId(siteId: string): void {
    this.store.dispatch(CartActions.updateDropShipSiteId(siteId));
  }

  updateCartQuantities(
    cartQuantities: CartQuantityUpdate[],
    analytics?: AuxiliaryAnalyticsData,
  ): void {
    this.store.dispatch(
      CartActions.updateCartQuantities(cartQuantities, analytics),
    );
  }

  deleteCartMaterial(itemId: string, recommendationEngineName?: string): void {
    this.store.dispatch(
      CartActions.deleteCartMaterial(itemId, {
        recommendationEngineName,
      }),
    );
  }

  restoreCartMaterial(
    itemId: string,
    context: MaterialRowContext,
    analytics?: AuxiliaryAnalyticsData,
  ): void {
    this.store.dispatch(
      CartActions.restoreCartMaterial(itemId, context, analytics),
    );
  }

  focusCartQuantity(itemId: string): void {
    this.store.dispatch(CartActions.focusCartQuantity(itemId));
  }

  blurCartQuantity(
    itemId: string,
    uom: string,
    context: MaterialRowContext,
  ): void {
    this.store.dispatch(CartActions.blurCartQuantity(itemId, uom, context));
  }

  clearDeletedCartMaterials(): void {
    this.store.dispatch(CartActions.clearDeletedCartMaterials());
  }

  getLoadedCart(): Observable<Cart> {
    return this.store
      .select(selectLoadedCart)
      .pipe(filter((cartEntityState) => !!cartEntityState?.materials));
  }

  getRouteDate(): Observable<Date> {
    return this.store.select(selectRouteDate);
  }

  getInvalidDropShipOrders(): Observable<SplitOrder[]> {
    return this.store.select(selectInvalidDropShipOrders);
  }

  getLoadedCustomerArrivalDate(): Observable<Date> {
    return this.getLoadedCart().pipe(
      map((cart) => cart.truckFulfillment?.customerArrivalDate),
    );
  }

  isShoppingContextSelected(): Observable<boolean> {
    return this.getLoadedCart().pipe(
      map(
        (cart) =>
          !!cart.truckFulfillment?.routeDate ||
          !!cart.storeFulfillment?.deliveryWindowEndTimestamp ||
          !!cart.storeFulfillment?.requestedPickupTimestamp,
      ),
    );
  }

  getLoadedRouteDate(): Observable<Date> {
    return this.getLoadedCart().pipe(
      map((cart) => cart.truckFulfillment?.routeDate),
    );
  }

  isDoneUpdating(): Observable<boolean> {
    return this.store.select(selectIsDoneUpdating);
  }

  isPoNumberInvalid(): Observable<boolean> {
    return this.store.select(selectIsPoNumberInvalid);
  }

  getCartPriceTotals(): Observable<CartPriceTotals> {
    return this.store.select(selectCartPriceTotals());
  }

  isCartLoaded(): Observable<boolean> {
    return this.store.select(selectIsCartLoaded).pipe(distinctUntilChanged());
  }

  getSelectedFulfillmentType(): Observable<FulfillmentType> {
    return this.store.select(selectFulfillmentType);
  }

  fulfillmentChangesHaveCompleted(): Observable<boolean> {
    return this.store
      .select(selectFulfillmentChangesHaveCompleted)
      .pipe(distinctUntilChanged());
  }

  updatePickupStoreFulfillmentForOrderMethodModal(
    storePlantId: string,
    date: Moment,
  ): void {
    return this.store.dispatch(
      CartActions.updatePickupStoreFulfillmentForOrderMethodModal(
        storePlantId,
        date,
      ),
    );
  }

  updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded(): void {
    return this.store.dispatch(
      CartActions.updatePickupStoreFulfillmentForSameDayDeliveryIfNeeded(),
    );
  }

  updateExpressStoreFulfillment(
    expressDeliveryWindow: ExpressDeliveryWindow,
  ): void {
    return this.store.dispatch(
      CartActions.updateExpressStoreFulfillment(expressDeliveryWindow),
    );
  }

  submitCartEvent(): void {
    return this.store.dispatch(CartActions.submitCartEvent());
  }
}
