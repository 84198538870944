import { createActionGroup, emptyProps } from '@ngrx/store';
import { NutritionLabel } from './models/nutrition';

export const NutritionActions = createActionGroup({
  source: 'Nutrition',
  events: {
    'Load Nutrition': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Nutrition': (materialNumber: string) => ({ materialNumber }),
    'Get Nutrition Success': (
      materialNumber: string,
      nutritionLabel: NutritionLabel,
    ) => ({
      materialNumber,
      nutritionLabel,
    }),
    'Get Nutrition Error': (materialNumber: string) => ({
      materialNumber,
    }),
    'Clear Nutrition': emptyProps(),
  },
});
