export enum OrderType {
  Stock = 'STOCK',
  NonStock = 'NON_STOCK',
  AutoShip = 'AUTO_SHIP',
  DropShip = 'DROP_SHIP',
  Invoiced = 'INVOICED',
  StoreFulfillment = 'STORE_FULFILLMENT',
}

export class RequestedDeliveryType {
  static EXPRESS = '04';
}
