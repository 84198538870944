import { createReducer, on } from '@ngrx/store';
import {
  initialMaterialRecommendationsState,
  materialRecommendationsAdapter,
  MaterialRecommendationsRecordState,
  MaterialRecommendationsRequestStatus,
  MaterialRecommendationsState,
  Page,
} from './material-recommendations.state';
import { MaterialRecommendationsActions } from './material-recommendations.actions';
import { Update } from '@ngrx/entity';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';

export const materialRecommendationsReducer = createReducer(
  initialMaterialRecommendationsState,
  on(
    MaterialRecommendationsActions.loadMaterialRecommendations,
    (state, action): MaterialRecommendationsState =>
      loadMaterialRecommendations(state, action.page, action.materialNumber),
  ),
  on(
    MaterialRecommendationsActions.getMaterialRecommendations,
    (state, action): MaterialRecommendationsState =>
      getMaterialRecommendations(state, action.page),
  ),
  on(
    MaterialRecommendationsActions.getMaterialRecommendationsSuccess,
    (state, action): MaterialRecommendationsState =>
      getMaterialRecommendationsSuccess(
        state,
        action.page,
        action.materialRecommendations,
      ),
  ),
  on(
    MaterialRecommendationsActions.getMaterialRecommendationsError,
    (state, action): MaterialRecommendationsState =>
      getMaterialRecommendationsError(state, action.page),
  ),
  on(
    MaterialRecommendationsActions.refreshMaterialRecommendations,
    MaterialRecommendationsActions.clearMaterialRecommendations,
    (): MaterialRecommendationsState => initialMaterialRecommendationsState,
  ),
);

function loadMaterialRecommendations(
  state: MaterialRecommendationsState,
  page: Page,
  materialNumber?: string,
): MaterialRecommendationsState {
  const recordState = state.entities[page];
  const shouldQueue =
    !recordState ||
    recordState.status === MaterialRecommendationsRequestStatus.Error ||
    recordState.materialNumber !== materialNumber;
  if (!shouldQueue) {
    return state;
  }

  return materialRecommendationsAdapter.upsertOne(
    {
      page,
      status: MaterialRecommendationsRequestStatus.Queued,
      materialRecommendations: undefined,
      materialNumber,
    },
    state,
  );
}

function getMaterialRecommendations(
  state: MaterialRecommendationsState,
  page: string,
): MaterialRecommendationsState {
  return materialRecommendationsAdapter.updateOne(
    {
      id: page,
      changes: {
        status: MaterialRecommendationsRequestStatus.Requested,
      },
    },
    state,
  );
}

function getMaterialRecommendationsSuccess(
  state: MaterialRecommendationsState,
  page: string,
  materialRecommendations: MaterialRecommendations[],
): MaterialRecommendationsState {
  const updatedMaterialRecommendationsState: Update<MaterialRecommendationsRecordState> =
    {
      id: page,
      changes: {
        materialRecommendations,
        status: MaterialRecommendationsRequestStatus.Success,
      },
    };

  return materialRecommendationsAdapter.updateOne(
    updatedMaterialRecommendationsState,
    state,
  );
}

function getMaterialRecommendationsError(
  state: MaterialRecommendationsState,
  page: Page,
): MaterialRecommendationsState {
  const updatedMaterialRecommendationsState: Update<MaterialRecommendationsRecordState> =
    {
      id: page,
      changes: {
        status: MaterialRecommendationsRequestStatus.Error,
      },
    };

  return materialRecommendationsAdapter.updateOne(
    updatedMaterialRecommendationsState,
    state,
  );
}
