import {
  CartLineState,
  CartMaterialState,
  FocusedMaterialState,
} from '../../core/store/cart/cart.state';
import { Dictionary } from '@ngrx/entity';
import { CartOrderMaterialRecord } from '../../core/services/cart-order/models/cart-order';
import { CartLine } from '../models/cart-material';
import { CartQuantityUpdate } from '../../core/store/cart/cart.actions';
import { MaterialRowContext } from '../../core/store/material-row/models/material-row';

export function filterNonEmptyCartLines(
  lines: Dictionary<CartLineState>,
): CartLineState[] {
  return Object.values(lines).filter(
    (line: CartLineState) => line.quantity > 0,
  );
}

export function getQuantityFromCartMaterial(
  cartMaterial: CartMaterialState | FocusedMaterialState,
  unitOfMeasure: string,
): number {
  return cartMaterial?.lines?.entities?.[unitOfMeasure]?.quantity ?? 0;
}

export function getQuantityFromCartOrderMaterial(
  cartOrderMaterial: CartOrderMaterialRecord,
  unitOfMeasure: string,
): number {
  const unitAvailable = cartOrderMaterial.lines.find(
    (unit) => unit.uom === unitOfMeasure,
  );
  return !!unitAvailable ? unitAvailable.quantity : 0;
}

interface MaterialToMerge {
  materialNumber: string;
  lines: CartLine[];
}

export function mergeMaterialList(
  materialList1: MaterialToMerge[],
  materialList2: MaterialToMerge[],
  context: MaterialRowContext,
): CartQuantityUpdate[] {
  const materialMap = new Map<string, CartLine[]>();
  const combinedList: CartQuantityUpdate[] = [];

  materialList1?.forEach((cartMaterial) => {
    materialMap.set(cartMaterial.materialNumber, cartMaterial.lines);
  });

  materialList2?.forEach((cartMaterial) => {
    if (!materialMap.has(cartMaterial.materialNumber)) {
      materialMap.set(cartMaterial.materialNumber, []);
    }
    const cartLines = materialMap.get(cartMaterial.materialNumber) || [];
    cartMaterial.lines.forEach((cartLine) => {
      const lineToUpdate = cartLines.find((line) => line.uom == cartLine.uom);
      if (lineToUpdate) {
        lineToUpdate.quantity += cartLine.quantity;
      } else {
        cartLines.push({
          uom: cartLine.uom,
          quantity: cartLine.quantity,
        });
      }
    });
  });

  for (const [materialNumber, uomMap] of materialMap) {
    combinedList.push({
      materialNumber: materialNumber,
      lines: uomMap,
      context: context,
      isAddedFromCriticalItem: undefined,
      isAddedFromMaterialComparison: undefined,
      merge: undefined,
      isRollUpConversion: undefined,
    });
  }

  return combinedList;
}
