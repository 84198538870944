import { HttpClient } from '@angular/common/http';
import { BannerAdsRecord } from './models/banner-ads-record';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';

export enum HomepageBannerAdSlotName {
  BannerAdSlot1 = 'homepage-ad-slot-1',
  BannerAdSlot2 = 'homepage-ad-slot-2',
  BannerAdSlot3 = 'homepage-ad-slot-3',
  BannerAdSlot4 = 'homepage-ad-slot-4',
  BannerAdSlot5 = 'homepage-ad-slot-5',
  BannerAdSlot6 = 'homepage-ad-slot-6',
  BannerAdSlot7 = 'homepage-ad-slot-7',
  BannerAdSlot8 = 'homepage-ad-slot-8',
  BannerAdSlot9 = 'homepage-ad-slot-9',
  BannerAdSlot10 = 'homepage-ad-slot-10',
  BannerAdSlot11 = 'homepage-ad-slot-11',
  BannerAdSlot12 = 'homepage-ad-slot-12',
}

@Injectable({ providedIn: 'root' })
export class BannerAdsService {
  private readonly noRedirectQueryParam = '&noredirect';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  getBannerAds(): Observable<BannerAdsRecord> {
    return this.httpClient.post<BannerAdsRecord>(
      this.webBffService.getBff() + `/api/v1/banners`,
      this.buildBannerAdRequestBody(),
    );
  }

  sendBannerAdsImpression(impressionUrl: string): void {
    this.httpClient
      .get(impressionUrl, {
        responseType: 'blob',
        withCredentials: true,
      })
      .pipe(first())
      .subscribe(
        () => {},
        () => {},
      );
  }

  sendBannerAdClickEvent(clickUrl: string): void {
    this.httpClient
      .get(clickUrl + this.noRedirectQueryParam, { withCredentials: true })
      .pipe(first())
      .subscribe(
        () => {},
        () => {},
      );
  }

  private buildBannerAdRequestBody(): string[] {
    return Object.values(HomepageBannerAdSlotName);
  }
}
