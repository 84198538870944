import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { selectCartMaterialEntities } from '../cart/cart.selectors';
import { CartActions } from '../cart/cart.actions';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { CartLineRecord } from '../../services/cart/models/cart-record';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { NaooSessionStorage } from '../../../shared/storage/session-storage/session-storage.service';
import { CartSortType } from './active-cart-summary.state';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { ActiveCartSummaryActions } from './active-cart-summary.actions';
import { SharedActions } from '../shared/shared.actions';
import { MaterialRowContext } from '../material-row/models/material-row';

@Injectable()
export class ActiveCartSummaryEffects {
  private readonly sortByLocalStorageKey = 'naoo-cart-sort-by-key';

  constructor(
    private store: Store,
    private actions$: Actions,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private analyticsManager: NaooAnalyticsManager,
    private sessionStorage: NaooSessionStorage,
  ) {}

  enableQuickAdd$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActiveCartSummaryActions.enableQuickAdd),
      map(() => ActiveCartSummaryActions.updateCartSort(CartSortType.AsAdded)), // reset sort when quick add is enabled
    );
  });

  updateQuickAddQuantities$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActiveCartSummaryActions.updateQuickAddQuantities),
      concatLatestFrom(() => this.store.select(selectCartMaterialEntities)),
      mergeMap(([action, cartMaterials]) => {
        const existingCartMaterial = cartMaterials[action.materialNumber];

        if (existingCartMaterial) {
          this.toastMessageService.showToastMessage(
            this.localizationService.instant(
              'CART_SUMMARY.QUICK_ADD.DUPLICATE_ITEM_TOAST',
              {
                materialNumber: action.materialNumber,
                quantity: action.caseQuantity,
              },
            ),
          );

          this.analyticsManager.trackAnalyticsEvent({
            action: 'added',
            category: 'quick add',
            label: 'duplicate item',
          });
        }

        const caseLine: CartLineRecord = {
          uom: NaooConstants.Uom.Case,
          quantity: action.caseQuantity,
        };

        return [
          CartActions.updateCartQuantities([
            {
              materialNumber: action.materialNumber,
              lines: [caseLine],
              context: MaterialRowContext.QuickAdd,
            },
          ]),
          MaterialAvailabilityActions.loadMaterialAvailability([
            action.materialNumber,
          ]),
          MaterialInfoActions.loadMaterialInfo([action.materialNumber]),
        ];
      }),
    );
  });

  updateCartSort$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ActiveCartSummaryActions.updateCartSort),
        map((action) => {
          this.sessionStorage.setItem(
            this.sortByLocalStorageKey,
            action.sortBy,
          );
        }),
      );
    },
    { dispatch: false },
  );

  refreshCartSort$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActiveCartSummaryActions.refreshCartSort),
      map(() => {
        const sortBy = this.sessionStorage.getItem(this.sortByLocalStorageKey);
        if (!sortBy) {
          return SharedActions.noOperation('Nothing in store for cart sort');
        } else {
          return ActiveCartSummaryActions.updateCartSort(
            sortBy as CartSortType,
          );
        }
      }),
    );
  });
}
