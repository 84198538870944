import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { WarningOpenDirection } from 'src/app/core/store/material-warning/material-warning';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  MaterialOrdering,
  MaterialOrderingInfo,
  MaterialOrderingTab,
  MaterialRowSharedOptions,
} from '../../../../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-ordering-tab',
  templateUrl: './material-ordering-tab.component.html',
  styleUrls: ['./material-ordering-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialOrderingTabComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() materialOrdering: MaterialOrdering;
  @Input() isMobile: boolean;
  @Input() displayGoPointsLogo: boolean;

  @Output() viewCommodityDetailsClicked = new EventEmitter();

  readonly materialOpenDirection = WarningOpenDirection.Up;

  selectedTabIndex = 0;

  get displayMaxAllocation(): boolean {
    return (
      this.isMobile &&
      this.materialRowOptions?.materialCommodityInfo?.displayMaxAllocation
    );
  }

  get displayLoyaltyPoints(): boolean {
    return !!(
      this.materialRowOptions.isLoyaltyProgramEligible &&
      this.selectedMaterialOrderingInfo?.loyaltyPoints
    );
  }

  get selectedMaterialOrderingInfo(): MaterialOrderingInfo {
    return this.materialOrdering.materialOrderingInfos[this.selectedTabIndex];
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  trackByTab(index: number, tab: MaterialOrderingTab): string {
    return tab.uom;
  }
}
