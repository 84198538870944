export class ImportGuideCategorizedProducts {
  categoryName: string;
  productIds: string[];

  constructor(categoryName: string, productId: string) {
    this.categoryName = categoryName;
    this.productIds = [];
    if (productId) {
      this.productIds.push(productId);
    }
  }

  addProduct(productId: string) {
    this.productIds.push(productId);
  }
}
