import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.state';
import {
  CustomerBrand,
  CustomerPermission,
  Language,
  Locale,
  SessionActiveCustomer,
  SessionCustomer,
  SessionRecord,
} from '../../services/session/models/session-record';
import { GordonNowData } from '../../../shared/gordon-now/gordon-now-chat.model';

const wholesalePrimaryChannel = 'WHOLESALE';
const grmPrimaryChannel = 'GRM';

const selectSessionFeature = createFeatureSelector<SessionState>('session');

const storeCustomerPermissions = new Set([
  CustomerPermission.ExpressDelivery,
  CustomerPermission.InStorePickup,
]);

export const selectPreviousCompositeCustomerId = createSelector(
  selectSessionFeature,
  (state: SessionState) => state?.previousSelectedCompositeCustomerId,
);

export const selectCurrentCompositeCustomerId = createSelector(
  selectSessionFeature,
  (state: SessionState) => state?.sessionRecord?.activeCustomer?.naooCustomerId,
);

export const selectSession = createSelector(
  selectSessionFeature,
  (state: SessionState) => state?.sessionRecord,
);

export const selectIsMultiCustomerUnit = createSelector(
  selectSession,
  (sessionRecord: SessionRecord): boolean =>
    sessionRecord?.customers.length > 1,
);

export const selectActiveCustomer = createSelector(
  selectSession,
  (sessionRecord: SessionRecord) => sessionRecord?.activeCustomer,
);

export const selectInactiveCustomers = createSelector(
  selectSession,
  (sessionRecord: SessionRecord): SessionCustomer[] =>
    sessionRecord
      ? sessionRecord.customers.filter(
          (customer) =>
            customer.naooCustomerId !==
            sessionRecord.activeCustomer?.naooCustomerId,
        )
      : undefined,
);

export const selectCurrentSystem = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer) => activeCustomer?.currentSystem,
);

export const selectLanguage = createSelector(
  selectSession,
  (sessionRecord: SessionRecord) => sessionRecord?.language,
);

export const selectLocale = createSelector(
  selectSession,
  (sessionRecord: SessionRecord): Locale => {
    if (!sessionRecord?.language) {
      return undefined;
    }

    if (sessionRecord.activeCustomer) {
      return sessionRecord.activeCustomer.locale;
    }

    switch (sessionRecord.language) {
      case Language.fr:
        return Locale.fr_CA;
      case Language.es:
        return Locale.es_US;
      case Language.en:
      default:
        return Locale.en_US;
    }
  },
);

export const selectActiveCustomerTimezone = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): string => activeCustomer?.timeZone,
);

export const selectIsWholesaleCustomer = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    wholesalePrimaryChannel === activeCustomer?.primaryChannel,
);

export const selectIsDigitalOnlyCustomer = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    activeCustomer?.isDigitalOnly,
);

export const selectIsGrmCustomer = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    grmPrimaryChannel === activeCustomer?.primaryChannel,
);

export const selectIsLoyaltyProgramEligible = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    activeCustomer?.isLoyaltyProgramEligible,
);

const selectAllEnabledFeatures = createSelector(
  selectSession,
  (sessionRecord: SessionRecord) => sessionRecord?.enabledFeatures,
);

export const selectCustomerBrand = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer) => activeCustomer?.brand,
);

export const selectIsPurchaseOrderRequired = createSelector(
  selectActiveCustomer,
  (customer: SessionActiveCustomer) =>
    customer
      ? customer.permissions.includes(CustomerPermission.PoRequired)
      : true,
);

export const selectIsLockedToOrderGuide = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    activeCustomer?.permissions.includes(CustomerPermission.LockedToOrderGuide),
);

export const selectIsSpecialOrderDashboardAccess = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    activeCustomer?.permissions.includes(
      CustomerPermission.SpecialOrderDashboardAccess,
    ) ?? false,
);

export const selectPrimarySiteId = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer) => activeCustomer?.primarySiteId,
);

export const selectPrimarySite = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer) => {
    return activeCustomer?.sites?.find(
      (site) => site.siteId === activeCustomer?.primarySiteId,
    );
  },
);

export const selectIsFeatureEnabled = (featureToFind: string) =>
  createSelector(selectAllEnabledFeatures, (enabledFeatures: string[]) =>
    enabledFeatures?.includes(featureToFind),
  );

export const selectHasPermissionEnabled = (permission: CustomerPermission) =>
  createSelector(
    selectActiveCustomer,
    (customer: SessionActiveCustomer): boolean =>
      customer?.permissions?.includes(permission),
  );

export const selectHasIspuOrExpressEnabled = createSelector(
  selectActiveCustomer,
  (customer: SessionActiveCustomer): boolean => {
    return customer?.permissions?.some((permission) =>
      storeCustomerPermissions.has(permission),
    );
  },
);

export const selectCanPlaceIspuOrExpressOrder = createSelector(
  selectHasIspuOrExpressEnabled,
  (hasIspuOrExpressEnabled: boolean): boolean => hasIspuOrExpressEnabled,
);

export const selectIsCustomerUpdated = createSelector(
  selectSessionFeature,
  (state: SessionState) => !state.isCustomerUpdating,
);

export const selectGordonNowData = createSelector(
  selectActiveCustomer,
  selectCustomerBrand,
  selectLanguage,
  (
    activeCustomer: SessionActiveCustomer,
    brand: CustomerBrand,
    language: Language,
  ): GordonNowData | undefined => {
    const fourPartKey = activeCustomer?.fourPartKey;
    if (!fourPartKey || !brand) {
      return undefined;
    }
    return {
      brand,
      customerUnitKey: {
        salesOrganization: fourPartKey.salesOrganization,
        distributionChannel: fourPartKey.distributionChannel,
        division: fourPartKey.division,
        customerNumber: fourPartKey.customerId,
      },
      language,
    };
  },
);

export const selectHasShowPurchaseHistoryEnabled = createSelector(
  selectActiveCustomer,
  (activeCustomer: SessionActiveCustomer): boolean =>
    activeCustomer?.showPurchaseHistory,
);
