import { BannerAdsActions } from './banner-ads.actions';
import {
  BannerAd,
  BannerAdsState,
  initialBannerAdsState,
} from './banner-ads.state';
import { createReducer, on } from '@ngrx/store';
import { BannerAdsRecord } from '../../services/banner-ads/models/banner-ads-record';

export const bannerAdsReducer = createReducer(
  initialBannerAdsState,
  on(
    BannerAdsActions.getBannerAdsSuccess,
    (state, action): BannerAdsState =>
      insertBannerAdRecords(state, action.bannerAdsRecord),
  ),
  on(
    BannerAdsActions.getBannerAdsError,
    (state): BannerAdsState => ({
      ...state,
      bannerAds: [],
    }),
  ),
  on(
    BannerAdsActions.refreshBannerAds,
    (): BannerAdsState => initialBannerAdsState,
  ),
  on(BannerAdsActions.getBannerAds, (state): BannerAdsState => state),
);

function insertBannerAdRecords(
  state: BannerAdsState,
  bannerAdsRecord: BannerAdsRecord,
): BannerAdsState {
  const bannerAds: BannerAd[] = [];
  for (const divName of bannerAdsRecord
    ? Object.keys(bannerAdsRecord.banners)
    : []) {
    const bannerAd = bannerAdsRecord.banners[divName];
    if (bannerAd) {
      bannerAds.push(bannerAd);
    }
  }
  return {
    ...state,
    bannerAds,
  };
}
