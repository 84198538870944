import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface SmartCartRecommendation {
  product: string;
  uom: string;
  quantity: number;
}

export interface SmartCartRecommendationState {
  smartCartRecommendation: EntityState<SmartCartRecommendation>;
  hasLoaded: boolean;
}

export interface SmartCartRequest {
  materialNumbers: string[];
}

export const smartCartRecommendationAdapter =
  createEntityAdapter<SmartCartRecommendation>({
    selectId: (recommendation) => recommendation.product,
  });

export const initialSmartCartRecommendationState: SmartCartRecommendationState =
  {
    smartCartRecommendation: smartCartRecommendationAdapter.getInitialState(),
    hasLoaded: false,
  };
