import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { hasMaterialInfoFinishedLoading } from '../material-info/material-info.util';
import { CommodityPricingData } from '../commodity-price/commodity-price.selectors';
import { UnitInfoRecord } from '../../services/material-info/models/materials-info-record';
import { NaooConstants } from '../../../shared/NaooConstants';
import { UnitPriceRecord } from '../../services/material-price/models/material-price-record';
import {
  MaterialPriceRecordState,
  MaterialPriceRecordStatus,
} from './material-price.state';
import { CartMaterialState } from '../cart/cart.state';
import { getQuantityFromCartMaterial } from '../../../shared/utilities/cart-material-utilities';

interface PriceData {
  price: number;
  catchWeightPrice: number | null;
}

export interface CombinedPricingRecord {
  materialNumber: string;
  isCatchWeight: boolean;
  weightUom: string | null;
  hasLoaded: boolean;
  unitPrices: CombinedUnitPriceRecord[];
  currency?: string;
}

export interface CombinedUnitPriceRecord {
  salesUom: string;
  price: number;
  pricingSource: PricingSource;
  catchWeightPrice?: number;
  coupon?: string;
  discountAmount?: number;
  loyaltyPoints?: number;
}

export enum PricingSource {
  Regular = 'Regular',
  Commodity = 'Commodity',
  Coupon = 'Coupon',
  Sap = 'Sap',
  Bulk = 'Bulk',
}

export function convertCombinedPriceToMap(
  combinedPricingRecords: CombinedPricingRecord[],
): Map<string, CombinedPricingRecord> {
  return new Map(combinedPricingRecords.map((i) => [i?.materialNumber, i]));
}

export function hasPricingFinishedLoading(
  recordState: MaterialPriceRecordState,
): boolean {
  const finishedStatuses: MaterialPriceRecordStatus[] = [
    MaterialPriceRecordStatus.Error,
    MaterialPriceRecordStatus.Success,
  ];
  return !!recordState && finishedStatuses.includes(recordState.status);
}

export function getWeightUom(weightUom: string): string {
  return weightUom?.toLowerCase();
}

export function hasCombinedPricingSourcesFinishedLoaded(
  commodityPricingData: CommodityPricingData,
  materialPriceRecord: MaterialPriceRecordState,
  materialInfoRecord: MaterialInfoRecordState,
  hasCommodityAccess: boolean,
): boolean {
  return (
    ((hasCommodityAccess && !!commodityPricingData?.hasLoaded) ||
      !hasCommodityAccess) &&
    hasPricingFinishedLoading(materialPriceRecord) &&
    hasMaterialInfoFinishedLoading(materialInfoRecord)
  );
}

// eslint:disable-next-line:parameters-max-number
export function getCombinedPricingRecord(
  materialNumber: string,
  materialInfo: MaterialInfoRecordState,
  commodityPricingData: CommodityPricingData,
  priceRecord: MaterialPriceRecordState | undefined,
  hasLoaded: boolean,
  displayCoupon: boolean,
  cartMaterialState: CartMaterialState | undefined,
  currency: string,
): CombinedPricingRecord {
  const isCatchWeight = materialInfo?.record?.isCatchWeight;
  const unitPrices: CombinedUnitPriceRecord[] = materialInfo?.record?.units.map(
    (infoUnit): CombinedUnitPriceRecord => {
      const priceUnitRecord = priceRecord?.record?.unitPrices.find(
        (priceUnit) => infoUnit.uom === priceUnit.salesUom,
      );
      const quantity = getQuantityFromCartMaterial(
        cartMaterialState,
        infoUnit.uom,
      );
      const pricingSource: PricingSource = determinePricingSource(
        infoUnit,
        commodityPricingData,
        displayCoupon,
        quantity,
        priceUnitRecord,
      );
      const priceData: PriceData = getPriceData(
        pricingSource,
        priceUnitRecord,
        commodityPricingData,
      );
      const loyaltyPoints =
        PricingSource.Bulk == pricingSource
          ? priceUnitRecord?.quantityPriceLoyaltyPoints
          : priceUnitRecord?.priceLoyaltyPoints;
      const isCouponPricingSource = PricingSource.Coupon === pricingSource;
      return {
        salesUom: infoUnit.uom,
        price: priceData.price,
        catchWeightPrice: priceData.catchWeightPrice
          ? priceData.catchWeightPrice
          : undefined,
        pricingSource,
        coupon: isCouponPricingSource ? priceUnitRecord?.coupon : undefined,
        discountAmount: isCouponPricingSource
          ? priceUnitRecord?.discountAmount
          : undefined,
        loyaltyPoints,
      };
    },
  );
  return {
    materialNumber,
    isCatchWeight,
    weightUom: priceRecord?.record?.weightUom,
    hasLoaded,
    unitPrices,
    currency,
  };
}

function determinePricingSource(
  infoUnit: UnitInfoRecord,
  commodityPricingData: CommodityPricingData,
  displayCoupon: boolean,
  quantity: number,
  priceUnitRecord: UnitPriceRecord,
) {
  if (
    priceUnitRecord?.quantityPriceThreshold &&
    quantity >= priceUnitRecord?.quantityPriceThreshold
  ) {
    return PricingSource.Bulk;
  }
  if (
    NaooConstants.Uom.Case === infoUnit.uom &&
    commodityPricingData?.isCommodityPricingType
  ) {
    return PricingSource.Commodity;
  }
  return displayCoupon ? PricingSource.Coupon : PricingSource.Regular;
}

function getPriceData(
  pricing: PricingSource,
  priceUnitRecord: UnitPriceRecord,
  commodityPricing: CommodityPricingData,
): PriceData {
  let price, catchWeightPrice: number;
  switch (pricing) {
    case PricingSource.Bulk: {
      price = priceUnitRecord?.quantityPrice;
      catchWeightPrice = priceUnitRecord?.quantityCatchWeightPrice;
      break;
    }
    case PricingSource.Regular: {
      price = priceUnitRecord?.coupon
        ? priceUnitRecord?.originalPrice
        : priceUnitRecord?.price;
      catchWeightPrice = priceUnitRecord?.catchWeightPrice;
      break;
    }
    case PricingSource.Commodity: {
      price = commodityPricing?.casePrice;
      catchWeightPrice = commodityPricing?.catchWeightPrice;
      break;
    }
    case PricingSource.Coupon: {
      price = priceUnitRecord?.price;
      catchWeightPrice = priceUnitRecord?.catchWeightPrice;
    }
  }
  return {
    price: price ? price : null,
    catchWeightPrice,
  };
}
