import { createActionGroup, emptyProps } from '@ngrx/store';
import { OpenSpecialOrderItemsResponse } from '../../services/open-special-order-items/model/open-special-order-items-response';

export const OpenSpecialOrderItemsActions = createActionGroup({
  source: 'OpenSpecialOrderItems',
  events: {
    'Get Open Special Order Items': emptyProps(),
    'Get Open Special Order Items Success': (
      openSpecialOrderItemsResponse: OpenSpecialOrderItemsResponse,
    ) => ({ openSpecialOrderItemsResponse }),
    'Get Open Special Order Items Error': emptyProps(),
    'Refresh Open Special Order Items': emptyProps(),
  },
});
