import { createActionGroup, emptyProps } from '@ngrx/store';
import { BannerAdsRecord } from '../../services/banner-ads/models/banner-ads-record';

export const BannerAdsActions = createActionGroup({
  source: 'BannerAds',
  events: {
    'Get Banner Ads': emptyProps(),
    'Get Banner Ads Success': (bannerAdsRecord: BannerAdsRecord) => ({
      bannerAdsRecord,
    }),
    'Get Banner Ads Error': emptyProps(),
    'Refresh Banner Ads': emptyProps(),
  },
});
