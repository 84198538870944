import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialPriceRecord } from '../../services/material-price/models/material-price-record';

export interface CommodityPriceState {
  prices: EntityState<CommodityPriceRecordState>;
}

export interface CommodityPriceRecordState {
  materialNumber: string;
  status: CommodityPriceRecordStatus;
  isRegularPricing: boolean;
  record?: MaterialPriceRecord;
}

export enum CommodityPriceRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const commodityPriceRecordEntityAdapter =
  createEntityAdapter<CommodityPriceRecordState>({
    selectId: (commodityPrice) => commodityPrice.materialNumber,
  });

export const initialCommodityPriceState: CommodityPriceState = {
  prices: commodityPriceRecordEntityAdapter.getInitialState(),
};
