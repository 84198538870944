export interface OrderGuideChangeHistoryRecord {
  materialNumber: string;
  lastUpdate: Date;
  status: OrderGuideChangeItemStatus;
}

export enum OrderGuideChangeItemStatus {
  Added = 'A',
  Removed = 'X',
}
