import { AuthService } from '../auth/auth.service';
import { WebBffService } from '../web-bff/web-bff.service';
import { NaooConstants } from '../../NaooConstants';
import { Injectable } from '@angular/core';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';

@Injectable({ providedIn: 'root' })
export class LogoutService {
  private isLoggingOut = false;

  constructor(
    private windowObject: Window,
    private authService: AuthService,
    private webBffService: WebBffService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  logout() {
    if (!this.isLoggingOut) {
      this.isLoggingOut = true;
      this.ecommerceAnalyticsFacade.trackCustomerLogoutEvent();
      this.authService.clearStashUrl();
      this.windowObject.location.href =
        this.webBffService.getBff() + NaooConstants.LOGOUT_URL;
    }
  }
}
